import { useDispatch, useSelector } from "react-redux";
import { selectPaymentCreation } from "../../../../store/paymentCreation/repository/selector";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { resetAllFields, setPaymentFields, setPaymentMethod, setPaymentType, setServiceFields, setTime } from "../../../../store/paymentCreation/repository/slice";
import { getPaymentMethod } from "../../../../store/paymentCalendar/useCases/getPaymentMethod/action";
import { getPaymentKind } from "../../../../store/paymentCalendar/useCases/getPaymentKind/action";
import { getPaymentType } from "../../../../store/paymentCalendar/useCases/getPaymentType/action";
import { getExpenditure } from "../../../../store/paymentCalendar/useCases/getExpenditure/action";
import { getPartners } from "../../../../store/paymentCalendar/useCases/getPartners/action";
import { getClinics } from "../../../../store/paymentCalendar/useCases/getClinics/action";
import { selectPayments } from "../../../../store/paymentCalendar/repository/selector";
import { getServiceList } from "../../../../store/service/useCases/getServiceList/action";
import { getAccountList } from "../../../../store/paymentCalendar/useCases/getAccountList/action";
import { useParams } from "react-router-dom";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ContactlessIcon from '@mui/icons-material/Contactless';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { CALENDAR_MODE } from "../../../../config";
import { getSingleServicePayment } from "../../../../store/serviceHistory/useCases/getSingleServicePayment/action";
import { selectServiceHistory } from "../../../../store/serviceHistory/repository/selector";
import { setSingleService } from "../../../../store/serviceHistory/repository/slice";
import { setPaymentUpdated } from "../../../../store/operationValidation/repository/slice";

export const useInitService = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [currentTab, setCurrentTab] = useState<any>(false);
    const { paymentMethod, formStatus } = useSelector(selectPaymentCreation);
    const { paymentMethods, paymentTypes } = useSelector(selectPayments);
    const { singleService } = useSelector(selectServiceHistory);

    const storedMode = JSON.parse(localStorage.getItem('mode') || 'false');
    const mode = storedMode || CALENDAR_MODE.PAYMENT;
    const [isLoading, setIsLoading] = useState(false);
    const [isPaymentShown, setPaymentShown] = useState(false);
    const paymentMethodOptions = paymentMethods.map((method: any) => {
        if (method.key === 'card') {
            return { ...method, icon: CreditCardIcon };
        } else if (method.key === 'cash') {
            return { ...method, icon: AttachMoneyIcon };
        } else {
            return { ...method, icon: ContactlessIcon };
        }
    });

    useEffect(() => {
        dispatch(getPaymentMethod());
        dispatch(getPaymentKind());
        dispatch(getPaymentType());
        dispatch(getExpenditure({ offset: 0, limit: 20, search: '' }));
        dispatch(getPartners({ offset: 0, limit: 20, search: '' }));
        dispatch(getClinics());
        dispatch(getServiceList({ page: 1, perPage: 100 }));
        return () => {
            dispatch(resetAllFields());
            dispatch(setSingleService(null));
            dispatch(setPaymentUpdated(false));
        }
    }, []);

    useEffect(() => {
        paymentMethod && dispatch(getAccountList({ offset: 0, limit: 20, search: '', account_type: paymentMethod.name_enote }));
    }, [paymentMethod]);

    useEffect(() => {
        if (paymentTypes.length > 0) {
            const serviceType = paymentTypes.find((type: any) => type.key === "service_receiving");
            dispatch(setPaymentType(serviceType.id));
        }
    }, [paymentTypes]);

    useEffect(() => {
        if (id) {
            setIsLoading(true);
            dispatch(getSingleServicePayment({ id }))
        }
    }, [id]);

    useEffect(() => {
        if (singleService) {
            setTimeout(() => {
                dispatch(setServiceFields(singleService.receipt_goods_services));
                dispatch(setTime(singleService.payment.payment_date));
                if(singleService.payment.amount){
                    dispatch(setPaymentFields(singleService.payment));
                    setPaymentShown(true);
                }
                setIsLoading(false);
            }, 0);
        };
    }, [singleService]);

    const handleTogglePaymentShown = () => {
        setPaymentShown(!isPaymentShown);
    };

    const handleTabsChange = useCallback((event: ChangeEvent<{}>, tab: string): void => {
        const currentMethod = paymentMethods.find((method: any) => method.key === tab);
        setCurrentTab(tab);
        dispatch(setPaymentMethod(currentMethod));
    }, [paymentMethods]);

    const serviceStatus = formStatus || singleService?.receipt_goods_services?.status;
    const operationId = singleService?.receipt_goods_services?.id;

    return { currentTab, handleTabsChange, paymentMethodOptions, paymentMethod, mode, singleService, serviceStatus, 
        isLoading, handleTogglePaymentShown, isPaymentShown, operationId }
};