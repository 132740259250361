import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';

interface IState {
  isLoading: boolean;
  serviceHistoryList: any[];
  serviceHistoryCount: number;
  createServicePaymentError: IError;
  updateServicePaymentError: IError;
  processServiceError: IError;
  singleService: any;
};

const initialState: IState = {
  isLoading: false,
  serviceHistoryList: [],
  serviceHistoryCount: 0,
  createServicePaymentError: null,
  updateServicePaymentError: null,
  processServiceError: null,
  singleService: null
};

const ServiceHistorySlice = createSlice({
  name: 'serviceHistory',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSingleService: (state, action: PayloadAction<any>) => {
      state.singleService = action.payload;
    },
    setServiceHistoryList: (state, action: PayloadAction<any>) => {
      state.serviceHistoryList = action.payload.list;
      state.serviceHistoryCount = action.payload.count;
    },
    setCreateServicePaymentError: (state, action: PayloadAction<IError>) => {
      state.createServicePaymentError = action.payload;
    },
    setUpdateServicePaymentError: (state, action: PayloadAction<IError>) => {
      state.updateServicePaymentError = action.payload;
    },
    setProcessServiceError: (state, action: PayloadAction<IError>) => {
      state.processServiceError = action.payload;
    },
  },
});

export const { setLoading, setServiceHistoryList, setCreateServicePaymentError, setUpdateServicePaymentError, setSingleService, setProcessServiceError } = ServiceHistorySlice.actions;
export const ServiceHistoryReducer = ServiceHistorySlice.reducer;