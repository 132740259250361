import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";
import { setPrepaymentList } from "../../repository/slice";

function* getPrepaymentListWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.get, "/zoolux-api/payments/list-prepayment", payload);
        if (isError) {
            toast.error(message);
        } else {
            yield put(setPrepaymentList({ list: data.rows, count: data.count }));
        }
    } catch (error) {
        console.error("getPrepaymentListWorker: ", error);
    } finally {
    }
};

export function* getPrepaymentListWatcher() {
    yield takeLatest("@saga/GET_PREPAYMENT_LIST", getPrepaymentListWorker);
};

