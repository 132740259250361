import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { FC, useMemo } from 'react';
import { useStepperMui } from '../presenter/useStepperMui';
import { getStyles } from './styles';

interface IProps {
    status: string;
    mode: string;
};

export const StepperMui: FC<IProps> = ({ status, mode }) => {
    const styles = useMemo(() => getStyles(), []);
    const { activeStep, steps } = useStepperMui(status, mode);

    return (
        <Box sx={styles.box}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </Box>
    );
};