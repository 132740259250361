import { FC, useMemo } from "react";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Stack, TextField, Theme, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getStyles } from "./styles";

interface IProps {
    isDisabled?: boolean;
    value: number | null;
    isValid: boolean;
    title: string;
    isdisabledPast: boolean;
    handleDate: (date: Date | null) => void;
};

export const TimeDatePicker: FC<IProps> = ({ handleDate, value, isValid, title, isdisabledPast, isDisabled }) => {
    const { t } = useTranslation();
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const time = value ? new Date(value) : null;
    const styles = useMemo(() => getStyles(isValid), [isValid]);

    return (
        <Stack width='100%' spacing={1}>
            <Typography variant={downSm ? 'body1' : 'h6'} color={isValid ? 'primary' : 'error'}>{title}</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div style={{ borderColor: isValid ? 'primary' : 'error' }}>
                    <DatePicker
                        disabled={isDisabled}
                        onChange={handleDate}
                        value={time}
                        disablePast={isdisabledPast}
                        renderInput={props =>
                            <TextField
                                variant="outlined"
                                fullWidth
                                color={isValid ? 'primary' : 'error'}
                                error={!isValid}
                                {...props}
                                sx={styles.date}
                                size="small"
                            />}
                    />
                </div>
            </LocalizationProvider>
        </Stack>
    )
};