import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";
import { setTelegramCode } from "../../repository/slice";

function* getTelegramCodeWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.get, "/zoolux-api/telegram-bot/get-secret-key");
        if (isError) {
            toast.error(message);
        } else {
            yield put(setTelegramCode(data.key));
        }
    } catch (error) {
        console.error("getTelegramCodeWorker: ", error);
    } finally {

    }
};

export function* getTelegramCodeWatcher() {
    yield takeLatest("@saga/GET_TELEGRAM_CODE", getTelegramCodeWorker);
};
