import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import { ChangeEvent, FC } from "react"
import { collectFolderList } from "../../../../../../utils/collectOptionList";

interface IProps {
    title: string;
    value: any;
    handleChangeValue: any;
    isValid: boolean;
    optionList: any[];
    changeText: (event: ChangeEvent<HTMLInputElement>) => void;
}; 

export const FolderAutocomplete: FC<IProps> = ({ value, handleChangeValue, optionList, title, isValid, changeText }) => {
    const options = collectFolderList(optionList);
    
    return (
        <Stack direction='column' alignItems='flex-start' spacing={1} width='100%' pt={1}>
            <Typography color={isValid? 'primary' : 'error'} variant='h6'>{title}</Typography>
            <Autocomplete
                disablePortal
                onChange={(e, value) => handleChangeValue(value)}
                value={value}
                options={options}
                renderOption={(params, option): JSX.Element => (
                    <Typography {...params} key={option.id}>
                        {option.name}
                    </Typography>
                )}
                renderInput={(params): JSX.Element => (
                    <TextField
                        {...params}
                        fullWidth
                        error={!isValid}
                        name="category"
                        variant="outlined"
                        label={title}
                        onChange={changeText}
                    />
                )}
                clearIcon={false}
                sx={{ width: '100%' }}
            />
        </Stack>
    )
};