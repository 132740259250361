import { FC } from "react";
import { SectionItem } from "../../../../../types/settings";
import { Stack } from "@mui/material";
import { renderItems } from "../../../../../utils/renderNavSections";
import { getStyles } from "./styles";

interface IProps {
    items?: SectionItem[];
    pathname?: string | null;
    subheader?: string;
}

export const SideNavSection: FC<IProps> = ({ items = [], pathname, subheader = '' }) => {
    const styles = getStyles();
    
    return (
        <Stack component="ul" spacing={0.5} sx={styles.ul}>
            {renderItems({ items, pathname })}
        </Stack>
    )
};