import { Stack, TextField, Theme, Typography, useMediaQuery } from "@mui/material"
import { ChangeEvent, FC } from "react"
import { TScreenFlag } from "../../modules/paymentCalendar/payment/ui/components/parts/paymentData/presenter/useServicePayment";

interface IProps {
    screenFlag?: TScreenFlag;
    isDisabled?: boolean;
    title: string;
    comment: string;
    isValid: boolean;
    onChangeComment: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const FieldComment: FC<IProps> = ({ comment, onChangeComment, isValid, title, screenFlag, isDisabled }) => {
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const isDisabledField = screenFlag === "SERVICE" || isDisabled;

    return (
        <Stack direction='column' spacing={1}>
            <Typography color={isValid ? 'primary' : 'error'} variant={downSm ? 'body1' : 'h6'}>{title}</Typography>
            <Stack>
                <TextField fullWidth value={comment} onChange={onChangeComment} label={title} multiline rows={2} inputProps={{ maxLength: 512 }} error={!isValid} size="small" disabled={isDisabledField} />
                <Typography variant="caption" color='GrayText' mt={1}>Максимальна кількість символів {comment.length} / 512</Typography>
            </Stack>
        </Stack>
    )
};