export const getStyles = () => {
    const styles = {
        header: { 
            justifyContent: 'space-between',
            mt: 2,
            alignItems: "center"
        },
        createBtn: { 
            borderRadius: '4px', 
            color: '#FFFFFF',
            textTransform: 'none' 
        }
    };
    return styles;
}