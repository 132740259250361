export const PAYMENT_STATUS = {
    CREATED: "CREATED",
    VISAED: "VISAED",
    OPERATED: "OPERATED",
    TRANSMITTED: "TRANSMITTED",
    COMPLETED: "COMPLETED",
    DELETED: "DELETED"
};

export const PAYMENT_METHOD = {
    NONCASH: 'non-cash',
    CASH: 'cash',
    CARD: 'card'
};

export const PREPAYMENT_STATUS = {
    NEW: "NEW",
    DONE: "DONE"
};

export const CURRENCY = {
    UAH: ' грн. '
};

export const CALENDAR_MODE = {
    PAYMENT: 'Payment',
    BUDGET: "Budget",
};

export const TIMESTAMP_DAY = 24 * 60 * 60 * 1000;
export const TIMESTAMP_25MB = 25 * 1024 * 1024;

export const PER_PAGE = 15;

export const config = {
    location: {
        debounceSearch: 400,
        limit: 30
    },
    fixedAsset: {
        debounceSearch: 400,
        limit: 30
    }
};