import { TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../locales/tokens";
import { getStyles } from "./styles";

export const PrepaymentTableHead: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);

    return (
        <TableHead sx={styles.header}>
            <TableRow >
                <TableCell align="center">
                    <Typography fontWeight={600}>{t(tokens.payment.paymentDate)}</Typography>
                </TableCell>
                <TableCell align="center">
                    <Typography fontWeight={600}>{t(tokens.payment.prepaymentDate)}</Typography>
                </TableCell>
                <TableCell align="center">
                    <Typography fontWeight={600}>{t(tokens.payment.partner)}</Typography>
                </TableCell>
                <TableCell align="center">
                    <Typography fontWeight={600}>{t(tokens.payment.sum)}</Typography>
                </TableCell>
                <TableCell align="center">
                    <Typography fontWeight={600}>{t(tokens.payment.comment)}</Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    )
};