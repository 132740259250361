import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";
import { getPartners } from "../../../paymentCalendar/useCases/getPartners/action";
import { getAllAccounts } from "../getAllAccounts/action";

function* refreshAccountsWorker(): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.post, "/zoolux-api/company-accounts/refresh-enote");
        if (isError) {
            toast.error("Рахунки не оновилися");
        } else {
            toast.success("Рахунки оновлені");
            yield put(getAllAccounts({ offset: 0, limit: 1000 }));
        }
    } catch (error) {
        console.error("refreshAccountsWorker: ", error);
    } finally {

    };
};

export function* refreshAccountsWatcher() {
    yield takeLatest("@saga/REFRESH_ACCOUNTS", refreshAccountsWorker);
};
