
export const getStyles = ({ isOnePage }: any) => ({
    tableContainer: {
        boxShadow: "none",
        width: '100%',
        position: "relative",
        overflowX: 'scroll',
        height: isOnePage ? "calc(100vh - 64px - 60px - 44px - 16px - 8px - 32px - 1px)" : "calc(100vh - 64px - 60px - 44px - 16px - 8px - 32px - 1px - 1.97rem)"
    },
    headerSection: {
        backgroundColor: "white",
        position: "sticky",
        top: "64px",
        mx: "-2.5px",
        pb: 2,
        rowGap: 2
    }
});