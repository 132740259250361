import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import dayjs from "dayjs";
import 'dayjs/locale/uk';

interface IProps {
    date: any;
    title: string;
    name: string;
    spacing: number;
    direction: 'row' | 'column';
};

export const HistoryItem: FC<IProps> = ({ direction, date, name, title, spacing }) => {
    const formattedDate = date? dayjs(date).locale('uk').format('MMMM D, YYYY, HH:mm') : '';
    const comma = name && date ? ', ' : '';

    return (
        <Stack direction={direction} spacing={spacing}>
            <Typography fontWeight={600}>{title}:</Typography>
            <Typography variant="subtitle2">{name}{comma}{formattedDate}</Typography>
        </Stack>
    )
};