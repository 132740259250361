import { Box, FormControl } from '@mui/material';
import 'dayjs/locale/uk';
import React, { FC, ReactNode } from 'react';
import { Control, Controller } from 'react-hook-form';

interface IProps {
    children: ReactNode;
    label: string;
    control?: Control<any>;
    defaultValue?: string | boolean | number | null;
    name?: string;
}

export const FormControlLayout: FC<IProps> = ({ children, label, control, defaultValue = '', name }: any) => (
    <FormControl fullWidth>
        <Box component={"label"} fontWeight={600}>{label}</Box>
        {control ? <Controller
            control={control}
            defaultValue={defaultValue}
            name={name}
            render={({ field }) => (children && React.Children.map(children, child => React.isValidElement(child) ? React.cloneElement(child, { ...field }) : child))}
        /> : children}
    </FormControl>
);
