import { Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import { PAYMENT_STATUS } from "../../../../../../config";

interface IProps {
    paymentId: number;
    status: string;
};

export const PaymentTitle: FC<IProps> = ({ paymentId, status }) => {
    const { t } = useTranslation();

    return (
        <>
            {status !== PAYMENT_STATUS.OPERATED ?
                <Typography variant="h4" textAlign="center" mb={2}>
                    {paymentId ? t(tokens.payment.editPayment) : t(tokens.payment.createPayment)}
                </Typography>
                :
                <Typography variant="h4" textAlign="center" mb={2}>
                    Оплата проведена
                </Typography>
            }
        </>
    )
};