import { useDispatch, useSelector } from "react-redux";
import { selectPayments } from "../../../store/paymentCalendar/repository/selector";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";
import { useEffect } from "react";
import { setPrepaymentComment, setPrepaymentDate, setResponsible } from "../../../store/paymentCreation/repository/slice";

export const usePaymentKindSelector = (value: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { paymentKinds } = useSelector(selectPayments);

    useEffect(() => {
        const kind = paymentKinds.find(item => item.id === value);
        if (kind?.key !== "prepayment") {
            dispatch(setPrepaymentComment(''));
            dispatch(setPrepaymentDate(null));
            dispatch(setResponsible(null));
        }
    }, [value]);
    
    const paymentKindOptions: any = {
        postpayment: t(tokens.payment.postpayment),
        prepayment: t(tokens.payment.prepayment),
    };
    return { paymentKinds, paymentKindOptions }
}