import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';

interface IState {
  isLoading: boolean,
  singlePartner: any;
  partnerList: any[],
  partnersCount: number,
  partnerFoldersCount: number,
  partnerFolders: any[],
  createPartnerError: IError,
  updatePartnerError: IError,
};

const initialState: IState = {
  isLoading: false,
  singlePartner: null,
  partnerList: [],
  partnersCount: 0,
  partnerFoldersCount: 0,
  partnerFolders: [],
  createPartnerError: null,
  updatePartnerError: null,
};

const PartnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSinglePartner: (state, action: PayloadAction<any>) => {
      state.singlePartner = action.payload;
    },
    setPartnersList: (state, action: PayloadAction<any[]>) => {
      state.partnerList = action.payload;
    },
    setCreatePartnerError: (state, action: PayloadAction<IError>) => {
      state.createPartnerError = action.payload;
    },
    setUpdatePartnerError: (state, action: PayloadAction<IError>) => {
      state.updatePartnerError = action.payload;
    },
    setPartnerFolders: (state, action: PayloadAction<any>) => {
      state.partnerFolders = action.payload.list;
      state.partnerFoldersCount = action.payload.count;
    },
    setPartnersCount: (state, action: PayloadAction<number>) => {
      state.partnersCount = action.payload;
    },
  },
});

export const { setSinglePartner, setPartnersCount, setPartnersList, setPartnerFolders, setLoading, setCreatePartnerError, setUpdatePartnerError } = PartnerSlice.actions;
export const PartnerReducer = PartnerSlice.reducer;