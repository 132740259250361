import { SagaIterator } from "redux-saga";
import { put, call, takeEvery } from "redux-saga/effects";
import { requester } from "../../../../libs/requester/requester-axios";
import { IResponse } from "../../../../libs/requester/IResponse";
import toast from "react-hot-toast";
import { setImage } from "../../../paymentCreation/repository/slice";

function* watchFileWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.get, "/zoolux-api/file/watch/" + payload.fileName );
        if (isError) {
            toast.error(message);
        } else {
            yield put(setImage(process.env.REACT_APP_PUBLIC_URL + '/zoolux-api/file/watch/' + payload.fileName));
        }
    } catch (error) {
        console.error("watchFileWorker: ", error);
    } finally {

    }
};

export function* watchFileWatcher() {
    yield takeEvery("@saga/WATCH_FILE", watchFileWorker);
};