import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import dayjs from "dayjs";
import { FC, useCallback, useMemo } from "react";
import { getStyles } from "./styles";
import { t } from "i18next";
import { tokens } from "../../../../../../locales/tokens";
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface IProps {
    importItem: any;
    rowId: number;
    uploadPayments: any[];
    isItemOpen: boolean;
    onChangeItemOpen: (id: number, isItemOpen: boolean) => void;
};

export const ImportsTableItem: FC<IProps> = ({ importItem, isItemOpen, onChangeItemOpen, rowId, uploadPayments }) => {
    const styles = useMemo(() => getStyles(), []);
    const importDate = dayjs(importItem.import_date).locale('uk').format('MMMM D, YYYY');

    const typeOptions: any = {
        IMPORT_ENOTE: t(tokens.importPayment.uploadInEnot),
        IMPORT_CALENDAR: t(tokens.importPayment.uploadInCalendar),
    };

    const getStatusColor = useMemo(() => {
        return (status: string) => {
            return status === "SUCCESS"
                ? 'green'
                : status === "MIXED"
                    ? 'orange'
                    : 'red';
        };
    }, []);

    const color = getStatusColor(importItem.status);

    const toggleRow = useCallback(() => onChangeItemOpen(importItem.id, isItemOpen), [importItem.id, isItemOpen]);

    return (
        <TableRow hover key={importItem.name + importItem.id} sx={styles.tableRow} onClick={toggleRow}>
            <TableCell>
                <IconButton aria-label="expand row" size="small" onClick={toggleRow}>
                    {isItemOpen && importItem.id === rowId ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </TableCell>
            <TableCell align="center">
                <Box bgcolor={color} sx={styles.status}>
                    {importItem?.status || "ERROR"}
                </Box>
            </TableCell>
            <TableCell align="center">
                <Typography>
                    {importItem?.name}
                </Typography>
            </TableCell>
            <TableCell align="center">
                <Typography>
                    {importItem?.count}
                </Typography>
            </TableCell>
            <TableCell align="center">
                <Typography>
                    {typeOptions[importItem?.type]}
                </Typography>
            </TableCell>
            <TableCell align="center">
                <Typography>
                    {importItem?.full_name}
                </Typography>
            </TableCell>
            <TableCell align="center">
                <Typography>
                    {importDate}
                </Typography>
            </TableCell>
        </TableRow>
    )
};