import { SagaIterator } from "redux-saga";
import { put, call, takeEvery } from "redux-saga/effects";
import { requester } from "../../../../libs/requester/requester-axios";
import { IResponse } from "../../../../libs/requester/IResponse";
import { setLoading, setUpdateContractError } from "../../repository/slice";

function* editContractWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const formData = new FormData();
        for (const [key, value] of Object.entries(payload as Object)) {
            const isValue = value !== null && value !== undefined && value !== "";
            if (isValue) {
                if (Array.isArray(value)) {
                    value.forEach(file => {
                        formData.append(key, file);
                    });
                } else {
                    formData.append(key, value);
                }
            }
        };
        const { data, isError, message }: IResponse<any> = yield call(requester.put, "/zoolux-api/document-counterparty", formData, { "Content-Type": "multipart/form-data" });
        if (isError) {
            yield put(setUpdateContractError(message));
        } else {
            yield put(setUpdateContractError(null));
        }
    } catch (error) {
        console.error("editContractWorker: ", error);
    } finally {
        yield put(setLoading(false));
    }
};

export function* editContractWatcher() {
    yield takeEvery("@saga/EDIT_CONTRACT", editContractWorker);
};