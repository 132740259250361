export const getStyles = () => {
    const styles = {
        tableRow: {
            cursor: "pointer",
            textDecoration: 'none',
        },
        cell: { 
            borderRight: '1px solid #ddd', 
            backgroundColor: "#F3F3F3"
        },
    };
    return styles;
};