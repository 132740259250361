import { TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getStyles } from "./styles";
import { tokens } from "../../../../../locales/tokens";

export const NomenclatureTableHead: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);

    return (
        <TableHead >
            <TableRow sx={styles.header}>
                <TableCell align="center" width='15%'>
                    <Typography fontWeight={600}>{t(tokens.nomenclature.code)}</Typography>
                </TableCell>
                <TableCell align="center" width='40%'>
                    <Typography fontWeight={600}>{t(tokens.nomenclature.description)}</Typography>
                </TableCell>
                <TableCell align="center" width='15%'>
                    <Typography fontWeight={600}>{t(tokens.nomenclature.articul)}</Typography>
                </TableCell>
                <TableCell align="center" width='15%'>
                    <Typography fontWeight={600}>{t(tokens.nomenclature.nomenclatureType)}</Typography>
                </TableCell>
                <TableCell align="center" width='15%'>
                    <Typography fontWeight={600}>{t(tokens.nomenclature.makeService)}</Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    )
};