import { SelectChangeEvent } from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { tokens } from "../../../../locales/tokens";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getSingleContract } from "../../../../store/contract/useCases/getSingleContract/action";
import { selectContract } from "../../../../store/contract/repository/selector";
import { editSingleContract } from "../../../../store/contract/useCases/editContract/action";
import { deleteImageFromContract } from "../../../../store/contract/useCases/deleteImageFromContract/action";
import { TIMESTAMP_25MB } from "../../../../config";

export const useContractEdit = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { partnerId, contractId } = useParams();
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [type, setType] = useState('');
    const [typeValid, setTypeValid] = useState(true);
    const [typeOptions, setTypeOptions] = useState(['Договір', 'Товари', 'Послуги', 'Інше']);
    const [comment, setComment] = useState('');
    const [commentValid, setCommentValid] = useState(true);
    const [files, setFiles] = useState<any[]>([]);
    const [fileValid, setFileValid] = useState(true);
    const [startDate, setStartDate] = useState<any>(null);
    const [startDateValid, setStartDateValid] = useState<any>(true);
    const [endDate, setEndDate] = useState<any>(null);
    const [endDateValid, setEndDateValid] = useState<any>(true);
    const { singleContract } = useSelector(selectContract);
    const [editClick, setEditClick] = useState(false);
    const { isLoading, updateContractError } = useSelector(selectContract);

    useEffect(() => {
        if (contractId) {
            dispatch(getSingleContract({ id: Number(contractId) }));
        }
    }, [contractId]);

    useEffect(() => {
        if (singleContract) {
            setType(singleContract.type);
            setComment(singleContract.comment);
            setStartDate(new Date(singleContract.date_start).getTime());
            setEndDate(new Date(singleContract.date_end).getTime());
            setFiles(singleContract.file);
        }
    }, [singleContract]);

    useEffect(() => {
        if (!isLoading && editClick) {
            if (updateContractError) {
                toast.error(updateContractError);
            } else {
                toast.success(t(tokens.contracts.message.contractUpdated));
                navigate(`/partner-contracts/${partnerId}`);
            }
            setEditClick(false);
        }
    }, [updateContractError, isLoading]);

    const handleChangeType = useCallback((event: SelectChangeEvent) => {
        setType(event.target.value);
        setTypeValid(true);
    }, []);

    const onChangeTitle = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    }, []);

    const onChangeComment = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setComment(event.target.value);
        setCommentValid(true);
    }, []);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            if (file.size <= TIMESTAMP_25MB) {
                setFiles((prevState) => [...prevState, file]);
            } else {
                toast.error(t(tokens.contracts.maxSizeFile));
            }
            setFileValid(true);
        };
    };

    const watchCurrentFile = (name: string) => {
        const fileUrl = name;
        window.open(fileUrl, '_blank');
    };

    const handleDeleteFile = (file: any) => {
        if (file instanceof File) {
            const newFiles = files.filter((item: any) => item.name !== file.name);
            setFiles(newFiles);
        } else {
            dispatch(deleteImageFromContract({ id: Number(contractId), fileName : file.name }));
            const newFiles = files.filter((item: any) => item.name !== file.name);
            setFiles(newFiles);
        }
    };

    const handleStartDateChange = useCallback((date: Date | null): void => {
        if (date) {
            setStartDate(date?.valueOf());
            setStartDateValid(true);
        }
    }, []);

    const handleEndDateChange = useCallback((date: Date | null): void => {
        if (date) {
            setEndDate(date?.valueOf());
            setEndDateValid(true);
        }
    }, []);

    const editContract = () => {
        const isValidData = validateData();
        if (isValidData) {
            dispatch(editSingleContract(getRequestBody()));
            setEditClick(true);
        } else {
            toast.error(t(tokens.contracts.fillAllNeededData))
        }
    };

    const validateData = () => {
        const data = [
            { field: type, setField: setTypeValid },
            { field: comment, setField: setCommentValid },
            { field: startDate, setField: setStartDateValid },
            { field: endDate, setField: setEndDateValid },
            { field: files.length, setField: setFileValid },
        ];
        let allFieldsValid = true;
        for (const { field, setField } of data) {
            if (!field) {
                setField(false);
                allFieldsValid = false;
            }
        };
        return allFieldsValid;
    };

    const getRequestBody = () => {
        const body: any = { document_id: Number(contractId), type, comment, date_start: startDate, date_end: endDate };
        const newFiles = files.filter(file => file instanceof File);
        if (newFiles.length) body.files = newFiles;
        //if (title) body.title = title;
        return body;
    };

    return {
        type, handleChangeType, typeOptions, title, onChangeTitle, typeValid, commentValid, comment, onChangeComment, editContract,
        handleFileChange, watchCurrentFile, handleDeleteFile, files, startDate, endDate, handleStartDateChange, handleEndDateChange,
        startDateValid, endDateValid, fileValid
    }
};