import { useMemo, type FC } from 'react';
import { TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../../locales/tokens';
import { getStyles } from './styles';

export const TableExpenseItemHead: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);

    return (
        <TableHead>
            <TableRow >
                <TableCell width='30%' align="left" sx={styles.cell}>
                    <Typography variant='h6'>{t(tokens.account.name)}</Typography>
                </TableCell>
                <TableCell width='30%' align="left" sx={styles.cell}>
                    <Typography variant='h6'>{t(tokens.account.shortName)}</Typography>
                </TableCell>
                <TableCell width='30%' align="center">
                    <Typography variant='h6'>{t(tokens.account.status)}</Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    );
};
