import { Box, Button, IconButton, Stack, TextField, Theme, Typography, useMediaQuery } from "@mui/material"
import { ChangeEvent, FC } from "react";
import { getStyles } from "./styles";
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from "react-i18next";
import { tokens } from "../../locales/tokens";
import { useGetPermissions } from "../../hooks/useGetPermission";

interface IProps {
    title: string;
    message: string;
    icon: React.ReactNode;
    files: File[] | string[] | [];
    watchCurrentFile: (name: string) => void;
    handleDeleteFile: (name: string) => void;
    handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const UploadConfirmFile: FC<IProps> = ({ title, message, icon, files, handleFileChange, handleDeleteFile, watchCurrentFile }) => {
    const { t } = useTranslation();
    const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const styles = getStyles();
    const { isConfirm_Payment } = useGetPermissions();

    return (
        <Stack spacing={1} sx={{ width: '100%' }}>
            <Typography color='primary' variant={downMd ? 'body1' : 'h6'}>
                {title}
            </Typography>
            <Button
                component="label"
                variant="contained"
                startIcon={icon}
                sx={styles.uploadBtn}
                disabled={!isConfirm_Payment}
            >
                {t(tokens.payment.uploadBill)}
                <TextField type="file" onChange={handleFileChange} sx={styles.inputFile} />
            </Button>
            <Typography color='GrayText' variant='caption'>{message}</Typography>
            {files.map((file: any, index) => {
                return (
                    <Box key={index}>
                        {file instanceof File ?
                            <Stack direction='row' alignItems='center' spacing={1}>
                                <Typography key={index}>{file.name}</Typography>
                                <IconButton onClick={() => handleDeleteFile(file.name)}><ClearIcon fontSize="small" /></IconButton>
                            </Stack>
                            :
                            <Stack direction='row' alignItems='flex-start' spacing={1}>
                                <Typography key={index} onClick={() => watchCurrentFile(file.name)} sx={{ cursor: 'pointer' }}>{file.name}</Typography>
                                <IconButton onClick={() => handleDeleteFile(file.name)}><ClearIcon fontSize="small" /></IconButton>
                            </Stack>
                        }
                    </Box>
                )
            })}
        </Stack>
    )
};