export const FILTER_TABS_FIXED_ASSETS = [
    { label: "Основні засоби", value: "one" },
    { label: "Малооцінка", value: "two" },
    { label: "Стандартизація", value: "three" },
    { label: "Аналітика", value: "four" }
];

export const SEARCH_NAME_FIXED_ASSETS = [
    { label: "Назва", value: "name" },
    { label: "Інвентарізаційний номер", value: "inventory_number" },
    { label: "Cерійний номер", value: "serial_number" }
];