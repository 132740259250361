import { IconButton, Stack, SvgIcon, Typography } from "@mui/material"
import { FC } from "react"
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import AttachFileIcon from '@mui/icons-material/AttachFile';

interface IProps {
    files: any[];
    watchCurrentFile: (name: string) => void;
};

export const FileField: FC<IProps> = ({ files, watchCurrentFile }) => {
    const { t } = useTranslation();

    return (
        <Stack display='flex' direction='row' spacing={2} alignItems='flex-start' mt={2} pr={1}>
            <Stack flex={1} direction='row' justifyContent='flex-end' alignItems='center' pt={1}>
                <Typography>{t(tokens.payment.file)}</Typography>
            </Stack>
            <Stack flex={3}>
                {files.map((file: any, index: number) =>
                    <Stack direction='row' alignItems='center' my={1}>
                        <IconButton sx={{ p: 0 }}>
                            <SvgIcon>
                                <AttachFileIcon/>
                            </SvgIcon>
                        </IconButton>
                        <Typography
                            key={index}
                            component='div'
                            onClick={() => watchCurrentFile(file.path)}
                            sx={{ cursor: 'pointer' }}
                        >
                            {file.name}
                        </Typography>
                    </Stack>
                )}
            </Stack>
        </Stack>
    )
};