import { Box, Collapse, Table, TableCell, TableRow } from "@mui/material";
import { FC } from "react";
import { PaymentsTableHead } from "../paymentsTableHead";
import { PaymentsTableBody } from "../paymentsTableBody";

interface IProps {
    isItemOpen: boolean;
    importItem: any;
    rowId: number;
    uploadPayments: any[];
};

export const PaymentsTable: FC<IProps> = ({ importItem, isItemOpen, rowId, uploadPayments }) => {

    return (
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                <Collapse in={isItemOpen && importItem.id === rowId} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                        <Table size="small" aria-label="purchases">
                            <PaymentsTableHead />
                            <PaymentsTableBody uploadPayments={uploadPayments} />
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    )
};