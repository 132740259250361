import { Box, Link } from "@mui/material";
import { FC } from "react";
import { getStyles } from "./styles";
import { tokens } from "../../../../../../locales/tokens";
import { useTranslation } from "react-i18next";

export const RecoverPassButton: FC = () => {
    const styles = getStyles();
    const { t } = useTranslation();

    return (
        <Box sx={styles.forgotPassword} >
            <Link
                href='/forgot-password'
                underline="hover"
                variant="subtitle2"
                color="#676767"
            >
                {t(tokens.auth.forgotPass)}
            </Link>
        </Box>
    )
}