import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDebounce } from "../../../hooks/useDebounce";
import { PER_PAGE } from "../../../config";
import { useDispatch, useSelector } from "react-redux";
import { selectPayments } from "../../../store/paymentCalendar/repository/selector";
import { getPayments } from "../../../store/paymentCalendar/useCases/getPayments/action";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export const useDocumentVerifiedPayments = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const [filters, setFilters] = useState<{ offset: number, limit: number, search: string }>({ offset: 0, limit: PER_PAGE, search: '' });
    const { payments, isLoading, getPaymentsListError, paymentsCount } = useSelector(selectPayments);
    const [isProcess, setProcess] = useState(false);
    const [pageAmount, setPageAmount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isInitialized, setInitialized] = useState(false);

    useEffect(()=>{
        dispatch(getPayments({
            body: { offset: filters.offset, limit: filters.limit, search: filters.search, isConfirmWithoutFiles: true },
            mode: "Payment"
        }));
        setProcess(true);
    }, [filters]);

    useEffect(() => {
        if (paymentsCount) {
            setPageAmount(Math.ceil(paymentsCount / filters.limit));
        }
    }, [paymentsCount]);

    useEffect(() => {
        debouncedHandleSeacrh(searchText);
    }, [searchText]);
    
    useEffect(() => {
        if (!isLoading && isProcess) {
            if (getPaymentsListError) {
                toast.error(getPaymentsListError);
            } 
            setTimeout(()=>setProcess(false), 300);
        }
    }, [getPaymentsListError, isLoading]);

    const handleSearchText = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const onSearchPayments = (value: string) => {
        if(isInitialized){
            setFilters((prevState) => ({ ...prevState, offset: 0, limit: PER_PAGE, search: value }));
            setCurrentPage(1);
        };
        setInitialized(true);
    };

    const navigateToPayment = (id: string) => {
        navigate(`/payment-edit/${id}`);
    };

    const { debouncedWrapper: debouncedHandleSeacrh } = useDebounce(onSearchPayments, 500);

    const handlePageChange = useCallback((event: ChangeEvent<unknown>, page: number): void => {
        setCurrentPage(page);
        const limit = page === 1 ? PER_PAGE : filters.limit + PER_PAGE;
        setFilters((prevState) => ({ ...prevState, offset: (page - 1) * PER_PAGE, limit: limit }));
    }, [setFilters]);
    
    return { searchText, handleSearchText, payments, isProcess, filters, navigateToPayment, pageAmount, handlePageChange, currentPage }
};