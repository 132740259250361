import { FC } from "react";
import { Button, Stack, SvgIcon, Theme, Typography, useMediaQuery } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { getStyles } from "./styles";
import { tokens } from "../../../../../../locales/tokens";
import { useTranslation } from "react-i18next";

interface IProps {
    updateUsers: () => void;
};

export const Header: FC<IProps> = ({ updateUsers }) => {
    const { t } = useTranslation();
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));  
    const styles = getStyles();

    return (
        <Stack direction={downSm ? "column" : "row"} sx={styles.header}>
            <Typography variant="h4" textAlign='center'>
                {t(tokens.user.users)}
            </Typography>
            <Stack direction="row" spacing={1} mt={downSm ? 2 : 0}>
                <Button
                    size="small"
                    variant="contained"
                    sx={styles.createBtn}
                    onClick={updateUsers}
                >
                    {t(tokens.user.updateUsers)}
                </Button>
                <Button
                    startIcon={(<SvgIcon><AddIcon /></SvgIcon>)}
                    variant="contained"
                    sx={styles.createBtn}
                    size="small"
                >
                    {t(tokens.user.createUser)}
                </Button>
            </Stack>
        </Stack>
    )
};