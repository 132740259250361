import { InputAdornment, MenuItem, Select } from '@mui/material';
import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';

const CURRENCY = [
    { value: "UAH", label: "UAH" },
    { value: "USD", label: "USD" }
];

export const CurrencyInputProps: FC = () => (
    <InputAdornment position="end">
        <Select onChange={() => { }} style={{ background: "none", border: "none", boxShadow: "none" }} sx={{
            ".MuiSelect-select": {
                display: "flex",
                alignItems: "center",
                color: "heatherField.main"
            },
            "fieldset": {
                background: "none !important", border: "none !important", boxShadow: "none !important"
            }
        }} displayEmpty defaultValue={CURRENCY[0].value} >
            {CURRENCY.map(({ value, label }) => (
                <MenuItem key={uuidv4()} value={value}>{label}</MenuItem>
            ))}
        </Select>
    </InputAdornment>
);