import { Checkbox, TableCell, TableRow, Typography } from "@mui/material";
import { ChangeEvent, FC, useCallback } from "react";
import { INomeclature, NOMENCLATURE_TYPE } from "../../../../../store/nomenclature/repository/types";
import { useGetPermissions } from "../../../../../hooks/useGetPermission";

interface IProps {
    nomenclature: INomeclature;
    handleUpdateNomenclature: (id: number, isActive: boolean) => void;
};

export const NomenclatureTableItem: FC<IProps> = ({ nomenclature, handleUpdateNomenclature }) => {
    const updateNomenclature = useCallback((e: ChangeEvent<HTMLInputElement>) =>
        handleUpdateNomenclature(nomenclature.id, !!nomenclature.service_receiving_id), [nomenclature.id, nomenclature.service_receiving_id]);
    const { isEdit_Nomenclature } = useGetPermissions();

    return (
        <TableRow key={nomenclature.id}>
            <TableCell align="center" >
                <Typography variant="subtitle2">
                    {nomenclature.code}
                </Typography>
            </TableCell>
            <TableCell align="left" sx={{ pl: "55px" }}>
                <Typography variant="subtitle2">
                    {nomenclature.name}
                </Typography>
            </TableCell>
            <TableCell align="center" >
                <Typography variant="subtitle2">
                    {nomenclature.article ? nomenclature.article : "-"}
                </Typography>
            </TableCell>
            <TableCell align="center">
                <Typography variant="subtitle2">
                    {nomenclature.type}
                </Typography>
            </TableCell>
            <TableCell align="center" >
                {nomenclature.type === NOMENCLATURE_TYPE.PRODUCT ?
                    '-'
                    : <Checkbox 
                    checked={!!nomenclature.service_receiving_id} 
                    onChange={updateNomenclature} 
                    disabled={!isEdit_Nomenclature}
                    />
                }
            </TableCell>
        </TableRow>
    )
};