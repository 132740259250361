import { Stack } from "@mui/material";
import { FC } from "react";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../../store/auth/repository/selector";
import { ServiceTitle } from "./components/serviceTitle";
import { useInitService } from "../presenter/useInitService";
import { ServiceData } from "../../../paymentCalendar/payment/ui/components/parts/serviceData/ui";
import { PaymentMethodSelector } from "../../../paymentCalendar/payment/ui/components/paymentMethodSelector";
import { PaymentData } from "../../../paymentCalendar/payment/ui/components/parts/paymentData/ui";
import { AddingPaymentButton } from "./components/addingPaymentButton";
import { ServiceProcessing } from "../../../paymentCalendar/payment/ui/components/parts/serviceProcessing/ui";
import { OperationHistory } from "./components/operationHistory";

const screenFlag = "SERVICE";

export const Service: FC = () => {
    const { user } = useSelector(selectAuth);
    const { currentTab, handleTabsChange, paymentMethodOptions, paymentMethod, mode, isLoading, handleTogglePaymentShown, isPaymentShown,
        singleService, serviceStatus, operationId } = useInitService();

    return (
        <Stack spacing={1} px={2} pb={4}>
            <Stack pt={2}>
                <ServiceTitle paymentId={operationId} status={serviceStatus} />
                <ServiceData paymentId={operationId} status={serviceStatus} screenFlag={screenFlag} source={singleService?.payment.source} />
                <AddingPaymentButton isPaymentShown={isPaymentShown} handleTogglePaymentShown={handleTogglePaymentShown} status={serviceStatus} />
                {(isPaymentShown && !singleService) && <PaymentMethodSelector tab={currentTab} tabOptions={paymentMethodOptions} handleTabsChange={handleTabsChange} />}
                {isPaymentShown && <PaymentData paymentMethod={paymentMethod} status={serviceStatus} modeFilter={mode} user={user} payment={singleService} screenFlag={screenFlag} />}
                <OperationHistory />
                <ServiceProcessing currentService={singleService} user={user} serviceStatus={serviceStatus} isPaymentShown={isPaymentShown} />
            </Stack>
        </Stack>
    )
};