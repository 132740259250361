import { TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import { getStyles } from "./styles";

export const PaymentsTableHead: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(()=> getStyles(), []);
    
    return (
        <TableHead sx={styles.thead}> 
            <TableRow>
                <TableCell width='20%'>
                    <Typography sx={styles.cell}>{t(tokens.importPayment.status)}</Typography>
                </TableCell>
                <TableCell align="center" width='20%'>
                    <Typography sx={styles.cell}>{t(tokens.importPayment.title)}</Typography>
                </TableCell>
                <TableCell align="center" width='20%'>
                    <Typography sx={styles.cell}>{t(tokens.importPayment.uploadDate)}</Typography>
                </TableCell>
                <TableCell align="center" width='20%'>
                    <Typography sx={styles.cell}>{t(tokens.importPayment.status)}</Typography>
                </TableCell>
                <TableCell align="right" width='20%'>
                    <Typography sx={styles.cell}>{t(tokens.importPayment.sum)}</Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    )
};