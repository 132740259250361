import { SagaIterator } from "redux-saga";
import { put, takeLatest, call } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";
import { setCreateDate, setInitiator } from "../../../paymentCreation/repository/slice";

function* passToPaymentWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.post, "/zoolux-api/budget-payments/transfer-to-payment/" + payload.id);
        if (isError) {
            toast.error(message);
        } else {
            toast.success("Платіж успішно переведено");
            yield put(setInitiator(data.payment.initiator));
            yield put(setCreateDate(data.payment.createdAt)); 
        }
    } catch (error) {
        console.error("passToPaymentWorker: ", error);
    } finally {
        
    }
};

export function* passToPaymentWatcher() {
    yield takeLatest("@saga/PASS_TO_PAYMENT", passToPaymentWorker);
};
