import { IError } from "../../../types/error";

interface IInitialState {
    tempSmsCode: number | null;
    isPhoneConfirmed: boolean | null;
    user: any;
    isLoading: boolean;
    isConfirmedSms: boolean;
}

const initialState: IInitialState = {
    tempSmsCode: null,
    isPhoneConfirmed: null,
    user: null,
    isLoading: false,
    isConfirmedSms: false,
};

export function authReducer(state: IInitialState = initialState, action: any): IInitialState {
    const { type, payload } = action;
    switch (type) {
        case 'SET_LOADING':
            return { ...state, isLoading: payload };
        case 'SET_VERIFY':
            return { ...state, isPhoneConfirmed: payload.isPhoneConfirmed };
        case 'LOGOUT':
            return { ...initialState };
        case 'SET_VERIFY':
            return { ...state, isPhoneConfirmed: payload.isPhoneConfirmed };
        case 'SET_CURRENT_USER':
            return { ...state, user: payload };
        case 'SET_CONFIRMED_SMS':
            return { ...state, isConfirmedSms: payload };
        
        default:
            return state;
    };
};

