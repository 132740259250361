import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";
import { getSingleUser } from "../getSingleUser/action";

function* setAvatarWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call( requester.post, "/zoolux-api/user/set-avatar", payload);
        if (isError) {
            // toast.error("аватар не встановлений")
        } else {
            yield put(getSingleUser(payload.id));
        }
    } catch (error) {
        console.error("setAvatarWorker: ", error);
    } finally {
        
    };
};

export function* setAvatarWatcher() {
    yield takeLatest("@saga/SET_AVATAR", setAvatarWorker);
};
