import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getSinglePayment } from "../../../../store/paymentCalendar/useCases/getSinglePayment/action";
import { selectPayments } from "../../../../store/paymentCalendar/repository/selector";
import toast from "react-hot-toast";
import { processPayment } from "../../../../store/paymentCalendar/useCases/processPayment/action";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { setSinglePayment } from "../../../../store/paymentCalendar/repository/actions";

export const usePaymentForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { paymentId } = useParams();
    const { singlePayment } = useSelector(selectPayments);
    const now = new Date().toISOString();
    const [currentPayment, setCurrentPayment] = useState<any>(null);

    useEffect(() => {
        dispatch(getSinglePayment({ body: { id: paymentId }, mode: 'Payment' }));
        return () => {
            dispatch(setSinglePayment(null));
        }
    }, [paymentId]);

    useEffect(()=>{
        if(singlePayment){
            setCurrentPayment(singlePayment.payment);
        }
    }, [singlePayment]);

    const handleCopy = (value: string) => {
        navigator.clipboard.writeText(value);
        toast.success(t(tokens.payment.copied));
    };

    const watchCurrentFile = (name: string) => {
        const fileUrl = name;
        window.open(fileUrl, '_blank');
    };

    const handlePayment = () => {
        dispatch(processPayment({ id: Number(paymentId) }));
        setTimeout(() => {
            navigate(`/payment-edit/${paymentId}`);
        }, 1000);
    };

    const handleCancel = useCallback(() => {
        navigate(`/payment-edit/${paymentId}`);
    }, []);

    return { currentPayment, handleCopy, watchCurrentFile, handlePayment, handleCancel, now }
};