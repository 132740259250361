import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';

interface IState {
  isLoading: boolean,
  contracts: any[],
  contractsCount: number,
  singleContract: any;
  updateContractError: IError;
  createContractError: IError;
  deleteContractError: IError;
  partnerName: string;
};

const initialState: IState = {
  isLoading: false,
  contracts: [],
  contractsCount: 0,
  singleContract: null,
  updateContractError: null,
  createContractError: null,
  deleteContractError: null,
  partnerName: ''
};

const ContractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setContracts: (state, action: PayloadAction<any>) => {
      state.contracts = action.payload.list;
      state.contractsCount = action.payload.count;
      state.partnerName = action.payload.partnerName;
    },
    setSingleContract: (state, action: PayloadAction<any>) => {
      state.singleContract = action.payload;
    },
    setUpdateContractError: (state, action: PayloadAction<IError>) => {
      state.updateContractError = action.payload;
    },
    setCreateContractError: (state, action: PayloadAction<IError>) => {
      state.createContractError = action.payload;
    },
    setDeleteContractError: (state, action: PayloadAction<IError>) => {
      state.deleteContractError = action.payload;
    },
  },
});

export const { setLoading, setContracts, setSingleContract, setUpdateContractError, setCreateContractError, setDeleteContractError } = ContractSlice.actions;
export const ContractReducer = ContractSlice.reducer;