import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { tokens } from "../../../../locales/tokens";
import { SelectChangeEvent } from "@mui/material";
import { updateExpenseItem } from "../../../../store/expenseItem/useCases/updateExpenseItem/action";
import { selectExpenseItem } from "../../../../store/expenseItem/repository/selector";
import toast from "react-hot-toast";
import { getAllExpenseItems } from "../../../../store/expenseItem/useCases/getAllExpenseItems/action";

export const useExpenseItemEdit = (item: any, filters: any) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [nameValid, setNameValid] = useState(true);
    const [accountStatusList, setAccountStatusList] = useState([t(tokens.expenseItem.active), t(tokens.expenseItem.hidden)]);
    const [handleClick, setHandleClick] = useState(false);
    const [status, setStatus] = useState('');
    const { isLoading, error } = useSelector(selectExpenseItem);

    useEffect(() => {
        setName(item.name);
        specifyStatus(item.hidden);
    }, [item]);

    const specifyStatus = (hidden: boolean) => {
        if (hidden) {
            setStatus(t(tokens.expenseItem.hidden));
        } else {
            setStatus(t(tokens.expenseItem.active))
        }
    };

    useEffect(() => {
        if (!isLoading && handleClick) {
            if (error) {
                toast.error(error);
            } else {
                toast.success(t(tokens.account.accountUpdated));
                const offset = filters.page > 0 ? (filters.page - 1) * 40 : 0;
                // dispatch(getAllExpenseItems({ offset: offset, limit: 40, search: '' }));
            }
            setHandleClick(false);
        }
    }, [error, isLoading]); 

    const onChangeName = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setName(e.target.value);
        setNameValid(true);
    }, []);

    const handleChangeStatus = useCallback((event: SelectChangeEvent) => {
        setStatus(event.target.value);
    }, []);

    const handleEdit = () => {
        const isStatus = status === t(tokens.account.active) ? false : true;
        const body: any = { id: item.id, hidden: isStatus, name: name };
        dispatch(updateExpenseItem(body));
        setHandleClick(true);
    };

    return { name, onChangeName, nameValid, accountStatusList, handleChangeStatus, handleEdit, status }
};