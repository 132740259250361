import { TableBody } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { UsersTableItem } from "../usersTableItem";

interface IProps {
    selected: number[];
    users: any[];
    signInToUserAccount: (id: number) => void;
    handleToggleOne: (event: ChangeEvent<HTMLInputElement>, id: number) => void;
};

export const UsersTableBody: FC<IProps> = ({ users, selected, handleToggleOne, signInToUserAccount }) => {

    return (
        <TableBody>
            {users?.map(user => {
                const isSelected = selected.includes(user.id);

                return (
                    <UsersTableItem
                        isSelected={isSelected}
                        user={user}
                        key={user.id + user.phone}
                        signInToUserAccount={signInToUserAccount}
                        handleToggleOne={handleToggleOne}
                    />
                )
            })}
        </TableBody>
    )
};