import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import { FieldComment } from "../../../../../../ui-kit/fieldComment";
import { Box, Button, Divider, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import { TimeDatePicker } from "../../../../../../ui-kit/timePicker";
import { getStyles } from "./styles";
import { UserAutocomplete } from "../../../../../../ui-kit/userAutocomplete/ui";
import { usePrePaymentForm } from "../../../presenters/usePrePaymentForm";
import { useSelector } from "react-redux";
import { selectPaymentCreation } from "../../../../../../store/paymentCreation/repository/selector";
import { PREPAYMENT_STATUS } from "../../../../../../config";

interface IProps {
    prePaymentId: string | undefined;
    status: string | undefined;
    dateValid: any;
    userValid: boolean;
};

export const PrePaymentForm: FC<IProps> = ({ prePaymentId, status, userValid, dateValid }) => {
    const { t } = useTranslation();
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const styles = useMemo(() => getStyles(), []);
    const { onChangeComment, handleDateChange, handleChangeUser, receiveProduct } = usePrePaymentForm(prePaymentId);
    const { responsible, prepaymentComment, prepaymentDate } = useSelector(selectPaymentCreation);
    const { prepaymentStatus } = useSelector(selectPaymentCreation);

    return (
        <>
            <Divider><Typography variant="subtitle2" pt={2}>Передплата</Typography></Divider>
            <FieldComment
                title={t(tokens.payment.prepaymentComment)}
                comment={prepaymentComment}
                onChangeComment={onChangeComment}
                isValid={true}
            />
            <Stack direction='row' spacing={3} alignItems='center'>
                <UserAutocomplete
                    isFinancier={false}
                    title={t(tokens.payment.responsible)}
                    value={responsible}
                    handleChangeValue={handleChangeUser}
                    isValid={userValid}
                />
                {!downSm ? <Box flex={1} /> : null}
            </Stack>
            <Stack direction={downSm? 'column': 'row'} spacing={3} alignItems='flex-end'>
                <TimeDatePicker
                    value={prepaymentDate}
                    isValid={dateValid}
                    isdisabledPast={true}
                    title={t(tokens.payment.getProductOnDate)}
                    handleDate={handleDateChange}
                />
                <Button
                    sx={styles.formButton}
                    fullWidth
                    variant='contained'
                    onClick={receiveProduct}
                    disabled={
                        prepaymentStatus === PREPAYMENT_STATUS.DONE || !status
                    }
                >
                    {t(tokens.payment.receiveProduct)}
                </Button>
            </Stack>
            <Divider sx={{mt: 2}}/>
        </>
    )
};