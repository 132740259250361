import { FC } from "react";
import { useUserEdit } from "../presenters/useUserEdit";
import { Box, Card, CardContent, Divider, Stack, Typography } from "@mui/material";
import { UserCard } from "./components/userCard";
import { getStyles } from "./styles";
import { GirlImage } from "./components/girlImage";
import { UserInfo } from "./components/userInfo";
import { RoleTab } from "./components/roleTab";
import { StatusTab } from "./components/statusTab";
import { tokens } from "../../../../locales/tokens";
import { useTranslation } from "react-i18next";

export const UserEdit: FC = () => {
    const { t } = useTranslation();
    const styles = getStyles();
    const { singleUser, role, roles, handleChangeRole, status, handleChangeStatus } = useUserEdit();

    return (
        <>
            {singleUser ?
                <Box>
                    <Stack direction='row' spacing={3} mt={5}>
                        <GirlImage />
                        <UserCard user={singleUser} />
                    </Stack>
                    <UserInfo singleUser={singleUser} />
                    <Card elevation={6} sx={styles.card}>
                        <CardContent>
                            <Typography variant="h5">{t(tokens.user.changeData)}</Typography>
                            <Stack divider={<Divider />} spacing={3} mt={2}>
                                <RoleTab
                                    role={role}
                                    roles={roles}
                                    handleChangeRole={handleChangeRole}
                                />
                                <StatusTab 
                                    status={status}
                                    handleChangeStatus={handleChangeStatus}
                                />
                            </Stack>
                        </CardContent>
                    </Card>
                </Box> : null}
        </>
    )
};