import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";
import { setServiceList } from "../../repository/slice";
import { setServiceName } from "../../../paymentCreation/repository/slice";
import { RootState } from "../../../store";

function* getServiceListWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.get, "/zoolux-api/service-receiving", payload);
        if (isError) {
            toast.error(message);
        } else {
            yield put(setServiceList({ list: data.rows, count: data.count }));
            const serviceName = yield select((state: RootState) => state.paymentCreation.serviceName);
            const defaultService = data.rows?.find((item: any) => item.is_default);
            if(!payload.search && !serviceName && defaultService){
                yield put(setServiceName({...defaultService, label: defaultService?.name}));
            }; 
        }
    } catch (error) {
        console.error("getServiceListWorker: ", error);
    }
};

export function* getServiceListWatcher() {
    yield takeLatest("@saga/GET_SERVICE_LIST", getServiceListWorker);
};
