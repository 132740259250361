import { Box, Pagination, Stack, Table } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { UsersTableHead } from "../usersTableHead";
import { UsersTableBody } from "../usersTableBody";
import { BulkActions } from "../bulkActions";

interface IProps {
    usersCount: number;
    selected: number[];
    users: any[];
    currentPage: number;
    signInToUserAccount: (id: number) => void;
    handlePageChange: (event: ChangeEvent<unknown>, page: number) => void;
    handleToggleAll: (event: ChangeEvent<HTMLInputElement>) => void;
    handleToggleOne: (event: ChangeEvent<HTMLInputElement>, id: number) => void;
};

export const UsersTable: FC<IProps> = ({ users, usersCount, handlePageChange, handleToggleAll, selected, handleToggleOne, currentPage, signInToUserAccount }) => {
    const selectedAll = selected.length === users.length;
    const selectedSome = selected.length > 0 && selected.length < users.length;
    const enableBulkActions = selected.length > 0;

    return (
        <Box sx={{ position: 'relative', overflowX: 'auto'}}>
            {enableBulkActions &&
                <BulkActions
                    enableBulkActions={enableBulkActions}
                    selectedAll={selectedAll}
                    selectedSome={selectedSome}
                    handleToggleAll={handleToggleAll}
                />
            }
            <Table >
                {!enableBulkActions && 
                <UsersTableHead
                    selectedAll={selectedAll}
                    selectedSome={selectedSome}
                    handleToggleAll={handleToggleAll}
                />}
                <UsersTableBody
                    users={users}
                    selected={selected}
                    handleToggleOne={handleToggleOne}
                    signInToUserAccount={signInToUserAccount}
                />
            </Table>
            <Stack direction='row' justifyContent='center' mt={4}>
                <Pagination
                    count={usersCount || 1}
                    page={currentPage}
                    color="primary"
                    onChange={handlePageChange}
                />
            </Stack>
        </Box>
    )
}