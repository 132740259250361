import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPartners } from "../../../store/paymentCalendar/useCases/getPartners/action";
import { selectPayments } from "../../../store/paymentCalendar/repository/selector";
import { createFilterOptions } from "@mui/material";
import { useDebounce } from "../../../hooks/useDebounce";

export const usePartnerAutocomplete = (onPartnerChange: (value: any, index?: number) => void, index?: number) => {
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState(false);
    const [dialogValue, setDialogValue] = useState({ name: '', type: '' });
    const { partners } = useSelector(selectPayments); 

    useEffect(() => {
        dispatch(getPartners({ offset: 0, limit: 20, search: '' }));
    }, [dialogValue.type]);

    const handleClose = () => {
        setDialogValue({ name: '', type: '' });
        setOpen(false);
    };
 
    const handleOnChange = (event: any, newValue: any) => {
        if (typeof newValue === 'string') {
            setTimeout(() => {
                setOpen(true);
                setDialogValue({ name: newValue, type: '' });
            });
        } else if (newValue && newValue.inputValue) {
            setOpen(true);
            setDialogValue({ name: newValue.inputValue, type: '' });
        } else {
            onPartnerChange(newValue, index);
        };
    };

    const filter = createFilterOptions<any>();

    const filterPartnersOptions = (options: any[], params: any) => {
        const filtered = filter(options, params);
        if (params.inputValue !== '') {
            filtered.push({
                inputValue: params.inputValue,
                name: `Додати: "${params.inputValue}"`,
                type: ''
            });
        };
        return filtered;
    };
    
    const changePartnerText = (event: ChangeEvent<HTMLInputElement>) => {
        debouncedHandlePartnerSeacrh(event.target.value);
    };
    
    const onSearchPartner = (value: string) => {
        if (value.length) {
            dispatch(getPartners({ offset: 0, limit: 20, search: value }));
        } else {
            dispatch(getPartners({ offset: 0, limit: 20, search: '' }));
        }
    };

    const { debouncedWrapper: debouncedHandlePartnerSeacrh } = useDebounce(onSearchPartner, 400);
    
    return { isOpen, dialogValue, filterPartnersOptions, handleClose, handleOnChange, partners, setDialogValue, changePartnerText }
};