export const getStyles = () => {
    const styles = {
        status: { 
            borderRadius: '15px', 
            width: '100px',
            py: .5,
            fontSize: '12px',
            color: '#FFFFFF',
        },
        tableRow: {
            cursor: "pointer",
            textDecoration: 'none'
        }
    };
    return styles;
};