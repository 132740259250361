import { Button, Divider, IconButton, Tooltip } from "@mui/material"
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { FC } from "react";
import { useSelector } from "react-redux";
import { selectPaymentCreation } from "../../../../../../store/paymentCreation/repository/selector";

interface IProps {
    status: string;
    isPaymentShown: boolean;
    handleTogglePaymentShown: () => void;
};

export const AddingPaymentButton: FC<IProps> = ({ isPaymentShown, handleTogglePaymentShown, status }) => {
    const { t } = useTranslation();
    const { formStatus } = useSelector(selectPaymentCreation);
    const currentStatus = status || formStatus;

    return (
        <Divider sx={{ py: 2, backgroundColor: "#F6EFE1" }}>
            <Button
                variant="outlined"
                onClick={handleTogglePaymentShown}
                disabled={!!currentStatus}
                color="inherit"
                sx={{ px: 1.2 }}
                startIcon={isPaymentShown ?
                    <RemoveCircleIcon fontSize="large" />
                    : <AddCircleIcon fontSize="large" />
                }
            >
                {isPaymentShown ?
                    t(tokens.serviceHistory.cancelPaymentService) :
                    t(tokens.serviceHistory.addPaymentToService)
                }
            </Button>
        </Divider>
    )
};