import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { setRoles, setUsers } from "../../repository/actions";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";

function* getRolesWorker(): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call( requester.get, "/zoolux-api/roles");
        if (isError) {
            toast.error("ролі не знайдені")
        } else {
            yield put(setRoles(data));
        }
    } catch (error) {
        console.error("getRolesWorker: ", error);
    } finally {
        
    };
};

export function* getRolesWatcher() {
    yield takeLatest("@saga/GET_ROLES", getRolesWorker);
};
