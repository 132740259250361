import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setErrorsGetPayments, setLoading, setPayments } from "../../repository/actions";

function* getPaymentsWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const url = payload.mode === 'Payment' ? "/zoolux-api/payments/list" : '/zoolux-api/budget-payments/list';
        const { data, isError, message }: IResponse<any> = yield call(requester.get, url, payload.body);
        if (isError) {
            yield put(setErrorsGetPayments(message));
        } else {
            yield put(setErrorsGetPayments(null));
            yield put(setPayments({ list: data.data, count: data.total }));
        }
    } catch (error) {
        console.error("getPaymentsWorker: ", error);
    } finally {
        yield put(setLoading(false));
    }
};

export function* getPaymentsWatcher() {
    yield takeLatest("@saga/GET_PAYMENTS", getPaymentsWorker);
};
