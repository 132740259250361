import { Button, Dialog, IconButton, Stack, SvgIcon, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getStyles } from "./styles";
import { tokens } from "../../../../../../locales/tokens";
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch } from "react-redux";
import { setNotPutToEnot } from "../../../../../../store/paymentCreation/repository/slice";
import { setPaymentUpdated } from "../../../../../../store/operationValidation/repository/slice";

interface IProps {
    isOpen: boolean;
    handleToggleModal: () => void;
    handleModal: () => void;
};

export const ServiceModal: FC<IProps> = ({ isOpen, handleToggleModal, handleModal }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const styles = useMemo(() => getStyles(), []);

    const onSubmit = (isServiceHandled: boolean) => {
        if(isServiceHandled){
            dispatch(setNotPutToEnot(true));
            dispatch(setPaymentUpdated(true));
            handleToggleModal();
        } else {
            handleModal();
        }
    };

    return (
        <Dialog open={isOpen} maxWidth="md">
            <Stack p={3}>
                <IconButton onClick={handleToggleModal} sx={styles.clearIcon}>
                    <SvgIcon fontSize="small"><ClearIcon /></SvgIcon>
                </IconButton>
                <Typography variant="body1" fontWeight={600} mt={3}>
                    {t(tokens.service.message.capitalizeService)}
                </Typography>
                <Stack direction="row" spacing={1} mt={3} justifyContent="center">
                    <Button
                        variant="contained"
                        sx={styles.buttonConfirm}
                        onClick={()=>onSubmit(true)}
                    >
                        {t(tokens.common.yes)}
                    </Button>
                    <Button
                        variant="contained"
                        sx={styles.buttonCancel}
                        onClick={()=>onSubmit(false)}
                    >
                        {t(tokens.common.no)}
                    </Button>
                </Stack>
            </Stack>
        </Dialog>
    )
};