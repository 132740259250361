import { useDispatch, useSelector } from "react-redux";
import { Dispatch, useEffect, useState } from "react";
import { selectPayments } from "../../../../store/paymentCalendar/repository/selector";
import { getPayments } from "../../../../store/paymentCalendar/useCases/getPayments/action";
import { PAYMENT_STATUS } from "../../../../config";
import { DatesSetArg } from "@fullcalendar/common";

export const usePayments = (setPeriod: Dispatch<any>, status: string[], method: any[], mode: string, isDeleted: boolean, searchText: string, sortBy: string, 
    isConfirm: boolean, isPreferredFinancier: boolean) => {
    const dispatch = useDispatch();
    const { payments } = useSelector(selectPayments);
    const [paymentsList, setPaymentsList] = useState<any[]>([]);

    useEffect(() => {
            const newPayments = payments?.map(item => {
                const { payment_date, name, ...rest } = item;
                let color = '';

                switch (item.payment_method?.key) {
                    case 'cash':
                        color = '#F08080';
                        break;
                    case 'non-cash':
                        color = '#00CED1';
                        break;
                    default:
                        color = '#FFDAB9';
                        break;
                };

                let title = '';
                switch (item.status) {
                    case PAYMENT_STATUS.CREATED:
                        title = "🟦 " + item.name;
                        break;
                    case PAYMENT_STATUS.VISAED:
                        title = "🟨 " + item.name;
                        break;
                    case PAYMENT_STATUS.TRANSMITTED:
                        title = "🟧 " + item.name;
                        break;
                    case PAYMENT_STATUS.COMPLETED:
                        title = "🟪 " + item.name;
                        break;
                    case PAYMENT_STATUS.DELETED:
                        title = "🟥 " + item.name;
                        break;
                    default:
                        title = "🟩 " + item.name;
                        break;
                };

                return { date: payment_date, title, color, ...rest };
            });
            setPaymentsList(newPayments);
    }, [payments]);

    const handleDatesRender = (info: DatesSetArg) => {
        const startOfPeriod = getStartTime(info.view.currentStart.getTime());
        const endOfPeriod = getEndTime(info.view.currentEnd.getTime());

        setPeriod({ startTime: startOfPeriod, endTime: endOfPeriod });
        const body = combineRequestBody(startOfPeriod, endOfPeriod, searchText, sortBy);
        dispatch(getPayments({ body, mode }));
    };

    const getStartTime = (timestamp: number) => {
        const startDate = new Date(timestamp);
        startDate.setDate(startDate.getDate() - 15);
        return startDate.getTime(); 
    };

    const getEndTime = (timestamp: number) => {
        const endDate = new Date(timestamp);
        endDate.setDate(endDate.getDate() + 15);
        return endDate.getTime();
    };

    const combineRequestBody = (startOfPeriod: number, endOfPeriod: number, searchText: string, sortBy: string) => {
        const body: any = { offset: 0, limit: 1000, startTime: startOfPeriod, endTime: endOfPeriod, isDeleted, search: searchText };
        if (method.length) body.payment_method_id = method;
        if (status.length) body.status = status;
        if (searchText.length) body.search_name = sortBy;
        if (isConfirm) body.isConfirm = isConfirm;
        if (isPreferredFinancier) body.is_preferred_financier = isPreferredFinancier;
        return body;
    };

    return { paymentsList, handleDatesRender };
};