import { Button, Stack } from "@mui/material";
import { ChangeEvent, FC, useMemo } from "react";
import { getStyles } from "./styles";

interface IProps {
    tab: any;
    tabOptions: any[];
    handleTabsChange: (event: ChangeEvent<{}>, tab: string) => void;
};

export const PaymentMethodSelector: FC<IProps> = ({ tabOptions, handleTabsChange, tab }) => {
    const styles = useMemo(() => getStyles(), []);

    return (
        <Stack direction="row" spacing={2} width="100%" my={1}>
            {tabOptions
                .filter((item: any) => item.key !== 'report')
                .map(item => (
                    <Button
                        startIcon={<item.icon />}
                        key={item.key}
                        sx={tab === item.key?
                            styles.activeButton
                            :styles.button}
                        onClick={(e) => handleTabsChange(e, item.key)}
                    >
                        {item.name}
                    </Button>
                )
                )}
        </Stack>
    )
};