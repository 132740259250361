import { FC, useMemo } from "react";
import { TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getStyles } from "./styles";
import { CURRENCY } from "../../../../../../config";
import { tokens } from "../../../../../../locales/tokens";

interface IProps {
    account: any;
};

export const AccountListHead: FC<IProps> = ({ account }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);

    return (
        <TableHead>
            <TableRow>
                <TableCell style={styles.cell}>
                    {account?.name || t(tokens.paymentsList.noAccount)}
                </TableCell>
                <TableCell style={styles.cell} ></TableCell>
                <TableCell style={styles.cell} >
                    <Typography noWrap fontSize={14} fontWeight={600}>
                        {account.sum_amount_payment + CURRENCY.UAH}
                    </Typography>
                </TableCell>
                <TableCell style={styles.cell} ></TableCell>
                <TableCell style={styles.cell} ></TableCell>
                <TableCell style={styles.cell} ></TableCell>
                <TableCell style={styles.cell} ></TableCell>
                <TableCell style={styles.cell} >
                    <Typography noWrap fontSize={14} fontWeight={600}>
                        {account?.balance ?
                            account?.balance + CURRENCY.UAH : t(tokens.paymentsList.noBalance)
                        }
                    </Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    )
};