import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAccount } from "../../../../store/accounts/repository/selector";
import { getSingleAccount } from "../../../../store/accounts/useCases/getSingleAccount/action";
import { SelectChangeEvent } from "@mui/material";
import { useDebounce } from "../../../../hooks/useDebounce";
import { updateAccount } from "../../../../store/accounts/useCases/updateAccount/action";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { setSingleAccount } from "../../../../store/accounts/repository/slice";
import { getInputAccounts } from "../../../../store/accounts/useCases/getInputAccounts/action";
import { getAllAccounts } from "../../../../store/accounts/useCases/getAllAccounts/action";

export const useAccountEdit = (account: any, filters: any) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { singleAccount, inputAccounts, error, isLoading } = useSelector(selectAccount);
    const [name, setName] = useState('');
    const [nameValid, setNameValid] = useState(true);
    const [accountType, setAccountType] = useState('');
    const [accountTypeList, setAccountTypeList] = useState(['Безналичные', 'Наличные', 'Банковская карта']);
    const [accountStatusList, setAccountStatusList] = useState([t(tokens.account.active), t(tokens.account.hidden)]);
    const [parent, setParent] = useState<any | null>(null);
    const [accountsListSearch, setAccountsListSearch] = useState('');
    const [handleClick, setHandleClick] = useState(false);
    const [status, setStatus] = useState('');

    useEffect(() => {
        dispatch(getInputAccounts({ offset: 0, limit: 10, search: '' }));
    }, []);

    useEffect(() => {
            setName(account.name);
            setAccountType(account.account_type);
            specifyStatus(account.hidden);
            specifyParentAccount(account.parent);     
    }, [account]);

    const specifyStatus = (hidden: boolean) => {
        if(hidden){
            setStatus('Заблокований');
        } else{
            setStatus('Активний')
        }
    };

    const specifyParentAccount = (parent: any) => {
        if(parent){
            dispatch(getSingleAccount({id: parent.id}))
        } else {
            dispatch(setSingleAccount(null));
        }
    };

    useEffect(()=>{
        if(singleAccount){
            setParent({ ...singleAccount, label: singleAccount?.name });
        } else{
            setParent(null);
        }
    }, [singleAccount]);

    useEffect(() => {
        debouncedHandleAccountsSeacrh(accountsListSearch);
    }, [accountsListSearch]);

    useEffect(() => {
        if (!isLoading && handleClick) {
            if (error) {
                toast.error(error);
            } else {
                toast.success(t(tokens.account.accountUpdated));
                const offset = filters.page > 0 ? (filters.page - 1) * 40 : 0;
                dispatch(getAllAccounts({ offset: offset, limit: 40, search: '' }));
            }
            setHandleClick(false);
        }
    }, [error, isLoading]); 

    const onSearchAccounts = (value: any) => {
        if (value) {
            dispatch(getInputAccounts({ offset: 0, limit: 10, search: value }));
        } else {
            dispatch(getInputAccounts({ offset: 0, limit: 10, search: '' }));
        }
    };

    const { debouncedWrapper: debouncedHandleAccountsSeacrh } = useDebounce(onSearchAccounts, 400);

    const onChangeName = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setName(e.target.value);
        setNameValid(true);
    }, []);

    const handleChangeAccountType = useCallback((event: SelectChangeEvent) => {
        setAccountType(event.target.value);
    }, []);

    const handleChangeStatus = useCallback((event: SelectChangeEvent) => {
        setStatus(event.target.value);
    }, []);

    const onChangeMainAccount = useCallback((value: any | null) => {
        setParent(value);
    }, []);

    const changeCompanyAccountText = (event: ChangeEvent<HTMLInputElement>) => {
        setAccountsListSearch(event.target.value);
    };

    const handleEdit = () => {
        if (!name.length) {
            setNameValid(false);
            return;
        }
        const body: any = { id: account.id, account_type: accountType, name: name, parent_id: parent?.id || null };
        body.hidden = status === t(tokens.account.active)? false : true;
        dispatch(updateAccount(body));
        setHandleClick(true);
    };

    return {
        name, onChangeName, accountType, handleChangeAccountType, accountTypeList, inputAccounts, parent, onChangeMainAccount,
        changeCompanyAccountText, handleEdit, nameValid, accountStatusList, status, handleChangeStatus
    }
};