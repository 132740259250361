import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading } from "../../../accounts/repository/slice";
import { setDeleteRoleError } from "../../repository/actions";

function* deleteRoleWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, isError, message }: IResponse<any> = yield call(requester.delete, "/zoolux-api/roles", payload);
        if (isError) {
            yield put(setDeleteRoleError(message));
        } else {
            yield put(setDeleteRoleError(null));
        }
    } catch (error) {
        console.error("deleteRoleWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* deleteRoleWatcher() {
    yield takeLatest("@saga/DELETE_ROLE", deleteRoleWorker);
};
