import type { ChangeEvent, FC } from 'react';
import { Pagination, Stack, Table } from '@mui/material';
import { TableListHead } from '../tableListHead';
import { TableListBody } from '../tebleListBody';

interface IProps {
  accounts: any[];
  pageAmount: number;
  currentPage: number;
  handleAccountOpen: (partnerId: string) => void;
  handlePageChange: (event: ChangeEvent<unknown>, page: number) => void;
};

export const AccountListTable: FC<IProps> = ({ accounts, handleAccountOpen, handlePageChange, pageAmount, currentPage }) => {

  return (
    <>
      {accounts.length ?
        <div>
          <Table >
            <TableListHead />
            <TableListBody
              accounts={accounts}
              handleAccountOpen={handleAccountOpen}
            />
          </Table>
          <Stack direction='row' justifyContent='center' my={4}>
            <Pagination
              count={pageAmount || 1}
              page={currentPage || 1}
              color="primary"
              onChange={handlePageChange}
            />
          </Stack>
        </div> : null}
    </>
  );
};
