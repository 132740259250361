import { IconButton, SvgIcon } from "@mui/material";
import { FC } from "react";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { getStyles } from "./styles";

interface IProps {
    isPassShown: boolean; 
    onShowPassword: () => void;
}

export const VisibleButton: FC<IProps> = ({ isPassShown, onShowPassword }) => {
    const styles = getStyles();

    return (
        <IconButton sx={styles.visibleIcon} onClick={onShowPassword}>
            <SvgIcon>
                {isPassShown ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
            </SvgIcon>
        </IconButton>
    )
}