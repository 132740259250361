import { useSelector } from "react-redux";
import { selectAuth } from "../store/auth/repository/selector";
import { useMemo } from "react";
import { useGetPermissionsQuery } from '../store/permission';

export const useGetPermissions = () => {
    const { user } = useSelector(selectAuth);
    const { data: dataGetPermissions } = useGetPermissionsQuery(undefined, { skip: !user });

    const hasPermission = (permission: string) => {
        return user.permissions.some((item: any) => item.name === permission);
    };

    const permissions = useMemo(() => {
        const result: any = {};

        dataGetPermissions?.forEach((permissionItem: any) => {
            const permissionName = `is${permissionItem.name}`;
            const permissionValue = hasPermission(permissionItem.name);
            result[permissionName] = permissionValue;
        });

        return result;
    }, [user.permissions, dataGetPermissions]);

    return permissions;
};

const permissionsList = [
    { name: 'Find_All_Budget_Calendar', id: 1 },
    { name: 'Find_All_Cash_Budget_Calendar', id: 2 },
    { name: 'Find_All_Card_Budget_Calendar', id: 3 },
    { name: 'Find_All_Non_Cash_Budget_Calendar', id: 4 },
    { name: 'Delete_Budget_Calendar_All', id: 5 },
    { name: 'Update_Budget_Calendar', id: 6 },
    { name: 'Create_Clinic', id: 7 },
    { name: 'Find_All_Clinic', id: 8 },
    { name: 'Update_Clinic', id: 9 },
    { name: 'Create_Company_Account', id: 10 },
    { name: 'Find_One_Company_Account', id: 11 },
    { name: 'Update_Company_Account', id: 12 },
    { name: 'Find_All_Company_Account', id: 13 },
    { name: 'Create_Counterparty', id: 14 },
    { name: 'Find_One_Counterparty', id: 15 },
    { name: 'Update_Counterparty', id: 16 },
    { name: 'Find_All_Counterparties', id: 17 },
    { name: 'Find_All_Counterparty_Folder', id: 18 },
    { name: 'Refresh_Enote_Counterparty_Folder', id: 19 },
    { name: 'Create_Expenditure', id: 20 },
    { name: 'Find_One_Expenditure', id: 21 },
    { name: 'Update_Expenditure', id: 22 },
    { name: 'Find_All_Expenditure', id: 23 },
    { name: 'Get_Logging', id: 24 },
    { name: 'Set_Visa_Payment', id: 25 },
    { name: 'Set_Visa_Local_Payment', id: 26 },
    { name: 'Delete_Visa_Payment', id: 27 },
    { name: 'Delete_Visa_Local_Payment', id: 28 },
    { name: 'Process_Payment', id: 29 },
    { name: 'Process_Local_Payment', id: 30 },
    { name: 'Process_Cash_Payment', id: 31 },
    { name: 'Process_Card_Payment', id: 32 },
    { name: 'Process_Non_Cash_Payment', id: 33 },
    { name: 'Find_All_Payment', id: 34 },
    { name: 'Find_All_Cash_Payment', id: 35 },
    { name: 'Find_All_Card_Payment', id: 36 },
    { name: 'Find_All_Non_Cash_Payment', id: 37 },
    { name: 'Delete_Payment', id: 38 },
    { name: 'Delete_Local_Payment', id: 39 },
    { name: 'Update_Payment', id: 40 },
    { name: 'Transfer_To_Payment_Budget_Calendar', id: 41 },
    { name: 'Create_Payment_Kind', id: 42 },
    { name: 'Update_Payment_Kind', id: 43 },
    { name: 'Remove_Payment_Kind', id: 44 },
    { name: 'Create_Payment_Method', id: 45 },
    { name: 'Update_Payment_Method', id: 46 },
    { name: 'Remove_Payment_Method', id: 47 },
    { name: 'Create_Payment_Type', id: 48 },
    { name: 'Update_Payment_Type', id: 49 },
    { name: 'Remove_Payment_Type', id: 50 },
    { name: 'Change_Status_Prepayment', id: 51 },
    { name: 'Find_One_User', id: 52 },
    { name: 'Find_All_Users', id: 53 },
    { name: 'Create_Role', id: 54 },
    { name: 'Get_All_Role', id: 55 },
    { name: 'Update_Role', id: 56 },
    { name: 'Delete_Role', id: 57 },
    { name: 'Get_All_Permission', id: 58 },
    { name: 'Update_Role_Permission', id: 59 },
    { name: 'Create_User_Role', id: 60 },
    { name: 'Delete_User_Role', id: 61 },
    { name: 'Create_User_Permission', id: 62 },
    { name: 'Delete_User_Permission', id: 63 },
    { name: 'Get_List_Day_Payment', id: 64 },
    { name: 'Get_List_Prepayment', id: 65 },
    { name: 'Create_Import_Payment', id: 66 },
    { name: 'Get_All_Import_Payment', id: 67 },
    { name: 'Get_List_Operacionist_Payment', id: 68 },
    { name: 'Get_List_Financier_Payment', id: 69 },
    { name: "Write_Service_Receiving", id: 72 },
    { name: "Edit_Telegram", id: 100 },
    { name: "Read_Telegram", id: 77 },
    { name: 'Read_Service_Receiving', id: 73 },
    { name: 'Completed_Budget_Calendar', id: 74 },
    { name: 'Confirm_Budget_Calendar', id: 75 },
    { name: 'Confirm_Payment', id: 76 },
    { name: 'Create_In_Enote', id: 77 },
    { name: "Read_Nomenclature", id: 96 },
    { name: "Edit_Nomenclature", id: 97 },
    { name: 'Read_Configurations', id: 98 },
];