import { Stack } from "@mui/material";
import { SideNavItem } from "../layouts/dashboard/vertical-layout/components/side-nav-item/side-nav-item";
import { SectionItem } from "../types/settings";

export const renderItems = ({ depth = 0, items, pathname }: { depth?: number; items: SectionItem[]; pathname?: string | null; }): JSX.Element[] => items.reduce(
    (acc: JSX.Element[], item) => reduceChildRoutes({ acc, depth, item, pathname }), []);

const reduceChildRoutes = ({ acc, depth, item, pathname }: { acc: JSX.Element[]; depth: number; item: SectionItem; pathname?: string | null; }): Array<JSX.Element> => {
    const checkPath = !!(item.path && pathname);
    const partialMatch = checkPath ? pathname.includes(item.path!) : false;
    const exactMatch = checkPath ? pathname === item.path : false;

    if (item.items) {
        acc.push(
            <SideNavItem active={partialMatch} depth={depth} disabled={item.disabled} icon={item.icon} key={item.title} open={partialMatch} label={item.label} path={item.path} title={item.title}>
                <Stack component="ul" spacing={0.5} sx={{ listStyle: 'none', m: 0, p: 0 }} >
                    {renderItems({ depth: depth + 1, items: item.items, pathname })}
                </Stack>
            </SideNavItem>
        );
    } else {
        acc.push(
            <SideNavItem key={item.title} active={exactMatch} depth={depth} disabled={item.disabled} icon={item.icon} label={item.label} path={item.path} title={item.title} />
        );
    }
   
    return acc;
};