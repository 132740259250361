
export const getStyles = () => {
    const styles = {
        buttonCancel: { 
            color: "#FFFFFF", 
            backgroundColor: '#D74444' ,
            "&:hover": {
                backgroundColor: "#b81414"
            }
        },
        buttonConfirm: { 
            color: "#FFFFFF" 
        },
        clearIcon: { 
            position: 'absolute', 
            right: '10px', 
            top: '7px', 
            backgroundColor: '#F1F3F4',
            '&:hover': {
                backgroundColor: '#C6C7C2', 
            } 
        }
    };
    return styles;
};