import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import { getStyles } from './styles';
import { PaymentsTableHead } from '../paymentsTableHead';
import { Data, HeadCell } from '../../../presenters/types';
import { FC } from 'react';
import { PaymentsTableBody } from '../paymentsTableBody';
import { IWeekFilters } from '../../../../paymentList/presenters/types';

interface IProps {
    weekFilters: IWeekFilters;
    selected: readonly number[];
    headTitles: HeadCell[];
    payments: any[];
    handleClick: (event: React.MouseEvent<unknown>, id: number) => void;
    handleRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
};

export const PaymentsTable: FC<IProps> = ({ headTitles, handleClick, handleRequestSort, weekFilters, selected, payments }) => {
    const styles = React.useMemo(() => getStyles(), []);

    return (
        <Box sx={styles.container}>
            <TableContainer>
                <Table sx={styles.table} size='medium'>
                    <PaymentsTableHead
                        headTitles={headTitles}
                        numSelected={selected.length}
                        order={weekFilters.order}
                        orderBy={weekFilters.orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <PaymentsTableBody
                        selected={selected}
                        handleClick={handleClick}
                        payments={payments}
                    />
                </Table>
            </TableContainer>
        </Box>
    );
};