import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { setPermissions, setRoles, setUsers } from "../../repository/actions";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";

function* getPermissionsWorker(): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call( requester.get, "/zoolux-api/permission");
        if (isError) {
            toast.error("даннi не знайдені")
        } else {
            yield put(setPermissions(data));
        }
    } catch (error) {
        console.error("getPermissionsWorker: ", error);
    } finally {
        
    };
};

export function* getPermissionsWatcher() {
    yield takeLatest("@saga/GET_PERMISSIONS", getPermissionsWorker);
};
