import { Button, ButtonGroup, Stack, TextField } from "@mui/material";
import { FC, useMemo } from "react";
import { DateRangeField } from "../dateRangeField";
import { getStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";

interface IProps {
    currentDay: number;
    startDate: string;
    endDate: string;
    handleListDate: (id: number) => void;
};

export const ListDayToolbar: FC<IProps> = ({ handleListDate, endDate, startDate, currentDay }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);

    const dateOptions: any = [
        { id: 1, title: t(tokens.paymentToolbar.one)},
        { id: 2, title: t(tokens.paymentToolbar.two) },
        { id: 3, title: t(tokens.paymentToolbar.three) },
        { id: 4, title: t(tokens.paymentToolbar.four) },
        { id: 5, title: t(tokens.paymentToolbar.five) },
        { id: 6, title: t(tokens.paymentToolbar.six) },
        { id: 7, title: t(tokens.paymentToolbar.seven)},
    ];

    return (
        <Stack alignItems="flex-start" justifyContent="flex-start" spacing={1} >
            <DateRangeField startDate={startDate} endDate={endDate} />
            <ButtonGroup variant="outlined" aria-label="button group">
                {dateOptions.map((date: any) =>
                    <Button
                        onClick={() => handleListDate(date.id)}
                        sx={{ ...styles.dateOption, backgroundColor: date.id === currentDay ? '#FEF49A' : 'primary' }}
                    >
                        {date.title}
                    </Button>
                )}
            </ButtonGroup>
        </Stack>
    )
};