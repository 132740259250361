import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";
import { setServicePaymentId } from "../../../paymentCreation/repository/slice";
import { createServiceRelated } from "../../../serviceRelated/useCases/createServiceRelated/action";

function* linkPaymentToServiceWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.post, "/zoolux-api/receipt-goods-services/create", payload.body);
        if (isError) {
            toast.error(message);
        } else {
            yield put(setServicePaymentId(data.id));
            if(payload.relatedService?.length > 0){
                yield put(createServiceRelated({
                    receipt_goods_service_id: data.id,
                    data: payload.relatedService
                }));
            };
        }
    } catch (error) {
        console.error("linkPaymentToServiceWorker: ", error);
    }
};

export function* linkPaymentToServiceWatcher() {
    yield takeLatest("@saga/LINK_PAYMENT_TO_SERVICE", linkPaymentToServiceWorker);
};
