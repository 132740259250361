export const getStyles = () => {
    const styles = {
        uploadBtn: { 
            color: '#FFFFFF',
            height: '40px',  
            backgroundColor: '#5798fa',
            ':hover': {
                backgroundColor: '#2358ba',
            }
        },
        inputFile: {
            color: '#FFFFFF',
            clip: 'rect(0 0 0 0)',
            clipPath: 'inset(50%)',
            height: 1,
            overflow: 'hidden',
            position: 'absolute',
            bottom: 0,
            left: 0,
            whiteSpace: 'nowrap',
            width: 1,
        }
    };
    return styles;
}