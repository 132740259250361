export const styles = {
    copyStack: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    copyBtn: {
        position: 'relative',
        left: 12,
        height: '35px'
    },
    updateCodeBtn: {
        px: 3,
        height: '40px',
        color: '#ffffff',
        textTransform: 'none'
    },
    secretCode: { 
        borderRadius: 0, 
        height: '40px',
    }
}