import { ChangeEvent, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCommentForService, setResetRelatedServices, setResetRelatedServicesValid, setServiceAmount, setServiceCompletionDate, setServiceExpenseItem, setServiceName, setServicePartner } from "../../../../../../../../store/paymentCreation/repository/slice";
import { selectPaymentCreation } from "../../../../../../../../store/paymentCreation/repository/selector";
import { selectPayments } from "../../../../../../../../store/paymentCalendar/repository/selector";
import { selectOperationValidation } from "../../../../../../../../store/operationValidation/repository/selector";
import { setPaymentFieldValidate, setPaymentUpdated } from "../../../../../../../../store/operationValidation/repository/slice";
import { IExpenditureOption, IPartnerOption } from "../../../../../../../../utils/collectOptionList";
import { TScreenFlag } from "../../paymentData/presenter/useServicePayment";

export const useServiceData = (screenFlag: TScreenFlag, status: string, source: string | undefined) => {
    const dispatch = useDispatch();
    const { paymentType, formStatus, isNotPutToEnot, serviceSource } = useSelector(selectPaymentCreation);
    const { paymentTypes } = useSelector(selectPayments);
    const { payment } = useSelector(selectOperationValidation);
    const currentPaymentType = paymentTypes.find(item => item.id === paymentType);
    const currentStatus = formStatus || status;

    const isDisabled = screenFlag === "PAYMENT" && currentPaymentType?.key === 'service_receiving';
    const isDisabledFromPayment = source === "SERVICE" || screenFlag === "SERVICE";
    const isDisabledAfterVisa = currentStatus === "VISAED" && isNotPutToEnot;

    useEffect(() => {
        if (paymentType) {
            if (currentPaymentType?.key === 'payments_suppliers') {
                dispatch(setResetRelatedServices([]));
                dispatch(setResetRelatedServicesValid([]));
            }
        }
    }, [paymentType]); 

    const handleChangeServiceName = useCallback((value: any) => {
        dispatch(setServiceName(value));
        dispatch(setPaymentFieldValidate('serviceName'));
        dispatch(setPaymentUpdated(true));
    }, []);

    const handleServiceCompletionDate = useCallback((date: Date | null): void => {
        if (date) {
            dispatch(setServiceCompletionDate(date?.valueOf()));
            dispatch(setPaymentFieldValidate('serviceCompletionDate'));
            dispatch(setPaymentUpdated(true));
        }
    }, []);

    const onChangeCommentForService = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        dispatch(setCommentForService(event.target.value));
        dispatch(setPaymentFieldValidate('commentForService'));
        dispatch(setPaymentUpdated(true));
    }, []);

    const handleChangeServicePartner = useCallback(((value: IPartnerOption | null) => {
        dispatch(setServicePartner(value));
        dispatch(setPaymentFieldValidate('servicePartner'));
        dispatch(setPaymentUpdated(true));
    }), []);

    const handleChangeServiceExpenseItem = useCallback((value: IExpenditureOption | null) => {
        dispatch(setServiceExpenseItem(value));
        dispatch(setPaymentFieldValidate('serviceExpenseItem'));
        dispatch(setPaymentUpdated(true));
    }, []);

    const handleChangeServiceAmount = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        dispatch(setServiceAmount(event.target.value));
        dispatch(setPaymentFieldValidate('serviceAmount'));
        dispatch(setPaymentUpdated(true));
    }, []);

    return {
        handleChangeServiceName, handleServiceCompletionDate, onChangeCommentForService, payment, currentPaymentType, handleChangeServicePartner,
        handleChangeServiceExpenseItem, handleChangeServiceAmount, isDisabledAfterVisa, isDisabled, isDisabledFromPayment, currentStatus 
    }
};