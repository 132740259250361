import { Stack, Button, Typography, useMediaQuery, Theme, Divider } from "@mui/material";
import { FC, useMemo } from "react";
import { PartnerAutocomplete } from "../../../../../../ui-kit/partnerAutocomplete/ui";
import { ExpenditureAutocomplete } from "../../../../../../ui-kit/expenditureAutocomplete/ui";
import { useSelector } from "react-redux";
import { selectPayments } from "../../../../../../store/paymentCalendar/repository/selector";
import { selectPaymentCreation } from "../../../../../../store/paymentCreation/repository/selector";
import { UploadFile } from "../../../../../../ui-kit/uploadFile";
import { FiledSum } from "../../../../../../ui-kit/filedSum";
import { FieldComment } from "../../../../../../ui-kit/fieldComment";
import { FieldText } from "../../../../../../ui-kit/fieldText";
import { BankCardAutocomplete } from "../../../../../../ui-kit/bankCardAutocomplete/ui";
import { HistoryPayment } from "../../../../../../ui-kit/historyPayment";
import { getStyles } from "../../styles";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import { PAYMENT_STATUS } from "../../../../../../config";
import { CardAutocomplete } from "../../../../../../ui-kit/cardAutocomplete/ui";
import { PrePaymentForm } from "../../../../../payment-calendar/payment-create/ui/components/prepaymentForm";
import { PaymentKindSelector } from "../../../../../../ui-kit/paymentKindSelector/ui";
import { useUserPermission } from "../../../../../payment-calendar/payment-create/presenters/useUserPermission";
import { DeleteModal } from "../../../../../../ui-kit/deleteModal/deleteModal";
import { IUser } from "../../../../../../types/users";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { UploadConfirmFile } from "../../../../../../ui-kit/uploadConfirmFile";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { RegularPaymentForm } from "../../../../../payment-calendar/payment-create/ui/components/regularPaymentForm";
import { UserAutocomplete } from "../../../../../../ui-kit/userAutocomplete/ui";
import { PaymentTypeSelector } from "../../../../../../ui-kit/paymentTypeSelector";
import { CheckBox } from "../../../../../../ui-kit/checkBox";
import { TScreenFlag, usePaymentData } from "../parts/paymentData/presenter/usePaymentData";
import { FinancierAutocomplete } from "../../../../../../ui-kit/financierAutocomplete/ui";
import { selectServiceHistory } from "../../../../../../store/serviceHistory/repository/selector";

interface IProps {
    paymentStatus: string | undefined;
    modeFilter: string;
    user: IUser;
    currentPayment: any;
    screenFlag: TScreenFlag;
};

export const CardPayment: FC<IProps> = ({ currentPayment, paymentStatus, modeFilter, user, screenFlag }) => {
    const { t } = useTranslation();
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const styles = useMemo(() => getStyles(downSm), [downSm]);
    const { paymentTypes, paymentKinds } = useSelector(selectPayments);

    const { paymentType, paymentKind, paymentMethod, partner, comment, time, expenseItem, fullName, bankCard, files, sum, pdv,
        responsible, prepaymentDate, isRegular, serviceComment, confirmPaymentFiles, financier, visaDate, formId, initiator,
        operationist, createDate, processPaymentDate, checkCode, companyAccount, approver, prepaymentStatusDate, isConfirmedFile,
        isNotPutToEnot, desiredFinancier, isPdvChecked, formStatus } = useSelector(selectPaymentCreation);
    const status = formStatus || paymentStatus;
    const paymentId = currentPayment?.payment.id || formId;
    const prePaymentId = currentPayment?.payment.prepayment?.id;
    const groupdId = currentPayment?.payment.group_id;
    const kind = paymentKinds.find(item => item.id === paymentKind);
    const isCreator = currentPayment?.payment.initiator.id === user.id;
    const currentType = paymentTypes?.find(type => type.id === paymentType);

    const source = currentPayment?.payment.source;
    const isFromService = source === "SERVICE";
    const isDisabled = status === "VISAED" && isNotPutToEnot;
    const { singleService } = useSelector(selectServiceHistory);
    const isDisabledPaymentInService = screenFlag === "SERVICE" && !!singleService?.payment.id;

    const { handleChangePaymentType, handleChangePaymentKind, handleChangePartner, handleChangeExpenseItem, onChangeCompanyAccount, onChangeComment,
        onChangeServiceComment, watchCurrentFile, handleFileChange, handleDeleteFile, onChangeChecked, onChangeSum, onChangePDV, handleChangeDesiredFinancier,
        payment, onConfirmPaymentFileChange, deleteConfirmPaymentFile, handleNotPutToEnot, handleConfirmFile, handleRegularChange, handleDeletePayment,
        handleDeleteGroupPayments, performBudgetOrder, onChangeFullName } = usePaymentData(screenFlag, paymentId, modeFilter);
    const { isInitiator, isDeleteAllowed, isCompleted_Budget_Calendar, isActivedConfirmPayment } = useUserPermission(user.roles);

    return (
        <>
            {(modeFilter === 'Budget' && screenFlag === 'PAYMENT') &&
                <Button
                    variant="contained"
                    size="medium"
                    startIcon={<TaskAltIcon />}
                    sx={styles.performButton}
                    onClick={performBudgetOrder}
                    disabled={
                        status !== PAYMENT_STATUS.CREATED
                        || !isCompleted_Budget_Calendar && !isCreator
                    }
                >
                    {t(tokens.payment.performed)}
                </Button>
            }
            {paymentId && screenFlag === 'PAYMENT' ?
                <Stack position='absolute' right={40} top={170}>
                    <DeleteModal
                        isInitiator={isInitiator}
                        isDeleteAllowed={isDeleteAllowed}
                        isCreator={isCreator}
                        status={status}
                        paymentId={paymentId}
                        groupdId={groupdId}
                        modeFilter={modeFilter}
                        isHidden={currentPayment?.payment.hidden}
                        handleDeletePayment={handleDeletePayment}
                        handleDeleteGroupPayments={handleDeleteGroupPayments}
                    />
                </Stack>
                : null}
            <Stack direction={downSm ? 'column' : 'row'} spacing={3}>
                <PaymentTypeSelector value={paymentType} onChangeValue={handleChangePaymentType} isValid={payment.paymentType} screenFlag={screenFlag} isDisabled={isFromService || isDisabled}/>
                <PaymentKindSelector value={paymentKind} onChangeValue={handleChangePaymentKind} isValid={payment.paymentKind} isDisabled={isDisabledPaymentInService}/>
            </Stack>
            <Stack direction={downSm ? 'column' : 'row'} spacing={3}>
                <PartnerAutocomplete value={partner} isValid={payment.partner} onPartnerChange={handleChangePartner} screenFlag={screenFlag} isDisabled={isFromService || isDisabled}/>
                <BankCardAutocomplete title={t(tokens.payment.cardBank)} partnerId={partner?.id} paymentMethod={paymentMethod?.key} isValid={payment.card} value={bankCard} isDisabled={isDisabledPaymentInService}/>
            </Stack>
            <Stack direction={downSm ? 'column' : 'row'} spacing={3}>
                <ExpenditureAutocomplete value={expenseItem} isValid={payment.expenseItem} handleChangeValue={handleChangeExpenseItem} screenFlag={screenFlag} isDisabled={isFromService || isDisabled}/>
                <CardAutocomplete value={companyAccount} handleChangeValue={onChangeCompanyAccount} isValid={payment.companyAccount} isDisabled={isDisabledPaymentInService}/>
            </Stack>
            <Stack direction='row' spacing={2}>
                <FieldText title={t(tokens.payment.fullName)} partnerId={partner?.id} value={fullName} onChangeValue={onChangeFullName} isValid={payment.fullName} />
            </Stack>
            <FieldComment title={t(tokens.payment.comment)} comment={comment} onChangeComment={onChangeComment} isValid={payment.comment} screenFlag={screenFlag} isDisabled={isFromService || isDisabled}/>
            <FieldComment title={t(tokens.payment.serviceComment)} comment={serviceComment} onChangeComment={onChangeServiceComment} isValid={true} isDisabled={isDisabledPaymentInService}/>
            <Stack spacing={2}>
                <Stack direction='row' spacing={2} flex={1}>
                    {modeFilter !== 'Budget' &&
                        <CheckBox title={t(tokens.payment.regularPayment)} checked={isRegular} isDisabled={!!paymentId} onChangeValue={handleRegularChange} />}
                    <CheckBox title={t(tokens.payment.paymentProof)} checked={isConfirmedFile} isDisabled={!isActivedConfirmPayment || (status === PAYMENT_STATUS.OPERATED || status === PAYMENT_STATUS.TRANSMITTED || status === PAYMENT_STATUS.DELETED)} onChangeValue={handleConfirmFile} />
                </Stack>
                {isRegular && !paymentId &&
                    <RegularPaymentForm amountValid={payment.regularAmount} />}
            </Stack>
            {kind?.key === "prepayment" ?
                <PrePaymentForm prePaymentId={prePaymentId} status={status} dateValid={payment.prepaymentDate} userValid={payment.user} />
                :
                null}
            <Stack direction={downSm ? 'column' : 'row'} spacing={3}>
                <UploadFile
                    title={t(tokens.payment.file)}
                    message={t(tokens.payment.maxSize25)}
                    icon={<CloudUploadIcon />}
                    files={files}
                    actionTitle={t(tokens.payment.uploadCheck)}
                    watchCurrentFile={watchCurrentFile}
                    handleFileChange={handleFileChange}
                    handleDeleteFile={handleDeleteFile}
                />
                <Stack width='100%' position='relative'>
                    <FiledSum
                        sum={sum}
                        pdv={pdv}
                        checked={isPdvChecked}
                        onChangeChecked={onChangeChecked}
                        onChangeSum={onChangeSum}
                        onChangePDV={onChangePDV}
                        sumValid={payment.sum}
                        pdvValid={payment.pdv}
                        screenFlag={screenFlag}
                        isDisabled={isFromService || isDisabled}
                    />
                    <Stack direction='row' spacing={2} mt={2}>
                        <HistoryPayment
                            initiator={initiator}
                            financier={financier}
                            operationist={operationist}
                            createDate={createDate}
                            visaDate={visaDate}
                            processPaymentDate={processPaymentDate}
                            checkCode={checkCode}
                            paymentDate={time}
                            inCharge={responsible}
                            inChargeDate={prepaymentDate}
                            approver={approver}
                            prepaymentStatusDate={prepaymentStatusDate}
                        />
                    </Stack>
                </Stack>
            </Stack>
            {isConfirmedFile ?
                <Stack width="50%" pr={2}>
                    <UploadConfirmFile
                        title={t(tokens.payment.proofFile)}
                        icon={<AttachFileIcon />}
                        message={t(tokens.payment.maxSize25)}
                        files={confirmPaymentFiles}
                        watchCurrentFile={watchCurrentFile}
                        handleFileChange={onConfirmPaymentFileChange}
                        handleDeleteFile={deleteConfirmPaymentFile}
                    />
                </Stack>
                : null
            }
            <Stack pt={1}>
                <Divider><Typography variant="subtitle2">{t(tokens.payment.desiredFinancier)}</Typography></Divider>
                <Stack direction={downSm ? 'column' : 'row'} spacing={3} alignItems='flex-end'>
                    <Stack width="100%">
                        <FinancierAutocomplete
                            title={t(tokens.payment.desiredFinancier)}
                            value={desiredFinancier}
                            handleChangeValue={handleChangeDesiredFinancier}
                            isValid={true}
                            isFinancier={true}
                            isDisabled={isDisabledPaymentInService}
                        />
                    </Stack>
                    <Stack mt={2} width="100%"></Stack>
                </Stack>
                <Divider sx={{ pt: 2 }} />
            </Stack>
        </>
    );
};