import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";
import { getPartners } from "../../../paymentCalendar/useCases/getPartners/action";

function* refreshPartnersWorker(): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.post, "/zoolux-api/counterparties/refresh-enote");
        if (isError) {
            toast.error("Контрагенти не оновилися");
        } else {
            toast.success("Контрагенти оновлені");
            yield put(getPartners({ offset: 0, limit: 15 }));
        }
    } catch (error) {
        console.error("refreshPartnersWorker: ", error);
    } finally {

    };
};

export function* refreshPartnerssWatcher() {
    yield takeLatest("@saga/REFRESH_PARTNERS", refreshPartnersWorker);
};
