import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import { getStyles } from "./styles";

interface IProps {
    file: any;
    isDialogOpen: boolean;
    uploadFile: () => void;
    handleDialogClose: () => void;
    handleFileUpload: (event: any) => void;
};

export const ImportFileModal: FC<IProps> = ({ file, isDialogOpen, handleDialogClose, handleFileUpload, uploadFile }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);

    return (
        <Dialog open={isDialogOpen} onClose={handleDialogClose}>
            <DialogTitle mt={1}>{t(tokens.importPayment.addCsv)}</DialogTitle>
            <DialogContent>
                <input type="file" accept=".csv" onChange={handleFileUpload}/>
            </DialogContent>
            <DialogActions sx={styles.action}>
                <Button
                    onClick={handleDialogClose}
                    size="small"
                    variant="contained"
                    sx={styles.button}
                >
                    {t(tokens.importPayment.close)}
                </Button>
                <Button
                    onClick={uploadFile}
                    size="small"
                    variant="contained"
                    sx={styles.button}
                >
                    {t(tokens.importPayment.upload)}
                </Button>
            </DialogActions>
        </Dialog>
    )
};