export const getStyles = () => {
    const styles = {
        box: { 
            width: 200, 
            '& img': { 
                width: '100%' 
            } 
        }
    };
    return styles;
};