import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { Data, HeadCell, Order } from "../../../presenters/types";

interface EnhancedTableProps {
    numSelected: number;
    order: Order;
    orderBy: string;
    headTitles: HeadCell[];
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
};

export function PaymentsTableHead ({ order, orderBy, onRequestSort, headTitles }: EnhancedTableProps) {
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead sx={{backgroundColor: '#F0EFFF'}}>
            <TableRow>
                {headTitles.map((headCell) => (
                    <TableCell key={headCell.id} align="center" padding={headCell.disablePadding ? 'none' : 'normal'} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};