import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";
import { getSingleUser } from "../getSingleUser/action";

function* setRoleWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call( requester.post, "/zoolux-api/user-role/create", payload);
        if (isError) {
            toast.error("роль не встановлена")
        } else {
            yield put(getSingleUser({id: payload.user_id}));
            toast.success("роль встановлена")
        } 
    } catch (error) {
        console.error("setRoleWorker: ", error);
    } finally {
        
    };
};

export function* setRoleWatcher() {
    yield takeLatest("@saga/SET_ROLE", setRoleWorker);
};
