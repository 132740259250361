import { Divider, Stack, TextField, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../../../locales/tokens";
import { TimeDatePicker } from "../../../../../../../../ui-kit/timePicker";
import { FieldComment } from "../../../../../../../../ui-kit/fieldComment";
import { RelatedServices } from "../../../../../../../payment-calendar/payment-create/ui/components/relatedServices/ui";
import { useSelector } from "react-redux";
import { selectPaymentCreation } from "../../../../../../../../store/paymentCreation/repository/selector";
import { useServiceData } from "../presenter/useServiceData";
import { ServiceAutocomplete } from "../../../../../../../../ui-kit/serviceAutocomplete/ui";
import { PartnerAutocomplete } from "../../../../../../../../ui-kit/partnerAutocomplete/ui";
import { ExpenditureAutocomplete } from "../../../../../../../../ui-kit/expenditureAutocomplete/ui";
import { TScreenFlag } from "../../paymentData/presenter/useServicePayment";
import { selectServiceHistory } from "../../../../../../../../store/serviceHistory/repository/selector";

interface IProps {
    paymentId?: any;
    screenFlag: TScreenFlag;
    status: string;
    source: string | undefined;
};

export const ServiceData: FC<IProps> = ({ paymentId, status, screenFlag, source }) => {
    const { t } = useTranslation();
    const { serviceName, serviceCompletionDate, commentForService, serviceExpenseItem, servicePartner, serviceAmount } = useSelector(selectPaymentCreation);
    const { handleChangeServiceName, handleServiceCompletionDate, onChangeCommentForService, payment, currentPaymentType, handleChangeServicePartner,
        handleChangeServiceExpenseItem, handleChangeServiceAmount, isDisabledAfterVisa, isDisabled, isDisabledFromPayment, currentStatus } = useServiceData(screenFlag, status, source);
    const { singleService } = useSelector(selectServiceHistory); 

    return (
        <>
            {currentPaymentType?.key === "service_receiving" &&
                <>
                    {singleService?.payment?.id && screenFlag === "SERVICE" &&
                        <Stack position="absolute" right="50px" top="90px">
                            <Typography color="error" variant="body1">До сервісу додан чек</Typography>
                        </Stack>}
                    <Stack px={2} pt={2} sx={{ backgroundColor: '#bad8ff' }} >
                        <Divider><Typography variant="subtitle2">{t(tokens.service.service)}</Typography></Divider>
                        <Stack direction="row" spacing={3} pt={2}>
                            <ServiceAutocomplete
                                value={serviceName}
                                isValid={payment.serviceName}
                                handleChangeValue={handleChangeServiceName}
                                isDisabled={isDisabledAfterVisa}
                            />
                            <TimeDatePicker
                                value={serviceCompletionDate}
                                isValid={payment.serviceCompletionDate}
                                isdisabledPast={false}
                                title={t(tokens.payment.serviceCopletionDate)}
                                handleDate={handleServiceCompletionDate}
                                isDisabled={isDisabledAfterVisa}
                            />
                        </Stack>
                        <FieldComment
                            title={t(tokens.service.commentForService)}
                            comment={commentForService}
                            onChangeComment={onChangeCommentForService}
                            isValid={payment.commentForService}
                            isDisabled={/* isDisabled || */ !isDisabledFromPayment || isDisabledAfterVisa}
                        />
                    </Stack>
                    <Stack direction="row" spacing={3} px={2} sx={{ backgroundColor: '#bad8ff' }}>
                        <PartnerAutocomplete
                            value={servicePartner}
                            isValid={payment.servicePartner}
                            onPartnerChange={handleChangeServicePartner}
                            isDisabled={/* isDisabled || */ !isDisabledFromPayment || isDisabledAfterVisa}
                        />
                        <ExpenditureAutocomplete
                            value={serviceExpenseItem}
                            isValid={payment.serviceExpenseItem}
                            handleChangeValue={handleChangeServiceExpenseItem}
                            isDisabled={/* isDisabled || */ !isDisabledFromPayment || isDisabledAfterVisa}
                        />
                    </Stack>
                    <Stack direction="row" spacing={3} px={2} pb={2} sx={{ backgroundColor: '#bad8ff' }}>
                        <Stack direction='column' spacing={1} flex={1}>
                            <Typography color={payment.serviceAmount ? 'primary' : 'error'} variant='h6'>
                                {t(tokens.payment.sum)}
                            </Typography>
                            <TextField
                                type="number"
                                fullWidth
                                value={serviceAmount}
                                onChange={handleChangeServiceAmount}
                                label={t(tokens.payment.sum)}
                                error={!payment.serviceAmount}
                                size="small"
                                disabled={/* isDisabled || */ !isDisabledFromPayment || isDisabledAfterVisa}
                            />
                        </Stack>
                        <Stack flex={1} />
                    </Stack>
                    <Stack sx={{ backgroundColor: '#c2fbfc' }} px={2}>
                        <RelatedServices paymentId={paymentId} status={currentStatus} isDisabled={isDisabledAfterVisa || !isDisabledFromPayment} />
                    </Stack>
                </>}
        </>
    )
};