import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";
import { setSinglePayment } from "../../repository/actions";

function* getSinglePaymentWorker({ payload }: any): SagaIterator {
    try {
        const url = payload.mode === 'Payment' ?
            "/zoolux-api/payments/one/" : "/zoolux-api/budget-payments/one/";
        const { data, isError, message }: IResponse<any> = yield call(requester.get, url + payload.body.id);
        if (isError) {
            toast.error(message);
        } else {
            yield put(setSinglePayment(data));
        }
    } catch (error) {
        console.error("getSinglePaymentWorker: ", error);
    } finally {

    }
};

export function* getSinglePaymentWatcher() {
    yield takeLatest("@saga/GET_SINGLE_PAYMENT", getSinglePaymentWorker);
};
