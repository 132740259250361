import { SagaIterator } from "redux-saga";
import { put, call, takeEvery, select } from "redux-saga/effects";
import { requester } from "../../../../libs/requester/requester-axios";
import { IResponse } from "../../../../libs/requester/IResponse";
import toast from "react-hot-toast";
import { setSinglePartner } from "../../repository/slice";

function* getSinglePartnerWorker({ payload }: any): SagaIterator {    
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.get, "/zoolux-api/admin/counterparties/" + payload.id);
        if (isError) {
            toast.error(message);
        } else {
            yield put(setSinglePartner(data));
        }
    } catch (error) {
        console.error("getSinglePartnerWorker: ", error);
    } finally {

    }
};

export function* getSinglePartnerWatcher() {
    yield takeEvery("@saga/GET_SINGLE_PARTNER", getSinglePartnerWorker);
};