import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPermissions } from "../../../../../store/users/usecases/getPermissions/action";
import { selectUsers } from "../../../../../store/users/repository/selector";
import { SelectChangeEvent } from "@mui/material";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { tokens } from "../../../../../locales/tokens";
import { useTranslation } from "react-i18next";
import { getRoles } from "../../../../../store/users/usecases/getRoles/action";
import { editRole } from "../../../../../store/users/usecases/editRole/action";

export const useRoleEdit = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { roleId } = useParams();
    const { permissions, roles } = useSelector(selectUsers);

    const [name, setName] = useState('');
    const [permission, setPermission] = useState<number[]>([]);
    const [role, setRole] = useState<any>(null);

    const [nameValid, setNameValid] = useState(true);
    const [permissionValid, setPermissionValid] = useState(true);

    useEffect(() => {
        dispatch(getPermissions());
        dispatch(getRoles());
    }, []);

    useEffect(() => {
        setRole(roles.find(item => item.id === Number(roleId)));
    }, [roles, roleId]);

    useEffect(() => {
        if (role) {
            setName(role.name);
            setPermission(role.permissions.map((item: any) => item.id));
        }
    }, [role]);

    const onChangeName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
        setNameValid(true);
    }, []);

    const handleChangePermission = useCallback((event: SelectChangeEvent<number[]>) => {
        setPermission(event.target.value as number[]);
        setPermissionValid(true);
    }, []);

    const handleDeletePermission = useCallback((e: React.MouseEvent, value: number) => {
        e.preventDefault();
        setPermission((current) => current.filter((item) => item !== value));
        setPermissionValid(true);
    }, []);

    const handleEditRole = () => {
        if (!name) {
            setNameValid(false);
        } else if (!permission.length) {
            setPermissionValid(false);
        } else {
            dispatch(editRole({ body: { id: Number(roleId), name }, permission }));
            setTimeout(() => {
                toast.success(t(tokens.user.messages.roleUpdated));
                navigate('/user-role');
            }, 500);
        }
    };

    return {
        name, permission, permissions, handleChangePermission, handleDeletePermission, onChangeName,
        handleEditRole, nameValid, permissionValid
    }
};