import { FC } from "react";
import { TableBody } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AccountListItem } from "../AccountListItem";

interface IProps {
    account: any;
};

export const AccountListBody: FC<IProps> = ({ account }) => {

    return (
        <TableBody>
            {account.payments.map((payment: any, index: number) =>
                <AccountListItem payment={payment} key={payment.payment_date + index} />
            )}
        </TableBody>
    )
};