import { Grid, Stack } from "@mui/material";
import { FC } from "react";
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit-icon.svg';
import { useGetPtServiceQuery } from '../../../../store/ptService/useCases/ptUserService';
import { CircularProgress, HeadingTitle } from '../../../../uiKit';
import { OperationCard } from "./components/oprationCard";

export const OperationList: FC = () => {

    const { data: dataGetPtService = [], isLoading: isLoadingGetPtService } = useGetPtServiceQuery();

    return (
        <Stack component={"section"} display={"flex"} rowGap={2.5} px={5} pr={2.5} pt={2}>
            <HeadingTitle title="Сервіси" textBtn={"Редагувати"} startIcon={<EditIcon />} disabled />
            {isLoadingGetPtService
                ? <CircularProgress />
                : <Grid container spacing={5}>
                    {dataGetPtService?.map(({ name, slug, icon }, index: number) => (
                        <Grid item xs={3} key={index}>
                            <OperationCard icon={icon} title={name} path={`${slug}`} />
                        </Grid>
                    ))}
                </Grid>
            }
        </Stack>
    );
};

