import { Box } from '@mui/material';
import 'dayjs/locale/uk';
import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const MoveFixedAssetDots: FC<any> = ({ moveFixedAssetArray }) => {
    return (
        <>
            {moveFixedAssetArray?.map(({ status, current = false }: any) => (
                <Box key={uuidv4()} sx={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    height: "2px",
                    backgroundColor: status ? "second.main" : "#D9D9D9",
                    width: "100%",
                    "&:first-of-type": {
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px"
                    },
                    "&:last-of-type": {
                        borderTopRightRadius: "10px",
                        borderBottomRightRRadius: "10px"
                    },
                    "&:first-of-type:after": {
                        content: "''",
                        width: current ? "10px" : "6px",
                        display: "block",
                        height: current ? "10px" : "6px",
                        borderRadius: "50%",
                        backgroundColor: "#585858",
                    },
                }}>
                    <Box sx={{
                        position: "absolute",
                        top: "50%",
                        zIndex: 2,
                        transform: "translateY(-50%)",
                        right: "0",
                        width: current ? "10px" : "6px",
                        height: current ? "10px" : "6px",
                        borderRadius: "50%",
                        backgroundColor: "#585858",

                    }}>
                        <Box sx={{
                            top: "50%",
                            zIndex: 1,
                            left: "50%",
                            position: "absolute",
                            transform: "translate(-50%, -50%)",
                            width: "6px",
                            display: "block",
                            height: "6px",
                            borderRadius: "50%",
                            backgroundColor: current ? "#5ADAB6" : "transparent",
                        }}>
                        </Box>
                    </Box>
                </Box>
            ))}
        </>
    );
};
