import { SagaIterator } from "redux-saga";
import { put, call, takeEvery } from "redux-saga/effects";
import { requester } from "../../../../libs/requester/requester-axios";
import { IResponse } from "../../../../libs/requester/IResponse";
import toast from "react-hot-toast";
import { setFinancier, setVisaDate, setVisaPayment } from "../../../paymentCreation/repository/slice";

function* setVisaWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.put, "/zoolux-api/payments/set-visa/" + payload.id);
        if (isError) {
            toast.error(message);
        } else {
            yield put(setVisaPayment(true));
            yield put(setFinancier(data.payment.financier)); 
            yield put(setVisaDate(data.payment.visa_date));
            toast.success("Платіж завізовано");
        }
    } catch (error) {
        console.error("setVisaWorker: ", error);
    }
};

export function* setVisaWatcher() {
    yield takeEvery("@saga/SET_VISA", setVisaWorker);
};