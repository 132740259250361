import { useCallback, useEffect, useMemo, useRef, useState, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "../../../../hooks/useDebounce";
import { refreshPartners } from "../../../../store/partners/useCases/refreshPartners/action";
import { getPartnersAll } from "../../../../store/partners/useCases/getPartnersAll/action";
import { selectPartner } from "../../../../store/partners/repository/selector";

export const usePartnerList = () => {
    const rootRef = useRef<HTMLDivElement | null>(null);
    const dispatch = useDispatch();
    const { partnerList, partnersCount } = useSelector(selectPartner);
    const [search, updateSearch] = useState({ offset: 0, limit: 15, query: '', sortDir: 'desc', status: undefined });
    const [drawer, setDrawer] = useState<{ isOpen: boolean; data?: string; }>({ isOpen: false, data: undefined });
    const [state, setState] = useState<{ partnersList: any[], partnersCount: number }>({ partnersList: [], partnersCount: 0 });
    const [searchText, setSearchText] = useState('');
    const [pageAmount, setPageAmount] = useState(0); 
    const [currentPage, setCurrentPage] = useState(1); 

    useEffect(() => {
        dispatch(getPartnersAll({ offset: search.offset, limit: search.limit, search: search.query }));
    }, [search]);

    useEffect(() => {
        if (partnerList?.length) {
            setState({ partnersList: partnerList, partnersCount: 0 as number })
        } else {
            setState({ partnersList: [], partnersCount: 0 as number })
        }
    }, [partnerList]);

    useEffect(() => {
        setPageAmount(Math.ceil(partnersCount / search.limit));
    }, [partnersCount]);

    const currentPartner = useMemo(() => {
        if (!drawer.data) {
            return undefined;
        };
        return state.partnersList.find((partner) => partner.id === Number(drawer.data));
    }, [drawer, state.partnersList]);

    const handleOrderOpen = useCallback((partnerId: string): void => {
        if (drawer.isOpen && drawer.data === partnerId) {
            setDrawer({ isOpen: false, data: undefined });
            return;
        }
        setDrawer({ isOpen: true, data: partnerId });
    }, [drawer]);

    const handlePartnerClose = useCallback((): void => {
        setDrawer({ isOpen: false, data: undefined });
    }, []);

    const handleStatusChange = useCallback((status: any): void => {
        updateSearch((prevState) => ({ ...prevState, status }));
    }, [updateSearch]);

    const handleQueryChange = useCallback((query: string): void => {
        updateSearch((prevState) => ({ ...prevState, query, offset: 0 }));
        setCurrentPage(1);
    }, [updateSearch]);

    const handleSortChange = useCallback((sortDir: string): void => {
        updateSearch((prevState) => ({ ...prevState, sortDir }));
    }, [updateSearch]);

    const handlePageChange = useCallback((event: ChangeEvent<unknown>, page: number): void => {
        updateSearch((prevState) => ({ ...prevState, offset: (page - 1) * search.limit }));
        setCurrentPage(page);
    }, [updateSearch]);

    const handleSearchPartnerText = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    useEffect(() => {
        debouncedHandlePartnersSeacrh(searchText);
    }, [searchText]);

    const onSearchPartners = (value: string) => {
        const query = value ? value : '';
        handleQueryChange(query);
    };

    const { debouncedWrapper: debouncedHandlePartnersSeacrh } = useDebounce(onSearchPartners, 400);

    const updatePartners = () => {
        dispatch(refreshPartners());
    }; 

    return {
        state, currentPartner, handlePartnerClose, handleOrderOpen, handlePageChange, handleQueryChange, updatePartners, currentPage,
        handleSortChange, rootRef, search, handleStatusChange, drawer, pageAmount, searchText, handleSearchPartnerText
    };
};