import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPrepaymentList } from "../../../store/prepayment/useCases/getPrepayments/action";
import { selectPrepayment } from "../../../store/prepayment/repository/selector";
import { TIMESTAMP_DAY } from "../../../config";

export const usePrepaymetList = () => {
    const dispatch = useDispatch();
    const [prepaymentAmount, setPrepaymentAmount] = useState(0);
    const [filters, setFilters] = useState<{page: number, rowsPerPage: number, date: null | number}>({ page: 1, rowsPerPage: 15, date: null });

    const { prepaymentList, prepaymentCount } = useSelector(selectPrepayment);

    useEffect(() => {
        dispatch(getPrepaymentList(combineRequestBody()));
    }, [filters]);

    const combineRequestBody = () => {
        const body: any = { page: filters.page, perPage: filters.rowsPerPage };
        if (filters.date) {
            body.startTime = filters.date;
            body.endTime = filters.date + TIMESTAMP_DAY;
        };
        return body;
    };

    useEffect(() => {
        if (prepaymentCount) {
            setPrepaymentAmount(Math.ceil(prepaymentCount / filters.rowsPerPage));
        }
    }, [prepaymentCount]);

    const handlePageChange = useCallback((event: ChangeEvent<unknown>, page: number): void => {
        setFilters((prevState) => ({ ...prevState, page }));
    }, [setFilters]);

    const handleDateChange = useCallback((date: Date | null): void => {
        const currentDate = date?.valueOf() || null;
        setFilters((prevState) => ({ ...prevState, page: 1, date: currentDate }));
    }, [setFilters]);

    return { prepaymentList, prepaymentAmount, handlePageChange, filters, handleDateChange }
};