import { useDispatch, useSelector } from "react-redux";
import { selectPaymentCreation } from "../../../../../../../../store/paymentCreation/repository/selector";
import { selectPayments } from "../../../../../../../../store/paymentCalendar/repository/selector";
import {
    setFormData, setRelatedServicePartnerValid, setTime, setUpdatedPaymentModal,
    setOpenServiceModal, setRelatedServiceCommentValid, setRelatedServiceExpenditureValid,
    setRelatedServiceAmountValid,
} from "../../../../../../../../store/paymentCreation/repository/slice";
import toast from "react-hot-toast";
import { createSinglePayment } from "../../../../../../../../store/paymentCalendar/useCases/createPayment/action";
import { updatePayment } from "../../../../../../../../store/paymentCalendar/useCases/updatePayment/action";
import { selectOperationValidation } from "../../../../../../../../store/operationValidation/repository/selector";
import { setVisa } from "../../../../../../../../store/paymentCalendar/useCases/setVisa/action";
import { PAYMENT_STATUS } from "../../../../../../../../config";
import { deleteVisa } from "../../../../../../../../store/paymentCalendar/useCases/deleteVisa/action";
import { useCallback } from "react";
import { passToPayment } from "../../../../../../../../store/paymentCalendar/useCases/passToPayment/action";
import { useNavigate } from "react-router-dom";
import { setPaymentFieldValidate, setPaymentUpdated, setPaymentValidate } from "../../../../../../../../store/operationValidation/repository/slice";

export const usePaymentProcessing = (paymentId?: any, modeFilter?: string) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { paymentKinds, paymentTypes } = useSelector(selectPayments);
    const { partner, sum, time, paymentType, paymentKind, expenseItem, prepaymentDate, responsible, regularAmount, isRegular, serviceName,
        serviceCompletionDate, commentForService, relatedServices, pdv, comment, companyAccount, clinic, paymentMethod, isConfirmedFile, bankCard,
        serviceComment, desiredFinancier, prepaymentComment, isNotPutToEnot, regularPeriod, files, confirmPaymentFiles, fullName, isVisa, isPdvChecked,
        isUpdatedPaymentModal, isOpenServiceModal, servicePartner, serviceExpenseItem, serviceAmount } = useSelector(selectPaymentCreation);
    const { payment, isPaymentUpdated } = useSelector(selectOperationValidation);
    const currentPaymentType = paymentTypes.find((type: any) => type.id === paymentType);

    const handleDateChange = useCallback((date: Date | null): void => {
        if (date) {
            dispatch(setTime(date?.valueOf()));
            dispatch(setPaymentFieldValidate('date'));
            dispatch(setPaymentUpdated(true));
        }
    }, []);

    const validateData = () => {
        const validFields = [
            { field: partner, isValid: "partner" },
            { field: sum, isValid: "sum" },
            { field: time, isValid: "date" },
            { field: paymentType, isValid: "paymentType" },
            { field: paymentKind, isValid: "paymentKind" },
            { field: expenseItem, isValid: "expenseItem" },
        ];
        if (isPdvChecked) validFields.push({ field: pdv, isValid: "pdv" });
        const kind = paymentKinds.find(item => item.id === paymentKind);
        if (kind?.key === 'prepayment') {
            validFields.push({ field: prepaymentDate, isValid: "prepaymentDate" });
            validFields.push({ field: responsible, isValid: "user" });
        };
        if (isRegular && !paymentId) {
            validFields.push({ field: regularAmount, isValid: "regularAmount" });
        };
        if (currentPaymentType?.key === "service_receiving") {
            validFields.push({ field: serviceName, isValid: "serviceName" });
            validFields.push({ field: serviceCompletionDate, isValid: "serviceCompletionDate" });
            validFields.push({ field: commentForService, isValid: "commentForService" });
            validFields.push({ field: servicePartner, isValid: "servicePartner" });
            validFields.push({ field: serviceExpenseItem, isValid: "serviceExpenseItem" });
            validFields.push({ field: serviceAmount, isValid: "serviceAmount" });
        };
        let allFieldsValid = true;

        for (const { field, isValid } of validFields) {
            if (!field) {
                dispatch(setPaymentValidate(isValid as any));
                allFieldsValid = false;
            }
        };

        if (relatedServices.length > 0) {
            relatedServices.forEach((service: any, index: number) => {
                for (let key in service) {
                    if (key === 'counterparty' && !service[key]) {
                        dispatch(setRelatedServicePartnerValid({ value: false, index }));
                        allFieldsValid = false;
                    } else if (key === 'expenditure' && !service[key]) {
                        dispatch(setRelatedServiceExpenditureValid({ value: false, index }));
                        allFieldsValid = false;
                    } else if (key === 'amount' && !service[key]) {
                        dispatch(setRelatedServiceAmountValid({ value: false, index }));
                        allFieldsValid = false;
                    } else if (key === 'comment' && !service[key]) {
                        dispatch(setRelatedServiceCommentValid({ value: false, index }));
                        allFieldsValid = false;
                    }
                }
            });
        };
        return allFieldsValid;
    };

    const getPaymentData = () => {
        const payment: any = {
            name: partner?.label,
            taxes: pdv ? Number(pdv) : null,
            comment: comment || null,
            service_comment: serviceComment || null,
            amount: parseFloat(sum.replace(',', '.')),
            payment_date: time,
            company_account_id: companyAccount?.id || null,
            payment_kind_id: paymentKind || null,
            payment_type_id: paymentType || null,
            payment_method_id: paymentMethod.id,
            clinic_id: clinic?.id || null,
            expenditure_id: expenseItem?.id || null,
            counterparty_id: partner?.id,
            isConfirmedFile: isConfirmedFile,
            bankDetails: bankCard,
            financier_id: desiredFinancier?.id || null
        };
        return payment;
    };

    const getPrepaymentData = () => {
        let prepayment;
        const currentPaymentKind = paymentKinds.find((kind: any) => kind.id === paymentKind);
        if (currentPaymentKind.key === "prepayment") {
            prepayment = {
                responsible_id: responsible.id,
                comment: prepaymentComment,
                prepayment_date: prepaymentDate
            };
        } else {
            prepayment = null;
        };
        return prepayment;
    };

    const getPaymentServiceData = () => {
        let receipt_goods_services;
        if (currentPaymentType.key === "service_receiving" && serviceName) {
            receipt_goods_services = {
                date: serviceCompletionDate,
                is_hidden: false,
                is_enote: true,
                comment: commentForService,
                counterparty_id: servicePartner?.id,
                expenditure_id: serviceExpenseItem?.id,
                amount: Number(serviceAmount)
            };
        } else {
            receipt_goods_services = null;
        }
        return receipt_goods_services;
    };

    const getServiceData = () => {
        let service_receiving;
        if (currentPaymentType.key === "service_receiving" && serviceName) {
            service_receiving = {
                service_receiving_ids: [serviceName?.id]
            };
        } else {
            service_receiving = [];
        };
        return service_receiving;
    };

    const getServiceRelatedData = () => {
        if (relatedServices.length > 0) {
            const newRelatedServices = relatedServices.map((service: any) => {
                const newService: any = {
                    amount: Number(service.amount),
                    counterparty_id: service.counterparty.id,
                    expenditure_id: service.expenditure.id,
                    comment: service.comment,
                    is_hidden: false
                };
                if (service?.id) newService.id = service.id;
                return newService;
            });
            return { data: newRelatedServices }
        } else {
            return null;
        }
    };

    const createOrEditPayment = (isShowMessage: boolean) => {
        const isPassedValidation = validateData();
        if (isPassedValidation) {
            let body: any = {
                prepayment: getPrepaymentData(),
                receipt_goods_services: getPaymentServiceData(),
                service_receiving: getServiceData(),
                service_related: getServiceRelatedData(),
            };
            modeFilter === 'Payment' ? body.payment = getPaymentData() : body.budget_payment = getPaymentData();
            if (paymentId) {
                editPayment(body, isShowMessage);
            } else {
                createPayment(body);
            };
        } else {
            toast.error('Заповніть обов\'язкові поля');
        };
    };

    const createPayment = (body: any) => {
        if (isRegular && regularPeriod === "Months") {
            body.regular_payment = true;
            body.count_months = Number(regularAmount);
        } else if (isRegular && regularPeriod === "Weeks") {
            body.regular_payment = true;
            body.count_weeks = Number(regularAmount);
        };
        dispatch(createSinglePayment({ body, files, confirmPaymentFiles, modeFilter }));
        dispatch(setPaymentUpdated(false));
    };

    const editPayment = (body: any, isShowMessage: boolean) => {
        modeFilter === 'Payment' ? body.payment_id = paymentId : body.budget_payment_id = paymentId;
        dispatch(updatePayment({ body, isShowMessage, modeFilter }));
    };

    /* ------------------------------ Visa And UnVisa Payment ------------------------ */
    const handleChangeVisa = (id: number, statusForm: string) => {
        if (isPaymentUpdated) {
            dispatch(setUpdatedPaymentModal(true));
        } else {
            if (statusForm === PAYMENT_STATUS.CREATED) {
                dispatch(setVisa({ id }));
                dispatch(setFormData({ id, status: PAYMENT_STATUS.VISAED }));

            } else {
                dispatch(deleteVisa({ id: paymentId }));
                dispatch(setFormData({ id, status: PAYMENT_STATUS.CREATED }));
            }
        }
    };

    /* ----------------------------------- Transfer Payment ------------------------------- */
    const transferToPayment = (id: string) => {
        if (isPaymentUpdated) {
            dispatch(setUpdatedPaymentModal(true));
        } else {
            dispatch(passToPayment({ id, modeFilter }));
        }
    };

    /* -------------------------------- Process Payment -------------------------------- */
    const makePayment = () => {
        const type = paymentTypes.find(item => item.id === paymentType);
        if (!isNotPutToEnot && type?.key !== 'payments_suppliers') {
            dispatch(setOpenServiceModal(true));
        } else {
            validateAndProcess();
        }
    };

    const validateAndProcess = () => {
        const fields: any[] = validateFields(paymentMethod.key);
        if (isPdvChecked) fields.push({ field: pdv, isValid: "pdv" });
        const kind = paymentKinds.find(item => item.id === paymentKind);
        if (kind?.key === 'prepayment') {
            fields.push({ field: prepaymentDate, isValid: "prepaymentDate" });
            fields.push({ field: responsible, isValid: "user" });
        };
        if (!isNotPutToEnot && currentPaymentType.key === "service_receiving") {
            fields.push({ field: serviceName, isValid: "serviceName" });
            fields.push({ field: serviceCompletionDate, isValid: "serviceCompletionDate" });
            fields.push({ field: commentForService, isValid: "commentForService" });
            fields.push({ field: servicePartner, isValid: "servicePartner" });
            fields.push({ field: serviceExpenseItem, isValid: "serviceExpenseItem" });
            fields.push({ field: serviceAmount, isValid: "serviceAmount" });
        };

        let allFieldsValid = true;
        for (const { field, isValid } of fields) {
            if (!field) {
                dispatch(setPaymentValidate(isValid as any));
                allFieldsValid = false;
            }
        };
        if (!allFieldsValid) {
            toast.error('Заповніть всі поля та редагуйте платіж для проведення');
            dispatch(setOpenServiceModal(false));
        } else {
            if (isPaymentUpdated) {
                dispatch(setUpdatedPaymentModal(true));
            } else {
                navigate(`/payment-form/${paymentId}`);
            }
        }
    };

    const validateFields = (paymentMethod: string) => {
        const validFields: Record<string, { field: any; isValid: string }[]> = {
            'non-cash': [
                { field: paymentKind, isValid: "paymentKind" },
                { field: paymentType, isValid: "paymentType" },
                { field: partner, isValid: "partner" },
                { field: expenseItem, isValid: "expenseItem" },
                { field: companyAccount, isValid: "companyAccount" },
                { field: bankCard, isValid: "card" },
                { field: comment, isValid: "comment" },
                { field: sum, isValid: "sum" },
                { field: time, isValid: "date" },
            ],
            'cash': [
                { field: paymentKind, isValid: "paymentKind" },
                { field: paymentType, isValid: "paymentType" },
                { field: partner, isValid: "partner" },
                { field: expenseItem, isValid: "expenseItem" },
                { field: clinic, isValid: "clinic" },
                { field: companyAccount, isValid: "companyAccount" },
                { field: comment, isValid: "comment" },
                { field: sum, isValid: "sum" },
                { field: time, isValid: "date" },
            ],
            'card': [
                { field: paymentKind, isValid: "paymentKind" },
                { field: paymentType, isValid: "paymentType" },
                { field: partner, isValid: "partner" },
                { field: expenseItem, isValid: "expenseItem" },
                { field: companyAccount, isValid: "companyAccount" },
                { field: bankCard, isValid: "card" },
                { field: comment, isValid: "comment" },
                { field: sum, isValid: "sum" },
                { field: time, isValid: "date" },
                { field: fullName, isValid: "fullName" },
            ]
        };
        return validFields[paymentMethod];
    };

    const handleToggleServiceModal = () => {
        dispatch(setOpenServiceModal(!isOpenServiceModal));
    };

    const handleToggleUpdateModal = () => {
        dispatch(setUpdatedPaymentModal(!isUpdatedPaymentModal));
    };

    const updatePaymentInModal = () => {
        createOrEditPayment(true);
        dispatch(setUpdatedPaymentModal(false))
        dispatch(setPaymentUpdated(false));
    };

    return {
        createOrEditPayment, time, handleDateChange, payment, transferToPayment, handleChangeVisa, isNotPutToEnot, makePayment, isVisa, updatePaymentInModal,
        currentPaymentType, isUpdatedPaymentModal, isOpenServiceModal, handleToggleServiceModal, handleToggleUpdateModal, validateAndProcess
    }
};