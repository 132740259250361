import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBankCardOption, ICardOption, IClinicOption, IExpenditureOption, IPartnerOption, IUserOption } from '../../../utils/collectOptionList';

interface IState {
  paymentMethod: any,
  paymentKind: any,
  paymentType: any,
  partner: IPartnerOption | null,
  expenseItem: IExpenditureOption | null,
  serviceName: any;
  clinic: IClinicOption | null,
  companyAccount: ICardOption | null,
  invoice: string,
  comment: string,
  commentForService: string,
  serviceComment: string,
  sum: string,
  pdv: string;
  time: number | null,
  fullName: string,
  bankCard: IBankCardOption | null,
  files: File[] | string[] | [],
  confirmPaymentFiles: File[] | string[] | [],
  image: string,
  isVisa: boolean;
  initiator: any | null;
  createDate: string;
  serviceCompletionDate: number | null;
  financier: any | null;
  visaDate: string;
  operationist: any | null;
  processPaymentDate: string;
  formId: number | null;
  formStatus: string | null;
  checkCode: string | null;
  startPeriod: number | null;
  endPeriod: number | null;
  prepaymentComment: string;
  prepaymentDate: number | null,
  responsible: any;
  inCharge: any;
  inChargeDate: string | null;
  approver: any | null;
  prepaymentStatusDate: number | null;
  prepaymentStatus: string;
  isRegular: boolean;
  regularAmount: string;
  regularPeriod: string;
  isConfirmedFile: boolean;
  servicePaymentId: any;
  isNotPutToEnot: boolean;
  relatedServices: any[];
  deletedRelatedServicesIds: number[];
  relatedServicesValid: any[];
  desiredFinancier: any;
  isPdvChecked: boolean;
  isUpdatedPaymentModal: boolean;
  isOpenServiceModal: boolean;
  servicePartner: IPartnerOption | null,
  serviceExpenseItem: IExpenditureOption | null,
  serviceAmount: string;
  serviceSource: string;
};

const initialState: IState = {
  paymentMethod: null,
  paymentKind: '',
  paymentType: '',
  partner: null,
  expenseItem: null,
  serviceName: null,
  clinic: null,
  companyAccount: null,
  invoice: '',
  comment: '',
  commentForService: '',
  serviceComment: '',
  sum: '',
  pdv: '',
  time: null,
  fullName: '',
  bankCard: null,
  files: [],
  confirmPaymentFiles: [],
  image: '',
  isVisa: false,
  initiator: null,
  createDate: '',
  serviceCompletionDate: new Date().getTime(),
  financier: null,
  visaDate: '',
  operationist: null,
  processPaymentDate: '',
  formId: null,
  formStatus: null,
  checkCode: null,
  startPeriod: null,
  endPeriod: null,
  prepaymentComment: '',
  prepaymentDate: null,
  responsible: null,
  inCharge: null,
  inChargeDate: null,
  approver: null,
  prepaymentStatusDate: null,
  prepaymentStatus: '',
  isRegular: false,
  regularAmount: '',
  regularPeriod: "Months",
  isConfirmedFile: false,
  isNotPutToEnot: true,
  servicePaymentId: null,
  relatedServices: [],
  deletedRelatedServicesIds: [],
  relatedServicesValid: [],
  desiredFinancier: null,
  isPdvChecked: false,
  isUpdatedPaymentModal: false,
  isOpenServiceModal: false,
  servicePartner: null,
  serviceExpenseItem: null,
  serviceAmount: '',
  serviceSource: '',
};

const PaymentCreationSlice = createSlice({
  name: 'payment-creation',
  initialState,
  reducers: {
    setPaymentMethod: (state, action: PayloadAction<any>) => {
      state.paymentMethod = action.payload;
    },
    setFormData: (state, action: PayloadAction<any>) => {
      state.formId = action.payload.id;
      state.formStatus = action.payload.status;
    },
    setInCharge: (state, action: PayloadAction<any>) => {
      state.inCharge = action.payload;
    },
    setRegularAmount: (state, action: PayloadAction<string>) => {
      state.regularAmount = action.payload;
    },
    setUpdatedPaymentModal: (state, action: PayloadAction<boolean>) => {
      state.isUpdatedPaymentModal = action.payload;
    },
    setOpenServiceModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenServiceModal = action.payload;
    },
    setRegularPeriod: (state, action: PayloadAction<string>) => {
      state.regularPeriod = action.payload;
    },
    setServicePaymentId: (state, action: PayloadAction<any>) => {
      state.servicePaymentId = action.payload;
    },
    setRegularPayment: (state, action: PayloadAction<boolean>) => {
      state.isRegular = action.payload;
    },
    setPdvChecked: (state, action: PayloadAction<boolean>) => {
      state.isPdvChecked = action.payload;
    },
    setConfirmedFile: (state, action: PayloadAction<boolean>) => {
      state.isConfirmedFile = action.payload;
    },
    setNotPutToEnot: (state, action: PayloadAction<boolean>) => {
      state.isNotPutToEnot = action.payload;
    },
    setApprover: (state, action: PayloadAction<any>) => {
      state.approver = action.payload;
    },
    setPaymentKind: (state, action: PayloadAction<any>) => {
      state.paymentKind = action.payload;
    },
    setPaymentType: (state, action: PayloadAction<any>) => {
      state.paymentType = action.payload;
    },
    setPartner: (state, action: PayloadAction<IPartnerOption | null>) => {
      state.partner = action.payload;
    },
    setServicePartner: (state, action: PayloadAction<IPartnerOption | null>) => {
      state.servicePartner = action.payload;
    },
    setResponsible: (state, action: PayloadAction<IUserOption | null>) => {
      state.responsible = action.payload;
    },
    setRelatedServices: (state, action: PayloadAction<any>) => {
      state.relatedServices = [...state.relatedServices, action.payload];
    },
    setResetRelatedServices: (state, action: PayloadAction<any>) => {
      state.relatedServices = action.payload;
    },
    setResetRelatedServicesValid: (state, action: PayloadAction<any>) => {
      state.relatedServicesValid = action.payload;
    },
    setDeletedRelatedServicesIds: (state, action: PayloadAction<any>) => {
      state.deletedRelatedServicesIds = [...state.deletedRelatedServicesIds, action.payload];
    },
    setRelatedServicesValid: (state, action: PayloadAction<any>) => {
      state.relatedServicesValid = [...state.relatedServicesValid, action.payload];
    },
    setRelatedServiceDelete: (state, action: PayloadAction<any[]>) => {
      state.relatedServices = action.payload;
    },
    setRelatedServicePartner: (state, action: PayloadAction<any>) => {
      state.relatedServices[action.payload.index].counterparty = action.payload.value;
    },
    setRelatedServicePartnerValid: (state, action: PayloadAction<any>) => {
      state.relatedServicesValid[action.payload.index].counterpartyValid = action.payload.value;
    },
    setRelatedServiceExpenditure: (state, action: PayloadAction<any>) => {
      state.relatedServices[action.payload.index].expenditure = action.payload.value;
    },
    setRelatedServiceExpenditureValid: (state, action: PayloadAction<any>) => {
      state.relatedServicesValid[action.payload.index].expenditureValid = action.payload.value;
    },
    setRelatedServiceAmount: (state, action: PayloadAction<any>) => {
      state.relatedServices[action.payload.index].amount = action.payload.value;
    },
    setRelatedServiceAmountValid: (state, action: PayloadAction<any>) => {
      state.relatedServicesValid[action.payload.index].amountValid = action.payload.value;
    },
    setRelatedServiceComment: (state, action: PayloadAction<any>) => {
      state.relatedServices[action.payload.index].comment = action.payload.value;
    },
    setRelatedServiceCommentValid: (state, action: PayloadAction<any>) => {
      state.relatedServicesValid[action.payload.index].commentValid = action.payload.value;
    },
    setRelatedServiceAfterCreate: (state, action: PayloadAction<any>) => {
      state.relatedServices = state.relatedServices.map((service: any, index: number) => ({
        ...service, id: action.payload[index].id
      }))
    },
    setExpenseItem: (state, action: PayloadAction<IExpenditureOption | null>) => {
      state.expenseItem = action.payload;
    },
    setServiceExpenseItem: (state, action: PayloadAction<IExpenditureOption | null>) => {
      state.serviceExpenseItem = action.payload;
    },
    setServiceName: (state, action: PayloadAction<any>) => {
      state.serviceName = action.payload;
    },
    setDesiredFinancier: (state, action: PayloadAction<any>) => {
      state.desiredFinancier = action.payload;
    },
    setClinic: (state, action: PayloadAction<IClinicOption | null>) => {
      state.clinic = action.payload;
    },
    setCompanyAccount: (state, action: PayloadAction<ICardOption | null>) => {
      state.companyAccount = action.payload;
    },
    setInvoice: (state, action: PayloadAction<string>) => {
      state.invoice = action.payload;
    },
    setComment: (state, action: PayloadAction<string>) => {
      state.comment = action.payload;
    },
    setCommentForService: (state, action: PayloadAction<string>) => {
      state.commentForService = action.payload;
    },
    setServiceComment: (state, action: PayloadAction<string>) => {
      state.serviceComment = action.payload;
    },
    setPrepaymentComment: (state, action: PayloadAction<string>) => {
      state.prepaymentComment = action.payload;
    },
    setSum: (state, action: PayloadAction<string>) => {
      state.sum = action.payload;
    },
    setServiceAmount: (state, action: PayloadAction<string>) => {
      state.serviceAmount = action.payload;
    },
    setPDV: (state, action: PayloadAction<string>) => {
      state.pdv = action.payload;
    },
    setTime: (state, action: PayloadAction<number | null>) => {
      state.time = action.payload;
    },
    setServiceCompletionDate: (state, action: PayloadAction<number | null>) => {
      state.serviceCompletionDate = action.payload;
    },
    setPrepaymentDate: (state, action: PayloadAction<number | null>) => {
      state.prepaymentDate = action.payload;
    },
    setChangeStatusDate: (state, action: PayloadAction<number | null>) => {
      state.prepaymentStatusDate = action.payload;
    },
    setInChargeDate: (state, action: PayloadAction<string | null>) => {
      state.inChargeDate = action.payload;
    },
    setStartPeriod: (state, action: PayloadAction<number | null>) => {
      state.startPeriod = action.payload;
    },
    setEndPeriod: (state, action: PayloadAction<number | null>) => {
      state.endPeriod = action.payload;
    },
    setFullName: (state, action: PayloadAction<string>) => {
      state.fullName = action.payload;
    },
    setBankCard: (state, action: PayloadAction<any>) => {
      state.bankCard = action.payload;
    },
    setFiles: (state, action: PayloadAction<File>) => {
      //@ts-ignore
      state.files = [...state.files, action.payload];
    },
    deleteFile: (state, action: PayloadAction<string>) => {
      //@ts-ignore
      const newFiles: File[] = state.files.filter(file => file.name !== action.payload);
      state.files = newFiles;
    },
    setConfirmPaymentFile: (state, action: PayloadAction<File>) => {
      //@ts-ignore
      state.confirmPaymentFiles = [...state.confirmPaymentFiles, action.payload];
    },
    deleteConfirmationPaymentFile: (state, action: PayloadAction<string>) => {
      //@ts-ignore
      const newFiles: File[] = state.confirmPaymentFiles.filter(file => file.name !== action.payload);
      state.confirmPaymentFiles = newFiles;
    },
    setImage: (state, action: PayloadAction<string>) => {
      state.image = action.payload;
    },
    setInitiator: (state, action: PayloadAction<any>) => {
      state.initiator = action.payload;
    },
    setCreateDate: (state, action: PayloadAction<string>) => {
      state.createDate = action.payload;
    },
    setFinancier: (state, action: PayloadAction<any>) => {
      state.financier = action.payload;
    },
    setVisaDate: (state, action: PayloadAction<string>) => {
      state.visaDate = action.payload;
    },
    setOperationist: (state, action: PayloadAction<any>) => {
      state.operationist = action.payload;
    },
    setProcessPaymentDate: (state, action: PayloadAction<string>) => {
      state.processPaymentDate = action.payload;
    },
    setVisaPayment: (state, action: PayloadAction<boolean>) => {
      state.isVisa = action.payload;
    },
    setPrepaymentStatus: (state, action: PayloadAction<string>) => {
      state.prepaymentStatus = action.payload;
    },
    setFields: (state, action: PayloadAction<any>) => {
      const payment = action.payload.payment;
      const prepayment = action.payload.payment.prepayment;
      const service = action.payload.receipt_goods_services;
      const prepaymentDate = new Date(payment.prepayment?.prepayment_date).getTime();
      const prepaymentStatusDate = new Date(payment.prepayment?.status_change_date).getTime();
      const relatedServicesData = service?.service_related;
      let files = payment.documents.filter((file: any) => file.type === 'file_payment');
      let confirmFiles = payment.documents.filter((file: any) => file.type !== 'file_payment');

      return {
        ...state,
        paymentMethod: payment.payment_method,
        partner: { ...payment.counterparty, label: payment.counterparty.name },
        sum: String(payment.amount),
        time: payment.payment_date,
        paymentKind: payment.payment_kind?.id || null,
        paymentType: payment.payment_type?.id || null,
        expenseItem: payment.expenditure ? { ...payment.expenditure, label: payment.expenditure.name } : null,
        clinic: payment.clinic ? { ...payment.clinic, label: payment.clinic?.name } : null,
        companyAccount: payment.company_account ? { ...payment.company_account, label: payment.company_account?.name } : null,
        comment: payment.comment || '',
        serviceComment: payment.service_comment || '',
        pdv: payment.taxes ? String(payment.taxes) : '',
        fullName: payment.counterparty.name,
        bankCard: payment.bankDetails,
        files: files || [],
        isVisa: payment.status !== 'CREATED' ? true : false,
        initiator: payment.initiator,
        createDate: payment.createdAt,
        financier: payment.financier,
        visaDate: payment.visa_date,
        operationist: payment.operacionist,
        processPaymentDate: payment.operation_date,
        isRegular: !!payment.group_id,
        checkCode: payment.code,
        desiredFinancier: payment.financier ?
          { id: payment.financier.id, name: payment.financier.full_name, label: payment.financier.full_name } : null,
        /* ---Prepayment --- */
        inCharge: prepayment?.responsible || null,
        inChargeDate: prepayment?.prepayment_date || null,
        prepaymentComment: prepayment?.comment || '',
        prepaymentDate: prepaymentDate || null,
        responsible: payment?.prepayment?.responsible ?
          { ...payment.prepayment?.responsible, label: payment.prepayment?.responsible?.full_name }
          : null,
        approver: payment.prepayment?.approver,
        prepaymentStatusDate: prepaymentStatusDate || null,
        prepaymentStatus: payment.prepayment?.status || '',
        isConfirmedFile: payment.isConfirmedFile,
        confirmPaymentFiles: confirmFiles || [],
        /* --Service--- */
        serviceName: service?.service_receiving ?
          { name: service.service_receiving[0]?.name, label: service.service_receiving[0]?.name, id: service.service_receiving[0]?.id }
          : null,
        commentForService: service?.comment || '',
        serviceCompletionDate: service?.date ?
          new Date(service.date).getTime()
          : null,
        servicePartner: service?.counterparty ? {...service.counterparty, label: service.counterparty.name} : null,
        serviceExpenseItem: service?.expenditure ? {...service.expenditure, label: service.expenditure.name} : null,
        serviceAmount: service?.amount ? service.amount : '',
        isNotPutToEnot: service ?
          service.is_enote : false,
        servicePaymentId: service?.id,
        relatedServices: relatedServicesData?.length > 0 ?
          relatedServicesData.map((service: any) => ({
            id: service.id,
            amount: String(service.amount),
            counterparty: { label: service.counterparty.name, name: service.counterparty.name, id: service.counterparty.id },
            expenditure: { label: service.expenditure.name, name: service.expenditure.name, id: service.expenditure.id },
            comment: service.comment
          })) : [],
        relatedServicesValid: relatedServicesData?.length > 0 ?
          relatedServicesData.map((service: any) => ({
            counterpartyValid: true, expenditureValid: true, amountValid: true, commentValid: true
          })) : []
      }
    },
    setServiceFields: (state, action: PayloadAction<any>) => {
      const service = action.payload;
      const relatedServicesData = service?.service_related;

      return {
        ...state,
        serviceName: service?.service_receiving ?
          { name: service.service_receiving[0]?.name, label: service.service_receiving[0]?.name, id: service.service_receiving[0]?.id } : null,
        commentForService: service.comment,
        serviceCompletionDate: service?.date ?
          new Date(service.date).getTime() : null,
        isNotPutToEnot: service ?
          service.is_enote : false,
        servicePaymentId: service?.id,
        relatedServices: relatedServicesData?.length > 0 ?
          relatedServicesData.map((service: any) => ({
            id: service.id,
            amount: String(service.amount),
            counterparty: { label: service.counterparty.name, name: service.counterparty.name, id: service.counterparty.id },
            expenditure: { label: service.expenditure.name, name: service.expenditure.name, id: service.expenditure.id },
            comment: service.comment
          })) : [],
        relatedServicesValid: relatedServicesData?.length > 0 ?
          relatedServicesData.map((service: any) => ({
            counterpartyValid: true, expenditureValid: true, amountValid: true, commentValid: true
          })) : [],
        initiator: service.initiator,
        createDate: service.createdAt,
        financier: service.financier,
        visaDate: service.visa_date,
        operationist: service.operacionist,
        processPaymentDate: service.operation_date,
        servicePartner: action.payload?.counterparty ? {...action.payload.counterparty, label: action.payload.counterparty.name} : null,
        serviceExpenseItem: action.payload?.expenditure ? {...action.payload.expenditure, label: action.payload.expenditure.name} : null,
        serviceAmount: action.payload?.amount ? action.payload.amount : '',
        serviceSource: action.payload?.source,
      }
    },
    setPaymentFields: (state, action: PayloadAction<any>) => {
      const payment = action.payload;
      const prepayment = payment.prepayment;
      const prepaymentDate = new Date(payment.prepayment?.prepayment_date).getTime();
      const prepaymentStatusDate = new Date(payment.prepayment?.status_change_date).getTime();
      let files = payment?.documents?.filter((file: any) => file.type === 'file_payment');
      let confirmFiles = payment?.documents?.filter((file: any) => file.type !== 'file_payment');

      return {
        ...state,
        paymentMethod: payment.payment_method,
        partner: { ...payment.counterparty, label: payment.counterparty.name },
        sum: String(payment.amount),
        paymentKind: payment.payment_kind?.id || null,
        paymentType: payment.payment_type?.id || null,
        expenseItem: payment.expenditure ? { ...payment.expenditure, label: payment.expenditure.name } : null,
        clinic: payment.clinic ? { ...payment.clinic, label: payment.clinic?.name } : null,
        companyAccount: payment.company_account ? { ...payment.company_account, label: payment.company_account?.name } : null,
        comment: payment.comment || '',
        serviceComment: payment.service_comment || '',
        pdv: payment.taxes ? String(payment.taxes) : '',
        fullName: payment.counterparty.name,
        bankCard: payment.bankDetails,
        files: files || [],
        isVisa: payment.status !== 'CREATED' ? true : false,
        initiator: payment.initiator,
        createDate: payment.create_date,
        financier: payment.financier,
        visaDate: payment.visa_date,
        operationist: payment.operacionist,
        processPaymentDate: payment.operation_date,
        isRegular: !!payment.group_id,
        checkCode: payment.code,
        desiredFinancier: payment.financier ?
          { id: payment.financier.id, name: payment.financier.full_name, label: payment.financier.full_name } : null,
        /* ---Prepayment --- */
        inCharge: prepayment?.responsible || null,
        inChargeDate: prepayment?.prepayment_date || null,
        prepaymentComment: prepayment?.comment || '',
        prepaymentDate: prepaymentDate || null,
        responsible: payment?.prepayment?.responsible ?
          { ...payment.prepayment?.responsible, label: payment.prepayment?.responsible?.full_name }
          : null,
        approver: payment.prepayment?.approver,
        prepaymentStatusDate: prepaymentStatusDate || null,
        prepaymentStatus: payment.prepayment?.status || '',
        isConfirmedFile: payment.isConfirmedFile,
        confirmPaymentFiles: confirmFiles || [],
      }
    },
    resetFields: (state, action: PayloadAction<any>) => {
      return { ...initialState, paymentMethod: action.payload.paymentMethod, time: action.payload.time };
    },
    resetServiceFields: (state, action: PayloadAction<any>) => {

      return {
        ...initialState,
        paymentMethod: action.payload.paymentMethod,
        paymentKind: action.payload.paymentKind,
        time: action.payload.time,
        paymentType: action.payload.paymentType,
        serviceName: action.payload.serviceName,
        serviceCompletionDate: action.payload.serviceCompletionDate,
        commentForService: action.payload.commentForService,
        relatedServices: action.payload.relatedServices || [],
        relatedServicesValid: action.payload.relatedServicesValid || [],
        servicePartner: action.payload.servicePartner,
        serviceExpenseItem: action.payload.serviceExpenseItem, 
        serviceAmount: action.payload.serviceAmount,
        partner: action.payload.servicePartner,
        expenseItem: action.payload.serviceExpenseItem,
        comment: action.payload.commentForService,
        sum: action.payload.serviceAmount,
        isNotPutToEnot: true,
      };
    },
    resetAllFields: (state) => {
      return { ...initialState };
    },
  },
});

export const { setPaymentMethod, setBankCard, setClinic, setComment, setCompanyAccount, setExpenseItem, setFullName, setFields, setImage, setRegularAmount, setRelatedServiceDelete,
  setVisaPayment, setInvoice, setPartner, setPaymentKind, setPaymentType, setSum, setTime, setPDV, setFiles, deleteFile, resetFields, setRegularPayment, setConfirmedFile,
  setFinancier, setVisaDate, setFormData, setInitiator, setCreateDate, setOperationist, setProcessPaymentDate, setStartPeriod, setEndPeriod, setRegularPeriod, setRelatedServiceExpenditure,
  setPrepaymentComment, setPrepaymentDate, setResponsible, setInCharge, setInChargeDate, setChangeStatusDate, setApprover, setPrepaymentStatus, setServiceComment, setRelatedServiceAmount,
  setConfirmPaymentFile, deleteConfirmationPaymentFile, setServiceCompletionDate, setServiceName, setNotPutToEnot, setServicePaymentId, setRelatedServices, setRelatedServicePartner,
  setRelatedServicesValid, setDeletedRelatedServicesIds, setRelatedServiceAfterCreate, setResetRelatedServices, setRelatedServicePartnerValid, setRelatedServiceExpenditureValid,
  setRelatedServiceAmountValid, setResetRelatedServicesValid, setDesiredFinancier, setRelatedServiceComment, setRelatedServiceCommentValid, setCommentForService, setPdvChecked,
  setUpdatedPaymentModal, setOpenServiceModal, resetServiceFields, setServiceFields, resetAllFields, setPaymentFields, setServiceExpenseItem, setServicePartner, setServiceAmount } = PaymentCreationSlice.actions;
export const PaymentCreationReducer = PaymentCreationSlice.reducer;