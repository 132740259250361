import { Box, Divider, MenuItem, Stack, Tab, Tabs, TextField, Theme, useMediaQuery } from "@mui/material";
import { ChangeEvent, FC, useCallback, useState } from "react";
import { SearchInput } from "../../../../../../ui-kit/searchInput/searchInput";

interface IProps {
    sortDir: string;
    searchText: string;
    handleSearchPartnerText: (e: ChangeEvent<HTMLInputElement>) => void;
    handleStatusChange: (status: any) => void;
    handleQueryChange: (query: any) => void;
    handleSortChange: (sortDir: string) => void;
};

export const PartnerListSearch: FC<IProps> = ({ handleStatusChange, handleQueryChange, handleSortChange, sortDir, searchText, handleSearchPartnerText }) => {
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const [currentTab, setCurrentTab] = useState('Type1');

    const tabOptions = [
        { label: "Type1", value: "Type1" },
        { label: "Type2", value: "Type2" },
        { label: "Type3", value: "Type3" },
    ];
    const sortOptions = [
        { label: 'Нові', value: 'desc' },
        { label: 'Старі', value: 'asc' }
    ];

    const handleTabsChange = useCallback((event: ChangeEvent<{}>, tab: string): void => {
        setCurrentTab(tab);
        const status = tab === 'Type1' ? undefined : tab;
        handleStatusChange(status);
        // onPageChange(null, 0);
    }, []);

    const onSortChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        const sortDir = event.target.value as string;
        handleSortChange(sortDir);
    }, [handleSortChange]);

    return (
        <Box m={1}>
            <Tabs indicatorColor="primary" onChange={handleTabsChange} scrollButtons="auto" sx={{ py: 2 }} textColor="primary" value={currentTab} variant="scrollable">
                {tabOptions.map((tab) => (
                    <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
            </Tabs>
            <Divider />
            <Stack alignItems="center" direction="row" flexWrap="wrap" gap={3} sx={{ py: 2 }}>
                <SearchInput
                    searchText={searchText}
                    label="Пошук"
                    handleSearch={handleSearchPartnerText}
                />
                {!downSm ?
                    <TextField
                        id="outlined-basic"
                        label='Сортувати'
                        select variant="outlined"
                        value={sortDir}
                        onChange={onSortChange}
                        sx={{ width: '150px' }}
                    >
                        {sortOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    : null}
            </Stack>
        </Box>
    )
};