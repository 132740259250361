import { FC, useMemo } from "react";
import { useAccountList } from "../presenters/useAccountList";
import { Box, Button, Card, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { getStyles } from "./styles";
import { SearchInput } from "../../../../ui-kit/searchInput/searchInput";
import { ListContainer } from "../../../partner/partner-list/ui/components/listContainer";
import { SideDrawer } from "../../../../ui-kit/sideDrawer";
import { AccountDetails } from "./components/accountDetails";
import { AccountListTable } from "./components/accountListTable";

export const AccountList: FC = () => {
    const { t } = useTranslation();
    const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md')); 
    const styles = useMemo(() => getStyles(downMd), [downMd]);
    const { state, currentAccount, handleAccountClose, handleAccountOpen, handlePageChange, handleQueryChange, updateAccounts,
        rootRef, filters, drawer, pageAmount, searchText, handleSearchAccountText, currentPage } = useAccountList(); 

    return (
        <Box component="main" ref={rootRef} sx={styles.main} >
            <Box ref={rootRef} sx={styles.container}>
                <ListContainer open={drawer.isOpen}>
                    <Stack direction={downMd ? 'column' : 'row'} justifyContent='space-between' mt={3}>
                        <Typography variant="h4" textAlign={downMd ? 'center' : 'start'}>{t(tokens.account.zooluxAccounts)}</Typography>
                        <Stack direction='row' justifyContent='center'>
                            <Button
                                variant="contained"
                                size="large"
                                sx={styles.updateButton}
                                onClick={updateAccounts}
                            >
                                {t(tokens.account.updateAccounts)}
                            </Button>
                        </Stack>
                    </Stack>
                    <Stack mt={3}>
                        <SearchInput
                            searchText={searchText}
                            label={t(tokens.account.searchAccount)}
                            handleSearch={handleSearchAccountText}
                        />
                        {state.accountsList.length ?
                            <Card elevation={10} sx={styles.card}>
                                <AccountListTable
                                    accounts={state.accountsList}
                                    pageAmount={pageAmount}
                                    currentPage={currentPage}
                                    handleAccountOpen={handleAccountOpen}
                                    handlePageChange={handlePageChange}
                                />
                            </Card>
                            :
                            null
                        }
                    </Stack>
                </ListContainer>
                <SideDrawer
                    open={drawer.isOpen}
                    container={rootRef.current}
                    value={currentAccount}
                    onClose={handleAccountClose}
                    drawerContent={
                        <AccountDetails
                            onApprove={handleAccountClose}
                            onReject={handleAccountClose}
                            account={currentAccount}
                            filters={filters}
                        />
                    }
                />
            </Box>
        </Box>
    )
};



