import { Checkbox, Stack, TextField, Typography } from "@mui/material"
import { FC, useCallback } from "react"
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";

interface IProps {
    amount: string;
    handleCopy: (value: string) => void;
};

export const SumField: FC<IProps> = ({ amount, handleCopy }) => {
    const { t } = useTranslation();
    const copyValue = useCallback(() => handleCopy(amount), [amount]);
    
    return (
        <Stack display='flex' direction='row' spacing={2}>
            <Stack flex={1} direction='row' justifyContent='flex-end' alignItems='center'>
                <Typography>{t(tokens.payment.sum)}</Typography>
            </Stack>
            <Stack flex={3} direction='row'>
                <Stack flex={2}>
                    <TextField
                        value={amount + ' грн.'}
                        size="small"
                        InputProps={{ readOnly: true }}
                        sx={{ input: { cursor: 'pointer' }, textArea: { cursor: 'pointer' } }}
                        onClick={copyValue} 
                    />
                </Stack>
            </Stack>
        </Stack>
    )
};