import { useTranslation } from "react-i18next";
import { MenuSection } from "../types/settings";
import { useMemo } from "react";
import { getMenuSections } from "../layouts/dashboard/config";
import { useGetPermissions } from "./useGetPermission";
import { useLocation } from "react-router-dom";

export const useTranslateNavSections = (): MenuSection[] => {
    const { t } = useTranslation();
    const permissions = useGetPermissions();
    const location = useLocation();

    return useMemo(() => getMenuSections(t, permissions, location.pathname), [t, permissions, location]);
};