import { SagaIterator } from "redux-saga";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setCurrentUser, setLoading } from "../../repository/actions";
import toast from "react-hot-toast";

function* SignInWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError }: IResponse<any> = yield call(requester.post, "/zoolux-api/user-auth/sign-in", payload);
        if (isError) {
            toast.error("неправильний номер телефону або пароль")
        } else {
            yield put(setLoading(true));
            yield delay(1600);
            yield call([localStorage, localStorage.setItem], "accessToken", data.token);
            yield put(setCurrentUser(data.user));
            globalThis.localStorage.setItem("isLoggedIn", "true");
        }
    } catch (error) {
        console.error("SignInWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* SignInWatcher() {
    yield takeLatest("@saga/SIGN_IN", SignInWorker);
};
