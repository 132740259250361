import { SagaIterator } from "redux-saga";
import { put, call, takeLatest } from "redux-saga/effects";
import { requester } from "../../../../libs/requester/requester-axios";
import { IResponse } from "../../../../libs/requester/IResponse";
import { setLoading, setUpdateTelegramCodeError } from "../../repository/slice";

function* editTelegramCodeWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, isError, message }: IResponse<any> = yield call(requester.post, "/zoolux-api/telegram-bot/change-secret-key", payload);
        if (isError) {
            yield put(setUpdateTelegramCodeError(message));
        } else {
            yield put(setUpdateTelegramCodeError(null));
        }
    } catch (error) {
        console.error("editTelegramCodeWorker: ", error);
    } finally {
        yield put(setLoading(false));
    }
};

export function* editTelegramCodeWatcher() {
    yield takeLatest("@saga/EDIT_TELEGRAM_CODE", editTelegramCodeWorker);
};