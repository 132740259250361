import { Checkbox, IconButton, Stack, SvgIcon, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { Dispatch, FC, SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from '@mui/icons-material/Delete';
import PersonIcon from '@mui/icons-material/Person';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import { Link } from "react-router-dom";
import { tokens } from "../../../../../locales/tokens";

interface IProps {
    user: any;
    tab: string;
    handleDeleteUser: (phone: string) => void;
};

export const UserListItem: FC<IProps> = ({ user, handleDeleteUser, tab }) => {
    const { t } = useTranslation();
    const deleteUser = useCallback(() => handleDeleteUser(user.phone), [user.phone]);

    return (
        <TableRow hover key={user.id}>
            <TableCell align="center" >
                <Typography variant="subtitle2">
                    {user?.id}
                </Typography>
            </TableCell>
            <TableCell align="center" >
                <Typography variant="subtitle2">
                    {user.first_name + user.last_name}
                </Typography>
            </TableCell>
            <TableCell align="center" >
                <Typography textAlign="center" variant="subtitle2">
                    {user.telegram_id}
                </Typography>
            </TableCell>
            <TableCell align="center" >
                <Typography variant="subtitle2">
                    {user.username || '-'}
                </Typography>
            </TableCell>
            <TableCell align="center" >
                <Typography variant="subtitle2">
                    {user.phone || t(tokens.telegram.noData)}
                </Typography>
            </TableCell>
            <TableCell align="right">
                <Stack direction="row" justifyContent="flex-end" pr={3}>
                    {tab === 'Removed' ?
                        <Tooltip title={t(tokens.telegram.restoreUser)}>
                            <IconButton onClick={deleteUser}>
                                <SvgIcon><ReplayCircleFilledIcon /></SvgIcon>
                            </IconButton>
                        </Tooltip>
                        :
                        <Tooltip title={t(tokens.telegram.deleteUser)}>
                            <IconButton onClick={deleteUser}>
                                <SvgIcon><DeleteIcon /></SvgIcon>
                            </IconButton>
                        </Tooltip>
                    }
                </Stack>
            </TableCell>
        </TableRow>
    )
};