import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";

function* addImageToContractWorker({ payload }: any): SagaIterator {
    try {
        const formData = new FormData();
        for (const [key, value] of Object.entries(payload as Object)) {
            const isValue = value !== null && value !== undefined && value !== "";
            if (isValue) {
                if (Array.isArray(value)) {
                    value.forEach(file => {
                        formData.append(key, file);
                    });
                } else {
                    formData.append(key, value);
                }
            }
        }; 
        const { data, isError, message }: IResponse<any> = yield call(requester.post, "link..............", formData);
        if (isError) {
            toast.error(message);
        } else {
            // yield put(setPartnersList(data.data));
        }
    } catch (error) {
        console.error("addImageToContractWorker: ", error);
    } finally {

    }
};

export function* addImageToContractWatcher() {
    yield takeLatest("@saga/ADD_IMAGE_TO_CONTRACT", addImageToContractWorker);
};
