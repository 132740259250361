import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";
import { setAllExpenseItems, setExpenseItemsAmount } from "../../repository/slice";

function* getAllExpenseItemsWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.get, "/zoolux-api/admin/expenditure", payload);
        if (isError) {
            toast.error(message);
        } else {
            yield put(setAllExpenseItems(data.rows));
            yield put(setExpenseItemsAmount(data.count));
        }
    } catch (error) {
        console.error("getAllExpenseItemsWorker: ", error);
    } finally {
    }
};

export function* getAllExpenseItemsWatcher() {
    yield takeLatest("@saga/GET_ALL_EXPENSE_ITEMS", getAllExpenseItemsWorker);
};

