import { FC } from "react";
import { getStyles } from "./styles";
import { Avatar, Box, Button, Stack, SvgIcon, Typography } from "@mui/material";
import CameraAltIcon from '@mui/icons-material/CameraAlt';

export const UserLogo: FC = () => {
    const styles = getStyles();

    return (
        <>
            <Box sx={styles.container}>
                <Box sx={styles.avatarWrapper}>
                    <Box sx={styles.selectBtn} >
                        <Stack alignItems="center" direction="row" spacing={1}>
                            <SvgIcon color="inherit">
                                <CameraAltIcon />
                            </SvgIcon>
                            <Typography
                                color="inherit"
                                variant="subtitle2"
                                sx={{ fontWeight: 700 }}
                            >
                                Select
                            </Typography>
                        </Stack>
                    </Box>
                    <Avatar
                        src='/images/account/people.png'
                        sx={styles.avatar}
                    />
                </Box>
            </Box>
            <Button
                size="medium"
                variant="contained"
                sx={styles.changeBtn}
                onClick={() => { }}
            >
                Change
            </Button>
        </>
    )
}