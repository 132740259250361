import { SagaIterator } from "redux-saga";
import { put, call, takeEvery } from "redux-saga/effects";
import { requester } from "../../../../libs/requester/requester-axios";
import { IResponse } from "../../../../libs/requester/IResponse";
import toast from "react-hot-toast";
import { setServicePaymentId } from "../../../paymentCreation/repository/slice";

function* deleteServicePaymentWorker({ payload }: any): SagaIterator {
    try {
        const { isError, message }: IResponse<any> = yield call(requester.delete, "/zoolux-api/receipt-goods-services/delete/" + payload.id);
        if (isError) {
            toast.error(message);
        } else {
            yield put(setServicePaymentId(null));
        }
    } catch (error) {
        console.error("deleteServicePaymentWorker: ", error);
    }
};

export function* deleteServicePaymentWatcher() {
    yield takeEvery("@saga/DELETE_SERVICE_PAYMENT", deleteServicePaymentWorker);
};