import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';
import { INomeclature } from './types';

interface IState {
  isLoading: boolean,
  nomenclatureList: INomeclature[],
  nomenclatureCount: number,
  nomenclatureError: IError;
};

const initialState: IState = {
  isLoading: false,
  nomenclatureList: [],
  nomenclatureCount: 0,
  nomenclatureError: null
};

const NomenclatureSlice = createSlice({
  name: 'nomenclature',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setNomenclatureList: (state, action: PayloadAction<any>) => {
      state.nomenclatureList = action.payload.list;
      state.nomenclatureCount = action.payload.count;
    },
    setEditNomenclatureError: (state, action: PayloadAction<IError>) => {
      state.nomenclatureError = action.payload;
    },
  },
});

export const { setLoading, setNomenclatureList, setEditNomenclatureError } = NomenclatureSlice.actions;
export const NomenclatureReducer = NomenclatureSlice.reducer;