import { SelectChangeEvent } from "@mui/material";
import { ChangeEvent, useCallback } from "react";
import { useDispatch } from "react-redux";
import { tokens } from "../../../../locales/tokens";
import { useTranslation } from "react-i18next";
import { setRegularAmount, setRegularPeriod } from "../../../../store/paymentCreation/repository/slice";
import { setPaymentFieldValidate } from "../../../../store/operationValidation/repository/slice";

export const useRegularPaymentForm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const periodOptions = [
        { id: "Months", name: t(tokens.payment.months) },
        { id: "Weeks", name: t(tokens.payment.weeks) }
    ];

    const handlePeriod = useCallback((event: SelectChangeEvent) => {
        dispatch(setRegularPeriod(event.target.value));
    }, []);

    const onChangeRegularAmount = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (/^\d*$/.test(event.target.value)) {
            dispatch(setRegularAmount(event.target.value));
            dispatch(setPaymentFieldValidate("regularAmount"));
        };
    }, []);

    return { handlePeriod, periodOptions, onChangeRegularAmount }
};