import { FC, ReactNode } from "react";
import { Drawer, Stack, Theme, useMediaQuery } from "@mui/material";
import { getStyles } from "./styles";

interface IProps {
    children: ReactNode;
    isOpen: boolean;
    onNavOpen: () => void;
}

export const SideDrawer: FC<IProps> = ({ children, isOpen, onNavOpen }) => {
    const styles = getStyles();
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

    if (lgUp) {
        return (
            <Drawer anchor="left" open variant="permanent" PaperProps={{ sx: styles.drawer }} >
                <Stack height='100%'>
                    {children}
                </Stack>
            </Drawer>
        )
    }
    return (
        <Drawer anchor="left" open={isOpen} variant="temporary" PaperProps={{ sx: styles.drawer }} onClose={onNavOpen}>
            <Stack height='100%'>
                {children}
            </Stack>
        </Drawer>
    )
}

