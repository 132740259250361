import { Box, Card, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";

interface IProps {
    path: string;
    title: string;
    icon: string;
};

export const OperationCard: FC<IProps> = ({ path, title, icon }) => (
    <Card className='card action' component={Link} to={path} sx={{ height: "152px" }}>
        <Stack justifyContent="center" alignItems="center">
            <Stack rowGap={1} alignItems={"center"}>
                <Typography variant="h5" >
                    {title}
                </Typography>
                <Box width={"32px"} component={'img'} src={icon}></Box>
            </Stack>
        </Stack>
    </Card>
);