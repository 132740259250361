
import toast from 'react-hot-toast';
import { requester } from '../../../libs/requester/requester-axios';
import { deleteEmptyParams } from '../../../utils';
import { zooluxAPI } from '../../zooluxAPI';
import { IParamsCreateFixedAsset, IResponseCreateFixedAsset } from '../repository/IFixedAsset';

const fixedAsset = zooluxAPI.injectEndpoints({
    endpoints: (builder) => ({
        updateFixedAsset: builder.mutation<IResponseCreateFixedAsset, { id: number, params: IParamsCreateFixedAsset; }>({
            async queryFn({ id, params }, { dispatch }) {
                try {
                    const { data, isError, message }: any = await requester.patch('/zoolux-api/fixed-assets/' + id, deleteEmptyParams(params));
                    if (isError) {
                        toast.error(message);
                        throw new Error(message);
                    };
                    return { data };
                } catch (e) {
                    throw e;
                }
            },
        }),

    }),
    overrideExisting: false,
});

export const { useUpdateFixedAssetMutation } = fixedAsset;