import { Box, Card, Stack } from "@mui/material";
import { FC, useMemo } from "react";
import { getStyles } from "./styles";
import { CalendarToolbar } from "./components/calendarToolbar";
import Calendar from '@fullcalendar/react';
import { CalendarContainer } from "./components/calendarContainer";
import { useCalendar } from "../presenters/useCalendar";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import ukLocale from '@fullcalendar/core/locales/uk';
import { CalendarFilters } from "./components/calendarFilters";

import '@fullcalendar/common/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '@fullcalendar/list/main.css';
import '@fullcalendar/timeline/main.css';
import { PaymentForDay } from "../../paymentsForDay/ui";
import { PaymentsForWeek } from "../../paymentsForWeek/ui";

export const PaymentList: FC = () => {
    const { date, view, calendarRef, paymentsList, handleAddClick, handleDatePrev, handleDateNext, handleViewChange, handleRangeSelect,
        handleEventSelect, handleEventResize, handleEventDrop, handlePeriodChoose, chips, setChips, handleDatesRender, handleStatusPayments,
        period, setPeriod, handleMethodPayments, mode, handleChangeMode, handleIsDeletedPayments, handleFilterReset, paymentsDayList, weekFilters, setWeekFilters,
        handleChangeSearch, handleSearchTextChange, handleSortChange, handleImportPayments, handleHiddenPayments, dayPagination, pageAmount, paymentsWeekListCount,
        handlePageChange, handleConfirmPayments, handleFinancierPayments, handleEventAllow, handleListDate, currentDay, setCurrentDay, paymentsWeekList } = useCalendar();
    const styles = useMemo(() => getStyles(mode), [mode]);

    return (
        <>
            <Box component="main" sx={styles.toolbarContainer} >
                <Stack>
                    <CalendarToolbar
                        date={date}
                        view={view}
                        period={period}
                        setPeriod={setPeriod}
                        handlePeriodChoose={handlePeriodChoose}
                        onAddClick={handleAddClick}
                        onDateNext={handleDateNext}
                        onDatePrev={handleDatePrev}
                        handleListDate={handleListDate}
                        onViewChange={handleViewChange}
                        currentDay={currentDay}
                        setCurrentDay={setCurrentDay}
                    />
                    {view === 'dayGridMonth' &&
                        <CalendarFilters
                            chips={chips}
                            mode={mode}
                            setChips={setChips}
                            handleFilterReset={handleFilterReset}
                            handleIsDeletedPayments={handleIsDeletedPayments}
                            handleChangeMode={handleChangeMode}
                            handleStatusPayments={handleStatusPayments}
                            handleImportPayments={handleImportPayments}
                            handleMethodPayments={handleMethodPayments}
                            handleSearchTextChange={handleSearchTextChange}
                            handleChangeSearch={handleChangeSearch}
                            handleSortChange={handleSortChange}
                            handleHiddenPayments={handleHiddenPayments}
                            handleConfirmPayments={handleConfirmPayments}
                            handleFinancierPayments={handleFinancierPayments}
                        />}
                    {view === 'dayGridMonth' &&
                        <Card elevation={5} sx={styles.calendar}>
                            <CalendarContainer>
                                <Calendar
                                    locale={ukLocale}
                                    allDayMaintainDuration
                                    dayMaxEventRows={5}
                                    droppable
                                    editable
                                    eventClick={handleEventSelect}
                                    eventDisplay="block"
                                    eventDrop={handleEventDrop}
                                    eventResizableFromStart
                                    eventResize={handleEventResize}
                                    events={paymentsList}
                                    headerToolbar={false}
                                    height={800}
                                    initialDate={date}
                                    initialView={view}
                                    plugins={[
                                        dayGridPlugin,
                                        interactionPlugin,
                                        listPlugin,
                                        timeGridPlugin,
                                        timelinePlugin,
                                    ]}
                                    ref={calendarRef}
                                    rerenderDelay={10}
                                    select={handleRangeSelect}
                                    selectable
                                    weekends
                                    datesSet={handleDatesRender}
                                    displayEventTime={false}
                                    eventAllow={handleEventAllow}
                                />
                            </CalendarContainer>
                        </Card>}
                    {view === 'listDay' && 
                    <PaymentForDay
                        paymentsDayList={paymentsDayList}
                        page={dayPagination.page}
                        pageAmount={pageAmount}
                        handlePageChange={handlePageChange}
                    />}
                    {view === 'listWeek' &&
                        <PaymentsForWeek
                            paymentsWeekList={paymentsWeekList}
                            paymentsCount={paymentsWeekListCount}
                            weekFilters={weekFilters}
                            setWeekFilters={setWeekFilters}
                        />
                    }
                </Stack>
            </Box>
        </>
    )
}; 