import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "../../../../hooks/useDebounce";
import { getAllExpenseItems } from "../../../../store/expenseItem/useCases/getAllExpenseItems/action";
import { selectExpenseItem } from "../../../../store/expenseItem/repository/selector";
import { refreshExpenseItems } from "../../../../store/expenseItem/useCases/refreshExpenseItems/action";

export const useExpenseItemList = () => {
    const dispatch = useDispatch();
    const rootRef = useRef<HTMLDivElement | null>(null);
    const [drawer, setDrawer] = useState<{ isOpen: boolean; data?: string; }>({ isOpen: false, data: undefined });
    const [searchText, setSearchText] = useState('');
    const [filters, updateFilters] = useState({ page: 0, query: '' });
    const [state, setState] = useState<{ expenseItemList: any[], expenseItemCount: number }>({ expenseItemList: [], expenseItemCount: 0 });
    const [pageAmount, setPageAmount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const { expenditure, expenseItemCount } = useSelector(selectExpenseItem);

    useEffect(() => {
        dispatch(getAllExpenseItems({offset: 0, limit: 20, search: filters.query}));
    }, [filters]);

    useEffect(() => {
        if (expenditure?.length) {
            setState({ expenseItemList: expenditure, expenseItemCount: 0 as number })
        } else {
            setState({ expenseItemList: [], expenseItemCount: 0 as number })
        }
    }, [expenditure]);

    useEffect(() => {
        const offset = (filters.page - 1) * 20;
        dispatch(getAllExpenseItems({ offset: offset, limit: 20, search: filters.query }));
    }, [filters.page]);

    useEffect(() => {
        setPageAmount(Math.ceil(expenseItemCount / 20));
    }, [expenseItemCount]);

    const currentItem = useMemo(() => {
        if (!drawer.data) {
            return undefined;
        };
        return state.expenseItemList.find((item) => item.id === Number(drawer.data));
    }, [drawer, state.expenseItemList]);

    useEffect(() => {
        debouncedHandleExpenseItemSeacrh(searchText);
    }, [searchText]);

    const handleQueryChange = useCallback((query: string): void => {
        updateFilters((prevState) => ({ ...prevState, query }));
    }, [updateFilters]);

    const onSearchExpenseItem = (value: string) => {
        const query = value ? value : '';
        handleQueryChange(query);
        setCurrentPage(1);
    };

    const { debouncedWrapper: debouncedHandleExpenseItemSeacrh } = useDebounce(onSearchExpenseItem, 400);

    const handleSearchExpenseItemText = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const updateExpenseItems = () => {
        dispatch(refreshExpenseItems());
    };

    const handleExpenseItemOpen = useCallback((accountId: string): void => {
        if (drawer.isOpen && drawer.data === accountId) {
            setDrawer({ isOpen: false, data: undefined });
            return;
        }
        setDrawer({ isOpen: true, data: accountId });
    }, [drawer]);

    const handleExpenseItemClose = useCallback((): void => {
        setDrawer({ isOpen: false, data: undefined });
    }, []);

    const handlePageChange = useCallback((event: ChangeEvent<unknown>, page: number): void => {
        updateFilters((prevState) => ({ ...prevState, page }));
        setCurrentPage(page);
    }, [updateFilters]);

    return {
        rootRef, drawer, searchText, handleSearchExpenseItemText, state, updateExpenseItems, handleExpenseItemOpen, handlePageChange,
        pageAmount, currentItem, handleExpenseItemClose, filters, currentPage
    }
};