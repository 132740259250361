import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setPaymentKind } from "../../repository/actions";
import toast from "react-hot-toast";

function* getPaymentKindWorker(): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call( requester.get, "/zoolux-api/payment-kind");

        if (isError) {
            toast.error(message);
        } else {
            const kinds = data.filter((kind: any) => kind.key !== 'partial-payment');
            yield put(setPaymentKind(kinds));
        }
    } catch (error) {
        console.error("getPaymentKindWorker: ", error);
    } finally {
        
    }
};

export function* getPaymentKindWatcher() {
    yield takeLatest("@saga/GET_PAYMENT_KIND", getPaymentKindWorker);
};
