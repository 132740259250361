import { SagaIterator } from "redux-saga";
import { put, call, takeEvery } from "redux-saga/effects";
import { requester } from "../../../../libs/requester/requester-axios";
import { IResponse } from "../../../../libs/requester/IResponse";
import toast from "react-hot-toast";
import { deleteConfirmationPaymentFile, deleteFile } from "../../../paymentCreation/repository/slice";

function* deleteFileWorker({ payload }: any): SagaIterator {
    try {
        const url = payload.mode === "Payment" ? "/zoolux-api/payments/delete-files" : "/zoolux-api/payments/delete-files";
        const { data, isError, message }: IResponse<any> = yield call(requester.delete, url, payload.body);
        if (isError) {
            toast.error(message);
        } else {
            if(payload.body.type === "DEFAULT"){
                yield put(deleteFile(payload.body.files_name[0]));
            };
            if(payload.body.type === "CONFIRM"){
                yield put(deleteConfirmationPaymentFile(payload.body.files_name[0]));
            };
        }
    } catch (error) {
        console.error("deleteFileWorker: ", error);
    } finally {

    }
};

export function* deleteFileWatcher() {
    yield takeEvery("@saga/REMOVE_FILE", deleteFileWorker);
};