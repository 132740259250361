import { Stack, Typography } from "@mui/material"
import { FC } from "react"

interface IProps{
    title: string;
    data: string;
    
};

export const ReceiptField: FC<IProps> = ({title, data}) => {

    return (
        <Stack direction='row' spacing={2} alignItems='flex-start' width='100%'>
            <Typography variant="body1" fontWeight={700} >{title}</Typography>
            <Typography variant="body1">{data}</Typography>
        </Stack>
    )
};