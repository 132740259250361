import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { setUsers, setUsersCount } from "../../repository/actions";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";

function* getUsersAllWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call( requester.get, "/zoolux-api/admin/users", payload);
        if (isError) {
            toast.error("користувачі не знайдені")
        } else {
            yield put(setUsers(data.rows));
            yield put(setUsersCount(data.count));
        }
    } catch (error) {
        console.error("getUsersAllWorker: ", error);
    } finally {
        
    };
};

export function* getUsersAllWatcher() {
    yield takeLatest("@saga/GET_USERS_ALL", getUsersAllWorker);
};
