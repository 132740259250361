
import toast from 'react-hot-toast';
import { requester } from '../../../libs/requester/requester-axios';
import { deleteEmptyParams } from '../../../utils';
import { zooluxAPI } from '../../zooluxAPI';
import { IParamsLocation, IResponseGetLocations } from '../repository/ILocation';

const location = zooluxAPI.injectEndpoints({
    endpoints: (builder) => ({
        getLocations: builder.query<IResponseGetLocations, IParamsLocation>({
            async queryFn(params, { dispatch }) {
                try {
                    const { data, isError, message }: any = await requester.get('/zoolux-api/locations', deleteEmptyParams(params));
                    if (isError) {
                        toast.error(message);
                        throw new Error(message);
                    };
                    return { data };
                } catch (e) {
                    throw e;
                }
            },
            keepUnusedDataFor: 0
        }),

    }),
    overrideExisting: false,
});

export const { useGetLocationsQuery } = location;