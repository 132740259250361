import { Stack, TextField, Typography } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { tokens } from "../../../../../../locales/tokens";
import { useTranslation } from "react-i18next";

interface IProps {
    title: string;
    label: string;
    isValid: boolean;
    value: string;
    isMultiline: boolean;
    rows: number;
    symbolsAmount: number;
    onChangeValue: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const TextInput: FC<IProps> = ({ isMultiline, isValid, label, onChangeValue, rows, title, value, symbolsAmount }) => {
    const { t } = useTranslation();
     
    return (
        <Stack direction='column' spacing={1} width="100%">
            <Typography
                variant='h6'
                color={isValid ? "primary" : "error"}
            >
                {title}
            </Typography>
            <TextField
                value={value}
                onChange={onChangeValue}
                label={label}
                autoComplete="off"
                error={!isValid}
                multiline={isMultiline}
                rows={rows}
                inputProps={{ maxLength: symbolsAmount }}
            />
            {
                isMultiline &&
                <Typography
                    variant="caption"
                    color='GrayText'
                    mt={1}
                >
                    {t(tokens.contracts.maxSymbols)} {value.length} / {symbolsAmount}
                </Typography>
            }
        </Stack>
    )
};