import { FC } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import { Grid, SelectChangeEvent, Theme, Typography, useMediaQuery } from "@mui/material";
import { StatusSelect } from "../statusSelect";

interface IProps {
    status: string;
    handleChangeStatus: (event: SelectChangeEvent) => void;
};

export const StatusTab: FC<IProps> = ({ status, handleChangeStatus }) => {
    const { t } = useTranslation();
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    return (
        <Grid container>
            <Grid item sm={6}>
                <Typography variant="subtitle1">{t(tokens.user.userStatus)}</Typography>
                <Typography color="text.secondary" variant="body2">{t(tokens.user.statusDesc)}</Typography>
            </Grid>
            <Grid item sm={6} mt={downSm ? 2 : 0}>
                <StatusSelect
                    status={status}
                    handleChangeStatus={handleChangeStatus}
                />
            </Grid>
        </Grid>
    )
};