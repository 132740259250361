import { Button, Stack, SvgIcon } from "@mui/material";
import { FC, useMemo } from "react";
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { Link } from "react-router-dom";
import { paths } from "../../../../paths";
import { getStyles } from "./styles";

export const ServiceEmptyState: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    
    return (
        <Stack alignItems="center">
            <img
                src="/images/account/empty-document.png"
                alt="document"
                width={280}
                height={280}
            />
            <Button
                variant="text"
                size="medium"
                sx={styles.createBtn}
                startIcon={(<SvgIcon fontSize="large"><AddIcon /></SvgIcon>)}
                component={Link}
                to={paths.dashboard.serviceHistory.create}
            >
                {t(tokens.serviceHistory.createService)}
            </Button>
        </Stack>
    )
};