import { FC, ReactNode, createContext, useEffect, useState } from "react";

interface IProps {
    isLoggedIn: boolean;
    setLoggedIn: (value: boolean) => void;
};

export const AuthContext = createContext<IProps | undefined>(undefined);

interface AuthProviderProps {
    children: ReactNode;
};

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
    const [isLoggedIn, setLoggedInState] = useState<boolean>(() => {
        const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
        return storedIsLoggedIn ? JSON.parse(storedIsLoggedIn) : false;
    });

    const setLoggedIn = (value: boolean) => {
        setLoggedInState(value);
        localStorage.setItem("isLoggedIn", JSON.stringify(value));
    };

    useEffect(() => {
        localStorage.setItem("isLoggedIn", JSON.stringify(isLoggedIn));
    }, [isLoggedIn]);

    return (
        <AuthContext.Provider value={{ isLoggedIn, setLoggedIn }}>
            {children}
        </AuthContext.Provider>
    );
};