import { IError } from "../../../types/error";

interface IInitialState {
    isLoading: boolean,
    error: IError,
    payments: any[],
    financiers: any[],
    paymentsCount: number,
    singlePayment: any,
    expenditure: any[],
    paymentMethods: any[],
    paymentTypes: any[],
    paymentKinds: any[],
    partners: any[],
    servicePartners: any[],
    accountList: any[],
    clinics: any[],
    bankCards: any[],
    partnersAmount: number,
    errorsGetPayments: IError,
    getPaymentsListError: IError,
    visaError: IError,
    processPaymentError: IError,
    paymentsDayList: any[],
    paymentsDayListCount: number,
    paymentsWeekList: any[],
    paymentsWeekListCount: number,
};

const initialState: IInitialState = {
    isLoading: false,
    error: null,
    payments: [],
    financiers: [],
    paymentsCount: 0,
    singlePayment: null,
    expenditure: [],
    paymentMethods: [],
    paymentTypes: [],
    paymentKinds: [],
    partners: [],
    servicePartners: [],
    accountList: [],
    clinics: [],
    bankCards: [],
    partnersAmount: 0,
    errorsGetPayments: null,
    getPaymentsListError: null,
    processPaymentError: null,
    visaError: null,
    paymentsDayList: [],
    paymentsDayListCount: 0,
    paymentsWeekList: [],
    paymentsWeekListCount: 0,
};

export function paymentReducer(state: IInitialState = initialState, action: any): IInitialState {
    const { type, payload } = action;
    switch (type) {
        case 'SET_LOADING_PAYMENTS':
            return { ...state, isLoading: payload };
        case 'SET_ERROR':
            return { ...state, error: payload };
        case 'SET_VISA_ERROR':
            return { ...state, visaError: payload };
        case 'SET_PAYMENTS_LIST_ERROR':
            return { ...state, getPaymentsListError: payload };
        case 'SET_PAYMENTS':
            return { ...state, payments: payload.list, paymentsCount: payload.count };
        case 'SET_PAYMENTS_LIST':
            return { ...state, paymentsDayList: payload.list, paymentsDayListCount: payload.count };
        case 'SET_PAYMENTS_WEEK':
            return { ...state, paymentsWeekList: payload.list, paymentsWeekListCount: payload.count };
        case 'SET_ERRORS_GET_PAYMENTS':
            return { ...state, errorsGetPayments: payload };
        case 'SET_PROCESS_PAYMENT_ERROR':
            return { ...state, processPaymentError: payload };
        case 'SET_SINGLE_PAYMENT':
            return { ...state, singlePayment: payload };
        case 'SET_EXPENDITURE':
            return { ...state, expenditure: payload };
        case 'SET_PAYMENT_METHOD':
            return { ...state, paymentMethods: payload };
        case 'SET_PAYMENT_TYPE':
            return { ...state, paymentTypes: payload };
        case 'SET_PAYMENT_KIND':
            return { ...state, paymentKinds: payload };
        case 'SET_PARTNERS':
            return { ...state, partners: payload };
        case 'SET_SERVICE_PARTNERS':
            return { ...state, servicePartners: payload };
        case 'SET_PARTNERS_AMOUNT':
            return { ...state, partnersAmount: payload };
        case 'SET_ACCOUNT_LIST':
            return { ...state, accountList: payload };
        case 'SET_CLINICS':
            return { ...state, clinics: payload };
        case 'SET_FINANCIERS':
            return { ...state, financiers: payload };
        case 'SET_BANK_CARDS':
            return { ...state, bankCards: payload };
        default:
            return state;
    }
};

