import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";
import { setConfiguration } from "../../repository/slice";

function* getConfigurationWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.get, "/zoolux-api/admin/configurations", payload);
        if (isError) {
            toast.error(message);
        } else {
            yield put(setConfiguration(data));
        }
    } catch (error) {
        console.error("getConfigurationWorker: ", error);
    } finally {
    }
};

export function* getConfigurationWatcher() {
    yield takeLatest("@saga/GET_CONFIGURATION", getConfigurationWorker);
};

