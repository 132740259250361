import { FC } from "react";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../locales/tokens";

dayjs.locale('uk');

interface IProps {
    value: number | null;
    handleDate: (date: Date | null) => void;
};

export const TimePicker: FC<IProps> = ({ handleDate, value }) => {
    const { t } = useTranslation();
    const time = value ? new Date(value) : null;

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                onChange={handleDate}
                value={time}
                renderInput={(props: any) =>
                    <TextField
                        variant="outlined"
                        size="small"
                        color="primary"
                        {...props}
                        label={t(tokens.paymentsList.date)}
                    />
                }
            />
        </LocalizationProvider>
    )
};