import { type FC } from 'react';
import { TableCell, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../../locales/tokens';

interface IProps {
    account: any;
    handleAccountOpen: (partnerId: string) => void;
};

export const TableListItem: FC<IProps> = ({ account, handleAccountOpen }) => {
    const { t } = useTranslation();

    return (
        <TableRow
            hover
            key={account.id}
            onClick={() => handleAccountOpen?.(String(account.id))}
            sx={{ cursor: 'pointer' }}
        >
            <TableCell >
                <Typography >
                    {account.name_enote}
                </Typography>
            </TableCell>
            <TableCell >
                <Typography >
                    {account.name}
                </Typography>
            </TableCell>
            <TableCell align="center">
                <Typography >
                    {account.account_type}
                </Typography>
            </TableCell>
            <TableCell align="center">
                <Typography >
                    {account.organization}
                </Typography>
            </TableCell>
            <TableCell align="center">
                <Typography >
                    {account.parent ? account.parent.name : '-'}
                </Typography>
            </TableCell>
            <TableCell align="center">
                <Typography >
                    {account.hidden ? t(tokens.account.hidden) : t(tokens.account.active)}
                </Typography>
            </TableCell>
        </TableRow>
    );
};
