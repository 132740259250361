export const paths = {
  index: "/",
  dashboard: {
    user: {
      list: "/user",
      create: "/user-create",
      edit: "/user-edit/:id",
      role: {
        list: "/user-role",
        create: "/user-roleCreate",
        edit: "/user-roleEdit/:roleId"
      }
    },
    payment: {
      list: "/payment-list",
      create: "/payment-create",
      edit: "/payment-edit/:id",
      form: "/payment-form/:paymentId",
      receipt: "/view-payment/:paymentId"
    },
    prepayment: {
      list: "/prepayment-list"
    },
    partner: {
      list: "/partner",
      create: "/partner-create",
      edit: "/partner-edit/:partnerId",
      contracts: "/partner-contracts/:id",
      contractCreate: "/partner-contractCreate/:partnerId",
      contractEdit: "/partner-contractEdit/:partnerId/:contractId",
    },
    account: {
      list: "/account",
      edit: "/account-edit/:id"
    },
    expenseItem: {
      list: "/expenseItem"
    },
    importPayment: {
      list: "/importPayment",
    },
    service: {
      list: "/admin-service",
      create: "/admin-service-create",
      edit: "/admin-service-edit/:id"
    },
    serviceHistory: {
      emptyState: "/service-empty-state",
      list: "/service-history",
      create: "/service-create",
      edit: "/service-edit/:id",
    },
    documentVerifiedPayments: {
      list: "/verified-payments"
    },
    nomenclature: {
      list: "/nomenclature-list"
    },
    telegram: {
      list: "/telegram"
    },
    location: {
      index: '/locations'
    },
    configuration: {
      list: "/configuration"
    },
    fixedAssets: {
      index: "/fixed-assets",
      create: "/fixed-assets/create",
      update: "/fixed-assets/:fixedAssetId/edit",
      detail: "/fixed-assets/:fixedAssetId/detail"
    }
  },
  401: "/401",
  404: "/404",
  500: "/500",
};
