
import toast from 'react-hot-toast';
import { requester } from '../../../libs/requester/requester-axios';
import { zooluxAPI } from '../../zooluxAPI';

interface IResponseUser {
    count: number;
    rows: IUser[];
}

interface IUser {
    full_name: string;
    id: number;
}

const user = zooluxAPI.injectEndpoints({
    endpoints: (builder) => ({
        getUsers: builder.query<IResponseUser, any>({
            async queryFn(_, { dispatch }) {
                try {
                    const { data, isError, message }: any = await requester.get('/zoolux-api/users');
                    if (isError) {
                        toast.error(message);
                        throw new Error(message);
                    };
                    return { data };
                } catch (e) {
                    throw e;
                }
            },
            keepUnusedDataFor: 0
        }),

    }),
    overrideExisting: false,
});

export const { useGetUsersQuery } = user;