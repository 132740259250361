import type { FC } from 'react';
import { Box, Button, Divider, Popover, Stack, Typography } from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import { getStyles } from './styles';
import { MenuItem } from './menu-item';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../locales/tokens';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../store/auth/repository/selector';

interface IProps {
  open?: boolean;
  anchorEl: null | Element;
  onClose?: () => void;
  handleLogout: () => void;
};

export const AccountPopover: FC<IProps> = ({ anchorEl, onClose, open, handleLogout, ...other }) => {
  const styles = getStyles();
  const { t } = useTranslation();
  const { user } = useSelector(selectAuth);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 200 } }}
    >
      <Box p={2} sx={{}}>
        <Typography variant="h6"> {user?.full_name || "Ім'я Прізвище"} </Typography>
        <Typography color="text.secondary" variant="body2" > {user?.phone || "+380XXXXXXXXX"} </Typography>
        <Stack width='100%'>
          {user?.roles?.map((role: string, index: number) =>
            <Typography key={index} color="text.secondary" variant="body2">{role}</Typography>
          )}
        </Stack>
      </Box>
      <Divider />
      <Box p={1}>
        <MenuItem path='/' title='Профіль' picture={<PersonOutlineIcon />} />
        <MenuItem path='/' title='Налаштування' picture={<SettingsIcon />} />
      </Box>
      <Divider />
      <Button color="inherit" onClick={handleLogout} size="small" sx={styles.logout}>
        {t(tokens.auth.logout)}
      </Button>
    </Popover>
  );
};