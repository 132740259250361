import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import { UserData } from "../userData";
import { UserLogo } from "../userLogo";
import { getStyles } from "./styles";

interface IProps {
    singleUser: any;
};

export const UserInfo: FC<IProps> = ({ singleUser }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);

    return (
        <Card elevation={6} sx={styles.card}>
            <CardContent>
                <Typography variant="h5">{t(tokens.user.userData)}</Typography>
                <Stack spacing={3} mt={2}>
                    <Stack alignItems="center" direction="row" spacing={2}><UserLogo /></Stack>
                    <UserData user={singleUser} />
                </Stack>
            </CardContent>
        </Card >
    )
};