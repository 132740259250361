import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';

interface IState {
  isLoading: boolean;
  expenditure: any[];
  expenseItemCount: number;
  error: IError;
};

const initialState: IState = {
  isLoading: false,
  expenditure: [],
  expenseItemCount: 0,
  error: null
};

const ExpenseItemSlice = createSlice({
  name: 'expenseItem',
  initialState,
  reducers: {
    setAllExpenseItems: (state, action: PayloadAction<any[] | []>) => {
      state.expenditure = action.payload;
    },
    setExpenseItemsAmount: (state, action: PayloadAction<number>) => {
      state.expenseItemCount = action.payload;
    },
    setEditExpenseItemError: (state, action: PayloadAction<IError>) => {
      state.error = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setAllExpenseItems, setExpenseItemsAmount, setEditExpenseItemError, setLoading } = ExpenseItemSlice.actions;
export const ExpenseItemReducer = ExpenseItemSlice.reducer;