import { Stack, Typography } from "@mui/material";
import { FC } from "react";

interface IProps {
    title: string;
    description: string;
};

export const EmptyList: FC<IProps> = ({ title, description }) => {

    return (
        <Stack alignItems="center" mt={10}>
            <img src="/images/account/empty_list.png" alt="empty_page" width={155} height={155} />
            <Typography variant="h6" fontWeight={600} mt={1}>
                {title}
            </Typography>
            <Typography variant="body1" width="600px" textAlign="center" mt={1}>
                {description}
            </Typography>
        </Stack>
    )
};