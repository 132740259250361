import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { setSingleUser, setUsers } from "../../repository/actions";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";

function* editUserWorker(payload?: any): SagaIterator {
    try {
        // const { data, isError, message }: IResponse<any> = yield call( requester.put, "link for editing user...", payload);
        // if (isError) {
        //     yield put(setErrorsGetEvents(message));
        // } else {
        //     yield put(setErrorsGetEvents(null));
        // }
    } catch (error) {
        console.error("editUserWorker: ", error);
    } finally {
        
    };
};

export function* editUserWatcher() {
    yield takeLatest("@saga/GET_USERS", editUserWorker);
};
