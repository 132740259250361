export const uk = {
  nav: {},
  common: {
    list: "Лист",
    create: "Створити",
    edit: "Редагувати",
    detail: "Деталі",
    send: "Відправити",
    cancel: "Скасувати",
    action: "Дії",
    delete: "Видалити",
    active: "Активні",
    archive: "Архів",
    copy: "Копіювати",
    emptyList: "Лист порожній",
    yes: "Так",
    no: "Ні"
  },
  tables: {},
  forms: {},
  auth: {
    phone: "Номер телефону",
    password: "Пароль",
    confirmPass: "Підтвердити пароль",
    enter: "Увійти",
    logout: "Вийти",
    createAndEnter: "Створити пароль",
    forgotPass: "Забули пароль?",
    checkPhone: "Перевірити номер",
    recoverPhone: "Відновити пароль",
    confirmCode: "Підтвердити код",
    resend: "Надіслати код повторно",
    smsPassword: "Пароль з sms",
    validation: {
      phoneValid: "Невірний формат номера. Зразок: +380XXXXXXXXX.",
      passValid: "На даний момент пароль - Zoolux.",
      matchPass: "Пароль не збігається. Будь ласка, перевірте ваш пароль."
    },
    messages: {
      createSafePass: "Створіть безпечний пароль",
      confirmYourPass: "Підтвердіть ваш пароль",
      enterSms: "Введіть код, який ви отримали по смс"
    }
  },
  payment: {
    calendar: "Календар",
    payments: "Платежі",
    monthView: "Місяць",
    weekView: "Тиждень",
    dayView: "День",
    confirmed: "Підтверджені",
    uploadFile: "Завантажити файл",
    listView: "Лист",
    cash: "готівка",
    file: "Файл",
    importantMessage: "ВАЖЛИВО! НЕОБХIДНО ДОДАТИ ФАЙЛ ДЛЯ ПIДТВЕРДЖЕННЯ ОПЛАТИ.",
    updateModalMessage: "Деякі дані було змінено. Ви бажаєте оновити платіж перед операцією?",
    proofFile: "Файл для підтвердження опати",
    maxSize4: "Максимальний розмір файлу становить 4 мб",
    maxSize25: "Максимальний розмір файлу становить 25 мб",
    'non-cash': "безготівковий розрахунок",
    report: "підзвіт",
    infoFileInModal: "Будь ласка, переконайтеся, що правильний файл для підтвердження оплати прикріплений!",
    getBack: "Повернутися назад",
    card: "карта банку",
    paymentForm: "Форма оплати",
    emptyPageDesc: "Для здійснення платежу оберіть один з наступних способiв оплати.",
    visaPayment: "Візувати платіж",
    urPartnerName: "Отримувач (Юридична назва)",
    unVisaPayment: "Скасувати візування",
    postpayment: "Післяплата",
    prepayment: "Передплата",
    paymentProof: "Підтвердження оплати",
    partialPayment: "Часткова оплата",
    createPayment: "Створити платіж",
    createPaymentButton: "Створити оплату",
    editPayment: "Редагувати платіж",
    paymentType: "Вид платежа",
    paymentKind: "Тип розрахунку",
    partner: "Контрагенти",
    expenseItem: "Стаття витрат",
    accountBusiness: "Рахунок контрАГЕНТА",
    account: "Рахунок Зоолюкс",
    fromAccount: "З рахунку",
    comment: "Коментарій до платежу",
    serviceComment: "Службовий коментар",
    prepaymentComment: "Коментар для предоплати",
    processPayment: "Провести оплату",
    clinic: "Клініка",
    sender: "Відправник",
    receiverAccount: "Рахунок отримувача",
    bank: "Банк",
    edrpou: "ЕДРПОУ",
    mfo: "МФО",
    paymentMethod: "Метод оплати",
    sum: "Сума",
    pdv: "ПДВ",
    files: "Файли",
    fileWasNotAdded: "Файл не був доданий до оплати.",
    paymentDate: "Дата оплати",
    preparePayment: "Сформувати платіж",
    fullName: "ПІБ отримувача",
    cardBank: "Карта банку",
    copied: "скопійовано",
    addCard: "Додати карту",
    addCardOrAccount: "Додати нову карту або рахунок",
    addCardInfo: "Якщо у списку ви не знайшли вашу картку, додайте нову.",
    add: "Додати: ",
    receiver: "Отримувач",
    initiator: "Ініціатор",
    financier: "Фінансист",
    operacionist: "Операціоніст",
    withPdv: "з ПДВ",
    withoutPdv: "без ПДВ",
    codeCheck: "Код чека",
    zooluxCard: "Карта банка Зоолюкс",
    created: "Створені",
    visaed: "Візовані",
    processed: "Проведені",
    completed: "Виконані",
    transmitted: "Переведені в платежі",
    payOnDay: "Оплатити на дату",
    placingApp: "Розміщення заявки",
    visaedPayment: "Візовано",
    paymentProcessed: "Оплату проведено",
    codeUr: "Код юр/фіз особи",
    all: "Всі",
    expectedDate: "Дата бажаної оплати заявки",
    responsible: "Відповідальний",
    getProductOnDate: "Дата отримання",
    receiveProduct: "Отримати товар",
    responsibleAndDate: "Відповідальний та дата отримання",
    productReceiverAndDate: "Отримав товар та фактична дата отримання",
    budget: "Бюджет",
    deletePayment: "Видалити платіж",
    transferToPayment: "Перевести до оплати",
    deleteRegular: "Цей платіж є регулярним. Будь ласка, вкажіть, чи бажаєте ви повністю видалити всі платежі або тільки один з них.",
    allPayments: "Всі платежі",
    thisPayment: "Цей платіж",
    regularPayment: "Регулярний платіж",
    months: "Місяць",
    weeks: "Тиждень",
    paymentsAmount: "Кількість платежів",
    period: "Період",
    status: "Статус",
    noFilters: "Фильтри не застосованi",
    deleted: "Видалені",
    parametrs: "Параметри",
    reset: "Скинути",
    filters: "Фільтри",
    isOperated: "Проведено",
    isCreated: "Створено",
    isVisaed: "Завізовано",
    paymentsSearch: "Пошук платежiв",
    prepaymentDate: "Дата очікуваного надходження",
    imported: "Імпортовані",
    hidden: "Приховані",
    operated: "Проведений",
    paymentVisaed: "Візований",
    paymentCreated: "Створений",
    desiredFinancier: "Очікуваний фінансист",
    receipt: {
      productReceive: "Товар отримав:",
      gettingProductDate: "Дата очікування отримання товару:",
      responsible: "Відповідальний за отримання товару:",
      operationDate: "Дата проведення платежу:",
      operationist: "Операціоніст:",
      visaDate: "Дата візування платежу:",
      financier: "Фінансист:",
      createDate: "Дата створення платежу:",
      initiator: "Ініціатор:",
      sum: "Сума операції:",
      files: "Файли: ",
      mfo: "МФО:",
      bank: "Банк:",
      receiverAccount: "Рахунок отримувача:",
      partner: "Контрагент:",
      fopZoolux: "ФОП Зоолюкс:",
      sender: "Відправник:",
      expenseItem: "Стаття витрат:",
      paymentType: "Тип платежу:",
      paymentKind: "Спосіб розрахунку:",
      paymentMethod: "Метод оплати:",
      paymentNum: "Платіж №",
    },
    serviceName: "Назва послуги",
    serviceCopletionDate: "Дата виконання послуги",
    notToPutInEnot: "Оприбуткувати послугу",
    processService: "Оприбуткувати послугу та провести платіж",
    uploadCheck: "Завантажити рахунок",
    uploadBill: "Завантажити платіжку",
    emptyList: "Список порожній",
    emptyListDesc: "Тут буде відображено список платежів по рахункам, але зараз він порожній. Натисніть кнопку “Створити” або змініть пошуковий запит.",
    performed: "Виконано"
  },
  paymentToolbar: {
    one: "Один",
    two: "Два",
    three: "Три",
    four: "Чотири",
    five: "П'ять",
    six: "Шість",
    seven: "Сім",
  },
  user: {
    users: "Користувачі",
    userNumber: "Номер користувача",
    shortName: "ПIБ",
    phone: "Телефон",
    position: "Посада",
    userData: "Данні користувача",
    userRole: "Роль користувача",
    roleDesc: "Роль надає можливість створювати платіжний документ, верифікувати його та проводити оплату.",
    role: "Роль",
    userStatus: " Статус користувача",
    statusDesc: "Статус включає в себе працюючих та звільнених користувачів.",
    status: "Статус",
    working: "Працює",
    fired: "Звільнений",
    changeData: "Змінити дані",
    updateUsers: "Оновити користувачів",
    createUser: "Створити користувача",
    roles: "Ролi",
    rolesList: "Список ролей користувачів",
    title: "Назва",
    permissions: "Доступи",
    createRole: "Створити роль",
    editRole: "Редагувати роль",
    messages: {
      roleCreated: "Роль створена",
      roleRemoved: "роль видалена",
      roleUpdated: "Роль оновлена",
      changedAccount: "Ви увійшли до облікового запису як "
    }
  },
  partner: {
    partner: "Контрагент",
    partners: "Контрагенти",
    createPartner: "Створити контрагента",
    editPartner: "Редагувати контрагента",
    name: "Ім'я",
    folder: "Каталог",
    shortName: "Коротке ім'я",
    edrpou: "ЄДРПОУ",
    desc: "Опис",
    descEnote: "Опис Єнота",
    specifyName: "Вкажіть ім'я для контрагента",
    specifyEdrpou: "Вкажіть ЄДРПОУ для контрагента",
    details: "Деталі",
    notShortName: "Коротке ім\'я не вказано",
    notEdrpou: "ЄДРПОУ не вказано",
    notDesc: "Опис не вказано",
    accounts: "Рахунки",
    cards: "Карти",
    updatePartner: "Оновити контрагентів",
    urName: "Юридична назва",
    status: "Статус",
    bankName: "Назва банку",
    actions: "Дії",
    fillAllFileds: "Заповніть всі необхідні поля",
    message: {
      partnerCreated: "Контрагент створений",
      partnerUpdated: "Контрагент оновлений",
    },
    errors: {
      uniqueEdrpou: "ЄДРПОУ повинен бути унiкальним."
    }
  },
  contracts: {
    contracts: "Договора",
    contractsList: "Список договорiв",
    file: "Файл",
    type: "Тип",
    createContract: "Створити договор",
    title: "Назва",
    edrpou: "ЄДРПОУ",
    comment: "Коментар",
    startContract: "Початок договору",
    endContract: "Завершення договору",
    maxSymbols: "Максимальна кількість символів",
    maxSizeFile: 'Файл занадто великий. Максимальний розмір файлу - 25 МБ.',
    fillAllNeededData: "Заповніть всі необхідні поля",
    actions: "Дії",
    editContract: "Редагувати договор",
    deleteContract: "Видалити договор",
    searchContract: "Пошук договора за коментарем",
    noData: "дані відсутні",
    listEmpty: "Список порожній",
    emptyListDesc: "Тут буде відображено список договорів, але зараз він порожній. Натисніть кнопку “Створити” або змініть пошуковий запит.",
    contract: "Договір",
    product: "Товари",
    service: "Послуги",
    other: "Інше",
    message: {
      contractRemoved: "Договор видалений",
      contractCreated: "Договор створений",
      contractUpdated: "Договор оновлено",
    }
  },
  account: {
    accounts: "Рахунки",
    zooluxAccounts: "Рахунки Zoolux",
    searchAccount: "Пошук рахунку за назвою",
    companyAccounts: "Рахунки компанії",
    editAccount: "Редагувати рахунок",
    active: "Активний",
    hidden: "Заблокований",
    title: "Назва",
    accountType: "Тип рахунку",
    mainAccount: "Головний рахунок",
    titleRequired: "Необхідно вказати назву",
    accountUpdated: "Рахунок успішно оновлено",
    updateAccounts: "Оновити рахунки",
    accountName: "Назва рахунку",
    name: "Назва",
    shortName: "Коротка назва",
    organization: "Організація",
    status: "Статус",
  },
  expenseItem: {
    expenseItem: "Стаття витрат",
    updateExpenseItem: "Оновити статтi витрат",
    active: "Активний",
    hidden: "Заблокований",
    searchExpenseItem: "Пошук статтi за назвою",
  },
  permissions: {
    getAllBudgetPayments: "Отримати_всі_бюджеті_платежі",
    getAllCashBudgetPayments: "Отримати_всі_готівкові_бюджеті_платежі",
    getAllCardBudgetPayments: "Отримати_всі_карткові_бюджеті_платежі",
    getAllNonCashBudgetPayments: "Отримати_всі_безготівкові_бюджеті_платежі",
    getAllBudgetPaymentsTypes: "Видили_всі_бюджетні_платежі",
    updateBudgetPayment: "Оновити_бюджетний_платіж",
    createClinic: "Створити_клініку",
    getAllClinics: "Отримати_всі_клініки",
    updateClinic: "Оновити_клініку",
    createCompanyAccount: "Створити_рахунок_компанії",
    getCompanyAccount: "Отримати_рахунок_компанії",
    updateCompanyAccount: "Оновити_рахунок_компанії",
    createCounterparty: "Створити_контрагента",
    getCounterparty: "Отримати_контрагента",
    updateCounterparty: "Оновити_контрагента",
    getCounterpartiesInFolder: "Отримати_контрагенів_в_папці",
    updateCounterpartiesInFolder: "Оновити_контрагентів_в_папці",
    createExpenseArticle: "Створити_статтю_витрат",
    getExpenseArticle: "Отримати_статтю_витрат",
    updateExpenseArticle: "Оновити_статтю_витрат",
    getLogging: "Отримати_логування",
    signPayment: "Підписати_платіж",
    signLocalPayment: "Підписати_локальний_платіж",
    removeSignature: "Видалити_підпис",
    removeLocalSignature: "Видалити_локальний_підпис",
    payPayment: "Сплатити_платіж",
    payLocalPayment: "Сплатити_локальний_платіж",
    payCashPayment: "Сплатити_готівковий_платіж",
    payCardPayment: "Сплатити_картковий_платіж",
    payNonCashPayment: "Сплатити_безготівковий_платіж",
    getAllPayments: "Отримати_всі_платежі",
    getAllCashPayments: "Отримати_всі_готівкові_платежи",
    getAllCardPayments: "Отримати_всі_карткові_платежи",
    getAllNonCashPayments: "Отримати_всі_безготівкові_платежи",
    removePayment: "Видалити_платіж",
    removeLocalPayment: "Видалити_локальний_платіж",
    updatePayment: "Оновити_платіж",
    convertBudgetPaymentToCalendar: "Перевести_платіж_бюджетний_у_календар",
    createPaymentType: "Створити_тип_розрахунку",
    updatePaymentType: "Оновити_тип_розрахунку",
    removePaymentType: "Видалили_тип_розрахунку",
    createPaymentMethod: "Створити_метод_оплати",
    updatePaymentMethod: "Оновити_метод_оплати",
    removePaymentMethod: "Видалити_метод_оплати",
    createPaymentKind: "Створити_вид_платежу",
    updatePaymentKind: "Оновити_вид_платежу",
    removePaymentKind: "Видалити_вид_платежу",
    changePrepaymentStatus: "Змінювати_статус_предоплати",
    getUser: "Отримати_користувача",
    getUsers: "Отримати_користувачів",
    createRole: "Створити_роль",
    getRoles: "Отримати_ролі",
    updateRole: "Оновити_роль",
    removeRole: "Видалили_роль",
    getAllPermissions: "Отримати_всі_доступи",
    addRoleAccessToUser: "Додати_доступ_до_ролі_користувача",
    createRoleForUser: "Створити_роль_для_користувача",
    revokeUserRole: "Скасувати_роль_користувача",
    grantUserAccess: "Надати_доступ_для_користувача",
    revokeUserAccess: "Скасувати_доступ_для_користувача",
    getAllCompanyAccounts: "Отримати_всі_рахунки_компаніі",
    getAllCounterparties: "Отримати_всіх_контрагентів",
    getAllExpenseArticles: "Отримати_всі_статті_витрат",
    getListDayPayment: "Отримати баланс з  Енота",
    getListPrepayment: "Отримати розрахунок предоплат",
    createImportPayment: "Створити імпорт платежів",
    getAllImportPayment: "Отримати імпорт платежів",
    getListOperacionistPayment: "Отримати платежі проведені операціоністом",
    getListFinancierPayment: "Отримати платежі візовані фінансістом",
    writeServiceReceiving: "Редагувати послугу",
    editTelegram: "Редагувати дані по телеграм боту",
    readTelegram: "Отримати дані по телеграм боту",
    readServiceReceiving: "Отримати список послуг",
    completedBudgetCalendar: "Бюджетний платіж виконано",
    confirmBudgetCalendar: 'Додати файл для підтвердження оплати',
    confirmPayment: 'Підтвердження оплати',
    createInEnote: 'Оприбуткувати послугу',
    isEditNomenclature: 'Редагувати номенклатуру',
    isReadNomenclature: 'Отримати список номенклатур',
    isReadConfigurations: 'Отримати конфігурацію',
  },
  importPayment: {
    unloadingInfo: "Інформація про вигрузку з 1С",
    importInEnot: "Імпорт в ЄНОТ",
    importInCalendar: "Імпорт в календар",
    addCsv: "Додати .csv файл",
    upload: "Завантажити",
    close: "Скасувати",
    status: "Статус",
    title: "Назва",
    paymentsCount: "Кількість платежів",
    type: "Тип",
    uploader: "Завантажив",
    uploadDate: "Дата",
    uploadInEnot: "Вивантажено в ЄНОТ",
    uploadInCalendar: "Вивантажено в календар",
    payments: "Платежi",
    importPayments: "Імпорт платежів",
    sum: "Сума",
    emptyListDesc: "Тут буде відображено список завантажень з 1С, але зараз він порожній. Оберіть одну з опцій імпорту платежів.",
    noData: "Дані відсутні",
    messages: {
      paymentsUploadEnot: "Платежі вивантажені в Єнот",
      paymentsUploadCalendar: "Платежі вивантажені в календар",
    }
  },
  paymentsList: {
    account: "Рахунок",
    partner: "Контрагент",
    sum: "Сума",
    date: "Дата",
    initiator: "Ініціатор",
    method: "Метод",
    balance: "Баланс",
    status: "Статус",
    noAccount: "Немає рахунку",
    noBalance: "Немає балансу"
  },
  prepayment: {
    prepayments: "Платежі (за передоплатою)",
    dateNotAssign: "Дата не встановлена",
    emptyListDesc: "Тут буде відображено список передплат, але зараз він порожній. Створіть передплату у розділі 'Платежі' або змініть дату для пошуку.",
  },
  service: {
    service: "Послуги",
    serviceList: "Список послуг",
    listEmpty: "Список порожній",
    emptyListDesc: "Тут буде відображено список послуг, але зараз він порожній. Натисніть кнопку “Створити” або змініть пошуковий запит.",
    title: "Назва",
    nomenclature: "Номенклатура Key",
    nomenclatureSeria: "Серія номенклатури",
    units: "Одиниці виміру Key",
    byDefault: "За замовчуванням",
    searchByTitle: "Пошук послуги",
    noData: "Дані відсутні",
    createService: "Створити послугу",
    editService: "Редагувати послугу",
    deleteService: "Видалити послугу",
    fillAllData: "Заповніть всі обов'язкові поля",
    relatedService: "Супутні витрати",
    deleteRelatedService: "Видалити витрату",
    addRelatedService: "Додати супутню витрату",
    comment: "Коментар",
    commentForService: "Коментар до послуги",
    serviceProccessed: "Послугу проведено",
    message: {
      serviceCreated: "Послугу створено",
      serviceUpdated: "Послугу оновлено",
      serviceDeleted: "Послугу видалено",
      serviceByDefaultChanged: "Послугу за замовчуванням змінено",
      capitalizeService: "Є неоприбуткована послуга. Оприбуткувати платіж із послугою ?"
    }
  },
  telegram: {
    telegramBot: "Телеграм бот",
    listEmpty: "Список порожній",
    emptyDesc: "Тут буде відображено список користувачiв телеграм бота, але зараз він порожній. Сгенеруйте секретний ключ та надайте його користувачу або змініть пошуковий запит.",
    searchUsersByNickname: "Пошук користувачем за нікнеймом",
    id: "ID",
    name: "Ім'я",
    nickNameId: "Нікнейм ID",
    nickName: "Нікнейм",
    phone: "Телефон",
    goToUser: "Перейти на сторiнку користувача",
    deleteUser: "Видалити користувача",
    noData: "Дані відсутні",
    restoreUser: "Відновити користувача",
    secretCode: "Секретний код",
    changeCode: "Змінити код",
    active: "Активні",
    deleted: "Видалені",
    codeCopied: "Код скопійовано",
    message: {
      userRemoved: "Користувач видалений",
      userRestored: "Користувача відновлено",
      codeChanged: "Код для тлеграм бота змінено",
    }
  },
  verifiedPayments: {
    verifiedPayments: "Підтвердження платежів",
    verifiedPaymentsList: "Список платежів, що потребують підтвердження",
    listEmpty: "Список порожній",
    emptyListDesc: "Тут буде відображено список платежів, що потребують підтвердження, але зараз він порожній. Платіж можна створити в розділі 'Платежі'.",
    title: "Назва",
    companyAccount: "Рахунок Зоолюкс",
    partner: "Контрагент",
    paymentMethod: "Метод оплати",
    date: "Дата оплати"
  },
  serviceHistory: {
    serviceHistory: "Історія послуг",
    createService: "Створити послугу",
    title: "Назва",
    comment: "Коментар",
    date: "Дата",
    status: "Статус послуги",
    listEmpty: "Список порожній",
    emptyListDesc: "Тут буде відображено список послуг, але зараз він порожній. Натисніть кнопку “Створити” або змініть пошуковий запит.",
    searchFor: "Пошук за",
    createdDate: "Дата створення послуги",
    partner: "Контрагент",
    sum: "Сума",
    processDate: "Дата проведення полуги",
    addPaymentToService: "Додати платіж до послуги",
    cancelPaymentService: "Скасувати платіж послуги"
  },
  nomenclature: {
    nomenclature: "Номенклатури",
    emptyList: "Список порожній",
    emptyDesc: "Тут буде відображено список номенклатур, але зараз він порожній. Номенклатури можна отримати з Єнота або змініть пошуковий запит.",
    nomenclatureList: "Список номенклатур",
    code: "Код",
    description: "Назва",
    articul: "Артикул",
    nomenclatureType: "Вид номенклатури",
    makeService: "Зробити послугою",
    nomenclatureSearch: "Пошук номенклатури",
    message: {
      nomenclatureEdited: "Номеклатура успішно змінена"
    }
  },
  location: {
    title: "Склад",
    searchInputPlaceholder: "Назва, контакти, адреса"
  },
  paymentWeek: {
    emptyList: "Список порожній",
    emptyDesc: "Тут буде відображено список платежів, але зараз він порожній. Шоб отримати платежі змініть пошуковий запит.",
    paymentDate: "Оплатити на дату",
    partner: "Контрагент",
    amount: "Сума",
    paymentMethod: "Нал/безнал/карта",
    account: "Рахунок",
    initiator: "Ініціатор",
    operationist: "Проведен",
    comment: "Коментар"
  },
  configuration: {
    configuration: "Конфігурація",
    key: "ENOTE_URL",
    meaning: "ORGANIZATION_REF_KEY"
  },
  fixedAsset: {
    title: "Основні засоби",
    searchInputPlaceholder: "Назва, серійний номер, інвентарізаційний номер",
    emptyStateMessage: "Нічого не знайдено",
    actionBtn: "Створити"
  }
};
