import { TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import { getStyles } from "./styles";

export const ServiceListHead: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);

    return (
        <TableHead >
            <TableRow sx={styles.header}>
                <TableCell align="center" width='20%'>
                    <Typography fontWeight={600}>{t(tokens.service.title)}</Typography>
                </TableCell>
                <TableCell align="center" width='30%'>
                    <Typography fontWeight={600}>{t(tokens.service.nomenclature)}</Typography>
                </TableCell>
                <TableCell align="center" width='35%'>
                    <Typography fontWeight={600}>{t(tokens.service.units)}</Typography>
                </TableCell>
                <TableCell align="center" width='5%'>
                    <Typography fontWeight={600}>{t(tokens.service.byDefault)}</Typography>
                </TableCell>
                <TableCell align="right" width='10%'>
                    <Typography fontWeight={600} pr={4}>{t(tokens.contracts.actions)}</Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    )
};