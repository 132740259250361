export const getStyles = (downSm: boolean) => {
    const styles = {
        activeBtn: {
            backgroundColor: '#36B355',
            color: '#FFFFFF',
            '&:hover': {
                backgroundColor: '#36B355'
            },
            textTransform: downSm? 'none' : 'uppercase',
        },
        nonActiveBtn: {
            backgroundColor: '#FEF49A',
            '&:hover': {
                backgroundColor: '#36B355',
                color: '#FFFFFF',
            },
            textTransform: downSm? 'none' : 'uppercase',
        },
        formButton: { 
            color: '#FFFFFF', 
            p: 0, 
            m: 0, 
            height: '55px' 
        },
        title: {
            justifyContent: downSm? 'flex-start': 'center',
            pl: downSm? 3 : 0
        },
        performButton: { 
            position: 'absolute', 
            color: '#ffffff', 
            top: '170px' 
        }
    };
    return styles;
};