import { Table, TableBody } from "@mui/material";
import { FC } from "react";
import { RoleTableItem } from "../roleTableItem";

interface IProps {
    roles: any[];
    handleDelete: (id: number) => void;
};

export const RolesTable: FC<IProps> = ({ roles, handleDelete }) => {

    return (
        <Table sx={{ mt: 2 }}>
            <TableBody>
                {roles.map(role =>
                    <RoleTableItem
                        role={role}
                        key={role.id}
                        handleDelete={handleDelete}
                    />
                )}
            </TableBody>
        </Table>
    )
};