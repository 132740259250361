import { FC } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";

interface IProps {
    status: string;
    handleChangeStatus: (event: SelectChangeEvent) => void;
};

export const StatusSelect: FC<IProps> = ({ status, handleChangeStatus }) => {
    const { t } = useTranslation();

    return (
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-status">{t(tokens.user.status)}</InputLabel>
            <Select
                labelId="demo-simple-select-status"
                id="demo-simple-select-status"
                value={status}
                label={t(tokens.user.status)}
                onChange={handleChangeStatus}
            >
                <MenuItem value='Active'>{t(tokens.user.working)}</MenuItem>
                <MenuItem value='Fired'>{t(tokens.user.fired)}</MenuItem>
            </Select>
        </FormControl>
    )
};