
export const getStyles = () => {
    const styles = {
        logoContainer: {
            justifyContent: 'center',
            alignItems: 'center',
            p: 3
        },
        navContainer: {
            flexGrow: 1,
            px: 2,
        }
    }
    return styles;
}