import type { FC } from 'react';
import { TableBody } from '@mui/material';
import { TableItem } from '../tableItem';

interface IProps {
    items: any[];
    handleExpenseItemOpen: (partnerId: string) => void;
};

export const TableExpenseItemBody: FC<IProps> = ({ items, handleExpenseItemOpen }) => {

    return (
        <TableBody>
            {items?.map((item, index) => (
                <TableItem
                    key={index}
                    item={item}
                    handleExpenseItemOpen={handleExpenseItemOpen}
                />
            )
            )}
        </TableBody>
    );
};
