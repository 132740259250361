import { Box, Button, Chip, Divider, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import { ChangeEvent, Dispatch, FC, FormEvent, SetStateAction, useCallback, useMemo } from "react";
import { MultiSelect } from "../../../../../../ui-kit/multiSelect/multiSelect";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import { getStyles } from "./styles";
import { ModeGroupButtons } from "../modeGroupButtons";
import { PaymentsSearch } from "../paymentsSearch";
import { PAYMENT_STATUS } from "../../../../../../config";

interface IProps {
    chips: {displayValue : string; field :  string; label : string; value : string}[];
    mode: string;
    handleFilterReset: () => void;
    handleChangeMode: (status: string) => void;
    setChips: Dispatch<SetStateAction<any[]>>;
    handleSearchTextChange: (event: FormEvent<HTMLFormElement>) => void;
    handleChangeSearch: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleSortChange: (event: ChangeEvent<HTMLInputElement>) => void;
    handleStatusPayments: (status: string[], method: any[], isDeleted: boolean[], imported: string, isHidden: boolean, isConfirmed: boolean, isPreferredFinancier: boolean) => void;
    handleMethodPayments: (method: any[], status: string[], isDeleted: boolean[], imported: string, isHidden: boolean, isConfirmed: boolean, isPreferredFinancier: boolean) => void;
    handleIsDeletedPayments: (isDeleted: boolean[], status: string[], method: any[], imported: string, isHidden: boolean, isConfirmed: boolean, isPreferredFinancier: boolean) => void;
    handleHiddenPayments: (status: string[], method: any[], isDeleted: boolean[], imported: string, isHidden: boolean, isConfirmed: boolean, isPreferredFinancier: boolean) => void;
    handleConfirmPayments: (status: string[], method: any[], isDeleted: boolean[], imported: string, isHidden: boolean, isConfirmed: boolean, isPreferredFinancier: boolean) => void;
    handleFinancierPayments: (status: string[], method: any[], isDeleted: boolean[], imported: string, isHidden: boolean, isConfirmed: boolean, isPreferredFinancier: boolean) => void;
    handleImportPayments: (status: string[], method: any[], isDeleted: boolean[], imported: string, isHidden: boolean, isConfirmed: boolean, isPreferredFinancier: boolean) => void;
};

export const CalendarFilters: FC<IProps> = ({ handleFilterReset, handleMethodPayments, handleStatusPayments, chips, setChips, mode, handleChangeMode, handleIsDeletedPayments,
    handleChangeSearch, handleSearchTextChange, handleSortChange, handleImportPayments, handleHiddenPayments, handleConfirmPayments, handleFinancierPayments }) => {
    const { t } = useTranslation();
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm')); 
    const styles = useMemo(() => getStyles(), []);

    const statusOptions = [
        { label: "🟦 " + t(tokens.payment.created), value: 'CREATED', id: 1 },
        { label: "🟨 " + t(tokens.payment.visaed), value: 'VISAED', id: 2 },
        { label: "🟩 " + t(tokens.payment.processed), value: 'OPERATED', id: 3 },
        { label: "🟪 " + t(tokens.payment.completed), value: 'COMPLETED', id: 4 },
        { label: "🟧 " + t(tokens.payment.transmitted), value: 'TRANSMITTED', id: 5 },
    ];

    const methodOptions = [
        { label: "🟥 " + t(tokens.payment.cash), value: 'cash', id: 1, },
        { label: "🟫 " + t(tokens.payment.card), value: 'card', id: 3, },
        { label: "🟦 " + t(tokens.payment["non-сash"]), value: 'non-cash', id: 4, },
    ];

    const deletedOptions = [
        { label: t(tokens.payment.deleted), value: 'deleted', id: 1, },
    ];

    const importOptions = [
        { label: t(tokens.payment.imported), value: 'IMPORT', id: 1 },
    ];

    const hiddenOptions = [
        { label: t(tokens.payment.hidden), value: 'HIDDEN', id: 1 },
    ];

    const confirmOptions = [
        { label: t(tokens.payment.confirmed), value: 'CONFIRMED', id: 1 },
    ];

    const financierOptions = [
        { label: "Бажаний фінансист", value: 'FINANCIER', id: 1 },
    ];

    const handleChipDelete = useCallback((deletedChip: any): void => {
        if (deletedChip) {
            setChips((prevChips) => {
                return prevChips.filter((chip) => {
                    return !(
                        deletedChip.field === chip.field && deletedChip.value === chip.value
                    );
                });
            });
        } else {
            setChips([]);
            handleFilterReset();
        }
    }, []);

    const statusValues = useMemo(() => {
        let values = chips
            .filter((chip) => chip.field === "status")
            .map((chip) => chip.value) as string[];
        if (values.length === 0) {
            // values.unshift("");
        }
        return values;
    }, [chips]);

    const importValues = useMemo(() => {
        let values = chips
            .filter((chip) => chip.field === "import")
            .map((chip) => chip.value) as string[];
        if (values.length === 0) {
            // values.unshift("");
        }
        return values;
    }, [chips]);

    const hiddenValues = useMemo(() => {
        let values = chips
            .filter((chip) => chip.field === "hidden")
            .map((chip) => chip.value) as string[];
        if (values.length === 0) {
            // values.unshift("");
        }
        return values;
    }, [chips]);

    const confirmValues = useMemo(() => {
        let values = chips
            .filter((chip) => chip.field === "confirmed")
            .map((chip) => chip.value) as string[];
        if (values.length === 0) {
            // values.unshift("");
        }
        return values;
    }, [chips]);

    const financierValues = useMemo(() => {
        let values = chips
            .filter((chip) => chip.field === "financier")
            .map((chip) => chip.value) as string[];
        if (values.length === 0) {
            // values.unshift("");
        }
        return values;
    }, [chips]);

    const methodValues = useMemo(() => {
        const values = chips
            .filter((chip) => chip.field === "method")
            .map((chip) => chip.value) as string[];
        if (values.length === 0) {
            // values.unshift("");
        }
        return values;
    }, [chips]);
    
    const deletedValues = useMemo(() => {
        const values = chips
            .filter((chip) => chip.field === "isDelete")
            .map((chip) => chip.value) as string[];
        if (values.length === 0) {
            // values.unshift("");
        }
        return values;
    }, [chips]);

    const changeDeleted = (values: string[]): boolean[] => {
        return values.map((value) => {
            switch (value) {
                case 'deleted': return true;
                default: return false
            }
        });
    };

    const changeHidden = (values: string[]): boolean[] => {
        return values.map((value) => {
            switch (value) {
                case 'HIDDEN': return true;
                default: return false
            }
        });
    };

    const changeConfirm = (values: string[]): boolean[] => {
        return values.map((value) => {
            switch (value) {
                case 'CONFIRMED': return true;
                default: return false
            }
        });
    };
    
    const changeFinancier = (values: string[]): boolean[] => {
        return values.map((value) => {
            switch (value) {
                case 'FINANCIER': return true;
                default: return false
            }
        });
    };

    const handleConfirmChange = useCallback((values: string[]): void => {
        setChips((prevChips) => {
            const filteredChips = prevChips.filter((chip) => chip.field !== "confirmed");
            const newChips = values.map((value) => {
                switch (value) {
                    case "CONFIRMED":
                        return {
                            label: t(tokens.payment.parametrs),
                            field: "confirmed",
                            value: "CONFIRMED",
                            displayValue: t(tokens.payment.confirmed),
                        };
                    default:
                        return null;
                };
            }).filter(Boolean);
            handleConfirmPayments(statusValues, methodValues, changeDeleted(deletedValues), importValues[0], changeHidden(hiddenValues)[0], changeConfirm(values)[0], changeFinancier(financierValues)[0]);
            localStorage.setItem('chips', JSON.stringify([...filteredChips, ...newChips]));
            return [...filteredChips, ...newChips];
        });
    }, [statusValues, methodValues, changeDeleted(deletedValues), importValues, changeHidden(hiddenValues), changeFinancier(financierValues), changeConfirm(confirmValues)]);

    const handleFinancierChange = useCallback((values: string[]): void => {
        setChips((prevChips) => {
            const filteredChips = prevChips.filter((chip) => chip.field !== "financier");
            const newChips = values.map((value) => {
                switch (value) {
                    case "FINANCIER":
                        return {
                            label: t(tokens.payment.parametrs),
                            field: "financier",
                            value: "FINANCIER",
                            displayValue: "Бажаний фінансист",
                        };
                    default:
                        return null;
                };
            }).filter(Boolean);
            handleFinancierPayments(statusValues, methodValues, changeDeleted(deletedValues), importValues[0], changeHidden(hiddenValues)[0], changeConfirm(confirmValues)[0], changeFinancier(values)[0]);
            localStorage.setItem('chips', JSON.stringify([...filteredChips, ...newChips]));
            return [...filteredChips, ...newChips];
        });
    }, [statusValues, methodValues, changeDeleted(deletedValues), importValues, changeHidden(hiddenValues), changeFinancier(financierValues), changeConfirm(confirmValues)]);

    const handleStatusChange = useCallback((values: string[]): void => {
        setChips((prevChips) => {
            const filteredChips = prevChips.filter((chip) => chip.field !== "status");
            const newChips = values.map((value) => {
                switch (value) {
                    case PAYMENT_STATUS.CREATED:
                        return {
                            label: t(tokens.payment.status),
                            field: "status",
                            value: PAYMENT_STATUS.CREATED,
                            displayValue: "🟦 " + t(tokens.payment.created),
                        };
                    case PAYMENT_STATUS.VISAED:
                        return {
                            label: t(tokens.payment.status),
                            field: "status",
                            value: PAYMENT_STATUS.VISAED,
                            displayValue: "🟨 " + t(tokens.payment.visaed),
                        };
                    case PAYMENT_STATUS.OPERATED:
                        return {
                            label: t(tokens.payment.status),
                            field: "status",
                            value: PAYMENT_STATUS.OPERATED,
                            displayValue: "🟩 " + t(tokens.payment.processed),
                        };
                    case PAYMENT_STATUS.COMPLETED:
                        return {
                            label: t(tokens.payment.status),
                            field: "status",
                            value: PAYMENT_STATUS.COMPLETED,
                            displayValue: "🟪 " + t(tokens.payment.completed),
                        };
                    case PAYMENT_STATUS.TRANSMITTED:
                        return {
                            label: t(tokens.payment.status),
                            field: "status",
                            value: PAYMENT_STATUS.TRANSMITTED,
                            displayValue: "🟧 " + t(tokens.payment.transmitted),
                        };
                    default:
                        return null;
                }
            }).filter(Boolean);
            handleStatusPayments(values, methodValues, changeDeleted(deletedValues), importValues[0], changeHidden(hiddenValues)[0], changeConfirm(confirmValues)[0], changeFinancier(financierValues)[0]);
            localStorage.setItem('chips', JSON.stringify([...filteredChips, ...newChips]));
            return [...filteredChips, ...newChips];
        });
    }, [methodValues, changeDeleted(deletedValues), changeHidden(hiddenValues), importValues, changeConfirm(confirmValues), changeFinancier(financierValues)]);

    const handleMethodChange = useCallback((values: string[]): void => {
        setChips((prevChips) => {
            const filteredChips = prevChips.filter((chip) => chip.field !== "method");
            const newChips = values.map((value) => {
                switch (value) {
                    case "cash":
                        return {
                            label: t(tokens.payment.paymentMethod),
                            field: "method",
                            value: "cash",
                            displayValue: "🟥 " + t(tokens.payment.cash),
                        };
                    case "non-cash":
                        return {
                            label: t(tokens.payment.paymentMethod),
                            field: "method",
                            value: "non-cash",
                            displayValue: "🟦 " + t(tokens.payment["non-сash"]),
                        };
                    case "card":
                        return {
                            label: t(tokens.payment.paymentMethod),
                            field: "method",
                            value: "card",
                            displayValue: "🟫 " + t(tokens.payment.card),
                        };
                    default:
                        return null;
                };
            }).filter(Boolean);
            handleMethodPayments(values, statusValues, changeDeleted(deletedValues), importValues[0], changeHidden(hiddenValues)[0], changeConfirm(confirmValues)[0], changeFinancier(financierValues)[0]);
            localStorage.setItem('chips', JSON.stringify([...filteredChips, ...newChips]));
            return [...filteredChips, ...newChips];
        });
    }, [statusValues, changeDeleted(deletedValues), changeHidden(hiddenValues), importValues, changeConfirm(confirmValues), changeFinancier(financierValues)]);

    const handleDeleteChange = useCallback((values: string[]): void => {
        setChips((prevChips) => {
            const filteredChips = prevChips.filter((chip) => chip.field !== "isDelete");
            const newChips = values.map((value) => {
                switch (value) {
                    case "deleted":
                        return {
                            label: t(tokens.payment.parametrs),
                            field: "isDelete",
                            value: "deleted",
                            displayValue: t(tokens.payment.deleted),
                        };
                    default:
                        return null;
                };
            }).filter(Boolean);
            handleIsDeletedPayments(changeDeleted(values), statusValues, methodValues, importValues[0], changeHidden(hiddenValues)[0], changeConfirm(confirmValues)[0], changeFinancier(financierValues)[0]);
            localStorage.setItem('chips', JSON.stringify([...filteredChips, ...newChips]));
            return [...filteredChips, ...newChips];
        });
    }, [statusValues, methodValues, changeHidden(hiddenValues), importValues, changeConfirm(confirmValues), changeFinancier(financierValues)]);

    const handleImportChange = useCallback((values: string[]): void => {
        setChips((prevChips) => {
            const filteredChips = prevChips.filter((chip) => chip.field !== "import");
            const newChips = values.map((value) => {
                switch (value) {
                    case "IMPORT":
                        return {
                            label: t(tokens.payment.parametrs),
                            field: "import",
                            value: "IMPORT",
                            displayValue: t(tokens.payment.imported),
                        };
                    default:
                        return null;
                }
            }).filter(Boolean);
            handleImportPayments(statusValues, methodValues, changeDeleted(deletedValues), values[0], changeHidden(hiddenValues)[0], changeConfirm(confirmValues)[0], changeFinancier(financierValues)[0]);
            localStorage.setItem('chips', JSON.stringify([...filteredChips, ...newChips]));
            return [...filteredChips, ...newChips];
        });
    }, [statusValues, methodValues, changeDeleted(deletedValues), changeHidden(hiddenValues), changeConfirm(confirmValues), changeFinancier(financierValues)]);

    const handleHiddenChange = useCallback((values: string[]): void => {
        setChips((prevChips) => {
            const filteredChips = prevChips.filter((chip) => chip.field !== "hidden");
            const newChips = values.map((value) => {
                switch (value) {
                    case "HIDDEN":
                        return {
                            label: t(tokens.payment.parametrs),
                            field: "hidden",
                            value: "HIDDEN",
                            displayValue: t(tokens.payment.hidden),
                        };
                    default:
                        return null;
                };
            }).filter(Boolean);
            handleHiddenPayments(statusValues, methodValues, changeDeleted(deletedValues), importValues[0], changeHidden(values)[0], changeConfirm(confirmValues)[0], changeFinancier(financierValues)[0]);
            localStorage.setItem('chips', JSON.stringify([...filteredChips, ...newChips]));
            return [...filteredChips, ...newChips];
        });
    }, [statusValues, methodValues, changeDeleted(deletedValues), importValues, changeConfirm(confirmValues), changeFinancier(financierValues)]);

    const showChips = chips.length > 0;

    return (
        <Stack mt={2}>
            <Stack direction='row' spacing={1}>
                <Stack pl={1}>
                    <Button sx={styles.reset} variant="contained" size="small" onClick={() => handleChipDelete(null)}>{t(tokens.payment.reset)}</Button>
                </Stack>
                {showChips ? (
                    <>
                        <Stack alignItems="center" direction="row" flexWrap="wrap" gap={1}>
                            {chips.map((chip, index) => (
                                <Chip
                                    key={index}
                                    label={
                                        <Box sx={styles.chipLabel}>
                                            <span>{chip.label}</span>: {chip.displayValue || chip.value}
                                        </Box>
                                    }
                                    sx={styles.chip}
                                    size={downSm ? 'small' : 'medium'}
                                    // onDelete={(): void => handleChipDelete(chip)}
                                    variant="outlined"
                                    onDelete={undefined}
                                />
                            ))}
                        </Stack>
                    </>
                ) : (
                    <>
                        <Typography color="text.secondary" variant="subtitle2" sx={styles.noFilters}>
                            {t(tokens.payment.noFilters)}
                        </Typography>
                    </>
                )}
            </Stack>
            <Divider sx={{ mt: 1 }} />
            <Stack direction='row' spacing={3} mt={1} alignItems="center">
                <Stack alignItems="center" direction="row" flexWrap="wrap" spacing={1} sx={{ p: 1 }}>
                    <MultiSelect
                        label={t(tokens.payment.filters)}
                        onChangeStatus={handleStatusChange}
                        statusOptions={statusOptions}
                        statusValues={statusValues}
                        onChangeMethod={handleMethodChange}
                        methodOptions={methodOptions}
                        methodValues={methodValues}
                        deletedOptions={deletedOptions}
                        deletedValues={deletedValues}
                        onChangeDelete={handleDeleteChange}
                        importValues={importValues}
                        importOptions={importOptions}
                        onChangeImport={handleImportChange}
                        hiddenOptions={hiddenOptions}
                        hiddenValues={hiddenValues}
                        onChangeHidden={handleHiddenChange}
                        confirmOptions={confirmOptions}
                        confirmValues={confirmValues}
                        onChangeConfirm={handleConfirmChange}  
                        financierOptions={financierOptions}
                        financierValues={financierValues}
                        onChangeFinancier={handleFinancierChange}                      
                    />
                </Stack>
                <PaymentsSearch
                    handleSearchTextChange={handleSearchTextChange}
                    handleChangeSearch={handleChangeSearch}
                    handleSortChange={handleSortChange}
                />
                <ModeGroupButtons
                    status={mode}
                    handleStatusPayments={handleChangeMode}
                />
            </Stack>
        </Stack>
    )
};
