import { useAuth } from '../hooks/useAuth';
import { Loader } from '../ui-kit/loader/loader';
import { useGetAccess } from '../hooks/useGetAccess';
import { PrivateRoutes } from './privateRoutes';
import { PublicRoutes } from './publicRoutes';

function Router() {
    const { isLoggedIn } = useAuth();
    const { user, isLoading } = useGetAccess();

    if (isLoading) {
        return <Loader />
    };

    return (
        <>
            {user ? <PrivateRoutes /> : !isLoggedIn ? <PublicRoutes /> : null}
        </>
    )
};

export default Router;

