import { SagaIterator } from "redux-saga";
import { put, call, takeEvery } from "redux-saga/effects";
import { requester } from "../../../../libs/requester/requester-axios";
import { IResponse } from "../../../../libs/requester/IResponse";
import { setLoading, setUpdateServiceError } from "../../repository/slice";

function* editServiceWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, isError, message }: IResponse<any> = yield call(requester.put, "/zoolux-api/admin/service-receiving", payload);
        if (isError) {
            yield put(setUpdateServiceError(message));
        } else {
            yield put(setUpdateServiceError(null));
        }
    } catch (error) {
        console.error("editServiceWorker: ", error);
    } finally {
        yield put(setLoading(false));
    }
};

export function* editServiceWatcher() {
    yield takeEvery("@saga/EDIT_SERVICE", editServiceWorker);
};