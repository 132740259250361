import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import { Button, Stack } from "@mui/material";
import { getStyles } from "./styles";

interface IProps {
    status: string;
    handleStatusPayments: (status: string) => void;
};

export const ModeGroupButtons: FC<IProps> = ({ status, handleStatusPayments }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);

    const modeOptions = [
        { label: t(tokens.payment.payments), value: 'Payment' },
        { label: t(tokens.payment.budget), value: 'Budget' }
    ];

    return (
        <Stack alignItems="center" direction="row" spacing={1} >
            {modeOptions.map(option =>
                <Button
                    variant='contained'
                    key={option.value}
                    size="small"
                    sx={status === option.value ?
                        styles.viewActive
                        :
                        styles.viewNoActive}
                    onClick={() => handleStatusPayments(option.value)}
                >
                    {option.label}
                </Button>
            )}
        </Stack>
    )
};