import { Box, Button, Card, Stack, SvgIcon, Theme, Typography, useMediaQuery } from "@mui/material";
import { FC } from "react";
import { usePartnerList } from "../presenters/usePartnerList";
import { getStyles } from "./styles";
import { PartnerListSearch } from "./components/partnerListSearch";
import { PartnerListTable } from "./components/partnerListTable";
import { SideDrawer } from "../../../../ui-kit/sideDrawer";
import { ListContainer } from "./components/listContainer";
import AddIcon from '@mui/icons-material/Add';
import { Link } from "react-router-dom";
import { tokens } from "../../../../locales/tokens";
import { useTranslation } from "react-i18next";
import { PartnerDetails } from "./components/partnerDetails";

export const PartnerList: FC = () => {
    const { t } = useTranslation();
    const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const styles = getStyles();
    const { state, currentPartner, handlePartnerClose, handleOrderOpen, handlePageChange, handleQueryChange, updatePartners, currentPage,
        handleSortChange, rootRef, search, handleStatusChange, drawer, pageAmount, searchText, handleSearchPartnerText } = usePartnerList();

    return (
        <>
            <Box component="main" ref={rootRef} sx={styles.main} >
                <Box ref={rootRef} sx={styles.container}>
                    <ListContainer open={drawer.isOpen}>
                        <Stack direction={downMd ? 'column' : 'row'} sx={styles.header}>
                            <Typography variant="h4" textAlign={downMd ? 'center' : 'start'}>
                                {t(tokens.partner.partners)}
                            </Typography>
                            <Stack
                                direction='row'
                                spacing={1}
                                justifyContent={downMd ? 'center' : 'start'}
                                mt={downMd ? 2 : 0}
                            >
                                <Button
                                    variant="contained"
                                    sx={styles.createBtn}
                                    onClick={updatePartners}
                                >
                                    {t(tokens.partner.updatePartner)}
                                </Button>
                                <Button
                                    startIcon={(<SvgIcon><AddIcon /></SvgIcon>)}
                                    variant="contained"
                                    sx={styles.createBtn}
                                    component={Link}
                                    to={'/partner-create'}
                                >
                                    {t(tokens.partner.createPartner)}
                                </Button>
                            </Stack>
                        </Stack>
                        <PartnerListSearch
                            sortDir={search.sortDir}
                            searchText={searchText}
                            handleSearchPartnerText={handleSearchPartnerText}
                            handleStatusChange={handleStatusChange}
                            handleQueryChange={handleQueryChange}
                            handleSortChange={handleSortChange}
                        />
                        {state.partnersList.length ?
                            <Card elevation={10} sx={styles.card}>
                                <PartnerListTable
                                    partners={state.partnersList}
                                    pageAmount={pageAmount}
                                    currentPage={currentPage}
                                    handleOrderOpen={handleOrderOpen}
                                    handlePageChange={handlePageChange}
                                />
                            </Card>
                            : null}
                    </ListContainer>
                    <SideDrawer
                        open={drawer.isOpen}
                        container={rootRef.current}
                        value={currentPartner}
                        onClose={handlePartnerClose}
                        drawerContent={
                            <PartnerDetails
                                onApprove={handlePartnerClose}
                                onReject={handlePartnerClose}
                                partner={currentPartner}
                            />
                        }
                    />
                </Box>
            </Box>
        </>
    )
};