export const getStyles = (downSm: boolean) => {
    const styles = {
        calendarBtn: {
            width: downSm? '40px' : '60px',
            height: '35px',
            borderRadius: '10px',
            backgroundColor: '#36B355',
            color: '#FFFFFF',
            '&:hover': {
                backgroundColor: '#36B355',
                boxShadow: '1px 1px 3px gray'
            }
        },
        datePicker: {
            position: 'absolute',
            zIndex: 300,
            top: '45px',
        },
    };
    return styles;
};