import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import { Grid, SelectChangeEvent, Theme, Typography, useMediaQuery } from "@mui/material";
import { RoleSelect } from "../roleSelect";

interface IProps {
    role: string[];
    roles: any[];
    handleChangeRole: (event: SelectChangeEvent<string[]>, child: ReactNode) => void;
};

export const RoleTab: FC<IProps> = ({ role, roles, handleChangeRole }) => {
    const { t } = useTranslation();
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    return (
        <Grid container>
            <Grid item sm={6}>
                <Typography variant="subtitle1">{t(tokens.user.userRole)}</Typography>
                <Typography color="text.secondary" variant="body2">{t(tokens.user.roleDesc)}</Typography>
            </Grid>
            <Grid item sm={6} mt={downSm ? 2 : 0}> 
                <RoleSelect
                    role={role}
                    roles={roles}
                    handleChangeRole={handleChangeRole}
                />
            </Grid>
        </Grid>
    )
};