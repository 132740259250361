import Calendar from '@fullcalendar/react';
import dayjs from "dayjs";
import { DateSelectArg, EventClickArg, EventDropArg } from "@fullcalendar/core";
import { Theme, useMediaQuery } from "@mui/material";
import { ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { CalendarView, DialogState } from "../../../../types/payment-calendar";
import { EventResizeDoneArg } from "@fullcalendar/interaction";
import { useDispatch, useSelector } from "react-redux";
import { updatePayment } from "../../../../store/paymentCalendar/useCases/updatePayment/action";
import { resetFields, setTime } from "../../../../store/paymentCreation/repository/slice";
import { usePayments } from "./usePayments";
import { setSinglePayment } from "../../../../store/paymentCalendar/repository/actions";
import { getPayments } from "../../../../store/paymentCalendar/useCases/getPayments/action";
import { getPaymentsList } from "../../../../store/paymentCalendar/useCases/getPaymentsList/action";
import { selectPayments } from "../../../../store/paymentCalendar/repository/selector";
import { useDebounce } from "../../../../hooks/useDebounce";
import { useNavigate } from "react-router-dom";
import { PAYMENT_STATUS } from "../../../../config";
import { getPaymentsWeek } from "../../../../store/paymentCalendar/useCases/getPaymentsWeek/action";
import { IWeekFilters } from "./types";

export const useCalendar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [date, setDate] = useState<Date>(new Date());
    const storedMode = JSON.parse(localStorage.getItem("mode") || 'false');
    const [mode, setMode] = useState(storedMode || "Payment");
    const storedChips = JSON.parse(localStorage.getItem('chips') || '[]');
    const [chips, setChips] = useState<{displayValue : string; field :  string; label : string; value : string}[]>(storedChips);
    const storedStatus = JSON.parse(localStorage.getItem('status-filter') || '[]');
    const [status, setStatus] = useState<string[]>(storedStatus);
    const storedMethod = JSON.parse(localStorage.getItem('method-filter') || '[]');
    const [method, setMethod] = useState<number[]>(storedMethod);
    const storedisDeleted = JSON.parse(localStorage.getItem('isDeleted-filter') || 'false');
    const [isDeleted, setDeleted] = useState<boolean>(storedisDeleted);
    const storedImport = JSON.parse(localStorage.getItem('import-filter') || '[]');
    const [imported, setImported] = useState<string>(storedImport);
    const storedHidden = JSON.parse(localStorage.getItem('hidden-filter') || 'false');
    const [isHidden, setHidden] = useState<boolean>(storedHidden);
    const storedConfirm = JSON.parse(localStorage.getItem("confirm-filter") || 'false');
    const [isConfirm, setConfirm] = useState(storedConfirm);
    const storedPreferredFinancier = JSON.parse(localStorage.getItem("preferred-financier") || 'false');
    const [isPreferredFinancier, setPreferredFinancier] = useState(storedPreferredFinancier);
    const [period, setPeriod] = useState<any>({ startTime: null, endTime: null });
    const [dialog, setDialog] = useState<DialogState>({ isOpen: false, data: undefined });
    const [search, setSearch] = useState('');
    const [searchText, setSearchText] = useState('');
    const [sortBy, setSortBy] = useState('COUNTERPARTY');
    const { paymentsList, handleDatesRender } = usePayments(setPeriod, status, method, mode, isDeleted, searchText, sortBy, isConfirm, isPreferredFinancier);
    const calendarRef = useRef<Calendar | null>(null);
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const [view, setView] = useState<CalendarView>('dayGridMonth');
    const { paymentsDayList, paymentsDayListCount, paymentsWeekList, paymentsWeekListCount } = useSelector(selectPayments);
    const [pageAmount, setPageAmount] = useState(0);
    const [dayPagination, setDayPagination] = useState({ page: 1, perPage: 10 });
    const [currentDay, setCurrentDay] = useState(1);
    const [weekFilters, setWeekFilters] = useState<IWeekFilters>({ offset: 0, limit: 30, order: 'asc', orderBy: 'PAYMENT_DATE' });

    useEffect(() => {
        handleScreenResize();
    }, [mdUp]); 

    useEffect(() => {
        if (view === 'listDay') {
            dispatch(getPaymentsList({ 
                page: dayPagination.page, 
                perPage: dayPagination.perPage, 
                startTime: period.startTime, 
                endTime: period.endTime 
            }));
        } else if(view === 'listWeek') {
            dispatch(getPaymentsWeek({ 
                offset: weekFilters.offset, 
                limit: weekFilters.limit, 
                startTime: period.startTime,
                endTime: period.endTime, 
                sortedDirection: weekFilters.order === 'asc'? 'ASC' : 'DESC', 
                sortedBy: weekFilters.orderBy,
                isDeleted: false
            }));
        }
    }, [view, period, dayPagination, weekFilters]);

    useEffect(() => {
        if (paymentsDayListCount) {
            setPageAmount(Math.ceil(paymentsDayListCount / dayPagination.perPage));
        }
    }, [paymentsDayListCount]);

    useEffect(() => {
        dispatch(setSinglePayment(null));
        dispatch(resetFields({ paymentMethod: null, time: null }));
    }, []);

    const handleScreenResize = useCallback((): void => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            const newView = mdUp ? 'dayGridMonth' : 'listWeek';
            calendarApi.changeView(newView);
            setView(newView);
        }
    }, [calendarRef, mdUp]);

    const handleViewChange = useCallback((view: CalendarView): void => {
        if (view === 'dayGridMonth') {
            const calendarEl = calendarRef.current;
            if (calendarEl) {
                const calendarApi = calendarEl.getApi();
                calendarApi.changeView(view);
                setView(view);
            };
        } else if(view === 'listDay'){
            handleListDate(1);
        } else{
            handlePaymentsWeek();
        }
        setView(view);
    }, []);

    const handleStatusPayments = useCallback((status: string[], method: any[], isDeleted: boolean[], imported: string, isHidden: boolean, isConfirmed: boolean, isPreferredFinancier: boolean) => {
        const body = combineRequestBody(status, method, isDeleted, period, imported, isHidden, isConfirmed, isPreferredFinancier);
        dispatch(getPayments({ body, mode }));
        setStatus(status);
        localStorage.setItem('status-filter', JSON.stringify(status));
    }, [mode, status, method, isDeleted, period, imported, isHidden, searchText, isConfirm, isPreferredFinancier]);

    const handleImportPayments = useCallback((status: string[], method: any[], isDeleted: boolean[], imported: string, isHidden: boolean, isConfirmed: boolean, isPreferredFinancier: boolean) => {
        const body = combineRequestBody(status, method, isDeleted, period, imported, isHidden, isConfirmed, isPreferredFinancier);
        dispatch(getPayments({ body, mode }));
        setImported(imported || '');
        localStorage.setItem('import-filter', JSON.stringify(imported || ''));
    }, [mode, status, method, isDeleted, period, imported, isHidden, searchText, isConfirm, isPreferredFinancier]);

    const handleHiddenPayments = useCallback((status: string[], method: any[], isDeleted: boolean[], imported: string, isHidden: boolean, isConfirmed: boolean, isPreferredFinancier: boolean) => {
        const body = combineRequestBody(status, method, isDeleted, period, imported, isHidden, isConfirmed, isPreferredFinancier);
        dispatch(getPayments({ body, mode }));
        setHidden(isHidden || false);
        localStorage.setItem('hidden-filter', JSON.stringify(isHidden || false));
    }, [mode, status, method, isDeleted, period, imported, isHidden, searchText, isConfirm, isPreferredFinancier]);

    const handleConfirmPayments = useCallback((status: string[], method: any[], isDeleted: boolean[], imported: string, isHidden: boolean, isConfirmed: boolean, isPreferredFinancier: boolean) => {
        const body = combineRequestBody(status, method, isDeleted, period, imported, isHidden, isConfirmed, isPreferredFinancier);
        dispatch(getPayments({ body, mode }));
        setConfirm(isConfirmed || false);
        localStorage.setItem('confirm-filter', JSON.stringify(isConfirmed || false));
    }, [mode, status, method, isDeleted, period, imported, isHidden, searchText, isConfirm, isPreferredFinancier]);

    const handleFinancierPayments = useCallback((status: string[], method: any[], isDeleted: boolean[], imported: string, isHidden: boolean, isConfirmed: boolean, isPreferredFinancier: boolean) => {
        const body = combineRequestBody(status, method, isDeleted, period, imported, isHidden, isConfirmed, isPreferredFinancier);
        dispatch(getPayments({ body, mode }));
        setPreferredFinancier(isPreferredFinancier || false);
        localStorage.setItem('preferred-financier', JSON.stringify(isPreferredFinancier || false));
    }, [mode, status, method, isDeleted, period, imported, isHidden, searchText, isConfirm, isPreferredFinancier]);

    const handleMethodPayments = useCallback((method: any[], status: string[], isDeleted: boolean[], imported: string, isHidden: boolean, isConfirmed: boolean, isPreferredFinancier: boolean) => {
        const body = combineRequestBody(status, method, isDeleted, period, imported, isHidden, isConfirmed, isPreferredFinancier);
        dispatch(getPayments({ body, mode }));
        setMethod(method);
        localStorage.setItem('method-filter', JSON.stringify(method));
    }, [mode, status, method, isDeleted, period, imported, isHidden, searchText, isConfirm, isPreferredFinancier]);

    const handleIsDeletedPayments = useCallback((isDeleted: boolean[], status: string[], method: any[], imported: string, isHidden: boolean, isConfirmed: boolean, isPreferredFinancier: boolean) => {
        const body = combineRequestBody(status, method, isDeleted, period, imported, isHidden, isConfirmed, isPreferredFinancier);
        dispatch(getPayments({ body, mode }));
        setDeleted(isDeleted[0] || false);
        localStorage.setItem('isDeleted-filter', JSON.stringify(isDeleted[0] || false));
    }, [mode, status, method, isDeleted, period, imported, isHidden, searchText, isConfirm, isPreferredFinancier]);

    const combineRequestBody = (status: string[], method: any[], isDeleted: boolean[], period: any, imported: string, isHidden: boolean, isConfirmed: boolean, isPreferredFinancier: boolean) => {
        const body: any = { offset: 0, limit: 1000, startTime: period.startTime, endTime: period.endTime, search: searchText };
        if (method.length) body.payment_method_keys = method;
        if (status.length) body.status = status;
        if (searchText.length) body.search_name = sortBy;
        if (imported === "IMPORT") body.source = imported;
        if (isHidden) body.isArchive = isHidden;
        if (isConfirmed) body.isConfirmed = isConfirm;
        if (isPreferredFinancier) body.is_preferred_financier = isPreferredFinancier;
        body.isDeleted = isDeleted.length ? true : false;
        return body;
    };

    const handleChangeMode = useCallback((mode: string) => {
        const body: any = { offset: 0, limit: 1000, startTime: period.startTime, endTime: period.endTime, isDeleted, search: searchText, isConfirm };
        if (method.length) body.payment_method_id = method;
        if (status.length) body.status = status;
        if (searchText.length) body.search_name = sortBy;
        if (isConfirm) body.isConfirmed = isConfirm;
        if (isPreferredFinancier) body.is_preferred_financier = isPreferredFinancier;
        dispatch(getPayments({ body, mode }));
        setMode(mode);
        localStorage.setItem('mode', JSON.stringify(mode));
    }, [mode, status, method, isDeleted, period]);

    const handleFilterReset = useCallback(() => {
        setStatus([]);
        setMethod([]);
        setDeleted(false);
        dispatch(getPayments({ body: { offset: 0, limit: 1000, startTime: period.startTime, endTime: period.endTime, isDeleted: false }, mode }));
        localStorage.removeItem('status-filter');
        localStorage.removeItem('method-filter');
        localStorage.removeItem('isDeleted-filter');
        localStorage.removeItem('chips');
        localStorage.removeItem('import-filter');
        localStorage.removeItem('hidden-filter');
        localStorage.removeItem('confirm-filter');
        localStorage.removeItem('preferred-financier');
    }, [mode, period, isConfirm, isDeleted, isPreferredFinancier]);

    const handlePeriodChoose = (startDate: Date, endDate: Date): void => {
        const calendarEl = calendarRef.current;
        if (calendarEl && view !== 'dayGridMonth') {
            const calendarApi = calendarEl.getApi();
            const newDate = startDate.toISOString();
            calendarApi.gotoDate(newDate);
            setDate(calendarApi.getDate());
        }
        const startOfPeriod = startDate.getTime();
        const endOfPeriod = endDate ? endDate.getTime() : startOfPeriod;
        const body: any = { offset: 0, limit: 1000, startTime: startOfPeriod, endTime: endOfPeriod };
        if (method.length) body.payment_method_id = method;
        if (status.length) body.status = status;
        dispatch(getPayments({ body, mode }));
    };

    const handleDatePrev = useCallback((): void => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.prev();
            setDate(calendarApi.getDate());
        }
    }, []);

    const handleDateNext = useCallback((): void => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.next();
            setDate(calendarApi.getDate());
        }
    }, []);

    const handleAddClick = useCallback((): void => {
        navigate(`/payment-create`);
    }, []);

    const handleRangeSelect = useCallback((arg: DateSelectArg): void => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.unselect();
        };
        const startTime = arg.start.getTime();
        const today = Date.now();
        const start = startTime < today ? today : startTime;
        dispatch(setTime(start));
        navigate(`/payment-create`);
    }, [dialog]);

    const handleEventSelect = useCallback((arg: EventClickArg): void => {
        navigate(`/payment-edit/${arg.event.id}`);
    }, []);

    const handleEventResize = useCallback(async (arg: EventResizeDoneArg): Promise<void> => {
        const { event } = arg;
        dispatch(updatePayment({
            eventId: event.id,
            update: {
                allDay: event.allDay,
                start: event.start?.getTime(),
                end: event.end?.getTime()
            }
        }));
    }, [dispatch]);

    const handleEventDrop = useCallback(async (arg: EventDropArg): Promise<void> => {
        const { event } = arg;
        let body: any = {};
        mode === "Payment" ?
            body = { payment_id: Number(event.id), payment: { payment_date: event.start?.getTime() } }
            : body = { budget_payment_id: Number(event.id), budget_payment: { payment_date: event.start?.getTime() } }
        dispatch(updatePayment({
            body,
            period,
            isShowMessage: true,
            statusFilter: status,
            methodFilter: method,
            modeFilter: mode,
            deleteFilter: isDeleted
        }));
    }, [dispatch, mode]);

    const handleEventAllow = (dropInfo: any, draggedEvent: any) => {
        const paymentStatus = draggedEvent.extendedProps.status;
        if (paymentStatus !== PAYMENT_STATUS.CREATED && paymentStatus !== PAYMENT_STATUS.VISAED) {
            return false;
        }
        return true;
    };

    const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearch(e.target.value);
        debouncedHandlePaymentSearch(e.target.value);
    }, []);

    const handleSearchTextChange = useCallback((event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
    }, []);

    const handleSortChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const body: any = { offset: 0, limit: 1000, startTime: period.startTime, endTime: period.endTime, isDeleted, search: searchText };
        if (method.length) body.payment_method_id = method;
        if (status.length) body.status = status;
        if (searchText.length) body.search_name = event.target.value;
        if (isConfirm) body.isConfirmed = isConfirm;
        if (isPreferredFinancier) body.is_preferred_financier = isPreferredFinancier;
        dispatch(getPayments({ body, mode }));
        setSortBy(event.target.value)
    };

    const onSearch = (value: any) => {
        const body: any = { offset: 0, limit: 1000, startTime: period.startTime, endTime: period.endTime, isDeleted, search: value };
        if (method.length) body.payment_method_id = method;
        if (status.length) body.status = status;
        if (value.length) body.search_name = sortBy;
        if (imported === "IMPORT") body.source = imported;
        if (isHidden) body.isArchive = isHidden;
        if (isConfirm) body.isConfirmed = isConfirm;
        if (isPreferredFinancier) body.is_preferred_financier = isPreferredFinancier;
        dispatch(getPayments({ body, mode }));
        setSearchText(value);
    };

    const { debouncedWrapper: debouncedHandlePaymentSearch } = useDebounce(onSearch, 1000);

    const handlePageChange = useCallback((event: ChangeEvent<unknown>, page: number): void => {
        setDayPagination((prevState) => ({ ...prevState, page }));
    }, [setDayPagination]);

    const handleListDate = (id: number) => {
        const startListDate = dayjs().startOf('day').valueOf();
        const endListDate = id === 1 ? dayjs().endOf('day').valueOf() : dayjs().add(id - 1, 'day').endOf('day').valueOf();
        setPeriod({ startTime: startListDate, endTime: endListDate });
        setCurrentDay(id);
    };

    const handlePaymentsWeek = () => {
        const startListDate = dayjs().startOf('week').valueOf();
        const endListDate = dayjs().endOf('week').valueOf();
        setPeriod({ startTime: startListDate, endTime: endListDate });
    };

    return {
        date, view, calendarRef, paymentsList, handleAddClick, handleDatePrev, handleDateNext, handleViewChange, handleRangeSelect,
        handleEventSelect, handleEventResize, handleEventDrop, handlePeriodChoose, chips, setChips, handleDatesRender, handleStatusPayments,
        period, setPeriod, handleMethodPayments, mode, handleChangeMode, handleIsDeletedPayments, handleFilterReset, paymentsDayList, paymentsWeekList,
        handleChangeSearch, handleSearchTextChange, handleSortChange, handleImportPayments, handleHiddenPayments, dayPagination, pageAmount, paymentsWeekListCount,
        handlePageChange, isConfirm, handleConfirmPayments, handleFinancierPayments, handleEventAllow, currentDay, handleListDate, setCurrentDay, weekFilters, setWeekFilters
    };
};