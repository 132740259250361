import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setEditExpenseItemError } from "../../repository/slice";

function* updateExpenseItemWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, isError, message }: IResponse<any> = yield call(requester.put, "/zoolux-api/admin/expenditure", payload);
        if (isError) {
            yield put(setEditExpenseItemError(message));
        } else {
            yield put(setEditExpenseItemError(null));
        }
    } catch (error) {
        console.error("updateExpenseItemWorker: ", error);
    } finally {
        yield put(setLoading(false));
    }
};

export function* updateExpenseItemWatcher() {
    yield takeLatest("@saga/UPDATE_EXPENSE_ITEM", updateExpenseItemWorker);
};
