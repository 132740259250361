import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../../locales/tokens";
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useMemo, useRef, useState } from "react";

export const useStatusSelect = (chips: any[], setChips: Dispatch<SetStateAction<any[]>>, handleStatusPayments: (status: string[]) => void) => {
    const { t } = useTranslation();
    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const [openMenu, setOpenMenu] = useState<boolean>(false);

    const statusOptions = [
        { label: "🟦 " + t(tokens.payment.created), value: 'CREATED', id: 1 },
        { label: "🟩 " + t(tokens.payment.processed), value: 'OPERATED', id: 2 },
    ];

    const statusValues = useMemo(() => {
        let values = chips
            .filter((chip) => chip.field === "status")
            .map((chip) => chip.value) as string[];
        if (values.length === 0) {
            // values.unshift("");
        }
        return values;
    }, [chips]);

    const handleStatusChange = useCallback((values: string[]): void => {
        setChips((prevChips) => {
            const filteredChips = prevChips.filter((chip) => chip.field !== "status");
            const newChips = values.map((value) => {
                switch (value) {
                    case "CREATED":
                        return {
                            label: t(tokens.payment.status),
                            field: "status",
                            value: "CREATED",
                            displayValue: "🟦 " + t(tokens.payment.created),
                        };
                    case "OPERATED":
                        return {
                            label: t(tokens.payment.status),
                            field: "status",
                            value: "OPERATED",
                            displayValue: "🟩 " + t(tokens.payment.processed),
                        };
                    default:
                        return null;
                }
            }).filter(Boolean);
            handleStatusPayments(values);
            localStorage.setItem('chips', JSON.stringify([...filteredChips, ...newChips]));
            return [...filteredChips, ...newChips];
        });
    }, []);

    const handleMenuOpen = useCallback((): void => {
        setOpenMenu(true);
    }, []);

    const handleMenuClose = useCallback((): void => {
        setOpenMenu(false);
    }, []);

    const ChangeStatus = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        let newValue = [...statusValues];
        if (event.target.checked) {
            newValue.push(event.target.value);
        } else {
            newValue = newValue.filter((item) => item !== event.target.value);
        }
        handleStatusChange(newValue);
    },
        [handleStatusChange, statusValues]
    );

    return { ChangeStatus, openMenu, anchorRef, handleMenuOpen, handleMenuClose, statusOptions, statusValues }
};