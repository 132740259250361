export const getStyles = () => {
    const styles = {
        thead: {
            backgroundColor: '#4287f5', 
            '& th:first-of-type': {
                borderRadius: '15px 0 0 15px'
            },
            '& th:last-of-type': {
                borderRadius: '0 15px 15px 0'
            },
        },
        cell: {
            fontWeight: 600,
            color: "#ffffff"
        }
    };
    return styles;
};