import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "../../../../store/auth/repository/selector";
import { verifyPhone } from "../../../../store/auth/useCases/verifyPhone/action";
import { createPassword } from "../../../../store/auth/useCases/createPassword/action";
import { confirmSmsCode } from "../../../../store/auth/useCases/confirmSmsCode/action";
import { signIn } from "../../../../store/auth/useCases/signIn/action";
import { setVerify } from "../../../../store/auth/repository/actions";

export const useAuthorization = () => {
    const { isPhoneConfirmed, isLoading, isConfirmedSms } = useSelector(selectAuth);
    const dispatch = useDispatch();
    const [phone, setPhone] = useState('');
    const [phoneValid, setPhoneValid] = useState(true);
    const [isPhoneInBD, setIsPhoneInBD] = useState(false);
    const [smsCode, setSmsCode] = useState('');
    const [timerInSec, setTimerInSec] = useState(0);
    const [isCorrectSms, setCorrectSms] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState(true);
    const [isPassShown, setPassShown] = useState(false);
    const [confirmPass, setConfirmPass] = useState('');
    const [confirmPassValid, setConfirmPassValid] = useState(true);
    const [isConfirmPassShown, setConfirmPass2Shown] = useState(false);

    const handlePhone = () => {
        !phone.length && setPhone('+380');
    };

    const onChangePhone = (event: ChangeEvent<HTMLInputElement>) => {
        setPhoneValid(true);
        setPhone(event.target.value);
    };

    const validatePhone = (phoneNumber: string) => {
        const phonePattern = /^\+380\d{9}$/;
        return phonePattern.test(phoneNumber);
    };

    const handleEnterPassword = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };

    const handleCheckPhone = () => {
        const isPhoneValid = validatePhone(phone);
        setPhoneValid(isPhoneValid);
        if (isPhoneValid) {
            dispatch(verifyPhone({ phone: phone.slice(3) }));
        };
    };

    const resendSmsCode = () => {
        dispatch(verifyPhone({ phone: phone.slice(3) }));
        dispatch(setVerify({isPhoneConfirmed: null}));
    };

    useEffect(() => {
        if (isPhoneConfirmed === false) {
            setIsPhoneInBD(true);
            setTimerInSec(59);
            return;
        } else if (isPhoneConfirmed === true) {
            setCorrectSms(true);
        }
    }, [isPhoneConfirmed]);

    useEffect(() => {
        const timer = setInterval(() => {
            if (timerInSec > 0) {
                setTimerInSec(timerInSec - 1);
            } /* else if(timerInSec<1 && !isCorrectSms){
                dispatch(setVerify({isPhoneConfirmed: null}))
                setIsPhoneInBD(false);
            }; */
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [timerInSec]);

    const onChangeTimer = () => {
        setTimerInSec(59);
    };

    const onChangeSmsCode = (event: ChangeEvent<HTMLInputElement>) => {
        setSmsCode(event.target.value);
    };

    const handleCheckSmsCode = () => {
        dispatch(confirmSmsCode({ phone: phone.slice(3), code: Number(smsCode) }))
    };

    useEffect(() => {
        if (isConfirmedSms) {
            setCorrectSms(true);
        }
    }, [isConfirmedSms])


    const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
        setPasswordValid(true);
        setPassword(event.target.value);
    };

    const validatePassword = (password: string) => {
        // const passwordPattern = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z]).{8,}$/;
        // return passwordPattern.test(password);
        return password === password;
    };

    const onShowPassword = () => {
        setPassShown(!isPassShown)
    };

    const onChangeConfirmPassword = (event: ChangeEvent<HTMLInputElement>) => {
        setConfirmPassValid(true);
        setConfirmPass(event.target.value);
    };

    const onShowConfirmPassword = () => {
        setConfirmPass2Shown(!isConfirmPassShown)
    };

    const handleLogin = () => {
        const isPasswordValid = validatePassword(password);
        const isPhoneValid = validatePhone(phone);

        setPasswordValid(isPasswordValid);
        setPhoneValid(isPhoneValid);

        if (isPasswordValid && isPhoneValid) {

            if (isCorrectSms && isPhoneInBD) {
                if (password !== confirmPass) {
                    setConfirmPassValid(false);
                    return;
                }
                dispatch(createPassword({ password: password }));
            } else if (!isPhoneInBD) {
                dispatch(signIn({ phone: phone.slice(3), password }));
            }
        }
    };

    useEffect(()=>{
        setCorrectSms(false);
        setIsPhoneInBD(false);
    }, []);
    
    return {
        phone, onChangePhone, password, onChangePassword, handleLogin, isPassShown, onShowPassword, handlePhone,
        phoneValid, passwordValid, isLoading, isPhoneInBD, smsCode, onChangeSmsCode, timerInSec, isCorrectSms,
        handleCheckPhone, handleCheckSmsCode, onChangeConfirmPassword, onShowConfirmPassword, confirmPass,
        confirmPassValid, isConfirmPassShown, onChangeTimer, resendSmsCode, handleEnterPassword
    };
};