import { FC, useMemo } from "react";
import { TableCell, TableHead, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getStyles } from "./styles";
import { tokens } from "../../../../../../locales/tokens";

export const TableListHead: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);

    return (
        <TableHead>
            <TableRow>
                <TableCell style={styles.cell}>{t(tokens.paymentsList.account)}</TableCell>
                <TableCell style={styles.cell}>{t(tokens.paymentsList.partner)}</TableCell>
                <TableCell style={styles.cell}>{t(tokens.paymentsList.sum)}</TableCell>
                <TableCell style={styles.cell}>{t(tokens.paymentsList.date)}</TableCell>
                <TableCell style={styles.cell}>{t(tokens.paymentsList.initiator)}</TableCell>
                <TableCell style={styles.cell}>{t(tokens.paymentsList.method)}</TableCell>
                <TableCell style={styles.cell}>{t(tokens.paymentsList.status)}</TableCell>
                <TableCell style={styles.cell}>{t(tokens.paymentsList.balance)}</TableCell>
            </TableRow>
        </TableHead>
    )
};