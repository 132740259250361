import { SagaIterator } from "redux-saga";
import { put, call, takeEvery } from "redux-saga/effects";
import { requester } from "../../../../libs/requester/requester-axios";
import { IResponse } from "../../../../libs/requester/IResponse";
import { setCreatePartnerError, setLoading } from "../../repository/slice";

function* createPartnerWorker({ payload }: any): SagaIterator {    
    try {
        yield put(setLoading(true));
        const { data, isError, message }: IResponse<any> = yield call(requester.post, "/zoolux-api/admin/counterparties", payload);
        if (isError) {
            yield put(setCreatePartnerError(message));
        } else {
            yield put(setCreatePartnerError(null));
        }
    } catch (error) {
        console.error("createPartnerWorker: ", error);
    } finally {
        yield put(setLoading(false));
    }
};

export function* createPartnerWatcher() {
    yield takeEvery("@saga/CREATE_NEW_PARTNER", createPartnerWorker);
};