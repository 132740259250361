
import toast from 'react-hot-toast';
import { requester } from '../../../libs/requester/requester-axios';
import { deleteEmptyParams } from '../../../utils';
import { zooluxAPI } from '../../zooluxAPI';
import { IParamsFixedAssets, IResponseFixedAssets } from '../repository/IFixedAsset';

const fixedAsset = zooluxAPI.injectEndpoints({
    endpoints: (builder) => ({
        getFixedAssets: builder.query<IResponseFixedAssets, IParamsFixedAssets>({
            async queryFn(params, { dispatch }) {
                try {
                    const { data, isError, message }: any = await requester.get('/zoolux-api/fixed-assets', deleteEmptyParams(params));
                    if (isError) {
                        toast.error(message);
                        throw new Error(message);
                    };
                    return { data };
                } catch (e) {
                    throw e;
                }
            },
            providesTags: ["FixedAsset"],
            keepUnusedDataFor: 0
        }),

    }),
    overrideExisting: false,
});

export const { useGetFixedAssetsQuery } = fixedAsset;