import type { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button, ButtonGroup, IconButton, Stack, SvgIcon, TextField } from '@mui/material';
import type { CalendarView } from '../../../../../../types/payment-calendar';
import { getStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../../locales/tokens';
import dayjs from 'dayjs';
import { RangeDatePicker } from '../rangeDatePicker';
import { ViewGroupButtons } from '../viewGroupButtons';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangeField } from '../dateRangeField';
import { ListDayToolbar } from '../listDayToolbar';

interface IProps {
    children?: ReactNode;
    date: Date;
    view: CalendarView;
    period: any;
    currentDay: number;
    setCurrentDay: Dispatch<SetStateAction<number>>;
    setPeriod: Dispatch<any>;
    onAddClick?: () => void;
    onDateNext?: () => void;
    onDatePrev: () => void;
    onViewChange?: (view: CalendarView) => void;
    handleListDate: (id: number) => void;
    handlePeriodChoose: (startDate: Date, endDate: Date) => void;
};

export const CalendarToolbar: FC<IProps> = ({ date, onAddClick, onDateNext, onDatePrev, onViewChange, view,
    handlePeriodChoose, period, setPeriod, handleListDate, currentDay, setCurrentDay, ...other }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const [isOpen, setOpen] = useState(false);
    const componentRef = useRef<HTMLDivElement | null>(null);
    dayjs.locale('uk');
    let startDate = dayjs(period.startTime).locale('uk').format('MMMM D, YYYY');
    let endDate = dayjs(period.endTime).locale('uk').format('MMMM D, YYYY');

    const handleOutsideClick = (e: MouseEvent) => {
        if (componentRef.current && !componentRef.current.contains(e.target as Node)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => { document.removeEventListener('click', handleOutsideClick); };
    }, []);

    return (
        <>
            <Stack spacing={3} sx={styles.toolbarContainer} {...other} >
                {view === 'dayGridMonth' &&
                    <Stack alignItems="center" direction="row" spacing={1} sx={{ position: 'relative', top: '5px' }} ref={componentRef}>
                        <RangeDatePicker
                            isOpen={isOpen}
                            setOpen={setOpen}
                            period={period}
                            setPeriod={setPeriod}
                            handlePeriodChoose={handlePeriodChoose}
                            onDatePrev={onDatePrev}
                            onDateNext={onDateNext}
                        />
                        <IconButton onClick={onDatePrev}><SvgIcon><ChevronLeftIcon /></SvgIcon></IconButton>
                        <DateRangeField startDate={startDate} endDate={endDate} />
                        <IconButton onClick={onDateNext}><SvgIcon><ChevronRightIcon /></SvgIcon></IconButton>
                    </Stack>
                }
                {view === 'listDay' &&
                    <ListDayToolbar
                        currentDay={currentDay}
                        startDate={startDate}
                        endDate={endDate}
                        handleListDate={handleListDate}
                    />}
                {view === 'listWeek' &&
                    <Stack alignItems="flex-start" justifyContent="flex-start" spacing={1} >
                        <DateRangeField startDate={startDate} endDate={endDate} />
                    </Stack>
                }
                <Stack alignItems="center" direction="row" spacing={1}>
                    <ViewGroupButtons
                        view={view}
                        onViewChange={onViewChange}
                        setCurrentDay={setCurrentDay}
                    />
                    <Button
                        onClick={onAddClick}
                        startIcon={(<SvgIcon><AddIcon /></SvgIcon>)}
                        sx={styles.btnNew}
                        variant="contained"
                        size='small'
                    >
                        {t(tokens.payment.createPaymentButton)}
                    </Button>
                </Stack>
            </Stack>
        </>
    );
};