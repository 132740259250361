import { FC, useMemo } from "react";
import { useDocumentVerifiedPayments } from "../presenter/useDocumentVerifiedPayments";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";
import { SearchInput } from "../../../ui-kit/searchInput/searchInput";
import { EmptyList } from "../../../ui-kit/emptyList";
import { getStyles } from "./styles";
import { PaymentsListTable } from "./components/paymentsListTable";

export const DocumentVerifiedPayments: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { searchText, handleSearchText, payments, isProcess, filters, navigateToPayment, pageAmount, handlePageChange, currentPage } = useDocumentVerifiedPayments();

    return (
        <Stack>
            <Typography variant="h4" mt={3}>{t(tokens.verifiedPayments.verifiedPaymentsList)}</Typography>
            <Stack mt={5}>
                <SearchInput
                    searchText={searchText}
                    label={t(tokens.service.searchByTitle)}
                    handleSearch={handleSearchText}
                />
            </Stack>
            {payments?.length > 0 &&
                <PaymentsListTable
                    payments={payments}
                    navigateToPayment={navigateToPayment}
                    currentPage={currentPage}
                    pageAmount={pageAmount}
                    handlePageChange={handlePageChange}
                />}
            {(!payments?.length && isProcess) &&
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={styles.progress}
                >
                    <CircularProgress color="primary" />
                </Stack>
            }
            {(!payments?.length && !isProcess) &&
                <EmptyList
                    title={t(tokens.verifiedPayments.listEmpty)}
                    description={t(tokens.verifiedPayments.emptyListDesc)}
                />
            }
        </Stack>
    )
};