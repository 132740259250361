import { IconButton, Stack, SvgIcon, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { FC, Fragment, useCallback, useMemo } from "react";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../../locales/tokens";
import { getStyles } from "./styles";
import { useGetPermissions } from "../../../../../../../hooks/useGetPermission";

interface IProps {
    role: any;
    handleDelete: (id: number) => void;
};

export const RoleTableItem: FC<IProps> = ({ role, handleDelete }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { isUpdate_Role, isDelete_Role } = useGetPermissions();
    const deleteRole = useCallback(() => handleDelete(role.id), [role.id]);

    const translatedPermissions: any = {
        Find_All_Budget_Calendar: t(tokens.permissions.getAllBudgetPayments),
        Find_All_Cash_Budget_Calendar: t(tokens.permissions.getAllCashBudgetPayments),
        Find_All_Card_Budget_Calendar: t(tokens.permissions.getAllCardBudgetPayments),
        Find_All_Non_Cash_Budget_Calendar: t(tokens.permissions.getAllNonCashBudgetPayments),
        Delete_Budget_Calendar_All: t(tokens.permissions.removePaymentKind),
        Update_Budget_Calendar: t(tokens.permissions.updatePaymentType),
        Create_Clinic: t(tokens.permissions.createClinic),
        Find_All_Clinic: t(tokens.permissions.getAllClinics),
        Update_Clinic: t(tokens.permissions.updateClinic),
        Create_Company_Account: t(tokens.permissions.createCompanyAccount),
        Find_One_Company_Account: t(tokens.permissions.getCompanyAccount),
        Update_Company_Account: t(tokens.permissions.updateCompanyAccount),
        Create_Counterparty: t(tokens.permissions.createCounterparty),
        Find_One_Counterparty: t(tokens.permissions.getCounterparty),
        Update_Counterparty: t(tokens.permissions.updateCounterparty),
        Find_All_Counterparty_Folder: t(tokens.permissions.getCounterpartiesInFolder),
        Refresh_Enote_Counterparty_Folder: t(tokens.permissions.updateCounterpartiesInFolder),
        Create_Expenditure: t(tokens.permissions.createExpenseArticle),
        Find_One_Expenditure: t(tokens.permissions.getExpenseArticle),
        Update_Expenditure: t(tokens.permissions.updateExpenseArticle),
        Get_Logging: t(tokens.permissions.getLogging),
        Set_Visa_Payment: t(tokens.permissions.signPayment),
        Set_Visa_Local_Payment: t(tokens.permissions.signLocalPayment),
        Delete_Visa_Payment: t(tokens.permissions.removeSignature),
        Delete_Visa_Local_Payment: t(tokens.permissions.removeLocalSignature),
        Process_Payment: t(tokens.permissions.payPayment),
        Process_Local_Payment: t(tokens.permissions.payLocalPayment),
        Process_Cash_Payment: t(tokens.permissions.payCashPayment),
        Process_Card_Payment: t(tokens.permissions.payCardPayment),
        Process_Non_Cash_Payment: t(tokens.permissions.payNonCashPayment),
        Find_All_Payment: t(tokens.permissions.getAllPayments),
        Find_All_Cash_Payment: t(tokens.permissions.getAllCashPayments),
        Find_All_Card_Payment: t(tokens.permissions.getAllCardPayments),
        Find_All_Non_Cash_Payment: t(tokens.permissions.getAllNonCashPayments),
        Delete_Payment: t(tokens.permissions.removePayment),
        Delete_Local_Payment: t(tokens.permissions.removeLocalPayment),
        Update_Payment: t(tokens.permissions.updatePayment),
        Transfer_To_Payment_Budget_Calendar: t(tokens.permissions.convertBudgetPaymentToCalendar),
        Create_Payment_Kind: t(tokens.permissions.createPaymentKind),
        Update_Payment_Kind: t(tokens.permissions.updatePaymentKind),
        Remove_Payment_Kind: t(tokens.permissions.removePaymentKind),
        Create_Payment_Method: t(tokens.permissions.createPaymentMethod),
        Update_Payment_Method: t(tokens.permissions.updatePaymentMethod),
        Remove_Payment_Method: t(tokens.permissions.removePaymentMethod),
        Create_Payment_Type: t(tokens.permissions.createPaymentType),
        Update_Payment_Type: t(tokens.permissions.updatePaymentType),
        Remove_Payment_Type: t(tokens.permissions.removePaymentType),
        Change_Status_Prepayment: t(tokens.permissions.changePrepaymentStatus),
        Find_One_User: t(tokens.permissions.getUser),
        Find_All_Users: t(tokens.permissions.getUsers),
        Create_Role: t(tokens.permissions.createRole),
        Get_All_Role: t(tokens.permissions.getRoles),
        Update_Role: t(tokens.permissions.updateRole),
        Delete_Role: t(tokens.permissions.removeRole),
        Get_All_Permission: t(tokens.permissions.getAllPermissions),
        Update_Role_Permission: t(tokens.permissions.addRoleAccessToUser),
        Create_User_Role: t(tokens.permissions.createRoleForUser),
        Delete_User_Role: t(tokens.permissions.revokeUserRole),
        Create_User_Permission: t(tokens.permissions.grantUserAccess),
        Delete_User_Permission: t(tokens.permissions.revokeUserAccess),
        Find_All_Company_Account: t(tokens.permissions.getAllCompanyAccounts),
        Find_All_Counterparties: t(tokens.permissions.getAllCounterparties),
        Find_All_Expenditure: t(tokens.permissions.getAllExpenseArticles),
        Get_List_Day_Payment: t(tokens.permissions.getListDayPayment),
        Get_List_Prepayment: t(tokens.permissions.getListPrepayment),
        Create_Import_Payment: t(tokens.permissions.createImportPayment),
        Get_All_Import_Payment: t(tokens.permissions.getAllImportPayment),
        Get_List_Operacionist_Payment: t(tokens.permissions.getListOperacionistPayment),
        Get_List_Financier_Payment: t(tokens.permissions.getListFinancierPayment),
        Write_Service_Receiving: t(tokens.permissions.writeServiceReceiving),
        Edit_Telegram: t(tokens.permissions.editTelegram),
        Read_Telegram: t(tokens.permissions.readTelegram),
        Read_Service_Receiving: t(tokens.permissions.readServiceReceiving),
        Completed_Budget_Calendar: t(tokens.permissions.completedBudgetCalendar),
        Confirm_Budget_Calendar: t(tokens.permissions.confirmBudgetCalendar),
        Confirm_Payment: t(tokens.permissions.confirmPayment),
        Create_In_Enote: t(tokens.permissions.createInEnote),
    };

    return (
        <TableRow hover key={role.id}>
            <TableCell align="left" width='20%'><Typography>{role.name}</Typography></TableCell>
            <TableCell align="left" width='60%'>
                <Stack direction='row' flexWrap="wrap">
                    {role.permissions.map((item: any, index: number) =>
                        <Fragment key={item.id}>
                            <Typography mr={1}>{translatedPermissions[item.name]},</Typography>
                        </Fragment>
                    )}
                </Stack>
            </TableCell>
            <TableCell align="right" width='10%'>
                <Stack direction='row'>
                    <IconButton size="small" component={Link} to={`/user-roleEdit/${role.id}`} disabled={!isUpdate_Role}>
                        <Tooltip title={t(tokens.common.edit)}>
                            <SvgIcon style={styles.svgIcon}><EditOutlinedIcon /></SvgIcon>
                        </Tooltip>
                    </IconButton>
                    <IconButton size="small" onClick={deleteRole} disabled={!isDelete_Role}>
                        <Tooltip title={t(tokens.common.delete)}>
                            <SvgIcon style={styles.svgIcon}><DeleteForeverIcon /></SvgIcon>
                        </Tooltip>
                    </IconButton>
                </Stack>
            </TableCell>
        </TableRow>
    )
};