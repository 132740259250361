import { SagaIterator } from "redux-saga";
import { put, call, takeEvery } from "redux-saga/effects";
import { requester } from "../../../../libs/requester/requester-axios";
import { IResponse } from "../../../../libs/requester/IResponse";
import { setLoading, setCreateServiceRelatedError } from "../../repository/slice";
import { setRelatedServiceAfterCreate } from "../../../paymentCreation/repository/slice";

function* createServiceRelatedWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, isError, message }: IResponse<any> = yield call(requester.post, "/zoolux-api/service-related/create", payload);
        if (isError) {
            yield put(setCreateServiceRelatedError(message));
        } else {
            yield put(setCreateServiceRelatedError(null));
            yield put(setRelatedServiceAfterCreate(data));
        }
    } catch (error) {
        console.error("createServiceRelatedWorker: ", error);
    } finally {
        yield put(setLoading(false));
    }
};

export function* createServiceRelatedWatcher() {
    yield takeEvery("@saga/CREATE_SERVICE_RELATED", createServiceRelatedWorker);
};