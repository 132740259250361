import { Stack, TextField, Typography } from "@mui/material"
import { ChangeEvent, FC } from "react"

interface IProps {
    disabled: boolean;
    title: string;
    label: string;
    value: string;
    isValid: boolean;
    isTextArea: boolean;
    errorText?: string;
    onChangeValue: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const TextInput: FC<IProps> = ({ value, onChangeValue, isValid, label, title, isTextArea, errorText, disabled }) => {

    return (
        <Stack direction='column' spacing={1} height='90px' width="100%">
            <Typography color={isValid ? 'primary' : 'error'} variant='h6'>{title}</Typography>
            <Stack>
                <TextField
                    autoComplete="off"
                    disabled={disabled}
                    fullWidth
                    value={value}
                    onChange={onChangeValue}
                    label={label}
                    error={!isValid}
                    multiline={isTextArea}
                    rows={isTextArea ? 3 : 1}
                    helperText={!isValid ? errorText : null}
                />
            </Stack>
        </Stack>
    )
};