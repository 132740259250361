import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { editPartner } from "../../../../store/partners/useCases/editPartner/action";
import { getSinglePartner } from "../../../../store/partners/useCases/getSinglePartner/action";
import { selectPartner } from "../../../../store/partners/repository/selector";
import { setSinglePartner } from "../../../../store/partners/repository/slice";
import { tokens } from "../../../../locales/tokens";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

export const usePartnerEdit = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { singlePartner, isLoading, updatePartnerError } = useSelector(selectPartner);
    const { partnerId } = useParams();
    const [shortName, setShortName] = useState('');
    const [shortNameValid, setShortNameValid] = useState(true);
    const [bankName, setBankName] = useState('');
    const [edrpou, setEdrpou] = useState('');
    const [desc, setDesc] = useState('');
    const [updateClick, setUpdateClick] = useState(false);

    useEffect(() => {
        dispatch(getSinglePartner({ id: partnerId }));
        return () => {
            dispatch(setSinglePartner(null));
        }
    }, []);

    useEffect(() => {
        if (singlePartner) {
            setShortName(singlePartner.name || '');
            setBankName(singlePartner.name_bank || '');
            setEdrpou(singlePartner.EDRPOU || '');
            setDesc(singlePartner.description || '');
        }
    }, [singlePartner]);

    useEffect(() => {
        if (!isLoading && updateClick) {
            if (updatePartnerError) {
                toast.error(updatePartnerError);
            } else {
                toast.success(t(tokens.partner.message.partnerUpdated));
                navigate('/partner');
            }
            setUpdateClick(false);
        }
    }, [updatePartnerError, isLoading]);

    const onChangeShortName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setShortName(event.target.value);
        setShortNameValid(true);
    }, []);

    const onChangeBankName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setBankName(event.target.value);
    }, []);

    const onChangeEdrpou = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        if (/^\d{1,8}$/.test(input)) {
            setEdrpou(input);
        }
    }, []);

    const onChangeDesc = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setDesc(event.target.value);
    }, []);

    const handleEdit = () => {
        if (shortName) {
            dispatch(editPartner(getRequestBody()));
            setUpdateClick(true);
        } else {
            toast.error(t(tokens.partner.fillAllFileds));
            setShortNameValid(false);
        }
    };

    const getRequestBody = () => {
        const body: any = { id: Number(partnerId), name: shortName, name_bank: bankName || null, description: desc || null };
        return body;
    };

    return {
        shortName, edrpou, desc, onChangeShortName, onChangeEdrpou, onChangeDesc, handleEdit, bankName, onChangeBankName,
        updateClick, shortNameValid
    }
};