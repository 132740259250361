import { SagaIterator } from "redux-saga";
import { put, call, takeEvery } from "redux-saga/effects";
import { requester } from "../../../../libs/requester/requester-axios";
import { IResponse } from "../../../../libs/requester/IResponse";
import { setLoading, setUpdateServiceRelatedError } from "../../repository/slice";

function* editServiceRelatedWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, isError, message }: IResponse<any> = yield call(requester.put, "/zoolux-api/service-related/update", payload);
        if (isError) {
            yield put(setUpdateServiceRelatedError(message));
        } else {
            yield put(setUpdateServiceRelatedError(null));
        }
    } catch (error) {
        console.error("editServiceRelatedWorker: ", error);
    } finally {
        yield put(setLoading(false));
    }
};

export function* editServiceRelatedWatcher() {
    yield takeEvery("@saga/EDIT_SERVICE_RELATED", editServiceRelatedWorker);
};