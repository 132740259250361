import { useEffect } from "react";
import { useParams } from "react-router-dom"
import { getSinglePayment } from "../../../../store/paymentCalendar/useCases/getSinglePayment/action";
import { useDispatch, useSelector } from "react-redux";
import { selectPayments } from "../../../../store/paymentCalendar/repository/selector";

export const usePaymentReceipt = () => {
    const dispatch = useDispatch();
    const { paymentId } = useParams();
    const { singlePayment } = useSelector(selectPayments);

    useEffect(() => {
        dispatch(getSinglePayment({ body: { id: paymentId }, mode: 'Payment' }));
    }, [paymentId]);

    const watchCurrentFile = (name: string) => {
        const fileUrl = process.env.REACT_APP_PUBLIC_URL + '/zoolux-api/file-storage/' + name;
        window.open(fileUrl, '_blank');
    };

    return { singlePayment, watchCurrentFile }
};
