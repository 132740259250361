import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { requester } from "../../../../libs/requester/requester-axios";
import { IResponse } from "../../../../libs/requester/IResponse";
import toast from "react-hot-toast";
import { setApprover, setChangeStatusDate, setPrepaymentStatus } from "../../../paymentCreation/repository/slice";

function* changePrepaymentStatusWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.put, "/zoolux-api/prepayment/change-status", payload);
        if (isError) {
            toast.error(message);
        } else {
            yield put(setApprover(data.approver));
            yield put(setChangeStatusDate(data.status_change_date));
            yield put(setPrepaymentStatus(data.status));
            toast.success("Товар отримано");
        }
    } catch (error) {
        console.error("changePrepaymentStatusWorker: ", error);
    } finally {
    }
};

export function* changePrepaymentStatusWatcher() {
    yield takeEvery("@saga/CHANGE_PREPAYMENT_STATUS", changePrepaymentStatusWorker);
};