import { IError } from "../../../types/error";

export const setUsers = (payload: any[]) => ({
    type: "SET_USERS",
    payload
});

export const setUsersCount = (payload: number) => ({
    type: "SET_USERS_COUNT",
    payload
});

export const setSingleUser = (payload: any) => ({
    type: "SET_SINGLE_USER",
    payload
});

export const setRoles = (payload: any[]) => ({
    type: "SET_ROLES",
    payload
});

export const setPermissions = (payload: any[]) => ({
    type: "SET_PERMISSIONS",
    payload
});

export const setAddPermissionsError = (payload: IError) => ({
    type: "SET_ADD_PERMISSIONS_ERROR",
    payload
});

export const setDeleteRoleError = (payload: IError) => ({
    type: "SET_DELETE_ROLE_ERROR",
    payload
});

export const setLoading = (payload: boolean) => ({
    type: "SET_LOADING_USER",
    payload
});

export const setGetAccessError = (payload: IError) => ({
    type: "SET_GET_ACCESS_ERROR",
    payload
});
