import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";

interface IProps {
    user: any;
};

export const UserCard: FC<IProps> = ({ user }) => {
    const { t } = useTranslation();
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Typography color="primary.main" variant="overline" fontWeight={700}>
                {t(tokens.user.userNumber)}: {user?.ref_key}
            </Typography>
            <Typography color="text.primary" sx={{ mt: 2 }} variant={downSm ? 'subtitle1' : 'h6'}>
            {t(tokens.user.shortName)}: {user?.full_name}
            </Typography>
            <Typography color="text.primary" sx={{ mt: 1 }} variant={downSm ? 'subtitle2' : 'body1'}>
            {t(tokens.user.phone)}: {user?.phone}
            </Typography>
            <Typography color="text.primary" sx={{ mt: 1 }} variant={downSm ? 'subtitle2' : 'body1'}>
            {t(tokens.user.position)}: {user?.position || ''}
            </Typography>
        </Box>
    )
};