import { Tab, Tabs } from "@mui/material";
import { FC, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { StatusType, TabOption } from "../../../../../../types/users";

interface IProps {
    currentTab: StatusType;
    handleStatusChange: (event: SyntheticEvent<Element, Event>, value: any) => void;
};

export const TabStatus: FC<IProps> = ({ handleStatusChange, currentTab }) => {
    const { t } = useTranslation();

    const tabs: TabOption[] = [
        { label: 'Всі', value: 'ALL' },
        { label: 'Активні', value: 'ACTIVE' },
    ];

    return (
        <Tabs
            indicatorColor="primary"
            onChange={handleStatusChange}
            scrollButtons="auto"
            sx={{ px: 3 }}
            textColor="primary"
            value={currentTab}
            variant="scrollable"
        >
            {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
        </Tabs>

    )
};