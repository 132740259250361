import { Button, Stack, Typography } from '@mui/material';
import { ElementType, FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
    title: string;
    textBtn?: string;
    disabled?: boolean;
    startIcon?: ReactElement;
    to?: string;
}

export const HeadingTitle: FC<IProps> = ({ title, textBtn, ...props }) => (
    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} position={"sticky"} top="64px" zIndex={3} bgcolor={"white"}>
        <Typography color="second.main" fontWeight={500} component={"h1"} fontSize={40}>{title}</Typography>
        {textBtn
            ? <Button component={Link as ElementType} variant="outlineGreen" {...props}>{textBtn}</Button>
            : null}
    </Stack>
);