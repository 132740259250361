import { combineReducers } from "@reduxjs/toolkit";
import { authReducer } from "./auth/repository/reducer";
import { paymentReducer } from "./paymentCalendar/repository/reducer";
import { userReducer } from "./users/repository/reducer";
import { PaymentCreationReducer } from "./paymentCreation/repository/slice";
import { PartnerReducer } from "./partners/repository/slice";
import { AccountReducer } from "./accounts/repository/slice";
import { ExpenseItemReducer } from "./expenseItem/repository/slice";
import { ImportPaymentReducer } from "./importPayment/repository/slice";
import { PrepaymentReducer } from "./prepayment/repository/slice";
import { ContractReducer } from "./contract/repository/slice";
import { ServiceReducer } from "./service/repository/slice";
import { TelegramBotReducer } from "./telegramBot/repository/slice";
import { ServiceRelatedReducer } from "./serviceRelated/repository/slice";
import { ServiceHistoryReducer } from "./serviceHistory/repository/slice";
import { OperationValidationReducer } from "./operationValidation/repository/slice";
import { NomenclatureReducer } from "./nomenclature/repository/slice";
import { zooluxAPI } from './zooluxAPI';
import { ConfigurationReducer } from "./configuration/repository/slice";

export const rootReducer = combineReducers({
    [zooluxAPI.reducerPath]: zooluxAPI.reducer,
    auth: authReducer,
    payments: paymentReducer,
    user: userReducer,
    paymentCreation: PaymentCreationReducer,
    partner: PartnerReducer,
    account: AccountReducer,
    expenseItem: ExpenseItemReducer,
    importPayment: ImportPaymentReducer,
    prepayment: PrepaymentReducer,
    contract: ContractReducer,
    service: ServiceReducer,
    telegramBot: TelegramBotReducer,
    serviceRelated: ServiceRelatedReducer,
    serviceHistory: ServiceHistoryReducer,
    operationValidation: OperationValidationReducer,
    nomenclature: NomenclatureReducer,
    configuration: ConfigurationReducer
});