import { TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import { getStyles } from "./styles";

export const ContractsListHead: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);

    return (
        <TableHead >
            <TableRow sx={styles.header}>
                {/* <TableCell align="center" width='15%'>
                    <Typography fontWeight={600}>{t(tokens.contracts.title)}</Typography>
                </TableCell> */}
                <TableCell align="center" width='10%'>
                    <Typography fontWeight={600}>{t(tokens.contracts.type)}</Typography>
                </TableCell>
                <TableCell align="center" width='30%'>
                    <Typography fontWeight={600}>{t(tokens.contracts.comment)}</Typography>
                </TableCell>
                <TableCell align="center" width='10%'>
                    <Typography fontWeight={600}>{t(tokens.contracts.startContract)}</Typography>
                </TableCell>
                <TableCell align="center" width='10%'>
                    <Typography fontWeight={600}>{t(tokens.contracts.endContract)}</Typography>
                </TableCell>
                <TableCell align="center" width='15%'>
                    <Typography fontWeight={600}>{t(tokens.contracts.file)}</Typography>
                </TableCell>
                <TableCell align="right" width='10%'>
                    <Typography fontWeight={600} pr={4}>{t(tokens.contracts.actions)}</Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    )
};