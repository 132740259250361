import { SagaIterator } from "redux-saga";
import { put, call, takeEvery } from "redux-saga/effects";
import { requester } from "../../../../libs/requester/requester-axios";
import { IResponse } from "../../../../libs/requester/IResponse";

function* downloadFileWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.get, "/zoolux-api/file/download/" + payload.fileName );
        if (isError) {
            
        } else {
            // yield put(setNewsById(data));
        }
    } catch (error) {
        console.error("downloadFileWorker: ", error);
    } finally {

    }
};

export function* downloadFileWatcher() {
    yield takeEvery("@saga/DOWNLOAD_FILE", downloadFileWorker);
};