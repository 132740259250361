export const getStyles = () => {
    const styles = {
        button: { 
            textTransform: 'none', 
            color: 'white' 
        },
        menuItem: { 
            "span": { 
                fontSize: '14px', 
                height: '20px' 
            } 
        },
        formControl: { 
            flexGrow: 1, 
            mr: 0 
        }
    };
    return styles;
};