import { SagaIterator } from "redux-saga";
import { put, call, takeEvery } from "redux-saga/effects";
import { requester } from "../../../../libs/requester/requester-axios";
import { IResponse } from "../../../../libs/requester/IResponse";
import toast from "react-hot-toast";
import { getPayments } from "../getPayments/action";

function* deletePaymentWorker({ payload }: any): SagaIterator {
    try {
        const url = payload.modeFilter === "Payment"? "/zoolux-api/payments/" : "/zoolux-api/budget-payments/budget-payment/";
        const { data, isError, message }: IResponse<any> = yield call(requester.delete, url + payload.id);
        if (isError) {
            toast.error(message);
        } else {
            toast.success("Платіж видалено");
        }
    } catch (error) {
        console.error("deletePaymentWorker: ", error);
    } finally {
        
    }
};

export function* deletePaymentWatcher() {
    yield takeEvery("@saga/DELETE_PAYMENT", deletePaymentWorker);
};