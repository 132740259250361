
import toast from 'react-hot-toast';
import { requester } from '../../../libs/requester/requester-axios';
import { zooluxAPI } from '../../zooluxAPI';
import { IResponseCreateFixedAsset } from '../repository/IFixedAsset';

const fixedAsset = zooluxAPI.injectEndpoints({
    endpoints: (builder) => ({
        getFixedAsset: builder.query<IResponseCreateFixedAsset | any, number>({
            async queryFn(id, { dispatch }) {
                try {
                    const { data, isError, message }: any = await requester.get('/zoolux-api/fixed-assets/' + id);
                    if (isError) {
                        toast.error(message);
                        throw new Error(message);
                    };
                    return { data };
                } catch (e) {
                    throw e;
                }
            },
            keepUnusedDataFor: 0
        }),

    }),
    overrideExisting: false,
});

export const { useGetFixedAssetQuery } = fixedAsset;