export const getStyles = () => {
    const styles = {
        button: {
            py: 2, 
            flex: 1, 
            color: '#1C1C1E',
            backgroundColor: "#EDF4F7", 
            boxShadow: '0 1px 4px rgba(0, 0, 0, 0.3), 0 0 30px rgba(0, 0, 0, 0.1) inset',
            "&:hover": {
                backgroundColor: '#d5dbde'
            }
        },
        activeButton: {
            py: 2, 
            flex: 1, 
            color: '#1C1C1E',
            backgroundColor: '#d5dbde', 
            boxShadow: '0 1px 4px rgba(0, 0, 0, 0.3), 0 0 30px rgba(0, 0, 0, 0.1) inset',
            "&:hover": {
                backgroundColor: '#d5dbde'
            }
        }
    };
    return styles;
}