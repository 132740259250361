import { Avatar, Checkbox, IconButton, Stack, SvgIcon, TableBody, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { ChangeEvent, FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { useGetPermissions } from "../../../../../../hooks/useGetPermission";

interface IProps {
    isSelected: boolean;
    user: any;
    signInToUserAccount: (id: number) => void;
    handleToggleOne: (event: ChangeEvent<HTMLInputElement>, id: number) => void;
};

export const UsersTableItem: FC<IProps> = ({ user, isSelected, handleToggleOne, signInToUserAccount }) => {
    const { t } = useTranslation();
    const { isFind_One_User } = useGetPermissions();
    const getAccess = useCallback(()=> signInToUserAccount(user.id), [user.id]);

    return (
        <TableRow hover key={user?.id} selected={isSelected}>
            <TableCell padding="checkbox">
                <Checkbox
                    checked={isSelected}
                    onChange={(e) => handleToggleOne(e, user?.id)}
                    value={isSelected}
                />
            </TableCell>
            <TableCell sx={{ width: '25%' }}>
                <Stack alignItems="center" direction="row" spacing={1}>
                    <Avatar /* src={user.avatar} */ sx={{ height: 42, width: 42 }}>
                        {/* {getInitials(customer.name)} */}
                    </Avatar>
                    <div>
                        <Typography component={Link} color="inherit" to={`/user-edit/${user?.id}`} variant="subtitle2" noWrap>
                            {user?.full_name}
                        </Typography>
                        <Typography color="text.secondary" variant="body2">
                            {user?.email}
                        </Typography>
                    </div>
                </Stack>
            </TableCell>
            <TableCell align="center" sx={{ width: '20%' }}>
                {user?.position}
            </TableCell>
            <TableCell align="center" sx={{ width: '20%' }}>
                {user?.phone}
            </TableCell>
            <TableCell align="center" sx={{ width: '20%' }}>
                {user.roles?.map((role: string) =>
                    <Typography variant="subtitle2" key={role}>{role}</Typography>)
                }
            </TableCell>
            <TableCell align="center" sx={{ width: '15%' }}>
                <Stack direction='row'>
                    <Tooltip title="Увійти до акаунту">
                        <IconButton onClick={getAccess} disabled={!isFind_One_User}>
                            <SvgIcon>
                                <PersonSearchIcon />
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Редагувати користувача">
                        <IconButton component={Link} to={`/user-edit/${user?.id}`} disabled={!isFind_One_User}>
                            <SvgIcon>
                                <EditIcon />
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Видалити користувача">
                        <IconButton component={Link} to={`/user`}>
                            <SvgIcon>
                                <DeleteIcon />
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                </Stack>
            </TableCell>
        </TableRow>
    )
};