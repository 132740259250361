import { IconButton, Stack, SvgIcon, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from "react-router-dom";

interface IProps {
    contract: any;
    partnerId: string | undefined;
    handleDeleteContract: (id: number) => void;
};

export const ContractsListItem: FC<IProps> = ({ contract, handleDeleteContract, partnerId }) => {
    const { t } = useTranslation();
    const startDate = dayjs(contract.date_start).locale('uk').format('MMMM D, YYYY');
    const endDate = dayjs(contract.date_end).locale('uk').format('MMMM D, YYYY');
    const deletContract = useCallback(() => handleDeleteContract(contract.id), [contract.id]);

    return (
        <TableRow hover key={contract.id}>
            <TableCell align="center" >
                <Typography variant="subtitle2">{contract.type || t(tokens.contracts.noData)}</Typography>
            </TableCell>
            <TableCell align="center" >
                <Tooltip title={contract.comment || t(tokens.contracts.noData)}>
                    <Typography textAlign="center" variant="subtitle2">
                        {contract.comment.length > 75
                            ? `${contract.comment.slice(0, 75)}...`
                            : contract.comment
                        }
                    </Typography>
                </Tooltip>
            </TableCell>
            <TableCell align="center" >
                <Typography variant="subtitle2">{startDate}</Typography>
            </TableCell>
            <TableCell align="center" >
                <Typography variant="subtitle2">{endDate}</Typography>
            </TableCell>
            <TableCell align="center" >
                <Stack>
                    {contract?.files?.map((file: any) =>
                        <Tooltip title={file.path} key={file.name}>
                            <Typography
                                component={Link}
                                to={file.path}
                                variant="subtitle2"
                                sx={{ textDecoration: 'none', color: '#04A3F5' }}
                            >
                                {file.path.length > 30
                                    ? `${file.path.slice(0, 30)}...`
                                    : file.path
                                }
                            </Typography>
                        </Tooltip>
                    )}
                </Stack>
            </TableCell>
            <TableCell align="right" >
                <Stack direction="row" justifyContent="flex-end">
                    <Tooltip title={t(tokens.contracts.editContract)}>
                        <IconButton component={Link} to={`/partner-contractEdit/${partnerId}/${contract.id}`}>
                            <SvgIcon><EditIcon /></SvgIcon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t(tokens.contracts.deleteContract)}>
                        <IconButton onClick={deletContract}>
                            <SvgIcon><DeleteIcon /></SvgIcon>
                        </IconButton>
                    </Tooltip>
                </Stack>
            </TableCell>
        </TableRow>
    )
};