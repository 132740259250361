import { MenuSection } from "../../types/settings";
import { paths } from "../../paths";
import { TFunction } from "i18next";
import { tokens } from "../../locales/tokens";
import PaymentIcon from '@mui/icons-material/Payment';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import HandshakeIcon from '@mui/icons-material/Handshake';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DescriptionIcon from '@mui/icons-material/Description';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import TelegramIcon from '@mui/icons-material/Telegram';
import ReceiptIcon from '@mui/icons-material/Receipt';
import EventNoteIcon from '@mui/icons-material/EventNote';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
const { version } = require('../../../package.json');

const SERVICES = {
    servise: ['/service-history', '/service-empty-state', '/service-create', '/service-edit'],
};

export const getMenuSections = (t: TFunction, permissions: any, pathname: string): MenuSection[] => {   
    const isServiceTab = SERVICES.servise.some((item: any) => item.includes(pathname) || /^\/service-edit\/\d+$/.test(pathname));
    const menuSections: MenuSection[] = [{
        items: [
            
        ]
    }];

    if(!isServiceTab){
        menuSections[0].items.push(
            {
                title: t(tokens.payment.payments),
                icon: <PaymentIcon />,
                path: paths.dashboard.payment.list,
            },
        )
    };

    if(isServiceTab){
        menuSections[0].items.push(
            {
                title: t(tokens.serviceHistory.serviceHistory),
                icon: <EventNoteIcon />,
                path: paths.dashboard.serviceHistory.list,
            },
        )
    };

    if (permissions.isConfirm_Payment && !isServiceTab) {
        menuSections[0].items.push(
            {
                title: t(tokens.verifiedPayments.verifiedPayments),
                icon: <ReceiptIcon />,
                path: paths.dashboard.documentVerifiedPayments.list,
            }
        )
    };

    if (permissions.isRead_Service_Receiving && !isServiceTab) {
        menuSections[0].items.push(
            {
                title: t(tokens.service.service),
                icon: <RoomServiceIcon />,
                path: paths.dashboard.service.list,
                items: [
                    { title: t(tokens.common.list), path: paths.dashboard.service.list },
                    permissions.isWrite_Service_Receiving && {
                        title: t(tokens.common.create),
                        path: paths.dashboard.service.create
                    }
                ].filter(Boolean),
            }
        )
    };

    if (permissions.isFind_All_Users && !isServiceTab) {
        menuSections[0].items.push(
            {
                title: t(tokens.user.users),
                icon: <PeopleOutlineIcon />,
                path: paths.dashboard.user.list,
                items: [
                    { title: t(tokens.common.list), path: paths.dashboard.user.list },
                    permissions.isGet_All_Role && {
                        title: t(tokens.user.roles),
                        path: paths.dashboard.user.role.list,
                        items: [
                            { title: t(tokens.common.list), path: paths.dashboard.user.role.list },
                            permissions.isCreate_User_Role && { title: t(tokens.common.create), path: paths.dashboard.user.role.create },
                        ].filter(Boolean)
                    },
                ].filter(Boolean),
            },
        )
    };

    if (permissions.isFind_All_Counterparties && !isServiceTab) {
        menuSections[0].items.push(
            {
                title: t(tokens.partner.partner),
                icon: <HandshakeIcon />,
                path: paths.dashboard.partner.list,
                items: [
                    { title: t(tokens.common.list), path: paths.dashboard.partner.list },
                    { title: t(tokens.common.create), path: paths.dashboard.partner.create },
                ],
            },
        )
    };
 
    if (permissions.isFind_All_Company_Account && !isServiceTab) {
        menuSections[0].items.push(
            {
                title: t(tokens.account.accounts),
                icon: <AccountBalanceIcon />,
                path: paths.dashboard.account.list,
                items: [
                    { title: t(tokens.common.list), path: paths.dashboard.account.list },
                ],
            },
        )
    };

    if (permissions.isRead_Nomenclature && !isServiceTab) {
        menuSections[0].items.push(
            {
                title: t(tokens.nomenclature.nomenclature),
                icon: <FormatListBulletedIcon />,
                path: paths.dashboard.nomenclature.list,
            },
        )
    };

    if (permissions.isFind_All_Expenditure && !isServiceTab) {
        menuSections[0].items.push(
            {
                title: t(tokens.expenseItem.expenseItem),
                icon: <DescriptionIcon />,
                path: paths.dashboard.expenseItem.list,
                items: [
                    { title: t(tokens.common.list), path: paths.dashboard.expenseItem.list },
                ],
            },
        )
    };

    if (permissions.isGet_List_Prepayment && !isServiceTab) {
        menuSections[0].items.push(
            {
                title: t(tokens.payment.prepayment),
                icon: <AccountBalanceWalletIcon />,
                path: paths.dashboard.prepayment.list,
            },
        )
    };

    if (permissions.isGet_All_Import_Payment && !isServiceTab) {
        menuSections[0].items.push(
            {
                title: t(tokens.importPayment.importPayments),
                icon: <FileOpenIcon />,
                path: paths.dashboard.importPayment.list,
            },
        )
    };

    if (permissions.isRead_Telegram && !isServiceTab) {
        menuSections[0].items.push({
            title: t(tokens.telegram.telegramBot),
            icon: <TelegramIcon />,
            path: paths.dashboard.telegram.list,
        })
    };

    if (permissions.isRead_Configurations && !isServiceTab) {
        menuSections[0].items.push({
            title: t(tokens.configuration.configuration),
            icon: <SettingsApplicationsIcon />,
            path: paths.dashboard.configuration.list,
        })
    };

    menuSections[0].items.push({ title: `Zoolux ${version}` });

    return menuSections;
};