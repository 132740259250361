import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setPaymentMethod } from "../../repository/actions";
import toast from "react-hot-toast";

function* getPaymentMethodWorker(): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call( requester.get, "/zoolux-api/payment-method");

        if (isError) {
            toast.error(message);
        } else {
            yield put(setPaymentMethod(data));
        }
    } catch (error) {
        console.error("getPaymentMethodWorker: ", error);
    } finally {
        
    }
};

export function* getPaymentMethodWatcher() {
    yield takeLatest("@saga/GET_PAYMENT_METHOD", getPaymentMethodWorker);
};
