import { Pagination, Paper, Stack, Table, TableBody } from "@mui/material";
import { ChangeEvent, FC, useMemo } from "react";
import { PrepaymentTableItem } from "../prepaymentTableItem";
import { getStyles } from "./styles";
import { PrepaymentTableHead } from "../prepaymentTableHead";

interface IProps {
    prepaymentList: any[];
    page: number;
    pageAmount: number;
    handlePageChange: (event: ChangeEvent<unknown>, page: number) => void;
};

export const PrepaymentTable: FC<IProps> = ({ prepaymentList, handlePageChange, page, pageAmount }) => {
    const styles = useMemo(() => getStyles(), []);

    return (
        <Paper elevation={12} sx={styles.paper}>
            <Table sx={{ mt: 2 }}>
                <PrepaymentTableHead />
                <TableBody>
                    {prepaymentList.map(payment =>
                        <PrepaymentTableItem
                            payment={payment}
                            key={payment.prepayment.id}
                        />
                    )}
                </TableBody>
            </Table>
            <Stack direction='row' justifyContent='center' my={4}>
                <Pagination
                    page={page}
                    count={pageAmount || 1}
                    color="primary"
                    onChange={handlePageChange}
                />
            </Stack>
        </Paper>
    )
};