import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setPartners, setPartnersAmount } from "../../repository/actions";
import toast from "react-hot-toast";
import { setPartner } from "../../../paymentCreation/repository/slice";

function* getPartnersWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.get, "/zoolux-api/counterparties", payload);

        if (isError) {
            toast.error(message);
        } else {
            yield put(setPartners(data.data));
            yield put(setPartnersAmount(data.total));
        }
    } catch (error) {
        console.error("getPartnersWorker: ", error);
    } finally {

    }
};

export function* getPartnersWatcher() {
    yield takeLatest("@saga/GET_PARTNERS", getPartnersWorker);
};
