export const collectOptionList: Function = (options: any[]) => {
    const result = options?.reduce((result: any, option: any) => {
        return [
            ...result,
            {
                label: option.name,
                name: option.name,
                id: option.id,
            },
        ];
    }, [] as any[]) ?? [];
    return result;
};

export const collectFolderList: Function = (options: any[]) => {
    const result = options?.reduce((result: any, option: any) => {
        return [
            ...result,
            {
                label: option.name,
                name: option.name,
                id: option.id,
                ref_key: option.ref_key
            },
        ];
    }, [] as any[]) ?? [];
    return result;
};

export interface IPartnerItem {
    id: number;
    name: string;
    EDRPOU?: string | null;
    ref_key: string;
    bankDetailsPersonal?: string | null;
    bankDetailsBusiness?: string | null;
};

export interface IPartnerOption {
    label: string;
    name: string;
    id: number;
    edrpou: string | null;
    ref_key: string;
    bankDetailsPersonal?: string | null;
    bankDetailsBusiness?: string | null;
};

export const collectPartnerList: Function = (options: IPartnerItem[]) => {
    const result = options?.reduce((result: any, option: IPartnerItem) => {
        return [
            ...result,
            {
                label: option.name,
                name: option.name,
                id: option.id,
                edrpou: option.EDRPOU,
                ref_key: option.ref_key,
                bankDetailsPersonal: option.bankDetailsPersonal,
                bankDetailsBusiness: option.bankDetailsBusiness
            },
        ];
    }, [] as IPartnerOption[]) ?? [];
    return result;
};

export interface IExpenditureItem {
    id: number;
    name: string;
    ref_key: string;
    is_tax: boolean;
    is_permanent: boolean
};

export interface IExpenditureOption {
    label: string;
    name: string;
    id: number;
    ref_key: string;
    is_tax: boolean;
    is_permanent: boolean
};

export const collectExpenditureList: Function = (options: IExpenditureItem[]) => {

    return options?.reduce((result: any, option: IExpenditureItem) => {
        return [
            ...result,
            {
                label: option.name,
                name: option.name,
                id: option.id,
                ref_key: option.ref_key,
                is_tax: option.is_tax,
                is_permanent: option.is_permanent
            },
        ];
    }, [] as IExpenditureOption[]) ?? [];
};

export interface IClinicItem {
    id: number;
    name: string;
    ref_key: string | null;
    hidden: boolean;
};

export interface IClinicOption {
    label: string;
    name: string;
    id: number;
    ref_key: string | null;
    hidden: boolean;
};

export const collectClinicList: Function = (options: IClinicItem[]) => {

    return options?.reduce((result: any, option: IClinicItem) => {
        return [
            ...result,
            {
                label: option.name,
                name: option.name,
                id: option.id,
                ref_key: option.ref_key,
                hidden: option.hidden
            },
        ];
    }, [] as IClinicOption[]) ?? [];
};

export interface ICardItem {
    id: number;
    name: string;
    account_type: string | null;
    ref_key: string;
    owner_key: string;
    organization: string | null;
    hidden: boolean;
};

export interface ICardOption {
    label: string;
    name: string;
    id: number;
    organization: string | null;
    account_type: string | null;
    ref_key: string;
    owner_key: string;
    hidden: boolean;
};

export const collectCardList: Function = (options: ICardItem[]) => {

    return options?.reduce((result: any, option: ICardItem) => {
        return [
            ...result,
            {
                label: option.name,
                name: option.name,
                id: option.id,
                organization: option.organization,
                account_type: option.account_type,
                ref_key: option.ref_key,
                owner_key: option.owner_key,
                hidden: option.hidden
            },
        ];
    }, [] as ICardOption[]) ?? [];
};

export interface IBankCardItem {
    account: string;
    bank_name: string;
    bank_mfo: string;
    recipient_name: string;
};

export interface IBankCardOption {
    // id: number;
    inputValue?: string;
    account: string;
    bank_name: string;
    bank_mfo: string;
    recipient_name: string;
};

export const collectBankCardList: Function = (options: IBankCardItem[]) => {

    return options?.reduce((result: any, option: IBankCardItem) => {
        return [
            ...result,
            {
                // id: option.account,
                account: option.account,
                bank_name: option.bank_name,
                bank_mfo: option.bank_mfo,
                recipient_name: option.recipient_name
            },
        ];
    }, [] as IBankCardOption[]) ?? [];
};


export interface IUserItem {
    id: number;
    full_name: string;
    phone: string | null;
    email: string | null;
    fired_date: string | null;
    position: string | null;
};

export interface IUserOption {
    label: string;
    id: number;
    full_name: string;
    phone: string | null;
    email: string | null;
    fired_date: string | null;
    position: string | null;
};

export const collectUserList: Function = (options: IUserItem[]) => {
    const result = options?.reduce((result: any, option: IUserItem) => {
        return [
            ...result,
            {
                label: option.full_name,
                id: option.id,
                name: option.full_name,
                phone: option.phone,
                email: option.email,
                fired_date: option.fired_date,
                position: option.position
            },
        ];
    }, [] as IUserOption[]) ?? [];
    return result;
};