import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "../../../../hooks/useDebounce";
import toast from "react-hot-toast";
import { tokens } from "../../../../locales/tokens";
import { selectService } from "../../../../store/service/repository/selector";
import { deleteService } from "../../../../store/service/useCases/deleteService/action";
import { PER_PAGE } from "../../../../config";
import { editService } from "../../../../store/service/useCases/editService/action";
import { getServiceListAll } from "../../../../store/service/useCases/getServiceListAll/action";

export const useServiceList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const [serviceAmount, setServiceAmount] = useState(0);
    const [deleteClick, setDeleteClick] = useState(false);
    const [editClick, setEditClick] = useState(false);
    const [isProcess, setProcess] = useState(false);
    const [serviceId, setServiceId] = useState<null | number>(null);
    const [deletedId, setDeletedId] = useState<null | number>(null);
    const { serviceListAll, serviceCountAll, isLoading, deleteServiceError, updateServiceError, getServiceListError } = useSelector(selectService);
    const [filters, setFilters] = useState<{ page: number, rowsPerPage: number, search: string }>({ page: 1, rowsPerPage: PER_PAGE, search: '' });

    useEffect(() => {
        dispatch(getServiceListAll({ page: filters.page, perPage: filters.rowsPerPage, search: filters.search }));
        setProcess(true);
    }, [filters]);

    useEffect(() => {
        if (serviceCountAll) {
            setServiceAmount(Math.ceil(serviceCountAll / filters.rowsPerPage));
        }
    }, [serviceCountAll]);

    useEffect(() => {
        if (serviceListAll) {
            serviceListAll.forEach((service: any) => {
                service.is_default === true && setServiceId(service.id);
            });
        }
    }, [serviceListAll]);

    useEffect(() => {
        debouncedHandleSeacrh(searchText);
    }, [searchText]);

    useEffect(() => {
        if (!isLoading && isProcess) {
            if (getServiceListError) {
                toast.error(getServiceListError);
            } 
            setTimeout(()=>setProcess(false), 300);
        }
    }, [getServiceListError, isLoading]);

    useEffect(() => {
        if (!isLoading && deleteClick) {
            if (deleteServiceError) {
                toast.error(deleteServiceError);
            } else {
                toast.success(t(tokens.service.message.serviceDeleted));
                dispatch(getServiceListAll({ page: filters.page, perPage: filters.rowsPerPage, search: filters.search }));
            }
            setDeleteClick(false);
            setDeletedId(null);
        }
    }, [deleteServiceError, isLoading]);

    useEffect(() => {
        if (!isLoading && editClick) {
            if (updateServiceError) {
                toast.error(updateServiceError);
            } else {
                toast.success(t(tokens.service.message.serviceByDefaultChanged));
                dispatch(getServiceListAll({ page: filters.page, perPage: filters.rowsPerPage, search: filters.search }));
            }
            setEditClick(false);
        }
    }, [updateServiceError, isLoading]);

    const handleSearchText = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const onSearchService = (value: string) => {
        const search = value ? value : '';
        setFilters((prevState) => ({ ...prevState, page: 1, search }));
    };

    const { debouncedWrapper: debouncedHandleSeacrh } = useDebounce(onSearchService, 500);

    const handlePageChange = useCallback((event: ChangeEvent<unknown>, page: number): void => {
        setFilters((prevState) => ({ ...prevState, page }));
    }, [setFilters]);

    const handleDeleteService = (id: number) => {
        dispatch(deleteService({ id }));
        setDeleteClick(true);
        setDeletedId(id);
    };

    const handleServiceId = (id: number) => {
        setServiceId(id);
        dispatch(editService({
            service_receiving_id: id,
            is_default: true
        }));
        setEditClick(true);
    };

    return {
        searchText, handleSearchText, serviceListAll, handleDeleteService, serviceAmount, filters, handlePageChange,
        serviceId, handleServiceId, isProcess, deleteClick, deletedId
    }
};