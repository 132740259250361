import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setConfirmedSms } from "../../repository/actions";
import toast from "react-hot-toast";

function* confirmSmsCodeWorker({ payload }: any): SagaIterator {    
    try {
        const { data, isError }: IResponse<any> = yield call(requester.post, "/zoolux-api/user-auth/confirm-code", payload);
        if (isError) {
            toast.error("пароль не вірний")
        } else {
            yield call([localStorage, localStorage.setItem], "accessToken", data.token);
            yield put(setConfirmedSms(true));
        }
    } catch (error) {
        console.error("confirmSmsCodeWorker: ", error);
    } finally {

    };
};

export function* confirmSmsCodeWatcher() {
    yield takeLatest("@saga/CONFIRM_SMSCODE", confirmSmsCodeWorker);
};
