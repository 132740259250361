import { useCallback, useState, ChangeEvent, useRef, SyntheticEvent, FormEvent, useEffect, useMemo } from "react";
import { Filters, SortType, StatusType } from "../../../../types/users";
import { useDebounce } from "../../../../hooks/useDebounce";
import { useDispatch, useSelector } from "react-redux";
import { getUsersAll } from "../../../../store/users/usecases/getUsersAll/action";
import { selectUsers } from "../../../../store/users/repository/selector";
import { refreshUsers } from "../../../../store/users/usecases/refreshUsers/action";
import { getAccessToAccount } from "../../../../store/users/usecases/getAccessToAccount/action";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { tokens } from "../../../../locales/tokens";
import { useTranslation } from "react-i18next";

export const useUsers = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { users, usersCount, isLoading, getAccessError } = useSelector(selectUsers);
    const [filters, setFilters] = useState<Filters>({ offset: 0, limit: 15, searchText: '', status: 'ALL', sortBy: 'NEW' });
    const searchTextRef = useRef<HTMLInputElement | null>(null);
    const [search, setSearch] = useState('');
    const [currentTab, setCurrentTab] = useState<StatusType>('ALL');
    const [selected, setSelected] = useState<number[]>([]);
    const [usersAmount, setUsersAmount] = useState(0);
    const [usersList, setUsersList] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [getAccessClick, setGetAccessClick] = useState(false);

    useEffect(() => {
        dispatch(getUsersAll(combineRequestBody()));
    }, [filters]);

    const combineRequestBody = () => {
        const body: any = { offset: filters.offset, limit: filters.limit, search: filters.searchText };
        if (filters.status !== 'ALL') {
            body.isAuthorized = true;
        }
        return body;
    };

    useEffect(() => {
        setUsersList(users);
        setUsersAmount(Math.ceil(usersCount / filters.limit));
    }, [users, usersCount]);

    useEffect(() => {
        if (!isLoading && getAccessClick) {
            if (getAccessError) {
                toast.error(getAccessError);
            } else {
                const user = JSON.parse(localStorage.getItem("user") || '').full_name;
                toast.success(`${t(tokens.user.messages.changedAccount)} ${user}`);
                navigate('/', { replace: true });
            };
            setGetAccessClick(false);
        }
    }, [getAccessError, isLoading]);

    const handlePageChange = useCallback((event: ChangeEvent<unknown>, page: number): void => {
        setCurrentPage(page);
        setFilters((prevState) => ({ ...prevState, offset: (page - 1) * filters.limit }));
    }, [setFilters]);

    const handleStatusChange = useCallback((event: SyntheticEvent<Element, Event>, value: any): void => {
        setCurrentTab(value);
        setFilters((prevState) => ({ ...prevState, status: value, offset: 0 }));
        setCurrentPage(1);
    }, [setFilters]);

    const handleSortChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        setFilters((prevState) => ({ ...prevState, sortBy: event.target.value as SortType }));
    }, [setFilters]);

    const handleSearchTextChange = useCallback((event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const query = searchTextRef.current?.value || '';
        setFilters((prevState) => ({ ...prevState, searchText: query }));
    }, []);

    useEffect(() => {
        debouncedHandleUserSeacrh(search);
    }, [search]);

    const onSearch = (value: any) => {
        if (value.length) {
            setFilters((prevState) => ({ ...prevState, searchText: value, offset: 0 }));
            setCurrentPage(1);
        } else {
            setFilters((prevState) => ({ ...prevState, searchText: '', offset: 0 }));
            setCurrentPage(1);
        }
    };

    const { debouncedWrapper: debouncedHandleUserSeacrh } = useDebounce(onSearch, 400);

    const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearch(e.target.value);
    }, []);

    const customerIds = useMemo(() => {
        return usersList.map((user) => user.id);
    }, [usersList]);

    // useEffect(() => {
    //     setSelected([]);
    // }, [customerIds]);

    const selectOne = useCallback((customerId: number): void => {
        setSelected((prevState) => [...prevState, customerId]);
    }, []);

    const deselectOne = useCallback((customerId: number): void => {
        setSelected((prevState) => {
            return prevState.filter((id) => id !== customerId);
        });
    }, []);

    const handleToggleOne = useCallback((event: ChangeEvent<HTMLInputElement>, id: number): void => {
        const { checked } = event.target;
        if (checked) {
            selectOne(id);
        } else {
            deselectOne(id);
        }
    }, [selectOne, deselectOne]);

    const selectAll = useCallback((): void => {
        setSelected([...customerIds]);
    }, [customerIds]);

    const deselectAll = useCallback(() => {
        setSelected([]);
    }, []);

    const handleToggleAll = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        const { checked } = event.target;
        if (checked) {
            selectAll();
        } else {
            deselectAll();
        }
    }, [selectAll, deselectAll]);

    const updateUsers = () => {
        dispatch(refreshUsers());
    };

    const signInToUserAccount = (id: number) => {
        dispatch(getAccessToAccount({ id }));
        setGetAccessClick(true);
    };

    return {
        usersList, usersAmount, handlePageChange, handleStatusChange, handleSortChange, searchTextRef, currentTab, handleSearchTextChange,
        handleToggleAll, selected, handleToggleOne, handleChangeSearch, updateUsers, currentPage, signInToUserAccount
    };
}
