import { Button, Theme, useMediaQuery } from "@mui/material";
import { FC } from "react";
import { getStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";

interface IProps {
    title: string;
    handleLogin: () => void;
};

export const LogInButton: FC<IProps> = ({ title, handleLogin }) => {
    const { t } = useTranslation();
    const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const styles = getStyles();

    return (
        <Button
            fullWidth
            variant="contained"
            size="large"
            sx={styles.loginBtn}
            onClick={handleLogin}
        >
            {title}
        </Button>
    )
}