import type { ChangeEvent, FC } from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Checkbox, FormControlLabel, Menu, MenuItem, Stack, SvgIcon, Theme, Typography, useMediaQuery } from '@mui/material';
import { getStyles } from './styles';
import { tokens } from '../../locales/tokens';
import { useTranslation } from 'react-i18next';

interface IProps {
  label: string;
  onChangeStatus?: (value: any[]) => void;
  statusOptions: { label: string; value: unknown; }[];
  statusValues: any[];
  onChangeMethod?: (value: any[]) => void;
  methodOptions: { label: string; value: unknown; }[];
  methodValues: any[];
  deletedOptions: { label: string; value: unknown; }[];
  deletedValues: any[];
  onChangeDelete?: (value: any[]) => void;
  importOptions: { label: string; value: unknown; }[];
  importValues: any[];
  onChangeImport?: (value: any[]) => void;
  hiddenOptions: { label: string; value: unknown; }[];
  hiddenValues: any[];
  onChangeHidden?: (value: any[]) => void;
  confirmOptions: { label: string; value: unknown; }[];
  confirmValues: any[];
  onChangeConfirm: (value: any[]) => void;
  financierOptions: { label: string; value: unknown; }[];
  financierValues: any[];
  onChangeFinancier: (value: any[]) => void;
};

export const MultiSelect: FC<IProps> = (props) => {
  const { t } = useTranslation();
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const styles = useMemo(() => getStyles(), []);
  const { label, onChangeStatus, statusOptions, statusValues = [], onChangeMethod, methodOptions, methodValues = [], deletedOptions,
    deletedValues, onChangeDelete, importOptions, importValues, onChangeImport, hiddenOptions, hiddenValues, onChangeHidden, confirmOptions,
    confirmValues, onChangeConfirm, financierOptions, financierValues, onChangeFinancier, ...other } = props;
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const handleMenuOpen = useCallback((): void => {
    setOpenMenu(true);
  }, []);

  const handleMenuClose = useCallback((): void => {
    setOpenMenu(false);
  }, []);

  const handleStatusChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    let newValue = [...statusValues];
    if (event.target.checked) {
      newValue.push(event.target.value);
    } else {
      newValue = newValue.filter((item) => item !== event.target.value);
    }
    onChangeStatus?.(newValue);
  },
    [onChangeStatus, statusValues]
  );

  const handleMethodChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    let newValue = [...methodValues];
    if (event.target.checked) {
      newValue.push(event.target.value);
    } else {
      newValue = newValue.filter((item) => item !== event.target.value);
    }
    onChangeMethod?.(newValue);
  },
    [onChangeMethod, methodValues]
  );

  const handleDeletedChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    let newValue = [...deletedValues];
    if (event.target.checked) {
      newValue.push(event.target.value);
    } else {
      newValue = newValue.filter((item) => item !== event.target.value);
    }
    onChangeDelete?.(newValue);
  },
    [onChangeDelete, deletedValues]
  );

  const handleImportChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    let newValue = [...importValues];
    if (event.target.checked) {
      newValue.push(event.target.value);
    } else {
      newValue = newValue.filter((item) => item !== event.target.value);
    }
    onChangeImport?.(newValue);
  },
    [onChangeImport, importValues]
  );

  const handleHiddenChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    let newValue = [...hiddenValues];
    if (event.target.checked) {
      newValue.push(event.target.value);
    } else {
      newValue = newValue.filter((item) => item !== event.target.value);
    }
    onChangeHidden?.(newValue);
  },
    [onChangeHidden, hiddenValues]
  );

  const handleConfirmChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    let newValue = [...confirmValues];
    if (event.target.checked) {
      newValue.push(event.target.value);
    } else {
      newValue = newValue.filter((item) => item !== event.target.value);
    }
    onChangeConfirm?.(newValue);
  },
    [onChangeConfirm, confirmValues]
  );

  const handleFinancierChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    let newValue = [...financierValues];
    if (event.target.checked) {
      newValue.push(event.target.value);
    } else {
      newValue = newValue.filter((item) => item !== event.target.value);
    }
    onChangeFinancier?.(newValue);
  },
    [onChangeFinancier, financierValues]
  );

  return (
    <>
      <Button
        color="primary"
        variant='contained'
        size='small'
        sx={styles.button}
        endIcon={(
          <SvgIcon>
            <ExpandMoreIcon />
          </SvgIcon>
        )}
        onClick={handleMenuOpen}
        ref={anchorRef}
        {...other}
      >
        {label}
      </Button>
      <Menu anchorEl={anchorRef.current} onClose={handleMenuClose} open={openMenu} PaperProps={{ style: { width: downSm ? 250 : 890 } }} sx={{ mt: 1 }}>
        <Stack direction={downSm ? "column" : "row"} spacing={1}>
          <Stack spacing={1}>
            <Typography pl={2} variant='subtitle2' fontWeight={600}>{t(tokens.payment.status)}</Typography>
            {statusOptions.map((option) => (
              <MenuItem key={option.label} sx={styles.menuItem}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={statusValues.includes(option.value)}
                      onChange={handleStatusChange}
                      value={option.value}
                      size='small'
                    />
                  )}
                  label={option.label}
                  sx={styles.formControl}
                />
              </MenuItem>
            ))}
          </Stack>
          <Stack spacing={1}>
            <Typography pl={2} variant='subtitle2' fontWeight={600}>{t(tokens.payment.paymentMethod)}</Typography>
            {methodOptions.map((option) => (
              <MenuItem key={option.label} sx={styles.menuItem} >
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={methodValues.includes(option.value)}
                      onChange={handleMethodChange}
                      value={option.value}
                      size='small'
                    />
                  )}
                  label={option.label}
                  sx={styles.formControl}
                />
              </MenuItem>
            ))}
          </Stack>
          <Stack spacing={1}>
            <Typography pl={2} variant='subtitle2' fontWeight={600}>{t(tokens.payment.parametrs)}</Typography>
            {deletedOptions.map((option) => (
              <MenuItem key={option.label} sx={styles.menuItem} >
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={deletedValues.includes(option.value)}
                      onChange={handleDeletedChange}
                      value={option.value}
                      size='small'
                    />
                  )}
                  label={option.label}
                  sx={styles.formControl}
                />
              </MenuItem>
            ))}
            {importOptions.map((option) => (
              <MenuItem key={option.label} sx={styles.menuItem} >
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={importValues.includes(option.value)}
                      onChange={handleImportChange}
                      value={option.value}
                      size='small'
                    />
                  )}
                  label={option.label}
                  sx={styles.formControl}
                />
              </MenuItem>
            ))}
            {hiddenOptions.map((option) => (
              <MenuItem key={option.label} sx={styles.menuItem} >
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={hiddenValues.includes(option.value)}
                      onChange={handleHiddenChange}
                      value={option.value}
                      size='small'
                    />
                  )}
                  label={option.label}
                  sx={styles.formControl}
                />
              </MenuItem>
            ))}
            {confirmOptions.map((option: any) => (
              <MenuItem key={option.label} sx={styles.menuItem} >
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={confirmValues.includes(option.value)}
                      onChange={handleConfirmChange}
                      value={option.value}
                      size='small'
                    />
                  )}
                  label={option.label}
                  sx={styles.formControl}
                />
              </MenuItem>
            ))}
          </Stack>
          <Stack spacing={1}>
            <Stack pb={3}></Stack>
            {financierOptions.map((option) => (
              <MenuItem key={option.label} sx={styles.menuItem} >
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={financierValues.includes(option.value)}
                      onChange={handleFinancierChange}
                      value={option.value}
                      size='small'
                    />
                  )}
                  label="Бажаний финансист"
                  sx={styles.formControl}
                />
              </MenuItem>
            ))}
          </Stack>
        </Stack>
      </Menu >
    </>
  );
};
