import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setGetPaymentsError, setLoading, setUploadPayments } from "../../repository/slice";

function* getUploadPaymentsWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, isError, message }: IResponse<any> = yield call(requester.get, "/zoolux-api/admin/import-payment/" + payload.id);
        if (isError) {
            yield put(setGetPaymentsError(message));
        } else {
            yield put(setGetPaymentsError(null));
            yield put(setUploadPayments({list: data.rows, count: data.count})); 
        }
    } catch (error) {
        console.error("getUploadPaymentsWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* getUploadPaymentsWatcher() {
    yield takeLatest("@saga/GET_UPLOAD_PAYMENTS", getUploadPaymentsWorker);
};
