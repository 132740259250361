import { useMemo, type FC } from 'react';
import { TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../../locales/tokens';

export const TableListHead: FC = () => {
    const { t } = useTranslation();
     
    return (
        <TableHead> 
            <TableRow sx={{ alignItems: 'flex-start' }}>
                <TableCell width='20%' align="center">
                    <Typography variant='h6'>{t(tokens.partner.name)}</Typography>
                </TableCell>
                <TableCell width='20%' align="center">
                    <Typography variant='h6'>{t(tokens.partner.shortName)}</Typography>
                </TableCell>
                <TableCell width='15%' align="center">
                    <Typography variant='h6'>{t(tokens.partner.bankName)}</Typography>
                </TableCell>
                <TableCell width='10%' align="center" >
                    <Typography variant='h6'>{t(tokens.partner.edrpou)}</Typography>
                </TableCell>
                <TableCell width='20%' align="center" >
                    <Typography variant='h6'>{t(tokens.partner.urName)}</Typography>
                </TableCell>
                <TableCell width='10%' align="center">
                    <Typography variant='h6'>{t(tokens.partner.status)}</Typography>
                </TableCell>
                <TableCell width='10%' align="center">
                    <Typography variant='h6'>{t(tokens.partner.actions)}</Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    );
};
