import { TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { FC, useMemo } from "react";
import { Link } from "react-router-dom";
import { paths } from "../../../../../../paths";
import { getStyles } from "./styles";

interface IProps {
    payment: any;
    isItemSelected: boolean;
    handleClick: (event: React.MouseEvent<unknown>, id: number) => void;
};

export const PaymentsTableItem: FC<IProps> = ({ handleClick, isItemSelected, payment }) => {
    const styles = useMemo(() => getStyles(), []);
    const paymentDate = dayjs(payment.payment_date).locale('uk').format('MMMM D, YYYY');

    return (
        <TableRow hover onClick={(event) => handleClick(event, payment.id)} key={payment.id} sx={styles.tableRow} component={Link} to={`${paths.dashboard.payment.edit.replace(':id', payment.id)}`}>
            <TableCell align="center">{paymentDate}</TableCell>
            <TableCell align="center">{payment.counterparty?.name}</TableCell>
            <TableCell align="center">{payment.amount}</TableCell>
            <TableCell align="center">{payment.payment_method?.name}</TableCell>
            <TableCell align="center">{payment.company_account?.name}</TableCell>
            <TableCell align="center">{payment.initiator?.full_name}</TableCell>
            <TableCell align="center">{payment.operacionist?.full_name || '-'}</TableCell>
            <TableCell align="center">{payment.comment || '-'}</TableCell>
        </TableRow>
    )
};