import { useCallback, useEffect, useState } from "react";
import { Role } from "../../../../types/roles";
import { useGetPermissions } from "../../../../hooks/useGetPermission";

export const useUserPermission = (roles?: Role[] | null) => {
    const isInitiator = roles? roles.length === 0 : false;
    const { isSet_Visa_Payment, isDelete_Visa_Payment, isSet_Visa_Local_Payment, isDelete_Visa_Local_Payment, isProcess_Card_Payment,
        isProcess_Cash_Payment, isProcess_Local_Payment, isProcess_Non_Cash_Payment, isProcess_Payment, isTransfer_To_Payment_Budget_Calendar,
        isDelete_Local_Payment, isDelete_Payment, isCompleted_Budget_Calendar, isCreate_In_Enote, isConfirm_Payment, isConfirm_Budget_Calendar } = useGetPermissions();

    const hasPermission = useCallback((permissions: boolean[]) => {
        return permissions?.some((item: boolean) => item === true);
    }, [roles]);

    const isVisaAllowed = hasPermission([isSet_Visa_Payment, isDelete_Visa_Payment, isSet_Visa_Local_Payment, isDelete_Visa_Local_Payment]);
    const isProcessAllowed = hasPermission([isProcess_Card_Payment, isProcess_Cash_Payment, isProcess_Local_Payment, isProcess_Non_Cash_Payment, isProcess_Payment]);
    const isTransferAllowed = hasPermission([isTransfer_To_Payment_Budget_Calendar]);
    const isDeleteAllowed = hasPermission([isDelete_Local_Payment, isDelete_Payment]);
    const isActivedConfirmPayment = isConfirm_Payment || isConfirm_Budget_Calendar;

    return { isInitiator, isDeleteAllowed, isVisaAllowed, isProcessAllowed, isTransferAllowed, isCompleted_Budget_Calendar, isCreate_In_Enote, isActivedConfirmPayment };
};