import { Stack, TextField, Theme, Typography, useMediaQuery } from "@mui/material"
import { ChangeEvent, FC } from "react"

interface IProps{
    title: string;
    value: string;
    isValid: boolean;
    partnerId: number | undefined;
    onChangeValue: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const FieldText: FC<IProps> = ({ isValid, onChangeValue, value, title, partnerId}) => {
    const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return (
        <Stack direction='column' spacing={1} sx={{ width: '100%' }} >
            <Typography color={isValid ? 'primary' : 'error'} variant={downMd? 'body1' : 'h6'}>{title}</Typography>
            <TextField value={value} onChange={onChangeValue} label={title} error={!isValid} disabled={!partnerId}/>
        </Stack>
    )
};