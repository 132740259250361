import { Box, Card, CardContent, Stack, Theme, keyframes, useMediaQuery } from "@mui/material";
import { FC } from "react";
import { getStyles } from "./styles";
import { useAuthorization } from "../presenters/useAuth";
import { PhoneField } from "./components/phoneField";
import { LogInButton } from "./components/logInButton";
import { PasswordField } from "./components/passwordField";
import { RecoverPassButton } from "./components/recoverPassButton";
import { SmsField } from "./components/smsFiled";
import { ConfirmButton } from "./components/confirmButton";
import { tokens } from "../../../../locales/tokens";
import { useTranslation } from "react-i18next";
import { Loader } from "../../../../ui-kit/loader/loader";

const changeSize = keyframes({ '0%': { transform: 'scale(1)', }, '50%': { transform: 'scale(1.1)', }, '100%': { transform: 'scale(1)', } })

export const Login: FC = () => {
    const { t } = useTranslation();
    const upMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const upSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
    const styles = getStyles(upMd, upSm);
    const { phone, onChangePhone, password, onChangePassword, handleLogin, isPassShown, onShowPassword, handlePhone,
        phoneValid, passwordValid, isLoading, isPhoneInBD, smsCode, onChangeSmsCode, timerInSec, isCorrectSms,
        handleCheckPhone, handleCheckSmsCode, onChangeConfirmPassword, onShowConfirmPassword, confirmPass,
        confirmPassValid, isConfirmPassShown, onChangeTimer, resendSmsCode, handleEnterPassword } = useAuthorization();

    return (
        <>
            {!isLoading ?
                <Box sx={styles.container}>
                    <Box sx={{ ...styles.logoContainer, animation: `${changeSize} 5s infinite` }}>
                        <img src="/images/logo/logo-zoolux.png" style={styles.logo} alt="logo" />
                    </Box>
                    <Card elevation={16}>
                        <CardContent sx={styles.content}>
                            <Stack spacing={upMd ? 3 : 2}>
                                <PhoneField
                                    phone={phone}
                                    handlePhone={handlePhone}
                                    onChangePhone={onChangePhone}
                                    phoneValid={phoneValid}
                                    isPhoneInBD={isPhoneInBD}
                                />
                                {(isPhoneInBD && !isCorrectSms) &&
                                    <SmsField
                                        smsCode={smsCode}
                                        onChangeSmsCode={onChangeSmsCode}
                                        timerInSec={timerInSec}
                                        onChangeTimer={onChangeTimer}
                                        resendSmsCode={resendSmsCode}
                                    />
                                }
                                {isCorrectSms &&
                                    <PasswordField
                                        title={t(tokens.auth.password)}
                                        password={password}
                                        onChangePassword={onChangePassword}
                                        passwordValid={passwordValid}
                                        isPassShown={isPassShown}
                                        onShowPassword={onShowPassword}
                                        handleEnterPassword={handleEnterPassword}
                                        isPhoneInBD={isPhoneInBD}
                                        supportText={t(tokens.auth.messages.createSafePass)}
                                        validText={t(tokens.auth.validation.passValid)}
                                    />}
                                {(isCorrectSms && isPhoneInBD) &&
                                    <PasswordField
                                        title={t(tokens.auth.confirmPass)}
                                        password={confirmPass}
                                        onChangePassword={onChangeConfirmPassword}
                                        passwordValid={confirmPassValid}
                                        isPassShown={isConfirmPassShown}
                                        onShowPassword={onShowConfirmPassword}
                                        handleEnterPassword={handleEnterPassword}
                                        isPhoneInBD={isPhoneInBD}
                                        supportText={t(tokens.auth.messages.confirmYourPass)}
                                        validText={t(tokens.auth.validation.matchPass)}
                                    />}
                            </Stack >
                            {(!isCorrectSms && !isPhoneInBD) &&
                                <ConfirmButton title={t(tokens.auth.checkPhone)} handleButton={handleCheckPhone} />}
                            {(!isCorrectSms && isPhoneInBD) &&
                                <ConfirmButton title={t(tokens.auth.confirmCode)} handleButton={handleCheckSmsCode} />}
                            {isCorrectSms &&
                                <LogInButton handleLogin={handleLogin} title={t(tokens.auth.enter)} />}
                            {(isCorrectSms && !isPhoneInBD) &&
                                <RecoverPassButton />}
                        </CardContent>
                    </Card>
                </Box>
                :
                <Loader />} 
        </>
    )
};