import type { FC } from 'react';
import { useCallback, useState } from 'react';
import type { Theme } from '@mui/material';
import { Drawer, useMediaQuery } from '@mui/material';
import { PartnerDetails } from '../../modules/partner/partner-list/ui/components/partnerDetails';
import { getStyles } from './styles';

interface IProps {
  container?: HTMLDivElement | null;
  value?: any;
  open?: boolean;
  onClose?: () => void;
  drawerContent: JSX.Element;
};

export const SideDrawer: FC<IProps> = ({ container, value, open, onClose, drawerContent }) => {
  const styles = getStyles();
  let content: JSX.Element | null = null;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const handleEditOpen = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleEditCancel = useCallback(() => {
    setIsEditing(false);
  }, []);


  if (value) {
    content = drawerContent;
  };

  if (lgUp) {
    return (
      <Drawer
        anchor="right"
        open={open}
        PaperProps={{ sx: styles.paperLg }}
        SlideProps={{ container }} 
        variant="persistent"
      >
        {content}
      </Drawer>
    );
  };

  return (
    <Drawer
      anchor="right"
      hideBackdrop
      ModalProps={{ container, sx: styles.modal }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: styles.paper }}
      SlideProps={{ container }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};
