import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";
import { setBankCards } from "../../repository/actions";

function* getBankCardsWorker(): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call( requester.get, "link.............");

        if (isError) {
            toast.error(message);
        } else {
            yield put(setBankCards(data));
        }
    } catch (error) {
        console.error("getBankCardsWorker: ", error);
    } finally {
        
    }
};

export function* getBankCardsWatcher() {
    yield takeLatest("@saga/GET_BANK_CARDS", getBankCardsWorker);
};
