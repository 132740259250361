export const getStyles = () => {
    const styles = {
        button: { 
            textTransform: 'none', 
            color: '#ffffff' 
        },
        action: { 
            justifyContent: 'flex-start', 
            ml: 2, 
            mb: 1 
        }
    };
    return styles;
};