import { Autocomplete, Stack, TextField, Theme, Typography, useMediaQuery } from "@mui/material";
import { FC } from "react"
import { IUserOption, collectUserList } from "../../../utils/collectOptionList";
import { useFinancierAutocomplete } from "../presenter/useFinancierAutocomplete";

interface IProps {
    title: string;
    value: IUserOption | null;
    handleChangeValue: any;
    isValid: boolean;
    isFinancier: boolean;
    isDisabled: boolean;
};

export const FinancierAutocomplete: FC<IProps> = ({ value, handleChangeValue, title, isValid, isFinancier, isDisabled }) => {
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const { financiers, changeUserText } = useFinancierAutocomplete(isFinancier); 

    return (
        <Stack direction='column' alignItems='flex-start' spacing={1} flex={1}>
            <Typography color={isValid ? 'primary' : 'error'} variant={downSm ? 'body1' : 'h6'}>{title}</Typography>
            <Autocomplete
                disablePortal
                onChange={(e, value) => handleChangeValue(value)}
                value={value}
                options={collectUserList(financiers) as IUserOption[]}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(params, option): JSX.Element => (
                    <Typography {...params} key={option.id}>
                        {option.label}
                    </Typography>
                )}
                renderInput={(params): JSX.Element => (
                    <TextField
                        {...params}
                        fullWidth
                        error={!isValid}
                        name="category"
                        variant="outlined"
                        label={title}
                        onChange={changeUserText}
                        size="small"
                    />
                )}
                clearIcon={false}
                sx={{ width: '100%' }}
                disabled={isDisabled}
            />
        </Stack>
    )
};