import { SelectChangeEvent } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { selectUsers } from "../../../../store/users/repository/selector";
import { useDispatch, useSelector } from "react-redux";
import { getRoles } from "../../../../store/users/usecases/getRoles/action";
import { useParams } from "react-router-dom";
import { setUserRole } from "../../../../store/users/usecases/setRole/action";
import { deleteUserRole } from "../../../../store/users/usecases/deleteUserRole/action";
import { getSingleUser } from "../../../../store/users/usecases/getSingleUser/action";

export const useUserEdit = () => {
    const dispatch = useDispatch();
    const { singleUser, roles } = useSelector(selectUsers);
    
    const [role, setRole] = useState<string[]>([]);
    const [status, setStatus] = useState("Active");

    const { id } = useParams();

    useEffect(() => {
        dispatch(getRoles());
        dispatch(getSingleUser({ id }));
    }, []);
    
    useEffect(() => {
        setRole(singleUser?.roles || []);
    }, [singleUser]);

    const handleChangeRole = (event: SelectChangeEvent<string[]>, child: ReactNode) => {
        const newRoles = event.target.value || [];
        const originalRoles = singleUser.roles || [];

        const addedRoles = (newRoles as string[]).filter(role => !originalRoles.includes(role));
        const removedRoles = (originalRoles as string[]).filter(role => !newRoles.includes(role));
        if (addedRoles.length > 0) {
            dispatch(setUserRole({ user_id: Number(id), roles_name: addedRoles }));
        }
        if (removedRoles.length > 0) {
            dispatch(deleteUserRole({ user_id: Number(id), role_name: removedRoles[0] }));
        }
    };

    const handleChangeStatus = (event: SelectChangeEvent) => {
        setStatus(event.target.value);
    };

    return { role, roles, handleChangeRole, status, handleChangeStatus, singleUser };
}