import { useMemo, type FC } from 'react';
import type { Theme } from '@mui/material';
import { Button, IconButton, Stack, SvgIcon, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../../locales/tokens';
import ClearIcon from '@mui/icons-material/Clear';
import { getStyles } from './styles';
import { useExpenseItemEdit } from '../../../presenters/useExpenseItemEdit';
import { TextInput } from '../../../../../../ui-kit/textInput/textInput';
import { FieldSelect } from '../../../../../account/accountEdit/ui/components/fieldSelect';
import { useGetPermissions } from '../../../../../../hooks/useGetPermission';

interface IProps {
  item: any;
  filters: any;
  onApprove?: () => void;
  onEdit?: () => void;
  onReject?: () => void;
};

export const ExpenseItemDetails: FC<IProps> = ({ onApprove, onEdit, onReject, item, filters }) => {
  const { t } = useTranslation();
  const styles = useMemo(() => getStyles(), []);
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const { name, onChangeName, nameValid, accountStatusList, handleChangeStatus, handleEdit, status } = useExpenseItemEdit(item, filters);
  const { isUpdate_Expenditure } = useGetPermissions();  
  
  return (
    <>
      <Stack alignItems="center" direction="row" justifyContent="space-between" sx={styles.code} >
        <Typography color="inherit" variant="body1" ></Typography>
        <IconButton color="inherit" onClick={onApprove}>
          <SvgIcon><ClearIcon /></SvgIcon>
        </IconButton>
      </Stack>
      <Stack m={2}>
            <Typography variant="h5" fontWeight={600}>{t(tokens.account.editAccount)}</Typography>
            <Stack width={lgUp? '450px' : '350px'} mt={5} spacing={3}>
                <TextInput
                    disabled={false}
                    title={t(tokens.account.shortName)}
                    label={t(tokens.account.shortName)}
                    value={name}
                    isValid={nameValid}
                    isTextArea={false}
                    errorText={t(tokens.account.titleRequired)}
                    onChangeValue={onChangeName}
                />
                <FieldSelect
                    title={t(tokens.account.status)}
                    value={status}
                    handleValue={handleChangeStatus}
                    options={accountStatusList}
                />
                <Stack pt={2}>
                    <Button
                        variant="contained"
                        size="large"
                        sx={{ color: '#FFFFFF' }}
                        onClick={handleEdit}
                        disabled={!isUpdate_Expenditure}
                    >
                        {t(tokens.common.edit)}
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    </>
  );
};
