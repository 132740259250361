import { IconButton, Stack, SvgIcon, TextField, Tooltip, Typography } from "@mui/material";
import { FC, useCallback } from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface IProps {
    title: string;
    value: string;
    isMultiline?: boolean;
    rows?: number;
    handleCopy: (value: string) => void;
}; 

export const FormField: FC<IProps> = ({ title, value, isMultiline, rows, handleCopy }) => {
    const copyValue = useCallback(() => handleCopy(value), [value]);

    return (
        <Stack display='flex' direction='row' spacing={2}>
            <Stack flex={1} direction='row' justifyContent='flex-end' alignItems='center'>
                <Typography>{title}</Typography>
            </Stack>
            <Stack flex={3} >
                <TextField
                    value={value}
                    size="small"
                    multiline={isMultiline}
                    rows={rows || 1}
                    onClick={copyValue}
                    InputProps={{ readOnly: true }}
                    sx={{ input: { cursor: 'pointer' }, textArea: {cursor: 'pointer'} }}
                />
            </Stack>
        </Stack>
    )
};