import { TableBody } from "@mui/material";
import { FC } from "react";
import { PaymentsTableItem } from "../paymentsTableItem";

interface IProps {
    uploadPayments: any[];
};

export const PaymentsTableBody: FC<IProps> = ({ uploadPayments }) => {

    return (
        <TableBody>
            {uploadPayments.map((importFile: any) => (
                <PaymentsTableItem
                    key={importFile.id}
                    importFile={importFile}
                />
            ))}
        </TableBody>
    )
};