import { SagaIterator } from "redux-saga";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setCurrentUser, setLoading } from "../../repository/actions";
import toast from "react-hot-toast";

function* getCurrentUserWorker(): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, isError }: IResponse<any> = yield call(requester.get, "/zoolux-api/users/profile");
        if (isError) {
            toast.error("користувач не знайдений");
        } else {
            yield delay(1000);
            yield put(setCurrentUser(data.user));
        }
    } catch (error) {
        console.error("getCurrentUserWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* getCurrentUserWatcher() {
    yield takeLatest("@saga/GET_CURRENT_USER", getCurrentUserWorker);
};
