import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { FC, ReactNode, useContext } from "react";
import { SettingsContext } from "../providers/settings";
import { themeLightMain } from './palette';

interface IProps {
    children: ReactNode;
}

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        outlineGreen: true;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        containedGreen: true;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        inputMain: true;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        outlineWhite: true;
    }
}

declare module '@mui/material/TextField' {
    interface TextFieldPropsVariantOverrides {
        main: true;
    }
}

const ThemeManager: FC<IProps> = ({ children }) => {
    const { paletteMode } = useContext(SettingsContext);

    const lightTheme = createTheme(themeLightMain, {

        components: {
            MuiTypography: {
                defaultProps: {
                    fontFamily: "SF-Pro-Text"
                },
            },
            MuiCssBaseline: {
                styleOverrides: (themeParam: any) => ({
                    body: {
                        fontFamily: ['SF-Pro-Text'].join(','),
                        color: themeLightMain.palette.main.main
                    },
                }),
            },
            MuiTooltip: {
                defaultProps: {
                    arrow: true,
                    PopperProps: {
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -6],
                                },
                            },
                        ],
                    },
                },
                styleOverrides: {
                    tooltip: {},
                },
            },
            MuiPagination: {
                styleOverrides: {
                    root: {
                        "&.pagination": {
                            "& button.MuiPaginationItem-root.Mui-selected": {
                                backgroundColor: themeLightMain.palette.second.main,
                                color: "white"
                            }
                        }
                    }
                }
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        "&.card": {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: themeLightMain.palette.backgroundCard.main,
                            textDecoration: 'none',
                            boxShadow: themeLightMain.palette.boxShadow.main,
                            borderRadius: 4,
                            color: '#1C1C1E',
                            "&.action:hover": {
                                boxShadow: 'none',
                            },
                            "&.action:active": {
                                boxShadow: themeLightMain.palette.boxShadow.main,
                                border: "1px solid",
                                borderColor: themeLightMain.palette.victorianGreenhouse.main,
                            }
                        }
                    }
                }
            },
            MuiTable: {
                styleOverrides: {
                    root: {
                        "&.table": {
                            minWidth: 650,
                            borderSpacing: "0 1em",
                            "& .MuiTableCell-head, & .MuiTableCell-body": {
                                fontSize: 16,
                                lineHeight: "24px",
                                fontFamily: "SF-Pro-Text",
                                height: "44px",
                                paddingTop: 0,
                                paddingBottom: 0,
                                paddingLeft: "8px",
                                borderBottom: "none"
                            },
                            "& .MuiTableCell-head": {
                                color: themeLightMain.palette.second.main,
                                textWrap: "nowrap"
                            },
                            "& .MuiTableHead-root": {
                                top: "0px",
                                position: "sticky",
                                boxShadow: "0 2px 8px 0 rgba(139, 139, 178, 0.16)"

                            },
                            "& .MuiTableRow-root": {
                                boxShadow: "0 3px 4px 0 rgba(139, 139, 178, 0.32)"
                            },
                            // "& .MuiTableRow-root:hover": {
                            //     boxShadow: "none",
                            //     width: "100%"
                            // },
                            "& .MuiTableRow-root:active": {
                                // boxShadow: "0 3px 4px 0 rgba(139, 139, 178, 0.32)",
                            },
                            "& .MuiTableBody-root .MuiTableCell-root:first-of-type": {
                                fontWeight: 600,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                            },
                            "& .MuiTableBody-root .MuiTableCell-root:nth-of-type(2)": {
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                            },
                            "& .MuiTableBody-root .MuiTableCell-root": {
                                color: themeLightMain.palette.main.main,
                                fontSize: 16,
                                lineHeight: "24px"
                            },
                            "& .MuiTableBody-root": {
                                width: "100%"
                            }
                        }
                    }
                },
            },
            MuiSelect: {
                styleOverrides: {
                    root: {
                        "&.select:hover fieldset.MuiOutlinedInput-notchedOutline": {
                            boxShadow: "none",
                            border: `2px solid ${themeLightMain.palette.diamondCut.main}`,
                        },
                        "&.select": {
                            "&.MuiInputBase-root": {
                                borderRadius: 8,
                                padding: 0,
                                height: 48,
                                boxShadow: "0 3px 4px 0 rgba(139, 139, 178, 0.36)",
                                border: `1px solid ${themeLightMain.palette.diamondCut.main}`,
                                "&:hover": {
                                    boxShadow: "none",
                                }
                            },
                            "& .MuiSelect-select": {
                                padding: 0,
                                paddingLeft: 8
                            },
                            "& fieldset": {

                            },
                            "& fieldset.Mui-focused.MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(0, 128, 92, 0.5)"
                            },
                            "& fieldset.MuiOutlinedInput-notchedOutline": {
                                border: "none"
                            },
                            "& .MuiSelect-nativeInput": {
                                fontFamily: "SF-Pro-Text",
                            }
                        }
                    }
                }
            },
            MuiTabs: {
                styleOverrides: {
                    root: {
                        "&.tabsMain": {
                            "& .MuiTab-root.Mui-selected": {
                                backgroundColor: "white",
                                color: themeLightMain.palette.main.main,
                                boxShadow: "2px 2px 4px 0 rgba(0, 0, 0, 0.15)",
                                borderRadius: 7,
                                height: "44px",
                                minHeight: "unset"
                                // "&::before": {
                                //     content: '""',
                                //     width: "0",
                                // }
                            },
                            "& .MuiTabs-flexContainer": {
                                backgroundColor: themeLightMain.palette.diamondCut.main,
                                borderRadius: 8,
                                padding: 2,
                                alignItems: "center",
                                maxWidth: "100%",
                                height: "48px"
                            },
                            "& .MuiTab-root": {
                                width: "100%",
                                textTransform: "none",
                                fontSize: "16px",
                                lineHeight: "24px",
                                fontWeight: 500,
                                fontFamily: "SF-Pro-Text",
                            },

                            // "& .MuiTab-root": {
                            //     "&::before": {
                            //         content: '""',
                            //         position: "absolute",
                            //         right: 0,
                            //         height: "14px", width: "1px", background: "#D1D1D6"
                            //     }
                            // },
                            // "& .MuiTab-root:last-child": {
                            //     "&::before": {
                            //         content: '""',
                            //         width: "0",
                            //     }
                            // },
                            "& .MuiTabs-indicator": {
                                display: "none"
                            },
                        },
                    }
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        "&.tabsMain": {
                            "&:hover": {
                                // backgroundColor: "green",
                                // color: "yellow"
                            },
                            selected: {

                                color: "yellow",
                                "&:hover": {
                                    // backgroundColor: "green",
                                    // color: "yellow"
                                }
                            }
                        }
                    }
                },


            },
            MuiAutocomplete: {
                // defaultProps: {
                //     inputProps: {
                //         style: {
                //             height: "48px",
                //         },
                //     },
                // }
                styleOverrides: {
                    root: {
                        "&.autocomplete": {
                            "& .MuiInputBase-root": {
                                height: 48,
                                padding: 0,
                                // paddingLeft: 16,
                                paddingRight: 20
                            },
                            "& .MuiAutocomplete-input": {
                                padding: "0 8px",
                            }
                        }
                    }
                }
            },

            MuiTextField: {

                styleOverrides: {
                    root: {
                        "&.inputBase": {
                            width: "100%",
                            // background: "white",
                            borderRadius: "10px",
                            "& fieldset": {
                                border: `1px solid ${themeLightMain.palette.diamondCut.main}`,
                                borderRadius: "10px",
                                boxShadow: "0 3px 4px 0 rgba(139, 139, 178, 0.36)",
                            },
                            "&.inputPrimary .MuiInputBase-root": {
                                background: themeLightMain.palette.backgroundCard.main,
                                borderRadius: "10px",
                            },
                            "& .Mui-error .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(231, 55, 0, .5)"
                            },
                            "& .MuiInputBase-input": {
                                fontSize: 16,
                                lineHeight: "24px",
                                fontFamily: "SF-Pro-Text",
                                height: 48,
                                paddingTop: 0,
                                paddingBottom: 0,
                                paddingLeft: "8px",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                color: themeLightMain.palette.heatherField.main,
                                fontSize: 16,
                                lineHeight: "24px"
                            },
                            "& .MuiInputBase-root.MuiOutlinedInput-root:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
                                border: `1px solid ${themeLightMain.palette.diamondCut.main}`,
                                boxShadow: "none"
                            },
                            "& .MuiOutlinedInput-root": {
                                // paddingLeft: "16px"
                            },
                            "& .MuiInputBase-input:focus > fieldset": {
                                border: `1px solid rgba(0, 128, 92, 0.5)`,
                                boxShadow: "none"
                            },
                            "& .Mui-focused > fieldset": {
                                border: `1px solid rgba(0, 128, 92, 0.5)`,
                                boxShadow: "none"
                            },
                            // multiline
                            "& .Mui-focused.MuiInputBase-multiline fieldset": {
                                border: `1px solid rgba(0, 128, 92, 0.5)`,
                            },
                            "&.textareaBase .MuiInputBase-multiline": {
                                background: themeLightMain.palette.backgroundCard.main,
                                borderRadius: "10px",
                                paddingLeft: "0"
                            }
                        },
                    }
                }
            },
            MuiButton: {
                defaultProps: {
                    fontFamily: "SF-Pro-Text"
                },
                styleOverrides: {
                    root: {
                        fontFamily: "SF-Pro-Text"
                    },
                },
                variants: [
                    {
                        props: { variant: 'outlineGreen' },
                        style: {
                            textTransform: 'none',
                            border: `1px solid`,
                            borderColor: themeLightMain.palette.victorianGreenhouse.main,
                            height: 48,
                            borderRadius: 16,
                            color: themeLightMain.palette.main.main,
                            fontSize: 16,
                            fontWeight: 600,
                            lineHeight: "24px",
                            padding: "6px 64px",
                            ":disabled": {
                                backgroundColor: "lightgray",
                                borderColor: "gray"
                            }
                        },
                    },
                    {
                        props: { variant: "containedGreen" },
                        style: {
                            textTransform: 'none',
                            border: `none`,
                            backgroundColor: themeLightMain.palette.victorianGreenhouse.main,
                            height: 48,
                            borderRadius: 16,
                            color: "white",
                            fontSize: 16,
                            fontWeight: 600,
                            lineHeight: "24px",
                            padding: "6px 64px",
                            boxShadow: "0px 3px 4px 0px rgba(139, 139, 178, 0.36)",
                            ":hover": {
                                background: "#32E1C0",
                                boxShadow: "none"
                            },
                            ":active": {
                                background: "#0A8A66"
                            },
                            ":disabled": {
                                backgroundColor: "lightgray",
                                borderColor: "gray"
                            }
                        },
                    },
                    {
                        props: { variant: "outlineWhite" },
                        style: {
                            textTransform: 'none',

                            width: "100%",
                            backgroundColor: "white",
                            boxShadow: "0px 3px 4px 0px rgba(139, 139, 178, 0.36)",
                            border: "1px solid #BAC2BF",
                            borderRadius: 8,
                            fontSize: "16px",
                            fontWeight: 400,
                            ":hover": {
                                boxShadow: "none",
                                backgroundColor: "white",
                            },
                            ":active": {
                                borderColor: themeLightMain.palette.second.main,
                            },
                            ":disabled": {
                                backgroundColor: "lightgray",
                                borderColor: "gray"
                            }
                        },
                    }
                ],
            },
        },

    });

    const darkTheme = createTheme({
        palette: {
            mode: "dark",
            primary: {
                main: "#2196f3",
            },
            secondary: {
                main: "#2d3035",
            },
        },
    });

    const theme = paletteMode === "light" ? lightTheme : darkTheme;

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
};

export default ThemeManager;




