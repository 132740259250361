import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";
import { setNomenclatureList } from "../../repository/slice";

function* getNomenclatureListWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.get, "/zoolux-api/admin/nomenclatures", payload);
        if (isError) {
            toast.error(message);
        } else {
            yield put(setNomenclatureList({ list: data.rows, count: data.count }));
        }
    } catch (error) {
        console.error("getNomenclatureListWorker: ", error);
    }
};

export function* getNomenclatureListWatcher() {
    yield takeLatest("@saga/GET_NOMENCLATURE_LIST", getNomenclatureListWorker);
};
