import { Box, TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { FC, useMemo } from "react";
import { getStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import { Link } from "react-router-dom";

interface IProps {
    importFile: any;
};

export const PaymentsTableItem: FC<IProps> = ({ importFile }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const paymentDate = (importFile.payment?.payment_date || importFile.date) ?
        dayjs(importFile.payment?.payment_date || importFile.date).locale('uk').format('MMMM D, YYYY, HH:mm') : t(tokens.importPayment.noData);

    const getStatusColor = useMemo(() => {
        return (status: string) => {
            return status === "SUCCESS"
                ? '#51c979'
                : status === "MIXED"
                    ? 'orange'
                    : 'red';
        };
    }, []);

    const paymentStatusOptions: any = {
        CREATED: t(tokens.payment.paymentCreated),
        VISAED: t(tokens.payment.paymentVisaed),
        OPERATED: t(tokens.payment.operated)
    };

    const color = getStatusColor(importFile.status);

    const sum = importFile.payment?.amount || importFile?.amount_non_cash || importFile?.amount_cash;

    return (
        <TableRow
            key={importFile.payment?.id || importFile.payment_id}
            sx={styles.tableRow}
            component={Link}
            to={`/payment-edit/${importFile.payment_id}`}
        >
            <TableCell align="center">
                <Box bgcolor={color} sx={styles.status}>
                    {importFile?.status || "ERROR"}
                </Box>
            </TableCell>
            <TableCell align="center">
                {importFile.payment?.name || importFile.comment || t(tokens.importPayment.noData)}
            </TableCell>
            <TableCell align="center">
                {paymentDate}
            </TableCell>
            <TableCell align="center">
                {paymentStatusOptions[importFile?.payment?.status || 'OPERATED']}
            </TableCell>
            <TableCell align="right">
                {sum ? sum + " грн." : t(tokens.importPayment.noData)}
            </TableCell>
        </TableRow>
    )
};