import { FC } from "react";
import { MenuSection, NavColor } from "../../../../../types/settings";
import { Drawer, Stack, Theme, useMediaQuery } from "@mui/material";
import { Link, useLocation } from 'react-router-dom';
import { SideNavSection } from "../side-nav-section/side-nav-section";
import { getStyles } from "./styles";
import { SideDrawer } from "../side-drawer/side-drawer";

interface IProps {
    color?: NavColor;
    sections?: MenuSection[];
    isOpen: boolean;
    onNavOpen: () => void;
}

export const SideNav: FC<IProps> = ({ color, sections = [], isOpen, onNavOpen }) => {
    const location = useLocation();
    const styles = getStyles();

    return (
        <SideDrawer isOpen={isOpen} onNavOpen={onNavOpen}>
            <Link to="/">
                <Stack direction="column" spacing={2} sx={styles.logoContainer} >
                    <img src="/images/logo/logo.png" alt="logo" width={80} />
                    <img src="/images/logo/logo-title.png" alt="logo" width={100} />
                </Stack>
            </Link>
            <Stack component="nav" spacing={2} sx={styles.navContainer} >
                {sections.map((section, index) => (
                    <SideNavSection
                        items={section.items}
                        key={index}
                        pathname={location.pathname}
                        subheader={section.subheader as string}
                    />
                ))}
            </Stack>
        </SideDrawer >
    )
};