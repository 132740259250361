import { ChangeEvent, FC, Fragment, useMemo } from "react";
import { Box, LinearProgress, Pagination, Stack, Table } from "@mui/material";
import { TableListHead } from "./components/TableListHead";
import { AccountListHead } from "./components/AccountListHead";
import { AccountListBody } from "./components/AccountListBody";
import { usePaymentsList } from "../presenters/usePaymentsList";
import { getStyles } from "./styles";
import { EmptyList } from "../../../../ui-kit/emptyList";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";

interface IProps {
    paymentsDayList: any[];
    page: number;
    pageAmount: number;
    handlePageChange: (event: ChangeEvent<unknown>, page: number) => void;
};

export const PaymentForDay: FC<IProps> = ({ paymentsDayList, page, pageAmount, handlePageChange }) => {
    const { t } = useTranslation();
    const { isLoading } = usePaymentsList();
    const styles = useMemo(() => getStyles(), []);

    return (
        <Fragment>
            {paymentsDayList?.length ?
                <Box sx={styles.box}>
                    <Table>
                        <TableListHead />
                        {paymentsDayList?.map((account: any, index: number) =>
                            <Fragment key={index + account.sum_amount_payment}>
                                <AccountListHead account={account} />
                                <AccountListBody account={account} />
                            </Fragment>
                        )}
                    </Table>
                    <Stack direction='row' justifyContent='center' mt={4}>
                        <Pagination
                            page={page}
                            count={pageAmount || 1}
                            color="primary"
                            onChange={handlePageChange}
                        />
                    </Stack>
                </Box>
                :
                <EmptyList
                    title={t(tokens.payment.emptyList)}
                    description={t(tokens.payment.emptyListDesc)}
                />
            }
        </Fragment>
    )
};