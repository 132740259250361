import { Theme } from "@mui/material";

export const getStyles = (theme: Theme) => {
    const styles = {
        container: {
            alignItems: 'center',
            backgroundColor: 'neutral.50',
            position: 'relative',
            top: '3px',
            right: '12px',
            width: '100%',
            px: 2,
            py: 0.9,
            zIndex: 10
        }
    };
    return styles;
}