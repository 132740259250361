import { useMemo, type FC } from 'react';
import { IconButton, Stack, SvgIcon, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { tokens } from '../../../../../../locales/tokens';
import { useTranslation } from 'react-i18next';
import { getStyles } from './styles';
import WorkIcon from '@mui/icons-material/Work';
import { Link } from 'react-router-dom';

interface IProps {
    partner: any;
    handleOrderOpen: (partnerId: string) => void;
};

export const TableListItem: FC<IProps> = ({ partner, handleOrderOpen }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);

    return (
        <TableRow
            hover
            key={partner.id}
            onClick={() => handleOrderOpen?.(String(partner.id))}
            sx={styles.tableRow}
        >
            <TableCell >
                <Stack >
                    <Typography color="text.secondary" variant="subtitle2">
                        code: {partner.code}
                    </Typography>
                    <Typography variant="subtitle2" >
                        {t(tokens.partner.name)}: {' '} {partner.name_enote}
                    </Typography>
                </Stack>
            </TableCell>
            <TableCell align="center" >
                <Typography variant="subtitle2">
                    {partner.name || '-'}
                </Typography>
            </TableCell>
            <TableCell align="center" >
                <Typography variant="subtitle2">
                    {partner.name_bank || '-'}
                </Typography>
            </TableCell>
            <TableCell align="center" >
                <Typography variant="subtitle2">
                    {partner.EDRPOU || '-'}
                </Typography>
            </TableCell>
            <TableCell align="center" >
                <Typography variant="subtitle2">
                    {partner.description || '-'}
                </Typography>
            </TableCell>
            <TableCell align="center">
                <Typography variant="subtitle2">
                    {
                        partner.hidden ?
                            t(tokens.account.hidden)
                            :
                            t(tokens.account.active)
                    }
                </Typography>
            </TableCell>
            <TableCell align="center" >
                <Tooltip title={t(tokens.contracts.contractsList)}>
                    <IconButton component={Link} to={`/partner-contracts/${partner.id}`}>
                        <SvgIcon><WorkIcon /></SvgIcon>
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};
