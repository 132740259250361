import { FC } from "react";
import { useAccountEdit } from "../presenters/useAccountEdit";
import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { TextInput } from "../../../../ui-kit/textInput/textInput";
import { FieldSelect } from "./components/fieldSelect";
import { CardAutocomplete } from "../../accountList/ui/components/cardAutocomplete";

export const AccountEdit: FC = () => {
    const { t } = useTranslation();
    const { name, onChangeName, accountType, handleChangeAccountType, accountTypeList, accountsList, parent, onChangeMainAccount,
        changeCompanyAccountText, handleEdit, nameValid } = useAccountEdit();

    return (
        <Stack m={2}>
            <Typography variant="h5" fontWeight={600}>{t(tokens.account.editAccount)}</Typography>
            <Stack width='500px' mt={5} spacing={3}>
                <TextInput
                    disabled={false}
                    title={t(tokens.account.title)}
                    label={t(tokens.account.title)}
                    value={name}
                    isValid={nameValid}
                    isTextArea={false}
                    errorText={t(tokens.account.titleRequired)}
                    onChangeValue={onChangeName}
                />
                <FieldSelect
                    title={t(tokens.account.accountType)}
                    value={accountType}
                    handleValue={handleChangeAccountType}
                    options={accountTypeList}
                />
                <CardAutocomplete
                    title={t(tokens.account.mainAccount)}
                    value={parent}
                    handleChangeValue={onChangeMainAccount}
                    isValid={true}
                    optionList={accountsList}
                    changeText={changeCompanyAccountText}
                />
                <Stack pt={2}>
                    <Button
                        variant="contained"
                        size="large"
                        sx={{ color: '#FFFFFF' }}
                        onClick={handleEdit}
                    >
                        {t(tokens.common.edit)}
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    )
};