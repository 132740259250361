import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';

interface IState {
  isLoading: boolean,
  createServiceRelatedError: IError,
  updateServiceRelatedError: IError,
  deleteServiceRelatedError: IError,
};

const initialState: IState = {
  isLoading: false,
  createServiceRelatedError: null,
  updateServiceRelatedError: null,
  deleteServiceRelatedError: null,
};

const ServiceRelatedSlice = createSlice({
  name: 'serviceRelated',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setCreateServiceRelatedError: (state, action: PayloadAction<IError>) => {
      state.createServiceRelatedError = action.payload;
    },
    
    setUpdateServiceRelatedError: (state, action: PayloadAction<IError>) => {
      state.updateServiceRelatedError = action.payload;
    },
    setDeleteServiceRelatedError: (state, action: PayloadAction<IError>) => {
      state.deleteServiceRelatedError = action.payload;
    },
  },
});

export const { setLoading, setCreateServiceRelatedError, setDeleteServiceRelatedError, setUpdateServiceRelatedError } = ServiceRelatedSlice.actions;
export const ServiceRelatedReducer = ServiceRelatedSlice.reducer;