import { SagaIterator } from "redux-saga";
import { put, takeLatest, call } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";
import { setCreateDate, setFormData, setInitiator } from "../../../paymentCreation/repository/slice";

function* completeBudgetOrderWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.put, "/zoolux-api/budget-payments/completed-budget-payment/" + payload.id);
        if (isError) {
            toast.error(message);
        } else {
            toast.success("Платіж успішно виконано");
            yield put(setFormData({ id: data.payment.id, status: data.payment.status }));
            yield put(setInitiator(data.payment.initiator));
            yield put(setCreateDate(data.payment.create_date)); 
        }
    } catch (error) {
        console.error("completeBudgetOrderWorker: ", error);
    } finally {
        
    }
};

export function* completeBudgetOrderWatcher() {
    yield takeLatest("@saga/COMPLETE_BUDGET_ORDER", completeBudgetOrderWorker);
};
