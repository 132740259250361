import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "../../../hooks/useDebounce";
import { IFilters } from "./types";
import { t } from "i18next";
import { tokens } from "../../../locales/tokens";
import toast from "react-hot-toast";
import { getUsersFromTelegram } from "../../../store/telegramBot/useCases/getUsersFromTelegram/action";
import { selectTelegramBot } from "../../../store/telegramBot/repository/selector";
import { deleteUserFromTelegram } from "../../../store/telegramBot/useCases/deleteUser/action";
import { getTelegramCode } from "../../../store/telegramBot/useCases/getTelegramCode/action";
import { editTelegramCode } from "../../../store/telegramBot/useCases/editTelegramCode/action";

export const useTelegramBot = () => {
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const [usersAmount, setUsersAmount] = useState(0);
    const [currentTab, setCurrentTab] = useState('Active');
    const [deleteClick, setDeleteClick] = useState(false);
    const [changeCodeClick, setChangeCodeClick] = useState(false);
    const [codeValue, setCodeValue] = useState('');
    const [isCodeHidden, setCodeHidden] = useState(true);
    const [filters, setFilters] = useState<IFilters>({ page: 1, rowsPerPage: 15, search: '', step: '' });
    const { usersFromTelegram, usersCount, isLoading, telegramCode, deleteUserError, updateTelegramCodeError } = useSelector(selectTelegramBot);

    useEffect(() => {
        dispatch(getTelegramCode());
    }, []);

    useEffect(() => {
        telegramCode && setCodeValue(telegramCode);
    }, [telegramCode]);

    useEffect(() => {
        dispatch(getUsersFromTelegram(getRequetsBody()));
    }, [filters]);

    const getRequetsBody = () => {
        const body: any = {
            page: filters.page,
            perPage: filters.rowsPerPage,
            search: filters.search
        };
        body.filter_deleted = filters.step === "Removed" ? true : false;
        return body;
    };

    useEffect(() => {
        if (usersCount) {
            setUsersAmount(Math.ceil(usersCount / filters.rowsPerPage));
        }
    }, [usersCount]);

    useEffect(() => {
        debouncedHandleSeacrh(searchText);
    }, [searchText]);

    useEffect(() => {
        if (!isLoading && deleteClick) {
            if (deleteUserError) {
                toast.error(deleteUserError);
            } else {
                showToast();
                dispatch(getUsersFromTelegram(getRequetsBody()));
            }
            setDeleteClick(false);
        }
    }, [deleteUserError, isLoading]);

    useEffect(() => {
        if (!isLoading && changeCodeClick) {
            if (updateTelegramCodeError) {
                toast.error(updateTelegramCodeError);
            } else {
                toast.success(t(tokens.telegram.message.codeChanged));
            }
            setChangeCodeClick(false);
        }
    }, [updateTelegramCodeError, isLoading]);

    const handleSearchText = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const onSearchUsers = (value: string) => {
        const search = value ? value : '';
        setFilters((prevState) => ({ ...prevState, page: 1, search }));
    };

    const { debouncedWrapper: debouncedHandleSeacrh } = useDebounce(onSearchUsers, 1000);

    const handlePageChange = useCallback((event: ChangeEvent<unknown>, page: number): void => {
        setFilters((prevState) => ({ ...prevState, page }));
    }, [setFilters]);

    const handleDeleteUser = (phone: string) => {
        dispatch(deleteUserFromTelegram({ phone }));
        setDeleteClick(true);
    };

    const tabOptions = [
        { label: t(tokens.telegram.active), value: "Active" },
        { label: t(tokens.telegram.deleted), value: "Removed" },
    ];

    const handleTabsChange = useCallback((event: ChangeEvent<{}>, tab: string): void => {
        setCurrentTab(tab);
        setFilters((prevState: IFilters) => ({ ...prevState, step: tab, page: 1 }))
    }, []);

    const onChangeCode = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setCodeValue(event.target.value);
    }, []);

    const hideCode = useCallback(() => {
        setCodeHidden(prev => !prev);
    }, []);

    const updateSecretCode = () => {
        dispatch(editTelegramCode({ secretKey: codeValue }));
        setChangeCodeClick(true);
    };

    const showToast = () => {
        filters.step === "Removed" ?
            toast.success(t(tokens.telegram.message.userRestored))
            :
            toast.success(t(tokens.telegram.message.userRemoved));
    };
    
    return {
        searchText, handleSearchText, usersFromTelegram, filters, usersAmount, handlePageChange, handleDeleteUser, currentTab, tabOptions,
        handleTabsChange, codeValue, isCodeHidden, onChangeCode, hideCode, updateSecretCode
    }
};