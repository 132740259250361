import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "../store/auth/repository/selector";
import { useEffect } from "react";
import { getCurrentUser } from "../store/auth/useCases/getCurrentUser/action";
import { useLocation, useNavigate } from "react-router-dom";

export const useGetAccess = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    
    const { user, isLoading } = useSelector(selectAuth);
    const token = globalThis.localStorage.getItem("accessToken");

    useEffect(() => {
        if (token?.length) {
            dispatch(getCurrentUser());
        } else if (!token?.length && location.pathname !== "/forgot-password") {
            navigate("/");
        }
    }, []);

    return { user, isLoading }
};