import { FC } from "react";
import { getStyles } from "./styles";
import { Box, Card, CardContent, Stack, Theme, keyframes, useMediaQuery } from "@mui/material";
import { PhoneField } from "../../login/ui/components/phoneField";
import { useForgotPassword } from "../presenters/useForgotPassword";
import { ConfirmButton } from "../../login/ui/components/confirmButton";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { SmsField } from "../../login/ui/components/smsFiled";
import { PasswordField } from "../../login/ui/components/passwordField";
import { LogInButton } from "../../login/ui/components/logInButton";
import { Loader } from "../../../../ui-kit/loader/loader";

const changeSize = keyframes({ '0%': { transform: 'scale(1)', }, '50%': { transform: 'scale(1.1)', }, '100%': { transform: 'scale(1)', } });

export const ForgotPassword: FC = () => {
    const { t } = useTranslation();
    const upMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const upSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
    const styles = getStyles(upMd, upSm);
    const { phone, handlePhone, onChangePhone, phoneValid, isPhoneInBD, isCorrectSms, handleCheckPhone, handleCheckSmsCode,
        smsCode, onChangeSmsCode, timerInSec, onChangeTimer, password, confirmPass, passwordValid, isPassShown,
        confirmPassValid, isConfirmPassShown, onShowPassword, onChangeConfirmPassword, onShowConfirmPassword,
        onChangePassword, handleLogin, isLoading, resendSmsCode, handleEnterPassword } = useForgotPassword();

    return (
        <>
            {!isLoading ?
                <Box sx={styles.container}>
                    <Box sx={{ ...styles.logoContainer, animation: `${changeSize} 5s infinite` }}>
                        <img src="/images/logo/logo-zoolux.png" style={styles.logo} alt="logo" />
                    </Box>
                    <Card elevation={16}>
                        <CardContent sx={styles.content}>
                            <Stack spacing={upMd ? 3 : 2}>
                                {!isCorrectSms &&
                                    <PhoneField
                                        phone={phone}
                                        handlePhone={handlePhone}
                                        onChangePhone={onChangePhone}
                                        phoneValid={phoneValid}
                                        isPhoneInBD={isPhoneInBD}
                                    />}
                                {(isPhoneInBD && !isCorrectSms) &&
                                    <SmsField
                                        smsCode={smsCode}
                                        onChangeSmsCode={onChangeSmsCode}
                                        timerInSec={timerInSec}
                                        onChangeTimer={onChangeTimer}
                                        resendSmsCode={resendSmsCode}
                                    />
                                }
                                {isCorrectSms &&
                                    <PasswordField
                                        title={t(tokens.auth.password)}
                                        password={password}
                                        onChangePassword={onChangePassword}
                                        handleEnterPassword={handleEnterPassword}
                                        passwordValid={passwordValid}
                                        isPassShown={isPassShown}
                                        onShowPassword={onShowPassword}
                                        isPhoneInBD={isPhoneInBD}
                                        supportText={t(tokens.auth.messages.createSafePass)}
                                        validText={t(tokens.auth.validation.passValid)}
                                    />}
                                {isCorrectSms &&
                                    <PasswordField
                                        title={t(tokens.auth.confirmPass)}
                                        password={confirmPass}
                                        onChangePassword={onChangeConfirmPassword}
                                        handleEnterPassword={handleEnterPassword}
                                        passwordValid={confirmPassValid}
                                        isPassShown={isConfirmPassShown}
                                        onShowPassword={onShowConfirmPassword}
                                        isPhoneInBD={isPhoneInBD}
                                        supportText={t(tokens.auth.messages.confirmYourPass)}
                                        validText={t(tokens.auth.validation.matchPass)}
                                    />}
                                {(!isCorrectSms && !isPhoneInBD) &&
                                    <ConfirmButton title={"Відновити пароль"} handleButton={handleCheckPhone} />}
                                {(!isCorrectSms && isPhoneInBD) &&
                                    <ConfirmButton title={t(tokens.auth.confirmCode)} handleButton={handleCheckSmsCode} />}
                                {isCorrectSms && <LogInButton handleLogin={handleLogin} title={t(tokens.auth.createAndEnter)} />}
                            </Stack>
                        </CardContent>
                    </Card>
                </Box>
                :
                <Loader />}
        </>
    )
};