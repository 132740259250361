export const getStyles = () => {
    const styles = {
        container: { 
            width: '100%' 
        },
        sum: { 
            width: '100%' 
        },
        pdv: { 
            width: '200px' 
        }
    };
    return styles;
}