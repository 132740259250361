import { Card, IconButton, Stack, Typography } from '@mui/material';
import 'dayjs/locale/uk';
import { FC } from 'react';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close-icon.svg';
import { ReactComponent as DownloadIcon } from '../../../../../assets/icons/download-icon.svg';

interface IProps {
    name: string;
    handleDeleteFile: () => void;
    handleDownloadFile?: () => void;
}

export const CardFileFixedAsset: FC<IProps> = ({ name, handleDeleteFile, handleDownloadFile }) => (
    <Card component={"div"} className='card' style={{ display: "flex", justifyContent: "space-between", columnGap: "9px" }}>
        <Stack direction={"row"} sx={{ alignItems: "center" }} columnGap={"9px"}>
            <IconButton onClick={handleDownloadFile}><DownloadIcon /></IconButton>
            <Typography component={"span"}>{name}</Typography>
        </Stack>
        <IconButton onClick={handleDeleteFile}><CloseIcon /></IconButton>
    </Card>
)

