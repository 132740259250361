import { Button, Divider, Stack, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import { ChipsList } from "../chipsList";
import { getStyles } from "./styles";

interface IProps {
    chips: any[];
    handleChipsDelete: (deletedChip: any) => void;
};

export const ServiceChips: FC<IProps> = ({ chips, handleChipsDelete }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const showChips = chips.length > 0;

    return (
        <>
            <Stack direction='row' spacing={1} mt={3}>
                <Stack>
                    <Button sx={styles.reset} variant="contained" size="small" onClick={() => handleChipsDelete(null)}>
                        {t(tokens.payment.reset)}
                    </Button>
                </Stack>
                {showChips ?
                    <ChipsList chips={chips} />
                    :
                    <Typography color="text.secondary" variant="subtitle2" sx={styles.noFilters}>
                        {t(tokens.payment.noFilters)}
                    </Typography>
                }
            </Stack>
            <Divider sx={{ mt: 1 }} />
        </>
    )
};