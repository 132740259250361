import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllAccounts } from "../../../../store/accounts/useCases/getAllAccounts/action";
import { selectAccount } from "../../../../store/accounts/repository/selector";
import { useDebounce } from "../../../../hooks/useDebounce";
import { refreshAccounts } from "../../../../store/accounts/useCases/refreshAccounts/action";

export interface Account {
    id: number;
    parent_id: number | null;
    name: string;
    hidden: boolean;
    account_type: string;
    ref_key: string;
    owner_key: string;
    organization: string;
    organization_enote: string | null;
    children?: Account[];
};

export const useAccountList = () => {
    const dispatch = useDispatch();
    const rootRef = useRef<HTMLDivElement | null>(null);
    const { allAccounts, accountsAmount, isLoading, error } = useSelector(selectAccount);
    const [filters, updateFilters] = useState({ offset: 0, limit: 40, query: '' });
    const [drawer, setDrawer] = useState<{ isOpen: boolean; data?: string; }>({ isOpen: false, data: undefined });
    const [state, setState] = useState<{ accountsList: any[], accountsCount: number }>({ accountsList: [], accountsCount: 0 });
    const [searchText, setSearchText] = useState('');
    const [pageAmount, setPageAmount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1); 

    useEffect(() => {
        dispatch(getAllAccounts({ offset: filters.offset, limit: filters.limit, search: filters.query }));
    }, [filters]);

    useEffect(() => {
        if (allAccounts?.length) {
            setState({ accountsList: allAccounts, accountsCount: 0 as number })
        } else {
            setState({ accountsList: [], accountsCount: 0 as number })
        }
    }, [allAccounts]);

    useEffect(() => {
        setPageAmount(Math.ceil(accountsAmount / filters.limit));
    }, [accountsAmount]);

    const currentAccount = useMemo(() => {
        if (!drawer.data) {
            return undefined;
        };
        return state.accountsList.find((account) => account.id === Number(drawer.data));
    }, [drawer, state.accountsList]);

    const handleAccountOpen = useCallback((accountId: string): void => {
        if (drawer.isOpen && drawer.data === accountId) {
            setDrawer({ isOpen: false, data: undefined });
            return;
        }
        setDrawer({ isOpen: true, data: accountId });
    }, [drawer]);

    const handleAccountClose = useCallback((): void => {
        setDrawer({ isOpen: false, data: undefined });
    }, []);

    const handleQueryChange = useCallback((query: string): void => {
        updateFilters((prevState) => ({ ...prevState, query, offset: 0 }));
        setCurrentPage(1);
    }, [updateFilters]);

    const handlePageChange = useCallback((event: ChangeEvent<unknown>, page: number): void => {
        updateFilters((prevState) => ({ ...prevState, offset: (page - 1) * filters.limit }));
        setCurrentPage(page);
    }, [updateFilters]);

    const handleSearchAccountText = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    useEffect(() => {
        debouncedHandleAccountsSeacrh(searchText);
    }, [searchText]);

    const onSearchAccounts = (value: string) => {
        const query = value ? value : '';
        handleQueryChange(query);
    };

    const { debouncedWrapper: debouncedHandleAccountsSeacrh } = useDebounce(onSearchAccounts, 400);

    const updateAccounts = () => {
        dispatch(refreshAccounts());
    };

    return {
        state, currentAccount, handleAccountClose, handleAccountOpen, handlePageChange, handleQueryChange, updateAccounts,
        rootRef, filters, drawer, pageAmount, searchText, handleSearchAccountText, currentPage
    }
};