import { Button, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { usePartnerEdit } from "../presenters/usePartnerEdit";
import { TextInput } from "../../../../ui-kit/textInput/textInput";
import { getStyles } from "./styles";

export const PartnerEdit: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { shortName, edrpou, desc, onChangeShortName, onChangeEdrpou, onChangeDesc, handleEdit, bankName, onChangeBankName, 
        updateClick, shortNameValid } = usePartnerEdit();
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return (
        <Stack direction='row' alignItems='center' spacing={20} mt={3}>
            <Stack mt={5} spacing={3} width={'600px'}>
                <Typography variant="h4" fontWeight={500}>{t(tokens.partner.editPartner)}</Typography>
                <TextInput
                    disabled={false}
                    title={t(tokens.partner.name) + "*"}
                    label={t(tokens.partner.name)}
                    value={shortName}
                    isValid={shortNameValid}
                    isTextArea={false}
                    onChangeValue={onChangeShortName}
                />
                <TextInput
                    disabled={false}
                    title={t(tokens.partner.bankName)}
                    label={t(tokens.partner.bankName)}
                    value={bankName}
                    isValid={true}
                    isTextArea={false}
                    onChangeValue={onChangeBankName}
                />
                <TextInput
                    disabled={false}
                    title={t(tokens.partner.urName)}
                    label={t(tokens.partner.urName)}
                    value={desc}
                    isValid={true}
                    isTextArea={true}
                    onChangeValue={onChangeDesc}
                />
                <Stack pt={8}>
                    <Button
                        variant="contained"
                        sx={styles.confirmButton}
                        size="large"
                        onClick={handleEdit}
                        disabled={updateClick}
                    >
                        {t(tokens.common.edit)}
                    </Button>
                </Stack>

            </Stack>
            {!mdDown ? <img src="/images/account/doctor.png" alt="" width='350px' height='500px' /> : <></>}
        </Stack>
    )
};