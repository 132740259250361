import { Dispatch, FC, SetStateAction, useMemo } from "react";
import { Button, Checkbox, Dialog, FormControlLabel, IconButton, Stack, SvgIcon, TextField, Typography } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import { usePartnerCreateModal } from "../../../presenters/usePartnerCreateModal";
import { TextInput } from "../../../../../../ui-kit/textInput/textInput";
import { FolderAutocomplete } from "../../../../../partner/partner-create/ui/components/folderAutocomplete";
import { getStyles } from "./styles";

interface IProps {
    partnerName: string;
    isOpen: boolean;
    handleToggle: () => void;
    setDialogValue: Dispatch<SetStateAction<any>>;
};

export const PartnerCreateModal: FC<IProps> = ({ isOpen, handleToggle, setDialogValue, partnerName }) => {
    const { t } = useTranslation(); 
    const styles = useMemo(() => getStyles(), []);
    const { closeAndClear, name, edrpou, onChangeName, onChangeEdrpou, createClick, handleCreate, nameValid, edrpouValid, changeFolderText,
        handleChangeFolder, folder, partnerFolders, bankName, onChangeBankName, desc, onChangeDesc } = usePartnerCreateModal(partnerName, handleToggle, setDialogValue);

    return (
        <Dialog open={isOpen} onClose={closeAndClear} maxWidth='sm' fullWidth sx={{ borderRadius: '20px' }}>
            <Stack m={2} spacing={2}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h5" fontWeight={600}>
                        {t(tokens.partner.createPartner)}
                    </Typography>
                    <IconButton onClick={closeAndClear}>
                        <SvgIcon><ClearIcon /></SvgIcon>
                    </IconButton>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <TextInput
                        disabled={false}
                        title={t(tokens.partner.name) + "*"}
                        label={t(tokens.partner.name)}
                        value={name}
                        isValid={nameValid}
                        isTextArea={false}
                        errorText={t(tokens.partner.specifyName)}
                        onChangeValue={onChangeName}
                    />
                    <TextInput
                        disabled={false}
                        title={t(tokens.partner.edrpou) + "*"}
                        label={t(tokens.partner.edrpou)}
                        value={edrpou}
                        isValid={edrpouValid}
                        isTextArea={false}
                        errorText={t(tokens.partner.specifyEdrpou)}
                        onChangeValue={onChangeEdrpou}
                    />
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center">
                    <TextInput
                        disabled={false}
                        title={t(tokens.partner.bankName)}
                        label={t(tokens.partner.bankName)}
                        value={bankName}
                        isValid={true}
                        isTextArea={false}
                        onChangeValue={onChangeBankName}
                    />
                    <FolderAutocomplete
                        title={t(tokens.partner.folder)}
                        value={folder}
                        handleChangeValue={handleChangeFolder}
                        isValid={true}
                        optionList={partnerFolders}
                        changeText={changeFolderText}
                    />
                </Stack>
                <TextInput
                    disabled={false}
                    title={t(tokens.partner.urName)}
                    label={t(tokens.partner.urName)}
                    value={desc}
                    isValid={true}
                    isTextArea={true}
                    onChangeValue={onChangeDesc}
                />
                <Stack pt={8}>
                    <Button
                        variant="contained"
                        sx={styles.confirmButton}
                        size="large"
                        onClick={handleCreate}
                        disabled={createClick}
                    >
                        {t(tokens.common.create)}
                    </Button>
                </Stack>
            </Stack>
        </Dialog>
    )
};