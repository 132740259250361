import { SagaIterator } from "redux-saga";
import { put, call, takeEvery } from "redux-saga/effects";
import { requester } from "../../../../libs/requester/requester-axios";
import { IResponse } from "../../../../libs/requester/IResponse";
import toast from "react-hot-toast";
import { setConfirmPaymentFile, setFiles } from "../../../paymentCreation/repository/slice";

function* addFileWorker({ payload }: any): SagaIterator {
    try {
        const formData = new FormData();
        for (const [key, value] of Object.entries(payload.body as Object)) {
            const isValue = value !== null && value !== undefined && value !== "";
            if (isValue) {
                if (Array.isArray(value)) {
                    value.forEach(file => {
                        formData.append(key, file);
                    });
                } else {
                    formData.append(key, value);
                }
            }
        }; 
        const url = payload.mode === "Payment"? "/zoolux-api/payments/add-files" : "/zoolux-api/budget-payments/add-files";
        const { data, isError, message }: IResponse<any> = yield call(requester.post, url, formData, { "Content-Type": "multipart/form-data" });
        if (isError) {
            toast.error(message);
        } else {
            const newFiles =  data.payment.documents;
            if(payload.isUpdate && payload.body.type === "CONFIRM"){
                yield put(setConfirmPaymentFile(newFiles[newFiles.length - 1] as File));
            };
            if(payload.isUpdate && payload.body.type === "DEFAULT"){
                yield put(setFiles(newFiles[newFiles.length - 1] as File));
            };
        }
    } catch (error) {
        console.error("addFilesWorker: ", error);
    } finally {

    }
};

export function* addFileWatcher() {
    yield takeEvery("@saga/ADD_FILE", addFileWorker);
};