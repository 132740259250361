import type { FC } from 'react';
import { TableBody } from '@mui/material';
import { TableListItem } from '../tableListItem';

interface IProps {
    partners: any[];
    handleOrderOpen: (partnerId: string) => void;
};

export const TableListBody: FC<IProps> = ({ partners, handleOrderOpen }) => {

    return (
        <TableBody>
            {partners?.map((partner, index) => (
                <TableListItem
                    key={index}
                    partner={partner}
                    handleOrderOpen={handleOrderOpen}
                />
            )
            )}
        </TableBody>
    );
};
