import Router from "./navigation/router";
import AppProvider from "./providers/appProvider";
import './App.css';

function App() {

  return (
    <AppProvider>
      <Router />
    </AppProvider>
  );
};

export default App;
