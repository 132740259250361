import { Pagination, Stack, Table, TableBody } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { ServiceListHead } from "../serviceListHead";
import { ServiceListItem } from "../serviceListItem";

interface IProps {
    serviceList: any[];
    filters: any;
    pageAmount: number;
    serviceId: number | null;
    deletedId: number | null;
    isProgress: boolean;
    handleServiceId: (id: number) => void;
    handleDeleteService: (id: number) => void;
    handlePageChange: (event: ChangeEvent<unknown>, page: number) => void;
};

export const ServiceListTable: FC<IProps> = ({ serviceList, filters, pageAmount, handlePageChange, handleDeleteService,
    serviceId, handleServiceId, isProgress, deletedId }) => {

    return (
        <Stack sx={{ my: 5 }}>
            <Table>
                <ServiceListHead />
                <TableBody>
                    {serviceList.map((service: any) =>
                        <ServiceListItem
                            key={service.id + service.name}
                            service={service}
                            serviceId={serviceId}
                            deletedId={deletedId}
                            isProgress={isProgress}
                            handleServiceId={handleServiceId}
                            handleDeleteService={handleDeleteService}
                        />
                    )}
                </TableBody>
            </Table>
            <Stack direction='row' justifyContent='center' mt={3}>
                <Pagination
                    page={filters.page}
                    count={pageAmount || 1}
                    color="primary"
                    onChange={handlePageChange}
                />
            </Stack>
        </Stack>
    )
};