import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";
import { setLoading, setEditNomenclatureError } from "../../repository/slice";

function* editNomenclatureWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, isError, message }: IResponse<any> = yield call(requester.patch, "/zoolux-api/admin/nomenclatures", payload);
        if (isError) {
            yield put(setEditNomenclatureError(message));
        } else {
            yield put(setEditNomenclatureError(null));
        }
    } catch (error) {
        console.error("editNomenclatureWorker: ", error);
    } finally {
        yield put(setLoading(false));
    }
};

export function* editNomenclatureWatcher() {
    yield takeLatest("@saga/EDIT_NOMENCLATURE", editNomenclatureWorker);
};
