import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";
import { getAllExpenseItems } from "../getAllExpenseItems/action";

function* refreshExpenseItemsWorker(): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.post, "/zoolux-api/expenditure/refresh-enote");
        if (isError) {
            toast.error("Статті витрат не оновилися");
        } else {
            toast.success("Статті витрат оновлені");
            // yield put(getAllExpenseItems({ offset: 0, limit: 1000 }));
        }
    } catch (error) {
        console.error("refreshExpenseItemsWorker: ", error);
    } finally {

    };
};

export function* refreshExpenseItemsWatcher() {
    yield takeLatest("@saga/REFRESH_EXPENSE_ITEMS", refreshExpenseItemsWorker);
};
