import { Button, Stack, Theme, useMediaQuery } from "@mui/material";
import { FC, useMemo } from "react";
import { TimeDatePicker } from "../../../../../../../../ui-kit/timePicker";
import { useTranslation } from "react-i18next";
import { PAYMENT_STATUS } from "../../../../../../../../config";
import { usePaymentProcessing } from "../presenter/usePaymentProcessing";
import { tokens } from "../../../../../../../../locales/tokens";
import { getStyles } from "./styles";
import { useUserPermission } from "../../../../../../../payment-calendar/payment-create/presenters/useUserPermission";
import { IUser } from "../../../../../../../../types/users";
import { UpdateModal } from "../../../../../../../payment-calendar/payment-create/ui/components/updateModal";
import { ServiceModal } from "../../../../../../../payment-calendar/payment-create/ui/components/serviceModal";
import { useSelector } from "react-redux";
import { selectPaymentCreation } from "../../../../../../../../store/paymentCreation/repository/selector";
import { Link } from "react-router-dom";
import { paths } from "../../../../../../../../paths";

interface IProps {
    modeFilter?: string;
    currentPayment: any;
    user: IUser;
    paymentStatus: string | undefined;
};

export const PaymentProcessing: FC<IProps> = ({ modeFilter, currentPayment, user, paymentStatus }) => {
    const { t } = useTranslation();
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const styles = useMemo(() => getStyles(downSm), [downSm]);
    const { formStatus, formId } = useSelector(selectPaymentCreation);
    const paymentId = currentPayment?.payment.id || formId;

    const { createOrEditPayment, time, handleDateChange, payment, transferToPayment, handleChangeVisa, isNotPutToEnot, isVisa, currentPaymentType,
        makePayment, isUpdatedPaymentModal, isOpenServiceModal, handleToggleServiceModal, handleToggleUpdateModal, validateAndProcess,
        updatePaymentInModal } = usePaymentProcessing(paymentId, modeFilter);
    const { isInitiator, isTransferAllowed, isVisaAllowed, isProcessAllowed, isCreate_In_Enote } = useUserPermission(user.roles);
    const isCreator = currentPayment?.payment.initiator.id === user.id;
    const status = formStatus || paymentStatus;
    const isDisabled = status === "VISAED" && isNotPutToEnot;

    return (
        <>
            {currentPayment?.receipt_goods_services && (
                <Stack my={1}><Link to={paths.dashboard.serviceHistory.edit.replace(':id', currentPayment?.receipt_goods_services.id)}>
                    {process.env.REACT_APP_PUBLIC_URL + paths.dashboard.serviceHistory.edit.replace(':id', currentPayment?.receipt_goods_services.id)}
                </Link></Stack>
            )}
            <Stack direction={downSm ? 'column' : 'row'} spacing={3} alignItems='flex-end'>
                {status !== PAYMENT_STATUS.OPERATED ?
                    <TimeDatePicker value={time} isValid={payment.date} isdisabledPast={true} title={t(tokens.payment.payOnDay)} handleDate={handleDateChange} />
                    : null}
                <Stack width='100%'>
                    {(paymentId && isInitiator) || (paymentId && modeFilter === 'Budget') || (paymentId && isCreator) ?
                        <Button
                            sx={styles.formButton}
                            fullWidth
                            variant='contained'
                            onClick={() => createOrEditPayment(true)}
                            disabled={
                                status === PAYMENT_STATUS.OPERATED
                                || isInitiator && status === PAYMENT_STATUS.VISAED
                                || status === PAYMENT_STATUS.TRANSMITTED
                                || status === PAYMENT_STATUS.COMPLETED
                                || currentPayment?.payment.hidden
                            }
                        >
                            {t(tokens.payment.editPayment)}
                        </Button> : null
                    }
                    {!paymentId ?
                        <Button
                            sx={styles.formButton}
                            fullWidth
                            variant='contained'
                            onClick={() => createOrEditPayment(true)}
                        >
                            {t(tokens.payment.createPayment)}
                        </Button> : null}
                </Stack>
            </Stack>
            <Stack direction='row' spacing={3} mt={1}>
                <Stack flex={1}></Stack>
                <Stack flex={1}>
                    {paymentId && modeFilter === 'Budget' ?
                        <Button
                            sx={styles.formButton}
                            fullWidth
                            disabled={
                                !((isCreator && !isTransferAllowed) && (status !== PAYMENT_STATUS.TRANSMITTED)
                                    || isTransferAllowed && (status !== PAYMENT_STATUS.TRANSMITTED
                                        && status !== PAYMENT_STATUS.COMPLETED))
                            }
                            variant='contained'
                            onClick={() => transferToPayment(paymentId)}
                        >
                            {t(tokens.payment.transferToPayment)}
                        </Button> : null}
                </Stack>
            </Stack>
            {paymentId && modeFilter === "Payment" ?
                <Stack direction={downSm ? 'column' : 'row'} spacing={3} alignItems='flex-end' mt={2}>
                    <Button
                        sx={styles.formButton}
                        fullWidth
                        variant='contained'
                        onClick={() => handleChangeVisa(Number(paymentId), status as string)}
                        disabled={
                            !isVisaAllowed
                            || status === PAYMENT_STATUS.OPERATED
                            || currentPayment?.payment.hidden
                            || isDisabled
                        }
                    >
                        {isVisa ?
                            t(tokens.payment.unVisaPayment)
                            :
                            t(tokens.payment.visaPayment)
                        }
                    </Button>
                    <Button
                        sx={styles.formButton}
                        fullWidth
                        variant='contained'
                        onClick={makePayment}
                        disabled={
                            !isProcessAllowed
                            || status !== PAYMENT_STATUS.VISAED
                            || currentPayment?.payment.hidden
                            || isProcessAllowed && isNotPutToEnot && !isCreate_In_Enote
                        }
                    >
                        {currentPaymentType?.key === "service_receiving" ?
                            t(tokens.payment.processPayment)
                            :
                            t(tokens.payment.processPayment)
                        }
                    </Button>
                </Stack> : null}
            <UpdateModal
                title={t(tokens.payment.editPayment)}
                description={t(tokens.payment.updateModalMessage)}
                isOpen={isUpdatedPaymentModal}
                handleToggleModal={handleToggleUpdateModal}
                updatePaymentInModal={updatePaymentInModal}
            />
            <ServiceModal
                isOpen={isOpenServiceModal}
                handleToggleModal={handleToggleServiceModal}
                handleModal={validateAndProcess}
            />
        </>
    )
};