import { Checkbox, Stack, Typography } from "@mui/material"
import { ChangeEvent, FC } from "react";

interface IProps {
    title: string;
    checked: boolean;
    isDisabled: boolean;
    onChangeValue: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const CheckBox: FC<IProps> = ({ title, checked, onChangeValue, isDisabled }) => {

    return (
        <Stack direction='row' spacing={1} alignItems='center'>
            <Checkbox onChange={onChangeValue} checked={checked} disabled={isDisabled} />
            <Typography>{title}</Typography>
        </Stack>
    )
};