import { FC, useMemo } from "react";
import { usePaymentForm } from "../presenters/usePaymentForm";
import { Button, Divider, Stack, Typography } from "@mui/material";
import { FormField } from "./components/formField";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { DateField } from "./components/dateField";
import { getStyles } from "./styles";
import { SumField } from "./components/sumField";
import { TaxesField } from "./components/taxesField";
import { FileField } from "./components/fileField";

export const PaymentForm: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { currentPayment } = usePaymentForm();
    const { handleCopy, handlePayment, handleCancel, now, watchCurrentFile } = usePaymentForm();

    return (
        <Stack direction='row' justifyContent='center' mb={3}>
            {currentPayment ?
                <Stack width='80%'>
                    <Typography textAlign='center' variant="h5" mt={3}>
                        {currentPayment.payment_method.name}
                    </Typography>
                    <Divider sx={{ my: 3 }} />
                    <Stack spacing={1}>
                        <FormField
                            title={t(tokens.payment.fromAccount)}
                            value={currentPayment.company_account?.name}
                            handleCopy={handleCopy}
                        />
                        <DateField paymentDate={now} handleCopy={handleCopy} />
                    </Stack>
                    <Divider sx={{ my: 3 }} />
                    <Stack spacing={1} >
                        <FormField
                            title={t(tokens.payment.urPartnerName)}
                            value={
                                currentPayment.counterparty.name ||
                                currentPayment.bankDetails?.bank_name ||
                                currentPayment.counterparty.description || ''
                            }
                            handleCopy={handleCopy}
                        />
                        <SumField
                            amount={currentPayment.amount}
                            handleCopy={handleCopy}
                        />
                        <TaxesField
                            taxes={currentPayment.taxes}
                            handleCopy={handleCopy}
                        />
                        <FormField
                            title={t(tokens.payment.comment)}
                            value={currentPayment.comment}
                            isMultiline={true}
                            rows={3}
                            handleCopy={handleCopy}
                        />
                    </Stack>
                    {currentPayment.documents?.length ? <FileField files={currentPayment.documents} watchCurrentFile={watchCurrentFile} /> : null}
                    <Divider sx={{ my: 3 }} />
                    {currentPayment?.isConfirmedFile && <Typography variant="body1" color="error" textAlign="center">
                        {t(tokens.payment.importantMessage)}
                    </Typography>
                    }
                    <Stack>
                        <Stack direction='row' spacing={2} justifyContent='center' mt={4}>
                            <Button
                                variant="contained"
                                sx={styles.buttonCancel}
                                size="large"
                                onClick={handleCancel}
                            >
                                {t(tokens.common.cancel)}
                            </Button>
                            <Button
                                variant="contained"
                                sx={styles.buttonConfirm}
                                size="large"
                                onClick={handlePayment}
                            >
                                {t(tokens.payment.processPayment)}
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
                :
                null
            }
        </Stack>
    )
};