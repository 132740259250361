export const getStyles = (upMd: boolean, upSm: boolean) => {
    const styles = {
        container: {
            position: 'fixed',
            top: '40%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: upMd ? '450px' : upSm ? '350px' : '300px',
            borderRadius: '4px'
        },
        logoContainer: {
            display: 'flex',
            justifyContent: 'center',
            animation: 'zoomInAndOut 1s infinite',
            marginBottom: 10,
        },
        logo: {
            maxWidth: '60%',
            maxHeight: '60%',
        },
        content: {
            mt: 2,
        },
    }
    return styles;
};