import { Pagination, Stack, Table, TableBody } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { PaymentsListHead } from "../paymentsListHead";
import { PaymentsListItem } from "../paymentsListItem";

interface IProps {
    payments: any[];
    currentPage: number;
    pageAmount: number;
    navigateToPayment: (id: string) => void;
    handlePageChange: (event: ChangeEvent<unknown>, page: number) => void;
};

export const PaymentsListTable: FC<IProps> = ({ payments, pageAmount, currentPage, navigateToPayment, handlePageChange }) => {

    return (
        <Stack sx={{ mb: 5, mt: 2 }}>
            <Table>
                <PaymentsListHead />
                <TableBody>
                    {payments.map((payment: any) =>
                        <PaymentsListItem
                            key={payment.name + payment.id}
                            payment={payment}
                            navigateToPayment={navigateToPayment}
                        />
                    )}
                </TableBody>
            </Table>
            <Stack direction='row' justifyContent='center' mt={3}>
                <Pagination
                    page={currentPage}
                    count={pageAmount || 1}
                    color="primary"
                    onChange={handlePageChange}
                />
            </Stack>
        </Stack>
    )
};