import { SelectChangeEvent } from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { tokens } from "../../../../locales/tokens";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { createSingleContract } from "../../../../store/contract/useCases/createContract/action";
import { selectContract } from "../../../../store/contract/repository/selector";
import { useNavigate } from "react-router-dom";

export const useContractCreate = () => {
    const { partnerId } = useParams();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [type, setType] = useState('');
    const [typeOptions, setTypeOptions] = useState([t(tokens.contracts.contract), t(tokens.contracts.product), t(tokens.contracts.service), t(tokens.contracts.other)]);
    const [typeValid, setTypeValid] = useState(true);
    const [comment, setComment] = useState('');
    const [commentValid, setCommentValid] = useState(true);
    const [files, setFiles] = useState<any[]>([]);
    const [fileValid, setFileValid] = useState(true);
    const [startDate, setStartDate] = useState<any>(null);
    const [startDateValid, setStartDateValid] = useState<any>(true);
    const [endDate, setEndDate] = useState<any>(null);
    const [endDateValid, setEndDateValid] = useState(true);
    const [createClick, setCreateClick] = useState(false);
    const { isLoading, createContractError } = useSelector(selectContract);
    
    useEffect(() => {
        if (!isLoading && createClick) {
            if (createContractError) {
                toast.error(createContractError);
            } else {
                toast.success(t(tokens.contracts.message.contractCreated));
                navigate(`/partner-contracts/${partnerId}`);
            }
            setCreateClick(false);
        }
    }, [createContractError, isLoading]);
    
    const handleChangeType = useCallback((event: SelectChangeEvent) => {
        setType(event.target.value);
        setTypeValid(true);
    }, []);

    const onChangeTitle = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    }, []);

    const onChangeComment = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setComment(event.target.value);
        setCommentValid(true);
    }, []);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            if (file.size <= 25 * 1024 * 1024) {
                setFiles((prevState) => [...prevState, file]);
            }
        } else {
            toast.error(t(tokens.contracts.maxSizeFile));
        }
        setFileValid(true);
    };

    const watchCurrentFile = (name: string) => {
        const fileUrl = process.env.REACT_APP_PUBLIC_URL + '/zoolux-api/file-storage/' + name;
        window.open(fileUrl, '_blank');
    };

    const handleDeleteFile = (name: File) => {
        const newFiles = files.filter((file: any) => file.name !== name.name);
        setFiles(newFiles);
    };

    const handleStartDateChange = useCallback((date: Date | null): void => {
        if (date) {
            setStartDate(date?.valueOf());
            setStartDateValid(true);
        }
    }, []);

    const handleEndDateChange = useCallback((date: Date | null): void => {
        if (date) {
            setEndDate(date?.valueOf());
            setEndDateValid(true);
        }
    }, []);

    const createContract = () => {
        const isValidData = validateData();
        if (isValidData) {
            dispatch(createSingleContract(getRequestBody()));
            setCreateClick(true);
        } else {
            toast.error(t(tokens.contracts.fillAllNeededData))
        }
    };

    const validateData = () => {
        const data = [
            { field: type, setField: setTypeValid },
            { field: comment, setField: setCommentValid },
            { field: files.length, setField: setFileValid },
            { field: startDate, setField: setStartDateValid },
            { field: endDate, setField: setEndDateValid },
        ];
        let allFieldsValid = true;
        for (const { field, setField } of data) {
            if (!field) {
                setField(false);
                allFieldsValid = false;
            }
        };
        return allFieldsValid;
    };

    const getRequestBody = () => {
        const body: any ={ counterparty_id : Number(partnerId), type, comment, files, date_start: startDate, date_end: endDate };
        // if (title) body.title = title;
        return body;
    };

    return {
        type, handleChangeType, typeOptions, title, onChangeTitle, typeValid, commentValid, comment, onChangeComment, createContract,
        handleFileChange, watchCurrentFile, handleDeleteFile, files, startDate, endDate, handleStartDateChange, handleEndDateChange,
        fileValid, startDateValid, endDateValid, createClick
    }
};