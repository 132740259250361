import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";
import { setContracts } from "../../repository/slice";

function* getContractsListWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.get, "/zoolux-api/document-counterparty", payload);
        if (isError) {
            toast.error(message);
        } else {
            yield put(setContracts({ list: data.rows, count: data.count, partnerName: data.counterparty_name }));
        }
    } catch (error) {
        console.error("getContractsListWorker: ", error);
    } finally {

    }
};

export function* getContractsListWatcher() {
    yield takeLatest("@saga/GET_CONTRACTS_LIST", getContractsListWorker);
};
