import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { IUserOption } from "../../../../utils/collectOptionList";
import { useDispatch, useSelector } from "react-redux";
import { setPrepaymentComment, setPrepaymentDate, setResponsible } from "../../../../store/paymentCreation/repository/slice";
import { changePrepaymentStatus } from "../../../../store/paymentCalendar/useCases/changePrepaymentStatus/action";
import { selectAuth } from "../../../../store/auth/repository/selector";
import { setPaymentFieldValidate, setPaymentUpdated } from "../../../../store/operationValidation/repository/slice";

export const usePrePaymentForm = (prePaymentId: string | undefined) => {
    const dispatch = useDispatch();
    const { user } = useSelector(selectAuth);

    useEffect(()=>{
        if(!prePaymentId){
            dispatch(setResponsible({...user, label: user.full_name}))
        }
    }, [user]);

    const handleChangeUser = useCallback((value: IUserOption | null) => {
        dispatch(setResponsible(value));
        dispatch(setPaymentFieldValidate('user'));
        dispatch(setPaymentUpdated(true));
    }, []);

    const onChangeComment = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        dispatch(setPrepaymentComment(event.target.value));
        dispatch(setPaymentUpdated(true));
    }, []);

    const handleDateChange = useCallback((date: Date | null): void => {
        dispatch(setPrepaymentDate(date?.valueOf() || null));
        dispatch(setPaymentFieldValidate('prepaymentDate'));
        dispatch(setPaymentUpdated(true));
    }, []);

    const receiveProduct = () => {
        dispatch(changePrepaymentStatus({prepayment_id: prePaymentId}));
    };

    return { onChangeComment, handleDateChange, handleChangeUser, receiveProduct }
};