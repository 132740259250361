import { Pagination, Stack, Table, TableBody } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { ContractsListHead } from "../contractsListHead";
import { ContractsListItem } from "../contractsListItem";

interface IProps {
    contracts: any[];
    page: number;
    pageAmount: number;
    partnerId: string | undefined;
    handleDeleteContract: (id: number) => void;
    handlePageChange: (event: ChangeEvent<unknown>, page: number) => void;
};

export const ContractsList: FC<IProps> = ({ contracts, handlePageChange, page, pageAmount, handleDeleteContract, partnerId }) => {

    return (
        <>
            <Table sx={{ mt: 5 }}>
                <ContractsListHead />
                <TableBody>
                    {contracts?.map((contract: any) =>
                        <ContractsListItem
                            contract={contract}
                            partnerId={partnerId}
                            key={contract.id + contract.createdAt}
                            handleDeleteContract={handleDeleteContract}
                        />
                    )}
                </TableBody>
            </Table>
            <Stack direction='row' justifyContent='center' my={4}>
                <Pagination
                    page={page}
                    count={pageAmount || 1}
                    color="primary"
                    onChange={handlePageChange}
                />
            </Stack>
        </>
    )
};