import { FC, useMemo } from "react";
import { useServiceList } from "../presenters/useServiceList";
import { Button, CircularProgress, Stack, SvgIcon, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getStyles } from "./styles";
import { tokens } from "../../../../locales/tokens";
import AddIcon from '@mui/icons-material/Add';
import { Link } from "react-router-dom";
import { SearchInput } from "../../../../ui-kit/searchInput/searchInput";
import { EmptyList } from "../../../../ui-kit/emptyList";
import { ServiceListTable } from "./components/serviceListTable";
import { paths } from "../../../../paths";
import { useGetPermissions } from "../../../../hooks/useGetPermission";

export const ServiceList: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { isWrite_Service_Receiving } = useGetPermissions();
    const { searchText, handleSearchText, serviceListAll, handleDeleteService, serviceAmount, filters, handlePageChange,
        handleServiceId, serviceId, isProcess, deleteClick, deletedId } = useServiceList();

    return (
        <Stack>
            <Stack direction='row' justifyContent='space-between' mt={3}>
                <Typography variant="h4">{t(tokens.service.serviceList)}</Typography>
                <Button
                    variant="contained"
                    size="medium"
                    sx={styles.createBtn}
                    startIcon={(<SvgIcon><AddIcon /></SvgIcon>)}
                    component={Link}
                    to={paths.dashboard.service.create}
                    disabled={!isWrite_Service_Receiving}
                >
                    {t(tokens.common.create)}
                </Button>
            </Stack>
            <Stack mt={5}>
                <SearchInput
                    searchText={searchText}
                    label={t(tokens.service.searchByTitle)}
                    handleSearch={handleSearchText}
                />
            </Stack>
            {serviceListAll?.length > 0 &&
                <ServiceListTable
                    serviceList={serviceListAll}
                    filters={filters}
                    pageAmount={serviceAmount}
                    serviceId={serviceId}
                    deletedId={deletedId}
                    isProgress={deleteClick}
                    handleServiceId={handleServiceId}
                    handlePageChange={handlePageChange}
                    handleDeleteService={handleDeleteService}
                />}
            {(!serviceListAll.length && isProcess) &&
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={styles.progress}
                >
                    <CircularProgress color="primary" />
                </Stack>
            }
            {(!serviceListAll.length && !isProcess) &&
                <EmptyList
                    title={t(tokens.service.listEmpty)}
                    description={t(tokens.service.emptyListDesc)}
                />
            }
        </Stack>
    )
};