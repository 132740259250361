import { Button, Dialog, DialogContent, DialogTitle, Stack, Theme, Tooltip, useMediaQuery } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useTranslation } from "react-i18next";
import { tokens } from "../../locales/tokens";
import { PAYMENT_STATUS } from "../../config";
import { getStyles } from "./styles";

export interface IProps {
    isInitiator: boolean;
    isDeleteAllowed: boolean;
    isCreator: boolean;
    status: string | undefined;
    paymentId: string | undefined;
    groupdId: string | null;
    isHidden: boolean;
    modeFilter: string;
    handleDeletePayment: (id: string) => void;
    handleDeleteGroupPayments: (groupId: string) => void;
};

export const DeleteModal: FC<IProps> = ({ isInitiator, isDeleteAllowed, isCreator, status, handleDeletePayment, paymentId, groupdId, handleDeleteGroupPayments, isHidden, modeFilter }) => {
    const { t } = useTranslation();
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const styles = useMemo(() => getStyles(), []);
    const [open, toggleOpen] = useState(false);

    const handleClose = () => {
        toggleOpen(false);
    };

    const handleButton = useCallback((group_id: string | null, payment_id: string | undefined) => {
        if (group_id) {
            toggleOpen(true)
        } else {
            handleDeletePayment(payment_id as string)
        }
    }, [groupdId, paymentId]);

    const handleDeleteRegular = (groupd_id: string | null, payment_id: string | undefined) => {
        handleDeletePayment(payment_id as string);
        handleDeleteGroupPayments(groupd_id as string);
    };

    const isDesabled = useMemo(() => {
        if (modeFilter === 'Payment') {
            return (
                isInitiator && status !== PAYMENT_STATUS.CREATED
                || status == PAYMENT_STATUS.OPERATED
                || isHidden
            )
        } else {
            return (
                status == PAYMENT_STATUS.TRANSMITTED
                || status === PAYMENT_STATUS.COMPLETED
                || !isDeleteAllowed && !isCreator
            )
        }
    }, [modeFilter, isInitiator, isDeleteAllowed, isCreator, status]);

    return (
        <Stack>
            <Tooltip title={t(tokens.payment.deletePayment)} arrow>
                <span>
                    <Button
                        variant="contained"
                        color="error"
                        disabled={isDesabled}
                        size={downSm ? "small" : "medium"}
                        sx={{ flex: 1 }}
                        startIcon={!downSm ? <DeleteForeverOutlinedIcon color='inherit' /> : null}
                        onClick={() => handleButton(groupdId, paymentId)}
                    >
                        {t(tokens.common.delete)}
                    </Button>
                </span>
            </Tooltip>
            <Dialog open={open} onClose={handleClose} sx={{ p: 3 }} fullWidth>
                <DialogTitle textAlign='center'>{t(tokens.payment.deleteRegular)}</DialogTitle>
                <DialogContent>
                    <Stack direction='row' spacing={3} mt={2}>
                        <Button
                            variant="contained"
                            color="error"
                            size="medium"
                            sx={styles.deleteButton}
                            onClick={() => handleDeleteRegular(groupdId, paymentId as string)}
                        >
                            {t(tokens.payment.allPayments)}
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            size="medium"
                            sx={styles.deleteButton}
                            onClick={() => handleDeletePayment(paymentId as string)}
                        >
                            {t(tokens.payment.thisPayment)}
                        </Button>
                    </Stack>
                </DialogContent>
            </Dialog>
        </Stack>
    )
};