import { FC } from "react";
import { useTelegramBot } from "../presenters/useTelegramBot";
import { Stack, Typography } from "@mui/material";
import { tokens } from "../../../locales/tokens";
import { useTranslation } from "react-i18next";
import { SearchInput } from "../../../ui-kit/searchInput/searchInput";
import { EmptyList } from "../../../ui-kit/emptyList";
import { UsersListTable } from "./components/usersListTable";
import { TabSelector } from "../../../ui-kit/tabSelector/tabSelector";
import { TelegramCodeTool } from "./components/telegramCodeTool";

export const TelegramBot: FC = () => {
    const { t } = useTranslation()
    const { searchText, handleSearchText, usersFromTelegram, filters, usersAmount, handlePageChange, handleDeleteUser,
        currentTab, tabOptions, handleTabsChange, codeValue, isCodeHidden, onChangeCode, hideCode, updateSecretCode } = useTelegramBot();

    return (
        <Stack>
            <Stack direction='row' justifyContent='space-between' mt={3} alignItems="center">
                <Typography variant="h4">{t(tokens.telegram.telegramBot)}</Typography>
                <TelegramCodeTool
                    codeValue={codeValue}
                    hideCode={hideCode}
                    isCodeHidden={isCodeHidden}
                    onChangeCode={onChangeCode}
                    updateSecretCode={updateSecretCode}
                />
            </Stack>
            <Stack mt={5}>
                <SearchInput
                    searchText={searchText}
                    label={t(tokens.telegram.searchUsersByNickname)}
                    handleSearch={handleSearchText}
                />
            </Stack>
            <TabSelector
                tab={currentTab}
                tabOptions={tabOptions}
                handleTabsChange={handleTabsChange}
            />
            {
                usersFromTelegram.length ?
                    <UsersListTable
                        users={usersFromTelegram}
                        filters={filters}
                        pageAmount={usersAmount}
                        handlePageChange={handlePageChange}
                        handleDeleteUser={handleDeleteUser}
                    />
                    :
                    <Stack pb={10}>
                        <EmptyList
                            title={t(tokens.telegram.listEmpty)}
                            description={t(tokens.telegram.emptyDesc)}
                        />
                    </Stack>
            }
        </Stack>
    )
};