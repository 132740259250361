import type { FC } from 'react';
import { useCallback, useRef, useState } from 'react';
import { Avatar, Box, ButtonBase } from '@mui/material';
import { AccountPopover } from './account-popover';
import { getStyles } from './styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCurrentUser, setLogout, setVerify } from '../../store/auth/repository/actions';
import { useAuth } from '../../hooks/useAuth';

export const AccountButton: FC = () => {
  const styles = getStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const { setLoggedIn } = useAuth();

  const handleLogout = () => {
    dispatch(setLogout());
    localStorage.removeItem("accessToken");
    localStorage.removeItem('status-filter');
    localStorage.removeItem('method-filter');
    localStorage.removeItem('isDeleted-filter');
    localStorage.removeItem('chips');
    setLoggedIn(false);
    navigate('/');
  };

  const handlePopover = useCallback((): void => {
    setOpenPopover(openPopover => !openPopover);
  }, []);

  return (
    <>
      <Box component={ButtonBase} onClick={handlePopover} ref={anchorRef} sx={styles.account} >
        <Avatar sx={styles.avatar} >
          <img src='/images/account/account.png' alt='account' width={50} height={50} />
        </Avatar>
      </Box>
      <AccountPopover
        anchorEl={anchorRef.current}
        onClose={handlePopover}
        open={openPopover}
        handleLogout={handleLogout}
      />
    </>
  );
};
