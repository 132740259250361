import type { FC } from 'react';
import type { ListItemProps } from '@mui/material';
import { Box, ListItem, ListItemText, Typography } from '@mui/material';

type Direction = 'horizontal' | 'vertical';

interface IProps extends ListItemProps {
  align?: Direction;
  label: string;
  value?: string;
}

export const PropertyListItem: FC<IProps> = ({ align, children, disableGutters, value, label }) => {

  return (
    <ListItem
      sx={{ px: disableGutters ? 0 : 3, py: 1.5 }}>
      <ListItemText
        disableTypography
        primary={(
          <Typography sx={{ minWidth: align === 'vertical' ? 'inherit' : 180 }} variant="subtitle2" >
            {label}
          </Typography>
        )}
        secondary={(
          <Box sx={{ flex: 1, mt: align === 'vertical' ? 0.5 : 0 }}>
            {children || (
              <Typography color="text.secondary" variant="body2">
                {value}
              </Typography>
            )}
          </Box>
        )}
        sx={{ display: 'flex', flexDirection: align === 'vertical' ? 'column' : 'row', my: 0 }}
      />
    </ListItem>
  );
};
