import { IError } from "../../../types/error";
import { CalendarEvent } from "../../../types/payment-calendar";

export const setPayments = (payload: any) => ({
    type: "SET_PAYMENTS",
    payload
});

export const setFinanciers = (payload: any) => ({
    type: "SET_FINANCIERS",
    payload
});

export const setPaymentsList = (payload: any) => ({
    type: "SET_PAYMENTS_LIST",
    payload
});

export const setPaymentsWeek = (payload: any) => ({
    type: "SET_PAYMENTS_WEEK",
    payload
});

export const setLoading = (payload: boolean) => ({
    type: "SET_LOADING_PAYMENTS",
    payload
});

export const setGetPaymentsListError = (payload: IError) => ({
    type: "SET_PAYMENTS_LIST_ERROR",
    payload
});

export const setError = (payload: IError) => ({
    type: "SET_ERROR",
    payload
});

export const setVisaError = (payload: IError) => ({
    type: "SET_VISA_ERROR",
    payload
});

export const setErrorsGetPayments = (payload: IError) => ({
    type: "SET_ERRORS_GET_PAYMENTS",
    payload
});

export const setProcessPaymentError = (payload: IError) => ({
    type: "SET_PROCESS_PAYMENT_ERROR",
    payload
});

export const setSinglePayment = (payload: any) => ({
    type: "SET_SINGLE_PAYMENT",
    payload
});

export const setExpenditure = (payload: any[]) => ({
    type: "SET_EXPENDITURE",
    payload
});

export const setPaymentMethod = (payload: any[]) => ({
    type: "SET_PAYMENT_METHOD",
    payload
});

export const setPaymentKind = (payload: any[]) => ({
    type: "SET_PAYMENT_KIND",
    payload
});

export const setPaymentType = (payload: any[]) => ({
    type: "SET_PAYMENT_TYPE",
    payload
});

export const setPartners = (payload: any[]) => ({
    type: "SET_PARTNERS",
    payload
});

export const setServicePartners = (payload: any[]) => ({
    type: "SET_SERVICE_PARTNERS",
    payload
});

export const setPartnersAmount = (payload: number) => ({
    type: "SET_PARTNERS_AMOUNT",
    payload
});

export const setAccountList = (payload: any[]) => ({
    type: "SET_ACCOUNT_LIST",
    payload
});

export const setClinics = (payload: any[]) => ({
    type: "SET_CLINICS",
    payload
});

export const setBankCards = (payload: any[]) => ({
    type: "SET_BANK_CARDS",
    payload
});
