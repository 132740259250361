export const getStyles = () => {
    const styles = {
        inputDate: {
            cursor: 'pointer',
            width: '250px',
            height: '0px',
            border: '1px solid #36B355',
            borderRadius: '5px',
            fontSize: '14px'
        },
    };
    return styles;
};