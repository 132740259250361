import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./auth";
import { Provider } from "react-redux";
import { store } from "../store/store";
import { I18nextProvider } from "react-i18next";
import i18n from "../locales/i18n";
import ThemeManager from "../theme";
import { Toaster } from "../ui-kit/toaster/toaster";
import { FC, ReactNode } from "react";
import { SettingsProvider } from "./settings";

interface IProps{
    children: ReactNode;
};

const AppProvider: FC<IProps> = ({ children }) => {

    return (
        <BrowserRouter>
            <AuthProvider>
                <Provider store={store}>
                    <I18nextProvider i18n={i18n}>
                        <SettingsProvider>
                            <ThemeManager>
                                {children}
                                <Toaster />
                            </ThemeManager>
                        </SettingsProvider>
                    </I18nextProvider>
                </Provider>
            </AuthProvider>
        </BrowserRouter>
    )
};

export default AppProvider;
