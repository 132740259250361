import { Autocomplete, Stack, TextField, Theme, Typography, useMediaQuery } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { ICardOption, collectCardList } from "../../../../../../utils/collectOptionList";

interface IProps {
    title: string;
    value: any;
    handleChangeValue: any;
    isValid: boolean;
    optionList: any[];
    changeText: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const CardAutocomplete: FC<IProps> = ({ value, handleChangeValue, isValid, changeText, optionList, title }) => {
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    return (
        <Stack direction='column' alignItems='flex-start' spacing={1} width={'100%'}>
            <Typography color={isValid ? 'primary' : 'error'} variant={downSm ? 'body1' : 'h6'}>{title}</Typography>
            <Autocomplete
                disablePortal
                onChange={(e, value) => handleChangeValue(value)}
                value={value}
                options={collectCardList(optionList) as ICardOption[]}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(params, option): JSX.Element => (
                    <Typography {...params} key={option.id}>
                        {option.name}
                    </Typography>
                )}
                renderInput={(params): JSX.Element => (
                    <TextField
                        {...params}
                        fullWidth
                        error={!isValid}
                        onChange={changeText}
                        name={title}
                        variant="outlined"
                        label={title}
                    />
                )}
                clearIcon={false}
                sx={{ width: '100%' }}
                size="small"
            />
        </Stack>
    )
};