import { Pagination, Stack, Table, TableBody } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { UserListHead } from "../userListHead";
import { UserListItem } from "../userListItem";
import { IFilters } from "../../../presenters/types";

interface IProps {
    users: any[];
    filters: IFilters;
    pageAmount: number;
    handleDeleteUser: (phone: string) => void;
    handlePageChange: (event: ChangeEvent<unknown>, page: number) => void;
};

export const UsersListTable: FC<IProps> = ({ users, filters, pageAmount, handlePageChange, handleDeleteUser }) => {

    return (
        <Stack sx={{ my: 5 }}>
            <Table>
                <UserListHead />
                <TableBody>
                    {users.map((user: any) =>
                        <UserListItem
                            user={user}
                            tab={filters.step}
                            key={user.id + user.username}
                            handleDeleteUser={handleDeleteUser}
                        />
                    )}
                </TableBody>
            </Table>
            <Stack direction='row' justifyContent='center' mt={3}>
                <Pagination
                    page={filters.page}
                    count={pageAmount || 1}
                    color="primary"
                    onChange={handlePageChange}
                />
            </Stack>
        </Stack>
    )
};