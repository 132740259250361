import { TableBody } from "@mui/material";
import { FC, Fragment } from "react";
import { ImportsTableItem } from "../importsTableItem";
import { PaymentsTable } from "../paymentsTable";

interface IProps {
    uploadList: any[];
    isItemOpen: boolean;
    uploadPayments: any[];
    rowId: number;
    onChangeItemOpen: (id: number, isItemOpen: boolean) => void;
};

export const ImportsTableBody: FC<IProps> = ({ uploadList, isItemOpen, onChangeItemOpen, rowId, uploadPayments }) => {

    return (
        <TableBody>
            {uploadList.map((importItem: any) =>
                <Fragment key={importItem.name + importItem.id}>
                    <ImportsTableItem
                        importItem={importItem}
                        uploadPayments={uploadPayments}
                        rowId={rowId}
                        isItemOpen={isItemOpen}
                        onChangeItemOpen={onChangeItemOpen}
                    />
                    <PaymentsTable
                        importItem={importItem}
                        rowId={rowId}
                        isItemOpen={isItemOpen}
                        uploadPayments={uploadPayments}
                    />
                </Fragment>
            )}
        </TableBody>
    )
};