export const getStyles = () => {
    const styles = {
        noFilters: { 
            pl: 2, 
            height: '32px', 
            position: 'relative',
            top: '5px'
        },
        reset: { 
            borderRadius: '50px', 
            textTransform: 'none', 
            color: '#FFFFFF', 
            px: 2,
        },
    };
    return styles;
};