import { zodResolver } from '@hookform/resolvers/zod';
import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { config } from '../../../../config';
import { useGetClassesQuery } from '../../../../store/class';
import { useCreateFilesFixedAssetMutation, useCreateFixedAssetMutation, useDeleteFilesFixedAssetMutation, useGetFixedAssetQuery, useLazyGetInventoryNumberFixedAssetQuery, useUpdateFixedAssetMutation } from '../../../../store/fixedAsset';
import { useGetGroupsQuery } from '../../../../store/group';
import { useGetLocationsQuery } from '../../../../store/location';
import { useGetUsersQuery } from '../../../../store/user';
import { FileType } from '../../types';
import { downloadFileWithURL } from '../../utils/downloadFileWithURL';
import { upsertFixedAssetSchema } from '../validation';

dayjs.locale('uk');

function filterFilesByType(filesObject: any) {
    const filteredFiles = {} as any;
    for (const key in filesObject) {
        if (filesObject.hasOwnProperty(key) && Array.isArray(filesObject[key])) {
            filteredFiles[key] = filesObject[key].filter((file: any) => file instanceof File);
        }
    }
    return filteredFiles;
}

export const useUpsertFixedAsset = () => {
    const navigation = useNavigate();
    const { fixedAssetId } = useParams();
    const [searchParams] = useSearchParams();

    const [fileTypeTab, setFileTypeTab] = useState<FileType>("payment_files");
    const [groupNames, setGroupNames] = useState<string>('');
    const [classNames, setClassNames] = useState<string>('');
    const [locationNames, setLocationNames] = useState<string>('');
    const [userNames, setUserNames] = useState<string>('');
    const [defaultFilesFixedAsset, setDefaultFilesFixedAsset] = useState<any>({});

    const refDeleteFileIndex = useRef<number[]>([]);

    const [createFixedAsset, { isLoading: isLoadingCreateFixedAsset }] = useCreateFixedAssetMutation();
    const [createFileFixedAsset, { isSuccess: isSuccessCreateFileFixedAsset, isLoading: isLoadingCreateFileFixedAsset }] = useCreateFilesFixedAssetMutation();
    const [updateFileFixedAsset, { isLoading: isLoadingUpdateFileFixedAsset }] = useUpdateFixedAssetMutation();
    const [deleteFilesFixedAsset] = useDeleteFilesFixedAssetMutation();

    const { data: { rows: rowsDataGetGroup = [] } = {}, isFetching: isFetchingGetGroups } = useGetGroupsQuery({ limit: 20, offset: 0, search: groupNames });
    const { data: { rows: rowsDataGetClasses = [] } = {}, isFetching: isFetchingClasses } = useGetClassesQuery({ limit: 20, offset: 0, search: classNames });
    const { data: { rows: rowsGetLocations = [] } = {}, isFetching: isFetchingGetLocations } = useGetLocationsQuery({ limit: 20, offset: 0, search: locationNames });
    const { data: { rows: rowsGetUsersQuery = [] } = {}, isFetching: isFetchingGetUsers } = useGetUsersQuery({ limit: 20, offset: 0, search: userNames });

    const { data: { name = null, original_cost = null, group = {}, class: class_equipment = {}, manufacturer = null, location = {}, responsible = {}, production_date = null, guarantee_period = null, purchase_date = null,
        inventory_number: inventory_number_backend = null, serial_number = null, description = null, comment = null, payment_files = [], service_files = [], instruction_files = [], other_files = [] } = {},
        isLoading: isLoadingGetFixedAsset, isSuccess: isSuccessGetFixedAsset } = useGetFixedAssetQuery(Number(fixedAssetId), { refetchOnMountOrArgChange: true, skip: !fixedAssetId });
    const [getInventoryNumberFixedAsset, { data: { inventory_number: get_inventory_number = '' } = {}, isFetching: isFetchingGetInventoryNumberFixedAsset, isLoading: isLoadingGetInventoryNumberFixedAsset, isSuccess: isSuccessGetInventoryNumberFixedAsset }] = useLazyGetInventoryNumberFixedAssetQuery();

    const files = { payment_files, service_files, instruction_files, other_files };

    const { control, handleSubmit, watch, setValue, clearErrors, reset, formState: { errors, dirtyFields } } = useForm<upsertFixedAssetSchema>({
        resolver: zodResolver(upsertFixedAssetSchema),
        defaultValues: {
            name: searchParams.get("name") as string | undefined || '',
            inventory_number: searchParams.get("inventory_number") as string | undefined || '',
            serial_number: searchParams.get("serial_number") as string | undefined || '',
            class_id: null,
            group_id: null,
            location_id: null,
            responsible_id: null,
            production_date: null,
            guarantee_period: null,
            purchase_date: null,
            files: {
                payment_files: null,
                service_files: null,
                instruction_files: null,
                other_files: null
            }
        },
    });

    const { append: appendFiles, remove: removeFiles } = useFieldArray({
        control,
        name: `files.${fileTypeTab}`,
    });

    useEffect(() => {
        if (isSuccessGetFixedAsset) {
            reset({
                name,
                original_cost,
                serial_number,
                inventory_number: inventory_number_backend,
                manufacturer,
                comment,
                description,
                production_date: dayjs(production_date),
                guarantee_period: dayjs(guarantee_period),
                purchase_date: dayjs(purchase_date),
                group_id: { id: group?.id, value: group?.id, label: group?.name },
                class_id: { id: class_equipment?.id, value: class_equipment?.id, label: class_equipment?.name },
                location_id: { id: location?.id, value: location?.id, label: location?.name },
                responsible_id: { id: responsible?.id, value: responsible?.id, label: responsible?.full_name },
                files: {
                    payment_files,
                    service_files,
                    instruction_files,
                    other_files
                }
            });
            setDefaultFilesFixedAsset(files);
        }
    }, [isSuccessGetFixedAsset]);

    useEffect(() => {
        if (isSuccessGetInventoryNumberFixedAsset && !isFetchingGetInventoryNumberFixedAsset) {
            clearErrors('inventory_number');
            setValue('inventory_number', get_inventory_number);
        }
    }, [isSuccessGetInventoryNumberFixedAsset, isFetchingGetInventoryNumberFixedAsset]);

    useEffect(() => {
        if (isSuccessCreateFileFixedAsset) navigation('/fixed-assets');
    }, [isSuccessCreateFileFixedAsset]);

    const handleUpsertFixedAsset: SubmitHandler<upsertFixedAssetSchema> = (data) => {

        const { guarantee_period, purchase_date, production_date, group_id, location_id, responsible_id, class_id, original_cost, files, inventory_number, ...props } = data;

        const payload = {
            ...props,
            group_id: group_id?.id,
            location_id: location_id?.id,
            responsible_id: responsible_id?.id,
            class_id: class_id?.id,
            original_cost: Number(original_cost),
            production_date: dayjs(production_date).valueOf() + dayjs(production_date).utcOffset() * 1000 * 60,
            guarantee_period: dayjs(guarantee_period).valueOf() + dayjs(guarantee_period).utcOffset() * 1000 * 60,
            purchase_date: dayjs(purchase_date).valueOf() + dayjs(purchase_date).utcOffset() * 1000 * 60,
        };

        if (fixedAssetId) {
            if (refDeleteFileIndex.current?.length > 0) {
                const promises = refDeleteFileIndex.current.map((id: any) => deleteFilesFixedAsset({ fixedAssetId: Number(fixedAssetId), idFile: id }));
                Promise.all(promises).then().catch(error => { console.error(error); });
            }
            if (inventory_number_backend !== inventory_number) {
                updateFileFixedAsset({
                    id: Number(fixedAssetId),
                    params: {
                        ...payload,
                        inventory_number
                    }
                }).unwrap().then(() => {
                    const filesOfTypeFile = filterFilesByType(files);

                    if (`${filesOfTypeFile}.${fileTypeTab}`?.length === 0) return;

                    createFileFixedAsset({ id: Number(fixedAssetId), files });
                });
            } else {
                updateFileFixedAsset({
                    id: Number(fixedAssetId),
                    params: { ...payload }
                }).unwrap().then(() => {
                    const filesOfTypeFile = filterFilesByType(files);

                    if (`${filesOfTypeFile}.${fileTypeTab}`?.length === 0) return;

                    createFileFixedAsset({ id: Number(fixedAssetId), files: filesOfTypeFile });
                });
            }
            return;
        }

        createFixedAsset({
            ...payload,
            inventory_number,
        }).unwrap().then(({ id }) => {
            createFileFixedAsset({ id: id, files });
        });
    };

    let paymentFilesValue: any[] = [];
    if (watch('files') && fileTypeTab in watch('files')) {
        paymentFilesValue = watch('files.' + fileTypeTab as any);
    }

    const handleDeleteFile = (indexToRemove: number) => {
        removeFiles(indexToRemove);
    };

    const handleDeleteDefaultFile = ({ file_id, index }: any) => {
        removeFiles(index);

        const updatedFiles = {
            ...defaultFilesFixedAsset,
            [fileTypeTab]: defaultFilesFixedAsset[fileTypeTab].filter((_: any, i: number) => i !== index)
        };
        refDeleteFileIndex.current.push(file_id);
        setDefaultFilesFixedAsset(updatedFiles);
    };

    const handleChangeFileTypeTab = (_: SyntheticEvent, newValue: FileType) => {
        setFileTypeTab(newValue);
    };

    const handleCreateInventoryNumber = () => {
        getInventoryNumberFixedAsset();
    };

    const handleDownloadFile = ({ path, name }: { path: string; name: string; }) => {
        downloadFileWithURL({ path, name });
    };

    const handleChangeGroupName = (value: string): void => {
        setGroupNames(value);

    };
    const handleChangeClassName = (value: string): void => {
        setClassNames(value);

    };
    const handleChangeLocationName = (value: string): void => {
        setLocationNames(value);

    };
    const handleChangeUserName = (value: string): void => {
        setUserNames(value);

    };

    const debouncedHandleChangeGroupName = useDebouncedCallback(handleChangeGroupName, config.fixedAsset.debounceSearch);
    const debouncedHandleChangeClassName = useDebouncedCallback(handleChangeClassName, config.fixedAsset.debounceSearch);
    const debouncedHandleChangeLocationName = useDebouncedCallback(handleChangeLocationName, config.fixedAsset.debounceSearch);
    const debouncedHandleChangeUserName = useDebouncedCallback(handleChangeUserName, config.fixedAsset.debounceSearch);

    return {
        isLoadingGetFixedAsset, fixedAssetId, handleSubmit, handleUpsertFixedAsset, control, errors, debouncedHandleChangeGroupName, rowsDataGetGroup, isFetchingGetGroups, rowsDataGetClasses, isFetchingClasses,
        watch, rowsGetLocations, isFetchingGetLocations, debouncedHandleChangeLocationName, isFetchingGetUsers, rowsGetUsersQuery, debouncedHandleChangeUserName, dirtyFields, handleDeleteFile, handleDownloadFile,
        isLoadingGetInventoryNumberFixedAsset, handleCreateInventoryNumber, fileTypeTab, handleChangeFileTypeTab, defaultFilesFixedAsset, handleDeleteDefaultFile, paymentFilesValue, isLoadingUpdateFileFixedAsset,
        isLoadingCreateFixedAsset, isLoadingCreateFileFixedAsset, debouncedHandleChangeClassName, appendFiles
    };
};