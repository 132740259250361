import { FC, useMemo } from "react"
import { useRoleList } from "../presenters/useRoleList";
import { Button, Stack, SvgIcon, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../locales/tokens";
import { Link } from "react-router-dom";
import { getStyles } from "./styles";
import { RolesTable } from "./components/rolesTable";
import { paths } from "../../../../../paths";
import { useGetPermissions } from "../../../../../hooks/useGetPermission";

export const RoleList: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { roles, handleDeleteRole } = useRoleList();
    const { isCreate_Role } = useGetPermissions(); 

    return (
        <Stack m={2}>
            <Stack direction='row' justifyContent='space-between'>
                <Typography variant="h5" fontWeight={600}>
                    {t(tokens.user.rolesList)}
                </Typography>
                <Button
                    startIcon={(<SvgIcon><AddIcon /></SvgIcon>)}
                    variant="contained"
                    disabled={!isCreate_Role}
                    sx={styles.createBtn}
                    component={Link}
                    to={paths.dashboard.user.role.create}
                >
                    {t(tokens.common.create)}
                </Button>
            </Stack>
            <RolesTable
                roles={roles}
                handleDelete={handleDeleteRole}
            />
        </Stack>
    )
};