import { Checkbox, TableCell, TableHead, TableRow } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
    selectedAll: boolean;
    selectedSome: boolean;
    handleToggleAll: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const UsersTableHead: FC<IProps> = ({ selectedSome, selectedAll, handleToggleAll }) => {
    const { t } = useTranslation();

    return (
        <TableHead >
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        checked={selectedAll}
                        indeterminate={selectedSome}
                        onChange={handleToggleAll}
                    />
                </TableCell>
                <TableCell sx={{width: '25%'}} align="center">Ім'я</TableCell>
                <TableCell sx={{width: '20%'}} align="center">Посада</TableCell>
                <TableCell sx={{width: '20%'}} align="center">Телефон</TableCell>
                <TableCell sx={{width: '20%'}} align="center">Ролі</TableCell>
                <TableCell sx={{width: '15%'}} align="center">Дії</TableCell>
            </TableRow>
        </TableHead>
    )
}