import { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import { Stack, TextField, Theme, Typography, useMediaQuery } from "@mui/material";
import { useRegularPaymentForm } from "../../../presenters/useRegularPaymentForm";
import { FieldSelector } from "../../../../../../ui-kit/fieldSelector";
import { useSelector } from "react-redux";
import { selectPaymentCreation } from "../../../../../../store/paymentCreation/repository/selector";

interface IProps {
    amountValid: boolean;
};

export const RegularPaymentForm: FC<IProps> = ({ amountValid }) => {
    const { t } = useTranslation();
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const { handlePeriod, periodOptions, onChangeRegularAmount } = useRegularPaymentForm();
    const { regularAmount, regularPeriod } = useSelector(selectPaymentCreation);

    return (
        <>
            <Stack spacing={3} direction={downSm ? 'column' : 'row'} flex={1}>
                <Stack direction='column' spacing={1} flex={1}>
                    <FieldSelector
                        title={t(tokens.payment.period)}
                        label={t(tokens.payment.period)}
                        id="demo-simple-select-periodTime"
                        value={regularPeriod}
                        onChangeValue={handlePeriod}
                        required={false}
                        options={periodOptions}
                        isValid={true}
                    />
                </Stack>
                <Stack direction='column' spacing={1} flex={1}>
                    <Typography color={amountValid ? "primary" : "error"} variant={downSm ? 'body1' : 'h6'}>
                        {t(tokens.payment.paymentsAmount)}
                    </Typography>
                    <TextField
                        fullWidth
                        value={regularAmount}
                        onChange={onChangeRegularAmount}
                        label={t(tokens.payment.paymentsAmount)}
                        error={!amountValid}
                        size="small"
                    />
                </Stack>
            </Stack>
        </>
    )
};