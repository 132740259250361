
import toast from 'react-hot-toast';
import { requester } from '../../../libs/requester/requester-axios';
import { zooluxAPI } from '../../zooluxAPI';

interface IResponseGroup {
    count: number;
    rows: IGroup[];
}

interface IGroup {
    name: string;
    id: number;
    ref_key: string;
    updated_at: string;
    created_at: string | null;
    deleted_at: string;

}

const group = zooluxAPI.injectEndpoints({
    endpoints: (builder) => ({
        getGroups: builder.query<IResponseGroup, any>({
            async queryFn(_, { dispatch }) {
                try {
                    const { data, isError, message }: any = await requester.get('/zoolux-api/groups');
                    if (isError) {
                        toast.error(message);
                        throw new Error(message);
                    };
                    return { data };
                } catch (e) {
                    throw e;
                }
            },
            keepUnusedDataFor: 0
        }),

    }),
    overrideExisting: false,
});

export const { useGetGroupsQuery } = group;