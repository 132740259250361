import TableBody from '@mui/material/TableBody';
import { FC } from 'react';
import { PaymentsTableItem } from '../paymentsTableItem';

interface IProps {
    selected: readonly number[];
    payments: any[];
    handleClick: (event: React.MouseEvent<unknown>, id: number) => void;
};

export const PaymentsTableBody: FC<IProps> = ({ handleClick, selected, payments }) => {
    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    return (
        <TableBody>
            {payments?.map((row, index) => {
                const isItemSelected = isSelected(row.id);

                return (
                    <PaymentsTableItem
                        key={row.id}
                        isItemSelected={isItemSelected}
                        handleClick={handleClick}
                        payment={row}
                    />
                );
            })}
        </TableBody>
    );
};