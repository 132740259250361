import { FC, ReactNode, useEffect } from "react";
import { NavColor } from "../../../types/settings";
import { MenuSection } from "../../../types/settings";
import { Box, Theme, useMediaQuery } from "@mui/material";
import { SideNav } from "./components/side-nav/side-nav";
import { getStyles } from "./styles";
import { TopNav } from "./components/top-nav/top-nav";
import { useMobileNav } from "../../../hooks/useMobileNav";
import { useTheme } from "@emotion/react";
import { useLocation } from "react-router-dom";

interface IProps {
    children?: ReactNode;
    navColor?: NavColor;
    sections?: MenuSection[];
};

export const VerticalLayout: FC<IProps> = ({ children, sections, navColor }) => {
    const location = useLocation();
    const isNavBarHiden = location.pathname === '/';
    const theme = useTheme();
    const styles = getStyles(theme, isNavBarHiden);
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const { isOpen, handleOpen } = useMobileNav();

    return (
        <>
            <TopNav onNavOpen={handleOpen} isNavBarHiden={isNavBarHiden} />
            {isNavBarHiden ? null : <SideNav color={navColor} sections={sections} isOpen={isOpen} onNavOpen={handleOpen} />}
            <Box sx={styles.verticalLayoutRoot}>
                <Box sx={styles.verticalLayoutContainer}>
                    {children}
                </Box>
            </Box>
        </>
    );
};