import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";

interface IProps {
    title: string;
    value: string;
    options: string[];
    handleValue: (event: SelectChangeEvent) => void;
};

export const FieldSelect: FC<IProps> = ({ title, value, handleValue, options }) => {
    const { t } = useTranslation();

    return (
        <Stack width='100%'>
            <Typography color='primary' variant='h6'>{title}</Typography>
            <FormControl sx={{ mt: 1 }}>
                <InputLabel id="select-label">{title}</InputLabel>
                <Select
                    labelId="select-label"
                    id="select-small"
                    value={value}
                    label={title}
                    onChange={handleValue}
                    MenuProps={{
                        disableScrollLock: true,
                    }}
                >
                    {options.map(item => (
                        <MenuItem key={item} value={item}>{item}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Stack>
    )
};