import { type FC } from 'react';
import { TableCell, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../../locales/tokens';

interface IProps {
    item: any;
    handleExpenseItemOpen: (partnerId: string) => void;
};

export const TableItem: FC<IProps> = ({ item, handleExpenseItemOpen }) => {
    const { t } = useTranslation();

    return (
        <TableRow
            hover
            key={item.id}
            onClick={() => handleExpenseItemOpen?.(String(item.id))}
            sx={{ cursor: 'pointer' }}
        >
            <TableCell >
                <Typography >
                    {item.name_enote}
                </Typography>
            </TableCell>
            <TableCell >
                <Typography >
                    {item.name}
                </Typography>
            </TableCell>
            <TableCell align="center">
                <Typography >
                    {item.hidden ? t(tokens.account.hidden) : t(tokens.account.active)}
                </Typography>
            </TableCell>
        </TableRow>
    );
};
