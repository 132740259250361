import { Box, InputAdornment, OutlinedInput, SvgIcon } from "@mui/material";
import { ChangeEvent, FC, FormEvent } from "react";
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";

interface IProps {
    handleSearchTextChange: (event: FormEvent<HTMLFormElement>) => void;
    handleChangeSearch: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

export const SearchInput: FC<IProps> = ({ handleChangeSearch, handleSearchTextChange }) => {
    const { t } = useTranslation();

    return (
        <Box component="form" onSubmit={handleSearchTextChange} sx={{ flexGrow: 1 }}>
            <OutlinedInput
                size="small"
                defaultValue=""
                fullWidth
                onChange={handleChangeSearch}
                placeholder={t(tokens.payment.paymentsSearch)}
                startAdornment={(
                    <InputAdornment position="start">
                        <SvgIcon>
                            <SearchIcon />
                        </SvgIcon>
                    </InputAdornment>
                )}
            />
        </Box>
    )
}