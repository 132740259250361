import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServiceHistoryList } from "../../../../store/serviceHistory/useCases/getServiceHistoryList/action";
import { selectServiceHistory } from "../../../../store/serviceHistory/repository/selector";
import { PER_PAGE } from "../../../../config";
import { useDebounce } from "../../../../hooks/useDebounce";
import { SelectChangeEvent } from "@mui/material";

export const useServiceHistoryList = () => {
    const dispatch = useDispatch();
    const storedChips = JSON.parse(localStorage.getItem('chips-service') || '[]');
    const [chips, setChips] = useState<any[]>(storedChips);
    const storedStatus = JSON.parse(localStorage.getItem('status-service') || '[]');
    const [filters, setFilters] = useState<any>({ page: 1, rowsPerPage: PER_PAGE, search: '', search_name: 'COMMENT', status: storedStatus });
    const { serviceHistoryList, serviceHistoryCount } = useSelector(selectServiceHistory);
    const [searchText, setSearchText] = useState('');
    const [serviceAmount, setServiceAmount] = useState(0);

    useEffect(() => {
        dispatch(getServiceHistoryList(getRequestBody()));
    }, [filters]);

    useEffect(() => {
        if (serviceHistoryCount) {
            setServiceAmount(Math.ceil(serviceHistoryCount / filters.rowsPerPage));
        }
    }, [serviceHistoryCount]);

    const getRequestBody = () => {
        const body: any = {
            page: filters.page, 
            perPage: filters.rowsPerPage,
            search: filters.search
        };
        if(filters.status) body.status = filters.status;
        if(filters.search_name) body.search_name = filters.search_name;
        return body;
    };

    const handleSearchText = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        debouncedHandleSeacrh(e.target.value);
    };

    const onSearchService = (value: string) => {
        const search = value ? value : '';
        setFilters((prevState: any) => ({ ...prevState, page: 1, search }));
    };

    const { debouncedWrapper: debouncedHandleSeacrh } = useDebounce(onSearchService, 1000);

    const handlePageChange = useCallback((event: ChangeEvent<unknown>, page: number): void => {
        setFilters((prevState: any) => ({ ...prevState, page }));
    }, [setFilters]);

    const handleChipsDelete = useCallback((deletedChip: any): void => {
        if (deletedChip) {
            setChips((prevChips) => {
                return prevChips.filter((chip) => {
                    return !(
                        deletedChip.field === chip.field && deletedChip.value === chip.value
                    );
                });
            });
        } else {
            setFilters((prevState: any) => ({...prevState, status: []}));
            setChips([]);
            localStorage.removeItem('status-service');
        }
    }, []);

    const handleStatusPayments = useCallback((status: string[]) => {
        setFilters((prevState: any) => ({...prevState, status}));
        localStorage.setItem('status-service', JSON.stringify(status));
    }, [filters.status]);

    const handleSortChange = (event: SelectChangeEvent<any>): void => {
        setFilters((prevState: any) => ({...prevState, search_name: event.target.value }))
    };

    return {
        serviceHistoryList, serviceAmount, handlePageChange, handleSearchText, searchText, filters, handleChipsDelete, chips, setChips,
        handleStatusPayments, handleSortChange
    }
};