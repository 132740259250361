import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';

interface IState {
  isLoading: boolean;
  prepaymentList: any[];
  prepaymentCount: number;
};

const initialState: IState = {
  isLoading: false,
  prepaymentList: [],
  prepaymentCount: 0
};

const PrepaymentSlice = createSlice({
  name: 'prepayment',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setPrepaymentList: (state, action: PayloadAction<any>) => {
      state.prepaymentList = action.payload.list;
      state.prepaymentCount = action.payload.count;
    },
  },
});

export const { setLoading, setPrepaymentList } = PrepaymentSlice.actions;
export const PrepaymentReducer = PrepaymentSlice.reducer;