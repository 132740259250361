import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';

interface IState {
  isLoading: boolean;
  allAccounts: any[];
  inputAccounts: any[];
  accountsAmount: number;
  singleAccount: any;
  error: IError;
};

const initialState: IState = {
  allAccounts: [],
  inputAccounts: [],
  accountsAmount: 0,
  singleAccount: null,
  error: null,
  isLoading: false
};

const AccountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAllAccounts: (state, action: PayloadAction<any[] | []>) => {
      state.allAccounts = action.payload;
    },
    setInputAccounts: (state, action: PayloadAction<any[] | []>) => {
      state.inputAccounts = action.payload;
    },
    setAccountsAmount: (state, action: PayloadAction<number>) => {
      state.accountsAmount = action.payload;
    },
    setSingleAccount: (state, action: PayloadAction<any>) => {
      state.singleAccount = action.payload;
    },
    setSingleAccountError: (state, action: PayloadAction<IError>) => {
      state.error = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setAllAccounts, setAccountsAmount, setSingleAccount, setSingleAccountError, setLoading, setInputAccounts } = AccountSlice.actions;
export const AccountReducer = AccountSlice.reducer;