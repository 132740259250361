import { Divider, Stack, Theme, useMediaQuery } from "@mui/material";
import { ChangeEvent, FC, FormEvent, MutableRefObject, SyntheticEvent } from "react";
import { StatusType } from "../../../../../../types/users";
import { TabStatus } from "../tabStatus";
import { SortSelector } from "../sortSelector";
import { SearchInput } from "../searchInput";
import { getStyles } from "./styles";

interface IProps {
    currentTab: StatusType;
    searchTextRef: MutableRefObject<HTMLInputElement | null>;
    users: { id: number; name: string; phone: string; email: string; }[];
    handleSortChange: (event: ChangeEvent<HTMLInputElement>) => void;
    handleSearchTextChange: (event: FormEvent<HTMLFormElement>) => void;
    handleStatusChange: (event: SyntheticEvent<Element, Event>, value: any) => void;
    handleChangeSearch: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

export const UsersSearch: FC<IProps> = ({ handleSortChange, handleStatusChange, currentTab, searchTextRef, handleSearchTextChange, handleChangeSearch }) => {
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const styles = getStyles();

    return (
        <>
            <TabStatus
                currentTab={currentTab}
                handleStatusChange={handleStatusChange}
            />
            <Divider />
            <Stack direction="row" spacing={3} sx={styles.search} >
                <SearchInput
                    searchTextRef={searchTextRef}
                    handleSearchTextChange={handleSearchTextChange}
                    handleChangeSearch={handleChangeSearch}
                />
                {!downSm ? <SortSelector handleSortChange={handleSortChange} /> : null}
            </Stack>
        </>
    )
}