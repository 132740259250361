import { ChangeEvent, FC, useMemo } from "react";
import { Checkbox, Stack, TextField, Theme, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../locales/tokens";
import { getStyles } from "./styles";
import { TScreenFlag } from "../../modules/paymentCalendar/payment/ui/components/parts/paymentData/presenter/useServicePayment";

interface IProps {
    screenFlag?: TScreenFlag;
    isDisabled?: boolean;
    sum: string;
    pdv: string;
    checked: boolean;
    sumValid: boolean;
    pdvValid: boolean;
    onChangeChecked: () => void;
    onChangeSum: (event: ChangeEvent<HTMLInputElement>) => void;
    onChangePDV: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const FiledSum: FC<IProps> = ({ sum, onChangeSum, pdv, onChangePDV, sumValid, pdvValid, checked, onChangeChecked, screenFlag, isDisabled }) => {
    const { t } = useTranslation();
    const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const isDisabledField = screenFlag === "SERVICE" || isDisabled;
    const styles = useMemo(() => getStyles(), []);

    return (
        <Stack direction='column' sx={styles.container} spacing={1}>
            <Typography color={sumValid ? 'primary' : 'error'} variant={downMd ? 'body1' : 'h6'}>
                {t(tokens.payment.sum)}
            </Typography>
            <Stack direction="row" spacing={2} sx={styles.sum}>
                <TextField
                    fullWidth
                    type="number"
                    value={sum}
                    onChange={onChangeSum}
                    label={t(tokens.payment.sum)}
                    error={!sumValid}
                    size="small"
                    disabled={isDisabledField}
                />
                <Stack direction='row' alignItems='center'>
                    <Typography>{t(tokens.payment.pdv)}</Typography>
                    <Checkbox size="medium" checked={checked} onChange={onChangeChecked} disabled={isDisabledField}/>
                </Stack>
                <TextField
                    label={t(tokens.payment.pdv)}
                    disabled={checked ? false : true}
                    type="number"
                    value={pdv}
                    onChange={onChangePDV}
                    sx={styles.pdv}
                    error={!pdvValid && checked}
                    size="small"
                />
            </Stack>
        </Stack>
    )
};