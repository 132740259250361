import { Button, Stack, Typography } from "@mui/material"
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { getStyles } from "./styles";
import { TextInput } from "../../../contract/contractCreate/ui/components/textInput";
import { useServiceCreate } from "../presenters/useServiceCreate";

export const ServiceCreate: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { title, titleValid, onChangeTitle, nomenclature, nomenclatureValid, onChangeNomenclature, id, units, unitsValid, onChangeUnits, handleService } = useServiceCreate();

    return (
        <Stack my={3} width="600px" spacing={2}>
            <Typography variant="h4" pb={5}>
                { id ? t(tokens.service.editService) : t(tokens.service.createService)}
            </Typography>
            <TextInput
                title={t(tokens.service.title) + '*'}
                label={t(tokens.service.title)}
                isValid={titleValid}
                value={title}
                symbolsAmount={255}
                isMultiline={false}
                rows={3}
                onChangeValue={onChangeTitle}
            />
            <TextInput
                title={t(tokens.service.nomenclature) + '*'}
                label={t(tokens.service.nomenclature)}
                isValid={nomenclatureValid}
                value={nomenclature}
                symbolsAmount={255}
                isMultiline={true}
                rows={3}
                onChangeValue={onChangeNomenclature}
            />
            <TextInput
                title={t(tokens.service.units) + '*'}
                label={t(tokens.service.units)}
                isValid={unitsValid}
                value={units}
                isMultiline={true}
                rows={3}
                symbolsAmount={255}
                onChangeValue={onChangeUnits}
            />
            <Stack py={2}>
                <Button
                    variant="contained"
                    onClick={handleService}
                    sx={styles.createService}
                    size="large"
                >
                    {id ? t(tokens.common.edit) : t(tokens.common.create)}
                </Button>
            </Stack>
        </Stack>
    )
};