import { 
    Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, TextField, Theme, Typography, useMediaQuery
 } from "@mui/material";
import { Dispatch, FC, SetStateAction } from "react";
import { IBankCardOption, collectBankCardList } from "../../../utils/collectOptionList";
import { useDispatch } from "react-redux";
import { setBankCards } from "../../../store/paymentCalendar/repository/actions";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";
import { useBankCardAutocomplete } from "../presenter/useBankCardAutocomplete";
import { TScreenFlag } from "../../../modules/paymentCalendar/payment/ui/components/parts/paymentData/presenter/useServicePayment";

interface IProps {
    title: string;
    partnerId: any;
    paymentMethod: any;
    isValid: boolean;
    value: IBankCardOption | null;
    isDisabled: boolean;
};

export const BankCardAutocomplete: FC<IProps> = ({ isValid, value, partnerId, paymentMethod, title, isDisabled }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const { handleSubmit, handleOnChange, filterBankCardOptions, bankCards, open, handleClose, dialogValue, setDialogValue } = useBankCardAutocomplete(partnerId, paymentMethod);

    return (
        <Stack direction='column' alignItems='flex-start' spacing={1} width='100%' >
            <Typography color={isValid ? 'primary' : 'error'} variant={downSm ? 'body1' : 'h6'}>{title}</Typography>
            <Autocomplete
                disablePortal
                disabled={!partnerId || isDisabled}
                onChange={(e, value) => handleOnChange(e, value)}
                value={value}
                options={collectBankCardList(bankCards) as IBankCardOption[]}
                filterOptions={filterBankCardOptions}
                getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                        return option;
                    }
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option.account;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(props, option) => <li {...props}>{option.account}</li>}
                freeSolo
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label={title}
                        error={!isValid}
                        helperText={
                            !isValid && paymentMethod === 'card' ? 'Рахунок повинен мати 16 цифр' : null
                        } />}
                clearIcon={false}
                sx={{ width: '100%' }}
                size="small"
            />
            <Dialog open={open} onClose={handleClose}>
                <form onSubmit={handleSubmit}>
                    <DialogTitle textAlign='center'>{t(tokens.payment.addCardOrAccount)}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t(tokens.payment.addCardInfo)}
                        </DialogContentText>
                        <Stack direction='column' spacing={2} mt={2}>
                            <TextField
                                disabled={paymentMethod === 'card'}
                                autoFocus
                                margin="dense"
                                id={t(tokens.payment.card)}
                                value={dialogValue.account}
                                onChange={(event) => setDialogValue({ ...dialogValue, account: event.target.value, })}
                                label={t(tokens.payment.card)}
                                type="text"
                                variant="standard"
                            />
                            <TextField
                                margin="dense"
                                id={t(tokens.payment.bank)}
                                value={dialogValue.bank_name}
                                onChange={(event) => setDialogValue({ ...dialogValue, bank_name: event.target.value, })}
                                label={t(tokens.payment.bank)}
                                variant="standard"
                            />
                            {paymentMethod !== 'card' &&
                            <TextField
                                margin="dense"
                                id={t(tokens.payment.mfo)}
                                value={dialogValue.bank_mfo}
                                onChange={(event) => setDialogValue({ ...dialogValue, bank_mfo: event.target.value, })}
                                label={t(tokens.payment.mfo)}
                                variant="standard"
                            />}
                            <TextField
                                margin="dense"
                                id={t(tokens.payment.receiver)}
                                value={dialogValue.recipient_name}
                                onChange={(event) => setDialogValue({ ...dialogValue, recipient_name: event.target.value, })}
                                label={t(tokens.payment.receiver)}
                                variant="standard"
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>{t(tokens.common.cancel)}</Button>
                        <Button
                            type="submit"
                            onClick={() => dispatch(setBankCards([...bankCards, { account: dialogValue.account, bank_name: dialogValue.bank_name, bank_mfo: dialogValue.bank_mfo, recipient_name: dialogValue.recipient_name }]))}
                        >
                            {t(tokens.payment.addCard)}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Stack>
    )
};