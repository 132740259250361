import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";
import { SearchInput } from "../../../ui-kit/searchInput/searchInput";
import { useNomenclature } from "../presenter/useNomenclature";
import { SearchSelector } from "./components/searchSelector";
import { EmptyList } from "../../../ui-kit/emptyList";
import { NomenclatureTable } from "./components/nomenclatureTable";

export const NomenclatureList: FC = () => {
    const { t } = useTranslation();
    const { searchText, handleSearchText, filters, handleSortChange, nomenclatureList, nomenclatureAmount, handlePageChange, handleUpdateNomenclature,
        currentPage } = useNomenclature();
        
    return (
        <Stack>
            <Stack direction='row' justifyContent='space-between' mt={3}>
                <Typography variant="h4">{t(tokens.nomenclature.nomenclatureList)}</Typography>
            </Stack>
            <Stack direction="row" spacing={1} width="100%" mt={2}>
                <SearchInput searchText={searchText} label={t(tokens.nomenclature.nomenclatureSearch)} handleSearch={handleSearchText} />
                <SearchSelector value={filters.search_name} handleSortChange={handleSortChange} />
            </Stack>
            {nomenclatureList?.length > 0 ?
                <NomenclatureTable
                    nomenclatureList={nomenclatureList}
                    currentPage={currentPage}
                    pageAmount={nomenclatureAmount}
                    handlePageChange={handlePageChange}
                    handleUpdateNomenclature={handleUpdateNomenclature}
                />
                :
                <EmptyList
                    title={t(tokens.nomenclature.emptyList)}
                    description={t(tokens.nomenclature.emptyDesc)}
                />
            }
        </Stack>
    )
};