import { FC } from "react";
import { PAYMENT_METHOD } from "../../../../../../../../config";
import { NonCashPayment } from "../../../nonCashPayment";
import { CashPayment } from "../../../cashPayment";
import { CardPayment } from "../../../cardPayment";
import { TScreenFlag } from "../presenter/usePaymentData";

interface IProps {
    paymentMethod: any;
    status: any;
    modeFilter: any;
    user: any;
    payment: any;
    screenFlag: TScreenFlag;
};

export const PaymentData: FC<IProps> = ({ paymentMethod, modeFilter, payment, status, user, screenFlag }) => {
    
    return (
        <>
            {paymentMethod?.key === PAYMENT_METHOD.NONCASH &&
                <NonCashPayment paymentStatus={status} modeFilter={modeFilter} user={user} currentPayment={payment} screenFlag={screenFlag}/>}
            {paymentMethod?.key === PAYMENT_METHOD.CASH &&
                <CashPayment paymentStatus={status} modeFilter={modeFilter} user={user} currentPayment={payment} screenFlag={screenFlag}/>}
            {paymentMethod?.key === PAYMENT_METHOD.CARD &&
                <CardPayment paymentStatus={status} modeFilter={modeFilter} user={user} currentPayment={payment} screenFlag={screenFlag}/>}
        </>
    )
};