import { TableCell, TableRow, Typography } from "@mui/material";
import { FC, useCallback } from "react";
import dayjs from "dayjs";

interface IProps {
    payment: any;
    navigateToPayment: (id: string) => void;
};

export const PaymentsListItem: FC<IProps> = ({ payment, navigateToPayment }) => {
    const paymentDate = dayjs(payment.payment_date).locale('uk').format('MMMM D, YYYY');
    const navigateToEditPayment = useCallback(() => navigateToPayment(payment.id), [payment.id]);

    return (
        <TableRow
            key={payment.id}
            onClick={navigateToEditPayment}
            sx={{ cursor: 'pointer' }}
        >
            <TableCell align="center" >
                <Typography variant="subtitle2">
                    {payment.name}
                </Typography>
            </TableCell>
            <TableCell align="center" >
                <Typography variant="subtitle2">
                    {payment.company_account?.name}
                </Typography>
            </TableCell>
            <TableCell align="center" >
                <Typography variant="subtitle2">
                    {payment.counterparty.name}
                </Typography>
            </TableCell>
            <TableCell align="center" >
                <Typography variant="subtitle2">
                    {payment.payment_method.name}
                </Typography>
            </TableCell>
            <TableCell align="center" >
                <Typography variant="subtitle2">
                    {paymentDate}
                </Typography>
            </TableCell>
        </TableRow>
    )
};