import { SagaIterator } from "redux-saga";
import { put, call, takeEvery } from "redux-saga/effects";
import { requester } from "../../../../libs/requester/requester-axios";
import { IResponse } from "../../../../libs/requester/IResponse";
import { setLoading, setDeleteServiceRelatedError } from "../../repository/slice";
import toast from "react-hot-toast";

function* deleteServiceRelatedWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, isError, message }: IResponse<any> = yield call(requester.delete, "/zoolux-api/service-related/" + payload.id);
        if (isError) {
            yield put(setDeleteServiceRelatedError(message));
        } else {
            yield put(setDeleteServiceRelatedError(null));
            toast.success("Супутня витрата видалена");
        }
    } catch (error) {
        console.error("deleteServiceRelatedWorker: ", error);
    } finally {
        yield put(setLoading(false));
    }
};

export function* deleteServiceRelatedWatcher() {
    yield takeEvery("@saga/DELETE_SERVICE_RELATED", deleteServiceRelatedWorker);
};