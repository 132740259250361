import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";
import { addPermissionsToRole } from "../addPermissionsToRole/action";

function* editRoleWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.put, "/zoolux-api/roles", payload.body);
        if (isError) {
            toast.error(message);
        } else {
            yield put(addPermissionsToRole({ role_id: payload.body.id, permission_ids: payload.permission }));
        }
    } catch (error) {
        console.error("editRoleWorker: ", error);
    } finally {

    };
};

export function* editRoleWatcher() {
    yield takeLatest("@saga/EDIT_ROLE", editRoleWorker);
};
