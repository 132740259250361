import { SagaIterator } from "redux-saga";
import { put, call, takeEvery } from "redux-saga/effects";
import { requester } from "../../../../libs/requester/requester-axios";
import { IResponse } from "../../../../libs/requester/IResponse";
import { setLoading, setCreateServicePaymentError, setSingleService } from "../../repository/slice";
import { setCreateDate, setFormData, setInitiator } from "../../../paymentCreation/repository/slice";
import toast from "react-hot-toast";
import { addFile } from "../../../paymentCalendar/useCases/addFile/action";

function* createServicePaymentWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, isError, message }: IResponse<any> = yield call(requester.post, "/zoolux-api/services", payload.body);
        if (isError) {
            yield put(setCreateServicePaymentError(message));
        } else {
            yield put(setCreateServicePaymentError(null));
            yield put(setFormData({ id: data.receipt_goods_services.id, status: data.receipt_goods_services.status }));
            yield put(setInitiator(data.receipt_goods_services.initiator));
            yield put(setCreateDate(data.receipt_goods_services.createdAt));  
            if(!payload.isPaymentShown){
                yield put(setSingleService(data));
            };
            toast.success('Послуга створена');

            if (payload.files.length) {
                yield put(addFile({ body: { id: data.payment.id, type: 'DEFAULT', files: payload.files }, mode: payload.modeFilter, isUpdate: false }));
            };
            if (payload.confirmPaymentFiles.length) {
                yield put(addFile({ body: { id: data.payment.id, type: 'CONFIRM', files: payload.confirmPaymentFiles }, mode: payload.modeFilter, isUpdate: false }));
            };
        }
    } catch (error) {
        console.error("createServicePaymentWorker: ", error);
    } finally {
        yield put(setLoading(false));
    }
};

export function* createServicePaymentWatcher() {
    yield takeEvery("@saga/CREATE_SERVICE_PAYMENT", createServicePaymentWorker);
};