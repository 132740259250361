export const getStyles = () => ({
    mainWrapper: {
        rowGap: "24px",
        pt: "24px",
        pb: "60px"
    },
    cardInfo: {
        padding: "12px 8px",
        display: "flex",
        justifyContent: "space-between"
    },
    cardFile: {
        display: "flex",
        justifyContent: "space-between",
        columnGap: "9px"
    },
    tabList: {
        fontWeight: 400,
        textWrap: "nowrap"
    }
});