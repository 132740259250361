export const getStyles = ({ isOnePage = true, isEmptyState }: { isOnePage: boolean; isEmptyState: boolean; }) => ({
    tableContainer: {
        boxShadow: "none",
        width: '100%',
        position: "relative",
        overflowX: 'scroll',
        height: isOnePage ? `calc(100vh - 64px - 60px - 44px - 16px - 8px - 32px - 2.032rem - ${isEmptyState ? "5rem" : "6.25rem"})` : "calc(100vh - 64px - 60px - 44px - 16px - 8px - 32px - 1px - 5.5rem)"
    },
    headerSection: {
        backgroundColor: "white",
        position: "sticky",
        top: "64px",
        mx: "-2.5px",
        pb: 2,
        rowGap: 2
    }
});