import { Box, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search-icon.svg';
import { tokens } from '../../../../locales/tokens';
import { CircularProgress, HeadingTitle } from '../../../../uiKit';
import { useLocations } from '../presenters';
import { getStyles } from './styles';

export const Locations: FC = () => {
    const { t } = useTranslation();

    const { handleSearchLocation, isLoadingGetLocations, isFetchingGetLocations, rowsGetLocations, count, paramsLocation: { limit }, handlePageChange } = useLocations();

    const { tableContainer, headerSection } = getStyles({ isOnePage: !(count / limit > 1) });

    return (
        <Stack>
            <Stack sx={{ ...headerSection }}>
                <HeadingTitle title={t(tokens.location.title)} />
                <TextField onChange={handleSearchLocation} placeholder={t(tokens.location.searchInputPlaceholder)} className="inputBase" InputProps={{ startAdornment: <SearchIcon /> }} />
            </Stack>
            <TableContainer component={Paper} sx={tableContainer}>
                <Table stickyHeader aria-label="sticky table" className='table' sx={{ width: "100%" }}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ minWidth: "200px", maxWidth: "200px" }}>Назва</TableCell>
                            <TableCell style={{ minWidth: "400px", maxWidth: "400px" }}>Адреса</TableCell>
                            <TableCell align="center">Кількість</TableCell>
                            <TableCell align="center">Дата</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ overflow: "hidden" }}>
                        {isLoadingGetLocations || isFetchingGetLocations
                            ? <TableRow style={{ boxShadow: "none" }}>
                                <TableCell>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                            : rowsGetLocations.length > 0
                                ? rowsGetLocations?.map(({ id, name, address }) => (
                                    <TableRow key={id} hover>
                                        <TableCell style={{ minWidth: "200px", maxWidth: "200px" }}>
                                            <Tooltip title={name}>
                                                <Box component={"span"}>{name}</Box>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell style={{ minWidth: "400px", maxWidth: "400px" }}>
                                            <Tooltip title={address}>
                                                <Box component={"span"}>{address}</Box>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="center">200*</TableCell>
                                        <TableCell align="center">Дата*</TableCell>
                                    </TableRow>
                                ))
                                : <TableRow style={{ boxShadow: "none" }}>
                                    <TableCell>
                                        <Box position={"absolute"} top="50%" left="50%" sx={{ transform: "translate(-50%,-50%)" }}>Нічого не знайдено</Box>
                                    </TableCell>
                                </TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
            {count / limit > 1
                && <Pagination onChange={handlePageChange} count={Math.ceil(count / limit)} shape="rounded" className='pagination' sx={{ paddingY: 1.5, alignSelf: "center" }} />}
        </Stack >
    );
};
