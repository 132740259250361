export const getStyles = () => {
    const styles = {
        formButton: { 
            color: '#FFFFFF', 
            p: 0, 
            m: 0, 
            height: '40px',
            textTransform: 'none' 
        }
    };
    return styles;
};
