import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRoles } from "../../../../../store/users/usecases/getRoles/action";
import { selectUsers } from "../../../../../store/users/repository/selector";
import { deleteRole } from "../../../../../store/users/usecases/deleteRole/action";
import { setRoles } from "../../../../../store/users/repository/actions";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../locales/tokens";

export const useRoleList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { roles, isLoading, deleteRoleError } = useSelector(selectUsers);
    const [deleteClick, setDeleteClick] = useState(false);

    useEffect(() => {
        dispatch(getRoles());
        return () => { setRoles([]) };
    }, []);

    useEffect(() => {
        if (!isLoading && deleteClick) {
            if (deleteRoleError) {
                toast.error(deleteRoleError)
            } else {
                toast.success(t(tokens.user.messages.roleRemoved));
                dispatch(getRoles());
            }
            setDeleteClick(false);
        }
    }, [deleteRoleError, isLoading, deleteClick]);

    const handleDeleteRole = useCallback((id: number) => {
        setDeleteClick(true);
        dispatch(deleteRole({ id }));
    }, []);

    return { roles, handleDeleteRole };
};