import { LoadingButton, TabContext, TabList } from '@mui/lab';
import { Box, Button, Card, FormHelperText, Grid, InputAdornment, MenuItem, Select, Stack, Tab, TextField, Typography } from '@mui/material';
import 'dayjs/locale/uk';
import { ChangeEvent, FC } from 'react';
import { Controller, FieldError } from 'react-hook-form';
import { ReactComponent as AddIcon } from '../../../../assets/icons/add-icon.svg';
import { AutocompleteController, CircularProgress, DatePicker, FormControlLayout, HeadingTitle } from '../../../../uiKit';
import { TabPanel } from '../../components/tabPanel';
import { FILE_TYPE_TABS_CREATE_FIXED_ASSET } from '../../constants';
import { useUpsertFixedAsset } from '../presenters';
import { CardFileFixedAsset } from './components/cardFileFixedAsset';
import { VisuallyHiddenInput, getStyles } from './styles';
import { CurrencyInputProps } from './components/currencyInputProps';
import { v4 as uuidv4 } from 'uuid';

export const UpsertFixedAsset: FC = () => {

    const { isLoadingGetFixedAsset, fixedAssetId, handleSubmit, handleUpsertFixedAsset, control, errors, debouncedHandleChangeGroupName, rowsDataGetGroup, isFetchingGetGroups, rowsDataGetClasses, isFetchingClasses,
        debouncedHandleChangeClassName, watch, rowsGetLocations, isFetchingGetLocations, debouncedHandleChangeLocationName, isFetchingGetUsers, rowsGetUsersQuery, debouncedHandleChangeUserName, dirtyFields, handleDeleteFile,
        handleDownloadFile, isLoadingGetInventoryNumberFixedAsset, handleCreateInventoryNumber, fileTypeTab, handleChangeFileTypeTab, defaultFilesFixedAsset, handleDeleteDefaultFile, paymentFilesValue, isLoadingUpdateFileFixedAsset,
        isLoadingCreateFixedAsset, isLoadingCreateFileFixedAsset, appendFiles
    } = useUpsertFixedAsset();

    const { mainWrapper, gridContainer, gridItem, cardFormInputs, wrapperCardFormInputs, numberInput, secondVariantInput, countTextarea, datePicker } = getStyles({ isLoadingGetFixedAsset });

    return (
        <Stack position={"relative"} sx={mainWrapper}>
            <HeadingTitle title={(fixedAssetId ? "Оновлення" : "Створення") + " основного засобу"} />
            {isLoadingGetFixedAsset
                ? <CircularProgress />
                : <Box component={"form"} onSubmit={handleSubmit(handleUpsertFixedAsset)}>
                    <Grid container spacing={4} sx={gridContainer}>
                        <Grid item xs={12} lg={6} sx={gridItem}>
                            <FormControlLayout name={"name"} label="Назва товара" control={control} >
                                <TextField error={!!errors?.name} helperText={(errors?.name as FieldError | undefined)?.message || ''} placeholder='Введіть назву' className="inputBase inputPrimary" />
                            </FormControlLayout>
                            <FormControlLayout name={"original_cost"} label="Сума" control={control} >
                                <TextField type='number' placeholder="Введіть сумму" className="inputBase inputPrimary" error={!!errors?.original_cost} helperText={(errors?.original_cost as FieldError | undefined)?.message || ''}
                                    sx={numberInput} InputProps={{ style: { paddingRight: 0 }, endAdornment: <CurrencyInputProps /> }} />
                            </FormControlLayout>
                            <Card className='card' sx={cardFormInputs}>
                                <Stack sx={wrapperCardFormInputs}>
                                    <FormControlLayout label="Група">
                                        <AutocompleteController handleInputChange={debouncedHandleChangeGroupName} placeholder={"Оберіть групу"} name={"group_id"} control={control}
                                            options={rowsDataGetGroup?.map(({ name, id }: any) => ({ label: name, value: id, id }))} loading={isFetchingGetGroups} InputProps={{ style: { background: "white" } }} sx={[]} />
                                    </FormControlLayout>
                                    <FormControlLayout label="Клас">
                                        <AutocompleteController placeholder={"Оберіть клас"} name={"class_id"} control={control} options={rowsDataGetClasses?.map(({ name, id }: any) => ({ label: name, value: id, id }))} loading={isFetchingClasses}
                                            InputProps={{ style: { background: "white" } }} handleInputChange={debouncedHandleChangeClassName} defaultValue="" />
                                    </FormControlLayout>
                                </Stack>
                                <Stack sx={wrapperCardFormInputs}>
                                    <FormControlLayout name={"manufacturer"} label="Виробник" control={control} >
                                        <TextField error={!!errors?.manufacturer} helperText={(errors?.manufacturer as FieldError | undefined)?.message || ''} placeholder='Введіть виробника' className="inputBase" sx={secondVariantInput} />
                                    </FormControlLayout>
                                </Stack>
                            </Card>
                            <FormControlLayout name={"description"} label="Опис товару" control={control} >
                                <TextField error={!!errors?.description} helperText={(errors?.description as FieldError | undefined)?.message || ''} placeholder='Опис товару' className='inputBase textareaBase' multiline minRows={2} maxRows={7} />
                                <Box sx={countTextarea} component="span">{watch('description')?.length || 0}/512</Box>
                            </FormControlLayout>
                            <FormControlLayout name={"comment"} label="Коментар" control={control} >
                                <TextField error={!!errors?.comment} helperText={(errors?.comment as FieldError | undefined)?.message || ''} placeholder="Коментар до товару" className='inputBase textareaBase' multiline minRows={2} maxRows={7} />
                                <Box sx={countTextarea} component="span">{watch('comment')?.length || 0}/512</Box>
                            </FormControlLayout>
                        </Grid>
                        <Grid item xs={12} lg={6} sx={gridItem}>
                            <FormControlLayout label="Місце знаходження" >
                                <AutocompleteController placeholder={"Місце знаходження"} name={"location_id"} options={rowsGetLocations?.map(({ name, id }: any) => ({ label: name, value: id, id }))}
                                    control={control} loading={isFetchingGetLocations} handleInputChange={debouncedHandleChangeLocationName} defaultValue="" sx={[]} />
                            </FormControlLayout>
                            <FormControlLayout label="Оберіть відповідального" >
                                <AutocompleteController placeholder={"Оберіть відповідального"} name={"responsible_id"} control={control} loading={isFetchingGetUsers}
                                    options={rowsGetUsersQuery?.map(({ full_name, id }: any) => ({ label: full_name, value: id, id }))} handleInputChange={debouncedHandleChangeUserName} defaultValue="" sx={[]} />
                            </FormControlLayout>
                            <Card className='card' sx={cardFormInputs}>
                                <Stack sx={wrapperCardFormInputs}>
                                    <FormControlLayout name={"serial_number"} label="Серійний номер" control={control}>
                                        <TextField placeholder='00000000' className="inputBase" error={!!errors?.serial_number} helperText={(errors?.serial_number as FieldError | undefined)?.message || ''} sx={secondVariantInput} />
                                    </FormControlLayout>
                                    <FormControlLayout label="Дата виготовлення">
                                        <DatePicker name="production_date" control={control} sx={datePicker} />
                                    </FormControlLayout>
                                </Stack>
                                <Stack sx={wrapperCardFormInputs}>
                                    <FormControlLayout label="Гарантійний термін">
                                        <DatePicker name="guarantee_period" control={control} sx={datePicker} />
                                    </FormControlLayout>
                                    <FormControlLayout label="Дата покупки">
                                        <DatePicker name="purchase_date" control={control} sx={datePicker} />
                                    </FormControlLayout>
                                </Stack>
                            </Card>
                            <Stack direction={"row"} alignItems={"center"} columnGap={4}>
                                <FormControlLayout name={"inventory_number"} label="Інвентарний номер" control={control}>
                                    <TextField type="number" error={!!errors?.inventory_number} helperText={(errors?.inventory_number as FieldError | undefined)?.message || ''} placeholder='Введіть інвентарний номер' className="inputBase inputPrimary"
                                        sx={numberInput} disabled={isLoadingGetInventoryNumberFixedAsset} />
                                </FormControlLayout>
                                <LoadingButton sx={{ mt: !!errors?.inventory_number ? "unset" : "24px" }} loading={isLoadingGetInventoryNumberFixedAsset} onClick={handleCreateInventoryNumber} variant='outlineGreen'>Згенерувати</LoadingButton>
                            </Stack>
                            <Stack rowGap="16px">
                                <FormControlLayout label="Виберіть тип файлу" >
                                    <TabContext value={fileTypeTab} >
                                        <Stack rowGap={2}>
                                            <TabList variant="fullWidth" className='tabsMain' onChange={handleChangeFileTypeTab} aria-label="create fixed asset tabs">
                                                {FILE_TYPE_TABS_CREATE_FIXED_ASSET?.map(({ label, value }, index) => (
                                                    <Tab key={uuidv4()} value={value}
                                                        label={label + ` (${(Number(defaultFilesFixedAsset[`${value}`]?.length || 0)
                                                            + (dirtyFields?.files
                                                                && dirtyFields.files[value as keyof typeof dirtyFields.files]
                                                                ? Number(watch('files.' + value as any)?.length || 0) : 0))})`}
                                                        style={{ fontWeight: 400, textWrap: "nowrap" }} />
                                                ))}
                                            </TabList>
                                            <Stack rowGap="6px">
                                                <Button component="label" role={undefined} variant="outlineWhite" tabIndex={-1} startIcon={<AddIcon />} >
                                                    <Box sx={{ fontWeight: 500 }}>Додати файл</Box>
                                                    {FILE_TYPE_TABS_CREATE_FIXED_ASSET.map(({ value }, index) => (
                                                        <TabPanel key={uuidv4()} value={FILE_TYPE_TABS_CREATE_FIXED_ASSET.findIndex(item => item.value === fileTypeTab)} index={index} >
                                                            <Controller control={control} name={`files.` + fileTypeTab as any} defaultValue={null}
                                                                render={({ field }) => (
                                                                    <VisuallyHiddenInput name={`files.` + fileTypeTab as any} multiple hidden type={'file'} onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                                        const filesArray = Array.from(e.target.files as any);
                                                                        appendFiles(filesArray);
                                                                    }} />
                                                                )}
                                                            />
                                                        </TabPanel>
                                                    ))}
                                                </Button>
                                                {errors?.files && <Box color="error">{errors?.files && Object.values(errors.files).flatMap((error: any, index: number) => {
                                                    if (typeof error === 'object' && 'message' in error) {
                                                        return <FormHelperText key={uuidv4()} sx={{ color: "#d32f2f" }}>{error.message}</FormHelperText>;
                                                    }
                                                    return [];
                                                })}
                                                </Box>}
                                                <Typography component={"span"} sx={{ fontSize: "14px", color: "#8E8E93" }}>Максимальний розмір файла 25 мб </Typography>
                                            </Stack>
                                        </Stack>
                                        {FILE_TYPE_TABS_CREATE_FIXED_ASSET.map(({ value }, index) => (
                                            <TabPanel key={uuidv4()} value={FILE_TYPE_TABS_CREATE_FIXED_ASSET.findIndex(item => item.value === fileTypeTab)} index={index} >
                                                <Stack rowGap={"10px"}>
                                                    {defaultFilesFixedAsset[`${fileTypeTab}`]?.map(({ name, file_id, path }: any, index: any) => (
                                                        <CardFileFixedAsset key={uuidv4()} handleDeleteFile={() => handleDeleteDefaultFile({ file_id, index })} name={name} handleDownloadFile={() => handleDownloadFile({ path, name })} />
                                                    ))}
                                                    {paymentFilesValue?.map((file: any, index: any) => {
                                                        return file instanceof File ?
                                                            <CardFileFixedAsset key={uuidv4()} handleDeleteFile={() => handleDeleteFile(index)} name={file.name} /> : null;
                                                    })}
                                                </Stack>
                                            </TabPanel>
                                        ))}
                                    </TabContext>
                                </FormControlLayout>
                            </Stack>
                            <LoadingButton loading={isLoadingUpdateFileFixedAsset || isLoadingCreateFixedAsset || isLoadingCreateFileFixedAsset} type="submit" variant='containedGreen'>{fixedAssetId ? "Оновити" : "Створити"}</LoadingButton>
                        </Grid>
                    </Grid>
                </Box>}
        </Stack>
    );
};

