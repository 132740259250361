import { SagaIterator } from "redux-saga";
import { put, takeLatest, call } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";
import { addFile } from "../addFile/action";
import { setCreateDate, setFormData, setInitiator } from "../../../paymentCreation/repository/slice";

function* createPaymentWorker({ payload }: any): SagaIterator {
    try {
        const url = payload.modeFilter === 'Payment' ? "/zoolux-api/payments" : "/zoolux-api/budget-payments";
        const { data, isError, message }: IResponse<any> = yield call(requester.post, url, payload.body);
        if (isError) {
            toast.error(message);
        } else {
            toast.success("Платіж успішно створено");
            yield put(setFormData({ id: data.payment.id, status: data.payment.status }));
            yield put(setInitiator(data.payment.initiator));
            yield put(setCreateDate(data.payment.createdAt)); 

            if (payload.files.length) {
                yield put(addFile({ body: { id: data.payment.id, type: 'DEFAULT', files: payload.files }, mode: payload.modeFilter, isUpdate: false }));
            };
            if (payload.confirmPaymentFiles.length) {
                yield put(addFile({ body: { id: data.payment.id, type: 'CONFIRM', files: payload.confirmPaymentFiles }, mode: payload.modeFilter, isUpdate: false }));
            };
        }
    } catch (error) {
        console.error("createPaymentWorker: ", error);
    } finally {

    }
};

export function* createPaymentWatcher() {
    yield takeLatest("@saga/CREATE_PAYMENT", createPaymentWorker);
};
