import { useEffect, useState } from "react";
import { CALENDAR_MODE, PAYMENT_STATUS } from "../../../../../../../config";

export const useStepperMui = (status: string, mode: string) => {
    const steps = mode === CALENDAR_MODE.PAYMENT ? 
    ['Ініціалізація оплати', 'Створення оплати', 'Візування оплати', 'Проведення оплати']
    : ['Ініціалізація оплати', 'Створення оплати', 'Переведення заявки'];

    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        if(mode === CALENDAR_MODE.PAYMENT){
            choosePaymentStep();
        }else{
            chooseBudgetStep();
        }
    }, [status]);

    const choosePaymentStep = () => {
        switch (status) {
            case PAYMENT_STATUS.CREATED:
                setActiveStep(1);
                break;
            case PAYMENT_STATUS.VISAED:
                setActiveStep(2);
                break;
            case PAYMENT_STATUS.OPERATED:
                setActiveStep(3);
                break;
            default:
                break;
        }
    };

    const chooseBudgetStep = () => {
        switch (status) {
            case PAYMENT_STATUS.CREATED:
                setActiveStep(1);
                break;
            case PAYMENT_STATUS.TRANSMITTED:
                setActiveStep(2);
                break;
            case PAYMENT_STATUS.COMPLETED:
                setActiveStep(2);
                break;
            default:
                break;
        }
    };

    return { steps, activeStep }
}