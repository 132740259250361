import { store } from "../../store/store";
import { IResponse } from "./IResponse";
import Axios, { AxiosError, AxiosResponse } from "axios";

class AxiosRequester {
    private getHeaders = (headers: object = {}) => {
        const accessToken = globalThis.localStorage.getItem("accessToken");

        return {
            Authorization: "Bearer " + accessToken,
            ...headers,
        };
    };

    private async handleRequestError<T>(error: AxiosError<T>): Promise<AxiosResponse<T>> {
        if (error.response?.status === 401) {
            localStorage.removeItem("accessToken");
            localStorage.setItem("isLoggedIn", "false");
        }
        throw error;
    };

    post = async (url: string, data?: object, headers?: object, timeoutMS?: number): Promise<any> => {
        try {
            const config = { headers: this.getHeaders(headers) };
            const response = await Axios.post(process.env.REACT_APP_PUBLIC_URL + url, data, config)
                .catch(async (error: AxiosError) => await this.handleRequestError(error));
            const result = this.processingResponse(response);
            return result;
        } catch (error: any) {
            console.warn("AxiosRequester -> post: ", error);
            return { isError: true, message: error.message, error };
        }
    };

    put = async (url: string, data?: object, headers?: object, timeoutMS?: number): Promise<any> => {
        try {
            const config = { headers: this.getHeaders(headers) };
            const response = await Axios.put(process.env.REACT_APP_PUBLIC_URL + url, data, config)
                .catch(async (error: AxiosError) => await this.handleRequestError(error));
            const result = this.processingResponse(response);
            return result;
        } catch (error: any) {
            console.warn("AxiosRequester -> put: ", error);
            return { isError: true, message: error.message };
        }
    };

    patch = async (url: string, data?: object, headers?: object, timeoutMS?: number): Promise<any> => {
        try {
            const config = { headers: this.getHeaders(headers) };
            const response = await Axios.patch(process.env.REACT_APP_PUBLIC_URL + url, data, config)
                .catch(async (error: AxiosError) => await this.handleRequestError(error));
            const result = this.processingResponse(response);
            return result;
        } catch (error: any) {
            console.warn("AxiosRequester -> patch: ", error);
            return { isError: true, message: error.message };
        }
    };

    delete = async (url: string, data?: object, headers?: object, timeoutMS?: number): Promise<any> => {
        try {
            const config = { headers: this.getHeaders(headers), data };
            const response = await Axios.delete(process.env.REACT_APP_PUBLIC_URL + url, config)
                .catch(async (error: AxiosError) => await this.handleRequestError(error));
            const result = this.processingResponse(response);
            return result;
        } catch (error: any) {
            console.warn("AxiosRequester -> post: ", error);
            return { isError: true, message: error.message };
        }
    };

    get = async (url: string, params?: object, headers?: object, timeoutMS?: number): Promise<any> => {
        try {
            const config = { headers: this.getHeaders(headers), params };
            const response = await Axios.get(process.env.REACT_APP_PUBLIC_URL + url, config)
                .catch(async (error: AxiosError) => await this.handleRequestError(error));
            const result = this.processingResponse(response);
            return result;
        } catch (error: any) {
            console.warn("AxiosRequester -> get: ", error);
            return { isError: true, message: error.message };
        }
    };

    private processingResponse = (response: any): IResponse<any> => {
        let result: any = { isError: true, message: "", detailMessage: '' };
        if (response?.status < 400) {
            result = { isError: false, data: response.data, message: "" };
        } else if (response?.data?.error === "validation") {
            result = {
                isError: true,
                message: JSON.stringify(typeof response?.data?.message !== 'string' ? response?.data?.message[0] : response?.data?.message),
                detailMessage: response.data.detailMessage || ''
            };
        } else {
            result = {
                isError: true,
                message: JSON.stringify(typeof response?.data?.message !== 'string' ? response?.data?.message[0] : response?.data?.message),
                detailMessage: response.data.detailMessage || ''
            };
        }
        return result;
    };
}

export const requester = new AxiosRequester();
