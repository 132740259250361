import { Button, Stack, Theme, useMediaQuery } from "@mui/material";
import { FC, useMemo } from "react";
import { TimeDatePicker } from "../../../../../../../../ui-kit/timePicker";
import { useTranslation } from "react-i18next";
import { PAYMENT_STATUS } from "../../../../../../../../config";
import { useServiceProcessing } from "../presenter/useServiceProcessing";
import { tokens } from "../../../../../../../../locales/tokens";
import { getStyles } from "./styles";
import { useUserPermission } from "../../../../../../../payment-calendar/payment-create/presenters/useUserPermission";
import { IUser } from "../../../../../../../../types/users";
import { UpdateModal } from "../../../../../../../payment-calendar/payment-create/ui/components/updateModal";
import { ServiceModal } from "../../../../../../../payment-calendar/payment-create/ui/components/serviceModal";
import { useSelector } from "react-redux";
import { selectPaymentCreation } from "../../../../../../../../store/paymentCreation/repository/selector";
import { useGetPermissions } from "../../../../../../../../hooks/useGetPermission";
import { Link } from "react-router-dom";
import { paths } from "../../../../../../../../paths";

interface IProps {
    isPaymentShown: boolean;
    currentService: any;
    user: IUser;
    serviceStatus: string | undefined;
};

export const ServiceProcessing: FC<IProps> = ({ currentService, user, serviceStatus, isPaymentShown }) => {
    const { t } = useTranslation();
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const styles = useMemo(() => getStyles(downSm), [downSm]);
    const { formStatus, formId } = useSelector(selectPaymentCreation);
    const paymentId = currentService?.receipt_goods_services.id || formId;

    const { createOrEditPayment, time, payment, handleChangeVisa, isNotPutToEnot, isVisa,
        makePayment, isUpdatedPaymentModal, isOpenServiceModal, handleToggleServiceModal, handleToggleUpdateModal, validateAndProcessPayment,
        updatePaymentInModal, handleDateChange } = useServiceProcessing(isPaymentShown, paymentId);
    const { isInitiator, isVisaAllowed, isProcessAllowed, isCreate_In_Enote } = useUserPermission(user.roles);
    const status = formStatus || serviceStatus;
    const isCreator = currentService?.receipt_goods_services.initiator.id === user.id;

    return (
        <>
            {currentService?.payment && (
                <Stack my={1}><Link to={paths.dashboard.payment.edit.replace(':id', currentService?.payment.id)}>
                    {process.env.REACT_APP_PUBLIC_URL + paths.dashboard.payment.edit.replace(':id', currentService?.payment.id)}
                </Link></Stack>
            )}
            <Stack direction={downSm ? 'column' : 'row'} spacing={3} alignItems='flex-end' mt={3}>
                {status !== PAYMENT_STATUS.OPERATED ?
                    <>
                        {isPaymentShown ?
                            <TimeDatePicker value={time} isValid={payment.date} isdisabledPast={true} title={t(tokens.payment.payOnDay)} handleDate={handleDateChange} />
                            :
                            <Stack width="100%" />
                        }
                    </>
                    : <Stack width="100%" />}
                <Stack width='100%'>
                    {(paymentId && isInitiator) || (paymentId && isCreator) ?
                        <Button
                            sx={styles.formButton}
                            fullWidth
                            variant='contained'
                            onClick={() => createOrEditPayment(true)}
                            disabled={
                                status === PAYMENT_STATUS.OPERATED
                                || isInitiator && status === PAYMENT_STATUS.VISAED
                                || status === PAYMENT_STATUS.TRANSMITTED
                                || status === PAYMENT_STATUS.COMPLETED
                            }
                        >
                            Редагувати послугу
                        </Button> : null
                    }
                    {!paymentId ?
                        <Button
                            sx={styles.formButton}
                            fullWidth
                            variant='contained'
                            onClick={() => createOrEditPayment(true)}
                        >
                            Створити послугу
                        </Button> : null}
                </Stack>
            </Stack>
            {paymentId && isPaymentShown ?
                <Stack direction={downSm ? 'column' : 'row'} spacing={3} alignItems='flex-end' mt={2}>
                    <Stack flex={1} />
                    <Stack flex={1}>
                        <Button
                            sx={styles.formButton}
                            fullWidth
                            variant='contained'
                            onClick={makePayment}
                            disabled={
                                !(!!status)
                                || !isCreate_In_Enote
                                || status === PAYMENT_STATUS.OPERATED
                            }
                        >
                            Провести послугу
                        </Button>
                    </Stack>
                </Stack> : null}
            <UpdateModal
                title="Редагувати сервіс"
                description="Деякі дані було змінено. Ви бажаєте оновити сервіс перед операцією?"
                isOpen={isUpdatedPaymentModal}
                handleToggleModal={handleToggleUpdateModal}
                updatePaymentInModal={updatePaymentInModal}
            />
            <ServiceModal
                isOpen={isOpenServiceModal}
                handleToggleModal={handleToggleServiceModal}
                handleModal={validateAndProcessPayment}
            />
        </>
    )
};