import { Autocomplete, Box, TextField } from '@mui/material';
import 'dayjs/locale/uk';
import { FC } from 'react';
import { Controller, FieldError } from 'react-hook-form';

export const AutocompleteController: FC<any> = ({ name, control, options, label, loading, handleInputChange, defaultValue = '', sx = [], noOptionsText = "По запиту нічого не знайдено", placeholder, InputProps }) => (
    <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value, ref }, formState: { errors } }) => {
            return <Autocomplete
                className='autocomplete'
                sx={sx}
                value={value || null}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => (value === undefined && option === "") || (value !== undefined && option?.id === value?.id)}
                renderOption={(props, option, { selected }) => (
                    <Box component={'li'} {...props} key={option?.id} sx={{ overflowWrap: 'anywhere' }}>
                        {option?.label}
                    </Box>
                )}
                noOptionsText={noOptionsText}
                clearOnEscape={true}
                loading={loading}
                onInputChange={(e, value) => { handleInputChange && handleInputChange(value); }}
                onChange={(_, data) => onChange(data)}
                options={options}
                renderInput={(params) => <TextField {...params} InputProps={{ ...params.InputProps, ...InputProps }} className="inputBase inputPrimary"
                    placeholder={placeholder} label={label} inputRef={ref} error={!!errors[name]} helperText={(errors[name] as FieldError | undefined)?.message || (errors[name] as any | undefined)?.label?.message || ''} />}
            />;
        }
        }
    />
);