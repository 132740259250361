import { FC } from "react";
import { usePrepaymetList } from "../presenters/usePrepaymentList";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PrepaymentTable } from "./components/prepaymentTable";
import { TimePicker } from "./components/timePicker";
import { tokens } from "../../../locales/tokens";
import { EmptyList } from "../../../ui-kit/emptyList";

export const PrepaymentList: FC = () => {
    const { t } = useTranslation();
    const { prepaymentList, prepaymentAmount, handlePageChange, filters, handleDateChange } = usePrepaymetList();

    return (
        <Stack m={2}>
            <Stack direction='row' justifyContent='space-between' alignItems="center">
                <Typography variant="h5" fontWeight={600}>
                    {t(tokens.prepayment.prepayments)}
                </Typography>
                <TimePicker
                    value={filters.date}
                    handleDate={handleDateChange}
                />
            </Stack>
            {
                prepaymentList?.length ?
                    <PrepaymentTable
                        prepaymentList={prepaymentList}
                        page={filters.page}
                        pageAmount={prepaymentAmount}
                        handlePageChange={handlePageChange}
                    />
                    :
                    <EmptyList
                        title={t(tokens.common.emptyList)}
                        description={t(tokens.prepayment.emptyListDesc)}
                    />
            }
        </Stack>
    )
};