import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';

interface IState {
  isLoading: boolean,
  serviceList: any[],
  serviceListAll: any[],
  singleService: any,
  serviceCount: number,
  serviceCountAll: number,
  createServiceError: IError,
  updateServiceError: IError,
  deleteServiceError: IError,
  getServiceListError: IError,
};

const initialState: IState = {
  isLoading: false,
  serviceList: [],
  serviceListAll: [],
  singleService: null,
  serviceCount: 0,
  serviceCountAll: 0,
  createServiceError: null,
  updateServiceError: null,
  deleteServiceError: null,
  getServiceListError: null,
};

const ServiceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSingleService: (state, action: PayloadAction<any>) => {
      state.singleService = action.payload;
    },
    setCreateServiceError: (state, action: PayloadAction<IError>) => {
      state.createServiceError = action.payload;
    },
    setUpdateServiceError: (state, action: PayloadAction<IError>) => {
      state.updateServiceError = action.payload;
    },
    setDeleteServiceError: (state, action: PayloadAction<IError>) => {
      state.deleteServiceError = action.payload;
    },
    setServiceList: (state, action: PayloadAction<any>) => {
      state.serviceList = action.payload.list;
      state.serviceCount = action.payload.count;
    },
    setServiceListAll: (state, action: PayloadAction<any>) => {
      state.serviceListAll = action.payload.list;
      state.serviceCountAll = action.payload.count;
    },
    setGetServiceListError: (state, action: PayloadAction<IError>) => {
      state.getServiceListError = action.payload;
    },
  },
});

export const { setLoading, setServiceList, setSingleService, setCreateServiceError, setDeleteServiceError, setUpdateServiceError, setServiceListAll,
  setGetServiceListError } = ServiceSlice.actions;
export const ServiceReducer = ServiceSlice.reducer;