export const getStyles = () => {
    const styles = {
        container: { 
            width: '100%',
            mt: 2,
        },
        table: { 
            minWidth: 750 
        }
    };
    return styles;
};