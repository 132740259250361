export const getStyles = () => {
    const styles = {
        viewActive: {
            backgroundColor: '#36B355',
            color: '#FFFFFF',
            '&:hover': {
                backgroundColor: '#36B355'
            },
            textTransform: 'none'
        },
        viewNoActive: {
            backgroundColor: '#FEF49A',
            '&:hover': {
                backgroundColor: '#36B355',
                color: '#FFFFFF',
            },
            textTransform: 'none'
        },
    };
    return styles;
};