import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDebounce } from "../../../hooks/useDebounce";
import { SelectChangeEvent } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getNomenclatureList } from "../../../store/nomenclature/useCases/getNomenclatureList/action";
import { INomenclatureFilters } from "../../../store/nomenclature/repository/types";
import { selectNomenclature } from "../../../store/nomenclature/repository/selector";
import { editNomenclature } from "../../../store/nomenclature/useCases/editNomenclature/action";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";

export const useNomenclature = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const [isClick, setClick] = useState(false);
    const [filters, setFilters] = useState<INomenclatureFilters>({ offset: 0, limit: 30, search: '', search_name: 'CODE' });
    const { nomenclatureList, nomenclatureCount, nomenclatureError, isLoading } = useSelector(selectNomenclature);
    const [nomenclatureAmount, setNomenclatureAmount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1); 

    useEffect(() => {
        dispatch(getNomenclatureList(getRequestBody()));
    }, [filters]);

    useEffect(() => {
        if (nomenclatureCount) {
            setNomenclatureAmount(Math.ceil(nomenclatureCount / filters.limit));
        }
    }, [nomenclatureCount]); 

    useEffect(() => {
        if (!isLoading && isClick) {
            if (nomenclatureError) {
                toast.error(nomenclatureError);
            } else {
                toast.success(t(tokens.nomenclature.message.nomenclatureEdited));
                dispatch(getNomenclatureList(getRequestBody()));
            }
            setClick(false);
        }
    }, [nomenclatureError, isLoading]);

    const getRequestBody = () => {
        let body: INomenclatureFilters = { offset: filters.offset, limit: filters.limit, search: filters.search };
        if (filters.search_name) body.search_name = filters.search_name;
        return body;
    };

    const handleSearchText = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        debouncedHandleSeacrh(e.target.value);
    };

    const onSearchService = (value: string) => {
        const search = value ? value : '';
        setFilters((prevState) => ({ ...prevState, offset: 0, search }));
        setCurrentPage(1);
    };

    const { debouncedWrapper: debouncedHandleSeacrh } = useDebounce(onSearchService, 1000);

    const handleSortChange = (event: SelectChangeEvent<any>): void => {
        setFilters((prevState) => ({ ...prevState, offset: 0, search_name: event.target.value }));
        setCurrentPage(1);
    };

    const handlePageChange = useCallback((event: ChangeEvent<unknown>, page: number): void => {
        setFilters((prevState) => ({ ...prevState, offset: (page - 1) * filters.limit }));
        setCurrentPage(page);
    }, [setFilters]);

    const handleUpdateNomenclature = (id: number, isActive: boolean) => {
        dispatch(editNomenclature({ id, isActive: !isActive }));
        setClick(true);
    };

    return { searchText, handleSearchText, filters, handleSortChange, nomenclatureList, nomenclatureAmount, handlePageChange, handleUpdateNomenclature, currentPage }
};