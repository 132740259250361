import type { FC } from 'react';
import { TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../../locales/tokens';

export const TableListHead: FC = () => {
    const { t } = useTranslation();

    return (
        <TableHead> 
            <TableRow >
                <TableCell width='20%' align="center">
                    <Typography variant='h6'>{t(tokens.account.name)}</Typography>
                </TableCell>
                <TableCell width='20%' align="center">
                    <Typography variant='h6'>{t(tokens.account.shortName)}</Typography>
                </TableCell>
                <TableCell width='15%' align="center">
                    <Typography variant='h6'>{t(tokens.account.accountType)}</Typography>
                </TableCell>
                <TableCell width='15%' align="center">
                    <Typography variant='h6'>{t(tokens.account.organization)}</Typography>
                </TableCell>
                <TableCell width='20%' align="center">
                    <Typography variant='h6'>{t(tokens.account.mainAccount)}</Typography>
                </TableCell>
                <TableCell width='10%' align="center">
                    <Typography variant='h6'>{t(tokens.account.status)}</Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    );
};
