import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setGetAccessError } from "../../repository/actions";
import { setCurrentUser } from "../../../auth/repository/actions";

function* getAccessToAccountWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, isError, message }: IResponse<any> = yield call(requester.post, "/zoolux-api/user-auth/come-in-user/" + payload.id);
        if (isError) { 
            yield put(setGetAccessError(message));
        } else {
            localStorage.setItem("accessToken", data.token);
            localStorage.setItem("user", JSON.stringify(data.user));
            yield put(setCurrentUser(data.user));
            yield put(setGetAccessError(null));
        }
    } catch (error) {
        console.error("getAccessToAccountWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* getAccessToAccountWatcher() {
    yield takeLatest("@saga/GET_ACCESS_TO_ACCOUNT", getAccessToAccountWorker);
};
