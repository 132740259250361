import { Stack, TextField, Theme, Typography, useMediaQuery } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";

interface IProps {
    title: string;
    validText: string;
    supportText: string;
    password: string;
    onChangePassword: (event: ChangeEvent<HTMLInputElement>) => void;
    handleEnterPassword: (e: React.KeyboardEvent) => void;
    passwordValid: boolean;
    showPassword: boolean;
    isPhoneInBD: boolean;
}

export const PasswordInput: FC<IProps> = ({ password, handleEnterPassword, onChangePassword, passwordValid, showPassword, isPhoneInBD, title, validText, supportText }) => {
    const { t } = useTranslation();

    return (
        <>
            <TextField
                fullWidth
                label={title}
                name="password"
                onChange={onChangePassword}
                value={password}
                error={!passwordValid}
                helperText={!passwordValid ? validText : ''}
                type={showPassword ? 'text' : 'password'}
                size="medium"
                onKeyDown={handleEnterPassword}
            />
            {(isPhoneInBD && passwordValid) && <Typography variant="caption" color='GrayText' mt='3px' ml='13px'>{supportText}</Typography>}
        </>
    )
};