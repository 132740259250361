export interface INomenclatureFilters {
    offset: number;
    limit: number;
    search: string;
    search_name?: string;
};

export const NOMENCLATURE_TYPE = {
    PRODUCT: 'Товар',
    SERVICE: 'Услуга',
};

export interface INomeclature {
    id: number;
    ref_key: string;
    name: string;
    name_enote: string;
    code: string;
    type: string;
    article: string;
    measurement_unit_key: string;
    service_receiving_id: number | null;
    is_hidden: boolean;
};