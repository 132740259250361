import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setExpenditure } from "../../repository/actions";
import toast from "react-hot-toast";

function* getExpenditureWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call( requester.get, "/zoolux-api/expenditure", payload);

        if (isError) {
            toast.error(message);
        } else {
            yield put(setExpenditure(data.rows));
        }
    } catch (error) {
        console.error("getExpenditureWorker: ", error);
    } finally {
        
    }
};

export function* getExpenditureWatcher() {
    yield takeLatest("@saga/GET_EXPENDITURE", getExpenditureWorker);
};
