
import toast from 'react-hot-toast';
import { requester } from '../../../libs/requester/requester-axios';
import { zooluxAPI } from '../../zooluxAPI';

const permission = zooluxAPI.injectEndpoints({
    endpoints: (builder) => ({
        getPermissions: builder.query<any, void>({
            async queryFn(_, { dispatch }) {
                try {
                    const { data, isError, message }: any = await requester.get('/zoolux-api/permission');
                    if (isError) {
                        toast.error(message);
                        throw new Error(message);
                    };
                    return { data };
                } catch (e) {
                    throw e;
                }
            },
        }),

    }),
    overrideExisting: false,
});

export const { useGetPermissionsQuery } = permission;