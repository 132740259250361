import { SagaIterator } from "redux-saga";
import { put, call, takeEvery, select } from "redux-saga/effects";
import { requester } from "../../../../libs/requester/requester-axios";
import { IResponse } from "../../../../libs/requester/IResponse";

function* addBankDetailsWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.post, "/zoolux-api/counterparties/add-bank-details", payload);
        if (isError) {
            
        } else {
            // const { newsById } = yield select(selectNews);
            // yield put(setNewsById(data));
        }
    } catch (error) {
        console.error("addBankDetailsWorker: ", error);
    } finally {

    }
};

export function* addBankDetailsWatcher() {
    yield takeEvery("@saga/ADD_BANK_DETAILS", addBankDetailsWorker);
};