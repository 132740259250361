import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getPartnerFolders } from "../../../../store/partners/useCases/getPartnerFolders/action";
import { useDebounce } from "../../../../hooks/useDebounce";
import { createPartner } from "../../../../store/partners/useCases/createPartner/action";
import toast from "react-hot-toast";
import { tokens } from "../../../../locales/tokens";
import { useNavigate } from "react-router-dom";
import { selectPartner } from "../../../../store/partners/repository/selector";

export const usePartnerCreateModal = (partnerName: string, handleToggle: () => void, setDialogValue: Dispatch<SetStateAction<any>>) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { partnerFolders, isLoading, createPartnerError } = useSelector(selectPartner);
    const [name, setName] = useState('');
    const [nameValid, setNameValid] = useState(true);
    const [edrpou, setEdrpou] = useState('');
    const [edrpouValid, setEdrpouValid] = useState(true);
    const [desc, setDesc] = useState('');
    const [bankName, setBankName] = useState('');
    const [folder, setFolder] = useState<any>(null);
    const [folderSearch, setFolderSearch] = useState('');
    const [filters, setFilters] = useState({ offset: 0, limit: 20, search: '' });
    const [createClick, setCreateClick] = useState(false);

    useEffect(() => {
        if (partnerName) {
            setName(partnerName);
        }
    }, [partnerName]);


    useEffect(() => {
        debouncedHandleFolderSeacrh(folderSearch);
    }, [folderSearch]);

    useEffect(() => {
        dispatch(getPartnerFolders({ offset: filters.offset, limit: filters.limit, search: filters.search }));
    }, [filters]);

    useEffect(() => {
        if (!isLoading && createClick) {
            if (createPartnerError) {
                toast.error(t(tokens.partner.errors.uniqueEdrpou));
            } else {
                toast.success(t(tokens.partner.message.partnerCreated));
                closeAndClear();
                // navigate('/partner');
            }
            setCreateClick(false);
        }
    }, [createPartnerError, isLoading]);

    const onChangeName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
        setNameValid(true);
    }, []);

    const onChangeEdrpou = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        if (/^\d*$/.test(input) || input === "") {
            setEdrpou(input);
        };
        setEdrpouValid(true);
    }, []);

    const onChangeDesc = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setDesc(event.target.value);
    }, []);

    const handleChangeFolder = useCallback((value: any) => {
        setFolder(value);
    }, []);

    const changeFolderText = (event: ChangeEvent<HTMLInputElement>) => {
        setFolderSearch(event.target.value);
    };

    const onSearchFolder = (value: string) => {
        if (value.length) {
            setFilters((prevState: any) => ({ ...prevState, search: value }))
        } else {
            setFilters((prevState: any) => ({ ...prevState, search: '' }))
        }
    };

    const { debouncedWrapper: debouncedHandleFolderSeacrh } = useDebounce(onSearchFolder, 400);

    const onChangeBankName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setBankName(event.target.value);
    }, []);

    const handleCreate = () => {
        const isValidData = validateFields();
        if (isValidData) {
            dispatch(createPartner(getRequestBody()));
            setCreateClick(true);
        } else {
            toast.error(t(tokens.partner.fillAllFileds));
        }
    };

    const validateFields = () => {
        const data = [
            { field: name, setField: setNameValid },
            { field: edrpou, setField: setEdrpouValid },
        ];
        let allFieldsValid = true;
        for (const { field, setField } of data) {
            if (!field) {
                setField(false);
                allFieldsValid = false;
            }
        };
        return allFieldsValid;
    };

    const getRequestBody = () => {
        const body: any = { name, EDRPOU: edrpou };
        if (folder) body.folder = folder.ref_key;
        if (bankName) body.name_bank = bankName;
        if (desc) body.description = desc;
        return body;
    };

    const closeAndClear = () => {
        handleToggle();
        clearFields();
    };
    
    const clearFields = () => {
        setName('');
        setEdrpou('');
        setBankName('');
        setDesc('');
        setFolder(null);
    };

    return {
        name, closeAndClear, bankName, onChangeBankName, edrpou, desc, onChangeName, onChangeEdrpou, onChangeDesc, createClick,
        handleCreate, nameValid, edrpouValid, changeFolderText, handleChangeFolder, folder, partnerFolders
    }
};