import { useSelector } from "react-redux";
import { selectPayments } from "../../../store/paymentCalendar/repository/selector";
import { IClinicOption } from "../../../utils/collectOptionList";
import { FilterOptionsState } from "@mui/material";

export const useClinicAutocomplete = () => {
    const { clinics } = useSelector(selectPayments);

    const filterClinics = (options: IClinicOption[], state: FilterOptionsState<IClinicOption>) => {
        const inputValue = state.inputValue.toLowerCase();
        return options.filter((option) =>
            option.name.toLowerCase().startsWith(inputValue)
        );
    };
    
    return { clinics, filterClinics }
};