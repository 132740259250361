import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { config } from '../../../../config';
import { IParamsLocation, useGetLocationsQuery } from '../../../../store/location';

export const useLocations = () => {
    const [paramsLocation, setParamsLocation] = useState<IParamsLocation>({ limit: config.location.limit, offset: 0, search: '' });
    const [searchLocation, setSearchLocation] = useState<string>('');

    const [debouncedSearchLocation] = useDebounce(searchLocation, config.location.debounceSearch);

    useEffect(() => {
        setParamsLocation((prev) => ({ ...prev, search: debouncedSearchLocation }));
    }, [debouncedSearchLocation]);

    const handleSearchLocation = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchLocation(e.target.value);
    };

    const handlePageChange = useCallback((e: ChangeEvent<unknown>, page: number) => {
        setParamsLocation((prev) => ({ ...prev, offset: (page - 1) * paramsLocation.limit }));
    }, [paramsLocation]);

    const { data: { count = 0, rows: rowsGetLocations = [] } = {}, isLoading: isLoadingGetLocations, isFetching: isFetchingGetLocations } = useGetLocationsQuery(paramsLocation);

    return {
        handleSearchLocation, isLoadingGetLocations, isFetchingGetLocations, rowsGetLocations, count, paramsLocation, handlePageChange
    };
};