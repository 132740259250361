import { Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../locales/tokens";
import { FC } from "react";
import dayjs from "dayjs";
import 'dayjs/locale/uk';
import { HistoryItem } from "../historyItem";

interface IProps {
    initiator: any;
    financier: any;
    operationist: any;
    createDate: string;
    visaDate: string;
    processPaymentDate: string;
    checkCode: string | null;
    paymentDate: number | null;
    inCharge: any;
    inChargeDate: any | null;
    approver: any;
    prepaymentStatusDate: any | null;
};

export const HistoryPayment: FC<IProps> = ({ createDate, financier, initiator, operationist, processPaymentDate, visaDate, checkCode, paymentDate,
    inCharge, inChargeDate, approver, prepaymentStatusDate }) => {
    const { t } = useTranslation();
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    return (
        <Stack spacing={1}>
            {initiator ?
                <HistoryItem
                    direction="column"
                    date={createDate}
                    title={t(tokens.payment.placingApp)}
                    name={initiator.full_name}
                    spacing={0}
                />
                : null}
            {paymentDate ?
                <HistoryItem
                    direction={downSm? "column" : "row"}
                    date={paymentDate}
                    title={t(tokens.payment.expectedDate)}
                    name={''}
                    spacing={1}
                />
                : null}
            {financier ?
                <HistoryItem
                    direction="column"
                    date={visaDate}
                    title={t(tokens.payment.visaedPayment)}
                    name={financier.full_name}
                    spacing={0}
                />
                : null}
            {operationist ?
                <HistoryItem
                    direction="column"
                    date={processPaymentDate}
                    title={t(tokens.payment.paymentProcessed)}
                    name={operationist.full_name}
                    spacing={0}
                />
                : null}
            {checkCode ?
                <HistoryItem
                    direction={downSm? "column" : "row"}
                    date={''}
                    title={t(tokens.payment.codeCheck)}
                    name={checkCode}
                    spacing={1}
                />
                : null}
            {inCharge ?
                <HistoryItem
                    direction="column"
                    date={inChargeDate}
                    title={t(tokens.payment.responsibleAndDate)}
                    name={inCharge.name || inCharge.full_name}
                    spacing={0}
                />
                : null}
            {approver ?
                <HistoryItem
                    direction="column"
                    date={prepaymentStatusDate}
                    title={t(tokens.payment.productReceiverAndDate)}
                    name={approver.full_name}
                    spacing={0}
                />
                : null}
        </Stack>
    )
};