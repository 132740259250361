import { ChangeEvent, FC } from "react";
import { getStyles } from "./styles";
import { Button, Checkbox, Stack, useTheme } from "@mui/material";

interface BulkActionsProps {
    enableBulkActions: boolean;
    selectedAll: boolean; 
    selectedSome: boolean;
    handleToggleAll: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const BulkActions: FC<BulkActionsProps> = ({ enableBulkActions, selectedAll, selectedSome, handleToggleAll }) => {
    const theme = useTheme();
    const styles = getStyles(theme);

    return (
        <Stack direction="row" spacing={2} sx={styles.container} display={enableBulkActions ? 'flex' : 'none'}>
            <Checkbox
                checked={selectedAll}
                indeterminate={selectedSome}
                onChange={handleToggleAll}
            />
            <Button color="primary" size="small" variant='contained' sx={{textTransform: 'none', color: '#FFFFFF'}}>
                Видалити
            </Button>
        </Stack>
    )
}