import toast from 'react-hot-toast';

export const downloadFileWithURL = ({ path, name }: { path: string; name: string; }) => {
    fetch(path)
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.download = name || "downloaded-file";
            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
            console.error("Error fetching the file:", error);
            toast.error("Error fetching the file:", error);
        });
};