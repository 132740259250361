import { Stack } from "@mui/material";
import { Dispatch, FC, SetStateAction } from "react";
import { StatusSelect } from "../statusSelect/ui";

interface IProps {
    chips: any[];
    setChips: Dispatch<SetStateAction<any[]>>;
    handleStatusPayments: (status: string[]) => void;
};

export const ServiceFilters: FC<IProps> = ({ chips, setChips, handleStatusPayments }) => {

    return (
        <Stack mt={2}>
            <StatusSelect
                chips={chips}
                setChips={setChips}
                handleStatusPayments={handleStatusPayments}
            />
        </Stack>
    )
};