import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConfigutation } from "../../../store/configuration/useCases/getConfiguration/action";
import { selectConfiguration } from "../../../store/configuration/repository/selector";

export const useConfiguration = () => {
    const dispatch = useDispatch();
    const { configuration } = useSelector(selectConfiguration);

    useEffect(() => {
        dispatch(getConfigutation());
    }, []);

    return { configuration } 
};