import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setServiceHistoryList } from "../../repository/slice";

function* getServiceHistoryListWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, isError, message }: IResponse<any> = yield call(requester.get, "/zoolux-api/services", payload);
        if (isError) {

        } else {
            yield put(setServiceHistoryList({ list: data.rows, count: data.count }))
        }
    } catch (error) {
        console.error("getServiceHistoryListWorker: ", error);
    } finally {
        yield put(setLoading(false));
    }
};

export function* getServiceHistoryListWatcher() {
    yield takeLatest("@saga/GET_SERVICE_HISTORY_LIST", getServiceHistoryListWorker);
};
