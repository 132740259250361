import { Checkbox, Stack, TextField, Typography } from "@mui/material"
import { FC, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import { getStyles } from "./styles";

interface IProps {
    taxes: any;
    handleCopy: (value: string) => void;
};

export const TaxesField: FC<IProps> = ({ taxes, handleCopy }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const copyValue = useCallback(() => handleCopy(taxes), [taxes]);

    return (
        <Stack display='flex' direction='row' spacing={2} pl={6} mt={2}>
            <Stack flex={1}></Stack>
            <Stack flex={4} direction='row' alignItems='center' >
                <Checkbox checked={taxes?.length > 0} />
                <Typography sx={styles.text} variant="body1">{t(tokens.payment.withPdv)}</Typography>
                <TextField
                    value={taxes || ''}
                    size="small"
                    InputProps={{ readOnly: true }}
                    sx={{ input: styles.input, textArea: styles.textArea }}
                    onClick={copyValue}
                />
                <Checkbox checked={taxes === null} />
                <Typography sx={styles.text} variant="body1">{t(tokens.payment.withoutPdv)}</Typography>
                <TextField
                    value={''}
                    size="small"
                    InputProps={{ readOnly: true }}
                    sx={{ input: styles.input, textArea: styles.textArea }}
                    onClick={copyValue}
                />
            </Stack>
        </Stack>
    )
};