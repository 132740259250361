import { SelectChangeEvent } from "@mui/material";
import { ChangeEvent, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ICardOption, IClinicOption, IExpenditureOption, IPartnerOption } from "../../../../../../../../utils/collectOptionList";
import {
    deleteFile, resetFields, setBankCard, setClinic, setComment, setCompanyAccount, setDesiredFinancier, setExpenseItem, resetServiceFields,
    setPDV, setPartner, setPaymentKind, setPaymentType, setPdvChecked, setServiceComment, setSum, setTime, setFiles, setFullName,
    setConfirmPaymentFile, deleteConfirmationPaymentFile, setNotPutToEnot, setConfirmedFile, setRegularAmount, setRegularPayment, setUpdatedPaymentModal,
} from "../../../../../../../../store/paymentCreation/repository/slice";
import { TIMESTAMP_25MB } from "../../../../../../../../config";
import toast from "react-hot-toast";
import { removeFile } from "../../../../../../../../store/paymentCalendar/useCases/removeFile/action";
import { tokens } from "../../../../../../../../locales/tokens";
import { useTranslation } from "react-i18next";
import { selectPaymentCreation } from "../../../../../../../../store/paymentCreation/repository/selector";
import { addFile } from "../../../../../../../../store/paymentCalendar/useCases/addFile/action";
import { setDropValidation, setPaymentFieldValidate, setPaymentUpdated } from "../../../../../../../../store/operationValidation/repository/slice";
import { selectOperationValidation } from "../../../../../../../../store/operationValidation/repository/selector";
import { deletePayment } from "../../../../../../../../store/paymentCalendar/useCases/deletePayment/action";
import { paths } from "../../../../../../../../paths";
import { useNavigate } from "react-router-dom";
import { deleteGroupPayments } from "../../../../../../../../store/paymentCalendar/useCases/deleteGroupPayments/action";
import { completeBudgetOrder } from "../../../../../../../../store/paymentCalendar/useCases/completeBudgetOrder/action";
import { setBankCards } from "../../../../../../../../store/paymentCalendar/repository/actions";
import { getPartners } from "../../../../../../../../store/paymentCalendar/useCases/getPartners/action";
import { selectPayments } from "../../../../../../../../store/paymentCalendar/repository/selector";
import { getServiceList } from "../../../../../../../../store/service/useCases/getServiceList/action";
import { useServicePayment } from "./useServicePayment";

export type TScreenFlag = "PAYMENT" | "SERVICE";

export const usePaymentData = (screenFlag: TScreenFlag = 'PAYMENT', paymentId?: any, modeFilter?: string, source?: any) => {
    useServicePayment(screenFlag, source);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { payment } = useSelector(selectOperationValidation);
    const { paymentTypes } = useSelector(selectPayments);
    const { pdv, paymentMethod, time, isPdvChecked, isUpdatedPaymentModal, paymentType, partner, serviceName, serviceCompletionDate,
        commentForService, relatedServices, relatedServicesValid, servicePartner, serviceExpenseItem, serviceAmount, sum, paymentKind } = useSelector(selectPaymentCreation);

    useEffect(() => {
        if (paymentMethod) {
            if (screenFlag === "PAYMENT") {
                dispatch(resetFields({ paymentMethod, time }));
            } else {
                // dispatch(resetServiceFields({
                //     paymentMethod, time, paymentType, serviceName, serviceCompletionDate,
                //     commentForService, relatedServices, relatedServicesValid, servicePartner,
                //     serviceExpenseItem, serviceAmount, paymentKind, 
                // }));
            }
            dispatch(setDropValidation());
        }
    }, [paymentMethod]);

    useEffect(() => {
        if (pdv.length) {
            dispatch(setPdvChecked(true));
        }
    }, [pdv]);

    useEffect(() => {
        if (partner) {
            dispatch(setFullName(partner.name));
            dispatch(setPaymentFieldValidate('fullName'));
            if (paymentMethod.key === 'non-cash') {
                dispatch(setBankCards(partner.bankDetailsBusiness as any || []));
                dispatch(getPartners({ offset: 0, limit: 20, search: '' }));
            } else if (paymentMethod.key === 'card') {
                dispatch(setBankCards(partner.bankDetailsPersonal as any || []));
                dispatch(getPartners({ offset: 0, limit: 20, search: '' }));
            }
            dispatch(setPaymentFieldValidate('card'));
        } else {
            dispatch(setBankCards([]));
            dispatch(setFullName(''));
        }
    }, [partner]);

    const handleChangePaymentType = useCallback((event: SelectChangeEvent) => {
        dispatch(setPaymentType(event.target.value));
        dispatch(setPaymentFieldValidate('paymentType'));
        dispatch(setPaymentUpdated(true));

        const currentPaymentType = paymentTypes.find((type: any) => type.id === event.target.value);
        if (currentPaymentType.key === 'service_receiving') {
            dispatch(getServiceList({ page: 1, perPage: 100 }))
        };
    }, []);

    const handleChangePaymentKind = useCallback((event: SelectChangeEvent) => {
        dispatch(setPaymentKind(event.target.value));
        dispatch(setPaymentFieldValidate('paymentKind'));
        dispatch(setPaymentUpdated(true));
    }, []);

    const handleChangePartner = useCallback((value: IPartnerOption | null) => {
        dispatch(setPartner(value));
        dispatch(setPaymentFieldValidate('partner'));
        dispatch(setPaymentUpdated(true));
    }, []);

    const handleChangeExpenseItem = useCallback((value: IExpenditureOption | null) => {
        dispatch(setExpenseItem(value));
        dispatch(setPaymentFieldValidate('expenseItem'));
        dispatch(setPaymentUpdated(true));
    }, []);

    const handleChangeClinic = useCallback((value: IClinicOption | null) => {
        dispatch(setClinic(value));
        dispatch(setPaymentFieldValidate('clinic'));
        dispatch(setPaymentUpdated(true));
    }, []);

    const onChangeCompanyAccount = useCallback((value: ICardOption | null) => {
        dispatch(setCompanyAccount(value));
        dispatch(setPaymentFieldValidate('companyAccount'));
        dispatch(setPaymentUpdated(true));
    }, []);

    const handleChangeBankCard = useCallback((card: any) => {
        dispatch(setBankCard(card));
        dispatch(setPaymentFieldValidate('card'));
        dispatch(setPaymentUpdated(true));
    }, []);

    const onChangeComment = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        dispatch(setComment(event.target.value));
        dispatch(setPaymentFieldValidate('comment'));
        dispatch(setPaymentUpdated(true));
    }, []);

    const onChangeServiceComment = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        dispatch(setServiceComment(event.target.value));
        dispatch(setPaymentUpdated(true));
    }, []);

    const onChangeFullName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        dispatch(setFullName(event.target.value));
        dispatch(setPaymentFieldValidate('fullName'));
        dispatch(setPaymentUpdated(true));
    }, []);

    const onChangeSum = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        const regex = /^\d*(?:\.\d{0,2})?$/;

        if (parseInt(inputValue) >= 0 || inputValue === "" || regex.test(inputValue)) {
            dispatch(setSum(event.target.value));
            dispatch(setPaymentFieldValidate('sum'));
            dispatch(setPaymentUpdated(true));

            if (isPdvChecked) {
                const pdvFromSum = Math.round((Number(event.target.value) / 6) * 100) / 100;
                dispatch(setPDV(String(pdvFromSum)));
            };
        }
    }, [isPdvChecked]);

    const onChangeChecked = useCallback(() => {
        dispatch(setPdvChecked(!isPdvChecked));
        dispatch(setPaymentUpdated(true));
        if (isPdvChecked) {
            dispatch(setPDV(''));
            dispatch(setPaymentFieldValidate('sum'));
        } else {
            const pdvFromSum = Math.round((Number(sum) / 6) * 100) / 100;
            dispatch(setPDV(String(pdvFromSum)));
        }
    }, [isPdvChecked, sum]);

    const onChangePDV = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        const regex = /^\d*(?:\.\d{0,2})?$/;

        if (inputValue === "" || regex.test(inputValue)) {
            dispatch(setPDV(event.target.value));
            dispatch(setPaymentFieldValidate('pdv'));
            dispatch(setPaymentUpdated(true));
        }
    }, []);

    const handleDateChange = useCallback((date: Date | null): void => {
        if (date) {
            dispatch(setTime(date?.valueOf()));
            dispatch(setPaymentFieldValidate('date'));
            dispatch(setPaymentUpdated(true));
        }
    }, []);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            if (file.size <= TIMESTAMP_25MB) {
                if (paymentId) {
                    dispatch(addFile({ body: { id: paymentId, type: 'DEFAULT', files: file }, mode: modeFilter, isUpdate: true }));
                } else {
                    dispatch(setFiles(file as File));
                }
            } else {
                toast.error(t(tokens.contracts.maxSizeFile));
            }
        };
    };

    const watchCurrentFile = (name: string) => {
        const fileUrl = process.env.REACT_APP_PUBLIC_URL + '/zoolux-api/file-storage/' + name;
        window.open(fileUrl, '_blank');
    };

    const handleDeleteFile = (name: string) => {
        if (paymentId) {
            dispatch(removeFile({ body: { id: paymentId, type: "DEFAULT", files_name: [name] }, mode: modeFilter }));
        } else {
            dispatch(deleteFile(name));
        }
    };

    const handleChangeDesiredFinancier = useCallback((value: any) => {
        dispatch(setDesiredFinancier(value));
        dispatch(setPaymentUpdated(true));
    }, []);

    const onConfirmPaymentFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            if (file.size <= TIMESTAMP_25MB) {
                if (paymentId) {
                    dispatch(addFile({ body: { id: paymentId, type: 'CONFIRM', files: [file] }, mode: modeFilter, isUpdate: true }));
                } else {
                    dispatch(setConfirmPaymentFile(file as File));
                }
            } else {
                toast.error(t(tokens.contracts.maxSizeFile));
            }
        };
    };

    const deleteConfirmPaymentFile = (name: string) => {
        if (paymentId) {
            dispatch(removeFile({ body: { id: paymentId, type: "CONFIRM", files_name: [name] }, mode: modeFilter }));
        } else {
            dispatch(deleteConfirmationPaymentFile(name));
        }
    };

    const handleNotPutToEnot = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(setNotPutToEnot(event.target.checked));
        dispatch(setPaymentUpdated(true));
    };

    const handleConfirmFile = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(setConfirmedFile(event.target.checked));
        dispatch(setPaymentUpdated(true));
    };

    const handleRegularChange = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(setRegularPayment(event.target.checked));
        dispatch(setRegularAmount(''));
    };

    const performBudgetOrder = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (isUpdatedPaymentModal) {
            dispatch(setUpdatedPaymentModal(true));
        } else {
            dispatch(completeBudgetOrder({ id: paymentId }));
        }
    };

    const handleDeletePayment = (id: string) => {
        dispatch(deletePayment({ id, modeFilter }));
        navigate(paths.dashboard.payment.list);
    };

    const handleDeleteGroupPayments = (groupId: string) => {
        dispatch(deleteGroupPayments({ id: groupId })); 
    };

    return {
        handleChangePaymentType, handleChangePaymentKind, handleChangePartner, handleChangeExpenseItem, handleChangeClinic, onChangeCompanyAccount,
        handleChangeBankCard, onChangeComment, onChangeServiceComment, onChangeFullName, handleDateChange, handleFileChange, handleDeleteFile, onChangePDV,
        onChangeSum, handleChangeDesiredFinancier, watchCurrentFile, onChangeChecked, payment, onConfirmPaymentFileChange, deleteConfirmPaymentFile,
        handleNotPutToEnot, handleConfirmFile, handleRegularChange, handleDeletePayment, handleDeleteGroupPayments, performBudgetOrder
    }
};