import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IState {
  payment: {
    partner: boolean;
    sum: boolean;
    pdv: boolean;
    paymentKind: boolean;
    paymentType: boolean;
    clinic: boolean;
    companyAccount: boolean;
    expenseItem: boolean;
    comment: boolean;
    commentForService: boolean;
    card: boolean;
    fullName: boolean;
    date: boolean;
    user: boolean;
    prepaymentDate: boolean;
    regularAmount: boolean;
    serviceCompletionDate: boolean;
    serviceName: boolean;
    servicePartner: boolean;
    serviceExpenseItem: boolean;
    serviceAmount: boolean;
  },
  isPaymentUpdated: boolean;
};

const initialState: IState = {
  payment: {
    partner: true,
    sum: true,
    pdv: true,
    paymentKind: true,
    paymentType: true,
    clinic: true,
    companyAccount: true,
    expenseItem: true,
    comment: true,
    commentForService: true,
    card: true,
    fullName: true,
    date: true,
    user: true,
    prepaymentDate: true,
    regularAmount: true,
    serviceCompletionDate: true,
    serviceName: true,
    servicePartner: true,
    serviceExpenseItem: true,
    serviceAmount: true,
  },
  isPaymentUpdated: false,
};

const OperationValidationSlice = createSlice({
  name: 'operationValidation',
  initialState,
  reducers: {
    setPaymentValidate: (state, action: PayloadAction<keyof typeof initialState.payment>) => {
      const field = action.payload;
      if (state.payment.hasOwnProperty(field)) {
        state.payment[field] = false;
      }
    },
    setPaymentFieldValidate: (state, action: PayloadAction<keyof typeof initialState.payment>) => {
      const field = action.payload;
      if (state.payment.hasOwnProperty(field)) {
        state.payment[field] = true;
      }
    },
    setDropValidation: (state) => {
      return { ...initialState };
    },
    setPaymentUpdated: (state, action: PayloadAction<boolean>) => {
      state.isPaymentUpdated = action.payload;
    },

  },
});

export const { setPaymentValidate, setPaymentFieldValidate, setPaymentUpdated, setDropValidation } = OperationValidationSlice.actions;
export const OperationValidationReducer = OperationValidationSlice.reducer;