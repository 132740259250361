import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";
import { setPartnersCount, setPartnersList } from "../../repository/slice";

function* getPartnersAllWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.get, "/zoolux-api/admin/counterparties", payload);

        if (isError) {
            toast.error(message);
        } else {
            yield put(setPartnersList(data.data));
            yield put(setPartnersCount(data.total));
        }
    } catch (error) {
        console.error("getPartnersAllWorker: ", error);
    } finally {

    }
};

export function* getPartnersAllWatcher() {
    yield takeLatest("@saga/GET_PARTNERS_ALL", getPartnersAllWorker);
};
