export const getStyles = () => {
    const styles = {
        noCode: {
            position: 'absolute', 
            right: '1px', 
            bottom: '-7px',
            color: 'gray',
            textTransform: 'none',
            fontSize: '12px',
            "&:hover":{
                textDecoration: 'underline'
            }
        }
    };
    return styles;
}