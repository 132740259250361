import { FC, useMemo } from "react";
import { useContractList } from "../presenters/useContractList";
import { Button, Stack, SvgIcon, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { getStyles } from "./styles";
import AddIcon from '@mui/icons-material/Add';
import { Link } from "react-router-dom";
import { ContractsList } from "./components/contractsList";
import { SearchInput } from "../../../../ui-kit/searchInput/searchInput";
import { EmptyList } from "../../../../ui-kit/emptyList";

export const ContractList: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { contracts, filters, contractsAmount, handlePageChange, handleDeleteContract, handleSearchContractText, searchText, id, partnerName } = useContractList();

    return (
        <Stack>
            <Stack direction='row' justifyContent='space-between' mt={3}>
                <Typography variant="h4">{t(tokens.contracts.contracts)}, {partnerName}</Typography>
                <Button
                    variant="contained"
                    size="medium"
                    sx={styles.createBtn}
                    startIcon={(<SvgIcon><AddIcon /></SvgIcon>)}
                    component={Link}
                    to={`/partner-contractCreate/${id}`}
                >
                    {t(tokens.common.create)}
                </Button>
            </Stack>
            <Stack mt={5}>
                <SearchInput
                    searchText={searchText}
                    label={t(tokens.contracts.searchContract)}
                    handleSearch={handleSearchContractText}
                />
            </Stack>
            {
                contracts?.length ?
                    <ContractsList
                        contracts={contracts}
                        page={filters.page}
                        pageAmount={contractsAmount}
                        partnerId={id}
                        handlePageChange={handlePageChange}
                        handleDeleteContract={handleDeleteContract}
                    />
                    :
                    <EmptyList
                        title={t(tokens.contracts.listEmpty)}
                        description={t(tokens.contracts.emptyListDesc)}
                    />
            }
        </Stack>
    )
};