import { useDispatch, useSelector } from "react-redux";
import { selectPaymentCreation } from "../../../../../../../../store/paymentCreation/repository/selector";
import { selectPayments } from "../../../../../../../../store/paymentCalendar/repository/selector";
import {
    setFormData, setRelatedServicePartnerValid, setTime, setUpdatedPaymentModal,
    setOpenServiceModal, setRelatedServiceCommentValid, setRelatedServiceExpenditureValid,
    setRelatedServiceAmountValid,
} from "../../../../../../../../store/paymentCreation/repository/slice";
import toast from "react-hot-toast";
import { selectOperationValidation } from "../../../../../../../../store/operationValidation/repository/selector";
import { PAYMENT_STATUS } from "../../../../../../../../config";
import { useCallback, useEffect, useState } from "react";
import { setPaymentFieldValidate, setPaymentUpdated, setPaymentValidate } from "../../../../../../../../store/operationValidation/repository/slice";
import { createServicePayment } from "../../../../../../../../store/serviceHistory/useCases/createServicePayment/action";
import { updateSingleServicePayment } from "../../../../../../../../store/serviceHistory/useCases/updateServicePayment/action";
import { setServiceVisa } from "../../../../../../../../store/serviceHistory/useCases/setServiceVisa/action";
import { deleteServiceVisa } from "../../../../../../../../store/serviceHistory/useCases/deleteServiceVisa/action";
import { processService } from "../../../../../../../../store/serviceHistory/useCases/processService/action";
import { selectServiceHistory } from "../../../../../../../../store/serviceHistory/repository/selector";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../../../../../../paths";

export const useServiceProcessing = (isPaymentShown: boolean, paymentId?: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { paymentTypes, paymentKinds } = useSelector(selectPayments);
    const {
        serviceCompletionDate, commentForService, relatedServices, isNotPutToEnot, isVisa, partner, sum, paymentKind, expenseItem, isPdvChecked, comment, fullName,
        isUpdatedPaymentModal, isOpenServiceModal, prepaymentDate, responsible, regularAmount, isRegular, pdv, time, paymentType, serviceName, serviceComment,
        clinic, companyAccount, paymentMethod, bankCard, isConfirmedFile, desiredFinancier, prepaymentComment, regularPeriod, confirmPaymentFiles, files,
        serviceExpenseItem, serviceAmount, servicePartner
    } = useSelector(selectPaymentCreation);
    const { isLoading, createServicePaymentError } = useSelector(selectServiceHistory);
    const { payment, isPaymentUpdated } = useSelector(selectOperationValidation);
    const [createClick, setCreateClick] = useState(false);
    const currentPaymentType = paymentTypes.find((type: any) => type.id === paymentType);

    useEffect(() => {
        if (!isLoading && createClick) {
            if (createServicePaymentError) {
                toast.error(createServicePaymentError)
            } else {
                isPaymentShown && navigate(paths.dashboard.payment.list);
            }
            setCreateClick(false);
        }
    }, [createServicePaymentError, isLoading]);

    const handleDateChange = useCallback((date: Date | null): void => {
        if(date){
            dispatch(setTime(date?.valueOf()));
            dispatch(setPaymentFieldValidate('date'));
            dispatch(setPaymentUpdated(true));
        }
    }, []);

    const validateData = () => {
        const validFields = getValidFields();
        let allFieldsValid = true;
        for (const { field, isValid } of validFields) {
            if (!field) {
                dispatch(setPaymentValidate(isValid as any));
                allFieldsValid = false;
            }
        };

        if (relatedServices.length > 0) {
            relatedServices.forEach((service: any, index: number) => {
                for (let key in service) {
                    if (key === 'counterparty' && !service[key]) {
                        dispatch(setRelatedServicePartnerValid({ value: false, index }));
                        allFieldsValid = false;
                    } else if (key === 'expenditure' && !service[key]) {
                        dispatch(setRelatedServiceExpenditureValid({ value: false, index }));
                        allFieldsValid = false;
                    } else if (key === 'amount' && !service[key]) {
                        dispatch(setRelatedServiceAmountValid({ value: false, index }));
                        allFieldsValid = false;
                    } else if (key === 'comment' && !service[key]) {
                        dispatch(setRelatedServiceCommentValid({ value: false, index }));
                        allFieldsValid = false;
                    }
                }
            });
        };
        return allFieldsValid;
    };

    const getValidFields = () => {
        if (!isPaymentShown) {
            const validFields = [
                { field: serviceName, isValid: "serviceName" },
                { field: serviceCompletionDate, isValid: "serviceCompletionDate" },
                { field: commentForService, isValid: "commentForService" },
                { field: servicePartner, isValid: "servicePartner" },
                { field: serviceExpenseItem, isValid: "serviceExpenseItem" },
                { field: serviceAmount, isValid: "serviceAmount" },
            ];
            return validFields;
        } else {
            const validFields = [
                { field: partner, isValid: "partner" },
                { field: sum, isValid: "sum" },
                { field: time, isValid: "date" },
                { field: paymentType, isValid: "paymentType" },
                { field: paymentKind, isValid: "paymentKind" },
                { field: expenseItem, isValid: "expenseItem" },
            ];
            if (isPdvChecked) validFields.push({ field: pdv, isValid: "pdv" });
            const kind = paymentKinds.find(item => item.id === paymentKind);
            if (kind?.key === 'prepayment') {
                validFields.push({ field: prepaymentDate, isValid: "prepaymentDate" });
                validFields.push({ field: responsible, isValid: "user" });
            };
            if (isRegular && !paymentId) {
                validFields.push({ field: regularAmount, isValid: "regularAmount" });
            };
            if (currentPaymentType?.key === "service_receiving") {
                validFields.push({ field: serviceName, isValid: "serviceName" });
                validFields.push({ field: serviceCompletionDate, isValid: "serviceCompletionDate" });
                validFields.push({ field: commentForService, isValid: "commentForService" });
                validFields.push({ field: servicePartner, isValid: "servicePartner" });
                validFields.push({ field: serviceExpenseItem, isValid: "serviceExpenseItem" });
                validFields.push({ field: serviceAmount, isValid: "serviceAmount" });
            };
            return validFields;
        }
    };

    const getPaymentData = () => {
        if (!isPaymentShown) {
            return null;
        } else {
            const payment: any = {
                name: partner?.label,
                taxes: pdv ? Number(pdv) : null,
                comment: comment || null,
                service_comment: serviceComment || null,
                amount: parseFloat(sum.replace(',', '.')),
                payment_date: time,
                company_account_id: companyAccount?.id || null,
                payment_kind_id: paymentKind || null,
                payment_type_id: paymentType || null,
                payment_method_id: paymentMethod.id,
                clinic_id: clinic?.id || null,
                expenditure_id: expenseItem?.id || null,
                counterparty_id: partner?.id,
                isConfirmedFile: isConfirmedFile,
                bankDetails: bankCard,
                financier_id: desiredFinancier?.id || null
            };
            return payment;
        }
    };

    const getPrepaymentData = () => {
        let prepayment;
        const currentPaymentKind = paymentKinds.find((kind: any) => kind.id === paymentKind);
        if (currentPaymentKind.key === "prepayment") {
            prepayment = {
                responsible_id: responsible.id,
                comment: prepaymentComment,
                prepayment_date: prepaymentDate
            };
        } else {
            prepayment = null;
        };
        return prepayment;
    };

    const getPaymentServiceData = () => {
        let receipt_goods_services;
        if (currentPaymentType.key === "service_receiving" && serviceName) {
            receipt_goods_services = {
                date: serviceCompletionDate,
                is_hidden: false,
                is_enote: true, 
                comment: commentForService,
                counterparty_id: servicePartner?.id,
                expenditure_id: serviceExpenseItem?.id,
                amount: Number(serviceAmount)
            };
        } else {
            receipt_goods_services = null;
        }
        return receipt_goods_services;
    };

    const getServiceData = () => {
        let service_receiving;
        if (currentPaymentType.key === "service_receiving" && serviceName) {
            service_receiving = {
                service_receiving_ids: [serviceName?.id]
            };
        } else {
            service_receiving = [];
        };
        return service_receiving;
    };

    const getServiceRelatedData = () => {
        if (relatedServices.length > 0) {
            const newRelatedServices = relatedServices.map((service: any) => {
                const newService: any = {
                    amount: Number(service.amount),
                    counterparty_id: service.counterparty.id,
                    expenditure_id: service.expenditure.id,
                    comment: service.comment,
                    is_hidden: false
                };
                if (service?.id) newService.id = service.id;
                return newService;
            });
            return { data: newRelatedServices }
        } else {
            return null;
        }
    };

    const createOrEditPayment = (isShowMessage: boolean) => {
        const isPassedValidation = validateData();
        if (isPassedValidation) {
            let body: any = {
                receipt_goods_services: getPaymentServiceData(),
                service_receiving: getServiceData(),
                service_related: getServiceRelatedData(),
            };
            if (isPaymentShown) body.prepayment = getPrepaymentData();
            if (isPaymentShown) body.payment = getPaymentData();
            if (paymentId) {
                editService(body);
            } else {
                createService(body);
            };
        } else {
            toast.error('Заповніть обов\'язкові поля');
        };
    };

    const createService = (body: any) => {
        const serviceData = checkRegularOption(body);
        dispatch(createServicePayment({ body: serviceData, files, confirmPaymentFiles, isPaymentShown, modeFilter: "Payment" }));
        dispatch(setPaymentUpdated(false));
        setCreateClick(true);
    };
        
    const checkRegularOption = (body: any) => {
        if (isPaymentShown) {
            if (isRegular && regularPeriod === "Months") {
                body.regular_payment = true;
                body.count_months = Number(regularAmount);
            } else if (isRegular && regularPeriod === "Weeks") {
                body.regular_payment = true;
                body.count_weeks = Number(regularAmount);
            };
        };
        return body;
    };

    const editService = (body: any) => {
        body.receipt_goods_services_id = paymentId;
        dispatch(updateSingleServicePayment({ body }));
    };

    /* ------------------------------ Visa And UnVisa Payment ------------------------ */
    const handleChangeVisa = (id: number, statusForm: string) => {
        if (isPaymentUpdated) {
            dispatch(setUpdatedPaymentModal(true));
        } else {
            if (statusForm === PAYMENT_STATUS.CREATED) {
                dispatch(setServiceVisa({ id }));
                dispatch(setFormData({ id, status: PAYMENT_STATUS.VISAED }));

            } else {
                dispatch(deleteServiceVisa({ id: paymentId }));
                dispatch(setFormData({ id, status: PAYMENT_STATUS.CREATED }));
            }
        }
    };

    /* -------------------------------- Process Payment -------------------------------- */
    const makePayment = () => {
        validateAndProcessPayment();
    };

    const validateAndProcessPayment = () => {
        const fields = getFiledsForProcessPayment();
        let allFieldsValid = true;
        for (const { field, isValid } of fields) {
            if (!field) {
                dispatch(setPaymentValidate(isValid as any));
                allFieldsValid = false;
            }
        };
        if (!allFieldsValid) {
            toast.error('Заповніть всі поля та редагуйте платіж для проведення');
            dispatch(setOpenServiceModal(false));
        } else {
            if (isPaymentUpdated) {
                dispatch(setUpdatedPaymentModal(true));
            } else {
                dispatch(processService({ id: Number(paymentId) }));
            }
        }
    };

    const getFiledsForProcessPayment = () => {
        if (!isPaymentShown) {
            const fields: any[] = [
                { field: serviceName, isValid: "serviceName" },
                { field: serviceCompletionDate, isValid: "serviceCompletionDate" },
                { field: commentForService, isValid: "commentForService" },
                { field: servicePartner, isValid: "servicePartner" },
                { field: serviceExpenseItem, isValid: "serviceExpenseItem" },
                { field: serviceAmount, isValid: "serviceAmount" },
            ];
            return fields;
        } else {
            const fields: any[] = validateAllFields(paymentMethod.key);
            if (isPdvChecked) fields.push({ field: pdv, isValid: "pdv" });
            const kind = paymentKinds.find(item => item.id === paymentKind);
            if (kind?.key === 'prepayment') {
                fields.push({ field: prepaymentDate, isValid: "prepaymentDate" });
                fields.push({ field: responsible, isValid: "user" });
            };
            if (!isNotPutToEnot && currentPaymentType.key === "service_receiving") {
                fields.push({ field: serviceName, isValid: "serviceName" });
                fields.push({ field: serviceCompletionDate, isValid: "serviceCompletionDate" });
                fields.push({ field: commentForService, isValid: "commentForService" });
                fields.push({ field: servicePartner, isValid: "servicePartner" });
                fields.push({ field: serviceExpenseItem, isValid: "serviceExpenseItem" });
                fields.push({ field: serviceAmount, isValid: "serviceAmount" });
            };
            return fields;
        }
    };

    const validateAllFields = (paymentMethod: string) => {
        const validFields: Record<string, { field: any; isValid: string }[]> = {
            'non-cash': [
                { field: paymentKind, isValid: "paymentKind" },
                { field: paymentType, isValid: "paymentType" },
                { field: partner, isValid: "partner" },
                { field: expenseItem, isValid: "expenseItem" },
                // { field: companyAccount, isValid: "companyAccount" },
                // { field: bankCard, isValid: "card" },
                { field: comment, isValid: "comment" },
                { field: sum, isValid: "sum" },
                { field: time, isValid: "date" },
            ],
            'cash': [
                { field: paymentKind, isValid: "paymentKind" },
                { field: paymentType, isValid: "paymentType" },
                { field: partner, isValid: "partner" },
                { field: expenseItem, isValid: "expenseItem" },
                // { field: clinic, isValid: "clinic" },
                // { field: companyAccount, isValid: "companyAccount" },
                { field: comment, isValid: "comment" },
                { field: sum, isValid: "sum" },
                { field: time, isValid: "date" },
            ],
            'card': [
                { field: paymentKind, isValid: "paymentKind" },
                { field: paymentType, isValid: "paymentType" },
                { field: partner, isValid: "partner" },
                { field: expenseItem, isValid: "expenseItem" },
                // { field: companyAccount, isValid: "companyAccount" },
                // { field: bankCard, isValid: "card" },
                { field: comment, isValid: "comment" },
                { field: sum, isValid: "sum" },
                { field: time, isValid: "date" },
                { field: fullName, isValid: "fullName" },
            ]
        };
        return validFields[paymentMethod];
    };

    const handleToggleServiceModal = () => {
        dispatch(setOpenServiceModal(!isOpenServiceModal));
    };

    const handleToggleUpdateModal = () => {
        dispatch(setUpdatedPaymentModal(!isUpdatedPaymentModal));
    };

    const updatePaymentInModal = () => {
        createOrEditPayment(true);
        dispatch(setUpdatedPaymentModal(false))
        dispatch(setPaymentUpdated(false));
    };

    return {
        createOrEditPayment, time, payment, handleChangeVisa, isNotPutToEnot, makePayment, isVisa, updatePaymentInModal, handleDateChange,
        currentPaymentType, isUpdatedPaymentModal, isOpenServiceModal, handleToggleServiceModal, handleToggleUpdateModal, validateAndProcessPayment
    }
};