import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./rootReducer";
import createSagaMiddleware from "redux-saga";
import { rootSaga } from "./rootSaga";
import { zooluxAPI } from './zooluxAPI';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware => getDefaultMiddleware().concat(zooluxAPI.middleware).concat(sagaMiddleware))
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;