import { TableCell, TableRow, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { getStyles } from "./styles";

interface IProps {
    service: any;
};

export const ServiceHistoryItem: FC<IProps> = ({ service }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const createdDate = dayjs(service.date).locale('uk').format('MMMM D, YYYY');
    const processedDate = service.operation_date && dayjs(service.operation_date).locale('uk').format('MMMM D, YYYY');

    const paymentStatusOptions: any = {
        CREATED: "🟦 " + t(tokens.payment.paymentCreated),
        OPERATED: "🟩 " + t(tokens.payment.operated)
    };

    return (
        <TableRow key={service.id} component={Link} sx={styles.tableRow} to={`/service-edit/${service.id}`}>
            <TableCell align="center" >
                <Typography variant="subtitle2">
                    {createdDate || "-"}
                </Typography>
            </TableCell>
            <TableCell align="center">
                <Typography variant="subtitle2" sx={{ pl: "15px" }}>
                    {service.counterparty?.name || '-'}
                </Typography>
            </TableCell>
            <TableCell align="center" >
                <Typography variant="subtitle2">
                    {service.amount ? `${service.amount} грн` : '-'}
                </Typography>
            </TableCell>
            <TableCell align="left">
                <Typography variant="subtitle2" sx={{ pl: '25%' }}>
                    {paymentStatusOptions[service.status] || '-'}
                </Typography>
            </TableCell>
            <TableCell align="center" >
                <Typography variant="subtitle2">
                    {processedDate ? processedDate : '-'}
                </Typography>
            </TableCell>
        </TableRow>
    )
};