export const getStyles = (mode: any) => {
    const styles = {
        toolbarContainer: {
            flexGrow: 1,
            pb: 2
        },
        calendar: {
            mt: 1,
            backgroundColor:
                mode === 'Budget' ?
                    '#FFFADF'
                    : '#ffffff'
        }
    };
    return styles;
}