export const getStyles = () => {
    const styles = {
        tabs: {
            py: 2,
            "& .MuiTabs-indicator": {
                backgroundColor: "#F1594E",
            }
        },
        tab: {
            "&.Mui-selected": {
                color: "#F1594E",
            },
        }
    };
    return styles;
}