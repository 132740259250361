import { Stack } from "@mui/material";
import { ChangeEvent, FC, FormEvent } from "react";
import { SearchInput } from "../searchInput";
import { SearchSelector } from "../ssearchSelector";

interface IProps {
    handleSearchTextChange: (event: FormEvent<HTMLFormElement>) => void;
    handleChangeSearch: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleSortChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const PaymentsSearch: FC<IProps> = ({ handleChangeSearch, handleSearchTextChange, handleSortChange }) => {

    return (
        <Stack direction="row" spacing={3} width="100%">
            <SearchInput
                handleSearchTextChange={handleSearchTextChange}
                handleChangeSearch={handleChangeSearch}
            />
            <SearchSelector
                handleSortChange={handleSortChange}
            />
        </Stack>
    )
};