import { Stack, Typography } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { SmsInput } from "../smsInput";
import { getStyles } from "../smsInput/styles";

interface IProps {
    smsCode: string;
    timerInSec: number;
    onChangeTimer: () => void;
    resendSmsCode: () => void;
    onChangeSmsCode: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const SmsField: FC<IProps> = ({ smsCode, onChangeSmsCode, timerInSec, onChangeTimer, resendSmsCode }) => {
    const styles = getStyles();

    return (
        <Stack sx={{ position: "relative" }}>
            <SmsInput
                smsCode={smsCode}
                timerInSec={timerInSec}
                resendSmsCode={resendSmsCode}
                onChangeTimer={onChangeTimer}
                onChangeSmsCode={onChangeSmsCode}
            />
        </Stack>
    )
}