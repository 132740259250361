import { TextField } from "@mui/material";
import { FC, useMemo } from "react";
import { getStyles } from "./styles";

interface IProps {
    startDate: string;
    endDate: string;
};

export const DateRangeField: FC<IProps> = ({ startDate, endDate }) => {
    const styles = useMemo(() => getStyles(), []);
    
    return (
        <TextField
            InputProps={{ readOnly: true }}
            sx={{ input: styles.inputDate }}
            value={`${startDate} - ${endDate}`}
        />
    )
};