import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { setSingleUser, setUsers } from "../../repository/actions";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";

function* getSingleUserWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.get, "/zoolux-api/admin/users/" + payload.id);
        if (isError) {
            toast.error("користувач не знайдений")
        } else {
            yield put(setSingleUser(data)); 
        }
    } catch (error) {
        console.error("getSingleUserWorker: ", error);
    } finally {

    };
};

export function* getSingleUserWatcher() {
    yield takeLatest("@saga/GET_SINGLE_USER", getSingleUserWorker);
};
