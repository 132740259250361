import { TabContext, TabList } from '@mui/lab';
import { Box, Card, IconButton, Stack, Tab, Typography } from '@mui/material';
import 'dayjs/locale/uk';
import { CSSProperties, FC } from 'react';
import { ReactComponent as DownloadIcon } from '../../../../assets/icons/download-icon.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit-icon.svg';
import { CircularProgress, HeadingTitle } from '../../../../uiKit';
import { FILE_TYPE_TABS_CREATE_FIXED_ASSET } from '../../constants';
import { TabPanel } from '../../components/tabPanel';
import { useDetailFixedAsset } from '../presenters';
import { getStyles } from './styles';
import { MoveFixedAssetDots } from './components/moveFixedAssetDots';
import { v4 as uuidv4 } from 'uuid';

const array: any = [
    { status: true },
    { status: true },
    { status: true },
    { status: true },
    { status: true, current: true },
    { status: true },
    { status: false },
    { status: false },
    { status: false },
    { status: false },

];

export const DetailFixedAsset: FC = () => {
    const { mainWrapper, cardInfo, cardFile, tabList } = getStyles();

    const { name, fixedAssetId, mainInfo, secondInfo, fileTypeTab, handleChangeFileTypeTab, files, handleDownloadFile, Edit_Fixed_Assets, isLoadingGetFixedAsset } = useDetailFixedAsset();

    return (
        <Stack sx={mainWrapper}>
            <HeadingTitle title={name as string} textBtn='Редагувати' startIcon={<EditIcon />} to={`/fixed-assets/${fixedAssetId}/edit`} disabled={!Edit_Fixed_Assets} />
            {isLoadingGetFixedAsset
                ? <CircularProgress />
                : <>
                    <Card className='card' sx={{ borderRadius: 0, p: "18px 14px", display: "flex" }}>
                        <Stack width={"100%"} maxWidth="300px">
                            <Typography fontWeight={500}>{name}</Typography>
                        </Stack>
                        <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
                            <Stack width={"94%"} rowGap={"4px"}>
                                <Typography fontWeight={600} sx={{ color: 'second.main', fontSize: "14px" }}>{"Склад 4"}</Typography>
                                <Stack direction={"row"} width="100%">
                                    <MoveFixedAssetDots moveFixedAssetArray={array} />
                                </Stack>
                            </Stack>
                            <Stack direction={"row"} alignItems={"end"} columnGap={"2px"}>
                                <Typography fontWeight={500} sx={{ color: 'second.main', fontSize: "24px", lineHeight: "28px" }} component={"span"}>4</Typography>
                                <Typography fontWeight={500} sx={{ color: 'heatherField.main', fontSize: "14px", lineHeight: "20px" }} component={"span"}>з 10</Typography>
                            </Stack>
                        </Stack>
                    </Card>
                    <Stack rowGap="12px">
                        {mainInfo?.map(({ value, label }: { value: string | number; label: string; }) => (
                            <Card component={"div"} key={uuidv4()} className='card' style={cardInfo}>
                                <Typography fontWeight={600}>{label}</Typography>
                                <Typography fontWeight={600}>{value ? value : "Данні відсутні"}</Typography>
                            </Card>
                        ))}
                    </Stack>
                    {secondInfo?.map(({ label, value }: any) => (
                        <Box key={uuidv4()}>
                            <Typography fontWeight={600}>{label}</Typography>
                            <Card component={"div"} className='card' style={cardInfo}>
                                <Typography>{value ? value : "Данні відсутні"}</Typography>
                            </Card>
                        </Box>
                    ))}
                    <TabContext value={fileTypeTab} >
                        <Stack rowGap={2}>
                            <Typography fontWeight={600}>Файли</Typography>
                            <TabList variant="fullWidth" className='tabsMain' onChange={handleChangeFileTypeTab} aria-label="create fixed asset tabs">
                                {FILE_TYPE_TABS_CREATE_FIXED_ASSET?.map(({ label, value }) => (
                                    <Tab key={value} value={value} label={label + ` (${files[`${value}`].length})`} style={tabList as CSSProperties} />
                                ))}
                            </TabList>
                        </Stack>
                        {FILE_TYPE_TABS_CREATE_FIXED_ASSET.map((_, index) => (
                            <TabPanel key={uuidv4()} value={FILE_TYPE_TABS_CREATE_FIXED_ASSET.findIndex(item => item.value === fileTypeTab)} index={index}>
                                <Stack rowGap={"10px"}>
                                    {files[`${fileTypeTab}`]?.map(({ name, path }: { name: string; path: string; }, index: number) => (
                                        <Card component={"div"} className='card' style={cardFile} sx={{ py: "8px" }} key={uuidv4()}>
                                            <Stack direction={"row"} sx={{ alignItems: "center" }} columnGap={"9px"}>
                                                <IconButton onClick={() => handleDownloadFile({ path, name })}><DownloadIcon /></IconButton>
                                                <Typography component={"span"}>{name}</Typography>
                                            </Stack>
                                        </Card>
                                    ))}
                                </Stack>
                            </TabPanel>
                        ))}
                    </TabContext>
                </>}
        </Stack>
    );
};
