import { Button, Divider, IconButton, Stack, SvgIcon, TextField, Theme, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { ChangeEvent, FC, useMemo } from "react";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useRelatedServiceList } from "../presenter/useRelatedServiceList";
import { ExpenditureAutocomplete } from "../../../../../../../ui-kit/expenditureAutocomplete/ui";
import { useTranslation } from "react-i18next";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { tokens } from "../../../../../../../locales/tokens";
import { getStyles } from "./styles";
import { PartnerServiceAutocomplete } from "../../../../../../../ui-kit/partnerServiceAutocomplete/ui";
import { PAYMENT_STATUS } from "../../../../../../../config";

interface IProps {
    isDisabled?: boolean;
    paymentId: any;
    status: string;
};

export const RelatedServices: FC<IProps> = ({ paymentId, status, isDisabled }) => {
    const { t } = useTranslation();
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const styles = useMemo(() => getStyles(), []);
    const { handleChangePartner, changePartnerText, addRelatedService, changeExpenseItemText, handleChangeExpenseItem, onChangeSum, deleteService,
        relatedServices, relatedServicesValid, onChangeComment, currentType, expenditure, formStatus } = useRelatedServiceList(paymentId);
    const currentStatus = status || formStatus;
 
    return (
        <>
            {(currentType?.key === "service_receiving" && relatedServices.length > 0) &&
                <Stack>
                    {relatedServices.map((service: any, index: number) =>
                        <Stack key={index} position="relative" pt={2}>
                            <Divider><Typography variant="subtitle2">{t(tokens.service.relatedService)}</Typography></Divider>
                            <Tooltip title={t(tokens.service.deleteRelatedService)}>
                                <IconButton sx={styles.deleteButton} onClick={() => deleteService(service.id, index)} disabled={status === PAYMENT_STATUS.OPERATED || isDisabled}>
                                    <SvgIcon fontSize="medium" color="error"><DeleteForeverIcon /></SvgIcon>
                                </IconButton>
                            </Tooltip>
                            <Stack direction={downSm ? 'column' : 'row'} spacing={3} alignItems='center' pt={2}>
                                <PartnerServiceAutocomplete
                                    index={index}
                                    label={t(tokens.payment.partner)}
                                    value={service.counterparty}
                                    isValid={relatedServicesValid[index]?.counterpartyValid}
                                    onPartnerChange={handleChangePartner}
                                    changeText={changePartnerText}
                                    isDisabled={isDisabled}
                                />
                                <ExpenditureAutocomplete
                                    index={index}
                                    value={service.expenditure}
                                    isValid={relatedServicesValid[index]?.expenditureValid}
                                    handleChangeValue={handleChangeExpenseItem}
                                    isDisabled={isDisabled}
                                />
                            </Stack>
                            <Stack spacing={3} direction="row">
                                <Stack direction='column' spacing={1} flex={1}>
                                    <Typography color={relatedServicesValid[index]?.amountValid ? 'primary' : 'error'} variant={downSm ? 'body1' : 'h6'}>
                                        {t(tokens.payment.sum)}
                                    </Typography>
                                    <TextField
                                        type="number"
                                        fullWidth
                                        value={service.amount}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeSum(e, index)}
                                        label={t(tokens.payment.sum)}
                                        error={!relatedServicesValid[index]?.amountValid}
                                        size="small"
                                        disabled={isDisabled}
                                    />
                                </Stack>
                                <Stack flex={1} spacing={1}>
                                    <Typography color={relatedServicesValid[index]?.commentValid ? 'primary' : 'error'} variant={downSm ? 'body1' : 'h6'}>
                                        {t(tokens.service.comment)}
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        value={service.comment}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeComment(e, index)}
                                        label={t(tokens.service.comment)}
                                        error={!relatedServicesValid[index]?.commentValid}
                                        size="small"
                                        disabled={isDisabled}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                    )}
                </Stack>}
            {currentType?.key === "service_receiving" &&
                <Divider sx={{ py: 2 }}>
                    <Button
                        variant="outlined"
                        color="inherit"
                        onClick={() => addRelatedService()}
                        disabled={
                            currentStatus === PAYMENT_STATUS.OPERATED
                            || isDisabled}
                        startIcon={<AddCircleIcon fontSize="large" />}
                    >
                        {t(tokens.service.addRelatedService)}
                    </Button>
                </Divider>}
        </>
    )
};