import { Button, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { TextInput } from "../../../../ui-kit/textInput/textInput";
import { usePartnerCreate } from "../presenters/usePartnerCreate";
import { getStyles } from "./styles";
import { FolderAutocomplete } from "./components/folderAutocomplete";

export const PartnerCreate: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { name, edrpou, onChangeName, onChangeEdrpou, createClick, handleCreate, nameValid, edrpouValid, changeFolderText,
        handleChangeFolder, folder, partnerFolders, bankName, onChangeBankName, desc, onChangeDesc } = usePartnerCreate();
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return (
        <Stack direction='row' alignItems='flex-start' spacing={15} mb={3}>
            <Stack spacing={3} width={'600px'} pt={3}>
                <Typography variant="h4" fontWeight={500}>{t(tokens.partner.createPartner)}</Typography>
                <TextInput
                    disabled={false}
                    title={t(tokens.partner.name) + "*"}
                    label={t(tokens.partner.name)}
                    value={name}
                    isValid={nameValid}
                    isTextArea={false}
                    errorText={t(tokens.partner.specifyName)}
                    onChangeValue={onChangeName}
                />
                <TextInput
                    disabled={false}
                    title={t(tokens.partner.edrpou) + "*"}
                    label={t(tokens.partner.edrpou)}
                    value={edrpou}
                    isValid={edrpouValid}
                    isTextArea={false}
                    errorText={t(tokens.partner.specifyEdrpou)}
                    onChangeValue={onChangeEdrpou}
                />
                <TextInput
                    disabled={false}
                    title={t(tokens.partner.bankName)}
                    label={t(tokens.partner.bankName)}
                    value={bankName}
                    isValid={true}
                    isTextArea={false}
                    onChangeValue={onChangeBankName}
                />
                <TextInput
                    disabled={false}
                    title={t(tokens.partner.urName)}
                    label={t(tokens.partner.urName)}
                    value={desc}
                    isValid={true}
                    isTextArea={true}
                    onChangeValue={onChangeDesc}
                />
                <Stack pt={4}>
                    <FolderAutocomplete
                        title={t(tokens.partner.folder)}
                        value={folder}
                        handleChangeValue={handleChangeFolder}
                        isValid={true}
                        optionList={partnerFolders}
                        changeText={changeFolderText}
                    />
                </Stack>
                <Stack pt={2}>
                    <Button
                        variant="contained"
                        sx={styles.confirmButton}
                        size="large"
                        onClick={handleCreate}
                        disabled={createClick}
                    >
                        {t(tokens.common.create)}
                    </Button>
                </Stack>
            </Stack>
            {!mdDown ?
                <Stack pt={3}>
                    <img src="/images/account/partners.png" alt="" width='450px' height='500px' />
                </Stack> : <></>}
        </Stack>
    )
};