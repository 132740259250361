import { TextField } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";

interface IProps {
    phone: string;
    phoneValid: boolean;
    isPhoneInBD: boolean;
    onChangePhone: (event: ChangeEvent<HTMLInputElement>) => void;
    handlePhone: () => void;
}

export const PhoneField: FC<IProps> = ({ phone, handlePhone, onChangePhone, phoneValid, isPhoneInBD }) => {
    const { t } = useTranslation();

    return (
        <TextField
            disabled={isPhoneInBD ? true : false}
            fullWidth
            label={t(tokens.auth.phone)}
            name="phone"
            value={phone}
            onClick={handlePhone}
            onFocus={handlePhone}
            onChange={onChangePhone}
            error={!phoneValid}
            helperText={!phoneValid ? t(tokens.auth.validation.phoneValid) : ''}
            size="medium"
        />
    )
}