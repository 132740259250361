import { Dispatch, FC, SetStateAction, useCallback, useMemo } from "react";
import { CalendarView, ViewOption } from "../../../../../../types/payment-calendar";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import { useMediaQuery, Theme, Button } from "@mui/material";
import { getStyles } from "./styles";
import { useGetPermissions } from "../../../../../../hooks/useGetPermission";

interface IProps {
    view: CalendarView;
    onViewChange?: (view: CalendarView) => void;
    setCurrentDay: Dispatch<SetStateAction<number>>;
};

export const ViewGroupButtons: FC<IProps> = ({ onViewChange, view, setCurrentDay }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const { isGet_List_Day_Payment } = useGetPermissions();

    const viewOptions: ViewOption[] = [
        { label: t(tokens.payment.monthView), value: 'dayGridMonth', disabled: false },
        { label: t(tokens.payment.weekView), value: 'listWeek', disabled: false },
        { label: t(tokens.payment.dayView), value: 'listDay', disabled: !isGet_List_Day_Payment },
    ];

    const availableViewOptions = useMemo(() => {
        return mdUp
            ? viewOptions
            : viewOptions.filter((option) => ['timeGridDay', 'listWeek'].includes(option.value));
    }, [mdUp]);

    const handleViewChange = useCallback((value: string): void => {
        onViewChange?.(value as CalendarView);
        setCurrentDay(1);
    }, [onViewChange]);

    return (
        <>
            {availableViewOptions.map(option =>
                <Button
                    variant='contained'
                    key={option.value}
                    size="small"
                    disabled={option.disabled}
                    sx={view === option.value ?
                        styles.viewActive
                        :
                        styles.viewNoActive}
                    onClick={() => handleViewChange(option.value)}
                >
                    {option.label}
                </Button>
            )}
        </>
    );
};