import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { setUsers } from "../../repository/actions";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";

function* getUsersWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.get, "/zoolux-api/users", payload);
        if (isError) {
            toast.error("користувачі не знайдені")
        } else {
            yield put(setUsers(data.rows));
        }
    } catch (error) {
        console.error("getUsersWorker: ", error);
    }
};

export function* getUsersWatcher() {
    yield takeLatest("@saga/GET_USERS", getUsersWorker);
};
