import { useMemo, type FC } from 'react';
import type { Theme } from '@mui/material';
import { Button, IconButton, Stack, SvgIcon, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../../locales/tokens';
import ClearIcon from '@mui/icons-material/Clear';
import { getStyles } from './styles';
import { TextInput } from '../../../../../../ui-kit/textInput/textInput';
import { FieldSelect } from '../../../../accountEdit/ui/components/fieldSelect';
import { useAccountEdit } from '../../../presenters/useAccountEdit';
import { useGetPermissions } from '../../../../../../hooks/useGetPermission';
import { CardAutocomplete } from '../cardAutocomplete';

interface IProps {
  account: any;
  filters: any;
  onApprove?: () => void;
  onEdit?: () => void;
  onReject?: () => void;
};

export const AccountDetails: FC<IProps> = ({ onApprove, onEdit, onReject, account, filters }) => {
  const { t } = useTranslation();
  const styles = useMemo(() => getStyles(), []);
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const { name, onChangeName, accountType, handleChangeAccountType, accountTypeList, inputAccounts, parent, onChangeMainAccount,
    changeCompanyAccountText, handleEdit, nameValid, accountStatusList, status, handleChangeStatus } = useAccountEdit(account, filters);
  const { isUpdate_Company_Account } = useGetPermissions();
  
  return (
    <>
      <Stack alignItems="center" direction="row" justifyContent="space-between" sx={styles.code} >
        <Typography color="inherit" variant="body1" ></Typography>
        <IconButton color="inherit" onClick={onApprove}>
          <SvgIcon><ClearIcon /></SvgIcon>
        </IconButton>
      </Stack>
      <Stack m={2}>
        <Typography variant="h5" fontWeight={600}>{t(tokens.account.editAccount)}</Typography>
        <Stack width={lgUp ? '450px' : '350px'} mt={5} spacing={3}>
          <TextInput
            disabled={false}
            title={t(tokens.account.shortName)}
            label={t(tokens.account.shortName)}
            value={name}
            isValid={nameValid}
            isTextArea={false}
            errorText={t(tokens.account.titleRequired)}
            onChangeValue={onChangeName}
          />
          <FieldSelect
            title={t(tokens.account.accountType)}
            value={accountType}
            handleValue={handleChangeAccountType}
            options={accountTypeList}
          />
          <CardAutocomplete
            title={t(tokens.account.mainAccount)}
            value={parent}
            handleChangeValue={onChangeMainAccount}
            isValid={true}
            optionList={inputAccounts}
            changeText={changeCompanyAccountText}
          />
          <FieldSelect
            title={t(tokens.account.status)}
            value={status}
            handleValue={handleChangeStatus}
            options={accountStatusList}
          />
          <Stack pt={2}>
            <Button
              variant="contained"
              size="large"
              sx={{ color: '#FFFFFF' }}
              onClick={handleEdit}
              disabled={!isUpdate_Company_Account}
            >
              {t(tokens.common.edit)}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
