import { FC, useMemo } from "react";
import { useServiceHistoryList } from "../presenters/useServiceHistoryList";
import { Button, Stack, SvgIcon, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import AddIcon from '@mui/icons-material/Add';
import { getStyles } from "./styles";
import { paths } from "../../../../paths";
import { Link } from "react-router-dom";
import { SearchInput } from "../../../../ui-kit/searchInput/searchInput";
import { ServiceHistoryTable } from "./components/serviceHistoryTable";
import { ServiceFilters } from "./components/serviceFilters";
import { ServiceChips } from "./components/serviceChips";
import { SearchServiceSelector } from "./components/searchServiceSelector";
import { EmptyList } from "../../../../ui-kit/emptyList";

export const ServiceHistoryList: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { serviceHistoryList, serviceAmount, handlePageChange, handleSearchText, searchText, filters, handleChipsDelete, chips,
        setChips, handleStatusPayments, handleSortChange } = useServiceHistoryList();

    return (
        <Stack>
            <Stack direction='row' justifyContent='space-between' mt={3}>
                <Typography variant="h4">{t(tokens.service.serviceList)}</Typography>
                <Button variant="contained" size="small" sx={styles.createBtn} startIcon={(<SvgIcon><AddIcon /></SvgIcon>)} component={Link} to={paths.dashboard.serviceHistory.create}>
                    {t(tokens.serviceHistory.createService)}
                </Button>
            </Stack>
            <ServiceChips chips={chips} handleChipsDelete={handleChipsDelete} />
            <Stack mt={1} direction="row" alignItems="center" spacing={1}>
                <ServiceFilters chips={chips} setChips={setChips} handleStatusPayments={handleStatusPayments} />
                <Stack direction="row" spacing={1} width="100%"> 
                    <SearchInput searchText={searchText} label={t(tokens.service.searchByTitle)} handleSearch={handleSearchText} />
                    <SearchServiceSelector value={filters.search_name} handleSortChange={handleSortChange} />
                </Stack>
            </Stack>
            {serviceHistoryList?.length > 0 ?
                <ServiceHistoryTable
                    serviceList={serviceHistoryList}
                    currentPage={filters.page}
                    pageAmount={serviceAmount}
                    handlePageChange={handlePageChange}
                />
                :
                <EmptyList
                    title={t(tokens.serviceHistory.listEmpty)}
                    description={t(tokens.serviceHistory.emptyListDesc)}
                />
            }
        </Stack>
    )
};