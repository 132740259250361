export const getStyles = () => {
    const styles = {
        paper: { 
            maxHeight: 300, 
            marginTop: '5px' 
        },
        select: { 
            display: 'flex', 
            flexWrap: 'wrap' 
        },
        chip: { 
            mt: 1, 
            mr: 1 
        }
    };
    return styles;
};