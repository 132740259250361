import { Pagination, Stack, Table } from "@mui/material";
import { ChangeEvent, FC, useMemo } from "react";
import { NomenclatureTableHead } from "../nomenclatureTableHead";
import { NomenclatureTableBody } from "../nomenclatureTableBody";
import { getStyles } from "./styles";
import { INomeclature } from "../../../../../store/nomenclature/repository/types";

interface IProps {
    nomenclatureList: INomeclature[];
    currentPage: number;
    pageAmount: number;
    handlePageChange: (event: ChangeEvent<unknown>, page: number) => void;
    handleUpdateNomenclature: (id: number, isActive: boolean) => void;
};

export const NomenclatureTable: FC<IProps> = ({ nomenclatureList, currentPage, handlePageChange, pageAmount, handleUpdateNomenclature }) => {
    const styles = useMemo(() => getStyles(), []);

    return (
        <Stack sx={styles.container}>
            <Table>
                <NomenclatureTableHead />
                <NomenclatureTableBody
                    nomenclatureList={nomenclatureList}
                    handleUpdateNomenclature={handleUpdateNomenclature}
                />
            </Table>
            <Stack direction="row" justifyContent="center" pt={4}>
                <Pagination
                    page={currentPage}
                    count={pageAmount || 1}
                    color="primary"
                    onChange={handlePageChange}
                />
            </Stack>
        </Stack>
    )
};