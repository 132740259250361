import { FC } from "react";
import { ImportPaymentHeader } from "./components/importPaymentHeader";
import { useImportList } from "../presenters/useImportList";
import { ImportsTable } from "./components/importsTable";
import { EmptyList } from "../../../../ui-kit/emptyList";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";

export const ImportList: FC = () => {
    const { t } = useTranslation();
    const { handleImportInEnot, handleImportInCalendar, handleDialogClose, handleFileUpload, isDialogOpen, file, uploadFile, uploadList,
        filters, pageAmount, handlePageChange, isItemOpen, onChangeItemOpen, rowId, uploadPayments } = useImportList();

    return (
        <>
            <ImportPaymentHeader
                file={file}
                isDialogOpen={isDialogOpen}
                uploadFile={uploadFile}
                handleImportInEnot={handleImportInEnot}
                handleImportInCalendar={handleImportInCalendar}
                handleDialogClose={handleDialogClose}
                handleFileUpload={handleFileUpload}
            />
            {
                uploadList?.length ?
                    <ImportsTable
                        page={filters.page}
                        uploadList={uploadList}
                        pageAmount={pageAmount}
                        uploadPayments={uploadPayments}
                        rowId={rowId}
                        isItemOpen={isItemOpen}
                        onChangeItemOpen={onChangeItemOpen}
                        handlePageChange={handlePageChange}
                    />
                    :
                    <EmptyList
                        title={t(tokens.common.emptyList)}
                        description={t(tokens.importPayment.emptyListDesc)}
                    />
            }
        </>
    )
};