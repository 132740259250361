import 'dayjs/locale/uk';
import { z } from 'zod';

const emptyMessage = "Це поле має бути заповненим";

const filesSizeIsValid = (files: any) => {
    if (files === null || files?.length === 0) {
        return true;
    }

    for (const file of files) {
        if (file.size > 25 * 1024 * 1024) {
            return false;
        }
    }

    return true;
};

const atLeastOneFileExists = ({ payment_files, service_files, instruction_files, other_files }: any) => {
    return (
        payment_files !== null && payment_files?.length > 0 ||
        service_files !== null && service_files?.length > 0 ||
        instruction_files !== null && instruction_files?.length > 0 ||
        other_files !== null && other_files?.length > 0
    );
};

export const upsertFixedAssetSchema = z.object({
    name: z.string().trim().min(1, { message: emptyMessage }),
    original_cost: z.string().trim().min(1, { message: emptyMessage }).regex(/^\d+(\.\d{1,2})?$/, { message: "Не більше 2 знаків після крапки" }),
    group_id: z.object({
        id: z.number().nullable(),
        value: z.number().nullable(),
        label: z.string().nullable()
    }).nullable().refine(val => val !== null && val.id !== null && val.value !== null && val.label !== null, { message: emptyMessage }),
    class_id: z.object({
        id: z.number().nullable(),
        value: z.number().nullable(),
        label: z.string().nullable()
    }).nullable().refine(val => val !== null && val.id !== null && val.value !== null && val.label !== null, { message: emptyMessage }),
    manufacturer: z.string().trim().min(1, { message: emptyMessage }),
    description: z.string().trim().min(1, { message: emptyMessage }),
    comment: z.string().trim().min(1, { message: emptyMessage }),
    location_id: z.object({
        id: z.number().nullable(),
        value: z.number().nullable(),
        label: z.string().nullable()
    }).nullable().refine(val => val !== null && val.id !== null && val.value !== null && val.label !== null, { message: emptyMessage }),
    responsible_id: z.object({
        id: z.number().nullable(),
        value: z.number().nullable(),
        label: z.string().nullable()
    }).nullable().refine(val => val !== null && val.id !== null && val.value !== null && val.label !== null, { message: emptyMessage }),
    serial_number: z.string().trim().min(1, { message: emptyMessage }),
    production_date: z.any().refine(val => val !== null, { message: emptyMessage }),
    guarantee_period: z.any().refine(val => val !== null, { message: emptyMessage }),
    purchase_date: z.any().refine(val => val !== null, { message: emptyMessage }),
    inventory_number: z.string().trim().length(10, { message: "Поле повинно містити рівно 10 символів" }),
    files: z.object({
        payment_files: z.any().nullable().refine(filesSizeIsValid, {
            message: "Платіжний файл перевищує 25 мб"
        }),
        service_files: z.any().nullable().refine(filesSizeIsValid, {
            message: "Сервісний файл перевищує 25 мб"
        }),
        instruction_files: z.any().nullable().refine(filesSizeIsValid, {
            message: "Інструкційний файл перевищує 25 мб"
        }),
        other_files: z.any().nullable().refine(filesSizeIsValid, {
            message: "Інші файл перевищує 25 мб"
        }),
    }).refine(atLeastOneFileExists, {
        message: "Один з файлів обовʼязковий"
    })

});

export type upsertFixedAssetSchema = z.infer<typeof upsertFixedAssetSchema>;