export const getStyles = () => {
    const styles = {
        toolbarContainer: {
            alignItems: "baseline",
            flexWrap: "wrap",
            justifyContent: "space-between",
            flexDirection: {
                xs: 'column',
                md: 'row'
            },
            px: 1
        },
        view: {
            minWidth: 120,
            order: {
                xs: -1,
                md: 0
            }
        },
        btnNew: {
            width: {
                xs: '100%',
                md: 'auto'
            },
            color: '#FFFFFF',
            textTransform: 'none'
        },
        viewActive: {
            backgroundColor: '#36B355',
            color: '#FFFFFF',
            '&:hover': {
                backgroundColor: '#36B355'
            },
        },
        viewNoActive: {
            backgroundColor: '#FEF49A',
            '&:hover': {
                backgroundColor: '#36B355',
                color: '#FFFFFF',
            },
        },
        inputDate: {
            cursor: 'pointer',
            width: '250px',
            height: '0px',
            border: '1px solid #36B355',
            borderRadius: '5px',
            fontSize: '14px'
        },
        chipLabel: {
            alignItems: "center",
            display: "flex",
            "& span": { fontWeight: 600 }
        },
        dateOption: {
            ":hover": { 
                backgroundColor: '#FEF49A' 
            }
        }
    };
    return styles;
}