import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";
import { getSingleUser } from "../getSingleUser/action";

function* deleteUserRoleWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.delete, "/zoolux-api/user-role/delete", payload);
        if (isError) {
            toast.error(message)
        } else {
            yield put(getSingleUser({id: payload.user_id}));
            toast.success("роль відключена")
        }
    } catch (error) {
        console.error("deleteRoleWorker: ", error);
    } finally {
        
    };
};

export function* deleteUserRoleWatcher() {
    yield takeLatest("@saga/DELETE_USER_ROLE", deleteUserRoleWorker);
};
