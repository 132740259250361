
import toast from 'react-hot-toast';
import { requester } from '../../../libs/requester/requester-axios';
import { convertToFormData } from '../../../utils';
import { zooluxAPI } from '../../zooluxAPI';
import { IParamsCreateFileFixedAsset, IResponseCreateFixedAsset } from '../repository/IFixedAsset';

const fixedAsset = zooluxAPI.injectEndpoints({
    endpoints: (builder) => ({
        createFilesFixedAsset: builder.mutation<IResponseCreateFixedAsset, IParamsCreateFileFixedAsset>({
            async queryFn({ id, files }, { dispatch }) {
                try {
                    const { data, isError, message }: any = await requester.post(`/zoolux-api/fixed-assets/${id}/add-files`, convertToFormData(files));
                    if (isError) {
                        toast.error(message);
                        throw new Error(message);
                    };
                    return { data };
                } catch (e) {
                    throw e;
                }
            },
            invalidatesTags: ["FixedAsset"]
        }),

    }),
    overrideExisting: false,
});

export const { useCreateFilesFixedAssetMutation } = fixedAsset;