import { FC, useMemo } from "react"
import { usePaymentReceipt } from "../presenters/usePaymentReceipt";
import { Box, Divider, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import { ReceiptField } from "./components/receiptField";
import dayjs from "dayjs";
import 'dayjs/locale/uk';
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";

export const PaymentReceipt: FC = () => {
    const { t } = useTranslation();
    const { singlePayment, watchCurrentFile } = usePaymentReceipt();
    const createDate = dayjs(singlePayment?.payment.create_date).locale('uk').format('MMMM D, YYYY, HH:mm:ss');
    const visaDate = dayjs(singlePayment?.payment.visa_date).locale('uk').format('MMMM D, YYYY, HH:mm:ss');
    const operationDate = dayjs(singlePayment?.payment.operation_date).locale('uk').format('MMMM D, YYYY, HH:mm:ss');
    const gettingProductDate = dayjs(singlePayment?.payment.prepayment?.prepayment_date).locale('uk').format('MMMM D, YYYY, HH:mm:ss');
    const sum = singlePayment?.payment.amount + ' грн.';
    const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

    const paymentMethodOptions = useMemo(() => {
        return {
            cash: t(tokens.payment.cash),
            'non-cash': t(tokens.payment['non-сash']),
            card: t(tokens.payment.card),
            report: t(tokens.payment.report)
        };
    }, [tokens, t]);

    const paymentKindOptions = useMemo(() => {
        return {
            postpayment: t(tokens.payment.postpayment),
            prepayment: t(tokens.payment.prepayment),
            "partial-payment": t(tokens.payment.partialPayment),
        };
    }, [tokens, t]);

    return (
        <>
            {singlePayment ?
                <Box mt={2}>
                    <Typography variant="h4">{t(tokens.payment.receipt.paymentNum)}{singlePayment?.payment.id}</Typography>
                    <Stack direction='row' justifyContent='space-between' position='relative' width='80%'>
                        <Stack direction='column' justifyContent='space-between' mt={3}>
                            <ReceiptField title={t(tokens.payment.receipt.paymentMethod)} data={paymentMethodOptions[singlePayment?.payment.payment_method?.key as keyof typeof paymentMethodOptions]} />
                            <ReceiptField title={t(tokens.payment.receipt.paymentKind)} data={paymentKindOptions[singlePayment?.payment.payment_kind?.key as keyof typeof paymentKindOptions]} />
                            <ReceiptField title={t(tokens.payment.receipt.paymentType)} data={singlePayment?.payment.payment_type.name} />
                            <ReceiptField title={t(tokens.payment.receipt.expenseItem)} data={singlePayment?.payment.expenditure.name} />
                        </Stack>
                        {downLg ? null : <img src="/images/logo/logo.png" style={{ position: 'absolute', right: 0 }} />}
                    </Stack>
                    <Divider sx={{ my: 1, width: '50%' }} />
                    <Stack direction='column' justifyContent='space-between' mt={1}>
                        <ReceiptField title={t(tokens.payment.receipt.sender)} data={singlePayment?.payment.company_account.organization} />
                        <ReceiptField title={t(tokens.payment.receipt.fopZoolux)} data={singlePayment?.payment.company_account.name} />
                    </Stack>
                    <Divider sx={{ my: 1, width: '50%' }} />
                    <Stack direction='column' justifyContent='space-between' alignItems='flex-start' mt={1}>
                        <ReceiptField title={t(tokens.payment.receipt.partner)} data={singlePayment?.payment.counterparty.name} />
                        <Stack direction='column'>
                            <ReceiptField title={t(tokens.payment.receipt.receiverAccount)} data={singlePayment?.payment.bankDetails?.account} />
                            <ReceiptField title={t(tokens.payment.receipt.bank)} data={singlePayment?.payment.bankDetails?.bank_name} />
                            <ReceiptField title={t(tokens.payment.receipt.mfo)} data={singlePayment?.payment.bankDetails?.bank_mfo} />
                        </Stack>
                    </Stack>
                    <Divider sx={{ my: 1, width: '80%' }} />
                    <ReceiptField title={t(tokens.payment.comment) + ':'} data={singlePayment?.payment.comment} />
                    <ReceiptField title={t(tokens.payment.serviceComment) + ':'} data={singlePayment?.payment.service_comment} />
                    <Stack direction='row' spacing={2} alignItems='flex-start' width='100%'>
                        <Typography variant="body1" fontWeight={700} >{t(tokens.payment.receipt.files)}</Typography>
                        <Stack spacing={1}>
                            {singlePayment?.payment.documents.map((file: any, index: number) =>
                                <Typography key={index} component='div' onClick={() => watchCurrentFile(file)} sx={{ cursor: 'pointer' }}>{file.name}</Typography>
                            )}
                        </Stack>
                    </Stack>
                    <ReceiptField title={t(tokens.payment.receipt.sum)} data={sum} />
                    <Stack direction='row' justifyContent='space-between' mt={5}>
                        <ReceiptField title={t(tokens.payment.receipt.initiator)} data={singlePayment?.payment.initiator?.full_name} />
                        <ReceiptField title={t(tokens.payment.receipt.createDate)} data={createDate} />
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <ReceiptField title={t(tokens.payment.receipt.financier)} data={singlePayment?.payment.financier?.full_name} />
                        <ReceiptField title={t(tokens.payment.receipt.visaDate)} data={visaDate} />
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <ReceiptField title={t(tokens.payment.receipt.operationist)} data={singlePayment?.payment.operacionist?.full_name} />
                        <ReceiptField title={t(tokens.payment.receipt.operationDate)} data={operationDate} />
                    </Stack>
                    {singlePayment?.payment.prepayment ?
                        <Stack my={3}>
                            <ReceiptField title={t(tokens.payment.receipt.responsible)} data={singlePayment?.payment.prepayment?.responsible.full_name} />
                            <ReceiptField title={t(tokens.payment.receipt.gettingProductDate)} data={gettingProductDate} />
                            {singlePayment?.payment.prepayment.approver ?
                                <ReceiptField title={t(tokens.payment.receipt.productReceive)} data={singlePayment?.payment.prepayment.approver.full_name} />
                                :
                                null
                            }
                        </Stack>
                        : null
                    }
                </Box>
                : null}
        </>
    )
};