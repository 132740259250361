import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setPaymentsWeek } from "../../repository/actions";

function* getPaymentsWeekWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.get, "/zoolux-api/payments/list", payload);
        if (isError) {

        } else {
            yield put(setPaymentsWeek({ list: data.data, count: data.currentPage }));
        }
    } catch (error) {
        console.error("getPaymentsWeekWorker: ", error);
    }
};

export function* getPaymentsWeekWatcher() {
    yield takeLatest("@saga/GET_PAYMENTS_WEEK", getPaymentsWeekWorker);
};
