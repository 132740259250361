import { TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getStyles } from "./styles";
import { tokens } from "../../../../../../locales/tokens";

export const ServiceHistoryTableHead: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    
    return(
        <TableHead >
            <TableRow sx={styles.header}>
            <TableCell align="center" width='20%'>
                    <Typography fontWeight={600}>{t(tokens.serviceHistory.createdDate)}</Typography>
                </TableCell>
                <TableCell align="center" width='20%'>
                    <Typography fontWeight={600}>{t(tokens.serviceHistory.partner)}</Typography>
                </TableCell>
                <TableCell align="center" width='20%'>
                    <Typography fontWeight={600}>{t(tokens.serviceHistory.sum)}</Typography>
                </TableCell>
                <TableCell align="center" width='20%'>
                    <Typography fontWeight={600}>{t(tokens.serviceHistory.status)}</Typography>
                </TableCell>
                <TableCell align="center" width='20%'>
                    <Typography fontWeight={600}>{t(tokens.serviceHistory.processDate)}</Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    )
};