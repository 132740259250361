import { Button } from "@mui/material";
import { FC } from "react";
import { getStyles } from "./styles";

interface IProps {
    title: string;
    handleButton: () => void;
}

export const ConfirmButton: FC<IProps> = ({ title, handleButton }) => {
    const styles = getStyles();

    return (
        <Button
            fullWidth
            variant="contained"
            size="large"
            sx={styles.loginBtn}
            onClick={handleButton}
        >
            {title}
        </Button>
    )
};