import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';

interface IState {
  isLoading: boolean;
  uploadEnotError: IError;
  uploadCalendarError: IError;
  getPaymentsError: IError;
  uploadList: any[];
  uploadsCount: number;
  uploadPayments: any[];
  paymentsCount: number;
};

const initialState: IState = {
  uploadEnotError: null,
  uploadCalendarError: null,
  getPaymentsError: null,
  isLoading: false,
  uploadList: [],
  uploadsCount: 0,
  uploadPayments:[],
  paymentsCount: 0,
};

const ImportPaymentSlice = createSlice({
  name: 'importPayment',
  initialState,
  reducers: {
    setUploadEnotError: (state, action: PayloadAction<IError>) => {
      state.uploadEnotError = action.payload;
    },
    setUploadCalendarError: (state, action: PayloadAction<IError>) => {
      state.uploadCalendarError = action.payload;
    },
    setGetPaymentsError: (state, action: PayloadAction<IError>) => {
      state.getPaymentsError = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setUploadList: (state, action: PayloadAction<any>) => {
      state.uploadList = action.payload.list;
      state.uploadsCount = action.payload.count;
    },
    setUploadPayments: (state, action: PayloadAction<any>) => {
      state.uploadPayments = action.payload.list;
      state.paymentsCount = action.payload.count;
    },
  },
});

export const { setLoading, setUploadEnotError, setUploadCalendarError, setUploadList, setUploadPayments, setGetPaymentsError } = ImportPaymentSlice.actions;
export const ImportPaymentReducer = ImportPaymentSlice.reducer;