import { Box, Button, IconButton, Stack, TextField, Typography } from "@mui/material"
import { ChangeEvent, FC } from "react";
import { getStyles } from "./styles";
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";

interface IProps {
    isDisabled?: boolean;
    title: string;
    isValid: boolean;
    message: string;
    icon: React.ReactNode;
    files: File[] | string[] | [];
    watchCurrentFile: (name: string) => void;
    handleDeleteFile: (file: any) => void;
    handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const FilePicker: FC<IProps> = ({ title, message, icon, files, handleFileChange, handleDeleteFile, watchCurrentFile, isValid, isDisabled }) => {
    const { t } = useTranslation();
    const styles = getStyles();

    return (
        <Stack spacing={1} width="100%">
            <Typography
                color={isValid ? 'primary' : 'error'}
                variant='h6'
            >
                {title}
            </Typography>
            <Button
                component="label"
                variant="contained"
                startIcon={icon}
                sx={styles.uploadBtn}
                disabled={isDisabled}
            >
                {t(tokens.payment.uploadFile)}
                <TextField type="file" onChange={handleFileChange} sx={styles.inputFile} />
            </Button>
            <Typography color='GrayText' variant='caption'>{message}</Typography>
            {files?.map((file: any, index) => {
                return (
                    <Box key={index}>
                        {file instanceof File ?
                            <Stack direction='row' alignItems='center' spacing={1}>
                                <Typography key={index}>{file.name}</Typography>
                                <IconButton onClick={() => handleDeleteFile(file)}><ClearIcon fontSize="small" /></IconButton>
                            </Stack>
                            : 
                            <Stack direction='row' alignItems='center' spacing={1}>
                                <Typography key={index} onClick={() => watchCurrentFile(file.path)} sx={{ cursor: 'pointer' }}>{file.name}</Typography>
                                <IconButton onClick={() => handleDeleteFile(file)}><ClearIcon fontSize="small" /></IconButton>
                            </Stack>
                        }
                    </Box>
                )
            })}
        </Stack>
    )
};