import { Box, ButtonBase, SvgIcon } from "@mui/material";
import { FC, ReactNode } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { getStyles } from "./styles";

interface IProps {
  disabled: boolean | undefined;
  active: boolean | undefined;
  depth: number;
  open: boolean;
  title: string;
  startIcon: ReactNode;
  children: any;
  offset: number;
  handleToggle: () => void;
  linkProps: any;
}

export const SideNavItemBtn: FC<IProps> = ({ disabled, handleToggle, active, depth, children, open, title, startIcon, offset, linkProps }) => {
  const styles = getStyles();

  return (
    <>
      <ButtonBase disabled={disabled} onClick={handleToggle} sx={active && depth === 0 ? { ...styles.btnSection, pl: `${16 + offset}px`, backgroundColor: 'secondary.main' } : { ...styles.btnSection, pl: `${16 + offset}px` }} {...linkProps}>
        {startIcon && (
          <Box component="span" sx={active ? { ...styles.sectionIcon, color: 'var(--nav-item-icon-active-color)' } : styles.sectionIcon} >
            {startIcon}
          </Box>
        )}
        <Box component="span" sx={active ? { ...styles.sectionTitle, color: 'var(--nav-item-active-color)' } : styles.sectionTitle} fontSize={depth > 0 ? 15 : 16} fontWeight={depth > 0 ? 500 : 600}>
          {title}
        </Box>
        {children && <SvgIcon sx={styles.arrows}>
          {open ? <ExpandMoreIcon /> : <ChevronRightIcon />}
        </SvgIcon>}
      </ButtonBase>
    </>
  );
};