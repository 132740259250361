import { ChangeEvent, useCallback, useState } from "react";
import { IExpenditureOption, IPartnerOption } from "../../../../../../../utils/collectOptionList";
import { useDebounce } from "../../../../../../../hooks/useDebounce";
import { useDispatch, useSelector } from "react-redux";
import { getPartners } from "../../../../../../../store/paymentCalendar/useCases/getPartners/action";
import {
    setRelatedServiceAmount, setRelatedServiceAmountValid, setRelatedServiceComment, setRelatedServiceCommentValid, 
    setRelatedServiceExpenditureValid, setRelatedServiceExpenditure, setRelatedServiceDelete, setResetRelatedServicesValid,
    setRelatedServicePartner, setRelatedServicePartnerValid, setRelatedServices, setRelatedServicesValid, 
} from "../../../../../../../store/paymentCreation/repository/slice";
import { getExpenditure } from "../../../../../../../store/paymentCalendar/useCases/getExpenditure/action";
import { selectPaymentCreation } from "../../../../../../../store/paymentCreation/repository/selector";
import { deleteServiceRelated } from "../../../../../../../store/serviceRelated/useCases/deleteServiceRelated/action";
import { selectPayments } from "../../../../../../../store/paymentCalendar/repository/selector";
import { setPaymentUpdated } from "../../../../../../../store/operationValidation/repository/slice";

export const useRelatedServiceList = (paymentId?: any) => {
    const dispatch = useDispatch();
    const [partnerSearch, setPartnerSearch] = useState('');
    const [expenseItemSearch, setExpenseItemSearch] = useState('');
    const { relatedServices, relatedServicesValid, paymentType, formStatus } = useSelector(selectPaymentCreation);
    const { paymentTypes, expenditure } = useSelector(selectPayments);
    const currentType = paymentTypes?.find(type => type.id === paymentType);

    const addRelatedService = () => {
        dispatch(setRelatedServices({ counterparty: null, expenditure: null, amount: '', comment: '' }));
        dispatch(setRelatedServicesValid({ counterpartyValid: true, expenditureValid: true, amountValid: true, commentValid: true }));
        dispatch(setPaymentUpdated(true));
    };

    const handleChangePartner = useCallback((value: IPartnerOption | null, index?: number) => {
        dispatch(setRelatedServicePartner({ value, index }));
        dispatch(setRelatedServicePartnerValid({ value: true, index }));
        dispatch(setPaymentUpdated(true));
    }, []);

    const changePartnerText = (event: ChangeEvent<HTMLInputElement>) => {
        setPartnerSearch(event.target.value);
        debouncedHandlePartnerSeacrh(event.target.value);
    };

    const onSearchPartner = (value: string) => {
        if (value.length) {
            dispatch(getPartners({ offset: 0, limit: 20, search: value }));
        } else {
            dispatch(getPartners({ offset: 0, limit: 20, search: '' }));
        }
    };

    const { debouncedWrapper: debouncedHandlePartnerSeacrh } = useDebounce(onSearchPartner, 400);

    const changeExpenseItemText = (event: ChangeEvent<HTMLInputElement>) => {
        setExpenseItemSearch(event.target.value);
        debouncedHandleExpenseItemSeacrh(event.target.value);
    };

    const onSearchExpenseItem = (value: string) => {
        if (value.length) {
            dispatch(getExpenditure({ offset: 0, limit: 20, search: value }));
        } else {
            dispatch(getExpenditure({ offset: 0, limit: 20, search: '' }));
        }
    };

    const { debouncedWrapper: debouncedHandleExpenseItemSeacrh } = useDebounce(onSearchExpenseItem, 400);

    const handleChangeExpenseItem = useCallback((value: IExpenditureOption | null, index?: number) => {
        dispatch(setRelatedServiceExpenditure({ value, index }));
        dispatch(setRelatedServiceExpenditureValid({ value: true, index }));
        dispatch(setPaymentUpdated(true));
    }, []);

    const onChangeSum = useCallback((event: ChangeEvent<HTMLInputElement>, index: number) => {
        dispatch(setRelatedServiceAmount({ value: event.target.value, index }));
        dispatch(setRelatedServiceAmountValid({ value: true, index }));
        dispatch(setPaymentUpdated(true));
    }, []);

    const onChangeComment = useCallback((event: ChangeEvent<HTMLInputElement>, index: number) => {
        dispatch(setRelatedServiceComment({ value: event.target.value, index }));
        dispatch(setRelatedServiceCommentValid({ value: true, index }));
        dispatch(setPaymentUpdated(true));
    }, []);

    const deleteService = (id: number, indexService: number) => {
        const newRelatedServiceList = relatedServices.filter((service: any, index: number) => index !== indexService);
        const newRelatedServiceValidList = relatedServicesValid.filter((service: any, index: number) => index !== indexService);
        if (paymentId && id) {
            dispatch(setRelatedServiceDelete(newRelatedServiceList));
            dispatch(deleteServiceRelated({ id }))
        } else {
            dispatch(setRelatedServiceDelete(newRelatedServiceList));
        };
        dispatch(setResetRelatedServicesValid(newRelatedServiceValidList));
        setPaymentUpdated(true);
    };

    return {
        handleChangePartner, changePartnerText, addRelatedService, changeExpenseItemText, handleChangeExpenseItem, onChangeSum, deleteService,
        relatedServices, relatedServicesValid, onChangeComment, currentType, expenditure, formStatus
    }
};