import { useDispatch, useSelector } from "react-redux";
import { selectPayments } from "../../../store/paymentCalendar/repository/selector";
import { ChangeEvent, useEffect } from "react";
import { useDebounce } from "../../../hooks/useDebounce";
import { getAccountList } from "../../../store/paymentCalendar/useCases/getAccountList/action";
import { selectPaymentCreation } from "../../../store/paymentCreation/repository/selector";

export const useCardAutocomplete = () => {
    const dispatch = useDispatch();
    const { accountList } = useSelector(selectPayments);
    const { paymentMethod, clinic } = useSelector(selectPaymentCreation);

    useEffect(() => {
        if (clinic) {
            dispatch(getAccountList({ offset: 0, limit: 20, search: '', owner_key: clinic.ref_key, account_type: paymentMethod.name_enote }));
        };
    }, [clinic]);

    const changeCompanyAccountText = (event: ChangeEvent<HTMLInputElement>) => {
        debouncedHandleAccountsSeacrh(event.target.value);
    };
    
    const onSearchAccounts = (value: any) => {
        if (value) {
            dispatch(getAccountList({ offset: 0, limit: 20, search: value, account_type: paymentMethod?.name_enote }));
        } else {
            dispatch(getAccountList({ offset: 0, limit: 20, search: '', account_type: paymentMethod?.name_enote }));
        }
    };

    const { debouncedWrapper: debouncedHandleAccountsSeacrh } = useDebounce(onSearchAccounts, 400);

    return { accountList, changeCompanyAccountText }
};