import { FC } from "react";
import { getStyles } from "./styles";
import { Box, IconButton, Stack, SvgIcon, Theme, useMediaQuery } from "@mui/material";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { LangSwitcher } from "../../../../../ui-kit/lang-switcher/lang-switcher";
import { ThemeSwitcher } from "../../../../../ui-kit/theme-switcher/theme-switcher";
import MenuIcon from '@mui/icons-material/Menu';
import { AccountButton } from "../../../../../ui-kit/account-button";

interface IProps{
    onNavOpen: () => void;
    isNavBarHiden?: boolean;
}

export const TopNav: FC<IProps> = ({onNavOpen, isNavBarHiden}) => {
    const styles = getStyles(isNavBarHiden);
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

    return (
        <Box component="header" sx={styles.header} >
            <Stack direction="row" spacing={2} sx={styles.headerContainer} >
                {!lgUp && (<IconButton onClick={onNavOpen}><SvgIcon><MenuIcon /></SvgIcon></IconButton>)}
                <Stack direction='row' alignItems='center' gap={1}>
                </Stack>
                <Stack direction='row'>
                    <AccountButton/>
                </Stack>
            </Stack>
        </Box>
    )
};
