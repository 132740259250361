import { FC } from "react";
import { useUsers } from "../presenters/useUsers";
import { UsersTable } from "./components/usersTable";
import { UsersSearch } from "./components/usersSearch";
import { Card } from "@mui/material";
import { getStyles } from "./styles";
import { Header } from "./components/header";

export const UserList: FC = () => {
    const styles = getStyles();
    const { usersList, usersAmount, handlePageChange, handleStatusChange, handleSortChange, searchTextRef, currentTab,
        handleSearchTextChange, handleToggleAll, selected, handleToggleOne, handleChangeSearch, updateUsers,
        currentPage, signInToUserAccount } = useUsers();

    return (
        <>
            <Header updateUsers={updateUsers} />
            <Card elevation={3} sx={styles.card} >
                <UsersSearch
                    users={usersList}
                    searchTextRef={searchTextRef}
                    currentTab={currentTab}
                    handleStatusChange={handleStatusChange}
                    handleSortChange={handleSortChange}
                    handleSearchTextChange={handleSearchTextChange}
                    handleChangeSearch={handleChangeSearch}
                />
                {usersList.length ?
                    <UsersTable
                        users={usersList}
                        usersCount={usersAmount}
                        selected={selected}
                        currentPage={currentPage}
                        handleToggleAll={handleToggleAll}
                        handleToggleOne={handleToggleOne}
                        handlePageChange={handlePageChange}
                        signInToUserAccount={signInToUserAccount}
                    />
                    :
                    null
                }
            </Card>
        </>
    )
};