import { Box } from "@mui/material";
import { FC, useMemo } from "react";
import { getStyles } from "./styles";

export const GirlImage: FC = () => {
    const styles = useMemo(() => getStyles(), []);
    
    return (
        <Box sx={styles.box} >
            <img src="/images/account/person-standing.png" />
        </Box>
    );
};