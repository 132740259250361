import 'dayjs/locale/uk';
import { SyntheticEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetFixedAssetQuery } from '../../../../store/fixedAsset';
import { FileType } from '../../types';
import { downloadFileWithURL } from '../../utils/downloadFileWithURL';
import dayjs from 'dayjs';
import { useGetPermissions } from '../../../../hooks/useGetPermission';

export const useDetailFixedAsset = () => {
    const { fixedAssetId } = useParams();

    const { Edit_Fixed_Assets = true } = useGetPermissions();

    const [fileTypeTab, setFileTypeTab] = useState<FileType>('payment_files');

    const { data: { name = null, original_cost = null, location = { name: null }, group = { name: null }, class: class_equipment = { name: null }, manufacturer = null, responsible = { full_name: null }, production_date = null, guarantee_period = null, purchase_date = null,
        inventory_number: inventory_number_backend = null, serial_number = null, description = null, comment = null, payment_files = [], service_files = [], instruction_files = [], other_files = [] } = {}, isLoading: isLoadingGetFixedAsset }
        = useGetFixedAssetQuery(Number(fixedAssetId), { refetchOnMountOrArgChange: true });
    const files: any = { payment_files, service_files, instruction_files, other_files };

    const handleChangeFileTypeTab = (_: SyntheticEvent, newValue: FileType) => {
        setFileTypeTab(newValue);
    };

    const handleDownloadFile = ({ path, name }: { path: string; name: string; }) => {
        downloadFileWithURL({ path, name });
    };

    const mainInfo = [
        { value: original_cost, label: "Сума" },
        { value: location?.name, label: "Склад" },
        { value: group?.name, label: "Група" },
        { value: class_equipment?.name, label: "Класс" },
        { value: manufacturer, label: "Виробник" },
        { value: inventory_number_backend, label: "Інвентарний номер" },
        { value: serial_number, label: "Серійний номер" },
        { value: production_date ? dayjs(production_date).format('DD.MM.YYYY') : null, label: "Дата виготовлення" },
        { value: guarantee_period ? dayjs(guarantee_period).format('DD.MM.YYYY') : null, label: "Гарантійний термін" },
        { value: purchase_date ? dayjs(purchase_date).format('DD.MM.YYYY') : null, label: "Дата покупки" }
    ];

    const secondInfo = [
        { label: "Відповідальний", value: responsible?.full_name },
        { label: "Опис", value: description },
        { label: "Коментар", value: comment }
    ];

    return {
        name, fixedAssetId, mainInfo, secondInfo, fileTypeTab, handleChangeFileTypeTab, files, handleDownloadFile, Edit_Fixed_Assets, isLoadingGetFixedAsset
    };
};
