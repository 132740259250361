import { Stack } from "@mui/material";
import { FC } from "react";
import { HistoryItem } from "../../../../../../ui-kit/historyItem";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import { useSelector } from "react-redux";
import { selectPaymentCreation } from "../../../../../../store/paymentCreation/repository/selector";

export const OperationHistory: FC = () => {
    const { t } = useTranslation();
    const {initiator, financier, operationist, createDate, visaDate, processPaymentDate } = useSelector(selectPaymentCreation);

    return (
        <Stack spacing={1} my={2}>
            {initiator ?
                <HistoryItem
                    direction="column"
                    date={createDate}
                    title={t(tokens.payment.placingApp)}
                    name={initiator.full_name}
                    spacing={0}
                />
                : null}
            {financier ?
                <HistoryItem
                    direction="column"
                    date={visaDate}
                    title={t(tokens.payment.visaedPayment)}
                    name={financier.full_name}
                    spacing={0}
                />
                : null}
            {operationist ?
                <HistoryItem
                    direction="column"
                    date={processPaymentDate}
                    title={t(tokens.service.serviceProccessed)}
                    name={operationist.full_name}
                    spacing={0}
                />
                : null}
        </Stack>
    )
};