import { ListItemButton, ListItemIcon, ListItemText, SvgIcon, Typography } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { getStyles } from './styles';

interface IProps {
    path: string;
    title: string;
    picture: ReactNode;
};

export const MenuItem: FC<IProps> = ({ path, title, picture }) => {
    const styles = getStyles();
    
    return (
        <ListItemButton component={Link} to={path} sx={styles.menuItem}>
            <ListItemIcon>
                <SvgIcon fontSize="small">
                    {picture}
                </SvgIcon>
            </ListItemIcon>
            <ListItemText primary={(
                <Typography variant="body1">
                    {title}
                </Typography>)}
            />
        </ListItemButton>
    );
};
