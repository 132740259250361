import { FC } from "react";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";

interface IProps {
    value: any;
    handleSortChange: (event: SelectChangeEvent<any>) => void;
};

export const SearchServiceSelector: FC<IProps> = ({ value, handleSortChange }) => {
    const { t } = useTranslation();

    const sortOptions: any[] = [
        { id: 1, label: t(tokens.payment.partner), value: 'COUNTERPARTY' },
        { id: 2, label: t(tokens.service.comment), value: 'COMMENT' },
        { id: 3, label: t(tokens.payment.operacionist), value: 'OPERACIONIST' },
        { id: 4, label: t(tokens.payment.initiator), value: 'INITIATOR' },
    ];

    return (
        <Stack alignItems='flex-start' spacing={1} width='50%'>
            <FormControl fullWidth size="small">
                <InputLabel id="select-search">{t(tokens.serviceHistory.searchFor)}</InputLabel>
                <Select
                    labelId="select-search"
                    id="select-search"
                    value={value}
                    label={t(tokens.serviceHistory.searchFor)}
                    onChange={handleSortChange}
                >
                    {sortOptions.map((option: any) =>
                        <MenuItem value={option.value} key={option.id}>
                            {option.label}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        </Stack>
    )
};