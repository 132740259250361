export const getStyles = () => {
    const styles = {
        createBtn: { 
            fontSize: '22px',
            textTransform: 'none',
            position: 'relative',
            bottom: '20px' 
        }
    };
    return styles;
};