import type { ChangeEvent, FC } from 'react';
import { Pagination, Stack, Table } from '@mui/material';
import { TableListHead } from '../tableListHead';
import { TableListBody } from '../tebleListBody';

interface IProps {
  partners: any[];
  pageAmount: number;
  currentPage: number;
  handleOrderOpen: (partnerId: string) => void;
  handlePageChange: (event: ChangeEvent<unknown>, page: number) => void;
};

export const PartnerListTable: FC<IProps> = ({ partners, handleOrderOpen, handlePageChange, pageAmount, currentPage }) => {

  return (
    <Stack>
      {partners.length ?
        <div>
          <Table>
            <TableListHead />
            <TableListBody
              partners={partners}
              handleOrderOpen={handleOrderOpen}
            />
          </Table>
          <Stack direction='row' justifyContent='center' my={4}>
            <Pagination
              count={pageAmount || 1}
              page={currentPage || 1}
              color="primary"
              onChange={handlePageChange}
            />
          </Stack>
        </div> : null}
    </Stack>
  );
};
