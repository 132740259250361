import { Box, InputAdornment, OutlinedInput, SvgIcon } from "@mui/material";
import { ChangeEvent, FC, FormEvent, MutableRefObject } from "react";
import SearchIcon from '@mui/icons-material/Search';

interface IProps {
    searchTextRef: MutableRefObject<HTMLInputElement | null>;
    handleSearchTextChange: (event: FormEvent<HTMLFormElement>) => void;
    handleChangeSearch: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

export const SearchInput: FC<IProps> = ({ searchTextRef, handleSearchTextChange, handleChangeSearch }) => {

    return (
        <Box component="form" onSubmit={handleSearchTextChange} sx={{ flexGrow: 1 }}>
            <OutlinedInput
                defaultValue=""
                fullWidth
                inputProps={{ ref: searchTextRef }}
                onChange={handleChangeSearch}
                placeholder="Пошук користувача"
                startAdornment={(
                    <InputAdornment position="start">
                        <SvgIcon>
                            <SearchIcon />
                        </SvgIcon>
                    </InputAdornment>
                )}
            />
        </Box>
    )
}