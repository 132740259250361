import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { Data, HeadCell } from "./types";
import { IWeekFilters } from "../../paymentList/presenters/types";
import { tokens } from "../../../../locales/tokens";
import { useTranslation } from "react-i18next";

export const usePaymentsForWeek = (weekFilters: IWeekFilters, setWeekFilters: Dispatch<SetStateAction<IWeekFilters>>, paymentsCount: number) => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState<readonly number[]>([]);
    const [paymentsAmount, setPaymentsAmount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        if (paymentsCount) {
            setPaymentsAmount(Math.ceil(paymentsCount / weekFilters.limit));
        }
    }, [paymentsCount]);

    const headTitles: HeadCell[] = [
        {
            id: 'PAYMENT_DATE',
            numeric: false,
            disablePadding: true,
            label: t(tokens.paymentWeek.paymentDate),
        },
        {
            id: 'COUNTERPARTY',
            numeric: true,
            disablePadding: false,
            label: t(tokens.paymentWeek.partner),
        },
        {
            id: 'AMOUNT',
            numeric: true,
            disablePadding: false,
            label: t(tokens.paymentWeek.amount),
        },
        {
            id: 'PAYMENT_METHOD',
            numeric: true,
            disablePadding: false,
            label: t(tokens.paymentWeek.paymentMethod),
        },
        {
            id: 'COMPANY_ACCOUNT',
            numeric: true,
            disablePadding: false,
            label: t(tokens.paymentWeek.account),
        },
        {
            id: 'INITIATOR',
            numeric: true,
            disablePadding: false,
            label: t(tokens.paymentWeek.initiator),
        },
        {
            id: 'OPERACIONIST',
            numeric: true,
            disablePadding: false,
            label: t(tokens.paymentWeek.operationist),
        },
        {
            id: 'COMMENT',
            numeric: false,
            disablePadding: true,
            label: t(tokens.paymentWeek.comment),
        },
    ];

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = weekFilters.orderBy === property && weekFilters.order === 'asc';
        setWeekFilters(prevState => ({ ...prevState, order: isAsc ? 'desc' : 'asc', orderBy: property }));
    };

    const handlePageChange = useCallback((event: ChangeEvent<unknown>, page: number): void => {
        setWeekFilters((prevState) => ({ ...prevState, offset: (page - 1) * weekFilters.limit }));
        setCurrentPage(page);
    }, [setWeekFilters]);

    return { headTitles, handleClick, handleRequestSort, selected, currentPage, paymentsAmount, handlePageChange }
};