import { ChangeEvent, FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { styles } from "./styles";
import { Button, IconButton, Stack, SvgIcon, TextField, ToggleButton, Tooltip } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { tokens } from "../../../../../locales/tokens";
import toast from "react-hot-toast";

interface IProps {
    isCodeHidden: boolean;
    codeValue: string;
    hideCode: () => void;
    updateSecretCode: () => void;
    onChangeCode: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const TelegramCodeTool: FC<IProps> = ({ codeValue, isCodeHidden, onChangeCode, updateSecretCode, hideCode }) => {
    const { t } = useTranslation();

    const handleCopy = useCallback(() => {
        navigator.clipboard.writeText(codeValue);
        toast.success(t(tokens.telegram.codeCopied));
    }, [codeValue]);

    return (
        <Stack direction='row' spacing={3} sx={styles.copyStack}>
            <Stack direction='row' alignItems="center">
                <TextField
                    label={t(tokens.telegram.secretCode)}
                    value={codeValue}
                    type={isCodeHidden ? 'password' : 'text'}
                    onChange={onChangeCode}
                    variant="outlined"
                    size="small"
                    sx={styles.secretCode}
                    InputProps={{
                        endAdornment: (
                            <>
                                <IconButton onClick={hideCode} sx={{position: 'absolute', right: '50px'}}>
                                    {isCodeHidden ?
                                        <VisibilityIcon fontSize="small" />
                                        :
                                        <VisibilityOffIcon fontSize="small" />
                                    }
                                </IconButton>
                                <Tooltip title={t(tokens.common.copy)}>
                                    <ToggleButton
                                        value="right"
                                        onClick={handleCopy}
                                        sx={styles.copyBtn}
                                    >
                                        <ContentCopyIcon fontSize="small" />
                                    </ToggleButton>
                                </Tooltip>
                            </>
                        ),
                    }}
                />
            </Stack>
            <Button
                startIcon={<SvgIcon fontSize="small">
                    <VpnKeyIcon fontSize="small" />
                </SvgIcon>
                }
                onClick={updateSecretCode}
                variant="contained"
                size="small"
                sx={styles.updateCodeBtn}
            >
                {t(tokens.telegram.changeCode)}
            </Button>
        </Stack>
    )
};