import type { FC, ReactNode } from 'react';
import { List } from '@mui/material';

interface IProps {
  children: ReactNode;
};

export const PropertyList: FC<IProps> = (props) => {
  const { children } = props;

  return (
    <List disablePadding>
      {children}
    </List>
  );
};
