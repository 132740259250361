import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useContractCreate } from "../presenters/useContractCreate";
import { Button, Stack, Typography } from "@mui/material";
import { tokens } from "../../../../locales/tokens";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { TimeDatePicker } from "../../../../ui-kit/timePicker";
import { TypeSelector } from "./components/typeSelector";
import { TextInput } from "./components/textInput";
import { getStyles } from "./styles";
import { FilePicker } from "./components/filePicker";

export const ContractCreate: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);

    const { type, handleChangeType, typeOptions, typeValid, commentValid, comment, onChangeComment, createContract,
        handleFileChange, watchCurrentFile, handleDeleteFile, files, startDate, endDate, handleStartDateChange, handleEndDateChange,
        startDateValid, endDateValid, fileValid, createClick } = useContractCreate();

    return (
        <Stack my={3}>
            <Typography variant="h4">{t(tokens.contracts.createContract)}</Typography>
            <Stack direction="row" mt={2} spacing={2}>
                <Stack flex={1} spacing={2}>
                    <TypeSelector
                        title={t(tokens.contracts.type) + '*'}
                        label={t(tokens.contracts.type)}
                        isValid={typeValid}
                        value={type}
                        options={typeOptions}
                        handleValue={handleChangeType}
                    />
                    <TextInput
                        title={t(tokens.contracts.comment) + '*'}
                        label={t(tokens.contracts.comment)}
                        isValid={commentValid}
                        value={comment}
                        symbolsAmount={512}
                        isMultiline={true}
                        rows={3}
                        onChangeValue={onChangeComment}
                    />
                    <Stack direction="row" spacing={2} alignItems="flex-end">
                        <TimeDatePicker
                            value={startDate}
                            isValid={startDateValid}
                            isdisabledPast={false}
                            title={t(tokens.contracts.startContract) + '*'}
                            handleDate={handleStartDateChange}
                        />
                        <TimeDatePicker
                            value={endDate}
                            isValid={endDateValid}
                            isdisabledPast={false}
                            title={t(tokens.contracts.endContract) + '*'}
                            handleDate={handleEndDateChange}
                        />
                    </Stack>
                    <Stack py={2}>
                        <Button
                            variant="contained"
                            onClick={createContract}
                            sx={styles.createBtn}
                            size="large"
                            disabled={createClick}
                        >
                            {t(tokens.contracts.createContract)}
                        </Button>
                    </Stack>
                </Stack>
                <Stack flex={1} spacing={2}>
                    <Stack width="100%">
                        <FilePicker
                            title={t(tokens.contracts.file) + '*'}
                            icon={<AttachFileIcon />}
                            isValid={fileValid}
                            message={t(tokens.payment.maxSize25)}
                            files={files}
                            watchCurrentFile={watchCurrentFile}
                            handleFileChange={handleFileChange}
                            handleDeleteFile={handleDeleteFile}
                            isDisabled={createClick}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
};