import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";
import { setInputAccounts } from "../../repository/slice";

function* getInputAccountsWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.get, "/zoolux-api/admin/company-accounts", payload);

        if (isError) {
            toast.error(message);
        } else {
            yield put(setInputAccounts(data.data));
        }
    } catch (error) {
        console.error("getInputAccountsWorker: ", error);
    } finally {

    }
};

export function* getInputAccountsWatcher() {
    yield takeLatest("@saga/GET_INPUT_ACCOUNTS", getInputAccountsWorker);
};

