import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { recoverPassword } from "../../../../store/auth/useCases/recoverPassword/action";
import { selectAuth } from "../../../../store/auth/repository/selector";
import { setVerify } from "../../../../store/auth/repository/actions";
import { confirmSmsCode } from "../../../../store/auth/useCases/confirmSmsCode/action";
import { createPassword } from "../../../../store/auth/useCases/createPassword/action";
import { useNavigate } from "react-router-dom";

export const useForgotPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isPhoneConfirmed, isLoading, isConfirmedSms } = useSelector(selectAuth);
    const [phone, setPhone] = useState('');
    const [phoneValid, setPhoneValid] = useState(true);
    const [isPhoneInBD, setIsPhoneInBD] = useState(false);
    const [smsCode, setSmsCode] = useState('');
    const [timerInSec, setTimerInSec] = useState(0);
    const [isCorrectSms, setCorrectSms] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState(true);
    const [isPassShown, setPassShown] = useState(false);
    const [confirmPass, setConfirmPass] = useState('');
    const [confirmPassValid, setConfirmPassValid] = useState(true);
    const [isConfirmPassShown, setConfirmPass2Shown] = useState(false);

    const handlePhone = () => {
        !phone.length && setPhone('+380');
    };

    const onChangePhone = (event: ChangeEvent<HTMLInputElement>) => {
        setPhoneValid(true);
        setPhone(event.target.value);
    };

    const validatePhone = (phoneNumber: string) => {
        const phonePattern = /^\+380\d{9}$/;
        return phonePattern.test(phoneNumber);
    };

    const handleEnterPassword = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };
    
    const handleCheckPhone = () => {
        const isPhoneValid = validatePhone(phone);
        setPhoneValid(isPhoneValid);
        if (isPhoneValid) {
            dispatch(recoverPassword({ phone: phone.slice(3) }));
        };
    };

    useEffect(() => {
        if (isPhoneConfirmed === false) {
            setIsPhoneInBD(true);
            setTimerInSec(59);
            return;
        } else if (isPhoneConfirmed === true) {
            setCorrectSms(true);
        }
    }, [isPhoneConfirmed]);

    const resendSmsCode = () => {
        dispatch(recoverPassword({ phone: phone.slice(3) }));
        dispatch(setVerify({isPhoneConfirmed: null}));
    };

    const onChangeSmsCode = (event: ChangeEvent<HTMLInputElement>) => {
        setSmsCode(event.target.value);
    };

    useEffect(() => {
        const timer = setInterval(() => {
            if (timerInSec > 0) {
                setTimerInSec(timerInSec - 1);
            };
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [timerInSec]);

    const onChangeTimer = () => {
        setTimerInSec(59);
    };

    const handleCheckSmsCode = () => {
        dispatch(confirmSmsCode({ phone: phone.slice(3), code: Number(smsCode) }))
    };

    useEffect(() => {
        if (isConfirmedSms) {
            setCorrectSms(true);
        }
    }, [isConfirmedSms]);

    const validatePassword = (password: string) => {
        // const passwordPattern = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z]).{8,}$/;
        // return passwordPattern.test(password);
        return password === password;
    };

    const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
        setPasswordValid(true);
        setPassword(event.target.value);
    };

    const onShowPassword = () => {
        setPassShown(!isPassShown)
    };

    const onChangeConfirmPassword = (event: ChangeEvent<HTMLInputElement>) => {
        setConfirmPassValid(true);
        setConfirmPass(event.target.value);
    };

    const onShowConfirmPassword = () => {
        setConfirmPass2Shown(!isConfirmPassShown)
    };

    const handleLogin = () => {
        const isPasswordValid = validatePassword(password);
        const isPhoneValid = validatePhone(phone);

        setPasswordValid(isPasswordValid);
        setPhoneValid(isPhoneValid);

        if (isPasswordValid && isPhoneValid) {

            if (isCorrectSms && isPhoneInBD) {
                if (password !== confirmPass) {
                    setConfirmPassValid(false);
                    return;
                }
                dispatch(createPassword({ password: password }));
                navigate('/');
            };
        }
    };

    return {
        phone, handlePhone, onChangePhone, phoneValid, isPhoneInBD, isCorrectSms, handleCheckPhone, handleCheckSmsCode, resendSmsCode,
        smsCode, onChangeSmsCode, timerInSec, onChangeTimer, password, confirmPass, passwordValid, isPassShown, confirmPassValid,
        isConfirmPassShown, onShowPassword, onChangeConfirmPassword, onShowConfirmPassword, onChangePassword, handleLogin, isLoading,
        handleEnterPassword
    };
};