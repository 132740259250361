export const setLoading = (payload: boolean) => ({
    type: "SET_LOADING",
    payload
});

export const setVerify = (payload: { isPhoneConfirmed: boolean | null}) => ({
    type: "SET_VERIFY",
    payload
});

export const setLogout = () => ({
    type: "LOGOUT",
});

export const setCurrentUser = (payload: any) => ({
    type: "SET_CURRENT_USER",
    payload
});

export const setConfirmedSms = (payload: boolean) => ({
    type: "SET_CONFIRMED_SMS",
    payload
});
