export const getStyles = () => {
    const styles = {
        main: {
            display: 'flex',
            flex: '1 1 auto',
            overflow: 'hidden',
            position: 'relative',
            mb: 2,
            overflowX: 'auto' 

        },
        container: {
            display: 'flex',
            flex: 1,
        },
        card: { 
            p: 3, 
            borderRadius: 4, 
            m: 1 ,
        },
        header: { 
            justifyContent: 'space-between',
            mt: 2 
        },
        createBtn: { 
            borderRadius: '4px', 
            color: '#FFFFFF' 
        }
    };
    return styles;
};