import { useDispatch, useSelector } from "react-redux";
import { selectPaymentCreation } from "../../../../../../../../store/paymentCreation/repository/selector";
import { useEffect } from "react";
import { setComment, setCommentForService, setExpenseItem, setPartner, setPrepaymentDate, setServiceAmount, setServiceExpenseItem, setServicePartner, setSum } from "../../../../../../../../store/paymentCreation/repository/slice";

export type TScreenFlag = "PAYMENT" | "SERVICE";

export const useServicePayment = (screenFlag: TScreenFlag = 'PAYMENT', source: any) => {
    const { commentForService, servicePartner, serviceExpenseItem, serviceAmount, comment, sum, partner, expenseItem, serviceCompletionDate, paymentKind } = useSelector(selectPaymentCreation);
    const dispatch = useDispatch();

    useEffect(() => {
        if (screenFlag === 'SERVICE' || (screenFlag === 'PAYMENT' && source === "SERVICE")) {
            dispatch(setComment(commentForService));
        }
    }, [commentForService]);

    useEffect(() => {
        if (screenFlag === 'SERVICE' || (screenFlag === 'PAYMENT' && source === "SERVICE")) {
            dispatch(setPartner(servicePartner));
        }
    }, [servicePartner]);

    useEffect(() => {
        if (screenFlag === 'SERVICE' || (screenFlag === 'PAYMENT' && source === "SERVICE")) {
            dispatch(setExpenseItem(serviceExpenseItem));
        }
    }, [serviceExpenseItem]);

    useEffect(() => {
        if (screenFlag === 'SERVICE' || (screenFlag === 'PAYMENT' && source === "SERVICE")) {
            dispatch(setSum(serviceAmount));
        }
    }, [serviceAmount]);

    useEffect(() => {
        if (screenFlag === 'SERVICE') {
            dispatch(setPrepaymentDate(serviceCompletionDate));
        }
    }, [paymentKind, serviceCompletionDate]);

    useEffect(() => {
        if (screenFlag === 'PAYMENT' && source !== "SERVICE") {
            dispatch(setCommentForService(comment));
        }
    }, [comment]);

    useEffect(() => {
        if (screenFlag === 'PAYMENT' && source !== "SERVICE") {
            dispatch(setServicePartner(partner));
        }
    }, [partner]);

    useEffect(() => {
        if (screenFlag === 'PAYMENT' && source !== "SERVICE") {
            dispatch(setServiceExpenseItem(expenseItem));
        }
    }, [expenseItem]);

    useEffect(() => {
        if (screenFlag === 'PAYMENT' && source !== "SERVICE") {
            dispatch(setServiceAmount(sum));
        }
    }, [sum]);
};