import { SagaIterator } from "redux-saga";
import { put, call, takeEvery } from "redux-saga/effects";
import { requester } from "../../../../libs/requester/requester-axios";
import { IResponse } from "../../../../libs/requester/IResponse";
import { setLoading, setSingleService, setUpdateServicePaymentError } from "../../repository/slice";
import toast from "react-hot-toast";

function* updateSingleServicePaymentWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, isError, message }: IResponse<any> = yield call(requester.put, "/zoolux-api/services", payload.body);
        if (isError) {
            yield put(setUpdateServicePaymentError(message));
        } else {
            yield put(setUpdateServicePaymentError(null));
            yield put(setSingleService(data));
            toast.success('Сервіс оновлено');
        }
    } catch (error) {
        console.error("updateSingleServicePaymentWorker: ", error);
    } finally {
        yield put(setLoading(false));
    }
};

export function* updateSingleServicePaymentWatcher() {
    yield takeEvery("@saga/UPDATE_SINGLE_SERVICE_PAYMENT", updateSingleServicePaymentWorker);
};