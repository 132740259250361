import { Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";

export const UserCreate: FC = () => {
    const { t } = useTranslation();
    return(
        <>
            <Typography variant="h3">{t(tokens.common.create)}</Typography>
        </>
    )
}