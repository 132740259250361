import { SagaIterator } from "redux-saga";
import { put, call, takeEvery } from "redux-saga/effects";
import { requester } from "../../../../libs/requester/requester-axios";
import { IResponse } from "../../../../libs/requester/IResponse";
import { setLoading, setDeleteContractError } from "../../repository/slice";

function* deleteContractWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, isError, message }: IResponse<any> = yield call(requester.delete, "/zoolux-api/document-counterparty/delete/" + payload.id);
        if (isError) {
            yield put(setDeleteContractError(message));
        } else {
            yield put(setDeleteContractError(null));
        }
    } catch (error) {
        console.error("deleteContractWorker: ", error);
    } finally {
        yield put(setLoading(false));
    }
};

export function* deleteContractWatcher() {
    yield takeEvery("@saga/DELETE_CONTRACT", deleteContractWorker);
};