import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setUploadCalendarError } from "../../repository/slice";

function* uploadImportCalendarWorker({ payload }: any): SagaIterator {
    try {
        const formData = new FormData();
        for (const [key, value] of Object.entries(payload as Object)) {
            const isValue = value !== null && value !== undefined && value !== "";
            if (isValue) {
                if (Array.isArray(value)) {
                    value.forEach(file => {
                        formData.append(key, file);
                    });
                } else {
                    formData.append(key, value);
                }
            }
        }; 
        yield put(setLoading(true));
        const { data, isError, message }: IResponse<any> = yield call(requester.post, "/zoolux-api/admin/import-payment", formData);
        if (isError) {
            yield put(setUploadCalendarError(message));
        } else {
            yield put(setUploadCalendarError(null));
        }
    } catch (error) {
        console.error("uploadImportCalendarWorker: ", error); 
    } finally {
        yield put(setLoading(false));
    };
};

export function* uploadImportCalendarWatcher() {
    yield takeLatest("@saga/UPLOAD_IMPORT_CALENDAR", uploadImportCalendarWorker);
};
