export const getStyles = () => {
    const styles = {
        status: { 
            borderRadius: '15px', 
            px: 2, 
            py: .5, 
            color: '#FFFFFF' 
        },
        tableRow: {
            cursor: "pointer",
            textDecoration: 'none'
        }
    };
    return styles;
};