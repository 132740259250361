export const getStyles = () => {
    const styles = {
        chipLabel: { 
            alignItems: "center", 
            display: "flex", 
            "& span": { fontWeight: 600 } 
        },
        noFilters: { 
            pl: 2, 
            height: '32px', 
            position: 'relative',
            top: '5px'
        },
        reset: { 
            borderRadius: '50px', 
            textTransform: 'none', 
            color: '#FFFFFF', 
            px: 2,
        },
        chip: { 
            fontSize: '12px' 
        }
    };
    return styles;
};