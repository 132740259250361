import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';

interface IState {
  isLoading: boolean;
  configuration: { ENOTE_URL: string; ORGANIZATION_REF_KEY: string; } | null;
  error: IError;
};

const initialState: IState = {
  isLoading: false,
  configuration: null,
  error: null
};

const ConfigurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    setConfiguration: (state, action: PayloadAction<{ ENOTE_URL: string; ORGANIZATION_REF_KEY: string; } | null>) => {
      state.configuration = action.payload;
    },
    setConfigurationError: (state, action: PayloadAction<IError>) => {
      state.error = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setConfiguration, setConfigurationError, setLoading } = ConfigurationSlice.actions;
export const ConfigurationReducer = ConfigurationSlice.reducer;