import { SagaIterator } from "redux-saga";
import { put, call, takeEvery } from "redux-saga/effects";
import { requester } from "../../../../libs/requester/requester-axios";
import { IResponse } from "../../../../libs/requester/IResponse";
import toast from "react-hot-toast";
import { deleteFile } from "../../../paymentCreation/repository/slice";
import { setSinglePayment } from "../../repository/actions";

function* removeConfirmPaymentFileWorker({ payload }: any): SagaIterator {
    try {
        const url = payload.mode === "Payment"? "/zoolux-api/payment/delete-confirm-files" : "/zoolux-api/budget-calendar/delete-confirm-files";
        const { data, isError, message }: IResponse<any> = yield call(requester.delete, url, payload.body );
        if (isError) {
            toast.error(message);
        } else {
            yield put(setSinglePayment(data));
        }
    } catch (error) {
        console.error("removeConfirmPaymentFileWorker: ", error);
    } finally {

    }
};

export function* removeConfirmPaymentFileWatcher() {
    yield takeEvery("@saga/REMOVE_CONFIRM_PAYMENT_FILE", removeConfirmPaymentFileWorker);
};