export function convertToFormData(object: any) {
    const formData = new FormData();

    function appendFormData(data: any, parentKey?: any) {
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
            Object.keys(data).forEach(key => {
                if (Array.isArray(data[key])) {
                    if (typeof data[key][0] === 'number') {
                        formData.append(key, JSON.stringify(data[key]));
                    } else {
                        data[key].forEach((item: any) => {
                            formData.append(key, item);
                        });
                    }
                } else {
                    appendFormData(data[key], parentKey ? `${parentKey}[${key}]` : key);
                }
            });
        } else {
            formData.append(parentKey, data);
        }
    }

    appendFormData(object);

    return formData;
}