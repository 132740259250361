import { createTheme } from '@mui/material';

export const themeLightMain = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: "#36B355",
            dark: "#247839"
        },
        secondary: {
            main: "#247839",
        },
        second: {
            main: "#00805C"
        },
        backgroundCard: {
            main: "#EDF4F7"
        },
        main: {
            main: "#1C1C1E"
        },
        diamondCut: {
            main: "#F2F2F7"
        },
        victorianGreenhouse: {
            main: "#00B28E",
        },
        boxShadow: {
            main: '0 3px 4px 0 rgba(139, 139, 178, 0.36)',
        },
        heatherField: {
            main: "#8E8E93"
        },
    },
});

declare module '@mui/material/styles' {
    interface Palette {
        backgroundCard: Palette['primary'];
    }

    interface PaletteOptions {
        backgroundCard?: PaletteOptions['primary'];
    }
}

declare module '@mui/material/styles' {
    interface Palette {
        victorianGreenhouse: Palette['primary'];
    }

    interface PaletteOptions {
        victorianGreenhouse?: PaletteOptions['primary'];
    }
}

declare module '@mui/material/styles' {
    interface Palette {
        main: Palette['primary'];
    }

    interface PaletteOptions {
        main?: PaletteOptions['primary'];
    }
}

declare module '@mui/material/styles' {
    interface Palette {
        boxShadow: Palette['primary'];
    }

    interface PaletteOptions {
        boxShadow?: PaletteOptions['primary'];
    }
}

declare module '@mui/material/styles' {
    interface Palette {
        second: Palette['primary'];
    }

    interface PaletteOptions {
        second?: PaletteOptions['primary'];
    }
}

declare module '@mui/material/styles' {
    interface Palette {
        diamondCut: Palette['primary'];
    }

    interface PaletteOptions {
        diamondCut?: PaletteOptions['primary'];
    }
}

declare module '@mui/material/styles' {
    interface Palette {
        heatherField: Palette['primary'];
    }

    interface PaletteOptions {
        heatherField?: PaletteOptions['primary'];
    }
}