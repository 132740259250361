import { Autocomplete, Stack, TextField, Theme, Typography, useMediaQuery } from "@mui/material";
import { FC } from "react"
import { IExpenditureOption, collectExpenditureList } from "../../../utils/collectOptionList";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";
import { useExpenditureAutocomplete } from "../presenter/useExpenditureAutocomplete";
import { TScreenFlag } from "../../../modules/paymentCalendar/payment/ui/components/parts/paymentData/presenter/useServicePayment";

interface IProps {
    index?: number;
    isDisabled?: boolean;
    screenFlag?: TScreenFlag;
    value: IExpenditureOption | null;
    isValid: boolean;
    handleChangeValue: (value: IExpenditureOption | null, index?: number) => void;
};

export const ExpenditureAutocomplete: FC<IProps> = ({ index, isValid, value, handleChangeValue, screenFlag, isDisabled }) => {
    const { t } = useTranslation();
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const isDisabledField = screenFlag === "SERVICE" || isDisabled;
    const { expenditure, changeExpenseItemText } = useExpenditureAutocomplete();

    return (
        <Stack direction='column' alignItems='flex-start' spacing={1} width={'100%'}>
            <Typography color={isValid ? 'primary' : 'error'} variant={downSm ? 'body1' : 'h6'}>{t(tokens.payment.expenseItem)}</Typography>
            <Autocomplete
                disablePortal
                onChange={(e, value) => handleChangeValue(value, index)}
                value={value}
                options={collectExpenditureList(expenditure) as IExpenditureOption[]}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(params, option): JSX.Element => (
                    <Typography {...params} key={option.id}>
                        {option.name}
                    </Typography>
                )}
                renderInput={(params): JSX.Element => (
                    <TextField
                        {...params}
                        fullWidth
                        error={!isValid}
                        name={t(tokens.payment.expenseItem)}
                        variant="outlined"
                        label={t(tokens.payment.expenseItem)}
                        onChange={changeExpenseItemText}
                        size="small"
                    />
                )}
                sx={{ width: '100%' }}
                size="small"
                disabled={isDisabledField}
            />
        </Stack>
    )
};