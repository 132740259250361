import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { FC } from "react";

interface IProps {
    title: string;
    label: string;
    isValid: boolean;
    value: string;
    options: string[];
    handleValue: (event: SelectChangeEvent) => void;
};

export const TypeSelector: FC<IProps> = ({ title, value, handleValue, options, label, isValid }) => {

    return (
        <Stack width='100%'>
            <Typography color={isValid ? 'primary' : 'error'} variant='h6'>{title}</Typography>
            <FormControl sx={{ mt: 1 }}>
                <InputLabel id="select-label" style={{ color: isValid? 'gray' : '#D32F2F' }}>{label}</InputLabel>
                <Select
                    labelId="select-label"
                    id="select-small"
                    value={value}
                    label={label}
                    onChange={handleValue}
                    MenuProps={{
                        disableScrollLock: true,
                    }}
                    error={!isValid}
                >
                    {options.map(item => (
                        <MenuItem key={item} value={item}>{item}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Stack>
    )
};