export const getStyles = () => {
    const styles = {
        paperLg: { 
            width: 500,
            zIndex: 50 
        },
        modal: { 
            pointerEvents: 'none', 
        },
        paper: { 
            maxWidth: '100%', 
            width: 400, 
            pointerEvents: 'auto', 
        }
    };
    return styles;
};