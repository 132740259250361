import { SagaIterator } from "redux-saga";
import { put, call, takeEvery } from "redux-saga/effects";
import { requester } from "../../../../libs/requester/requester-axios";
import { IResponse } from "../../../../libs/requester/IResponse";
import toast from "react-hot-toast";
import { setProcessServiceError, setSingleService } from "../../repository/slice";
import { setFormData, setOperationist, setProcessPaymentDate } from "../../../paymentCreation/repository/slice";

function* processServiceWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.put, "/zoolux-api/services/process-service/" + payload.id);
        if (isError) {
            yield put(setProcessServiceError(message));
        } else {
            yield put(setProcessServiceError(null));
            toast.success("Послугу проведено");
            yield put(setFormData({ id: data.receipt_goods_services.id, status: data.receipt_goods_services.status }));
            yield put(setOperationist(data.receipt_goods_services.operacionist));
            yield put(setProcessPaymentDate(data.receipt_goods_services.operation_date)); 
        }
    } catch (error) {
        console.error("processServiceWorker: ", error);
    }
};

export function* processServiceWatcher() {
    yield takeEvery("@saga/PROCESS_SERVICE", processServiceWorker);
};