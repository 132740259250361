import { ChangeEvent, FC } from "react";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";

interface IProps {
    handleSortChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const SearchSelector: FC<IProps> = ({ handleSortChange }) => {
    const { t } = useTranslation();

    const sortOptions: any[] = [
        { label: t(tokens.payment.partner), value: 'COUNTERPARTY' },
        { label: t(tokens.payment.comment), value: 'COMMENT' },
        { label: t(tokens.payment.operacionist), value: 'OPERACIONIST' },
        { label: t(tokens.payment.financier), value: 'FINANCIER' },
        { label: t(tokens.payment.initiator), value: 'INITIATOR' },
        { label: t(tokens.payment.account), value: 'COMPANY_ACCOUNT' },
    ];

    return (
        <TextField
            size="small"
            name="sort"
            onChange={handleSortChange}
            select
            SelectProps={{ native: true }}
        >
            {sortOptions.map((option) => (
                <option
                    key={option.value}
                    value={option.value}
                >
                    {option.label}
                </option>
            ))}
        </TextField>
    )
};