import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadImportEnot } from "../../../../store/importPayment/useCases/uploadPaymentEnot/action";
import { uploadImportCalendar } from "../../../../store/importPayment/useCases/updatePaymentCalendar/action";
import { selectImportPayment } from "../../../../store/importPayment/repository/selector";
import toast from "react-hot-toast";
import { getUploadList } from "../../../../store/importPayment/useCases/getUploadList/action";
import { tokens } from "../../../../locales/tokens";
import { useTranslation } from "react-i18next";
import { getUploadPayments } from "../../../../store/importPayment/useCases/getUploadPayments/action";

export const useImportList = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [file, setFile] = useState<any>(null);
    const [type, setType] = useState('');
    const [uploadEnotClick, setUploadEnotClick] = useState(false);
    const [uploadCalendarClick, setUploadCalendarClick] = useState(false);
    const [filters, setFilters] = useState({ page: 1, perPage: 5 });
    const [pageAmount, setPageAmount] = useState(0);
    const [isItemOpen, setItemOpen] = useState(false);
    const [rowId, setRowId] = useState(0);
    const { isLoading, uploadCalendarError, uploadEnotError, uploadList, uploadsCount, uploadPayments } = useSelector(selectImportPayment);

    useEffect(() => {
        dispatch(getUploadList(combineRequestBody()));
    }, [filters]);

    useEffect(() => {
        if (uploadsCount) {
            setPageAmount(Math.ceil(uploadsCount / filters.perPage));
        }
    }, [uploadsCount]);

    useEffect(() => {
        if (!isLoading && uploadEnotClick) {
            if (uploadEnotError) {
                toast.error(uploadEnotError)
            } else {
                toast.success(t(tokens.importPayment.messages.paymentsUploadEnot));
                dispatch(getUploadList(combineRequestBody()));
            }
            setUploadEnotClick(false);
        }
    }, [uploadEnotError, isLoading]);

    useEffect(() => {
        if (!isLoading && uploadCalendarClick) {
            if (uploadCalendarError) {
                toast.error(uploadCalendarError)
            } else {
                toast.success(t(tokens.importPayment.messages.paymentsUploadEnot));
                dispatch(getUploadList(combineRequestBody()));
            }
            setUploadCalendarClick(false);
        }
    }, [uploadCalendarError, isLoading]);

    const combineRequestBody = () => {
        const body = { page: filters.page, perPage: filters.perPage };
        return body;
    };

    const handleImportInEnot = () => {
        setDialogOpen(true);
        setType('ENOT');
    };

    const handleImportInCalendar = () => {
        setDialogOpen(true);
        setType('CALENDAR');
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setType('');
        setFile(null);
    };

    const handleFileUpload = (event: any) => {
        if (event.target.files && event.target.files.length > 0) {
            setFile(event.target.files[0]);
        }
    };

    const uploadFile = () => {
        if (type === "ENOT") {
            dispatch(uploadImportEnot({ file }));
            setUploadEnotClick(true);
        } else {
            dispatch(uploadImportCalendar({ file }));
            setUploadCalendarClick(true);
        }
        handleDialogClose();
    };

    const handlePageChange = useCallback((event: ChangeEvent<unknown>, page: number): void => {
        setFilters((prevState) => ({ ...prevState, page }));
    }, [setFilters]);

    const onChangeItemOpen = (id: number, isItemOpen: boolean) => {
        dispatch(getUploadPayments({ id }));
        setRowId(id);
        setItemOpen(!isItemOpen);
    };

    return {
        handleImportInEnot, handleImportInCalendar, handleDialogClose, handleFileUpload, isDialogOpen, file, uploadFile, uploadList,
        filters, pageAmount, handlePageChange, isItemOpen, onChangeItemOpen, rowId, uploadPayments
    };
};