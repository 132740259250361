import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setPaymentsList } from "../../repository/actions";

function* getPaymentsListWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.get, "/zoolux-api/payments/list-day", payload);
        if (isError) {

        } else {
            yield put(setPaymentsList({ list: data.data, count: data.currentPage }));
        }
    } catch (error) {
        console.error("getPaymentsListWorker: ", error);
    } finally {

    }
};

export function* getPaymentsListWatcher() {
    yield takeLatest("@saga/GET_PAYMENTS_LIST", getPaymentsListWorker);
};
