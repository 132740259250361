import { SagaIterator } from "redux-saga";
import { put, takeLatest, call, all } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";
import { setServicePaymentId } from "../../../paymentCreation/repository/slice";
import { deleteServiceRelated } from "../../../serviceRelated/useCases/deleteServiceRelated/action";
import { editServiceRelated } from "../../../serviceRelated/useCases/editServiceRelated/action";
import { createServiceRelated } from "../../../serviceRelated/useCases/createServiceRelated/action";

function* updateServicePaymentWorker({ payload }: any): SagaIterator {
    try {
        const { isError, message }: IResponse<any> = yield call(requester.put, "/zoolux-api/receipt-goods-services/update", payload.body);
        if (isError) {
            toast.error(message);
        } else {
            yield put(setServicePaymentId(payload.servicePaymentId));
            
            if (payload.deletedRelatedServicesIds?.length > 0) {
                const deletedServices = payload.deletedRelatedServicesIds.map((id: number) =>
                    put(deleteServiceRelated({ id }))
                );
                yield all(deletedServices);
            };

            const updateServiceList = payload.relatedService.filter((service: any) => service.id);
            const createServiceList = payload.relatedService.filter((service: any) => !service.id);

            if(updateServiceList?.length > 0 && payload.servicePaymentId){
                const updatedServices = updateServiceList.map((service: any) => 
                    put(editServiceRelated({
                        service_related_id: service.id,
                        amount: service.amount,
                        counterparty_id: service.counterparty_id,
                        expenditure_id: service.expenditure_id,
                        receipt_goods_service_id: payload.servicePaymentId
                    }))
                )
                yield all(updatedServices);
            };

            if(createServiceList?.length > 0){
                yield put(createServiceRelated({
                    receipt_goods_service_id: payload.servicePaymentId,
                    data: createServiceList
                }));
            };
        }
    } catch (error) {
        console.error("updateServicePaymentWorker: ", error);
    }
};

export function* updateServicePaymentWatcher() {
    yield takeLatest("@saga/UPDATE_SERVICE_PAYMENT", updateServicePaymentWorker);
};
