export const getStyles = () => {
    const styles = {
        tableCell: {
            pl: '150px'
        },
        tableRow: { 
            cursor: 'pointer' 
        }
    };
    return styles;
};