
import toast from 'react-hot-toast';
import { requester } from '../../../libs/requester/requester-axios';
import { zooluxAPI } from '../../zooluxAPI';

interface IResponseClass {
    count: number;
    rows: IClass[];
}

interface IClass {
    id: number;
    ref_key: string;
}

const classEquipment = zooluxAPI.injectEndpoints({
    endpoints: (builder) => ({
        getClasses: builder.query<IResponseClass, any>({
            async queryFn(_, { dispatch }) {
                try {
                    const { data, isError, message }: any = await requester.get('/zoolux-api/classes');
                    if (isError) {
                        toast.error(message);
                        throw new Error(message);
                    };
                    return { data };
                } catch (e) {
                    throw e;
                }
            },
        }),
    }),
    overrideExisting: false,
});

export const { useGetClassesQuery } = classEquipment;