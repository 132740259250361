import { TextField } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";
import { SortOption } from "../../../../../../types/users";

interface IProps {
    handleSortChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const SortSelector: FC<IProps> = ({ handleSortChange }) => {
    const { t } = useTranslation();

    const sortOptions: SortOption[] = [
        { label: 'Нові', value: 'NEW' },
        { label: 'Старі', value: 'OLD' },
    ];

    return (
        <TextField
            label="Сортувати"
            name="sort"
            onChange={handleSortChange}
            select
            SelectProps={{ native: true }}
        >
            {sortOptions.map((option) => (
                <option
                    key={option.value}
                    value={option.value}
                >
                    {option.label}
                </option>
            ))}
        </TextField>
    )
};