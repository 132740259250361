import { IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { FC, useCallback } from "react";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import dayjs from "dayjs";
import 'dayjs/locale/uk';
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";

interface IProps {
    paymentDate: string;
    handleCopy: (valuse: string) => void;
};

export const DateField: FC<IProps> = ({ paymentDate, handleCopy }) => {
    const { t } = useTranslation();
    const formatedDate = dayjs(paymentDate).locale('uk').format('MMMM D, YYYY');
    const copyValue = useCallback(() => handleCopy(formatedDate), [formatedDate]);

    return ( 
        <Stack display='flex' direction='row' spacing={2}>
            <Stack flex={1} direction='row' justifyContent='flex-end' alignItems='center'>
                <Typography>{t(tokens.payment.paymentDate)}</Typography>
            </Stack>
            <Stack flex={3} direction='row'>
                <TextField
                    value={formatedDate}
                    size="small"
                    onClick={copyValue}
                    InputProps={{
                        readOnly: true,
                        startAdornment: (
                            <InputAdornment position="start" >
                                <IconButton sx={{ p: 0 }}>
                                    <CalendarMonthIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        input: { cursor: 'pointer' },
                        textArea: { cursor: 'pointer' }
                    }}
                />
            </Stack>
        </Stack>
    )
};