import { useDispatch, useSelector } from "react-redux";
import { selectUsers } from "../../../store/users/repository/selector";
import { getUsers } from "../../../store/users/usecases/getUsers/action";
import { ChangeEvent, useEffect, useState } from "react";
import { useDebounce } from "../../../hooks/useDebounce";
import { setUsers } from "../../../store/users/repository/actions";

export const useUserAutocomplete = (isFinancier: boolean) => {
    const dispatch = useDispatch();
    const [userSearch, setUserSearch] = useState('');
    const { users } = useSelector(selectUsers);
        
    useEffect(() => {
        dispatch(getUsers({ offset: 0, limit: 15, financier: isFinancier })); 
        return () => {
            dispatch(setUsers([]));
        };
    }, []);

    const changeUserText = (event: ChangeEvent<HTMLInputElement>) => {
        setUserSearch(event.target.value);
        debouncedHandleUserSeacrh(event.target.value);
    };

    const onSearchUser = (value: string) => {
        if (value.length) {
            dispatch(getUsers({ offset: 0, limit: 15, search: userSearch, financier: isFinancier }));
        } else {
            dispatch(getUsers({ offset: 0, limit: 15, financier: isFinancier }));
        }
    };

    const { debouncedWrapper: debouncedHandleUserSeacrh } = useDebounce(onSearchUser, 1000);
    
    return { users, changeUserText }
};