import { FormControl, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { FC, ReactNode } from "react";

interface IProps {
    id: string;
    title: string;
    icon: ReactNode;
    value: string;
};

export const InputField: FC<IProps> = ({ id, title, icon, value}) => {

    return (
        <FormControl fullWidth>
            <InputLabel htmlFor={id}>{title}</InputLabel>
            <OutlinedInput
                id={id}
                startAdornment={<InputAdornment position="start">{icon}</InputAdornment>}
                label={title}
                value={value}
            />
        </FormControl>
    );
};