export const getStyles = () => {
    const styles = {
        code: {
            px: 3,
            py: 2,
            pt: 10
        },
        details: { 
            px: 3, 
            py: 2 
        },
        editButton: {
            backgroundColor: '#6366F1', 
            color: '#FFFFFF', 
            px: 2, 
            py: 1, 
            borderRadius: '10px'
        },
        account: { 
            display: 'flex', 
            flexDirection: 'row', 
            pl: '180px', 
            my: 0 
        }
    };
    return styles;
};