import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addBankDetails } from "../../../store/paymentCalendar/useCases/addBankDetails/action";
import { setBankCard } from "../../../store/paymentCreation/repository/slice";
import { createFilterOptions } from "@mui/material";
import { IBankCardOption } from "../../../utils/collectOptionList";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";
import { selectPayments } from "../../../store/paymentCalendar/repository/selector";
import { setPaymentFieldValidate, setPaymentValidate } from "../../../store/operationValidation/repository/slice";

const filter = createFilterOptions<IBankCardOption>();

export const useBankCardAutocomplete = (partnerId: any, paymentMethod: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { bankCards } = useSelector(selectPayments);
    const [open, toggleOpen] = useState(false);
    const [dialogValue, setDialogValue] = useState({ account: '', bank_name: '', bank_mfo: '', recipient_name: '' });

    const handleClose = () => {
        setDialogValue({ account: '', bank_name: '', bank_mfo: '', recipient_name: '' });
        toggleOpen(false);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (paymentMethod === 'non-cash') {
            dispatch(addBankDetails({ id: partnerId, bankDetailsBusiness: { account: dialogValue.account, bank_name: dialogValue.bank_name, bank_mfo: dialogValue.bank_mfo, recipient_name: dialogValue.recipient_name } }));
        } else if (paymentMethod === 'card') {
            dispatch(addBankDetails({ id: partnerId, bankDetailsPersonal: { account: dialogValue.account, bank_name: dialogValue.bank_name, bank_mfo: dialogValue.bank_mfo, recipient_name: dialogValue.recipient_name } }));
        };
        dispatch(setBankCard({ account: dialogValue.account, bank_name: dialogValue.bank_name, bank_mfo: dialogValue.bank_mfo, recipient_name: dialogValue.recipient_name }));
        handleClose();
    };

    const handleOnChange = (event: any, newValue: any) => {
        if (typeof newValue === 'string') {
            setTimeout(() => {
                toggleOpen(true);
                setDialogValue({ account: newValue, bank_name: '', bank_mfo: '', recipient_name: '' });
            });
        } else if (newValue && newValue.inputValue) {
            const accountValid = paymentMethod === 'card' ? /^\d{16}$/ : /^[a-zA-Z0-9]*$/;
            if (accountValid.test(newValue.inputValue)) {
                toggleOpen(true);
                dispatch(setPaymentFieldValidate('card'));
                setDialogValue({ account: newValue.inputValue, bank_name: '', bank_mfo: '', recipient_name: '' });
            } else {
                dispatch(setPaymentValidate('card'));
            }
        } else {
            dispatch(setBankCard(newValue));
        };
    };

    const filterBankCardOptions = (options: any[], params: any) => {
        const filtered = filter(options, params);
        if (params.inputValue !== '') {
            filtered.push({
                inputValue: params.inputValue,
                account: `${t(tokens.payment.add)}"${params.inputValue}"`,
                bank_name: '',
                bank_mfo: '',
                recipient_name: ''
            });
        };
        setTimeout(() => dispatch(setPaymentFieldValidate('card')), 0);
        return filtered;
    };

    return { handleSubmit, handleOnChange, filterBankCardOptions, bankCards, open, handleClose, dialogValue, setDialogValue }
};