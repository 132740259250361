import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";
import { setLoading, setServiceListAll, setGetServiceListError } from "../../repository/slice";

function* getServiceListAllWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, isError, message }: IResponse<any> = yield call(requester.get, "/zoolux-api/admin/service-receiving", payload);
        if (isError) {
            toast.error(message);
            yield put(setGetServiceListError(message));
        } else {
            yield put(setGetServiceListError(null));
            yield put(setServiceListAll({ list: data.rows, count: data.count }));
        }
    } catch (error) {
        console.error("getServiceListAllWorker: ", error);
    } finally{
        yield put(setLoading(false));
    }
};

export function* getServiceListAllWatcher() {
    yield takeLatest("@saga/GET_SERVICE_LIST_ALL", getServiceListAllWorker);
};
