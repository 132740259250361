import { Box, Collapse } from "@mui/material";
import { FC, ReactNode, useCallback, useState } from "react";
import { getStyles } from "./styles";
import { SideNavItemBtn } from "../side-nav-itemBtn/side-nav-itemBtn";
import { Link } from "react-router-dom";

interface IProps {
  active?: boolean;
  children?: ReactNode;
  depth?: number;
  disabled?: boolean;
  icon?: ReactNode;
  label: ReactNode;
  path?: string;
  open?: boolean;
  title: string;
}

export const SideNavItem: FC<IProps> = ({ active, children, depth = 0, disabled, icon, label, open: openProp, path, title }) => {
  const [open, setOpen] = useState<boolean>(!!openProp);
  const styles = getStyles();
  let startIcon: ReactNode;
  const offset = depth === 0 ? 0 : (depth - 1) * 16;
  let linkProps: any = undefined;

  const handleToggle = useCallback((): void => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  if (depth === 0) {
    startIcon = icon;
  } else {
    startIcon = (
      <Box sx={styles.activeSectionContainer}>
        <Box sx={active ? { ...styles.section, ...styles.activeSection } : styles.section} />
      </Box>
    );
  }



  if (children) {
    return (
      <li>
        <SideNavItemBtn
          disabled={disabled}
          handleToggle={handleToggle}
          active={active}
          depth={depth}
          open={open}
          title={title}
          startIcon={startIcon}
          offset={offset}
          linkProps={linkProps}
          children={children}
        />
        <Collapse in={open} sx={styles.collapse}>
          {children}
        </Collapse>
      </li>
    );
  };

  if (path) {
    const isExternal = path.startsWith('http');
    linkProps = isExternal
      ? { component: 'a', href: path, target: '_blank' }
      : { component: Link, to: path };
  }

  return (
    <li style={{ position: 'relative' }}>
      <SideNavItemBtn
        disabled={disabled}
        handleToggle={handleToggle}
        active={active}
        depth={depth}
        open={open}
        title={title}
        startIcon={startIcon}
        offset={offset}
        linkProps={linkProps}
        children={children}
      />
    </li>
  );
};