import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";
import { usePaymentKindSelector } from "../presenter/usePaymentKindSelector";
import { TScreenFlag } from "../../../modules/paymentCalendar/payment/ui/components/parts/paymentData/presenter/useServicePayment";

interface IProps {
    value: any;
    isValid: boolean;
    isDisabled: boolean;
    onChangeValue: (event: SelectChangeEvent) => void;
};

export const PaymentKindSelector: FC<IProps> = ({ value, onChangeValue, isValid, isDisabled }) => {
    const { t } = useTranslation();
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const { paymentKinds, paymentKindOptions } = usePaymentKindSelector(value);

    return (
        <Stack alignItems='flex-start' spacing={1} width='100%'>
            <Typography color={isValid ? 'primary' : 'error'} variant={downSm ? 'body1' : 'h6'}>{t(tokens.payment.paymentKind)}</Typography>
            <FormControl fullWidth size="small">
                <InputLabel id="select-paymentKind" color={isValid ? 'primary' : 'error'}>{t(tokens.payment.paymentKind)}</InputLabel>
                <Select
                    labelId="select-paymentKind"
                    id="select-paymentKind"
                    value={value}
                    label={t(tokens.payment.paymentKind)}
                    onChange={onChangeValue}
                    error={!isValid}
                    disabled={isDisabled}
                >
                    {paymentKinds.map(option =>
                        <MenuItem value={option.id} key={option.id} placeholder={t(tokens.payment.paymentKind)}>{paymentKindOptions[option.key]}</MenuItem>
                    )}
                </Select>
            </FormControl>
        </Stack>
    )
};