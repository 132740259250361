import { Pagination, Stack, Table } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { ServiceHistoryTableHead } from "../serviceHistoryTableHead";
import { ServiceHistoryTableBody } from "../serviceHistoryTableBody";

interface IProps {
    serviceList: any[];
    currentPage: number;
    pageAmount: number;
    handlePageChange: (event: ChangeEvent<unknown>, page: number) => void;
};

export const ServiceHistoryTable: FC<IProps> = ({ currentPage, handlePageChange, pageAmount, serviceList }) => {

    return (
        <Stack sx={{ my: 5 }}> 
            <Table>
                <ServiceHistoryTableHead />
                <ServiceHistoryTableBody
                    serviceList={serviceList}
                />
            </Table>
            <Stack direction="row" justifyContent="center" pt={4}>
                <Pagination
                    page={currentPage}
                    count={pageAmount || 1}
                    color="primary"
                    onChange={handlePageChange}
                />
            </Stack>
        </Stack>
    )
};