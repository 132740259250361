import type { ChangeEvent, FC } from 'react';
import { Pagination, Stack, Table } from '@mui/material';
import { TableExpenseItemHead } from '../tableExpenseItemHead';
import { TableExpenseItemBody } from '../tebleExpenseItemBody';

interface IProps {
  items: any[];
  pageAmount: number;
  currentPage: number;
  handleExpenseItemOpen: (partnerId: string) => void;
  handlePageChange: (event: ChangeEvent<unknown>, page: number) => void;
};

export const ExpenseItemListTable: FC<IProps> = ({ items, handleExpenseItemOpen, handlePageChange, pageAmount, currentPage }) => {

  return (
    <>
      {items.length ?
        <div>
          <Table >
            <TableExpenseItemHead />
            <TableExpenseItemBody
              items={items}
              handleExpenseItemOpen={handleExpenseItemOpen}
            />
          </Table>
          <Stack direction='row' justifyContent='center' my={4}>
            <Pagination
              count={pageAmount || 1}
              page={currentPage || 1}
              color="primary"
              onChange={handlePageChange}
            />
          </Stack>
        </div> : null}
    </>
  );
};
