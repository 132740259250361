import { SagaIterator } from "redux-saga";
import { call, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";

function* updatePaymentWorker({ payload }: any): SagaIterator {
    try {
        const url = payload.modeFilter === "Payment" ? "/zoolux-api/payments" : "/zoolux-api/budget-payments";
        const { data, isError, message }: IResponse<any> = yield call(requester.put, url, payload.body);
        if (isError) {
            toast.error(message);
        } else {
            if (payload.isShowMessage) {
                toast.success("Платіж успішно оновлено");
            };
        };
    } catch (error) {
        console.error("createPaymentWorker: ", error);
    };
};

export function* updatePaymentWatcher() {
    yield takeLatest("@saga/UPDATE_PAYMENT", updatePaymentWorker);
};
