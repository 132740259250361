import { Route, Routes } from "react-router-dom";
import { Layout } from "../layouts/dashboard";
import { FC } from "react";
import { paths } from "../paths";
import { UserList } from "../modules/users/user-list/ui";
import { UserCreate } from "../modules/users/user-create/ui";
import { UserEdit } from "../modules/users/user-edit/ui";
import { PartnerList } from "../modules/partner/partner-list/ui";
import { PartnerCreate } from "../modules/partner/partner-create/ui";
import { PartnerEdit } from "../modules/partner/partner-edit/ui";
import { PaymentReceipt } from "../modules/payment-calendar/payment-receipt/ui";
import { AccountList } from "../modules/account/accountList/ui";
import { AccountEdit } from "../modules/account/accountEdit/ui";
import { ExpenseItemList } from "../modules/expenseItem/expenseItemList/ui";
import { RoleList } from "../modules/users/roles/roleList/ui";
import { RoleCreate } from "../modules/users/roles/roleCreate/ui";
import { RoleEdit } from "../modules/users/roles/roleEdit/ui";
import { ImportList } from "../modules/importPayments/importList/ui";
import { PrepaymentList } from "../modules/prepayments/ui";
import { ContractList } from "../modules/contract/contractList/ui";
import { ContractCreate } from "../modules/contract/contractCreate/ui";
import { ContractEdit } from "../modules/contract/contractEdit/ui";
import { ServiceList } from "../modules/service/serviceList/ui";
import { ServiceCreate } from "../modules/service/serviceCreate/ui";
import { TelegramBot } from "../modules/telegramBot/ui";
import { DocumentVerifiedPayments } from "../modules/documentVerifiedPayments/ui";
import { OperationList } from "../modules/operation/operationList/ui";
import { PaymentList } from "../modules/paymentCalendar/paymentList/ui";
import { Payment } from "../modules/paymentCalendar/payment/ui";
import { PaymentForm } from "../modules/paymentCalendar/payment-form/ui";
import { ServiceEmptyState } from "../modules/serviceHistory/serviceEmptyState/ui";
import { ServiceHistoryList } from "../modules/serviceHistory/serviceHistoryList/ui";
import { Service } from "../modules/serviceCalendar/service/ui";
import { NomenclatureList } from "../modules/nomenclature/ui";
import { Locations } from '../modules/location/locations/ui';
import { Configuration } from "../modules/configuration/ui";
import { FixedAssets } from '../modules/fixedAsset/fixedAssets/ui';
import { UpsertFixedAsset } from '../modules/fixedAsset/upsertFixedAsset/ui';
import { DetailFixedAsset } from '../modules/fixedAsset/detailFixedAsset/ui';

export const PrivateRoutes: FC = () => {

    return (
        <Routes>
            <Route path='/' element={<Layout />}>
                <Route index element={<OperationList />} />
                <Route path={paths.dashboard.payment.list} element={<PaymentList />} />
                <Route path={paths.dashboard.payment.create} element={<Payment />} />
                <Route path={paths.dashboard.payment.edit} element={<Payment />} />
                <Route path={paths.dashboard.serviceHistory.create} element={<Service />} />
                <Route path={paths.dashboard.serviceHistory.edit} element={<Service />} />
                <Route path={paths.dashboard.payment.receipt} element={<PaymentReceipt />} />
                <Route path={paths.dashboard.payment.form} element={<PaymentForm />} />
                <Route path={paths.dashboard.user.list} element={<UserList />} />
                <Route path={paths.dashboard.user.create} element={<UserCreate />} />
                <Route path={paths.dashboard.user.role.list} element={<RoleList />} />
                <Route path={paths.dashboard.user.role.create} element={<RoleCreate />} />
                <Route path={paths.dashboard.user.role.edit} element={<RoleEdit />} />
                <Route path={paths.dashboard.user.edit} element={<UserEdit />} />
                <Route path={paths.dashboard.partner.list} element={<PartnerList />} />
                <Route path={paths.dashboard.partner.create} element={<PartnerCreate />} />
                <Route path={paths.dashboard.partner.edit} element={<PartnerEdit />} />
                <Route path={paths.dashboard.partner.contracts} element={<ContractList />} />
                <Route path={paths.dashboard.partner.contractCreate} element={<ContractCreate />} />
                <Route path={paths.dashboard.partner.contractEdit} element={<ContractEdit />} />
                <Route path={paths.dashboard.account.list} element={<AccountList />} />
                <Route path={paths.dashboard.account.edit} element={<AccountEdit />} />
                <Route path={paths.dashboard.expenseItem.list} element={<ExpenseItemList />} />
                <Route path={paths.dashboard.importPayment.list} element={<ImportList />} />
                <Route path={paths.dashboard.prepayment.list} element={<PrepaymentList />} />
                <Route path={paths.dashboard.service.list} element={<ServiceList />} />
                <Route path={paths.dashboard.service.create} element={<ServiceCreate />} />
                <Route path={paths.dashboard.service.edit} element={<ServiceCreate />} />
                <Route path={paths.dashboard.telegram.list} element={<TelegramBot />} />
                <Route path={paths.dashboard.serviceHistory.emptyState} element={<ServiceEmptyState />} />
                <Route path={paths.dashboard.serviceHistory.list} element={<ServiceHistoryList />} />
                <Route path={paths.dashboard.documentVerifiedPayments.list} element={<DocumentVerifiedPayments />} />
                <Route path={paths.dashboard.nomenclature.list} element={<NomenclatureList />} />
                <Route path={paths.dashboard.location.index} element={<Locations />} />fixed-assets
                <Route path={paths.dashboard.configuration.list} element={<Configuration />} />
                <Route path={paths.dashboard.fixedAssets.index} element={<FixedAssets />} />
                <Route path={paths.dashboard.fixedAssets.create} element={<UpsertFixedAsset />} />
                <Route path={paths.dashboard.fixedAssets.update} element={<UpsertFixedAsset />} />
                <Route path={paths.dashboard.fixedAssets.detail} element={<DetailFixedAsset />} />
            </Route>
        </Routes>
    );
};