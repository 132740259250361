import { Stack, Theme, useMediaQuery } from "@mui/material";
import { FC } from "react";
import { InputField } from "../inputField";
import PersonIcon from '@mui/icons-material/Person';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

interface IProps {
    user: any;
};

export const UserData: FC<IProps> = ({ user }) => {
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    return (
        <>
            <Stack alignItems="center" direction={downSm ? "column" : "row"} spacing={2}>
                <InputField
                    id='outlined-adornment-name'
                    title='ПІБ'
                    icon={<PersonIcon />}
                    value={user?.full_name}
                />
                <InputField
                    id='outlined-adornment-address'
                    title='Адреса'
                    icon={<EmailIcon />}
                    value={user?.address || 'null'}
                />
            </Stack>
            <Stack alignItems="center" direction={downSm ? "column" : "row"} spacing={2}>
                <InputField
                    id='outlined-adornment-phone'
                    title='Телефон'
                    icon={<LocalPhoneIcon />}
                    value={user?.phone || 'null'}
                />
                <InputField
                    id='outlined-adornment-email'
                    title='Email'
                    icon={<AlternateEmailIcon />}
                    value={user?.email || 'null'}
                />
            </Stack>
        </>
    );
};