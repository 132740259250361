import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { selectAccount } from "../../../../store/accounts/repository/selector";
import { getSingleAccount } from "../../../../store/accounts/useCases/getSingleAccount/action";
import { SelectChangeEvent } from "@mui/material";
import { getAllAccounts } from "../../../../store/accounts/useCases/getAllAccounts/action";
import { useDebounce } from "../../../../hooks/useDebounce";
import { updateAccount } from "../../../../store/accounts/useCases/updateAccount/action";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { setSingleAccount } from "../../../../store/accounts/repository/slice";

export const useAccountEdit = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { singleAccount, allAccounts, error, isLoading } = useSelector(selectAccount);

    const [name, setName] = useState('');
    const [nameValid, setNameValid] = useState(true);
    const [accountType, setAccountType] = useState('');
    const [accountTypeList, setAccountTypeList] = useState(['Безналичные', 'Наличные', 'Банковская карта']);
    const [parent, setParent] = useState<any | null>(null);
    const [accountsList, setAccountsList] = useState<any[]>([]);
    const [accountsListSearch, setAccountsListSearch] = useState('');
    const [isFirstOpen, setFirstOpen] = useState(true);
    const [handleClick, setHandleClick] = useState(false);

    useEffect(() => {
        dispatch(getSingleAccount({ id }));
        dispatch(getAllAccounts({ offset: 0, limit: 1000, search: '' }));
        return () => {
            dispatch(setSingleAccount(null));
        }
    }, []);

    useEffect(() => {
        if (singleAccount) {
            setName(singleAccount.name);
            setAccountType(singleAccount.account_type);
            setAccountsList(allAccounts.slice(0, 20));
        }
    }, [singleAccount, allAccounts]);

    useEffect(() => {
        const myAccount = allAccounts.find(account => account.id === singleAccount?.parent_id);
        if (myAccount && isFirstOpen) {
            setParent({ ...myAccount, label: myAccount?.name });
            setFirstOpen(false);
        };
    }, [allAccounts]);

    useEffect(() => {
        debouncedHandleAccountsSeacrh(accountsListSearch);
    }, [accountsListSearch]);

    useEffect(() => {
        if (!isLoading && handleClick) {
            if (error) {
                toast.error(error);
            } else {
                toast.success(t(tokens.account.accountUpdated));
                navigate('/account');
                dispatch(getAllAccounts({ offset: 0, limit: 1000, search: '' }));
            }
            setHandleClick(false);
        }
    }, [error, isLoading]); 

    const onSearchAccounts = (value: any) => {
        if (value) {
            dispatch(getAllAccounts({ offset: 0, limit: 1000, search: value }));
        } else {
            dispatch(getAllAccounts({ offset: 0, limit: 1000, search: '' }));
        }
    };

    const { debouncedWrapper: debouncedHandleAccountsSeacrh } = useDebounce(onSearchAccounts, 400);

    const onChangeName = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setName(e.target.value);
        setNameValid(true);
    }, []);

    const handleChangeAccountType = useCallback((event: SelectChangeEvent) => {
        setAccountType(event.target.value);
    }, []);

    const onChangeMainAccount = useCallback((value: any | null) => {
        setParent(value);
    }, []);

    const changeCompanyAccountText = (event: ChangeEvent<HTMLInputElement>) => {
        setAccountsListSearch(event.target.value);
    };

    const handleEdit = () => {
        if (!name.length) {
            setNameValid(false);
            return;
        }
        const body: any = { id: Number(id), account_type: accountType, name: name, parent_id: parent?.id || null };
        dispatch(updateAccount(body));
        setHandleClick(true);
    };

    return {
        name, onChangeName, accountType, handleChangeAccountType, accountTypeList, accountsList, parent, onChangeMainAccount,
        changeCompanyAccountText, handleEdit, nameValid
    }
};