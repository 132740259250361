import { FC } from "react";
import { PaymentMethodSelector } from "./components/paymentMethodSelector";
import { useInitPayment } from "../presenters/useInitPayment";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../../store/auth/repository/selector";
import { StepperMui } from "./components/stepperMui/ui";
import { PaymentTitle } from "./components/paymentTitle";
import { Stack } from "@mui/material";
import { EmptyList } from "../../../../ui-kit/emptyList";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { PaymentData } from "./components/parts/paymentData/ui";
import { PaymentProcessing } from "./components/parts/paymentProcessing/ui";
import { ServiceData } from "./components/parts/serviceData/ui";

const screenFlag = "PAYMENT";

export const Payment: FC = () => {
    const { t } = useTranslation();
    const { user } = useSelector(selectAuth);
    const { currentTab, handleTabsChange, paymentMethodOptions, paymentMethod, mode, singlePayment, paymentStatus, isLoading } = useInitPayment();

    return (
        <Stack spacing={1} px={2} pb={4}>
            <Stack>
                <StepperMui status={paymentStatus} mode={mode}/>
                <PaymentTitle paymentId={singlePayment?.payment.id} status={paymentStatus} />
                {!singlePayment && <PaymentMethodSelector tab={currentTab} tabOptions={paymentMethodOptions} handleTabsChange={handleTabsChange} />}
                <PaymentData paymentMethod={paymentMethod} status={paymentStatus} modeFilter={mode} user={user} payment={singlePayment} screenFlag={screenFlag}/>
                <ServiceData paymentId={singlePayment?.id} status={paymentStatus} screenFlag={screenFlag} source={singlePayment?.payment.source}/>
                {paymentMethod && <PaymentProcessing modeFilter={mode} currentPayment={singlePayment} user={user} paymentStatus={paymentStatus}/>}
                {(currentTab === false && !singlePayment) && <EmptyList title={t(tokens.payment.paymentForm)} description={t(tokens.payment.emptyPageDesc)} />}
            </Stack>
        </Stack> 
    )
};