export const getStyles = (downMd: boolean) => {
    const styles = {
        main: {
            display: 'flex',
            flex: '1 1 auto',
            overflow: 'hidden',
            position: 'relative',
            mb: 2,
            overflowX: 'auto'
        },
        container: {
            display: 'flex',
            flex: 1,
        },
        card: { 
            p: 3, 
            borderRadius: 4, 
            my: 3,
            mx: 1 
        },
        header: { 
            justifyContent: 'space-between',
            mt: 2 
        },
        updateButton: { 
            borderRadius: '4px', 
            color: '#FFFFFF',
            justifyContent: downMd ? 'center' : 'start',
            mt: downMd ? 2 : 0 
        }
    };
    return styles;
};