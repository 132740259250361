import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import { FC } from "react"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectPayments } from "../../store/paymentCalendar/repository/selector";
import { tokens } from "../../locales/tokens";
import { TScreenFlag } from "../../modules/paymentCalendar/payment/ui/components/parts/paymentData/presenter/usePaymentData";

interface IProps {
    isDisabled?: boolean;
    value: any;
    isValid: boolean;
    screenFlag: TScreenFlag;
    onChangeValue: (event: SelectChangeEvent) => void;
};

export const PaymentTypeSelector: FC<IProps> = ({ value, onChangeValue, isValid, screenFlag, isDisabled }) => {
    const { t } = useTranslation();
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const { paymentTypes } = useSelector(selectPayments);

    return (
        <Stack alignItems='flex-start' spacing={1} width='100%'>
            <Typography color={isValid ? 'primary' : 'error'} variant={downSm ? 'body1' : 'h6'}>{t(tokens.payment.paymentType)}</Typography>
            <FormControl fullWidth size="small">
                <InputLabel id="select-paymentType" color={isValid ? 'primary' : 'error'}>{t(tokens.payment.paymentType)}</InputLabel>
                <Select
                    labelId="select-paymentType"
                    id="select-paymentType"
                    value={value}
                    label={t(tokens.payment.paymentType)}
                    onChange={onChangeValue}
                    error={!isValid}
                    disabled={screenFlag === "SERVICE" || isDisabled} 
                >
                    {paymentTypes.map((option: any) =>
                        <MenuItem value={option.id} key={option.id} placeholder={t(tokens.payment.paymentType)}>{option.name}</MenuItem>
                    )}
                </Select>
            </FormControl>
        </Stack>
    )
};