import { SagaIterator } from "redux-saga";
import { put, call, takeEvery, select } from "redux-saga/effects";
import { requester } from "../../../../libs/requester/requester-axios";
import { IResponse } from "../../../../libs/requester/IResponse";
import toast from "react-hot-toast";

function* deleteImageFromContractWorker({ payload }: any): SagaIterator {    
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.delete, "/zoolux-api/document-counterparty/delete-file/" + payload.fileName );
        if (isError) {
            toast.error(message);
        } else {
        }
    } catch (error) {
        console.error("deleteImageFromContractWorker: ", error);
    } finally {

    }
};

export function* deleteImageFromContractWatcher() {
    yield takeEvery("@saga/DELETE_IMAGE_FROM_CONTRACT", deleteImageFromContractWorker);
};