import { Box, IconButton, SvgIcon, Theme, useMediaQuery } from "@mui/material";
import { Dispatch, FC, useCallback, useMemo, useState } from "react";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DateRange } from 'react-date-range';
import { uk } from 'date-fns/locale';
import { getStyles } from "./styles";

interface IProps {
    isOpen: boolean;
    setOpen: Dispatch<any>;
    period: any;
    setPeriod:  Dispatch<any>;
    onDateNext?: () => void;
    onDatePrev: () => void;
    handlePeriodChoose: (startDate: Date, endDate: Date) => void;
};

export const RangeDatePicker: FC<IProps> = ({ period, setPeriod, handlePeriodChoose, isOpen, setOpen, onDatePrev, onDateNext }) => {
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const styles = useMemo(() => getStyles(downSm), [downSm]);
    const [state, setState] = useState<any>([{ startDate: new Date(), endDate: null, key: 'selection' }]);

    const handleToggleOpen = () => {
        setOpen((prevState: boolean) => !prevState);
    };

    const handleDateChange = (value: any) => {
        handlePeriodChoose(value[0].startDate, new Date(value[0].endDate.setHours(23, 59, 59, 999)));
        setState(value);
        setPeriod({startTime: value[0].startDate.setHours(0, 0, 0, 0), endTime: value[0].endDate.setHours(23, 59, 59, 999)});
    };

    const handleDate = useCallback((date: Date) => {
        date.getTime() > period.startTime? onDateNext?.() : onDatePrev();
    }, [period.startTime]);

    return (
        <>
            <IconButton onClick={handleToggleOpen} sx={styles.calendarBtn}>
                <SvgIcon>
                    <CalendarMonthIcon />
                </SvgIcon>
            </IconButton>
            {isOpen ?
                <Box sx={styles.datePicker}>
                    <DateRange
                        editableDateInputs={true}
                        onChange={item => handleDateChange([item.selection])}
                        moveRangeOnFirstSelection={false}
                        onShownDateChange={handleDate}
                        ranges={state}
                        locale={uk}
                        rangeColors={['#36B355', '#36B355']}
                    />
                </Box>
                :
                null
            }
        </>
    )
};