import { SelectChangeEvent } from '@mui/material';
import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { config } from '../../../../config';
import { useGetFixedAssetsQuery, IParamsFixedAssets } from '../../../../store/fixedAsset';
import { SEARCH_NAME_FIXED_ASSETS } from '../constants';
import { useNavigate } from 'react-router-dom';
import { useGetPermissions } from '../../../../hooks/useGetPermission';

const initialParamsFixedAssets = { offset: 0, limit: config.fixedAsset.limit, search: '', search_name: SEARCH_NAME_FIXED_ASSETS[0].value };

export const useFixedAssets = () => {
    const { t } = useTranslation();
    const navigation = useNavigate();

    const { isRead_Fixed_Assets = true, Create_Fixed_Assets = true } = useGetPermissions();

    const [paramsFixedAssets, setParamsFixedAssets] = useState<IParamsFixedAssets>(initialParamsFixedAssets);
    const [searchLocation, setSearchLocation] = useState<string>('');
    const [tab, setTab] = useState<string>('one');

    const { limit, offset } = paramsFixedAssets;

    const { data: { count: countGetFixedAsset = 0, rows: rowsGetFixedAsset = [] } = {}, isLoading: isLoadingGetFixedAssets, isFetching: isFetchingGetFixedAssets } = useGetFixedAssetsQuery(paramsFixedAssets, { skip: !isRead_Fixed_Assets });

    const [debouncedSearchLocation] = useDebounce(searchLocation, config.fixedAsset.debounceSearch);

    useEffect(() => {
        setParamsFixedAssets((prev) => ({ ...prev, search: debouncedSearchLocation }));
    }, [debouncedSearchLocation]);

    const handleSearchLocation = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchLocation(e.target.value);
    };

    const handleChangeTabs = (_: SyntheticEvent, newValue: string) => {
        setTab(newValue);
    };

    const handlePageChange = useCallback((_: ChangeEvent<unknown>, page: number) => {
        setParamsFixedAssets((prev) => ({ ...prev, offset: (page - 1) * limit }));
    }, [paramsFixedAssets]);

    const handleChangeFilterSearchName = (e: SelectChangeEvent<string>) => {
        setParamsFixedAssets((prev) => ({ ...prev, search_name: e.target.value, offset: 0 }));
    };

    const handleGoToCreateFixedAssetPage = (): void => {
        navigation(`/fixed-assets/create?${paramsFixedAssets.search_name}=${searchLocation}`);
    };

    return { handleSearchLocation, t, handleGoToCreateFixedAssetPage, handleChangeFilterSearchName, handleChangeTabs, tab, isLoadingGetFixedAssets, isFetchingGetFixedAssets, rowsGetFixedAsset, countGetFixedAsset, limit, offset, handlePageChange, Create_Fixed_Assets };
};
