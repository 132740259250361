import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setServicePartners } from "../../repository/actions";
import toast from "react-hot-toast";

function* getServicePartnersWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.get, "/zoolux-api/counterparties", payload);

        if (isError) {
            toast.error(message);
        } else {
            yield put(setServicePartners(data.data));
        }
    } catch (error) {
        console.error("getServicePartnersWorker: ", error);
    } finally {

    }
};

export function* getServicePartnersWatcher() {
    yield takeLatest("@saga/GET_SERVICE_PARTNERS", getServicePartnersWorker);
};
