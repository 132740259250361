import { useMemo, type FC } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import type { Theme } from '@mui/material';
import { Box, Button, Divider, IconButton, Stack, SvgIcon, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../../locales/tokens';
import { Link } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import { PropertyList } from '../properyList';
import { PropertyListItem } from '../propertyItem';
import { getStyles } from './styles';
import { useGetPermissions } from '../../../../../../hooks/useGetPermission';

interface IProps {
  partner: any;
  onApprove?: () => void;
  onEdit?: () => void;
  onReject?: () => void;
};

export const PartnerDetails: FC<IProps> = ({ onApprove, onEdit, onReject, partner }) => {
  const { t } = useTranslation();
  const styles = useMemo(()=>getStyles(), []);
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const align = lgUp ? 'horizontal' : 'vertical';
  const { isUpdate_Counterparty } = useGetPermissions();  

  return (
    <>
      <Stack alignItems="center" direction="row" justifyContent="space-between" sx={styles.code} >
        <Typography color="inherit" variant="body1" >
          code: {partner.code}
        </Typography>
        <IconButton color="inherit" onClick={onApprove}>
          <SvgIcon><ClearIcon /></SvgIcon>
        </IconButton>
      </Stack>
      <Box sx={styles.details}>
        <Stack spacing={6}>
          <Stack spacing={3}>
            <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={3}>
              <Typography variant="h6">
                {t(tokens.partner.details)}
              </Typography>
              <Stack direction='column' spacing={1}>
                <Button
                  sx={styles.editButton}
                  onClick={onEdit}
                  size="small"
                  disabled={!isUpdate_Counterparty}
                  startIcon={(<SvgIcon><EditIcon /></SvgIcon>)}
                  component={Link}
                  to={"/partner-edit/" + partner.id}
                >
                  {t(tokens.common.edit)}
                </Button>
              </Stack>
            </Stack>
            <PropertyList>
              <PropertyListItem align={align} disableGutters divider label={t(tokens.partner.name)} value={partner.name_enote} />
              <Divider />
              <PropertyListItem align={align} disableGutters divider label={t(tokens.partner.shortName)} value={partner.name} />
              <Divider />
              <PropertyListItem align={align} disableGutters divider label={t(tokens.partner.bankName)} value={partner.name_bank} />
              <Divider />
              <PropertyListItem align={align} disableGutters divider label={t(tokens.partner.edrpou)} value={partner.EDRPOU} />
              <Divider />
              <PropertyListItem align={align} disableGutters divider label={t(tokens.partner.urName)} value={partner.description} />
              <Divider />
              <PropertyListItem align={align} disableGutters divider label={t(tokens.partner.accounts)} value="" />
              {partner.bankDetailsBusiness?.map((item: any, index: number) => (
                <Stack mt={2} key={index} sx={styles.account}>
                  <Typography color="text.secondary" variant="body2">{item.account}</Typography>
                </Stack>
              ))}
              <Divider />
              <PropertyListItem align={align} disableGutters divider label={t(tokens.partner.cards)} value="" />
              {partner.bankDetailsPersonal?.map((item: any, index: number) => (
                <Stack mt={2} key={index} sx={styles.account}>
                  <Typography color="text.secondary" variant="body2">{item.account}</Typography>
                </Stack>
              ))}
              <Divider />
            </PropertyList>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
