
import toast from 'react-hot-toast';
import { requester } from '../../../libs/requester/requester-axios';
import { zooluxAPI } from '../../zooluxAPI';
import { IResponseCreateFixedAsset } from '../repository/IFixedAsset';

const fixedAsset = zooluxAPI.injectEndpoints({
    endpoints: (builder) => ({
        deleteFilesFixedAsset: builder.mutation<IResponseCreateFixedAsset, { fixedAssetId: number; idFile: number; }>({
            async queryFn({ fixedAssetId, idFile }, { dispatch }) {
                try {
                    const { data, isError, message }: any = await requester.delete(`/zoolux-api/fixed-assets/${fixedAssetId}/files/${idFile}`);
                    if (isError) {
                        toast.error(message);
                        throw new Error(message);
                    };
                    return { data };
                } catch (e) {
                    throw e;
                }
            },
            invalidatesTags: ["FixedAsset"]
        }),

    }),
    overrideExisting: false,
});

export const { useDeleteFilesFixedAssetMutation } = fixedAsset;