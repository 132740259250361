import { Box, Button, MenuItem, Pagination, Paper, Select, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as AddIcon } from '../../../../assets/icons/add-icon.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search-icon.svg';
import { ReactComponent as EmptyStateImage } from '../../../../assets/images/empty-state.svg';
import { tokens } from '../../../../locales/tokens';
import { paths } from '../../../../paths';
import { CircularProgress, HeadingTitle } from '../../../../uiKit';
import { FILTER_TABS_FIXED_ASSETS, SEARCH_NAME_FIXED_ASSETS } from '../constants';
import { useFixedAssets } from '../presenters';
import { getStyles } from './styles';
import { v4 as uuidv4 } from 'uuid';

export const FixedAssets: FC = () => {
    const navigation = useNavigate();

    const { handleSearchLocation, t, handleChangeFilterSearchName, handleChangeTabs, tab, isLoadingGetFixedAssets, isFetchingGetFixedAssets, rowsGetFixedAsset, countGetFixedAsset, limit, offset, handlePageChange, handleGoToCreateFixedAssetPage,
        Create_Fixed_Assets
    } = useFixedAssets();

    const { tableContainer } = getStyles({ isOnePage: countGetFixedAsset / limit > 1, isEmptyState: !(rowsGetFixedAsset.length > 0) });

    return (
        <>
            <Stack component={"section"} display={"flex"} rowGap={2.5} pr={2} pt={2}>
                <HeadingTitle title={t(tokens.fixedAsset.title)} textBtn={countGetFixedAsset ? t(tokens.fixedAsset.actionBtn) : undefined} startIcon={<AddIcon />} to={paths.dashboard.fixedAssets.create} disabled={!Create_Fixed_Assets} />
                <Stack flexDirection={"row"} columnGap={4}>
                    <TextField onChange={handleSearchLocation} placeholder={t(tokens.fixedAsset.searchInputPlaceholder)} className="inputBase" InputProps={{ startAdornment: <SearchIcon /> }} />
                    <Select onChange={handleChangeFilterSearchName} className='select' sx={{ height: "44px", maxWidth: "343px", width: "100%" }} displayEmpty defaultValue={SEARCH_NAME_FIXED_ASSETS[0].value} >
                        {SEARCH_NAME_FIXED_ASSETS.map(({ label, value }) => (
                            <MenuItem key={uuidv4()} value={value}>{label}</MenuItem>
                        ))}
                    </Select>
                </Stack>
                <Tabs variant="fullWidth" className='tabsMain' value={tab} onChange={handleChangeTabs} aria-label="fixed assets tabs">
                    {FILTER_TABS_FIXED_ASSETS?.map(({ label, value }) => (
                        <Tab key={uuidv4()} value={value} label={label} />
                    ))}
                </Tabs>
                <TableContainer component={Paper} sx={{ ...tableContainer }}>
                    <Table stickyHeader aria-label="sticky table" className='table' sx={{ width: "100%" }}>
                        {rowsGetFixedAsset.length > 0
                            ? <TableHead>
                                <TableRow>
                                    <TableCell style={{ minWidth: "250px", maxWidth: "250px" }}>Назва</TableCell>
                                    <TableCell align="center">Статус</TableCell>
                                    <TableCell align="center">Вартість</TableCell>
                                    <TableCell align="center">Дата купівлі</TableCell>
                                    <TableCell align="center">Амортизація</TableCell>
                                    <TableCell align="center">Гарантія до</TableCell>
                                    <TableCell align="center">Серійний номер</TableCell>
                                    <TableCell align="center">Зберігання</TableCell>
                                </TableRow>
                            </TableHead>
                            : null}
                        <TableBody sx={{ overflow: "hidden" }}>
                            {isLoadingGetFixedAssets || isFetchingGetFixedAssets
                                ? <TableRow style={{ boxShadow: "none" }}>
                                    <TableCell>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                                : rowsGetFixedAsset.length > 0
                                    ? rowsGetFixedAsset?.map(({ id, name, status, original_cost, purchase_date, amortization_amount, guarantee_period, serial_number }: any) => (
                                        <TableRow key={id} hover sx={{ cursor: "pointer" }} onClick={() => navigation(`/fixed-assets/${id}/detail`)}>
                                            <TableCell style={{ minWidth: "250px", maxWidth: "250px" }}>
                                                <Tooltip title={name}>
                                                    <Box component={"span"}>{name}</Box>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell align="center">{status ?? "Статус*"}</TableCell>
                                            <TableCell align="center">{original_cost}</TableCell>
                                            <TableCell align="center">{dayjs(purchase_date).format('DD.MM.YYYY')}</TableCell>
                                            <TableCell align="center">{amortization_amount}</TableCell>
                                            <TableCell align="center">{dayjs(guarantee_period).format('DD.MM.YYYY')}</TableCell>
                                            <TableCell align="center">{serial_number}</TableCell>
                                            <TableCell align="center">Зберігання*</TableCell>
                                        </TableRow>
                                    ))
                                    : <TableRow style={{ boxShadow: "none" }}>
                                        <TableCell colSpan={12}>
                                            <Stack alignItems={"center"} pt={5}>
                                                <EmptyStateImage />
                                                <Stack rowGap={3} alignItems={"center"}>
                                                    <Typography sx={{ color: "heatherField.main", fontSize: "20px", fontWeight: 500, lineHeight: "24px" }} >{t(tokens.fixedAsset.emptyStateMessage)}</Typography>
                                                    <Button disabled={!Create_Fixed_Assets} onClick={handleGoToCreateFixedAssetPage} sx={{ maxWidth: "249px", width: "100%" }} startIcon={<AddIcon />} variant="containedGreen">{"Додати"}</Button>
                                                </Stack>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
            {countGetFixedAsset / limit > 1
                && <Pagination onChange={handlePageChange} page={Math.ceil((offset + 1) / limit)} count={Math.ceil(countGetFixedAsset / limit)} shape="rounded" className='pagination' sx={{ paddingY: 1.5, alignSelf: "center" }} />}
        </>
    );
};