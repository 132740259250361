import { TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { FC } from "react";
import { CURRENCY } from "../../../../../config";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../locales/tokens";

interface IProps {
    payment: any;
};

export const PrepaymentTableItem: FC<IProps> = ({ payment }) => {
    const { t } = useTranslation();
    const paymentDate = dayjs(payment.payment_date).locale('uk').format('MMMM D, YYYY');
    const prepaymentDate = payment.prepayment.prepayment_date ? dayjs(payment.prepayment.prepayment_date).locale('uk').format('MMMM D, YYYY') : t(tokens.prepayment.dateNotAssign);

    return (
        <TableRow hover key={payment.id} sx={{ cursor: "pointer" }}>
            <TableCell align="center" >{paymentDate}</TableCell>
            <TableCell align="center" >{prepaymentDate}</TableCell>
            <TableCell align="center" >{payment.counterparty.name}</TableCell>
            <TableCell align="center" >{payment.amount + CURRENCY.UAH}</TableCell>
            <TableCell align="center" >{payment.comment || '-'}</TableCell>
        </TableRow>
    )
};