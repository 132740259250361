import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { setAddPermissionsError, setLoading } from "../../repository/actions";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";

function* addPermissionsToRoleWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.put, "/zoolux-api/role-permission/update", payload);
        if (isError) {
            toast.error(message);
        } else {
           
        }
    } catch (error) {
        console.error("addPermissionsToRoleWorker: ", error);
    } finally {

    };
};

export function* addPermissionsToRoleWatcher() {
    yield takeLatest("@saga/ADD_PERMISSIONS_TO_ROLE", addPermissionsToRoleWorker);
};
