import { FC, useMemo } from "react";
import { useRoleCreate } from "../presenters/useRoleCreate";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { MultiCheckboxSelect } from "./components/multiCheckboxSelect";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../locales/tokens";
import { getStyles } from "./styles";

export const RoleCreate: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { name, permission, permissions, handleChangePermission, handleDeletePermission, onChangeName,
        handleCreateRole, nameValid, permissionValid } = useRoleCreate();

    return (
        <Stack m={2} spacing={3}> 
            <Typography variant="h5" fontWeight={600}>
                {t(tokens.user.createRole)}
            </Typography>
            <Stack spacing={1} width="50%">
                <Typography
                    color={nameValid ? 'primary' : 'error'}
                >
                    {t(tokens.user.title)}*
                </Typography>
                <TextField
                    value={name}
                    onChange={onChangeName}
                    label={t(tokens.user.title) + '*'}
                    type="text"
                    error={!nameValid}
                />
            </Stack>
            <Stack spacing={1}>
                <Typography
                    color={permissionValid ? 'primary' : 'error'}
                >
                    {t(tokens.user.permissions)}*
                </Typography>
                <MultiCheckboxSelect
                    permission={permission}
                    permissions={permissions}
                    isValid={permissionValid}
                    handleChangePermission={handleChangePermission}
                    handleDeletePermission={handleDeletePermission}
                />
            </Stack>
            <Stack>
                <Button variant="contained" onClick={handleCreateRole} sx={styles.createButton}>
                    {t(tokens.common.create)}
                </Button>
            </Stack>
        </Stack>
    )
};