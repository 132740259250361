import type { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Checkbox, FormControlLabel, Menu, MenuItem, Stack, SvgIcon, Theme, Typography, useMediaQuery } from '@mui/material';
import { getStyles } from '../styles';
import { tokens } from '../../../../../../../locales/tokens';
import { useTranslation } from 'react-i18next';
import { useStatusSelect } from '../presenter/useStatusSelect';

interface IProps {
  chips: any[];
  setChips: Dispatch<SetStateAction<any[]>>;
  handleStatusPayments: (status: string[]) => void;
};

export const StatusSelect: FC<IProps> = ({ chips, setChips, handleStatusPayments }) => {
  const { t } = useTranslation();
  const styles = useMemo(() => getStyles(), []);
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { ChangeStatus, openMenu, anchorRef, handleMenuOpen, handleMenuClose, statusOptions, statusValues } = useStatusSelect(chips, setChips, handleStatusPayments);

  return (
    <>
      <Button color="primary" variant='contained' sx={styles.button} endIcon={(<SvgIcon><ExpandMoreIcon /></SvgIcon>)} onClick={handleMenuOpen} ref={anchorRef}>
        {t(tokens.payment.filters)}
      </Button>
      <Menu anchorEl={anchorRef.current} onClose={handleMenuClose} open={openMenu} PaperProps={{ style: { width: downSm ? 200 : 200 } }} sx={{ mt: 1 }}>
        <Stack direction={downSm ? "column" : "row"} spacing={1}>
          <Stack spacing={1} p={1}>
            <Typography pl={2} variant='subtitle2' fontWeight={600}>{t(tokens.payment.status)}</Typography>
            {statusOptions.map((option) => (
              <MenuItem key={option.label} sx={styles.menuItem}>
                <FormControlLabel control={(<Checkbox checked={statusValues.includes(option.value)} onChange={ChangeStatus} value={option.value} size='small' />)} label={option.label} sx={styles.formControl} />
              </MenuItem>
            ))}
          </Stack>
        </Stack>
      </Menu >
    </>
  );
};
