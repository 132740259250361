import type { FC } from 'react';
import { TableBody } from '@mui/material';
import { TableListItem } from '../tableListItem';

interface IProps {
    accounts: any[];
    handleAccountOpen: (partnerId: string) => void;
};

export const TableListBody: FC<IProps> = ({ accounts, handleAccountOpen }) => {

    return (
        <TableBody>
            {accounts?.map((account, index) => (
                <TableListItem
                    key={index}
                    account={account}
                    handleAccountOpen={handleAccountOpen}
                />
            )
            )}
        </TableBody>
    );
};
