import { FC, Fragment, useMemo } from "react";
import { TableCell, TableRow } from "@mui/material";
import { getStyles } from "./styles";
import dayjs from "dayjs";
import { t } from "i18next";
import { tokens } from "../../../../../../locales/tokens";
import { Link } from "react-router-dom";

interface IProps {
    payment: any;
};

export const AccountListItem: FC<IProps> = ({ payment }) => {
    const styles = useMemo(() => getStyles(), []);
    const paymentDate = dayjs(payment.payment_date).locale('uk').format('MMMM D, YYYY');

    const paymentMethods: any = {
        CREATED: t(tokens.payment.isCreated),
        VISAED: t(tokens.payment.isVisaed),
        OPERATED: t(tokens.payment.isOperated),
    };

    return (
        <TableRow
            key={payment.id}
            sx={styles.tableRow}
            hover
            component={Link}
            to={`/payment-edit/${payment.id}`}
        >
            <TableCell style={styles.cell}></TableCell>
            <TableCell style={styles.cell} >{payment.counterparty}</TableCell>
            <TableCell style={styles.cell} >{payment.amount}</TableCell>
            <TableCell style={styles.cell} >{paymentDate}</TableCell>
            <TableCell style={styles.cell} >{payment?.initiator}</TableCell>
            <TableCell style={styles.cell} >{payment.payment_method}</TableCell>
            <TableCell style={styles.cell} >{paymentMethods[payment.status]}</TableCell>
            <TableCell style={styles.cell} >{payment.comment}</TableCell>
        </TableRow>
    )
};