import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectPayments } from "../../../store/paymentCalendar/repository/selector";
import { createFilterOptions } from "@mui/material";
import { getServicePartners } from "../../../store/paymentCalendar/useCases/getServicePartners/action";

export const usePartnerServiceAutocomplete = (onPartnerChange: (value: any, index?: number) => void, index?: number) => {
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState(false);
    const [dialogValue, setDialogValue] = useState({ name: '', type: '' });
    const { servicePartners } = useSelector(selectPayments);

    useEffect(() => {
        dispatch(getServicePartners({ offset: 0, limit: 20, search: '' }));
    }, [dialogValue.type]);

    const handleClose = () => {
        setDialogValue({ name: '', type: '' });
        setOpen(false);
    };

    const handleOnChange = (event: any, newValue: any) => {
        if (typeof newValue === 'string') {
            setTimeout(() => {
                setOpen(true);
                setDialogValue({ name: newValue, type: '' });
            });
        } else if (newValue && newValue.inputValue) {
            setOpen(true);
            setDialogValue({ name: newValue.inputValue, type: '' });
        } else {
            onPartnerChange(newValue, index);
        };
    };

    const filter = createFilterOptions<any>();

    const filterPartnersOptions = (options: any[], params: any) => {
        const filtered = filter(options, params);
        if (params.inputValue !== '') {
            filtered.push({
                inputValue: params.inputValue,
                name: `Додати: "${params.inputValue}"`,
                type: ''
            });
        };
        return filtered;
    };
    
    return { isOpen, dialogValue, filterPartnersOptions, handleClose, handleOnChange, servicePartners, setDialogValue }
};