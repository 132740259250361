import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';

interface IState {
  isLoading: boolean,
  usersFromTelegram: any[],
  usersCount: number,
  telegramCode: string;
  updateTelegramCodeError: IError,
  deleteUserError: IError,
};

const initialState: IState = {
  isLoading: false,
  usersFromTelegram: [],
  usersCount: 0,
  telegramCode: '',
  updateTelegramCodeError: null,
  deleteUserError: null,
};

const TelegramBotSlice = createSlice({
  name: 'telegramBot',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setTelegramCode: (state, action: PayloadAction<string>) => {
      state.telegramCode = action.payload;
    },
    setUpdateTelegramCodeError: (state, action: PayloadAction<IError>) => {
      state.updateTelegramCodeError = action.payload;
    },
    setDeleteUserError: (state, action: PayloadAction<IError>) => {
      state.deleteUserError = action.payload;
    },
    setUsersFromTelegram: (state, action: PayloadAction<any>) => {
      state.usersFromTelegram = action.payload.list;
      state.usersCount = action.payload.count;
    },
  },
});

export const { setLoading, setUpdateTelegramCodeError, setUsersFromTelegram, setDeleteUserError, setTelegramCode } = TelegramBotSlice.actions;
export const TelegramBotReducer = TelegramBotSlice.reducer;