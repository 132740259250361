export const getStyles = () => {
    const styles = {
        activeSectionContainer: {
            alignItems: 'center',
            display: 'center',
            height: 20,
            justifyContent: 'center',
            width: 20,
        },
        section: {
            backgroundColor: 'var(--nav-item-icon-color)',
            borderRadius: '50%',
            opacity: 0,
            height: 4,
            width: 4,
        },
        activeSection: {
            backgroundColor: '#FFFFFF',
            height: 6,
            opacity: 1,
            width: 6
        },
        collapse: { 
            mt: 0.5, 
            position: 'relative' 
        }
    }
    return styles;
}