import { SagaIterator } from "redux-saga";
import { put, call, takeEvery, select } from "redux-saga/effects";
import { requester } from "../../../../libs/requester/requester-axios";
import { IResponse } from "../../../../libs/requester/IResponse";
import toast from "react-hot-toast";
import { setSinglePayment } from "../../repository/actions";
import { setConfirmPaymentFile, setFiles } from "../../../paymentCreation/repository/slice";

function* addConfirmPaymentFileWorker({ payload }: any): SagaIterator {
    try {
        const formData = new FormData();
        for (const [key, value] of Object.entries(payload.body as Object)) {
            const isValue = value !== null && value !== undefined && value !== "";
            if (isValue) {
                if (Array.isArray(value)) {
                    value.forEach(file => {
                        formData.append(key, file);
                    });
                } else {
                    formData.append(key, value);
                }
            }
        }; 
        const url = payload.mode === "Payment"? "/zoolux-api/payment/add-confirm-files" : "/zoolux-api/budget-calendar/add-confirm-files";
        const { data, isError, message }: IResponse<any> = yield call(requester.post, url, formData, { "Content-Type": "multipart/form-data" });
        if (isError) {
            toast.error(message);
        } else {
            if(payload.isUpdate){
                yield put(setConfirmPaymentFile(data.confirmPaymentFiles[data.confirmPaymentFiles.length - 1] as File));
            }
        }
    } catch (error) {
        console.error("addConfirmPaymentFileWorker: ", error);
    } finally {

    }
};

export function* addConfirmPaymentFileWatcher() {
    yield takeEvery("@saga/ADD_CONFIRM_PAYMENT_FILE", addConfirmPaymentFileWorker);
};