import { useDispatch, useSelector } from "react-redux";
import { selectPaymentCreation } from "../../../../store/paymentCreation/repository/selector";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { setFields, setPaymentMethod } from "../../../../store/paymentCreation/repository/slice";
import { getPaymentMethod } from "../../../../store/paymentCalendar/useCases/getPaymentMethod/action";
import { getPaymentKind } from "../../../../store/paymentCalendar/useCases/getPaymentKind/action";
import { getPaymentType } from "../../../../store/paymentCalendar/useCases/getPaymentType/action";
import { getExpenditure } from "../../../../store/paymentCalendar/useCases/getExpenditure/action";
import { getPartners } from "../../../../store/paymentCalendar/useCases/getPartners/action";
import { getClinics } from "../../../../store/paymentCalendar/useCases/getClinics/action";
import { selectPayments } from "../../../../store/paymentCalendar/repository/selector";
import { getServiceList } from "../../../../store/service/useCases/getServiceList/action";
import { getAccountList } from "../../../../store/paymentCalendar/useCases/getAccountList/action";
import { useParams } from "react-router-dom";
import { getSinglePayment } from "../../../../store/paymentCalendar/useCases/getSinglePayment/action";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ContactlessIcon from '@mui/icons-material/Contactless';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { CALENDAR_MODE } from "../../../../config";

export const useInitPayment = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [currentTab, setCurrentTab] = useState<any>(false);
    const { paymentMethod, formStatus } = useSelector(selectPaymentCreation);
    const { paymentMethods } = useSelector(selectPayments);
    const { singlePayment } = useSelector(selectPayments);
    const storedMode = JSON.parse(localStorage.getItem('mode') || 'false');
    const mode = storedMode || CALENDAR_MODE.PAYMENT;
    const [isLoading, setIsLoading] = useState(false);

    const paymentMethodOptions = paymentMethods.map((method: any) => {
        if (method.key === 'card') {
            return { ...method, icon: CreditCardIcon };
        } else if(method.key === 'cash'){
            return { ...method, icon: AttachMoneyIcon };
        } else {
            return { ...method, icon: ContactlessIcon };
        }
    });

    useEffect(() => {
        dispatch(getPaymentMethod());
        dispatch(getPaymentKind());
        dispatch(getPaymentType());
        dispatch(getExpenditure({ offset: 0, limit: 20, search: '' }));
        dispatch(getPartners({ offset: 0, limit: 20, search: '' }));
        dispatch(getClinics());
        dispatch(getServiceList({ page: 1, perPage: 100 }));
    }, []);

    useEffect(() => {
        paymentMethod && dispatch(getAccountList({ offset: 0, limit: 20, search: '', account_type: paymentMethod.name_enote }));
    }, [paymentMethod]);

    useEffect(() => {
        if (id) {
            setIsLoading(true);
            dispatch(getSinglePayment({ body: { id: id }, mode }));
        }
    }, [id]);

    useEffect(() => {
        if (singlePayment) {
            dispatch(setPaymentMethod(singlePayment.payment.payment_method));
            setTimeout(() => {
                dispatch(setFields(singlePayment));
                setIsLoading(false);
            }, 0);
        };
    }, [singlePayment]);

    const handleTabsChange = useCallback((event: ChangeEvent<{}>, tab: string): void => {
        const currentMethod = paymentMethods.find((method: any) => method.key === tab);
        setCurrentTab(tab);
        dispatch(setPaymentMethod(currentMethod));
    }, [paymentMethods]);

    const paymentStatus = formStatus || singlePayment?.payment?.status;

    return { currentTab, handleTabsChange, paymentMethodOptions, paymentMethod, mode, singlePayment, paymentStatus, isLoading }
};