import { Autocomplete, Stack, TextField, Theme, Typography, useMediaQuery } from "@mui/material";
import { ChangeEvent, Dispatch, FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";
import { IPartnerOption, collectPartnerList } from "../../../utils/collectOptionList";
import { usePartnerAutocomplete } from "../presenter/usePartnerAutocomplete";
import { PartnerCreateModal } from "../../../modules/payment-calendar/payment-create/ui/components/partnerCreateModal";
import { TScreenFlag } from "../../../modules/paymentCalendar/payment/ui/components/parts/paymentData/presenter/useServicePayment";

interface IProps {
    index?: number;
    value: IPartnerOption | null;
    isValid: boolean;
    screenFlag?: TScreenFlag;
    isDisabled?: boolean;
    onPartnerChange: (value: any) => void;
};

export const PartnerAutocomplete: FC<IProps> = ({ index, value, onPartnerChange, isValid, screenFlag, isDisabled }) => {
    const { t } = useTranslation();
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const isDisabledField = screenFlag === "SERVICE" || isDisabled;
    const { dialogValue, handleClose, handleOnChange, filterPartnersOptions, isOpen, partners, setDialogValue, changePartnerText } = usePartnerAutocomplete(onPartnerChange, index); 

    return (
        <Stack direction='column' alignItems='flex-start' spacing={1} width='100%'>
            <Typography color={isValid ? 'primary' : 'error'} variant={downSm ? 'body1' : 'h6'}>
                {t(tokens.payment.partner)}
            </Typography>
            <Autocomplete
                disablePortal
                onChange={(e, value) => handleOnChange(e, value)}
                value={value}
                options={collectPartnerList(partners) as IPartnerOption[]}
                filterOptions={filterPartnersOptions}
                getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                        return option;
                    }
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option.name;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(props, option) =>
                    <li {...props} key={option.name + option.id}>{option.name}</li>
                }
                freeSolo
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label={t(tokens.payment.partner)}
                        error={!isValid}
                        onChange={changePartnerText}
                    />
                }
                clearIcon={false}
                sx={{ width: '100%' }}
                size="small"
                disabled={isDisabledField}
            /> 
            <PartnerCreateModal
                partnerName={dialogValue.name}
                isOpen={isOpen}
                handleToggle={handleClose}
                setDialogValue={setDialogValue}
            />
        </Stack>
    )
};