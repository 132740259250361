export const getStyles = () => {
    const styles = {
        chipLabel: { 
            alignItems: "center", 
            display: "flex", 
            "& span": { fontWeight: 600 } 
        },
        chip: { 
            fontSize: '12px' 
        }
    };
    return styles;
};