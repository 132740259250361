import { IError } from "../../../types/error";

interface IInitialState {
    users: any[],
    usersCount: number,
    singleUser: any,
    roles: any[],
    permissions: any[],
    isLoading: boolean,
    addPermissionsError: IError;
    deleteRoleError: IError;
    getAccessError: IError;
}

const initialState: IInitialState = {
    users: [],
    usersCount: 0,
    singleUser: null,
    roles: [],
    permissions: [],
    isLoading: false,
    addPermissionsError: null,
    deleteRoleError: null,
    getAccessError: null
};

export function userReducer(state: IInitialState = initialState, action: any): IInitialState {
    const { type, payload } = action;
    switch (type) {
        case 'SET_USERS':
            return { ...state, users: payload };
        case 'SET_USERS_COUNT':
            return { ...state, usersCount: payload };
        case 'SET_SINGLE_USER':
            return { ...state, singleUser: payload };
        case 'SET_ROLES':
            return { ...state, roles: payload };
        case 'SET_PERMISSIONS':
            return { ...state, permissions: payload };
        case 'SET_ADD_PERMISSIONS_ERROR':
            return { ...state, addPermissionsError: payload };
        case 'SET_DELETE_ROLE_ERROR':
            return { ...state, deleteRoleError: payload };
        case 'SET_LOADING_USER':
            return { ...state, isLoading: payload };
        case 'SET_GET_ACCESS_ERROR':
            return { ...state, getAccessError: payload };
        default:
            return state;
    };
};

