import { Button, Dialog, IconButton, Stack, SvgIcon, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from "react-i18next";
import { getStyles } from "./styles";
import { tokens } from "../../../../../../locales/tokens";

interface IProps {
    title: string;
    description: string;
    isOpen: boolean;
    handleToggleModal: () => void;
    updatePaymentInModal: () => void;
};

export const UpdateModal: FC<IProps> = ({ isOpen, handleToggleModal, updatePaymentInModal, title, description }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);

    return (
        <Dialog open={isOpen} maxWidth="md">
            <Stack p={3}>
                <IconButton onClick={handleToggleModal} sx={styles.clearIcon}>
                    <SvgIcon fontSize="small"><ClearIcon /></SvgIcon>
                </IconButton>
                <Typography variant="body1" fontWeight={600} mt={3}>
                    {description}
                </Typography>
                <Stack direction="row" spacing={1} mt={3} justifyContent="center">
                    <Button
                        variant="contained"
                        sx={styles.buttonConfirm}
                        onClick={updatePaymentInModal}
                    >
                        {title}
                    </Button>
                    <Button
                        variant="contained"
                        sx={styles.buttonCancel}
                        onClick={handleToggleModal}
                    >
                        {t(tokens.payment.getBack)}
                    </Button>
                </Stack>
            </Stack>
        </Dialog>
    )
};