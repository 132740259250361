import { ChangeEvent, FC, useMemo } from "react";
import { Pagination, Paper, Stack, Table } from "@mui/material";
import { ImportsTableBody } from "../importsTableBody";
import { ImportsTableHead } from "../importsTableHead";
import { getStyles } from "./styles";

interface IProps {
    uploadList: any[];
    page: number;
    pageAmount: number;
    uploadPayments: any[];
    rowId: number;
    isItemOpen: boolean;
    onChangeItemOpen: (id: number, isItemOpen: boolean) => void;
    handlePageChange: (event: ChangeEvent<unknown>, page: number) => void;
};

export const ImportsTable: FC<IProps> = ({ uploadList, handlePageChange, pageAmount, page, isItemOpen, onChangeItemOpen, rowId, uploadPayments }) => {
    const styles = useMemo(() => getStyles(), []);
    
    return (
        <Paper elevation={12} sx={styles.paper}>
            <Table>
                <ImportsTableHead />
                <ImportsTableBody
                    uploadList={uploadList}
                    uploadPayments={uploadPayments}
                    rowId={rowId}
                    isItemOpen={isItemOpen}
                    onChangeItemOpen={onChangeItemOpen}
                />
            </Table>
            <Stack direction='row' justifyContent='center' my={4}>
                <Pagination
                    page={page}
                    count={pageAmount || 1}
                    color="primary"
                    onChange={handlePageChange}
                />
            </Stack>
        </Paper>
    )
};