import { all, fork } from "redux-saga/effects";
import { verifyPhoneWatcher } from "./auth/useCases/verifyPhone/worker";
import { createPasswordWatcher } from "./auth/useCases/createPassword/worker";
import { SignInWatcher } from "./auth/useCases/signIn/worker";
import { getPaymentsWatcher } from "./paymentCalendar/useCases/getPayments/worker";
import { updatePaymentWatcher } from "./paymentCalendar/useCases/updatePayment/worker";
import { getUsersAllWatcher } from "./users/usecases/getUsersAll/worker";
import { editUserWatcher } from "./users/usecases/editUser/worker";
import { confirmSmsCodeWatcher } from "./auth/useCases/confirmSmsCode/worker";
import { getCurrentUserWatcher } from "./auth/useCases/getCurrentUser/worker";
import { getSingleUserWatcher } from "./users/usecases/getSingleUser/worker";
import { getRolesWatcher } from "./users/usecases/getRoles/worker";
import { setRoleWatcher } from "./users/usecases/setRole/worker";
import { getExpenditureWatcher } from "./paymentCalendar/useCases/getExpenditure/worker";
import { setAvatarWatcher } from "./users/usecases/setAvatar/worker";
import { recoverPasswordWatcher } from "./auth/useCases/recoverPassword/worker";
import { deleteRoleWatcher } from "./users/usecases/deleteRole/worker";
import { getPaymentMethodWatcher } from "./paymentCalendar/useCases/getPaymentMethod/worker";
import { getPaymentKindWatcher } from "./paymentCalendar/useCases/getPaymentKind/worker";
import { getPaymentTypeWatcher } from "./paymentCalendar/useCases/getPaymentType/worker";
import { getPartnersWatcher } from "./paymentCalendar/useCases/getPartners/worker";
import { getAccountListWatcher } from "./paymentCalendar/useCases/getAccountList/worker";
import { getClinicsWatcher } from "./paymentCalendar/useCases/getClinics/worker";
import { getBankCardsWatcher } from "./paymentCalendar/useCases/getBankCards/worker";
import { createPaymentWatcher } from "./paymentCalendar/useCases/createPayment/worker";
import { getSinglePaymentWatcher } from "./paymentCalendar/useCases/getSinglePayment/worker";
import { addFileWatcher } from "./paymentCalendar/useCases/addFile/worker";
import { deleteFileWatcher } from "./paymentCalendar/useCases/removeFile/worker";
import { downloadFileWatcher } from "./paymentCalendar/useCases/downloadFile/worker";
import { watchFileWatcher } from "./paymentCalendar/useCases/watchFile/worker";
import { setVisaWatcher } from "./paymentCalendar/useCases/setVisa/worker";
import { deleteVisaWatcher } from "./paymentCalendar/useCases/deleteVisa/worker";
import { processPaymentWatcher } from "./paymentCalendar/useCases/processPayment/worker";
import { addBankDetailsWatcher } from "./paymentCalendar/useCases/addBankDetails/worker";
import { createPartnerWatcher } from "./partners/useCases/createPartner/worker";
import { editPartnerWatcher } from "./partners/useCases/editPartner/worker";
import { getSinglePartnerWatcher } from "./partners/useCases/getSinglePartner/worker";
import { getAllAccountsWatcher } from "./accounts/useCases/getAllAccounts/worker";
import { updateAccountWatcher } from "./accounts/useCases/updateAccount/worker";
import { getSingleAccountWatcher } from "./accounts/useCases/getSingleAccount/worker";
import { refreshUsersWatcher } from "./users/usecases/refreshUsers/worker";
import { refreshPartnerssWatcher } from "./partners/useCases/refreshPartners/worker";
import { refreshAccountsWatcher } from "./accounts/useCases/refreshAccounts/worker";
import { getInputAccountsWatcher } from "./accounts/useCases/getInputAccounts/worker";
import { changePrepaymentStatusWatcher } from "./paymentCalendar/useCases/changePrepaymentStatus/worker";
import { getUsersWatcher } from "./users/usecases/getUsers/worker";
import { getAllExpenseItemsWatcher } from "./expenseItem/useCases/getAllExpenseItems/worker";
import { updateExpenseItemWatcher } from "./expenseItem/useCases/updateExpenseItem/worker";
import { refreshExpenseItemsWatcher } from "./expenseItem/useCases/refreshExpenseItems/worker";
import { getPartnersAllWatcher } from "./partners/useCases/getPartnersAll/worker";
import { passToPaymentWatcher } from "./paymentCalendar/useCases/passToPayment/worker";
import { deletePaymentWatcher } from "./paymentCalendar/useCases/deletePayment/worker";
import { deleteGroupPaymentsWatcher } from "./paymentCalendar/useCases/deleteGroupPayments/worker";
import { getPermissionsWatcher } from "./users/usecases/getPermissions/worker";
import { createRoleWatcher } from "./users/usecases/createRole/worker";
import { addPermissionsToRoleWatcher } from "./users/usecases/addPermissionsToRole/worker";
import { deleteUserRoleWatcher } from "./users/usecases/deleteUserRole/worker";
import { editRoleWatcher } from "./users/usecases/editRole/worker";
import { uploadImportEnotWatcher } from "./importPayment/useCases/uploadPaymentEnot/worker";
import { uploadImportCalendarWatcher } from "./importPayment/useCases/updatePaymentCalendar/worker";
import { getUploadListWatcher } from "./importPayment/useCases/getUploadList/worker";
import { getUploadPaymentsWatcher } from "./importPayment/useCases/getUploadPayments/worker";
import { getPaymentsListWatcher } from "./paymentCalendar/useCases/getPaymentsList/worker";
import { getPrepaymentListWatcher } from "./prepayment/useCases/getPrepayments/worker";
import { addConfirmPaymentFileWatcher } from "./paymentCalendar/useCases/addConfirmPaymentFile/worker";
import { removeConfirmPaymentFileWatcher } from "./paymentCalendar/useCases/removeConfirmPaymentFile/worker";
import { getPartnerFoldersWatcher } from "./partners/useCases/getPartnerFolders/worker";
import { getContractsListWatcher } from "./contract/useCases/getContractsList/worker";
import { getSingleContractWatcher } from "./contract/useCases/getSingleContract/worker";
import { editContractWatcher } from "./contract/useCases/editContract/worker";
import { createContractWatcher } from "./contract/useCases/createContract/worker";
import { addImageToContractWatcher } from "./contract/useCases/addImageToContract/worker";
import { deleteImageFromContractWatcher } from "./contract/useCases/deleteImageFromContract/worker";
import { deleteContractWatcher } from "./contract/useCases/deleteContract/worker";
import { getServiceListWatcher } from "./service/useCases/getServiceList/worker";
import { deleteServiceWatcher } from "./service/useCases/deleteService/worker";
import { createServiceWatcher } from "./service/useCases/createService/worker";
import { getSingleServiceWatcher } from "./service/useCases/getSingleService/worker";
import { editServiceWatcher } from "./service/useCases/editService/worker";
import { getUsersFromTelegramWatcher } from "./telegramBot/useCases/getUsersFromTelegram/worker";
import { deleteUserFromTelegramWatcher } from "./telegramBot/useCases/deleteUser/worker";
import { getTelegramCodeWatcher } from "./telegramBot/useCases/getTelegramCode/worker";
import { editTelegramCodeWatcher } from "./telegramBot/useCases/editTelegramCode/worker";
import { getAccessToAccountWatcher } from "./users/usecases/getAccessToAccount/worker";
import { linkPaymentToServiceWatcher } from "./paymentCalendar/useCases/linkPaymentToService/worker";
import { updateServicePaymentWatcher } from "./paymentCalendar/useCases/updateServicePayment/worker";
import { deleteServicePaymentWatcher } from "./paymentCalendar/useCases/deleteServicePayment/worker";
import { getServiceListAllWatcher } from "./service/useCases/getServiceListAll/worker";
import { createServiceRelatedWatcher } from "./serviceRelated/useCases/createServiceRelated/worker";
import { editServiceRelatedWatcher } from "./serviceRelated/useCases/editServiceRelated/worker";
import { deleteServiceRelatedWatcher } from "./serviceRelated/useCases/deleteServiceRelated/worker";
import { completeBudgetOrderWatcher } from "./paymentCalendar/useCases/completeBudgetOrder/worker";
import { getServicePartnersWatcher } from "./paymentCalendar/useCases/getServicePartners/worker";
import { getServiceHistoryListWatcher } from "./serviceHistory/useCases/getServiceHistoryList/worker";
import { createServicePaymentWatcher } from "./serviceHistory/useCases/createServicePayment/worker";
import { getSingleServicePaymentWatcher } from "./serviceHistory/useCases/getSingleServicePayment/worker";
import { setServiceVisaWatcher } from "./serviceHistory/useCases/setServiceVisa/worker";
import { deleteServiceVisaWatcher } from "./serviceHistory/useCases/deleteServiceVisa/worker";
import { processServiceWatcher } from "./serviceHistory/useCases/processService/worker";
import { updateSingleServicePaymentWatcher } from "./serviceHistory/useCases/updateServicePayment/worker";
import { getFinanciersWatcher } from "./paymentCalendar/useCases/getFinanciers/worker";
import { editNomenclatureWatcher } from "./nomenclature/useCases/editNomenclature/worker";
import { getNomenclatureListWatcher } from "./nomenclature/useCases/getNomenclatureList/worker";
import { getConfigurationWatcher } from "./configuration/useCases/getConfiguration/worker";
import { getPaymentsWeekWatcher } from "./paymentCalendar/useCases/getPaymentsWeek/worker";

export function* rootSaga() {
    yield all([
        fork(verifyPhoneWatcher),
        fork(confirmSmsCodeWatcher),
        fork(createPasswordWatcher),
        fork(SignInWatcher),
        fork(getCurrentUserWatcher),
        fork(recoverPasswordWatcher),
        fork(getPaymentsWatcher),
        fork(createPaymentWatcher),
        fork(updatePaymentWatcher), 
        fork(getExpenditureWatcher), 
        fork(getPaymentMethodWatcher), 
        fork(getPaymentKindWatcher), 
        fork(getPaymentTypeWatcher), 
        fork(getPartnersWatcher), 
        fork(getAccountListWatcher), 
        fork(getClinicsWatcher), 
        fork(getBankCardsWatcher), 
        fork(getSinglePaymentWatcher), 
        fork(addFileWatcher), 
        fork(deleteFileWatcher), 
        fork(downloadFileWatcher), 
        fork(watchFileWatcher), 
        fork(setVisaWatcher), 
        fork(deleteVisaWatcher), 
        fork(processPaymentWatcher), 
        fork(addBankDetailsWatcher), 
        fork(getSingleUserWatcher),
        fork(editUserWatcher),
        fork(getRolesWatcher),
        fork(setRoleWatcher),
        fork(deleteRoleWatcher),
        fork(setAvatarWatcher),
        fork(createPartnerWatcher),
        fork(editPartnerWatcher),
        fork(getSinglePartnerWatcher),
        fork(getAllAccountsWatcher),
        fork(updateAccountWatcher),
        fork(getSingleAccountWatcher),
        fork(refreshUsersWatcher),
        fork(refreshPartnerssWatcher),
        fork(refreshAccountsWatcher),
        fork(getInputAccountsWatcher),
        fork(changePrepaymentStatusWatcher),
        fork(getUsersAllWatcher),
        fork(getUsersWatcher),
        fork(getAllExpenseItemsWatcher),
        fork(updateExpenseItemWatcher),
        fork(refreshExpenseItemsWatcher),
        fork(getPartnersAllWatcher),
        fork(passToPaymentWatcher),
        fork(deletePaymentWatcher),
        fork(deleteGroupPaymentsWatcher),
        fork(getPermissionsWatcher),
        fork(createRoleWatcher),
        fork(addPermissionsToRoleWatcher),
        fork(deleteUserRoleWatcher),
        fork(editRoleWatcher),
        fork(uploadImportEnotWatcher),
        fork(uploadImportCalendarWatcher),
        fork(getUploadListWatcher),
        fork(getUploadPaymentsWatcher),
        fork(getPaymentsListWatcher),
        fork(getPrepaymentListWatcher),
        fork(addConfirmPaymentFileWatcher),
        fork(removeConfirmPaymentFileWatcher),
        fork(getPartnerFoldersWatcher),
        fork(getContractsListWatcher),
        fork(getSingleContractWatcher),
        fork(editContractWatcher),
        fork(createContractWatcher),
        fork(addImageToContractWatcher),
        fork(deleteImageFromContractWatcher),
        fork(deleteContractWatcher),
        fork(getServiceListWatcher),
        fork(deleteServiceWatcher),
        fork(createServiceWatcher),
        fork(getSingleServiceWatcher),
        fork(editServiceWatcher),
        fork(getUsersFromTelegramWatcher),
        fork(deleteUserFromTelegramWatcher),
        fork(getTelegramCodeWatcher),
        fork(editTelegramCodeWatcher),
        fork(getAccessToAccountWatcher),
        fork(linkPaymentToServiceWatcher),
        fork(updateServicePaymentWatcher),
        fork(deleteServicePaymentWatcher),
        fork(getServiceListAllWatcher),
        fork(createServiceRelatedWatcher),
        fork(editServiceRelatedWatcher),
        fork(deleteServiceRelatedWatcher),
        fork(completeBudgetOrderWatcher),
        fork(getServicePartnersWatcher),
        fork(getServiceHistoryListWatcher),
        fork(createServicePaymentWatcher),
        fork(getSingleServicePaymentWatcher),
        fork(setServiceVisaWatcher),
        fork(deleteServiceVisaWatcher),
        fork(processServiceWatcher),
        fork(updateSingleServicePaymentWatcher),
        fork(getFinanciersWatcher),
        fork(editNomenclatureWatcher),
        fork(getNomenclatureListWatcher),
        fork(getConfigurationWatcher),
        fork(getPaymentsWeekWatcher),
    ]);
};