export const getStyles = () => {
    const styles = {
        card: {
            p: 3,
            my: 3,
            borderRadius: '4px',
        },
        progress: { 
            width: '100%', 
            mt: 2 
        }
    };
    return styles;
}