import { Button, Stack, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getStyles } from "./styles";
import { tokens } from "../../../../../../locales/tokens";
import { ImportFileModal } from "../importFileModal";
import { useGetPermissions } from "../../../../../../hooks/useGetPermission";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PetsIcon from '@mui/icons-material/Pets';

interface IProps {
    file: any;
    isDialogOpen: boolean;
    uploadFile: () => void;
    handleImportInEnot: () => void;
    handleImportInCalendar: () => void;
    handleDialogClose: () => void;
    handleFileUpload: (event: any) => void;
};

export const ImportPaymentHeader: FC<IProps> = ({ file, isDialogOpen, handleDialogClose, handleFileUpload, handleImportInCalendar,
handleImportInEnot, uploadFile }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { isCreate_Import_Payment  } = useGetPermissions();

    return (
        <Stack sx={styles.header}>
            <Typography variant="h4" textAlign='center'>
                {t(tokens.importPayment.unloadingInfo)}
            </Typography>
            <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center" width="100%" mt={2}>
                <Button
                    size="small"
                    variant="contained"
                    disabled={!isCreate_Import_Payment}
                    sx={styles.createBtn}
                    onClick={handleImportInEnot}
                    startIcon={<PetsIcon/>}
                >
                    {t(tokens.importPayment.importInEnot)}
                </Button>
                <Button
                    variant="contained"
                    sx={styles.createBtn}
                    disabled={!isCreate_Import_Payment}
                    size="small"
                    onClick={handleImportInCalendar}
                    startIcon={<CalendarMonthIcon/>}
                >
                    {t(tokens.importPayment.importInCalendar)}
                </Button>
            </Stack>
            <ImportFileModal
                file={file}
                isDialogOpen={isDialogOpen}
                uploadFile={uploadFile}
                handleDialogClose={handleDialogClose}
                handleFileUpload={handleFileUpload}
            />
        </Stack>
    )
};