import { FC } from "react";
import { useConfiguration } from "../presenter/useConfiguration";
import { Stack, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";

export const Configuration: FC = () => {
    const { t } = useTranslation();
    const { configuration } = useConfiguration();

    return (
        <Stack mt={4} spacing={2} alignItems="flex-start">
            <Stack direction="row" alignItems="center" spacing={2} width="100%">
                <Typography variant="body1" fontWeight={600} flex={1} textAlign="end" pl={13.7}>{t(tokens.configuration.key)}</Typography>
                <TextField value={configuration?.ENOTE_URL} disabled fullWidth/>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2} width="100%">
                <Typography variant="body1" fontWeight={600} flex={1} textAlign="end">{t(tokens.configuration.meaning)}</Typography>
                <TextField value={configuration?.ORGANIZATION_REF_KEY} disabled fullWidth/>
            </Stack>
        </Stack>
    )
};