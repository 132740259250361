import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";
import { setAccountList } from "../../repository/actions";

function* getAccountListWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.get, "/zoolux-api/company-accounts/list", payload);

        if (isError) {
            toast.error(message);
        } else {
            yield put(setAccountList(data.data));
        }
    } catch (error) {
        console.error("getAccountListWorker: ", error);
    } finally {

    }
};

export function* getAccountListWatcher() {
    yield takeLatest("@saga/GET_ACCOUNT_LIST", getAccountListWorker);
};
