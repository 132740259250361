import { Stack } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { PasswordInput } from "../passwordInput";
import { VisibleButton } from "../visibleButton";

interface IProps {
    title: string;
    validText: string;
    supportText: string;
    password: string;
    passwordValid: boolean;
    isPassShown: boolean;
    isPhoneInBD: boolean;
    onShowPassword: () => void;
    onChangePassword: (event: ChangeEvent<HTMLInputElement>) => void;
    handleEnterPassword: (e: React.KeyboardEvent) => void;
}

export const PasswordField: FC<IProps> = ({ isPassShown, handleEnterPassword, onChangePassword, onShowPassword, password, passwordValid, isPhoneInBD, title, validText, supportText }) => {

    return (
        <Stack sx={{ position: "relative" }}>
            <PasswordInput
                title={title}
                validText={validText}
                supportText={supportText}
                password={password}
                onChangePassword={onChangePassword}
                handleEnterPassword={handleEnterPassword}
                passwordValid={passwordValid}
                showPassword={isPassShown}
                isPhoneInBD={isPhoneInBD} />
            <VisibleButton
                isPassShown={isPassShown}
                onShowPassword={onShowPassword}
            />
        </Stack>
    )
}