import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setSingleAccountError } from "../../repository/slice";

function* updateAccountWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, isError, message }: IResponse<any> = yield call(requester.put, "/zoolux-api/admin/company-accounts", payload);
        if (isError) {
            yield put(setSingleAccountError(message));
        } else {
            yield put(setSingleAccountError(null));
        }
    } catch (error) {
        console.error("updateAccountWorker: ", error);
    } finally {
        yield put(setLoading(false));
    }
};

export function* updateAccountWatcher() {
    yield takeLatest("@saga/UPDATE_ACCOUNT", updateAccountWorker);
};
