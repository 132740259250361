import { TableBody } from "@mui/material";
import { FC } from "react";
import { NomenclatureTableItem } from "../nomenclatureTableItem";
import { INomeclature } from "../../../../../store/nomenclature/repository/types";

interface IProps {
    nomenclatureList: INomeclature[];
    handleUpdateNomenclature: (id: number, isActive: boolean) => void;
};

export const NomenclatureTableBody: FC<IProps> = ({ nomenclatureList, handleUpdateNomenclature }) => {

    return (
        <TableBody>
            {nomenclatureList.map((nomenclature: INomeclature) =>
                <NomenclatureTableItem
                    key={nomenclature.id}
                    nomenclature={nomenclature}
                    handleUpdateNomenclature={handleUpdateNomenclature}
                />
            )}
        </TableBody>
    )
};