import { useDispatch, useSelector } from "react-redux";
import { selectPayments } from "../../../store/paymentCalendar/repository/selector";
import { ChangeEvent } from "react";
import { useDebounce } from "../../../hooks/useDebounce";
import { getExpenditure } from "../../../store/paymentCalendar/useCases/getExpenditure/action";

export const useExpenditureAutocomplete = () => {
    const dispatch = useDispatch();
    const { expenditure } = useSelector(selectPayments);

    const changeExpenseItemText = (event: ChangeEvent<HTMLInputElement>) => {
        debouncedHandleExpenseItemSeacrh(event.target.value);
    };

    const onSearchExpenseItem = (value: string) => {
        if (value.length) {
            dispatch(getExpenditure({ offset: 0, limit: 20, search: value }));
        } else {
            dispatch(getExpenditure({ offset: 0, limit: 20, search: '' }));
        }
    };

    const { debouncedWrapper: debouncedHandleExpenseItemSeacrh } = useDebounce(onSearchExpenseItem, 1000);

    return { expenditure, changeExpenseItemText }
};