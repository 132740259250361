import { Login } from "../modules/auth/login/ui";
import { FC, Fragment } from "react"
import { Route, Routes } from "react-router-dom";
import { ForgotPassword } from "../modules/auth/forgotPassword/ui";

export const PublicRoutes: FC = () => {

    return (
        <Routes>
            <Route path='/' element={<Login />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
        </Routes>
    )
};