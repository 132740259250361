export const getStyles = () => {
    const styles = {
        input: { 
            cursor: 'pointer', 
            width: '100px', 
            height: '15px' 
        },
        text: { 
            whiteSpace: 'nowrap', 
            mr: 1 
        },
        textArea: { 
            cursor: 'pointer' 
        }
    };
    return styles;
};