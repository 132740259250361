import toast from 'react-hot-toast';
import { requester } from '../../../libs/requester/requester-axios';
import { zooluxAPI } from '../../zooluxAPI';
import { IPtService } from '../repository/IPtService';

const ptService = zooluxAPI.injectEndpoints({
    endpoints: (builder) => ({
        getPtService: builder.query<IPtService[], void>({
            async queryFn(_, { dispatch }) {
                try {
                    const { data, isError, message }: any = await requester.get('/zoolux-api/pt-user-services');
                    if (isError) {
                        toast.error(message);
                        throw new Error(message);
                    };
                    return { data };
                } catch (e) {
                    throw e;
                }
            },
        }),
    }),
    overrideExisting: false,
});

export const { useGetPtServiceQuery } = ptService;