import { Dispatch, FC, SetStateAction } from "react";
import { usePaymentsForWeek } from "../presenters/usePaymentsForWeek";
import { PaymentsTable } from "./components/paymentTable";
import { Pagination, Stack } from "@mui/material";
import { IWeekFilters } from "../../paymentList/presenters/types";
import { EmptyList } from "../../../../ui-kit/emptyList";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";

interface IProps {
    paymentsWeekList: any[];
    paymentsCount: number;
    weekFilters: IWeekFilters;
    setWeekFilters: Dispatch<SetStateAction<IWeekFilters>>;
};

export const PaymentsForWeek: FC<IProps> = ({ weekFilters, paymentsWeekList, setWeekFilters, paymentsCount }) => {
    const { t } = useTranslation();
    const { headTitles, handleClick, handleRequestSort, selected, currentPage, paymentsAmount, handlePageChange } = usePaymentsForWeek(weekFilters, setWeekFilters, paymentsCount);

    return (
        <Stack>
            {paymentsWeekList?.length ?
                <Stack>
                    <PaymentsTable
                        weekFilters={weekFilters}
                        selected={selected}
                        payments={paymentsWeekList}
                        headTitles={headTitles}
                        handleClick={handleClick}
                        handleRequestSort={handleRequestSort}
                    />
                    <Stack direction='row' justifyContent='center' mt={4}>
                        <Pagination
                            page={currentPage}
                            count={paymentsAmount || 1}
                            color="primary"
                            onChange={handlePageChange}
                        />
                    </Stack>
                </Stack>
                :
                <Stack mt={5}> 
                    <EmptyList
                        title={t(tokens.paymentWeek.emptyList)}
                        description={t(tokens.paymentWeek.emptyDesc)}
                    />
                </Stack>}
        </Stack>
    )
};