import { styled } from '@mui/material';

interface IStyles {
    isLoadingGetFixedAsset: boolean;
}

export const getStyles = ({ isLoadingGetFixedAsset }: IStyles) => ({
    mainWrapper: {
        minHeight: isLoadingGetFixedAsset ? "calc(100vh - 124px)" : "unset"
    },
    gridContainer: {
        pb: "40px",
        justifyContent: "center",
        mt: 0
    },
    gridItem: {
        rowGap: "26px",
        display: "flex",
        flexDirection: "column"
    },
    cardFormInputs: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        paddingX: 1,
        paddingY: 2.5,
        rowGap: "24px"
    },
    wrapperCardFormInputs: {
        flexDirection: "row",
        width: "100%",
        columnGap: "24px"
    },
    numberInput: {
        "input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button": {
            WebkitAppearance: "none",
            margin: 0
        },
    },
    secondVariantInput: {
        ".inputBase": {
            background: "unset"
        },
        "& .MuiOutlinedInput-root": {
            background: "white",
            borderRadius: "10px"
        }
    },
    countTextarea: {
        mt: .5,
        display: "flex",
        justifyContent: "end"
    },
    datePicker: {
        "& .MuiOutlinedInput-root": {
            background: "white",
            borderRadius: "10px"
        }
    },

});

export const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
