export const tokens = {
  nav: {},
  common: {
    list: "common.list",
    create: "common.create",
    edit: "common.edit",
    detail: "common.detail",
    send: "common.send",
    cancel: "common.cancel",
    action: "common.action",
    delete: "common.delete",
    active: "common.active",
    archive: "common.archive",
    copy: "common.copy",
    emptyList: "common.emptyList",
    yes: "common.yes",
    no: "common.no"
  },
  tables: {},
  forms: {},
  auth: {
    phone: "auth.phone",
    password: "auth.password",
    confirmPass: "auth.confirmPass",
    enter: "auth.enter",
    logout: "auth.logout",
    createAndEnter: "auth.createAndEnter",
    forgotPass: "auth.forgotPass",
    checkPhone: "auth.checkPhone",
    recoverPhone: "auth.recoverPhone",
    confirmCode: "auth.confirmCode",
    resend: "auth.resend",
    smsPassword: "auth.smsPassword",
    validation: {
      phoneValid: "auth.validation.phoneValid",
      passValid: "auth.validation.passValid",
      matchPass: "auth.validation.matchPass"
    },
    messages: {
      createSafePass: "auth.messages.createSafePass",
      confirmYourPass: "auth.messages.confirmYourPass",
      enterSms: "auth.messages.enterSms"
    }
  },
  payment: {
    calendar: "payment.calendar",
    payments: "payment.payments",
    monthView: "payment.monthView",
    weekView: "payment.weekView",
    dayView: "payment.dayView",
    uploadFile: "payment.uploadFile",
    listView: "payment.listView",
    paymentForm: "payment.paymentForm",
    emptyPageDesc: "payment.emptyPageDesc",
    confirmed: "payment.confirmed",
    cash: "payment.cash",
    file: "payment.file",
    proofFile: "payment.proofFile",
    maxSize4: "payment.maxSize4",
    maxSize25: "payment.maxSize25",
    'non-сash': "payment.non-cash",
    urPartnerName: "payment.urPartnerName",
    report: "payment.report",
    card: "payment.card",
    visaPayment: "payment.visaPayment",
    updateModalMessage: "payment.updateModalMessage",
    unVisaPayment: "payment.unVisaPayment",
    postpayment: "payment.postpayment",
    prepayment: "payment.prepayment",
    partialPayment: "payment.partialPayment",
    createPayment: "payment.createPayment",
    createPaymentButton: "payment.createPaymentButton",
    editPayment: "payment.editPayment",
    paymentType: "payment.paymentType",
    paymentKind: "payment.paymentKind",
    partner: "payment.partner",
    expenseItem: "payment.expenseItem",
    accountBusiness: "payment.accountBusiness",
    account: "payment.account",
    fromAccount: "payment.fromAccount",
    comment: "payment.comment",
    serviceComment: "payment.serviceComment",
    prepaymentComment: "payment.prepaymentComment",
    processPayment: "payment.processPayment",
    clinic: "payment.clinic",
    fullName: "payment.fullName",
    cardBank: "payment.cardBank",
    sender: "payment.sender",
    receiverAccount: "payment.receiverAccount",
    bank: "payment.bank",
    infoFileInModal: "payment.infoFileInModal",
    getBack: "payment.getBack",
    edrpou: "payment.edrpou",
    mfo: "payment.mfo",
    paymentMethod: "payment.paymentMethod",
    sum: "payment.sum",
    pdv: "payment.pdv",
    files: "payment.files",
    paymentDate: "payment.paymentDate",
    preparePayment: "payment.preparePayment",
    copied: "payment.copied",
    addCard: "payment.addCard",
    addCardOrAccount: "payment.addCardOrAccount",
    addCardInfo: "payment.addCardInfo",
    add: "payment.add",
    fileWasNotAdded: "payment.fileWasNotAdded",
    receiver: "payment.receiver",
    initiator: "payment.initiator",
    financier: "payment.financier",
    operacionist: "payment.operacionist",
    withPdv: "payment.withPdv",
    importantMessage: "payment.importantMessage",
    withoutPdv: "payment.withoutPdv",
    codeCheck: "payment.codeCheck",
    zooluxCard: "payment.zooluxCard",
    created: "payment.created",
    visaed: "payment.visaed",
    processed: "payment.processed",
    completed: "payment.completed",
    transmitted: "payment.transmitted",
    payOnDay: "payment.payOnDay",
    placingApp: "payment.placingApp",
    visaedPayment: "payment.visaedPayment",
    paymentProcessed: "payment.paymentProcessed",
    codeUr: "payment.codeUr",
    all: "payment.all",
    expectedDate: "payment.expectedDate",
    responsible: "payment.responsible",
    getProductOnDate: "payment.getProductOnDate",
    receiveProduct: "payment.receiveProduct",
    responsibleAndDate: "payment.responsibleAndDate",
    productReceiverAndDate: "payment.productReceiverAndDate",
    budget: "payment.budget",
    deletePayment: "payment.deletePayment",
    transferToPayment: "payment.transferToPayment",
    deleteRegular: "payment.deleteRegular",
    allPayments: "payment.allPayments",
    thisPayment: "payment.thisPayment",
    regularPayment: "payment.regularPayment",
    paymentProof: "payment.paymentProof",
    months: "payment.months",
    weeks: "payment.weeks",
    paymentsAmount: "payment.paymentsAmount",
    period: "payment.period",
    status: "payment.status",
    noFilters: "payment.noFilters",
    deleted: "payment.deleted",
    parametrs: "payment.parametrs",
    reset: "payment.reset",
    filters: "payment.filters",
    isOperated: "payment.isOperated",
    isCreated: "payment.isCreated",
    isVisaed: "payment.isVisaed",
    paymentsSearch: "payment.paymentsSearch",
    prepaymentDate: "payment.prepaymentDate",
    imported: "payment.imported",
    hidden: "payment.hidden",
    operated: "payment.operated",
    paymentVisaed: "payment.paymentVisaed",
    paymentCreated: "payment.paymentCreated",
    desiredFinancier: "payment.desiredFinancier",
    receipt: {
      productReceive: "payment.receipt.productReceive",
      gettingProductDate: "payment.receipt.gettingProductDate",
      responsible: "payment.receipt.responsible",
      operationDate: "payment.receipt.operationDate",
      operationist: "payment.receipt.operationist",
      visaDate: "payment.receipt.visaDate",
      financier: "payment.receipt.financier",
      createDate: "payment.receipt.createDate",
      initiator: "payment.receipt.initiator",
      sum: "payment.receipt.sum",
      files: "payment.receipt.files",
      mfo: "payment.receipt.mfo",
      bank: "payment.receipt.bank",
      receiverAccount: "payment.receipt.receiverAccount",
      partner: "payment.receipt.partner",
      fopZoolux: "payment.receipt.fopZoolux",
      sender: "payment.receipt.sender",
      expenseItem: "payment.receipt.expenseItem",
      paymentType: "payment.receipt.paymentType",
      paymentKind: "payment.receipt.paymentKind",
      paymentMethod: "payment.receipt.paymentMethod",
      paymentNum: "payment.receipt.paymentNum",
    },
    serviceName: "payment.serviceName",
    serviceCopletionDate: "payment.serviceCopletionDate",
    notToPutInEnot: "payment.notToPutInEnot",
    processService: "payment.processService",
    uploadCheck: "payment.uploadCheck",
    uploadBill: "payment.uploadBill",
    emptyList: "payment.emptyList",
    emptyListDesc: "payment.emptyListDesc",
    performed: "payment.performed"
  },
  user: {
    users: "user.users",
    userNumber: "user.userNumber",
    shortName: "user.shortName",
    phone: "user.phone",
    position: "user.position",
    userData: "user.userData",
    userRole: "user.userRole",
    roleDesc: "user.roleDesc",
    role: "user.role",
    userStatus: "user.userStatus",
    statusDesc: "user.statusDesc",
    status: "user.status",
    working: "user.working",
    fired: "user.fired",
    changeData: "user.changeData",
    updateUsers: "user.updateUsers",
    createUser: "user.createUser",
    roles: "user.roles",
    rolesList: "user.rolesList",
    title: "user.title",
    permissions: "user.permissions",
    createRole: "user.createRole",
    editRole: "user.editRole",
    messages: {
      roleCreated: "user.messages.roleCreated",
      roleRemoved: "user.messages.roleRemoved",
      roleUpdated: "user.messages.roleUpdated",
      changedAccount: "user.messages.changedAccount"
    }
  },
  partner: {
    partner: "partner.partner",
    partners: "partner.partners",
    createPartner: "partner.createPartner",
    editPartner: "partner.editPartner",
    name: "partner.name",
    folder: "partner.folder",
    shortName: "partner.shortName",
    edrpou: "partner.edrpou",
    desc: "partner.desc",
    descEnote: "partner.descEnote",
    specifyName: "partner.specifyName",
    specifyEdrpou: "partner.specifyEdrpou",
    details: "partner.details",
    notShortName: "partner.notShortName",
    notEdrpou: 'partner.notEdrpou',
    notDesc: 'partner.notDesc',
    accounts: 'partner.accounts',
    cards: 'partner.cards',
    updatePartner: "partner.updatePartner",
    urName: "partner.urName",
    status: "partner.status",
    bankName: "partner.bankName",
    actions: "partner.actions",
    fillAllFileds: "partner.fillAllFileds",
    message: {
      partnerCreated: "partner.message.partnerCreated",
      partnerUpdated: "partner.message.partnerUpdated",
    },
    errors: {
      uniqueEdrpou: "partner.errors.uniqueEdrpou"
    }
  },
  contracts: {
    contracts: "contracts.contracts",
    contractsList: "contracts.contractsList",
    file: "contracts.file",
    createContract: "contracts.createContract",
    type: "contracts.type",
    title: "contracts.title",
    edrpou: "contracts.edrpou",
    comment: "contracts.comment",
    startContract: "contracts.startContract",
    endContract: "contracts.endContract",
    maxSymbols: "contracts.maxSymbols",
    maxSizeFile: "contracts.maxSizeFile",
    fillAllNeededData: "contracts.fillAllNeededData",
    actions: "contracts.actions",
    editContract: "contracts.editContract",
    deleteContract: "contracts.deleteContract",
    searchContract: "contracts.searchContract",
    noData: "contracts.noData",
    listEmpty: "contracts.listEmpty",
    emptyListDesc: "contracts.emptyListDesc",
    contract: "contracts.contract",
    product: "contracts.product",
    service: "contracts.service",
    other: "contracts.other",
    message: {
      contractRemoved: "contracts.message.contractRemoved",
      contractCreated: "contracts.message.contractCreated",
      contractUpdated: "contracts.message.contractUpdated",
    }
  },
  paymentToolbar: {
    one: "paymentToolbar.one",
    two: "paymentToolbar.two",
    three: "paymentToolbar.three",
    four: "paymentToolbar.four",
    five: "paymentToolbar.five",
    six: "paymentToolbar.six",
    seven: "paymentToolbar.seven",
  },
  account: {
    accounts: "account.accounts",
    zooluxAccounts: "account.zooluxAccounts",
    searchAccount: "account.searchAccount",
    companyAccounts: "account.companyAccounts",
    editAccount: "account.editAccount",
    active: "account.active",
    hidden: "account.hidden",
    title: "account.title",
    accountType: "account.accountType",
    mainAccount: "account.mainAccount",
    titleRequired: "account.titleRequired",
    accountUpdated: "account.accountUpdated",
    updateAccounts: "account.updateAccounts",
    accountName: "account.accountName",
    name: "account.name",
    shortName: "account.shortName",
    organization: "account.organization",
    status: "account.status",
  },
  expenseItem: {
    expenseItem: "expenseItem.expenseItem",
    updateExpenseItem: "expenseItem.updateExpenseItem",
    active: "expenseItem.active",
    hidden: "expenseItem.hidden",
    searchExpenseItem: "expenseItem.searchExpenseItem",
  },
  permissions: {
    getAllBudgetPayments: "permissions.getAllBudgetPayments",
    getAllCashBudgetPayments: "permissions.getAllCashBudgetPayments",
    getAllCardBudgetPayments: "permissions.getAllCardBudgetPayments",
    getAllNonCashBudgetPayments: "permissions.getAllNonCashBudgetPayments",
    getAllBudgetPaymentsTypes: "permissions.getAllBudgetPaymentsTypes",
    updateBudgetPayment: "permissions.updateBudgetPayment",
    createClinic: "permissions.createClinic",
    getAllClinics: "permissions.getAllClinics",
    updateClinic: "permissions.updateClinic",
    createCompanyAccount: "permissions.createCompanyAccount",
    getCompanyAccount: "permissions.getCompanyAccount",
    updateCompanyAccount: "permissions.updateCompanyAccount",
    createCounterparty: "permissions.createCounterparty",
    getCounterparty: "permissions.getCounterparty",
    updateCounterparty: "permissions.updateCounterparty",
    getCounterpartiesInFolder: "permissions.getCounterpartiesInFolder",
    updateCounterpartiesInFolder: "permissions.updateCounterpartiesInFolder",
    createExpenseArticle: "permissions.createExpenseArticle",
    getExpenseArticle: "permissions.getExpenseArticle",
    updateExpenseArticle: "permissions.updateExpenseArticle",
    getLogging: "permissions.getLogging",
    signPayment: "permissions.signPayment",
    signLocalPayment: "permissions.signLocalPayment",
    removeSignature: "permissions.removeSignature",
    removeLocalSignature: "permissions.removeLocalSignature",
    payPayment: "permissions.payPayment",
    payLocalPayment: "permissions.payLocalPayment",
    payCashPayment: "permissions.payCashPayment",
    payCardPayment: "permissions.payCardPayment",
    payNonCashPayment: "permissions.payNonCashPayment",
    getAllPayments: "permissions.getAllPayments",
    getAllCashPayments: "permissions.getAllCashPayments",
    getAllCardPayments: "permissions.getAllCardPayments",
    getAllNonCashPayments: "permissions.getAllNonCashPayments",
    removePayment: "permissions.removePayment",
    removeLocalPayment: "permissions.removeLocalPayment",
    updatePayment: "permissions.updatePayment",
    convertBudgetPaymentToCalendar: "permissions.convertBudgetPaymentToCalendar",
    createPaymentType: "permissions.createPaymentType",
    updatePaymentType: "permissions.updatePaymentType",
    removePaymentType: "permissions.removePaymentType",
    createPaymentMethod: "permissions.createPaymentMethod",
    updatePaymentMethod: "permissions.updatePaymentMethod",
    removePaymentMethod: "permissions.removePaymentMethod",
    createPaymentKind: "permissions.createPaymentKind",
    updatePaymentKind: "permissions.updatePaymentKind",
    removePaymentKind: "permissions.removePaymentKind",
    changePrepaymentStatus: "permissions.changePrepaymentStatus",
    getUser: "permissions.getUser",
    getUsers: "permissions.getUsers",
    createRole: "permissions.createRole",
    getRoles: "permissions.getRoles",
    updateRole: "permissions.updateRole",
    removeRole: "permissions.removeRole",
    getAllPermissions: "permissions.getAllPermissions",
    addRoleAccessToUser: "permissions.addRoleAccessToUser",
    createRoleForUser: "permissions.createRoleForUser",
    revokeUserRole: "permissions.revokeUserRole",
    grantUserAccess: "permissions.grantUserAccess",
    revokeUserAccess: "permissions.revokeUserAccess",
    getAllCompanyAccounts: "permissions.getAllCompanyAccounts",
    getAllCounterparties: "permissions.getAllCounterparties",
    getAllExpenseArticles: "permissions.getAllExpenseArticles",
    getListDayPayment: "permissions.getListDayPayment",
    getListPrepayment: "permissions.getListPrepayment",
    createImportPayment: "permissions.createImportPayment",
    getAllImportPayment: "permissions.getAllImportPayment",
    getListOperacionistPayment: "permissions.getListOperacionistPayment",
    getListFinancierPayment: "permissions.getListFinancierPayment",
    writeServiceReceiving: "permissions.writeServiceReceiving",
    editTelegram: "permissions.editTelegram",
    readTelegram: "permissions.readTelegram",
    readServiceReceiving: "permissions.readServiceReceiving",
    completedBudgetCalendar: "permissions.completedBudgetCalendar",
    confirmBudgetCalendar: 'permissions.confirmBudgetCalendar',
    confirmPayment: 'permissions.confirmPayment',
    createInEnote: 'permissions.createInEnote',
    isEditNomenclature: 'permissions.isEditNomenclature',
    isReadNomenclature: 'permissions.isReadNomenclature',
    isReadConfigurations: 'permissions.isReadConfigurations',
  },
  importPayment: {
    unloadingInfo: "importPayment.unloadingInfo",
    importInEnot: "importPayment.importInEnot",
    importInCalendar: "importPayment.importInCalendar",
    addCsv: "importPayment.addCsv",
    upload: "importPayment.upload",
    close: "importPayment.close",
    status: "importPayment.status",
    title: "importPayment.title",
    paymentsCount: "importPayment.paymentsCount",
    type: "importPayment.type",
    uploader: "importPayment.uploader",
    uploadDate: "importPayment.uploadDate",
    uploadInEnot: "importPayment.uploadInEnot",
    uploadInCalendar: "importPayment.uploadInCalendar",
    payments: "importPayment.payments",
    sum: "importPayment.sum",
    importPayments: "importPayment.importPayments",
    emptyListDesc: "importPayment.emptyListDesc",
    noData: "importPayment.noData",
    messages: {
      paymentsUploadEnot: "importPayment.messages.paymentsUploadEnot",
      paymentsUploadCalendar: "importPayment.messages.paymentsUploadCalendar",
    }
  },
  paymentsList: {
    account: "paymentsList.account",
    partner: "paymentsList.partner",
    sum: "paymentsList.sum",
    date: "paymentsList.date",
    initiator: "paymentsList.initiator",
    method: "paymentsList.method",
    balance: "paymentsList.balance",
    status: "paymentsList.status",
    noAccount: "paymentsList.noAccount",
    noBalance: "paymentsList.noBalance"
  },
  prepayment: {
    prepayments: "prepayment.prepayments",
    dateNotAssign: "prepayment.dateNotAssign",
    emptyListDesc: "prepayment.emptyListDesc",
  },
  service: {
    service: "service.service",
    serviceList: "service.serviceList",
    listEmpty: "service.listEmpty",
    emptyListDesc: "service.emptyListDesc",
    title: "service.title",
    nomenclature: "service.nomenclature",
    nomenclatureSeria: "service.nomenclatureSeria",
    units: "service.units",
    byDefault: "service.byDefault",
    searchByTitle: "service.searchByTitle",
    noData: "service.noData",
    createService: "service.createService",
    deleteService: "service.deleteService",
    editService: "service.editService",
    fillAllData: "service.fillAllData",
    relatedService: "service.relatedService",
    deleteRelatedService: "service.deleteRelatedService",
    addRelatedService: "service.addRelatedService",
    comment: "service.comment",
    commentForService: "service.commentForService",
    serviceProccessed: "service.serviceProccessed",
    message: {
      serviceCreated: "service.message.serviceCreated",
      serviceUpdated: "service.message.serviceUpdated",
      serviceDeleted: "service.message.serviceDeleted",
      serviceByDefaultChanged: "service.message.serviceByDefaultChanged",
      capitalizeService: "service.message.capitalizeService"
    }
  },
  telegram: {
    telegramBot: "telegram.telegramBot",
    listEmpty: "telegram.listEmpty",
    emptyDesc: "telegram.emptyDesc",
    searchUsersByNickname: "telegram.searchUsersByNickname",
    id: "telegram.id",
    name: "telegram.name",
    nickNameId: "telegram.nickNameId",
    nickName: "telegram.nickName",
    phone: "telegram.phone",
    goToUser: "telegram.goToUser",
    deleteUser: "telegram.deleteUser",
    noData: "telegram.noData",
    restoreUser: "telegram.restoreUser",
    secretCode: "telegram.secretCode",
    changeCode: "telegram.changeCode",
    active: "telegram.active",
    deleted: "telegram.deleted",
    codeCopied: "telegram.codeCopied",
    message: {
      userRemoved: "telegram.message.userRemoved",
      userRestored: "telegram.message.userRestored",
      codeChanged: "telegram.message.codeChanged",
    }
  },
  verifiedPayments: {
    verifiedPayments: "verifiedPayments.verifiedPayments",
    verifiedPaymentsList: "verifiedPayments.verifiedPaymentsList",
    listEmpty: "verifiedPayments.listEmpty",
    emptyListDesc: "verifiedPayments.emptyListDesc",
    title: "verifiedPayments.title",
    companyAccount: "verifiedPayments.companyAccount",
    partner: "verifiedPayments.partner",
    paymentMethod: "verifiedPayments.paymentMethod",
    date: "verifiedPayments.date"
  },
  serviceHistory: {
    serviceHistory: "serviceHistory.serviceHistory",
    createService: "serviceHistory.createService",
    title: "serviceHistory.title",
    comment: "serviceHistory.comment",
    date: "serviceHistory.date",
    status: "serviceHistory.status",
    listEmpty: "serviceHistory.listEmpty",
    emptyListDesc: "serviceHistory.emptyListDesc",
    searchFor: "serviceHistory.searchFor",
    createdDate: "serviceHistory.createdDate",
    partner: "serviceHistory.partner",
    sum: "serviceHistory.sum",
    processDate: "serviceHistory.processDate",
    addPaymentToService: "serviceHistory.addPaymentToService",
    cancelPaymentService: "serviceHistory.cancelPaymentService"
  },
  nomenclature: {
    nomenclature: "nomenclature.nomenclature",
    emptyList: "nomenclature.emptyList",
    emptyDesc: "nomenclature.emptyDesc",
    nomenclatureList: "nomenclature.nomenclatureList",
    code: "nomenclature.code",
    description: "nomenclature.description",
    articul: "nomenclature.articul",
    nomenclatureType: "nomenclature.nomenclatureType",
    makeService: "nomenclature.makeService",
    nomenclatureSearch: "nomenclature.nomenclatureSearch",
    message: {
      nomenclatureEdited: "nomenclature.message.nomenclatureEdited"
    }
  },
  location: {
    title: "location.title",
    searchInputPlaceholder: "location.searchInputPlaceholder"
  },
  paymentWeek: {
    emptyList: "paymentWeek.emptyList",
    emptyDesc: "paymentWeek.emptyDesc",
    paymentDate: "paymentWeek.paymentDate",
    partner: "paymentWeek.partner",
    amount: "paymentWeek.amount",
    paymentMethod: "paymentWeek.paymentMethod",
    account: "paymentWeek.account",
    initiator: "paymentWeek.initiator",
    operationist: "paymentWeek.operationist",
    comment: "paymentWeek.comment"
  },
  configuration: {
    configuration: "configuration.configuration",
    key: "configuration.key",
    meaning: "configuration.meaning"
  },
  fixedAsset: {
    title: "fixedAsset.title",
    actionBtn: "fixedAsset.actionBtn",
    searchInputPlaceholder: "fixedAsset.searchInputPlaceholder",
    emptyStateMessage: "fixedAsset.emptyStateMessage"
  }
};
