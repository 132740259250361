export const getStyles = (theme: any, isNavBarHiden: boolean) => {
    const styles = {
        verticalLayoutRoot: {
            display: 'flex',
            flex: '1 1 auto',
            maxWidth: '100%',
            px: '20px',
            [theme.breakpoints.up('lg')]: {
                paddingLeft: isNavBarHiden ? '0px' : '315px',
            }
        },
        verticalLayoutContainer: {
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%',
        }
    };
    return styles;
};