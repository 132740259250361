export const getStyles = () => {
    const styles = {
        createBtn: { 
            borderRadius: '5px', 
            color: '#FFFFFF',
            textTransform: 'none' 
        },
        progress: {
            position: 'relative', 
            top: '20px', 
            height: '300px'
        }
    };
    return styles;
};