import { SagaIterator } from "redux-saga";
import { put, call, takeEvery } from "redux-saga/effects";
import { requester } from "../../../../libs/requester/requester-axios";
import { IResponse } from "../../../../libs/requester/IResponse";
import { setLoading, setDeleteServiceError } from "../../repository/slice";

function* deleteServiceWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, isError, message }: IResponse<any> = yield call(requester.delete, "/zoolux-api/admin/service-receiving/" + payload.id);
        if (isError) {
            yield put(setDeleteServiceError(message));
        } else {
            yield put(setDeleteServiceError(null));
        }
    } catch (error) {
        console.error("deleteServiceWorker: ", error);
    } finally {
        yield put(setLoading(false));
    }
};

export function* deleteServiceWatcher() {
    yield takeEvery("@saga/DELETE_SERVICE", deleteServiceWorker);
};