import { ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectContract } from "../../../../store/contract/repository/selector";
import { getContractsList } from "../../../../store/contract/useCases/getContractsList/action";
import { deleteContract } from "../../../../store/contract/useCases/deleteContract/action";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { useDebounce } from "../../../../hooks/useDebounce";

export const useContractList = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [contractsAmount, setContractsAmount] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [deleteClick, setDeleteClick] = useState(false);
    const { contracts, contractsCount, isLoading, deleteContractError, partnerName } = useSelector(selectContract);
    const [filters, setFilters] = useState<{ page: number, rowsPerPage: number, search: string }>({ page: 1, rowsPerPage: 15, search: '' });

    useEffect(() => {
        dispatch(getContractsList({ counterparty_id: Number(id), page: filters.page, perPage: filters.rowsPerPage, search: filters.search }));
    }, [filters]);

    useEffect(() => {
        if (contractsCount) {
            setContractsAmount(Math.ceil(contractsCount / filters.rowsPerPage));
        }
    }, [contractsCount]);

    useEffect(() => {
        if (!isLoading && deleteClick) {
            if (deleteContractError) {
                toast.error(deleteContractError);
            } else {
                toast.success(t(tokens.contracts.message.contractRemoved));
                dispatch(getContractsList({ counterparty_id: Number(id), page: filters.page, perPage: filters.rowsPerPage, search: filters.search }));
            }
            setDeleteClick(false);
        }
    }, [deleteContractError, isLoading]);
    
    useEffect(() => {
        debouncedHandleContractSeacrh(searchText);
    }, [searchText]);

    const handleSearchContractText = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const onSearchContract = (value: string) => {
        const search = value ? value : '';
        setFilters((prevState) => ({ ...prevState, page: 1, search }));
    };

    const { debouncedWrapper: debouncedHandleContractSeacrh } = useDebounce(onSearchContract, 1000);
    
    const handlePageChange = useCallback((event: ChangeEvent<unknown>, page: number): void => {
        setFilters((prevState) => ({ ...prevState, page }));
    }, [setFilters]);

    const handleDeleteContract = (id: number) => {
        dispatch(deleteContract({ id }));
        setDeleteClick(true);
    };

    return { contracts, filters, contractsAmount, handlePageChange, handleDeleteContract, handleSearchContractText, searchText, id, partnerName }
};