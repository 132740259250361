import { TextField } from '@mui/material';
import { DatePicker as DatePickerMui, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import { FC, forwardRef } from 'react';
import { ReactComponent as CalendarIcon } from './../assets/icons/calendar-icon.svg';
import { Control, Controller, FieldError } from 'react-hook-form';

dayjs.locale('uk');

export const DatePicker = forwardRef(({ control, name, sx }: { control: Control<any, any>; name: string; sx: any; }, ref: any) => {
    return (
        <>
            <Controller
                name={name}
                control={control}
                defaultValue={new Date()}
                render={({ field: { onChange, value }, formState: { errors } }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'uk'}>
                        <DatePickerMui
                            ref={ref}

                            className="inputBase"
                            components={{
                                OpenPickerIcon: CalendarIcon
                            }}
                            renderInput={(props: any) =>
                                <TextField
                                    {...props}
                                    sx={sx}
                                    autoComplete={undefined}
                                    inputProps={{
                                        ...props.inputProps,
                                        placeholder: "00.00.0000"
                                    }}
                                    error={!!errors[name]}
                                    helperText={(errors[name] as FieldError | undefined)?.message || ''}
                                />
                            }
                            value={value}
                            onChange={(e) => onChange(e)}
                        />
                    </LocalizationProvider>
                )}
            />
        </>
    );
});