import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent} from "@mui/material";

interface IProps {
    role: string[];
    roles: any[];
    handleChangeRole: (event: SelectChangeEvent<string[]>, child: ReactNode) => void;
};

export const RoleSelect: FC<IProps> = ({ role, roles, handleChangeRole}) => {
    const { t } = useTranslation();
    
    return (
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-role">{t(tokens.user.role)}</InputLabel>
            <Select
                fullWidth
                onChange={handleChangeRole}
                value={role}
                multiple
                renderValue={(selected) => selected.join(', ')}
                id="demo-simple-select-role"
                label={t(tokens.user.role)}
            >
                {roles?.map((userRole: any, index) => (
                    <MenuItem value={userRole.name} key={userRole.id}>
                        <Checkbox checked={role.includes(userRole.name)} />
                        <ListItemText primary={userRole.name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
};