import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import toast from "react-hot-toast";
import { addPermissionsToRole } from "../addPermissionsToRole/action";

function* createRoleWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.post, "/zoolux-api/roles", payload.body);
        if (isError) {
            toast.error(message);
        } else {
            yield put(addPermissionsToRole({ role_id: data.id, permission_ids: payload.permission }));
        }
    } catch (error) {
        console.error("createRoleWorker: ", error);
    } finally {

    };
};

export function* createRoleWatcher() {
    yield takeLatest("@saga/CREATE_ROLE", createRoleWorker);
};
