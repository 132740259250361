import { Autocomplete, Stack, TextField, Theme, Typography, useMediaQuery } from "@mui/material";
import { FC } from "react";
import { ICardOption, collectCardList } from "../../../utils/collectOptionList";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";
import { useCardAutocomplete } from "../presenter/useCardAutocomplete";

interface IProps {
    value: ICardOption | null;
    handleChangeValue: any;
    isValid: boolean;
    clinicId?: number;
    accountType?: string;
    isDisabled: boolean;
};

export const CardAutocomplete: FC<IProps> = ({ value, handleChangeValue, isValid, accountType, clinicId, isDisabled }) => {
    const { t } = useTranslation();
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const { accountList, changeCompanyAccountText } = useCardAutocomplete();

    return (
        <Stack direction='column' alignItems='flex-start' spacing={1} width={'100%'}>
            <Typography color={isValid ? 'primary' : 'error'} variant={downSm ? 'body1' : 'h6'}>{t(tokens.payment.account)}</Typography>
            <Autocomplete
                disabled={(clinicId == undefined && accountType === 'cash') || isDisabled}
                disablePortal
                onChange={(e, value) => handleChangeValue(value)}
                value={value}
                options={collectCardList(accountList) as ICardOption[]}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(params, option): JSX.Element => (
                    <Typography {...params} key={option.id}>
                        {option.name}
                    </Typography>
                )}
                renderInput={(params): JSX.Element => (
                    <TextField
                        {...params}
                        fullWidth
                        error={!isValid}
                        onChange={changeCompanyAccountText}
                        name={t(tokens.payment.account)}
                        variant="outlined"
                        label={t(tokens.payment.account)}
                    />
                )}
                clearIcon={false}
                sx={{ width: '100%' }}
                size="small"
            />
        </Stack>
    )
};