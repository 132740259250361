export const getStyles = () => {
    const styles = {
        container: { 
            borderColor: 'neutral.300', 
            borderRadius: '50%', 
            p: '4px', 
            border: '2px solid black' 
        },
        avatarWrapper: { 
            borderRadius: '50%', 
            height: '100%', 
            width: '100%', 
            position: 'relative' 
        },
        selectBtn: {
            alignItems: 'center',
            borderRadius: '50%',
            color: 'common.white',
            cursor: 'pointer',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            left: 0,
            opacity: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
            zIndex: 1,
            '&:hover': {
                opacity: 1,
                backgroundColor: 'grayText'
            }
        },
        changeBtn: { 
            color: '#FFFFFF' 
        },
        avatar: { 
            height: 90, width: 90 
        }

    };
    return styles;
}