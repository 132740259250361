import { Autocomplete, Stack, TextField, Theme, Typography, useMediaQuery } from "@mui/material";
import { FC } from "react"
import { collectOptionList } from "../../../utils/collectOptionList";
import { useServiceAutocomplete } from "../presenter/useServiceAutocomplete";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";

interface IProps {
    isDisabled?: boolean;
    value: any;
    isValid: boolean;
    handleChangeValue: (value: any) => void;
};

export const ServiceAutocomplete: FC<IProps> = ({ isValid, value, handleChangeValue, isDisabled }) => {
    const { t } = useTranslation();
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const { serviceList, changeServiceNameText } = useServiceAutocomplete();
    const options = collectOptionList(serviceList);

    return (
        <Stack direction='column' alignItems='flex-start' spacing={1} width={'100%'}>
            <Typography color={isValid ? 'primary' : 'error'} variant={downSm ? 'body1' : 'h6'}>{t(tokens.payment.serviceName)}</Typography>
            <Autocomplete
                disablePortal
                onChange={(e, value) => handleChangeValue(value)}
                value={value}
                options={options}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(params, option): JSX.Element => (
                    <Typography {...params} key={option.id}>
                        {option.name}
                    </Typography>
                )}
                renderInput={(params): JSX.Element => (
                    <TextField
                        {...params}
                        fullWidth
                        error={!isValid}
                        name={t(tokens.payment.serviceName)}
                        variant="outlined"
                        label={t(tokens.payment.serviceName)}
                        onChange={changeServiceNameText}
                        size="small"
                    />
                )}
                clearIcon={false}
                sx={{ width: '100%' }}
                disabled={isDisabled}
            />
        </Stack>
    )
};