import { SagaIterator } from "redux-saga";
import { put, call, takeEvery } from "redux-saga/effects";
import { requester } from "../../../../libs/requester/requester-axios";
import { IResponse } from "../../../../libs/requester/IResponse";
import { setDeleteUserError, setLoading } from "../../repository/slice";

function* deleteUserFromTelegramWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, isError, message }: IResponse<any> = yield call(requester.post, "/zoolux-api/telegram-bot/delete-user/" + payload.phone);
        if (isError) {
            yield put(setDeleteUserError(message));
        } else {
            yield put(setDeleteUserError(null));
        }
    } catch (error) {
        console.error("deleteUserFromTelegramWorker: ", error);
    } finally {
        yield put(setLoading(false));
    }
};

export function* deleteUserFromTelegramWatcher() {
    yield takeEvery("@saga/DELETE_USER_FROM_TELEGRAM", deleteUserFromTelegramWorker);
};