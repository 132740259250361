import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import { FC } from "react"

interface IProps {
    title: string;
    label: string;
    id: string;
    value: any;
    required: boolean;
    options: any[];
    isValid: boolean;
    onChangeValue: (event: SelectChangeEvent) => void;
};

export const FieldSelector: FC<IProps> = ({ title, id, value, onChangeValue, required, options, label, isValid }) => {
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    return (
        <Stack alignItems='flex-start' spacing={1} width='100%'>
            <Typography color={isValid ? 'primary' : 'error'} variant={downSm ? 'body1' : 'h6'}>{title}</Typography>
            <FormControl fullWidth required={required} size="small">
                <InputLabel id={id} color={isValid ? 'primary' : 'error'}>{title}</InputLabel>
                <Select
                    labelId={id}
                    id={id}
                    value={value}
                    label={label}
                    onChange={onChangeValue}
                    error={!isValid}
                >
                    {options.map(option =>
                        <MenuItem value={option.id} key={option.id} placeholder={label}>{option.name}</MenuItem>
                    )}
                </Select>
            </FormControl>
        </Stack>
    )
};