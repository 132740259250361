import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setVerify } from "../../repository/actions";
import toast from "react-hot-toast";

function* recoverPasswordWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.post, "/zoolux-api/user-auth/reset-password", payload);
        if (isError) {
            toast.error("користувач не знайдений")
        } else {
            if (data === 'code_sent') {
                yield put(setVerify({ isPhoneConfirmed: false }));
            } else {
                yield put(setVerify({ isPhoneConfirmed: true }));
            }
        }
    } catch (error) {
        console.error("recoverPasswordWorker: ", error);
    } finally {

    };
};

export function* recoverPasswordWatcher() {
    yield takeLatest("@saga/RECOVER_PASSWORD", recoverPasswordWorker);
};