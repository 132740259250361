import { useDispatch, useSelector } from "react-redux";
import { selectService } from "../../../store/service/repository/selector";
import { ChangeEvent } from "react";
import { useDebounce } from "../../../hooks/useDebounce";
import { getServiceList } from "../../../store/service/useCases/getServiceList/action";

export const useServiceAutocomplete = () => {
    const dispatch = useDispatch();
    const { serviceList } = useSelector(selectService);

    const changeServiceNameText = (event: ChangeEvent<HTMLInputElement>) => {
        debouncedHandleServiceNameSeacrh(event.target.value);
    };
    
    const onSearchServiceName = (value: string) => {
        if (value.length) {
            dispatch(getServiceList({ page: 1, perPage: 25, search: value }));
        } else {
            dispatch(getServiceList({ page: 1, perPage: 25, search: '' }));
        }
    };

    const { debouncedWrapper: debouncedHandleServiceNameSeacrh } = useDebounce(onSearchServiceName, 1000);

    return { serviceList, changeServiceNameText }
};