import { SagaIterator } from "redux-saga";
import { call, takeEvery } from "redux-saga/effects";
import { requester } from "../../../../libs/requester/requester-axios";
import { IResponse } from "../../../../libs/requester/IResponse";
import toast from "react-hot-toast";

function* deleteGroupPaymentsWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.delete, "/zoolux-api/budget-payments/budget-payment-regular/" + payload.id);
        if (isError) {
            toast.error(message);
        } else {
            
        }
    } catch (error) {
        console.error("deleteGroupPaymentsWorker: ", error);
    } finally {

    }
};

export function* deleteGroupPaymentsWatcher() {
    yield takeEvery("@saga/DELETE_GROUP_PAYMENTS", deleteGroupPaymentsWorker);
};