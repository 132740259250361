import { Box, Chip, Stack, Theme, useMediaQuery } from "@mui/material";
import { FC, useMemo } from "react";
import { getStyles } from "./styles";

interface IProps {
    chips: any[];
};

export const ChipsList: FC<IProps> = ({ chips }) => {
    const styles = useMemo(() => getStyles(), []);
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm')); 

    return (
        <Stack alignItems="center" direction="row" flexWrap="wrap" gap={1}>
            {chips.map((chip, index) => (
                <Chip
                    key={index}
                    label={<Box sx={styles.chipLabel}><span>{chip.label}</span>: {chip.displayValue || chip.value}</Box>}
                    sx={styles.chip}
                    size={downSm ? 'small' : 'medium'}
                    variant="outlined"
                    onDelete={undefined}
                />
            ))}
        </Stack>
    )
}