import { Autocomplete, Stack, TextField, Theme, Typography, useMediaQuery } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";
import { IPartnerOption, collectPartnerList } from "../../../utils/collectOptionList";
import { PartnerCreateModal } from "../../../modules/payment-calendar/payment-create/ui/components/partnerCreateModal";
import { usePartnerServiceAutocomplete } from "../presenter/usePartnerServiceAutocomplete";

interface IProps {
    index?: number;
    isDisabled?: boolean;
    label: string;
    value: IPartnerOption | null;
    isValid: boolean;
    onPartnerChange: (value: any) => void;
    changeText: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const PartnerServiceAutocomplete: FC<IProps> = ({ index, label, value, onPartnerChange, isValid, changeText, isDisabled }) => {
    const { t } = useTranslation();
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const { dialogValue, handleClose, handleOnChange, filterPartnersOptions, isOpen, servicePartners, setDialogValue } = usePartnerServiceAutocomplete(onPartnerChange, index); 

    return (
        <Stack direction='column' alignItems='flex-start' spacing={1} width='100%'>
            <Typography color={isValid ? 'primary' : 'error'} variant={downSm ? 'body1' : 'h6'}>
                {t(tokens.payment.partner)}
            </Typography>
            <Autocomplete
                disablePortal
                onChange={(e, value) => handleOnChange(e, value)}
                value={value}
                options={collectPartnerList(servicePartners) as IPartnerOption[]}
                filterOptions={filterPartnersOptions}
                getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                        return option;
                    }
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option.name;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(props, option) =>
                    <li {...props} key={option.name + option.id}>{option.name}</li>
                }
                freeSolo
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label={label}
                        error={!isValid}
                        onChange={changeText}
                        size="small"
                    />
                }
                clearIcon={false}
                sx={{ width: '100%' }}
                disabled={isDisabled}
            />
            <PartnerCreateModal
                partnerName={dialogValue.name}
                isOpen={isOpen}
                handleToggle={handleClose}
                setDialogValue={setDialogValue}
            />
        </Stack>
    )
};