import { TableBody } from "@mui/material";
import { FC } from "react";
import { ServiceHistoryItem } from "../serviceHistoryItem";

interface IProps {
    serviceList: any[];
};

export const ServiceHistoryTableBody: FC<IProps> = ({ serviceList }) => {

    return (
        <TableBody>
            {serviceList.map((service: any) =>
                <ServiceHistoryItem
                    key={service.id}
                    service={service}
                />
            )}
        </TableBody>
    )
};