import { Box, Theme, useMediaQuery } from "@mui/material";
import { FC } from "react";
import { getStyles } from "./styles";
import Lottie from "lottie-react";
import loader from './loader.json';

export const Loader: FC = () => {
    const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const styles = getStyles(downMd);

    return (
        <Box sx={styles.backgraund}>
            <Box sx={styles.loader}>
                <Lottie animationData={loader} loop={true} />
            </Box>
        </Box>
    )
}