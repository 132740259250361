import { TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";

export const ImportsTableHead: FC = () => {
    const { t } = useTranslation();

    return (
        <TableHead>
            <TableRow>
                <TableCell align="center">
                </TableCell>
                <TableCell align="center">
                    <Typography fontWeight={600}>{t(tokens.importPayment.status)}</Typography>
                </TableCell>
                <TableCell align="center">
                    <Typography fontWeight={600}>{t(tokens.importPayment.title)}</Typography>
                </TableCell>
                <TableCell align="center">
                    <Typography fontWeight={600}>{t(tokens.importPayment.paymentsCount)}</Typography>
                </TableCell>
                <TableCell align="center">
                    <Typography fontWeight={600}>{t(tokens.importPayment.type)}</Typography>
                </TableCell>
                <TableCell align="center">
                    <Typography fontWeight={600}>{t(tokens.importPayment.uploader)}</Typography>
                </TableCell>
                <TableCell align="center">
                    <Typography fontWeight={600}>{t(tokens.importPayment.uploadDate)}</Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    )
};