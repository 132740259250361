import { Autocomplete, Stack, TextField, Theme, Typography, useMediaQuery } from "@mui/material";
import { FC } from "react";
import { IClinicOption, collectClinicList } from "../../../utils/collectOptionList";
import { useClinicAutocomplete } from "../presenter/useClinicAutocomplete";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";

interface IProps {
    value: IClinicOption | null;
    handleChangeValue: any;
    isValid: boolean;
    isDisabled: boolean;
};

export const ClinicAutocomplete: FC<IProps> = ({ value, handleChangeValue, isValid, isDisabled }) => {
    const { t } = useTranslation();
    const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const { clinics, filterClinics } = useClinicAutocomplete();

    return (
        <Stack direction='column' alignItems='flex-start' spacing={1} width={'100%'}>
            <Typography color={isValid ? 'primary' : 'error'} variant={downMd ? 'body1' : 'h6'}>{t(tokens.payment.clinic)}</Typography>
            <Autocomplete
                disablePortal
                onChange={(e, value) => handleChangeValue(value)}
                value={value}
                options={collectClinicList(clinics) as IClinicOption[]}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(params, option): JSX.Element => (
                    <Typography {...params} key={option.id}>
                        {option.name}
                    </Typography>
                )}
                filterOptions={filterClinics}
                renderInput={(params): JSX.Element => (
                    <TextField
                        {...params}
                        fullWidth
                        error={!isValid}
                        hiddenLabel
                        name={t(tokens.payment.clinic)}
                        variant="outlined"
                        placeholder={t(tokens.payment.clinic)}
                    />
                )}
                sx={{ width: '100%' }}
                size="small"
                disabled={isDisabled}
            />
        </Stack>
    )
};