import { ChangeEvent, useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { tokens } from "../../../../locales/tokens";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectService } from "../../../../store/service/repository/selector";
import { useNavigate, useParams } from "react-router-dom";
import { paths } from "../../../../paths";
import { getSingleService } from "../../../../store/service/useCases/getSingleService/action";
import { createService } from "../../../../store/service/useCases/createService/action";
import { editService } from "../../../../store/service/useCases/editService/action";
import { setSingleService } from "../../../../store/service/repository/slice";

export const useServiceCreate = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [title, setTitle] = useState('');
    const [nomenclature, setNomenclature] = useState('');
    const [units, setUnits] = useState('');
    const [titleValid, setTitleValid] = useState(true);
    const [nomenclatureValid, setNomenclatureValid] = useState(true);
    const [unitsValid, setUnitsValid] = useState(true);
    const [createClick, setCreateClick] = useState(false);
    const [editClick, setEditClick] = useState(false);
    const { isLoading, createServiceError, singleService, updateServiceError } = useSelector(selectService);

    useEffect(() => {
        if (id) {
            dispatch(getSingleService({ id }))
        };

        return () => {
            dispatch(setSingleService(null));
            setTitle('');
            setNomenclature('');
            setUnits('');
        };
    }, [id]);

    useEffect(() => {
        if (singleService) {
            setTitle(singleService.name);
            setNomenclature(singleService.nomenclature_key);
            setUnits(singleService.measurement_unit_key);
        }
    }, [singleService]);

    useEffect(() => {
        if (!isLoading && createClick) {
            if (updateServiceError) {
                toast.error(updateServiceError);
            } else {
                toast.success(t(tokens.service.message.serviceCreated));
                navigate(paths.dashboard.service.list);
            }
            setCreateClick(false);
        }
    }, [updateServiceError, isLoading]);

    useEffect(() => {
        if (!isLoading && editClick) {
            if (createServiceError) {
                toast.error(createServiceError);
            } else {
                toast.success(t(tokens.service.message.serviceUpdated));
                navigate(paths.dashboard.service.list);
            }
            setEditClick(false);
        }
    }, [createServiceError, isLoading]);

    const onChangeTitle = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
        setTitleValid(true);
    }, []);

    const onChangeNomenclature = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setNomenclature(event.target.value);
        setNomenclatureValid(true);
    }, []);

    const onChangeUnits = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setUnits(event.target.value);
        setUnitsValid(true);
    }, []);

    const handleService = () => {
        const isDataValid = validateData();
        if (isDataValid) {
            performRequest();
        } else {
            toast.error(t(tokens.service.fillAllData))
        }
    };

    const validateData = () => {
        const data = [
            { field: title, setField: setTitleValid },
            { field: nomenclature, setField: setNomenclatureValid },
            { field: units, setField: setUnitsValid },
        ];
        let allFieldsValid = true;
        for (const { field, setField } of data) {
            if (!field) {
                setField(false);
                allFieldsValid = false;
            }
        };
        return allFieldsValid;
    };

    const getRequestBody = () => {
        const body: any = {
            name: title,
            nomenclature_key: nomenclature,
            measurement_unit_key: units
        };
        if (id) body.service_receiving_id = Number(id);
        return body;
    };

    const performRequest = () => {
        if (id) {
            dispatch(editService(getRequestBody()));
            setEditClick(true);
        } else {
            dispatch(createService(getRequestBody()));
            setCreateClick(true);
        }
    };

    return {
        title, nomenclature, titleValid, nomenclatureValid, onChangeNomenclature, onChangeTitle, units, unitsValid, onChangeUnits, handleService, id
    }
};