import { Box, InputAdornment, OutlinedInput, SvgIcon } from "@mui/material"
import { ChangeEvent, FC, MutableRefObject } from "react"
import SearchIcon from '@mui/icons-material/Search';

interface IProps {
    searchText: string;
    label: string;
    handleSearch: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const SearchInput: FC<IProps> = ({ searchText, handleSearch, label }) => {

    return (
        <Box component="form" sx={{ width: '100%' }}>
            <OutlinedInput
                value={searchText}
                fullWidth
                name="orderNumber"
                onChange={handleSearch}
                placeholder={label}
                size="small"
                startAdornment={(
                    <InputAdornment position="start">
                        <SvgIcon>
                            <SearchIcon />
                        </SvgIcon>
                    </InputAdornment>
                )}
            />
        </Box>
    )
};