import { Box, Checkbox, CircularProgress, IconButton, Stack, SvgIcon, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { Dispatch, FC, SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from "react-router-dom";
import { useGetPermissions } from "../../../../../../hooks/useGetPermission";

interface IProps {
    service: any;
    serviceId: number | null;
    deletedId: number | null;
    isProgress: boolean;
    handleDeleteService: (id: number) => void;
    handleServiceId: (id: number) => void;
};

export const ServiceListItem: FC<IProps> = ({ service, handleDeleteService, serviceId, handleServiceId, isProgress, deletedId }) => {
    const { t } = useTranslation();
    const { isWrite_Service_Receiving } = useGetPermissions();
    const deleteService = useCallback(() => handleDeleteService(service.id), [service.id]);
    const defineDefaultService = useCallback(() => handleServiceId(service.id), [service.id]);

    return (
        <TableRow hover key={service.id}>
            <TableCell align="center" >
                <Typography variant="subtitle2">
                    {service.name || t(tokens.service.noData)}
                </Typography>
            </TableCell>
            <TableCell align="center" >
                <Typography variant="subtitle2">
                    {service.nomenclature_key || t(tokens.service.noData)}
                </Typography>
            </TableCell>
            <TableCell align="center" >
                <Typography variant="subtitle2">
                    {service.measurement_unit_key || t(tokens.service.noData)}
                </Typography>
            </TableCell>
            <TableCell align="center" >
                <Checkbox
                    checked={serviceId === service.id ? true : false}
                    onChange={defineDefaultService}
                    disabled={!isWrite_Service_Receiving}
                />
            </TableCell>
            <TableCell align="right" >
                <Stack direction="row" justifyContent="flex-end" alignItems="baseline">
                    <Tooltip title={t(tokens.service.editService)}>
                        <IconButton
                            component={Link}
                            to={`/admin-service-edit/${service.id}`}
                            disabled={!isWrite_Service_Receiving}
                        >
                            <SvgIcon><EditIcon /></SvgIcon>
                        </IconButton>
                    </Tooltip>
                    {isProgress && deletedId === service.id ? (
                        <Box px={1.25}><CircularProgress color="primary" size={20} /></Box>
                    ) : (
                        <Tooltip title={t(tokens.service.deleteService)}>
                            <IconButton
                                onClick={deleteService}
                                disabled={!isWrite_Service_Receiving}
                            >
                                <SvgIcon><DeleteIcon /></SvgIcon>
                            </IconButton>
                        </Tooltip>)}
                </Stack>
            </TableCell>
        </TableRow>
    )
};